import { Query, query, where } from "firebase/firestore";

import { CompanyDocHelpers } from "../helpers/CompanyDocHelpers";
import { Doc } from "@/features/modules/doc/objects/Doc";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocModel } from "@/features/modules/doc/models/DocModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getExpiredCompanyDocs, getExpiringCompanyDocs, getMissingCompanyDocs } from "./methods";

export class CompanyDocModel extends DocModel {
  public constructor() {
    super();
    this.collectionName = "companyDocs";
    this.roleModule = "companyDoc";
    this.parentCollectionName = "companies";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<Doc[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    let customQuery: Query<Doc> | undefined;
    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(parentId) === false)
        throw new Error(`User doesn't have rights to read company #${parentId}`);
      customQuery = query(
        this.getPathReference(parentId).withConverter(this.firestoreConverter),
        where("type.visibleFor", "array-contains", DocTypeSource.Doctor)
      );
    }

    return super.getDocuments(sortCriterias, parentId, customQuery);
  }

  public async deleteDocument(companyDoc: Doc, parentId?: string): Promise<boolean> {
    const result: boolean = await super.deleteDocument(companyDoc, parentId);
    if (result === false) return false;

    for (const file of companyDoc.files) {
      await storageModel.deleteFile(CompanyDocHelpers.getFolderPath(parentId), file.uniqueName as string);
    }
    return true;
  }

  public async getExpiredCompanyDocs(): Promise<DocExpiration[]> {
    return getExpiredCompanyDocs();
  }

  public async getExpiringCompanyDocs(): Promise<DocExpiration[]> {
    return getExpiringCompanyDocs();
  }

  public async getMissingCompanyDocs(): Promise<DocExpiration[]> {
    return getMissingCompanyDocs();
  }
}

export const companyDocModel: CompanyDocModel = new CompanyDocModel();
