import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedServiceType } from "@/features/modules/serviceType/objects/LinkedServiceType";
import { Service } from "./Service";

import { DateStrictField, NumberField, ObjectField, StringStrictField } from "@/core/fields";

export class LinkedService extends LinkedFirestoreDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public date: Date = new Date();
  public type: LinkedServiceType | undefined = undefined;
  public company: LinkedCompany | undefined = undefined;
  public doctor: LinkedDoctor | undefined = undefined;
  public duration = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromService(service: Service): LinkedService {
    const linkedService: LinkedService = new LinkedService();
    linkedService.id = service.id;
    linkedService.code = service.code;
    linkedService.codeSort = service.codeSort;
    linkedService.codeDisplay = service.codeDisplay;
    linkedService.year = service.year;
    linkedService.date = service.date;
    linkedService.type = service.type;
    linkedService.company = service.company;
    linkedService.doctor = service.doctor;
    linkedService.duration = service.duration;

    return linkedService;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedService {
    super.fromFirestore(data);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.type = ObjectField.fromFirestore<LinkedServiceType>(data.type, (value) => new LinkedServiceType(value));
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.doctor = ObjectField.fromFirestore<LinkedDoctor>(data.doctor, (value) => new LinkedDoctor(value));
    this.duration = NumberField.fromFirestore(data.duration);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.type = ObjectField.toFirestore<LinkedServiceType>(this.type, (value) => value.toFirestore());
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.doctor = ObjectField.toFirestore<LinkedDoctor>(this.doctor, (value) => value.toFirestore());
    firestoreData.duration = NumberField.toFirestore(this.duration);

    return firestoreData;
  }
}
