import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";

export const emptyQueue = async (): Promise<void> => {
  try {
    const editsKeys: IDBValidKey[] = await indexedDbModel.getDocumentsKeys("offlineEdits");

    for (const key of editsKeys) {
      await indexedDbModel.deleteDocument("offlineEdits", key.toString());
    }

    const uploadsKeys: IDBValidKey[] = await indexedDbModel.getDocumentsKeys("offlineUploads");

    for (const key of uploadsKeys) {
      await indexedDbModel.deleteDocument("offlineUploads", key.toString());
    }

    eventBus.emit("offlineDocumentQueueChanged");
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.emptyQueue", {}, error);
  }
};
