import { StorageFile } from "@/core/modules/storage/objects/StorageFile";

import { deleteFile, fileExists, getBase64, getDownloadLink, upload, uploadBase64, uploadFile } from "./methods";

export class StorageModel {
  public async deleteFile(path: string, name: string): Promise<void> {
    return deleteFile(path, name);
  }

  public async fileExists(path: string, name: string): Promise<boolean> {
    return fileExists(path, name);
  }

  public async getBase64(path: string, name: string): Promise<string> {
    return getBase64(path, name);
  }

  public async getDownloadLink(path: string, name: string): Promise<string> {
    return getDownloadLink(path, name);
  }

  public async uploadBase64(path: string, fileName: string, base64File: string, metadata?: Record<string, string>): Promise<StorageFile> {
    return uploadBase64(path, fileName, base64File, metadata);
  }

  public async uploadFile(path: string, file: File, name: string | undefined, metadata?: Record<string, string>): Promise<StorageFile> {
    return uploadFile(path, file, name, metadata);
  }

  // INTERNAL

  public async upload(
    path: string,
    name: string,
    file: File,
    callback: (progress: number) => void,
    metadata?: Record<string, string>
  ): Promise<void> {
    return upload(path, name, file, callback, metadata);
  }
}

export const storageModel: StorageModel = new StorageModel();
