export class NumberField {
  public static fromFirestore(value: unknown): number {
    if (value === null || value === undefined) return 0;

    return Number(value);
  }

  public static toFirestore(value: number): number {
    if (value === null || value === undefined) return 0;

    return Number(value);
  }

  public static fromOfflineCache(value: unknown): number {
    return NumberField.fromFirestore(value);
  }

  public static toOfflineCache(value: number): number {
    return NumberField.toFirestore(value);
  }
}
