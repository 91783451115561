import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { NoseSurveyForm } from "../objects/NoseSurveyForm";

export class NoseSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private noseSurveyForm: NoseSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    noseSurveyForm: NoseSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.noseSurveyForm = noseSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      noseChoiceLabel: { color: this.textColor, fontSize: 10 },
      noseChoiceQuestion: { color: this.textColor, fontSize: 10, bold: true, lineHeight: 1.2 },
      noseChoiceValue: { color: this.textColor, bold: true, fontSize: 10 },
      noseNotes: {
        alignment: "center",
        bold: true,
        color: this.highlightColor,
        fontSize: 10,
        margin: [0, DataHelpers.mmToPoints(4), 0, DataHelpers.mmToPoints(4)],
      },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("QUESTIONARIO PER LO STUDIO DEI DISTURBI NASALI", isContinuos);

    await this.writeEmployeeData();

    this.writeQuestion(1, "Anno");
    this.writeQuestion(2, "Anno");
    this.writeQuestion(3, "Quali");

    this.writeNote("Invio allo specialista ORL in caso di almeno una risposta positiva alle domande sottoriportate");

    this.writeQuestion(4, "Da quanto");
    this.writeQuestion(5, "Da quanto");
    this.writeQuestion(6, "Da quanto");
    this.writeQuestion(7, "Da quanto");
    this.writeQuestion(8, "Da quanto");
    this.writeQuestion(9, "Da quanto");

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeQuestion(questionIndex: number, subQuestion: string): void {
    const answerNoTableBody: unknown[] = [
      [
        {
          text: this.noseSurveyForm[`question${questionIndex}` as keyof NoseSurveyForm] === false ? "X" : " ",
          style: "noseChoiceValue",
          border: [true, true, true, true],
        },
      ],
    ];
    const answerYesTableBody: unknown[] = [
      [
        {
          text: this.noseSurveyForm[`question${questionIndex}` as keyof NoseSurveyForm] === false ? "X" : " ",
          style: "noseChoiceValue",
          border: [true, true, true, true],
        },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(80),
          text: `${questionIndex}. ${this.t(`surveyForm.noseSurveyForm.question${questionIndex}`)}`,
          style: "noseChoiceQuestion",
        },
        {
          width: DataHelpers.mmToPoints(10),
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          style: "noseChoiceValue",
          table: {
            body: answerNoTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(15),
          text: this.t("gen.no"),
          style: "noseChoiceLabel",
        },
        {
          width: DataHelpers.mmToPoints(10),
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          style: "noseChoiceValue",
          table: {
            body: answerYesTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(15),
          text: this.t("gen.yes"),
          style: "noseChoiceLabel",
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.noseSurveyForm[`question${questionIndex}Details` as keyof NoseSurveyForm] !== undefined ? `${subQuestion}:` : "",
          style: "noseChoiceLabel",
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: this.noseSurveyForm[`question${questionIndex}Details` as keyof NoseSurveyForm] ?? "",
          style: "noseChoiceLabel",
        },
      ],
    });
  }

  private writeNote(text: string): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: text,
      style: "noseNotes",
    });
  }
}
