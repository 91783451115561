import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Norm } from "../objects/Norm";
import { normOfflineModel } from "./NormOfflineModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class NormModel extends FirestoreModel<Norm> {
  public constructor() {
    super(() => new Norm(), "norms", LockPolicy.DiscardUnsyncedChanges, "norm");
    this.beforeDeleteFunction = "featuresNormBeforeDelete";
    this.offlineModuleModel = normOfflineModel;
  }

  public async getDocuments(): Promise<Norm[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(normId: string): Promise<Norm> {
    return super.getDocument(normId);
  }

  public async createDocument(norm: Norm): Promise<string> {
    return super.createDocument(norm);
  }

  public async updateDocument(norm: Norm): Promise<void> {
    return super.updateDocument(norm);
  }

  public async deleteDocument(norm: Norm): Promise<boolean> {
    return super.deleteDocument(norm);
  }
}

export const normModel: NormModel = new NormModel();
