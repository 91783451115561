import { StringField } from "@/core/fields";

export class OfflineEdit {
  public collectionName: string | undefined = undefined;
  public id: string | undefined = undefined;
  public type: "create" | "update" | "delete" = "create";
  public object: Record<string, unknown> = {};

  public fromOfflineCache(data: Record<string, unknown>): OfflineEdit {
    this.collectionName = StringField.fromOfflineCache(data.collectionName);
    this.id = StringField.fromOfflineCache(data.id);
    this.type = StringField.fromOfflineCache(data.type) as "create" | "update" | "delete";
    this.object = data.object as Record<string, unknown>;

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    return {
      collectionName: StringField.toOfflineCache(this.collectionName),
      id: StringField.toOfflineCache(this.id),
      type: StringField.toOfflineCache(this.type),
      object: this.object,
    };
  }
}
