import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { RachisExamSurveyForm } from "../objects/RachisExamSurveyForm";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class RachisExamSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private rachisExamSurveyForm: RachisExamSurveyForm;

  private hasTitle5Details = false;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    rachisExamSurveyForm: RachisExamSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.rachisExamSurveyForm = rachisExamSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      rachisExamAnswerTable: { fontSize: 8 },
      rachisExamAnswerValue: { alignment: "center", bold: true },
      rachisExamNote: { color: this.textColor, fontSize: 10, alignment: "justify" },
      rachisExamRow: { color: this.textColor, fontSize: 10 },
      rachisExamRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      rachisExamQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("VALUTAZIONE FUNZIONALE DEL RACHIDE", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle(this.t("surveyForm.rachisExamSurveyForm.title1"));

    this.writeTwoQuestions(1, 2);
    this.writeTwoQuestions(3, undefined);
    this.writeTwoQuestions(4, 5);

    this.writeTwoQuestionsWithTitle(this.t("surveyForm.rachisExamSurveyForm.title2"), 6, 7);
    this.writeTwoQuestionsWithTitle(this.t("surveyForm.rachisExamSurveyForm.title3"), 8, 9);
    this.writeTwoQuestionsWithTitle(this.t("surveyForm.rachisExamSurveyForm.title4"), 10, undefined);

    this.writeTwoQuestions(11, 12);

    this.writeOneArrayQuestion(13);
    this.writeOneArrayQuestion(14);
    this.writeOneArrayQuestion(15);
    this.writeOneArrayQuestion(16);

    this.writeSubtitle(this.t("surveyForm.rachisExamSurveyForm.title5"));

    this.hasTitle5Details = this.hasTitle5Details || this.writeOneArrayQuestion(17);
    this.hasTitle5Details = this.hasTitle5Details || this.writeOneArrayQuestion(18);
    this.hasTitle5Details = this.hasTitle5Details || this.writeOneArrayQuestion(19);
    this.hasTitle5Details = this.hasTitle5Details || this.writeOneArrayQuestion(20);

    if (this.hasTitle5Details === false) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(6)],
        text: "Nulla da segnalare",
        style: "rachisExamRow",
      });
    } else {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(0), 0, DataHelpers.mmToPoints(4)],
        text: "",
        style: "rachisExamRow",
      });
    }

    this.writeOneArrayQuestion(21);

    if (this.rachisExamSurveyForm.question22 !== undefined) {
      this.writeSubtitle(this.t("surveyForm.rachisExamSurveyForm.question22"));
      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.rachisExamSurveyForm.question22 ?? "-",
        style: "rachisExamNote",
      });
    }

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeOneArrayQuestion(questionA: number): boolean {
    if ((this.rachisExamSurveyForm[`question${questionA}` as keyof RachisExamSurveyForm] as string[]).length === 0) return false;

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(70),
          text: questionA !== undefined ? this.t(`surveyForm.rachisExamSurveyForm.question${questionA}`) : "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: (this.rachisExamSurveyForm[`question${questionA}` as keyof RachisExamSurveyForm] as string[]).join(", ") ?? "-",
          style: "rachisExamRow",
        },
      ],
    });
    return true;
  }

  private writeTwoQuestions(questionA: number | undefined, questionB: number | undefined): void {
    const columnWidth = 45;
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionA !== undefined ? this.t(`surveyForm.rachisExamSurveyForm.question${questionA}`) : "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionA !== undefined ? this.rachisExamSurveyForm[`question${questionA}` as keyof RachisExamSurveyForm] ?? "-" : "",
          style: "rachisExamRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionB !== undefined ? this.t(`surveyForm.rachisExamSurveyForm.question${questionB}`) : "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionB !== undefined ? this.rachisExamSurveyForm[`question${questionB}` as keyof RachisExamSurveyForm] ?? "-" : "",
          style: "rachisExamRow",
        },
      ],
    });
  }

  private writeTwoQuestionsWithTitle(title: string, questionA: number | undefined, questionB: number | undefined): void {
    let columnWidth = 30;
    if (questionB === undefined) columnWidth = 60;
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(70),
          text: title,
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionA !== undefined ? this.t(`surveyForm.rachisExamSurveyForm.question${questionA}`) : "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionA !== undefined ? this.rachisExamSurveyForm[`question${questionA}` as keyof RachisExamSurveyForm] ?? "-" : "",
          style: "rachisExamRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionB !== undefined ? this.t(`surveyForm.rachisExamSurveyForm.question${questionB}`) : "",
          style: "rachisExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionB !== undefined ? this.rachisExamSurveyForm[`question${questionB}` as keyof RachisExamSurveyForm] ?? "-" : "",
          style: "rachisExamRow",
        },
      ],
    });
  }
}
