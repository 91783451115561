import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";
import { Supplier } from "./Supplier";

import { StringField } from "@/core/fields";

export class LinkedSupplier extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromSupplier(supplier: Supplier): LinkedSupplier {
    const linkedSupplier: LinkedSupplier = new LinkedSupplier();
    linkedSupplier.id = supplier.id;
    linkedSupplier.name = supplier.name;

    return linkedSupplier;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedSupplier {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedSupplier {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const offlineCacheData: Record<string, unknown> = super.toOfflineCache();

    offlineCacheData.name = StringField.toOfflineCache(this.name);

    return offlineCacheData;
  }
}
