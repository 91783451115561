
import { defineComponent, Ref, ref, watch } from "vue";

import { differenceInCalendarDays } from "date-fns";

import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "KoruExpiration",
  props: {
    date: { type: Date, default: undefined },
    explicitIfNone: { type: Boolean, default: true },
  },
  setup(props) {
    const { d, t } = useLocale();

    const expirationClasses: Ref<string> = ref("");
    const expirationText: Ref<string> = ref("");
    const expirationTooltip: Ref<string> = ref("");

    watch(
      () => props.date,
      (expirationDate: Date | undefined) => {
        if (expirationDate === undefined) {
          if (props.explicitIfNone === true) {
            expirationText.value = t("gen.expirationNotAvailable");
            expirationClasses.value = "badge status-blue";
            expirationTooltip.value = "";
          } else {
            expirationText.value = "-";
            expirationClasses.value = "";
            expirationTooltip.value = "";
          }
          return;
        }

        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);
        const daysLeft = differenceInCalendarDays(expirationDate, today);

        if (daysLeft >= 30) {
          expirationText.value = d(expirationDate, "shortDate");
          expirationClasses.value = "badge status-green";
          expirationTooltip.value = `${daysLeft} ${t("gen.expirationDaysLeft")}`;
          return;
        } else if (daysLeft >= 0) {
          expirationText.value = d(expirationDate, "shortDate");
          expirationClasses.value = "badge status-orange";
          expirationTooltip.value = `${daysLeft} ${t("gen.expirationDaysLeft")}`;
          return;
        }

        expirationText.value = d(expirationDate, "shortDate");
        expirationClasses.value = "badge status-red";
        expirationTooltip.value = t("gen.expirationExpired");
      },
      { immediate: true }
    );

    return {
      d,
      expirationClasses,
      expirationText,
      expirationTooltip,
      FormatHelpers,
      t,
    };
  },
});
