import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { VaccineType } from "../objects/VaccineType";
import { vaccineTypeModel } from "./VaccineTypeModel";

export class VaccineTypeOfflineModel extends OfflineModuleModel<VaccineType> {
  public constructor() {
    super(() => new VaccineType(), "vaccineTypes", "vaccineType.vaccineTypes", undefined);
  }

  public async downloadCache(): Promise<VaccineType[]> {
    try {
      return vaccineTypeModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("VaccineTypeOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const vaccineTypeOfflineModel: VaccineTypeOfflineModel = new VaccineTypeOfflineModel();
