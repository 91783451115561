import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";

export const setPersonalDuty = (employeeDuty: EmployeeDuty, companyDuties: CompanyDuty[]): void => {
  try {
    if (employeeDuty === undefined || companyDuties === undefined) throw new Error("setPersonalDuty");

    employeeDuty.name = "";
    employeeDuty.frequency = 999;
    employeeDuty.risks = {};
    employeeDuty.risksNotes = undefined;
    employeeDuty.testTypes = {};
    employeeDuty.surveys = {};

    for (const companyDuty of companyDuties) {
      // name
      employeeDuty.name += employeeDuty.name === "" ? companyDuty.name : `, ${companyDuty.name}`;

      // frequency
      if (companyDuty.frequency < employeeDuty.frequency) employeeDuty.frequency = companyDuty.frequency;

      // risks
      for (const riskId of Object.keys(companyDuty.risks)) {
        if (riskId in employeeDuty.risks) continue;
        employeeDuty.risks[riskId] = companyDuty.risks[riskId];
      }

      // risksNotes
      if (companyDuty.risksNotes != undefined) {
        if (employeeDuty.risksNotes === undefined) employeeDuty.risksNotes = "";
        employeeDuty.risksNotes += employeeDuty.risksNotes === "" ? companyDuty.risksNotes : `\n${companyDuty.risksNotes}`;
        employeeDuty.risksNotes = employeeDuty.risksNotes.trim();
      }

      // testTypes
      for (const testTypeId of Object.keys(companyDuty.testTypes)) {
        if (testTypeId in employeeDuty.testTypes) continue;
        employeeDuty.testTypes[testTypeId] = companyDuty.testTypes[testTypeId];
      }

      // surveys
      for (const surveyId of Object.keys(companyDuty.surveys)) {
        if (surveyId in employeeDuty.surveys) continue;
        employeeDuty.surveys[surveyId] = companyDuty.surveys[surveyId];
      }
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeDutyModel.setPersonalDuty", { employeeDuty, companyDuties }, error);
  }
};
