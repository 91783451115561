
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, WritableComputedRef } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { assistModel } from "../models/AssistModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";
import { websocketClient } from "../objects/WebsocketClient";

export default defineComponent({
  name: "AssistView",
  props: {
    show: { type: Boolean, default: false },
  },
  emits: ["update:show"],
  setup(props, { emit }) {
    const { t } = useLocale();
    const store = useStore();
    let eventBusId: string | undefined = undefined;

    const isVisible: WritableComputedRef<boolean> = computed({
      get: () => props.show,
      set: (val) => emit("update:show", val),
    });

    const assistState: Ref<"connected" | "disconnected"> = ref("disconnected");

    const connect = async (): Promise<void> => {
      await AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          isVisible.value = false;

          websocketClient.startWebsocket(
            () => ToastHelpers.showToastWithSuccess("assistConnection", t),
            () => ToastHelpers.showToastWithError("assistConnection", "generic", t)
          );
        },
        "assistConnection",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const disconnect = async (): Promise<void> => {
      await AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          isVisible.value = false;

          websocketClient.stopWebsocket();
        },
        "assistConnection",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      eventBusId = eventBus.on("assistToggleStateView", async (state: "connected" | "disconnected" | undefined) => {
        if (state === undefined) return;
        assistState.value = state;
      });

      assistState.value = assistModel.getAssistState();
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      assistState,
      connect,
      disconnect,
      isVisible,
      t,
    };
  },
});
