import { documentId, getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getExaminationsByIds = async (ids: string[]): Promise<Examination[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (examinationModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      return documents.filter((examination) => ids.includes(examination.id) === true);
    }

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where(documentId(), "in", ids)
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsByIds", { ids }, error);
    return [];
  }
};
