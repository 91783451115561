import { EmployeeDuty } from "./EmployeeDuty";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { DateField, StringField } from "@/core/fields";

export class LinkedEmployeeDuty extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public to: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromEmployeeDuty(employeeDuty: EmployeeDuty): LinkedEmployeeDuty {
    const linkedEmployeeDuty: LinkedEmployeeDuty = new LinkedEmployeeDuty();
    linkedEmployeeDuty.id = employeeDuty.id;
    linkedEmployeeDuty.name = employeeDuty.name;
    linkedEmployeeDuty.to = employeeDuty.to;

    return linkedEmployeeDuty;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedEmployeeDuty {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.to = DateField.fromFirestore(data.to);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.to = DateField.toFirestore(this.to);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedEmployeeDuty {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.to = DateField.fromOfflineCache(data.to);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.to = DateField.toOfflineCache(this.to);

    return firestoreData;
  }
}
