import { CostList } from "./CostList";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedCostList extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromCostList(costList: CostList): LinkedCostList {
    const linkedCostList: LinkedCostList = new LinkedCostList();
    linkedCostList.id = costList.id;
    linkedCostList.name = costList.name;

    return linkedCostList;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedCostList {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
