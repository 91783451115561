
import { defineComponent } from "vue";

import { config } from "@/core/modules/config/objects/Config";
import { NavigationItem } from "@/core/modules/navigation/interfaces/NavigationItem";
import { useLocale } from "@/core/modules/locale/module";

import SubMenu from "./SubMenu.vue";

export default defineComponent({
  name: "Menu",
  components: {
    SubMenu,
  },
  props: {
    model: Array<NavigationItem>,
    layoutMode: String,
    active: Boolean,
    mobileMenuActive: Boolean,
  },
  emits: ["menu-click", "menuitem-click", "root-menuitem-click"],
  setup() {
    const { t } = useLocale();

    const sidebarLogoUrl: string =
      config.app.logos.sidebarImage === "features" ? "/assets/features/images/logo-white.svg" : "/assets/core/images/koru-logo-white.svg";

    return {
      config,
      sidebarLogoUrl,
      t,
    };
  },
  methods: {
    onMenuClick(event: Event): void {
      this.$emit("menu-click", event);
    },
    onMenuItemClick(event: Event): void {
      this.$emit("menuitem-click", event);
    },
    onRootMenuItemClick(event: Event): void {
      this.$emit("root-menuitem-click", event);
    },
  },
});
