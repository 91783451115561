import { RouteRecordRaw } from "vue-router";

export const branchRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:companyId/branches/:branchId/edit",
    name: "BranchEdit",
    component: () => import("../views/BranchEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branch",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:companyId/branches/:id/:section?",
    name: "BranchView",
    component: () => import("../views/BranchView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branch",
      requiredRight: "viewUI",
    },
  },
];
