import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Risk } from "../objects/Risk";
import { riskOfflineModel } from "./RiskOfflineModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class RiskModel extends FirestoreModel<Risk> {
  public constructor() {
    super(() => new Risk(), "risks", LockPolicy.DiscardUnsyncedChanges, "risk");
    this.beforeDeleteFunction = "featuresRiskBeforeDelete";
    this.offlineModuleModel = riskOfflineModel;
  }

  public async getDocuments(): Promise<Risk[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(riskId: string): Promise<Risk> {
    return super.getDocument(riskId);
  }

  public async createDocument(risk: Risk): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    risk.order = documentCounter + 1;

    return super.createDocument(risk);
  }

  public async updateDocument(risk: Risk): Promise<void> {
    return super.updateDocument(risk);
  }

  public async deleteDocument(risk: Risk): Promise<boolean> {
    return super.deleteDocument(risk);
  }
}

export const riskModel: RiskModel = new RiskModel();
