import { Company } from "./Company";
import { CompanyType } from "./CompanyType";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { EnumField, StringField } from "@/core/fields";

export class LinkedCompany extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public type: CompanyType = CompanyType.Private;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromCompany(company: Company): LinkedCompany {
    const linkedCompany: LinkedCompany = new LinkedCompany();
    linkedCompany.id = company.id;
    linkedCompany.name = company.name;
    linkedCompany.type = company.type;

    return linkedCompany;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedCompany {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.type = EnumField.fromFirestore<CompanyType>(data.type, Object.values(CompanyType), CompanyType.Private);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.type = EnumField.toFirestore<CompanyType>(this.type, CompanyType.Private);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedCompany {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.type = EnumField.fromOfflineCache<CompanyType>(data.type, Object.values(CompanyType), CompanyType.Private);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.type = EnumField.toOfflineCache<CompanyType>(this.type, CompanyType.Private);

    return firestoreData;
  }
}
