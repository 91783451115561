import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Employer } from "../objects/Employer";
import { employerOfflineModel } from "./EmployerOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getEmployersByBranch, getEmployersByCompany, getEmployersByEmployeeAndDate } from "./methods";

export class EmployerModel extends FirestoreModel<Employer> {
  public constructor() {
    super(() => new Employer(), "employers", LockPolicy.DiscardUnsyncedChanges, "employer");
    this.parentCollectionName = "employees";
    this.offlineModuleModel = employerOfflineModel;
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<Employer[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      const employee: Employee = await employeeModel.getDocument(parentId);
      const employeeCompanies: string[] = Object.keys(employee.companies);
      if (doctor === undefined || doctor.authorizedCompaniesIds.filter((id) => employeeCompanies.includes(id)).length === 0)
        throw new Error(`User doesn't have rights to read employee #${parentId}`);
    }

    return super.getDocuments([new SortCriteria("fromSort", "asc", "string")], parentId);
  }

  public async getDocument(employerId: string, parentId?: string): Promise<Employer> {
    return super.getDocument(employerId, parentId);
  }

  public async createDocument(employer: Employer, parentId?: string): Promise<string> {
    return await super.createDocument(employer, parentId);
  }

  public async updateDocument(employer: Employer, parentId?: string): Promise<void> {
    return super.updateDocument(employer, parentId);
  }

  public async deleteDocument(employer: Employer, parentId?: string): Promise<boolean> {
    const employers: Employer[] = await employerModel.getDocuments([], parentId as string);
    if (employers.length <= 1) throw new Error("employerAtLeastOne");

    return super.deleteDocument(employer, parentId);
  }

  public async getEmployersByBranch(branchId: string): Promise<Employer[]> {
    return getEmployersByBranch(branchId);
  }

  public async getEmployersByCompany(companyId: string): Promise<Employer[]> {
    return getEmployersByCompany(companyId);
  }

  public async getEmployersByEmployeeAndDate(employeeId: string, date: Date): Promise<Employer[]> {
    return getEmployersByEmployeeAndDate(employeeId, date);
  }
}

export const employerModel: EmployerModel = new EmployerModel();
