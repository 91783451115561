import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { PriceList } from "./PriceList";

import { StringField } from "@/core/fields";

export class LinkedPriceList extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromPriceList(priceList: PriceList): LinkedPriceList {
    const linkedPriceList: LinkedPriceList = new LinkedPriceList();
    linkedPriceList.id = priceList.id;
    linkedPriceList.name = priceList.name;

    return linkedPriceList;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedPriceList {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
