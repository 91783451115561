import { DocumentData, DocumentReference, FieldValue, WriteBatch, writeBatch } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { config } from "@/core/modules/config/objects/Config";
import { Edit } from "../interfaces/Edit";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

const MAX_EDITS = 200;
const DELAY_MILLIS = 2000;
const LONG_DELAY_THRESHOLD = 9000;
const LONG_DELAY_MILLIS = 20000;

export class Batch {
  private edits: Edit[] = [];

  public set<T extends DocumentData>(documentRef: DocumentReference<T>, data: Partial<T>) {
    this.edits.push({
      type: "set",
      reference: documentRef,
      data: data,
    });
  }

  public update<T extends DocumentData>(documentRef: DocumentReference<T>, data: Partial<T>) {
    this.edits.push({
      type: "update",
      reference: documentRef,
      data: data,
    });
  }

  public delete(documentRef: DocumentReference) {
    this.edits.push({
      type: "delete",
      reference: documentRef,
      data: undefined,
    });
  }

  public async commit(): Promise<void> {
    try {
      let batch: WriteBatch = writeBatch(firebase.firestore);

      let index = 0;
      let counter = 0;
      let longCounter = 0;
      for (const edit of this.edits) {
        if (edit.type == "set") {
          batch.set(edit.reference, edit.data);
        } else if (edit.type == "update") {
          batch.update(edit.reference, edit.data as { [x: string]: FieldValue | Partial<unknown> | undefined });
        } else if (edit.type == "delete") {
          batch.delete(edit.reference);
        } else {
          continue;
        }

        index++;
        counter++;
        longCounter++;
        if (counter >= MAX_EDITS) {
          if (longCounter >= LONG_DELAY_THRESHOLD) {
            await AppHelpers.delay(LONG_DELAY_MILLIS);
            longCounter = 0;
          }
          if (config.app.debug === true) AppHelpers.showDebug("batch", `committing ${counter} operations (${index}/${this.edits.length})`);
          await batch.commit();
          await AppHelpers.delay(DELAY_MILLIS);
          batch = writeBatch(firebase.firestore);
          counter = 0;
        }
      }

      if (counter > 0) {
        if (longCounter >= LONG_DELAY_THRESHOLD) await AppHelpers.delay(LONG_DELAY_MILLIS);
        if (config.app.debug === true) AppHelpers.showDebug("batch", `committing ${counter} operations (${index}/${this.edits.length})`);
        await batch.commit();
      }

      this.edits = [];
    } catch (error: unknown) {
      appFaultModel.catchAppError("Batch.commit", {}, error);
    }
  }
}

export const batch: Batch = new Batch();
