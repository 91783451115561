import { GetterTree } from "vuex";

import { storeTypes } from "./types";
import { User } from "@/core/modules/user/objects/User";
import { StoreState } from "./interfaces/StoreState";

export const getters: GetterTree<StoreState, StoreState> = {
  [storeTypes.getters.isEditing]: (state: StoreState): boolean => {
    return state.editing;
  },
  [storeTypes.getters.isLoading]: (state: StoreState): boolean => {
    return state.loading;
  },
  [storeTypes.getters.getColorScheme]: (state: StoreState): string => {
    return state.colorScheme;
  },
  [storeTypes.getters.getPreviousRoute]: (state: StoreState): string | undefined => {
    return state.previousRoute;
  },
  [storeTypes.getters.getUploadedData]: (state: StoreState): string | undefined => {
    return state.uploadedData;
  },
  [storeTypes.getters.getUser]: (state: StoreState): User | undefined => {
    return state.user;
  },
  [storeTypes.getters.showEditingDialog]: (state: StoreState): boolean => {
    return state.editingDialog;
  },
};
