
import { computed, defineComponent, WritableComputedRef } from "vue";
import { formatDistanceToNow, Locale } from "date-fns";
import { de, enGB, fr, it } from "date-fns/locale";

import { history } from "@/core/modules/history/objects/History";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "HistoryComponent",
  props: {
    rightMenuActive: Boolean,
  },
  emits: ["right-menu-click", "update:rightMenuActive"],
  setup(props, { emit }) {
    const { t } = useLocale();

    const store = useStore();

    const rightMenuActiveRef: WritableComputedRef<boolean> = computed({
      get: () => props.rightMenuActive,
      set: (val) => emit("update:rightMenuActive", val),
    });

    const rightMenuClick = ($event: unknown): void => {
      emit("right-menu-click", $event);
    };

    const onHistoryClick = (): void => {
      rightMenuActiveRef.value = false;
    };

    const clearHistory = (): void => {
      history.clear();
      rightMenuActiveRef.value = false;
    };

    const getLocale = (): Locale => {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (user.detail.locale === "de") {
        return de;
      } else if (user.detail.locale === "en") {
        return enGB;
      } else if (user.detail.locale === "fr") {
        return fr;
      } else if (user.detail.locale === "it") {
        return it;
      }
      return enGB;
    };

    const locale: Locale = getLocale();

    return {
      clearHistory,
      formatDistanceToNow,
      history,
      locale,
      onHistoryClick,
      rightMenuClick,
      t,
    };
  },
});
