
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { exportEmployeeModel } from "../models/ExportEmployeeModel";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "ExportEmployeeView",
  setup() {
    const { t } = useLocale();

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    /* custom */

    const companiesIds: Ref<string[]> = ref([]);
    const companies: Ref<Company[]> = ref([]);

    const loadAction = async () => {
      await AppHelpers.tryOrError(
        async () => {
          companies.value = await companyModel.getActiveCompanies();
        },
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const exportEmployees = async (): Promise<void> => {
      if (companiesIds.value === undefined || companiesIds.value.length === 0) {
        ToastHelpers.showToastWithWarning("noCompany", t);
        return;
      }

      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          const csvData: string = await exportEmployeeModel.exportEmployees(companiesIds.value);

          const element: HTMLAnchorElement = document.createElement("a");
          element.setAttribute("href", `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`);
          element.setAttribute("download", "oscilla-lavoratori.csv");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        },
        "utilityDone",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companiesIds,
      exportEmployees,
      t,
    };
  },
});
