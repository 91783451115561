import CodiceFiscale from "codice-fiscale-js";
import { differenceInCalendarDays, format } from "date-fns";
import { HttpsCallable, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { Ref } from "vue";

import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationTest } from "@/features/modules/examination/objects/ExaminationTest";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { ExternalTestSession } from "@/features/modules/externalTestSession/objects/ExternalTestSession";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { LinkedContract } from "@/features/modules/contract/objects/LinkedContract";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { LinkedExternalTest } from "@/features/modules/externalTest/objects/LinkedExternalTest";

import { getPeriodContextCommands } from "./methods";

import atecoCodesJson from "@/features/data/atecoCodes.json";

export class FeaturesHelpers {
  /**
   * calculate fiscal code
   * @param firstName the first name
   * @param lastName the last name
   * @param sex the sex
   * @param birthDate the birth date
   * @param birthPlace the birth place
   * @returns the calculated fiscal code
   */
  public static calculateFiscalCode(
    firstName: string | undefined,
    lastName: string | undefined,
    sex: string | undefined,
    birthDate: Date | undefined,
    birthPlace: string | undefined
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (firstName == undefined || lastName == undefined || sex == undefined || birthDate == undefined || birthPlace == undefined) {
        reject();
        return;
      }
      const cf = new CodiceFiscale({
        name: firstName,
        surname: lastName,
        gender: sex,
        day: birthDate.getDate(),
        month: birthDate.getMonth() == 12 ? 0 : birthDate.getMonth() + 1,
        year: birthDate.getFullYear(),
        birthplace: birthPlace,
      });
      resolve(cf.cf);
    });
  }

  public static getAtecoCodeDescription(atecoCode: string | undefined): string {
    const atecoCodes: Record<string, string> = atecoCodesJson;
    if (atecoCode === undefined) return "-";
    if (atecoCode in atecoCodes === false) return "-";
    return atecoCodes[atecoCode];
  }

  public static getAtecoCodes(): Record<string, string> {
    const atecoCodes: Record<string, string> = atecoCodesJson;
    return atecoCodes;
  }

  public static getPeriodContextCommands(period: Ref<Date[]>, t: (entry: string, params?: Record<string, unknown>) => string): ContextCommand[] {
    return getPeriodContextCommands(period, t);
  }

  public static renderActiveContracts(
    activeContracts: LinkedContract[],
    d: (date: Date, format: string) => string,
    t: (entry: string, params?: unknown) => string
  ): Record<string, string> {
    if (activeContracts.length === 0) {
      return {
        color: "red",
        tooltip: "",
        text: t("branch.contractStates.notAssigned"),
      };
    }

    let color = "green";
    const tooltips: string[] = [];
    for (const activeContract of activeContracts) {
      if (activeContract.to !== undefined) {
        const calcDaysLeft = differenceInCalendarDays(activeContract.to, new Date());
        if (calcDaysLeft <= 30) color = "orange";
        tooltips.push(t("branch.contractStates.dueDate", { date: d(activeContract.to, "shortDate"), days: calcDaysLeft }));
      }
    }

    return {
      color: color,
      tooltip: tooltips.join("\n"),
      text: `${activeContracts.length} ${t("branch.contractStates.active", activeContracts.length)}`,
    };
  }

  public static renderExaminationDocumentFileName(examination: Examination, type: "CS" | "GD" | "GL"): string {
    let fileName = "";
    if (examination.employee !== undefined) {
      fileName += `${DataHelpers.normalizeString(examination.employee.lastName?.toUpperCase() ?? "X")}`;
      fileName += `_${DataHelpers.normalizeString(examination.employee.firstName?.toUpperCase() ?? "X")}`;
      if (examination.employee.birthDate !== undefined) {
        fileName += `_${format(examination.employee.birthDate, "ddMMyy")}`;
      } else {
        fileName += "_X";
      }
    } else {
      fileName += "X_X_X";
    }

    const [examinationNumber, examinationYear] = examination.codeDisplay.split("/");
    fileName += `_${examinationYear}_${examinationNumber}`;
    fileName += "_%TYPE%";

    if (type === "CS") {
      return fileName.replaceAll("%TYPE%", "CS");
    } else if (type === "GD") {
      return fileName.replaceAll("%TYPE%", "GD");
    } else if (type === "GL") {
      return fileName.replaceAll("%TYPE%", "GL");
    }
    return fileName.replaceAll("%TYPE%", "X");
  }

  public static renderExaminationTestFileName(examination: Examination, examinationTest: ExaminationTest): string {
    let fileName = "";
    if (examination.employee !== undefined) {
      fileName += `${DataHelpers.normalizeString(examination.employee.lastName?.toUpperCase() ?? "X")}`;
      fileName += `_${DataHelpers.normalizeString(examination.employee.firstName?.toUpperCase() ?? "X")}`;
      if (examination.employee.birthDate !== undefined) {
        fileName += `_${format(examination.employee.birthDate, "ddMMyy")}`;
      } else {
        fileName += "_X";
      }
    } else {
      fileName += "X_X_X";
    }

    const [examinationNumber, examinationYear] = examination.codeDisplay.split("/");
    fileName += `_${examinationYear}_${examinationNumber}`;

    if (examinationTest !== undefined && examinationTest.testType !== undefined && examinationTest.testType.name !== undefined) {
      fileName += `_${DataHelpers.normalizeString(examinationTest.testType.name.toUpperCase())}`;
    } else {
      fileName += "_X";
    }

    return fileName;
  }

  public static renderExaminationAttachmentFileName(examination: Examination): string {
    let fileName = "";
    if (examination.employee !== undefined) {
      fileName += `${DataHelpers.normalizeString(examination.employee.lastName?.toUpperCase() ?? "X")}`;
      fileName += `_${DataHelpers.normalizeString(examination.employee.firstName?.toUpperCase() ?? "X")}`;
      if (examination.employee.birthDate !== undefined) {
        fileName += `_${format(examination.employee.birthDate, "ddMMyy")}`;
      } else {
        fileName += "_X";
      }
    } else {
      fileName += "X_X_X";
    }

    const [examinationNumber, examinationYear] = examination.codeDisplay.split("/");
    fileName += `_${examinationYear}_${examinationNumber}`;
    fileName += "_ALLEGATO";

    return fileName;
  }

  public static renderExternalTestFileName(externalTest: ExternalTest | LinkedExternalTest, employee: LinkedEmployee): string {
    let fileName = "";
    if (employee !== undefined) {
      fileName += `${DataHelpers.normalizeString(employee.lastName?.toUpperCase() ?? "X")}`;
      fileName += `_${DataHelpers.normalizeString(employee.firstName?.toUpperCase() ?? "X")}`;
      if (employee.birthDate !== undefined) {
        fileName += `_${format(employee.birthDate, "ddMMyy")}`;
      } else {
        fileName += "_X";
      }
    } else {
      fileName += "X_X_X";
    }

    if (externalTest !== undefined) {
      fileName += `_${externalTest.id}`;
    } else {
      fileName += "_X";
    }

    return fileName;
  }

  public static renderExternalTestSessionFileName(externalTestSession: ExternalTestSession, employee: LinkedEmployee): string {
    let fileName = "";
    if (employee !== undefined) {
      fileName += `${DataHelpers.normalizeString(employee.lastName?.toUpperCase() ?? "X")}`;
      fileName += `_${DataHelpers.normalizeString(employee.firstName?.toUpperCase() ?? "X")}`;
      if (employee.birthDate !== undefined) {
        fileName += `_${format(employee.birthDate, "ddMMyy")}`;
      } else {
        fileName += "_X";
      }
    } else {
      fileName += "X_X_X";
    }

    if (externalTestSession !== undefined) {
      fileName += `_${externalTestSession.id}`;
    } else {
      fileName += "_X";
    }

    return fileName;
  }

  public static async runNextCodeFunction(functionName: string, currentYear: number, firmId: string): Promise<number> {
    const validatorFunction: HttpsCallable = httpsCallable(firebase.functions, functionName);
    const functionResult: HttpsCallableResult = await validatorFunction({ currentYear: currentYear, firmId: firmId });
    const response: { result: number } = functionResult.data as { result: number };

    if (response === undefined || response.result === undefined) throw new Error("next code is undefined");
    return Promise.resolve(response.result);
  }
}
