export const it = {
  testForm: {
    answerError: "Selezionare una risposta",
    audioTestForm: {
      classType: "Classificazione",
      leftClass: "Classe Sinistra",
      rightClass: "Classe Destra",
      notes: "Note",
    },
    genTestForm: {
      badResultLabel: "Alterato",
      goodResultLabel: "Normale",
      value: "Valore",
    },
    spiroTestForm: {
      class: "Classe CECA71",
      notes: "Note",
    },
    visioTestForm: {
      question1: "Test con lenti",
      question2: "Visione binoculare",
      question3: "Acuità binoculare",
      question4: "Occhio destro",
      question5: "Occhio sinistro",
      question6: "Stereopsi",
      question7: "Colori",
      question8: "Forie",
      question9: "Forie laterali",
      question19: "Eseguito",
      question26: "Deficit della visione binoculare",
      question27: "Deficit della visione monoculare destra",
      question28: "Deficit della visione monoculare sinistra",
      question29: "Disforie",
      question30: "Discromatopsie",
      title1: "Test da lontano",
      title2: "Test da vicino",
      title3: "Test visione intermedia 57 cm",
      title4: "Conclusioni",
    },
    result: "Esito",
  },
};
