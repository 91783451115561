import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Broker } from "../objects/Broker";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getActiveBrokers, getBrokersWithTrackExpirations, isVatCodeUnique } from "./methods";

export class BrokerModel extends FirestoreModel<Broker> {
  public constructor() {
    super(() => new Broker(), "brokers", LockPolicy.DiscardUnsyncedChanges, "broker");
    this.beforeDeleteFunction = "featuresBrokerBeforeDelete";
  }

  public async getDocuments(): Promise<Broker[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(brokerId: string): Promise<Broker> {
    return super.getDocument(brokerId);
  }

  public async createDocument(broker: Broker): Promise<string> {
    try {
      await broker.handleArchive();

      return super.createDocument(broker);
    } catch (error: unknown) {
      appFaultModel.catchAppError("BrokerModel.createBroker", { broker }, error);
      return "ERROR";
    }
  }

  public async updateDocument(broker: Broker): Promise<void> {
    try {
      await broker.handleArchive();

      return super.updateDocument(broker);
    } catch (error: unknown) {
      appFaultModel.catchAppError("BrokerModel.updateBroker", { broker }, error);
    }
  }

  public async deleteDocument(broker: Broker): Promise<boolean> {
    return super.deleteDocument(broker);
  }

  public async getActiveBrokers(): Promise<Broker[]> {
    return getActiveBrokers();
  }

  public async getBrokersWithTrackExpirations(): Promise<Broker[]> {
    return getBrokersWithTrackExpirations();
  }

  public async isVatCodeUnique(vatCode: string, oldVatCode: string | undefined): Promise<boolean> {
    return isVatCodeUnique(vatCode, oldVatCode);
  }
}

export const brokerModel: BrokerModel = new BrokerModel();
