import { Query, query, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "../objects/Company";
import { companyOfflineModel } from "./CompanyOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getActiveCompanies, getCompaniesByBroker, getCompaniesWithTrackExpirations, isEmailUnique } from "./methods";

export class CompanyModel extends FirestoreModel<Company> {
  public constructor() {
    super(() => new Company(), "companies", LockPolicy.DiscardUnsyncedChanges, "company");
    this.beforeDeleteFunction = "featuresCompanyBeforeDelete";
    this.offlineModuleModel = companyOfflineModel;
  }

  public async getDocuments(): Promise<Company[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    let customQuery: Query<Company> | undefined;
    if (user.role?.name === "Medico") {
      customQuery = query(this.getPathReference().withConverter(this.firestoreConverter), where("authorizedDoctorsIds", "array-contains", user.id));
    } else if (user.role?.name === "Azienda") {
      customQuery = query(this.getPathReference().withConverter(this.firestoreConverter), where("userId", "==", user.id));
    }

    return super.getDocuments([new SortCriteria("name", "asc", "string")], undefined, customQuery);
  }

  public async getDocument(companyId: string): Promise<Company> {
    return super.getDocument(companyId);
  }

  public async createDocument(company: Company): Promise<string> {
    try {
      await company.handleArchive();

      return super.createDocument(company);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyModel.createCompany", { company }, error);
      return "ERROR";
    }
  }

  public async updateDocument(company: Company): Promise<void> {
    try {
      await company.handleArchive();

      return super.updateDocument(company);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyModel.updateCompany", { company }, error);
    }
  }

  public async deleteDocument(company: Company): Promise<boolean> {
    return super.deleteDocument(company);
  }

  public async getActiveCompanies(): Promise<Company[]> {
    return getActiveCompanies();
  }

  public async getCompaniesByBroker(brokerId: string): Promise<Company[]> {
    return getCompaniesByBroker(brokerId);
  }

  public async getCompaniesWithTrackExpirations(): Promise<Company[]> {
    return getCompaniesWithTrackExpirations();
  }

  public async isEmailUnique(email: string, companyId?: string, companyUserId?: string): Promise<boolean> {
    return isEmailUnique(email, companyId, companyUserId);
  }
}

export const companyModel: CompanyModel = new CompanyModel();
