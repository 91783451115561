import { config } from "@/core/modules/config/objects/Config";

import { StringStrictField } from "@/core/fields";

export class UserDetail {
  public locale: string = config.app.locale.default;
  public theme: string = config.app.layout.defaultTheme;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): UserDetail {
    this.locale = StringStrictField.fromFirestore(data.locale, config.app.locale.default);
    this.theme = StringStrictField.fromFirestore(data.theme, config.app.layout.defaultTheme);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.locale = StringStrictField.toFirestore(this.locale);
    firestoreData.theme = StringStrictField.toFirestore(this.theme);

    return firestoreData;
  }
}
