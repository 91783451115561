import { RouteRecordRaw } from "vue-router";

export const doctorAgendaRoutes: RouteRecordRaw[] = [
  {
    path: "/agenda/doctor",
    name: "DoctorAgendaView",
    component: () => import("../views/DoctorAgendaView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doctorAgenda",
      requiredRight: "viewUI",
    },
  },
];
