import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";

export const createFirestoreConverter = <T extends FirestoreDocument>(newIstance: () => T): FirestoreDataConverter<T> => {
  return {
    fromFirestore: function (snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions | undefined): T {
      const data = snapshot.data(options);
      const firestoreDocument: T = newIstance();
      firestoreDocument.fromFirestore(data, snapshot.id, snapshot.ref);
      return firestoreDocument;
    },
    toFirestore: function (firestoreDocument: T): Record<string, unknown> {
      return firestoreDocument.toFirestore();
    },
  };
};
