import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { VaccineType } from "../objects/VaccineType";
import { vaccineTypeOfflineModel } from "./VaccineTypeOfflineModel";

export class VaccineTypeModel extends FirestoreModel<VaccineType> {
  public constructor() {
    super(() => new VaccineType(), "vaccineTypes", LockPolicy.DiscardUnsyncedChanges, "vaccineType");
    this.beforeDeleteFunction = "featuresVaccineTypeBeforeDelete";
    this.offlineModuleModel = vaccineTypeOfflineModel;
  }

  public async getDocuments(): Promise<VaccineType[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(vaccineTypeId: string): Promise<VaccineType> {
    return super.getDocument(vaccineTypeId);
  }

  public async createDocument(vaccineType: VaccineType): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    vaccineType.order = documentCounter + 1;

    return super.createDocument(vaccineType);
  }

  public async updateDocument(vaccineType: VaccineType): Promise<void> {
    return super.updateDocument(vaccineType);
  }

  public async deleteDocument(vaccineType: VaccineType): Promise<boolean> {
    return super.deleteDocument(vaccineType);
  }
}

export const vaccineTypeModel: VaccineTypeModel = new VaccineTypeModel();
