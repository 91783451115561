import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { Limitation } from "../objects/Limitation";
import { limitationOfflineModel } from "./LimitationOfflineModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class LimitationModel extends FirestoreModel<Limitation> {
  public constructor() {
    super(() => new Limitation(), "limitations", LockPolicy.DiscardUnsyncedChanges, "limitation");
    this.beforeDeleteFunction = "featuresLimitationBeforeDelete";
    this.offlineModuleModel = limitationOfflineModel;
  }

  public async getDocuments(): Promise<Limitation[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(limitationId: string): Promise<Limitation> {
    return super.getDocument(limitationId);
  }

  public async createDocument(limitation: Limitation): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    limitation.order = documentCounter + 1;

    return super.createDocument(limitation);
  }

  public async updateDocument(limitation: Limitation): Promise<void> {
    return super.updateDocument(limitation);
  }

  public async deleteDocument(limitation: Limitation): Promise<boolean> {
    return super.deleteDocument(limitation);
  }
}

export const limitationModel: LimitationModel = new LimitationModel();
