import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getInvoicesWithFilters = async (
  startDate: Date,
  endDate: Date,
  companyId: string | undefined,
  brokerId: string | undefined,
  state: string | undefined
): Promise<Invoice[]> => {
  try {
    if (startDate == undefined) throw new Error("startDate is undefined");
    if (endDate == undefined) throw new Error("endDate is undefined");
    if (companyId === undefined && brokerId === undefined) throw new Error("companyId and brokerId are undefined");

    if (offlineModel.getOfflineState() === "offline") return [];

    const firmId: string = firmModel.getSelectedFirmId();

    let snapshotQuery: Query<Invoice> | undefined = query(
      invoiceModel.getPathReference().withConverter(invoiceModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("firm.id", "==", firmId)
    );

    if (companyId !== undefined) {
      if (companyId !== "all") {
        snapshotQuery = query(snapshotQuery, where("company.id", "==", companyId));
      } else {
        snapshotQuery = query(snapshotQuery, where("broker", "==", null));
      }
    } else if (brokerId !== undefined) {
      if (brokerId !== "all") {
        snapshotQuery = query(snapshotQuery, where("broker.id", "==", brokerId));
      } else {
        snapshotQuery = query(snapshotQuery, where("company", "==", null));
      }
    }

    if (state !== undefined && state !== "all") snapshotQuery = query(snapshotQuery, where("state", "==", state));

    if (snapshotQuery == undefined) return [];

    const snapshot: QuerySnapshot<Invoice> = await getDocs(invoiceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getInvoicesWithFilters", { startDate, endDate, companyId, brokerId, state }, error);
    return [];
  }
};
