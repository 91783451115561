import { Clause } from "@/core/modules/firestore/objects/Clause";
import { Examination } from "../objects/Examination";
import { examinationModel } from "../models/ExaminationModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const examinationSearch: SearchModule = new SearchModule(
  "examination.examination",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Examination).codeDisplay ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/examinations/${firestoreDocument.id}`,
  "heart",
  examinationModel,
  [new SortCriteria("codeSort", "desc", "string")],
  () => {
    const firmId: string = firmModel.getSelectedFirmId();
    return [new Clause("firm.id", "==", firmId)];
  }
);
