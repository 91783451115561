export class ArrayByKeyField {
  public static fromFirestore<T>(value: unknown, converter: (value: Record<string, unknown>) => T): Record<string, T> {
    if (value === null || value === undefined) return {};

    return Object.entries(value as Record<string, unknown>).reduce((newObject: Record<string, T>, [key, item]) => {
      newObject[key] = converter(item as Record<string, unknown>);
      return newObject;
    }, {});
  }

  public static toFirestore<T>(value: Record<string, T>, converter: (value: T) => Record<string, unknown>): Record<string, Record<string, unknown>> {
    return Object.entries(value).reduce((newObject: Record<string, Record<string, unknown>>, [key, item]) => {
      newObject[key] = converter(item);
      return newObject;
    }, {});
  }

  public static fromOfflineCache<T>(value: unknown, converter: (value: Record<string, unknown>) => T): Record<string, T> {
    return ArrayByKeyField.fromFirestore<T>(value, converter);
  }

  public static toOfflineCache<T>(
    value: Record<string, T>,
    converter: (value: T) => Record<string, unknown>
  ): Record<string, Record<string, unknown>> {
    return ArrayByKeyField.toFirestore<T>(value, converter);
  }

  public static getIds(value: unknown): string[] {
    return Object.values(value as Record<string, unknown>).map((item) => (item as Record<string, string>).id as string);
  }
}
