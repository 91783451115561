import { Clause } from "@/core/modules/firestore/objects/Clause";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "../objects/Invoice";
import { invoiceModel } from "../models/InvoiceModel";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const invoiceSearch: SearchModule = new SearchModule(
  "invoice.invoice",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Invoice).codeDisplay ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/invoices/${firestoreDocument.id}/edit`,
  "dollar",
  invoiceModel,
  [new SortCriteria("codeSort", "desc", "string")],
  () => {
    const firmId: string = firmModel.getSelectedFirmId();
    return [new Clause("firm.id", "==", firmId)];
  }
);
