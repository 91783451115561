import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";

export const setAssistState = (state: "connected" | "disconnected"): void => {
  try {
    localStorage.setItem("koruAssistState", state);

    eventBus.emit("assistToggleStateComponent", state);
    eventBus.emit("assistToggleStateView", state);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.setAssistState", { state }, error);
  }
};
