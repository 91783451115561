import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export const getCacheByCollection = async <T extends FirestoreOfflineDocument>(module: OfflineModuleModel<T>): Promise<T[]> => {
  try {
    const rawCache: unknown[] | undefined = await indexedDbModel.getDocuments(module.collectionName);
    if (rawCache === undefined) return [];

    return rawCache.map((rawCacheItem) => {
      const cacheObject: Record<string, unknown> = rawCacheItem as Record<string, unknown>;

      const cacheDocument: T = module.newIstance();
      cacheDocument.fromOfflineCache(cacheObject);

      return cacheDocument;
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.getCacheByCollection", { module }, error);
    return [];
  }
};
