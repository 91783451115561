import { deleteField } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Contract } from "@/features/modules/contract/objects/Contract";
import { contractModel } from "../ContractModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { LinkedContract } from "@/features/modules/contract/objects/LinkedContract";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const updateContract = async (contract: Contract): Promise<void> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (contractModel.lockPolicy === LockPolicy.DiscardUnsyncedChanges) {
      const oldContract: Contract = await contractModel.getDocument(contract.id);
      if (contract.hasChangedFrom(oldContract)) throw new Error("sync");
    }

    const user: User = store.getters[storeTypes.getters.getUser];
    // check if user can update the document
    if (user.canUpdate(contractModel.roleModule, contract) === false)
      throw new Error(`Unable to update document #${contract.id} in collection ${contractModel.collectionName}`);

    contract.setSearchKeys();
    contract.setTimestampFields("update");
    batch.set(contractModel.getDocumentReference(contract.id), contract.toFirestore());

    if (contract.firm === undefined) throw new Error("firmUndefined");
    const firmId: string = contract.firm.id;

    if (contract.isCurrentlyActive()) {
      await updateActiveContract(contract, firmId);
    } else {
      await deleteActiveContract(contract, firmId);
    }

    await batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.updateContract", { contract }, error);
  }
};

const updateActiveContract = async (contract: Contract, firmId: string): Promise<void> => {
  try {
    const oldContract: Contract = await contractModel.getDocument(contract.id);
    const linkedContract: LinkedContract = LinkedContract.createFromContract(contract);

    const oldBranches: string[] = oldContract.getLinkedBranches().map((branch) => branch.id);
    const newBranches: string[] = contract.getLinkedBranches().map((branch) => branch.id);
    const removeBranches: string[] = oldBranches.filter((value) => !newBranches.includes(value));

    const user: User = store.getters[storeTypes.getters.getUser];

    if (contract.doctor === undefined) throw new Error("doctorUndefined");

    // company update
    if (contract.company !== undefined) {
      const company: Company = await companyModel.getDocument(contract.company.id);

      // check if user can update the document
      if (user.canUpdate(companyModel.roleModule, company) === false)
        throw new Error(`Unable to update document #${company.id} in collection ${companyModel.collectionName}`);

      if (company.authorizedDoctorsIds.includes(contract.doctor.id) === false) {
        company.authorizedDoctorsIds.push(contract.doctor.id);
      }

      batch.update(companyModel.getDocumentReference(contract.company.id), {
        [`activeContracts.${firmId}.${linkedContract.id}`]: linkedContract.toFirestore(),
        authorizedDoctorsIds: company.authorizedDoctorsIds,
      });

      if (oldContract.company !== undefined && oldContract.company.id !== contract.company?.id) {
        batch.update(companyModel.getDocumentReference(oldContract.company.id), {
          [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
        });
      }
      // doctor update
      if (contract.doctor !== undefined) {
        const doctor: Doctor = await doctorModel.getDocument(contract.doctor.id);

        // check if user can update the document
        if (user.canUpdate(doctorModel.roleModule, doctor) === false)
          throw new Error(`Unable to update document #${doctor.id} in collection ${doctorModel.collectionName}`);

        if (doctor.authorizedCompaniesIds.includes(contract.company.id) === false) {
          doctor.authorizedCompaniesIds.push(contract.company.id);
        }

        batch.update(doctorModel.getDocumentReference(contract.doctor.id), { authorizedCompaniesIds: doctor.authorizedCompaniesIds });
      }
    }

    // branch update
    for (const linkedBranch of contract.getLinkedBranches()) {
      const branch: Branch = await branchModel.getDocument(linkedBranch.id);

      // check if user can update the document
      if (user.canUpdate(branchModel.roleModule, branch) === false)
        throw new Error(`Unable to update document #${branch.id} in collection ${branchModel.collectionName}`);

      batch.update(branchModel.getDocumentReference(branch.id), {
        [`activeContracts.${firmId}.${linkedContract.id}`]: linkedContract.toFirestore(),
      });
    }

    // branch delete
    for (const branchId of removeBranches) {
      const branch: Branch = await branchModel.getDocument(branchId);

      // check if user can update the document
      if (user.canUpdate(branchModel.roleModule, branch) === false)
        throw new Error(`Unable to update document #${branch.id} in collection ${branchModel.collectionName}`);

      batch.update(branchModel.getDocumentReference(branchId), {
        [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
      });
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.updateActiveContract", { contract, firmId }, error);
  }
};

const deleteActiveContract = async (contract: Contract, firmId: string): Promise<void> => {
  try {
    const oldContract: Contract = await contractModel.getDocument(contract.id);

    const user: User = store.getters[storeTypes.getters.getUser];

    if (contract.doctor === undefined) throw new Error("doctorUndefined");

    // company
    if (contract.company !== undefined) {
      const company: Company = await companyModel.getDocument(contract.company.id);

      let doctorHasOtherContracts = false;
      for (const firmId of Object.keys(company.activeContracts.getRecord())) {
        const firmActiveContracts = company.activeContracts.getActiveContractsByFirm(firmId);
        if (firmActiveContracts === undefined) continue;
        for (const loopContract of firmActiveContracts) {
          if (loopContract.id === contract.id) continue;
          if (loopContract.doctor === undefined) continue;
          if (loopContract.doctor.id === contract.doctor.id) {
            doctorHasOtherContracts = true;
          }
        }
      }

      // check if user can update the document
      if (user.canUpdate(companyModel.roleModule, company) === false)
        throw new Error(`Unable to update document #${company.id} in collection ${companyModel.collectionName}`);

      if (doctorHasOtherContracts === true) {
        batch.update(companyModel.getDocumentReference(contract.company.id), {
          [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
        });
      } else {
        if (company.authorizedDoctorsIds.includes(contract.doctor.id) === true) {
          company.authorizedDoctorsIds.splice(company.authorizedDoctorsIds.indexOf(contract.doctor.id), 1);
        }

        batch.update(companyModel.getDocumentReference(contract.company.id), {
          [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
          authorizedDoctorsIds: company.authorizedDoctorsIds,
        });

        // doctor
        if (contract.doctor !== undefined) {
          const doctor: Doctor = await doctorModel.getDocument(contract.doctor.id);

          if (doctor.authorizedCompaniesIds.includes(contract.company.id) === true) {
            doctor.authorizedCompaniesIds.splice(doctor.authorizedCompaniesIds.indexOf(contract.company.id), 1);
          }

          batch.update(doctorModel.getDocumentReference(contract.doctor.id), { authorizedCompaniesIds: doctor.authorizedCompaniesIds });
        }
      }
    }

    // branch
    for (const linkedBranch of oldContract.getLinkedBranches()) {
      const branch: Branch = await branchModel.getDocument(linkedBranch.id);

      // check if user can update the document
      if (user.canUpdate(branchModel.roleModule, branch) === false)
        throw new Error(`Unable to update document #${branch.id} in collection ${branchModel.collectionName}`);

      batch.update(branchModel.getDocumentReference(branch.id), {
        [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
      });
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.deleteActiveContract", { contract, firmId }, error);
  }
};
