import { RouteRecordRaw } from "vue-router";

import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { SupplierDocHelpers } from "../helpers/SupplierDocHelpers";
import { supplierDocModel } from "../models/SupplierDocModel";

export const supplierDocRoutes: RouteRecordRaw[] = [
  {
    path: "/suppliers/:sourceId/docs/:docId/edit",
    name: "SupplierDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: SupplierDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Supplier,
      sourceModel: supplierDocModel,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "supplierDoc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/suppliers/:sourceId/docs/:docId",
    name: "SupplierDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      filesPath: SupplierDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Supplier,
      sourceId: route.params.sourceId,
      sourceModel: supplierDocModel,
      sourcePath: "suppliers",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "supplierDoc",
      requiredRight: "viewUI",
    },
  },
];
