import { DocumentReference } from "firebase/firestore";

import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";

import { AddressField, BooleanField, StringField } from "@/core/fields";

export class Firm extends FirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public businessName: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public vatCode: string | undefined = undefined;
  public fiscalCode: string | undefined = undefined;
  public phone: string | undefined = undefined;
  public email: string | undefined = undefined;
  public accountingEmail: string | undefined = undefined;
  public hasWithHoldingTax = false;
  public paymentRecipient: string | undefined = undefined;
  public paymentBank: string | undefined = undefined;
  public paymentIban: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Firm {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.businessName = StringField.fromFirestore(data.businessName);
    this.address.fromFirestore(data.address);
    this.vatCode = StringField.fromFirestore(data.vatCode);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.phone = StringField.fromFirestore(data.phone);
    this.email = StringField.fromFirestore(data.email);
    this.accountingEmail = StringField.fromFirestore(data.accountingEmail);
    this.hasWithHoldingTax = BooleanField.fromFirestore(data.hasWithHoldingTax);
    this.paymentRecipient = StringField.fromFirestore(data.paymentRecipient);
    this.paymentBank = StringField.fromFirestore(data.paymentBank);
    this.paymentIban = StringField.fromFirestore(data.paymentIban);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.businessName = StringField.toFirestore(this.businessName);
    firestoreData.address = this.address.toFirestore();
    firestoreData.vatCode = StringField.toFirestore(this.vatCode);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.accountingEmail = StringField.toFirestore(this.accountingEmail);
    firestoreData.hasWithHoldingTax = BooleanField.toFirestore(this.hasWithHoldingTax);
    firestoreData.paymentRecipient = StringField.toFirestore(this.paymentRecipient);
    firestoreData.paymentBank = StringField.toFirestore(this.paymentBank);
    firestoreData.paymentIban = StringField.toFirestore(this.paymentIban);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Firm {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.businessName = StringField.fromOfflineCache(data.businessName);
    this.address.fromOfflineCache(data.address);
    this.vatCode = StringField.fromOfflineCache(data.vatCode);
    this.fiscalCode = StringField.fromOfflineCache(data.fiscalCode);
    this.phone = StringField.fromOfflineCache(data.phone);
    this.email = StringField.fromOfflineCache(data.email);
    this.accountingEmail = StringField.fromOfflineCache(data.accountingEmail);
    this.hasWithHoldingTax = BooleanField.fromOfflineCache(data.hasWithHoldingTax);
    this.paymentRecipient = StringField.fromOfflineCache(data.paymentRecipient);
    this.paymentBank = StringField.fromOfflineCache(data.paymentBank);
    this.paymentIban = StringField.fromOfflineCache(data.paymentIban);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.businessName = StringField.toOfflineCache(this.businessName);
    firestoreData.address = this.address.toOfflineCache();
    firestoreData.vatCode = StringField.toOfflineCache(this.vatCode);
    firestoreData.fiscalCode = StringField.toOfflineCache(this.fiscalCode);
    firestoreData.phone = StringField.toOfflineCache(this.phone);
    firestoreData.email = StringField.toOfflineCache(this.email);
    firestoreData.accountingEmail = StringField.toOfflineCache(this.accountingEmail);
    firestoreData.hasWithHoldingTax = BooleanField.toOfflineCache(this.hasWithHoldingTax);
    firestoreData.paymentRecipient = StringField.toOfflineCache(this.paymentRecipient);
    firestoreData.paymentBank = StringField.toOfflineCache(this.paymentBank);
    firestoreData.paymentIban = StringField.toOfflineCache(this.paymentIban);

    return firestoreData;
  }
}
