import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Supplier } from "@/features/modules/supplier/objects/Supplier";
import { supplierModel } from "@/features/modules/supplier/models/SupplierModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getExpiringSupplierDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const suppliers: Supplier[] = await supplierModel.getSuppliersWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("expiringDocs", DocTypeSource.Supplier);

    for (const supplier of suppliers) {
      if (supplier.trackExpirations === false) continue;

      const supplierWidget: Widget | undefined = widgets.find(
        (widget) => widget.source === DocTypeSource.Supplier && widget.sourceId === supplier.id
      );
      if (supplierWidget === undefined) continue;
      if (supplierWidget.values.count === 0) continue;
      if (supplierWidget.values.expirations == undefined) continue;

      const expirations: Record<string, Timestamp> = supplierWidget.values.expirations as Record<string, Timestamp>;
      for (const docTypeId of Object.keys(expirations)) {
        docExpirations.push(new DocExpiration(supplier.id, supplier.name ?? "-", expirations[docTypeId].toDate(), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("SupplierDocModel.getExpiringSupplierDocs", {}, error);
    return [];
  }
};
