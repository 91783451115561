import { signInWithEmailAndPassword, UserCredential } from "firebase/auth";

import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/UserModel";

export const login = async (email: string, password: string): Promise<User> => {
  const userCredential: UserCredential = await signInWithEmailAndPassword(firebase.auth, email, password);
  if (userCredential.user !== null) {
    const user: User = await userModel.getDocument(userCredential.user.uid);
    if (user.role === undefined || user.role.hasKoruAccess === false) {
      await firebase.auth.signOut();
      throw new Error("User doesn't have access to Koru");
    }
    offlineModel.setOfflineState("online");
    return user;
  }
  throw new Error("User credentials are null");
};
