import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12 lg:col-6" }
const _hoisted_3 = { class: "field col-12 lg:col-6" }
const _hoisted_4 = { class: "field col-12 lg:col-6" }
const _hoisted_5 = { class: "field col-12 lg:col-3" }
const _hoisted_6 = { class: "col-12 mt-4" }
const _hoisted_7 = { class: "field col-12 text-right mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("company.company")), 1),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.companyId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyId) = $event)),
        options: _ctx.companies,
        optionLabel: "name",
        optionValue: "id",
        placeholder: "-",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.companyChanged())),
        filter: true
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("branch.branch")), 1),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.branchId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.branchId) = $event)),
        options: _ctx.branches,
        optionLabel: "name",
        optionValue: "id",
        placeholder: "-",
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.branchChanged())),
        filter: true
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("companyDuty.companyDuties")), 1),
      _createVNode(_component_MultiSelect, {
        modelValue: _ctx.companyDutiesIds,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.companyDutiesIds) = $event)),
        options: _ctx.companyDuties,
        optionLabel: "name",
        optionValue: "id",
        placeholder: "-",
        showToggleAll: false
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("employeeDuty.to")), 1),
      _createVNode(_component_Calendar, {
        modelValue: _ctx.toDate,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.toDate) = $event)),
        minDate: new Date('01/01/1950'),
        showIcon: true,
        showOnFocus: false,
        placeholder: _ctx.t('employeeDuty.unlimited')
      }, null, 8, ["modelValue", "minDate", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_DataTable, {
        value: _ctx.employees,
        showGridlines: "",
        responsiveLayout: "scroll"
      }, {
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("gen.noData")), 1)
        ]),
        loading: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("gen.loading")), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "fullName",
            header: _ctx.t('employee.fullName'),
            style: {"width":"40%"}
          }, {
            body: _withCtx(({ data, field }) => [
              _createTextVNode(_toDisplayString(data[field] ?? "-"), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "fiscalCode",
            header: _ctx.t('employee.fiscalCode'),
            style: {"width":"40%"}
          }, {
            body: _withCtx(({ data, field }) => [
              _createTextVNode(_toDisplayString(data[field] ?? "-"), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "isAssignPossible",
            header: _ctx.t('gen.actions'),
            bodyClass: "text-center",
            style: {"width":"20%"}
          }, {
            body: _withCtx(({ data, field }) => [
              (data[field] === false)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    icon: "pi pi-times",
                    label: _ctx.t('utilityAssignEmployeeDuty.notAvailable'),
                    class: "p-button-danger"
                  }, null, 8, ["label"]))
                : (_ctx.selectedEmployeesIds.includes(data['id']))
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      icon: "pi pi-check",
                      label: _ctx.t('utilityAssignEmployeeDuty.selected'),
                      class: "p-button-primary",
                      onClick: ($event: any) => (_ctx.toggleEmployee(data['id']))
                    }, null, 8, ["label", "onClick"]))
                  : (_openBlock(), _createBlock(_component_Button, {
                      key: 2,
                      icon: "pi pi-arrow-right",
                      label: _ctx.t('utilityAssignEmployeeDuty.available'),
                      class: "p-button-success",
                      onClick: ($event: any) => (_ctx.toggleEmployee(data['id']))
                    }, null, 8, ["label", "onClick"]))
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Button, {
        label: _ctx.t('buttons.save'),
        icon: "pi pi-save",
        type: "button",
        class: "p-button-primary p-uppercase",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.assignDuty()))
      }, null, 8, ["label"])
    ])
  ]))
}