import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "../CompanyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getCompaniesWithTrackExpirations = async (): Promise<Company[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (companyModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];
      return documents.filter((company) => company.trackExpirations === true);
    }

    let snapshotQuery: Query<Company> = query(
      companyModel.getPathReference().withConverter(companyModel.firestoreConverter),
      where("trackExpirations", "==", true)
    );

    if (user.role?.name === "Medico") {
      snapshotQuery = query(snapshotQuery, where("authorizedDoctorsIds", "array-contains", user.id));
    } else if (user.role?.name === "Azienda") {
      snapshotQuery = query(snapshotQuery, where("userId", "==", user.id));
    }

    const snapshot: QuerySnapshot<Company> = await getDocs(companyModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyModel.getCompaniesWithTrackExpirations", {}, error);
    return [];
  }
};
