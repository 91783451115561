export const de = {
  who: {
    details1: "Wir entwickeln Software für Web und mobile Endgeräte.",
    details2: "Wir lieben gut geschriebenen Code und Teamarbeit.",
    details3: "Für weitere Informationen können Sie uns schreiben unter",
    who: "Wer wir sind",
  },
  navigation: {
    who: "Wer wir sind",
  },
};
