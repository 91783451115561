import { addDays, compareAsc } from "date-fns";
import { customAlphabet } from "nanoid";

import { AppHelpers } from "./AppHelpers";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class DataHelpers {
  /**
   * check if an array is contained in another array
   * @param superset the array that should contain the other array
   * @param subset the array that should be contained in the other array
   * @returns true if the array is contained in the other array
   */
  public static arrayContainsArray(superset: unknown[], subset: unknown[]): boolean {
    if (subset.length === 0) return false;
    return subset.every((value) => superset.includes(value));
  }

  /**
   * check if an array contains any subset of another array
   * @param superset the array that should contain the subset
   * @param subset the array that should be contained in the other array
   * @returns true if the array contains any subset of the other array
   */
  public static arrayContainsSubset(superset: unknown[], subset: unknown[]): boolean {
    if (subset.length === 0) return false;
    const supersetSet = new Set(superset);
    for (const subsetItem of subset) {
      if (supersetSet.has(subsetItem)) return true;
    }
    return false;
  }

  /**
   * convert a data url to a file
   * @param url the data url
   * @param fileName the file name
   * @param mimeType the mime type
   * @returns the file
   */
  public static async convertDataUrlToFile(url: string, fileName: string, mimeType: string) {
    const response: Response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();

    return new File([arrayBuffer], fileName, { type: mimeType });
  }

  /**
   * create a date range
   * @param from the start date
   * @param to the end date
   * @param step the step
   * @returns the date range
   */
  public static createDateRange(from: Date, to: Date, step = 1): Date[] {
    const datesArray: Date[] = [];
    for (let date = from; compareAsc(date, to) < 1; date = addDays(date, step)) {
      datesArray.push(date);
    }
    return datesArray;
  }

  /**
   * create a range
   * @param from the start value
   * @param to the end value
   * @param step the step
   * @returns the range
   */
  public static createRange(from: number, to: number, step = 1): number[] {
    const array: number[] = [];
    for (let i = from; i <= to; i = i + step) {
      array.push(i);
    }
    return array;
  }

  /**
   * create search keys from a text string
   * @param text the text string
   * @returns the search keys
   */
  public static createSearchKeys(text: string | undefined): string[] {
    if (text === undefined) return [];

    const searchKeys: string[] = [];

    const words: string[] = text
      .toLowerCase()
      .split(" ")
      .filter((word) => word.length >= 3);

    for (const word of words) {
      const normalizedWord: string = DataHelpers.normalizeString(word);
      // split normalized word in every possible combination that has at least 3 characters
      for (let i = 0; i < normalizedWord.length - 2; i++) {
        for (let j = i + 3; j <= normalizedWord.length; j++) {
          const searchKey = normalizedWord.substring(i, j);
          if (searchKeys.includes(searchKey) === false) searchKeys.push(searchKey);
        }
      }
    }

    return searchKeys;
  }

  /**
   * encode a string for url
   * @param stringToEncode the string to encode
   * @returns the encoded string
   */
  public static encodeStringForUrl(stringToEncode: string): string {
    return encodeURIComponent(stringToEncode.toLowerCase()).replace(/\//g, "%2F").replace(/\?/g, "%3F");
  }

  /**
   * get a nested object value by key
   * @param object the object
   * @param key the nested key
   * @returns the value
   */
  public static getObjectValueByNestedKey(object: unknown, key: string): unknown {
    const keys: string[] = key.split(".");

    if (keys.length === 1) {
      return (<never>object)[key];
    } else {
      let inceptionObject: unknown = object;
      for (const loopKey of keys) {
        inceptionObject = (<never>inceptionObject)[loopKey];
      }
      return inceptionObject;
    }
  }

  /**
   * convert millimeters to points
   * @param mm the millimeters to convert
   * @returns the value in points
   */
  public static mmToPoints(mm: number): number {
    const MILLIMETERS_IN_INCH = 25.4;
    const POINTS_IN_INCH = 72;

    const inches = mm / MILLIMETERS_IN_INCH;
    return inches * POINTS_IN_INCH;
  }

  /**
   * move an array element
   * @param array the array
   * @param oldIndex the old index
   * @param newIndex the new index
   */
  public static moveArrayElement(array: unknown[], oldIndex: number, newIndex: number) {
    if (newIndex >= array.length) {
      let k: number = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  /**
   * normalize a string
   * @param string the string to normalize
   * @returns the normalized string
   */
  public static normalizeString(string: string): string {
    return string
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9]/gi, "");
  }

  /**
   * transform an object to a sorted array
   * @param object the object to transform
   * @param sortCriteria the sort criterias to apply
   * @returns the sorted array
   */
  public static objectToSortedArray<T>(object: Record<string, T>, sortCriteria = new SortCriteria("order", "asc", "number")): T[] {
    const sorter: FirestoreSorter<T> = new FirestoreSorter(Object.values(object));
    sorter.setSortCriterias([sortCriteria]);
    return sorter.sort();
  }

  /**
   * get a random element from an array
   * @param array the array
   * @returns the random element
   */
  public static randomElementFromArray<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
  }

  /**
   * get a random integer between min and max
   * @param min the min value
   * @param max the max value
   * @returns the random integer
   */
  public static randomIntBetween(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static async resizeImageWithCenteredCrop(file: File, targetWidth: number, targetHeight: number): Promise<string | undefined> {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = document.createElement("img");

    let resizedImageData: string | undefined = undefined;

    img.onload = function () {
      let sourceWidth = img.width;
      let sourceHeight = img.height;
      let sourceCropX = 0;
      let sourceCropY = 0;

      const sourceRatio = sourceWidth / sourceHeight;
      const targetRatio = targetWidth / targetHeight;

      if (sourceRatio >= targetRatio) {
        sourceWidth = sourceHeight * targetRatio;
        sourceCropX = Math.max((img.width - sourceWidth) / 2, 0);
      } else {
        sourceHeight = sourceWidth / targetRatio;
        sourceCropY = Math.max((img.height - sourceHeight) / 2, 0);
      }

      canvas.width = targetWidth;
      canvas.height = targetHeight;
      context?.drawImage(img, sourceCropX, sourceCropY, sourceWidth, sourceHeight, 0, 0, targetWidth, targetHeight);
      resizedImageData = canvas.toDataURL("image/jpeg");
    };
    img.src = URL.createObjectURL(file);
    await AppHelpers.delay(1000);
    return resizedImageData;
  }

  /**
   * round a number by given digits
   * @param number the number to round
   * @param digits the number of digits
   * @returns the rounded number
   */
  public static roundNumber(number: number, digits: number): number {
    const pow = Math.pow(10, digits);
    return Math.round((number + Number.EPSILON) * pow) / pow;
  }

  /**
   * remove an object from an array by value
   * @param array the array
   * @param object the object to remove
   * @param key the key to compare
   */
  public static removeFromArrayByObjectValueWithKey(array: unknown[], object: unknown, key: string): void {
    array.splice(
      (array as Record<string, unknown>[]).findIndex((item) => item[key] === DataHelpers.getObjectValueByNestedKey(object, key)),
      1
    );
  }

  /**
   * slugify a string
   * @param args the array of strings or numbers to slugify
   * @returns the slugified string
   */
  public static slugify(...args: (string | number)[]): string {
    const value = args.join(" ");
    return value
      .normalize("NFD") // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, "-"); // separator
  }

  /**
   * transform a sorted array to an object
   * @param sortedArray the sorted array to transform
   * @returns the object
   */
  public static sortedArrayToObject<T>(sortedArray: T[]): Record<string, T> {
    const object: Record<string, T> = {};
    for (const item of sortedArray) {
      object[item["id" as keyof T] as string] = item;
    }
    return object;
  }

  /**
   * sort an object by keys
   * @param object the object to sort
   * @returns the sorted object
   */
  public static sortObjectKeys<T>(object: Record<string, T>): Record<string, T> {
    return Object.keys(object)
      .sort()
      .reduce((obj: Record<string, T>, key: string) => {
        obj[key] = object[key];
        return obj;
      }, {});
  }

  /**
   * get a unique id
   * @param length the length of the id
   * @returns the unique id
   */
  public static uniqueId(length = 16): string {
    const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", length);
    return nanoid();
  }
}
