import { RouteRecordRaw } from "vue-router";

export const companyPriceListRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:companyId/price-lists/:companyPriceListId/edit",
    name: "CompanyPriceListEdit",
    component: () => import("../views/CompanyPriceListEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "companyPriceList",
      requiredRight: "viewUI",
    },
  },
];
