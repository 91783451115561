import { branchOfflineModel } from "@/features/modules/branch/models/BranchOfflineModel";
import { companyDutyOfflineModel } from "@/features/modules/companyDuty/models/CompanyDutyOfflineModel";
import { companyOfflineModel } from "@/features/modules/company/models/CompanyOfflineModel";
import { contractOfflineModel } from "@/features/modules/contract/models/ContractOfflineModel";
import { doctorOfflineModel } from "@/features/modules/doctor/models/DoctorOfflineModel";
import { docTypeOfflineModel } from "@/features/modules/docType/models/DocTypeOfflineModel";
import { dutyOfflineModel } from "@/features/modules/duty/models/DutyOfflineModel";
import { employeeDutyOfflineModel } from "@/features/modules/employeeDuty/models/EmployeeDutyOfflineModel";
import { employeeOfflineModel } from "@/features/modules/employee/models/EmployeeOfflineModel";
import { employerOfflineModel } from "@/features/modules/employer/models/EmployerOfflineModel";
import { examTypeOfflineModel } from "@/features/modules/examType/models/ExamTypeOfflineModel";
import { examinationOfflineModel } from "@/features/modules/examination/models/ExaminationOfflineModel";
import { firmOfflineModel } from "@/features/modules/firm/models/FirmOfflineModel";
import { hintOfflineModel } from "@/features/modules/hint/models/HintOfflineModel";
import { limitationOfflineModel } from "@/features/modules/limitation/models/LimitationOfflineModel";
import { normOfflineModel } from "@/features/modules/norm/models/NormOfflineModel";
import { prescriptionOfflineModel } from "@/features/modules/prescription/models/PrescriptionOfflineModel";
import { riskOfflineModel } from "@/features/modules/risk/models/RiskOfflineModel";
import { surveyOfflineModel } from "@/features/modules/survey/models/SurveyOfflineModel";
import { testTypeCategoryOfflineModel } from "@/features/modules/testTypeCategory/models/TestTypeCategoryOfflineModel";
import { testTypeOfflineModel } from "@/features/modules/testType/models/TestTypeOfflineModel";
import { vaccinationOfflineModel } from "@/features/modules/vaccination/models/VaccinationOfflineModel";
import { vaccineTypeOfflineModel } from "@/features/modules/vaccineType/models/VaccineTypeOfflineModel";

export const featuresOfflineModules = [
  firmOfflineModel,
  // company and sons
  companyOfflineModel,
  branchOfflineModel,
  companyDutyOfflineModel,
  contractOfflineModel,
  employeeOfflineModel,
  employeeDutyOfflineModel,
  employerOfflineModel,
  // by employee
  vaccinationOfflineModel,
  // by firm and company
  examinationOfflineModel,
  // everything else
  doctorOfflineModel,
  docTypeOfflineModel,
  dutyOfflineModel,
  examTypeOfflineModel,
  hintOfflineModel,
  limitationOfflineModel,
  normOfflineModel,
  prescriptionOfflineModel,
  riskOfflineModel,
  surveyOfflineModel,
  testTypeCategoryOfflineModel,
  testTypeOfflineModel,
  vaccineTypeOfflineModel,
];
