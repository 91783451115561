import { compareAsc } from "date-fns";
import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedCompanyDuty } from "@/features/modules/companyDuty/objects/LinkedCompanyDuty";
import { LinkedRisk } from "@/features/modules/risk/objects/LinkedRisk";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";

import { ArrayByKeyField, DateField, NumberWithDefaultField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class EmployeeDuty extends FirestoreOfflineDocument {
  public company: LinkedCompany | undefined = undefined;
  public companyDuties: Record<string, LinkedCompanyDuty> = {};
  public from: Date | undefined = undefined;
  public fromSort = "0000-00-00";
  public to: Date | undefined = undefined;
  public toSort = "9999-99-99";
  public name: string | undefined = undefined;
  public frequency = 999;
  public risks: Record<string, LinkedRisk> = {};
  public risksNotes: string | undefined = undefined;
  public testTypes: Record<string, LinkedTestType> = {};
  public surveys: Record<string, LinkedSurvey> = {};
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): EmployeeDuty {
    super.fromFirestore(data, id, firestoreRef);

    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.companyDuties = ArrayByKeyField.fromFirestore<LinkedCompanyDuty>(data.companyDuties, (value) => new LinkedCompanyDuty(value));
    this.from = DateField.fromFirestore(data.from);
    this.fromSort = StringStrictField.fromFirestore(data.fromSort, "0000-00-00");
    this.to = DateField.fromFirestore(data.to);
    this.toSort = StringStrictField.fromFirestore(data.toSort, "9999-99-99");
    this.name = StringField.fromFirestore(data.name);
    this.frequency = NumberWithDefaultField.fromFirestore(data.frequency, 999);
    this.risks = ArrayByKeyField.fromFirestore<LinkedRisk>(data.risks, (value) => new LinkedRisk(value));
    this.risksNotes = StringField.fromFirestore(data.risksNotes);
    this.testTypes = ArrayByKeyField.fromFirestore<LinkedTestType>(data.testTypes, (value) => new LinkedTestType(value));
    this.surveys = ArrayByKeyField.fromFirestore<LinkedSurvey>(data.surveys, (value) => new LinkedSurvey(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.company = ObjectField.toFirestore(this.company, (value) => value.toFirestore());
    firestoreData.companyDuties = ArrayByKeyField.toFirestore(this.companyDuties, (value) => value.toFirestore());
    firestoreData.companyDutiesIds = ArrayByKeyField.getIds(this.companyDuties);
    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.frequency = NumberWithDefaultField.toFirestore(this.frequency, 999);
    firestoreData.risks = ArrayByKeyField.toFirestore<LinkedRisk>(this.risks, (value) => value.toFirestore());
    firestoreData.risksIds = ArrayByKeyField.getIds(this.risks);
    firestoreData.risksNotes = StringField.toFirestore(this.risksNotes);
    firestoreData.testTypes = ArrayByKeyField.toFirestore<LinkedTestType>(this.testTypes, (value) => value.toFirestore());
    firestoreData.testTypesIds = ArrayByKeyField.getIds(this.testTypes);
    firestoreData.surveys = ArrayByKeyField.toFirestore<LinkedSurvey>(this.surveys, (value) => value.toFirestore());
    firestoreData.surveysIds = ArrayByKeyField.getIds(this.surveys);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): EmployeeDuty {
    super.fromOfflineCache(data);

    this.parentId = StringField.fromOfflineCache(data.parentId);
    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => {
      const linkedCompany: LinkedCompany = new LinkedCompany();
      return linkedCompany.fromOfflineCache(value);
    });
    this.companyDuties = ArrayByKeyField.fromOfflineCache<LinkedCompanyDuty>(data.companyDuties, (value) => {
      const linkedCompanyDuty: LinkedCompanyDuty = new LinkedCompanyDuty();
      return linkedCompanyDuty.fromOfflineCache(value);
    });
    this.from = DateField.fromOfflineCache(data.from);
    this.fromSort = StringStrictField.fromOfflineCache(data.fromSort, "0000-00-00");
    this.to = DateField.fromOfflineCache(data.to);
    this.toSort = StringStrictField.fromOfflineCache(data.toSort, "9999-99-99");
    this.name = StringField.fromOfflineCache(data.name);
    this.frequency = NumberWithDefaultField.fromOfflineCache(data.frequency, 999);
    this.risks = ArrayByKeyField.fromOfflineCache<LinkedRisk>(data.risks, (value) => {
      const linkedRisk: LinkedRisk = new LinkedRisk();
      return linkedRisk.fromOfflineCache(value);
    });
    this.risksNotes = StringField.fromOfflineCache(data.risksNotes);
    this.testTypes = ArrayByKeyField.fromOfflineCache<LinkedTestType>(data.testTypes, (value) => {
      const linkedTestType: LinkedTestType = new LinkedTestType();
      return linkedTestType.fromOfflineCache(value);
    });
    this.surveys = ArrayByKeyField.fromOfflineCache<LinkedSurvey>(data.surveys, (value) => {
      const linkedSurvey: LinkedSurvey = new LinkedSurvey();
      return linkedSurvey.fromOfflineCache(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.parentId = this.firestoreRef !== undefined ? this.firestoreRef.parent.parent?.id : StringField.toOfflineCache(this.parentId);
    firestoreData.company = ObjectField.toOfflineCache(this.company, (value) => value.toOfflineCache());
    firestoreData.companyDuties = ArrayByKeyField.toOfflineCache(this.companyDuties, (value) => value.toOfflineCache());
    firestoreData.companyDutiesIds = ArrayByKeyField.getIds(this.companyDuties);
    firestoreData.from = DateField.toOfflineCache(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toOfflineCache(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.frequency = NumberWithDefaultField.toOfflineCache(this.frequency, 999);
    firestoreData.risks = ArrayByKeyField.toOfflineCache<LinkedRisk>(this.risks, (value) => value.toOfflineCache());
    firestoreData.risksIds = ArrayByKeyField.getIds(this.risks);
    firestoreData.risksNotes = StringField.toOfflineCache(this.risksNotes);
    firestoreData.testTypes = ArrayByKeyField.toOfflineCache<LinkedTestType>(this.testTypes, (value) => value.toOfflineCache());
    firestoreData.testTypesIds = ArrayByKeyField.getIds(this.testTypes);
    firestoreData.surveys = ArrayByKeyField.toOfflineCache<LinkedSurvey>(this.surveys, (value) => value.toOfflineCache());
    firestoreData.surveysIds = ArrayByKeyField.getIds(this.surveys);
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }

  public getCompanyDuties(): LinkedCompanyDuty[] {
    return DataHelpers.objectToSortedArray<LinkedCompanyDuty>(this.companyDuties);
  }

  public setCompanyDuties(linkedCompanyDuties: LinkedCompanyDuty[]): void {
    this.companyDuties = DataHelpers.sortedArrayToObject<LinkedCompanyDuty>(linkedCompanyDuties);
  }

  public addCompanyDuty(linkedCompanyDuty: LinkedCompanyDuty): void {
    this.companyDuties[linkedCompanyDuty.id] = linkedCompanyDuty;
  }

  public removeCompanyDuty(linkedCompanyDuty: LinkedCompanyDuty): void {
    delete this.companyDuties[linkedCompanyDuty.id];
  }

  public getLinkedRisks(): LinkedRisk[] {
    return DataHelpers.objectToSortedArray<LinkedRisk>(this.risks);
  }

  public setLinkedRisks(linkedRisks: LinkedRisk[]): void {
    this.risks = DataHelpers.sortedArrayToObject<LinkedRisk>(linkedRisks);
  }

  public addLinkedRisk(linkedRisk: LinkedRisk): void {
    linkedRisk.order = Object.keys(this.risks).length + 1;
    this.risks[linkedRisk.id] = linkedRisk;
  }

  public removeLinkedRisk(linkedRisk: LinkedRisk): void {
    delete this.risks[linkedRisk.id];
  }

  public getLinkedTestTypes(): LinkedTestType[] {
    return DataHelpers.objectToSortedArray<LinkedTestType>(this.testTypes);
  }

  public setLinkedTestTypes(linkedTestTypes: LinkedTestType[]): void {
    this.testTypes = DataHelpers.sortedArrayToObject<LinkedTestType>(linkedTestTypes);
  }

  public addLinkedTestType(linkedTestType: LinkedTestType): void {
    linkedTestType.order = Object.keys(this.testTypes).length + 1;
    this.testTypes[linkedTestType.id] = linkedTestType;
  }

  public removeLinkedTestType(linkedTestType: LinkedTestType): void {
    delete this.testTypes[linkedTestType.id];
  }

  public getLinkedSurveys(): LinkedSurvey[] {
    return DataHelpers.objectToSortedArray<LinkedSurvey>(this.surveys);
  }

  public setLinkedSurveys(linkedSurveys: LinkedSurvey[]): void {
    this.surveys = DataHelpers.sortedArrayToObject<LinkedSurvey>(linkedSurveys);
  }

  public addLinkedSurvey(linkedSurvey: LinkedSurvey): void {
    linkedSurvey.order = Object.keys(this.surveys).length + 1;
    this.surveys[linkedSurvey.id] = linkedSurvey;
  }

  public removeLinkedSurvey(linkedSurvey: LinkedSurvey): void {
    delete this.surveys[linkedSurvey.id];
  }

  public isCurrentlyActive(): boolean {
    const now: Date = new Date();
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, now) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(now, to) > 0) return false;
    }
    return true;
  }

  public isActiveOnDate(selectedDate: Date): boolean {
    selectedDate.setHours(0, 0, 0, 0);
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, selectedDate) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(selectedDate, to) > 0) return false;
    }
    return true;
  }
}
