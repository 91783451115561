import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { Service } from "@/features/modules/service/objects/Service";
import { serviceModel } from "../ServiceModel";
import { ServiceState } from "@/features/modules/service/objects/ServiceState";

export const getServicesToInvoiceByCompanyAndPeriodAndFirm = async (companyId: string, startDate: Date, endDate: Date): Promise<Service[]> => {
  try {
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const firmId: string = firmModel.getSelectedFirmId();

    if (offlineModel.getOfflineState() === "offline") return [];

    const snapshotQuery: Query<Service> = query(
      serviceModel.getPathReference().withConverter(serviceModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("invoice", "==", null),
      where("state", "==", ServiceState.Completed),
      where("company.id", "==", companyId)
    );

    const snapshot: QuerySnapshot<Service> = await getDocs(serviceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ServiceModel.getServicesToInvoiceByCompanyAndPeriodAndFirm", { companyId, startDate, endDate }, error);
    return [];
  }
};

function sortDocuments(documents: Service[]): Service[] {
  const sorter: FirestoreSorter<Service> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "string");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
