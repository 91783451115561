import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { OfflineEdit } from "@/core/modules/offline/objects/OfflineEdit";
import { offlineModel } from "../OfflineModel";
import { offlineModuleModels } from "@/core/modules/offline/objects/offlineModuleModels";
import { OfflineUpload } from "@/core/modules/offline/objects/OfflineUpload";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const uploadCache = async (): Promise<void> => {
  try {
    await offlineModel.exportEdits();

    const rawEdits: unknown[] | undefined = await indexedDbModel.getDocuments("offlineEdits");
    const globalOfflineEdits: OfflineEdit[] = rawEdits.map((rawEdit) => {
      const rawEditObject: Record<string, unknown> = rawEdit as Record<string, unknown>;
      const offlineEdit: OfflineEdit = new OfflineEdit();
      return offlineEdit.fromOfflineCache(rawEditObject);
    });

    const user: User = store.getters[storeTypes.getters.getUser];

    for (const offlineModuleModel of offlineModuleModels) {
      if (offlineModuleModel.requiredModule !== undefined && user.canRead(offlineModuleModel.requiredModule) === false) continue;

      const offlineEdits: OfflineEdit[] = globalOfflineEdits.filter(
        (offlineEdit: OfflineEdit) => offlineEdit.collectionName === offlineModuleModel.collectionName
      );

      for (const offlineEdit of offlineEdits) {
        const document: FirestoreOfflineDocument = offlineModuleModel.newIstance();
        let result = false;
        if (offlineEdit.type === "create") {
          result = await offlineModuleModel.createDocumentCallback(document.fromOfflineCache(offlineEdit.object));
        } else if (offlineEdit.type === "update") {
          result = await offlineModuleModel.updateDocumentCallback(document.fromOfflineCache(offlineEdit.object));
          if (result === true) await indexedDbModel.deleteDocument("offlineEdits", offlineEdit.id as string);
        } else if (offlineEdit.type === "delete") {
          result = await offlineModuleModel.deleteDocumentCallback(document.fromOfflineCache(offlineEdit.object));
        }
        if (result === true) await indexedDbModel.deleteDocument("offlineEdits", offlineEdit.id as string);
      }
    }

    // offline uploads
    const offlineUploads: OfflineUpload[] = (await indexedDbModel.getDocuments("offlineUploads")).map(
      (rawOfflineUpload) => new OfflineUpload(rawOfflineUpload)
    );
    for (const offlineUpload of offlineUploads) {
      try {
        await storageModel.uploadBase64(offlineUpload.path as string, offlineUpload.uniqueName as string, offlineUpload.content as string);
        await indexedDbModel.deleteDocument("offlineUploads", offlineUpload.id as string);
      } catch (error: unknown) {
        appFaultModel.catchAppError("OfflineModel.uploadCacheLoop", { offlineUpload }, error);
      }
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.uploadCache", {}, error);
  }
};
