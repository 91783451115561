import { RouteRecordRaw } from "vue-router";

export const riskRoutes: RouteRecordRaw[] = [
  {
    path: "/risks/:id/edit",
    name: "RiskEdit",
    component: () => import("../views/RiskEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "risk",
      requiredRight: "viewUI",
    },
  },
];
