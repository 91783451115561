
import { computed, defineComponent, WritableComputedRef } from "vue";

import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "KoruPdfDialog",
  props: {
    show: { type: Boolean, default: false },
    value: { type: String, default: "" },
  },
  emits: ["update:show"],
  setup(props, { emit }) {
    const { t } = useLocale();

    const isVisible: WritableComputedRef<boolean> = computed({
      get: () => props.show,
      set: (val) => emit("update:show", val),
    });

    const pdfValue = computed(() => props.value);

    return {
      isVisible,
      pdfValue,
      t,
    };
  },
});
