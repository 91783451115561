import { RouteRecordRaw } from "vue-router";

export const externalTestSessionRoutes: RouteRecordRaw[] = [
  {
    path: "/external-test-sessions",
    name: "ExternalTestSessionList",
    component: () => import("../views/ExternalTestSessionList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "externalTestSession",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/external-test-sessions/create/:companyId/:branchId/:expirationDate",
    name: "ExternalTestSessionCreate",
    component: () => import("../views/ExternalTestSessionCreate.vue"),
    props: (route) => ({
      companyId: route.params.companyId,
      branchId: route.params.branchId,
      expirationDate: route.params.expirationDate,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "examinationSession",
      requiredRight: "create",
    },
  },
  {
    path: "/external-test-sessions/:id/edit",
    name: "ExternalTestSessionEdit",
    component: () => import("../views/ExternalTestSessionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "externalTestSession",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/external-test-sessions/:id/:section?",
    name: "ExternalTestSessionView",
    component: () => import("../views/ExternalTestSessionView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "externalTestSession",
      requiredRight: "viewUI",
    },
  },
];
