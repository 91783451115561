export const it = {
  notification: {
    alert: "Hai {count} notifica da leggere | Hai {count} notifiche da leggere",
    none: "Nessuna notifica da leggere",
    notifications: "Notifiche",
    test: {
      groupName: "Gruppo di test",
      message: "Notifica di test",
    },
  },
  buttons: {
    notificationGoToUrl: "Vai alla pagina",
  },
};
