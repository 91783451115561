import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Contract } from "@/features/modules/contract/objects/Contract";
import { contractModel } from "../ContractModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getContractsByCompany = async (companyId: string): Promise<Contract[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (contractModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Contract[] = (await offlineModel.getCacheByCollection(contractModel.offlineModuleModel)) as Contract[];
      return sortDocuments(documents.filter((contract) => contract.company?.id === companyId));
    }

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(companyId) === false)
        throw new Error(`User doesn't have rights to read company #${companyId}`);
    }

    const snapshotQuery: Query<Contract> = query(
      contractModel.getPathReference().withConverter(contractModel.firestoreConverter),
      where("company.id", "==", companyId)
    );

    const snapshot: QuerySnapshot<Contract> = await getDocs(contractModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.getContractsByCompany", { companyId }, error);
    return [];
  }
};

function sortDocuments(documents: Contract[]): Contract[] {
  const sorter: FirestoreSorter<Contract> = new FirestoreSorter(documents);
  sorter.addSortCriteria("fromSort", "asc", "string");
  return sorter.sort();
}
