
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { assistModel } from "../models/AssistModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { websocketClient } from "../objects/WebsocketClient";

import AssistView from "../views/AssistView.vue";

export default defineComponent({
  name: "AssistSelector",
  components: {
    AssistView,
  },
  setup() {
    const { t } = useLocale();
    let eventBusId: string | undefined = undefined;

    const assistViewVisible: Ref<boolean> = ref(false);
    const linkClass: Ref<string> = ref("");

    onMounted(() => {
      eventBusId = eventBus.on("assistToggleStateComponent", async (state: "connected" | "disconnected" | undefined) => {
        if (state === undefined) return;
        linkClass.value = state === "connected" ? "text-primary" : "text-danger";
      });

      const state: "connected" | "disconnected" = assistModel.getAssistState();
      linkClass.value = state === "connected" ? "text-primary" : "text-danger";

      if (state === "connected") {
        websocketClient.startWebsocket(
          () => ToastHelpers.showToastWithSuccess("assistConnection", t),
          () => ToastHelpers.showToastWithError("assistConnection", "generic", t)
        );
      }
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      assistViewVisible,
      linkClass,
    };
  },
});
