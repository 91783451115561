import { documentId, Query, query, where } from "firebase/firestore";

import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { Firm } from "../objects/Firm";
import { firmOfflineModel } from "./FirmOfflineModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getFirmByName, getSelectedFirmId, setSelectedFirmId } from "./methods";

export class FirmModel extends FirestoreModel<Firm> {
  public constructor() {
    super(() => new Firm(), "firms", LockPolicy.DiscardUnsyncedChanges, "firm");
    // this.beforeDeleteFunction = "featuresFirmBeforeDelete";
    this.offlineModuleModel = firmOfflineModel;
  }

  public async getDocuments(): Promise<Firm[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    let customQuery: Query<Firm> | undefined;
    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined) throw new Error(`User #${user.id} doesn't have rights to read firms`);

      customQuery = query(
        this.getPathReference().withConverter(this.firestoreConverter),
        where(
          documentId(),
          "in",
          Object.values(doctor.firms).map((firm) => firm.id)
        )
      );
    }

    return super.getDocuments([new SortCriteria("name", "asc", "string")], undefined, customQuery);
  }

  public async getDocument(firmId: string): Promise<Firm> {
    return super.getDocument(firmId);
  }

  public async createDocument(firm: Firm): Promise<string> {
    return super.createDocument(firm);
  }

  public async updateDocument(firm: Firm): Promise<void> {
    return super.updateDocument(firm);
  }

  public async deleteDocument(firm: Firm): Promise<boolean> {
    return super.deleteDocument(firm);
  }

  public async getFirmByName(firmName: string): Promise<Firm | undefined> {
    return getFirmByName(firmName);
  }

  public getSelectedFirm(): Promise<Firm> {
    return super.getDocument(this.getSelectedFirmId());
  }

  public getSelectedFirmId(): string {
    return getSelectedFirmId();
  }

  public setSelectedFirmId(firmId: string): void {
    return setSelectedFirmId(firmId);
  }
}

export const firmModel: FirmModel = new FirmModel();
