import { ExternalTest } from "../objects/ExternalTest";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import {
  getExternalTestsToIncludeByCompanyAndEmployee,
  getExternalTestsToInvoiceByCompanyAndPeriodAndFirm,
  getExternalTestsToInvoiceByPeriodAndFirm,
  getExternalTestsWithFilters,
} from "./methods";

export class ExternalTestModel extends FirestoreModel<ExternalTest> {
  public constructor() {
    super(() => new ExternalTest(), "externalTests", LockPolicy.DiscardUnsyncedChanges, "externalTest");
  }

  public async getDocuments(): Promise<ExternalTest[]> {
    return super.getDocuments();
  }

  public async getDocument(externalTestId: string): Promise<ExternalTest> {
    return super.getDocument(externalTestId);
  }

  public async createDocument(externalTest: ExternalTest): Promise<string> {
    return super.createDocument(externalTest);
  }

  public async updateDocument(externalTest: ExternalTest): Promise<void> {
    return super.updateDocument(externalTest);
  }

  public async deleteDocument(externalTest: ExternalTest): Promise<boolean> {
    return super.deleteDocument(externalTest);
  }

  public async getExternalTestsToIncludeByCompanyAndEmployee(companyId: string, employeeId: string): Promise<ExternalTest[]> {
    return getExternalTestsToIncludeByCompanyAndEmployee(companyId, employeeId);
  }

  public async getExternalTestsToInvoiceByCompanyAndPeriodAndFirm(companyId: string, startDate: Date, endDate: Date): Promise<ExternalTest[]> {
    return getExternalTestsToInvoiceByCompanyAndPeriodAndFirm(companyId, startDate, endDate);
  }

  public async getExternalTestsToInvoiceByPeriodAndFirm(startDate: Date, endDate: Date): Promise<ExternalTest[]> {
    return getExternalTestsToInvoiceByPeriodAndFirm(startDate, endDate);
  }

  public async getExternalTestsWithFilters(
    startDate: Date,
    endDate: Date,
    companyId: string,
    supplierId: string,
    employeeId: string,
    state: string,
    invoiced: string
  ): Promise<ExternalTest[]> {
    return getExternalTestsWithFilters(startDate, endDate, companyId, supplierId, employeeId, state, invoiced);
  }
}

export const externalTestModel: ExternalTestModel = new ExternalTestModel();
