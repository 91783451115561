
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { downloadHealthFolderModel } from "../models/DownloadHealthFolderModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

export default defineComponent({
  name: "DownloadHealthFolderView",
  setup() {
    const { d, n, t } = useLocale();

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    /* custom */

    const companyId: Ref<string | undefined> = ref(undefined);
    const companies: Ref<Company[]> = ref([]);

    const employees: Ref<Employee[]> = ref([]);

    const selectedEmployees: Ref<Employee[]> = ref([]);

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const companyChanged = async () => {
      employees.value = [];
      if (companyId.value === undefined) return;

      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrError(
        async () => {
          employees.value = await employeeModel.getEmployeesByCompany(companyId.value as string);
          selectedEmployees.value = [...employees.value];
        },
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const downloadHealthFolder = async (): Promise<void> => {
      if (companyId.value === undefined) {
        ToastHelpers.showToastWithWarning("noCompany", t);
        return;
      }
      if (selectedEmployees.value === undefined || selectedEmployees.value.length === 0) {
        ToastHelpers.showToastWithWarning("noEmployees", t);
        return;
      }

      store.commit(storeTypes.mutations.loadingStart);

      const company: Company | undefined = companies.value.find((company) => company.id === companyId.value) as Company;
      if (company === undefined) {
        ToastHelpers.showToastWithWarning("noCompany", t);
        return;
      }

      await AppHelpers.tryOrToast(
        async () => {
          const zipFileContent: string = await downloadHealthFolderModel.createHealthFolder(company, selectedEmployees.value, d, n, t);
          const fileName: string = DataHelpers.normalizeString(company.name as string);
          AppHelpers.promptDownloadFromBase64(zipFileContent, `${fileName}.zip`, "application/zip");

          companyId.value = undefined;
          employees.value = [];
          selectedEmployees.value = [];
        },
        "utilityDone",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companyChanged,
      companyId,
      downloadHealthFolder,
      employees,
      selectedEmployees,
      t,
    };
  },
});
