
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";
import { KoruBrowserModule } from "../objects/KoruBrowserModule";

export default defineComponent({
  name: "Filters",
  props: {
    browserModule: {
      type: KoruBrowserModule,
      required: true,
    },
  },
  setup(props) {
    let eventBusId: string | undefined = undefined;

    const overlayPanel = ref();
    const overlayPanelToggle = (event?: Event) => {
      overlayPanel.value.toggle(event);
    };

    const filtersRef = computed(() => {
      return props.browserModule.filters.value.filter((f) => f.isToggable === true);
    });

    onMounted(() => {
      eventBusId = eventBus.on(`toggleFilterSelectors-${props.browserModule.suffix}`, (event?: Event) => Promise.resolve(overlayPanelToggle(event)));
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      filtersRef,
      overlayPanel,
      overlayPanelToggle,
    };
  },
});
