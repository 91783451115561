import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { Vaccination } from "../objects/Vaccination";
import { vaccinationModel } from "./VaccinationModel";

export class VaccinationOfflineModel extends OfflineModuleModel<Vaccination> {
  public constructor() {
    super(() => new Vaccination(), "vaccinations", "vaccination.vaccinations", "vaccination");
  }

  public async downloadCache(): Promise<Vaccination[]> {
    try {
      if (employeeModel.offlineModuleModel === undefined) return [];
      const employees: Employee[] = (await offlineModel.getCacheByCollection(employeeModel.offlineModuleModel)) as Employee[];

      let vaccinations: Vaccination[] = [];
      for (const employee of employees) {
        vaccinations = [...vaccinations, ...(await vaccinationModel.getDocuments([], employee.id))];
      }

      return vaccinations;
    } catch (error: unknown) {
      appFaultModel.catchAppError("VaccinationOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const vaccinationOfflineModel: VaccinationOfflineModel = new VaccinationOfflineModel();
