export const it = {
  survey: {
    formName: "Nome scheda associata",
    formNameError: "Inserire un nome scheda valido",
    frequency: "Periodicità",
    frequencyError: "Selezionare una perdiodicità",
    name: "Nome",
    nameError: "Inserire un nome valido",
    survey: "Questionario",
    surveys: "Questionari",
  },
  navigation: {
    survey: "Questionari",
  },
};
