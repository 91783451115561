import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ServiceState } from "@/features/modules/service/objects/ServiceState";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Service } from "@/features/modules/service/objects/Service";
import { serviceModel } from "../ServiceModel";

export const getDraftServicesByFirm = async (): Promise<Service[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<Service> = query(
      serviceModel.getPathReference().withConverter(serviceModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("state", "==", ServiceState.Draft)
    );

    const snapshot: QuerySnapshot<Service> = await getDocs(serviceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ServiceModel.getDraftServicesByFirm", {}, error);
    return [];
  }
};

function sortDocuments(documents: Service[]): Service[] {
  const sorter: FirestoreSorter<Service> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "date");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
