import { Contract } from "./Contract";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";

import { DateField, ObjectField } from "@/core/fields";

export class LinkedContract extends LinkedFirestoreOfflineDocument {
  public firm: LinkedFirm | undefined = undefined;
  public doctor: LinkedDoctor | undefined = undefined;
  public to: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromContract(contract: Contract): LinkedContract {
    const linkedContract: LinkedContract = new LinkedContract();
    linkedContract.id = contract.id;
    linkedContract.firm = contract.firm;
    linkedContract.doctor = contract.doctor;
    linkedContract.to = contract.to;

    return linkedContract;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedContract {
    super.fromFirestore(data);

    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.doctor = ObjectField.fromFirestore<LinkedDoctor>(data.doctor, (value) => new LinkedDoctor(value));
    this.to = DateField.fromFirestore(data.to);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.doctor = ObjectField.toFirestore<LinkedDoctor>(this.doctor, (value) => value.toFirestore());
    firestoreData.to = DateField.toFirestore(this.to);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedContract {
    super.fromOfflineCache(data);

    this.firm = ObjectField.fromOfflineCache<LinkedFirm>(data.firm, (value) => {
      const firm: LinkedFirm = new LinkedFirm();
      return firm.fromOfflineCache(value);
    });
    this.doctor = ObjectField.fromOfflineCache<LinkedDoctor>(data.doctor, (value) => {
      const doctor: LinkedDoctor = new LinkedDoctor();
      return doctor.fromOfflineCache(value);
    });
    this.to = DateField.fromOfflineCache(data.to);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.firm = ObjectField.toOfflineCache<LinkedFirm>(this.firm, (value) => value.toOfflineCache());
    firestoreData.doctor = ObjectField.toOfflineCache<LinkedDoctor>(this.doctor, (value) => value.toOfflineCache());
    firestoreData.to = DateField.toOfflineCache(this.to);

    return firestoreData;
  }
}
