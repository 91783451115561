import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { PriceList } from "../objects/PriceList";

import { clonePriceList, getPriceListsByFirm } from "./methods";

export class PriceListModel extends FirestoreModel<PriceList> {
  public constructor() {
    super(() => new PriceList(), "priceLists", LockPolicy.DiscardUnsyncedChanges, "priceList");
    this.beforeDeleteFunction = "featuresPriceListBeforeDelete";
  }

  public async getDocuments(): Promise<PriceList[]> {
    return this.getPriceListsByFirm();
  }

  public async getDocument(priceListId: string): Promise<PriceList> {
    return super.getDocument(priceListId);
  }

  public async createDocument(priceList: PriceList): Promise<string> {
    await priceList.setFirm();
    priceList.sortPayments();

    return super.createDocument(priceList);
  }

  public async updateDocument(priceList: PriceList): Promise<void> {
    priceList.sortPayments();

    return super.updateDocument(priceList);
  }

  public async deleteDocument(priceList: PriceList): Promise<boolean> {
    return super.deleteDocument(priceList);
  }

  public async clonePriceList(priceList: PriceList): Promise<string> {
    return clonePriceList(priceList);
  }

  public async getPriceListsByFirm(): Promise<PriceList[]> {
    return getPriceListsByFirm();
  }
}

export const priceListModel: PriceListModel = new PriceListModel();
