import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { Role } from "./Role";
import { RoleRight } from "./RoleRight";

import { ArrayByKeyField, BooleanField, StringField } from "@/core/fields";

export class LinkedRole extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;
  public hasKoruAccess = true;
  public roleRights: Record<string, RoleRight> = {};

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromRole(role: Role): LinkedRole {
    const linkedRole: LinkedRole = new LinkedRole();
    linkedRole.id = role.id;
    linkedRole.name = role.name;
    linkedRole.hasKoruAccess = role.hasKoruAccess;
    linkedRole.roleRights = role.roleRights;

    return linkedRole;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedRole {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.hasKoruAccess = BooleanField.fromFirestore(data.hasKoruAccess);
    this.roleRights = ArrayByKeyField.fromFirestore<RoleRight>(data.roleRights, (value) => new RoleRight(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.hasKoruAccess = BooleanField.toFirestore(this.hasKoruAccess);
    firestoreData.roleRights = ArrayByKeyField.toFirestore(this.roleRights, (value) => value.toFirestore());

    return firestoreData;
  }
}
