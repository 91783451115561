
import { defineComponent, onMounted } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { storeTypes } from "@/core/modules/store/types";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "KoruViewer",
  props: {
    breadcrumbs: { type: Object, default: undefined },
    editRoute: { type: String },
    loadAction: { type: Function },
  },
  emits: ["update:sectionIndex"],
  setup(props) {
    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          if (props.loadAction != undefined) await props.loadAction();
        },
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => handleLoad());
  },
});
