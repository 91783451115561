import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";

import { StringArrayField, StringField } from "@/core/fields";

export class Notification extends FirestoreDocument {
  public groupId: string | undefined = undefined;
  public message: string | undefined = undefined;
  public url: string | undefined = undefined;
  public owners: string[] = [];
  public unread: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Notification {
    super.fromFirestore(data, id, firestoreRef);

    this.groupId = StringField.fromFirestore(data.groupId);
    this.message = StringField.fromFirestore(data.message);
    this.url = StringField.fromFirestore(data.url);
    this.owners = StringArrayField.fromFirestore(data.owners);
    this.unread = StringArrayField.fromFirestore(data.unread);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.groupId = StringField.toFirestore(this.groupId);
    firestoreData.message = StringField.toFirestore(this.message);
    firestoreData.url = StringField.toFirestore(this.url);
    firestoreData.owners = StringArrayField.toFirestore(this.owners);
    firestoreData.unread = StringArrayField.toFirestore(this.unread);

    return firestoreData;
  }
}
