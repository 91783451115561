import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "../DoctorModel";

export const getDoctorFromUser = async (userId: string): Promise<Doctor | undefined> => {
  try {
    const snapshotQuery: Query<Doctor> = query(
      doctorModel.getPathReference().withConverter(doctorModel.firestoreConverter),
      where("userId", "==", userId)
    );

    const snapshot: QuerySnapshot<Doctor> = await getDocs(doctorModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorModel.getDoctorFromUser", { userId }, error);
    return undefined;
  }
};
