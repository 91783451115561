import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyDuty } from "../objects/CompanyDuty";
import { companyDutyOfflineModel } from "./CompanyDutyOfflineModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Duty } from "@/features/modules/duty/objects/Duty";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { cascadeUpdateCompanyDuty, exportDuty, getActiveCompanyDuties, importDuty } from "./methods";

export class CompanyDutyModel extends FirestoreModel<CompanyDuty> {
  public constructor() {
    super(() => new CompanyDuty(), "companyDuties", LockPolicy.DiscardUnsyncedChanges, "companyDuty");
    this.parentCollectionName = "companies";
    this.beforeDeleteFunction = "featuresCompanyDutyBeforeDelete";
    this.offlineModuleModel = companyDutyOfflineModel;
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<CompanyDuty[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(parentId) === false)
        throw new Error(`User doesn't have rights to read company #${parentId}`);
    }

    return super.getDocuments([new SortCriteria("name", "asc", "string")], parentId);
  }

  public async getDocument(companyDutyId: string, parentId?: string): Promise<CompanyDuty> {
    return super.getDocument(companyDutyId, parentId);
  }

  public async createDocument(companyDuty: CompanyDuty, parentId?: string): Promise<string> {
    try {
      await companyDuty.handleArchive();

      return super.createDocument(companyDuty, parentId);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyDutyModel.createCompanyDuty", { companyDuty, parentId }, error);
      return "ERROR";
    }
  }

  public async updateDocument(companyDuty: CompanyDuty, parentId?: string): Promise<void> {
    try {
      await companyDuty.handleArchive();

      return super.updateDocument(companyDuty, parentId);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyDutyModel.updateCompanyDuty", { companyDuty, parentId }, error);
    }
  }

  public async deleteDocument(companyDuty: CompanyDuty, parentId?: string): Promise<boolean> {
    return super.deleteDocument(companyDuty, parentId);
  }

  public async cascadeUpdateCompanyDuty(companyDuty: CompanyDuty, companyId: string): Promise<void> {
    return cascadeUpdateCompanyDuty(companyDuty, companyId);
  }

  public async exportDuty(duty: Duty, importableDuties: Duty[]): Promise<string> {
    return exportDuty(duty, importableDuties);
  }

  public async getActiveCompanyDuties(parentId: string): Promise<CompanyDuty[]> {
    return getActiveCompanyDuties(parentId);
  }

  public async importDuty(companyDuties: Duty[], companyId: string, importableDuties: Duty[], importDutyId: string): Promise<string> {
    return importDuty(companyDuties, companyId, importableDuties, importDutyId);
  }
}

export const companyDutyModel: CompanyDutyModel = new CompanyDutyModel();
