import { SurveyForm } from "./SurveyForm";

import { BooleanField, StringField, StringArrayField } from "@/core/fields";

export class UpperSurveyForm extends SurveyForm {
  // spalla
  public question1: string | undefined = undefined;
  public question2: string | undefined = undefined;
  public question3 = false;
  public question4: string[] = [];
  public question5: string | undefined = undefined;
  public question6: string | undefined = undefined;
  public question7: string | undefined = undefined;
  // gomito
  public question8: string | undefined = undefined;
  public question9: string | undefined = undefined;
  public question10 = false;
  public question11: string[] = [];
  public question12: string | undefined = undefined;
  public question13: string | undefined = undefined;
  public question14: string | undefined = undefined;
  // polso/mano
  public question15: string | undefined = undefined;
  public question16: string | undefined = undefined;
  public question17 = false;
  public question18: string[] = [];
  public question19: string | undefined = undefined;
  public question20: string | undefined = undefined;
  public question21: string | undefined = undefined;
  public question22: string | undefined = undefined;
  public question23: string | undefined = undefined;
  public question24: string | undefined = undefined;
  public question25: string | undefined = undefined;
  public question26: string | undefined = undefined;
  // parestesie notturne
  public question27: string | undefined = undefined;
  public question28: string | undefined = undefined;
  public question29 = false;
  public question30: string[] = [];
  public question31: string | undefined = undefined;
  public question32: string | undefined = undefined;
  public question33: string | undefined = undefined;
  public question34: string | undefined = undefined;
  public question35: string | undefined = undefined;
  public question36: string | undefined = undefined;
  public question37: string | undefined = undefined;
  public question38: string | undefined = undefined;
  // parestesie diurne
  public question39: string | undefined = undefined;
  public question40: string | undefined = undefined;
  public question41 = false;
  public question42: string[] = [];
  public question43: string | undefined = undefined;
  public question44: string | undefined = undefined;
  public question45: string | undefined = undefined;
  public question46: string | undefined = undefined;
  public question47: string | undefined = undefined;
  public question48: string | undefined = undefined;
  public question49: string | undefined = undefined;
  public question50: string | undefined = undefined;
  public question51: string | undefined = undefined;
  // traumi/diagnosi
  public question52: string | undefined = undefined;
  public question53 = false;
  public question53Details: string | undefined = undefined;
  public question54 = false;
  public question54Details: string | undefined = undefined;
  public question55 = false;
  public question55Details: string | undefined = undefined;
  public question56 = false;
  public question56Details: string | undefined = undefined;
  public question57: string | undefined = undefined;
  public question58: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): UpperSurveyForm {
    super.fromFirestore(data);

    this.question1 = StringField.fromFirestore(data.question1);
    this.question2 = StringField.fromFirestore(data.question2);
    this.question3 = BooleanField.fromFirestore(data.question3);
    this.question4 = StringArrayField.fromFirestore(data.question4);
    this.question5 = StringField.fromFirestore(data.question5);
    this.question6 = StringField.fromFirestore(data.question6);
    this.question7 = StringField.fromFirestore(data.question7);
    this.question8 = StringField.fromFirestore(data.question8);
    this.question9 = StringField.fromFirestore(data.question9);
    this.question10 = BooleanField.fromFirestore(data.question10);
    this.question11 = StringArrayField.fromFirestore(data.question11);
    this.question12 = StringField.fromFirestore(data.question12);
    this.question13 = StringField.fromFirestore(data.question13);
    this.question14 = StringField.fromFirestore(data.question14);
    this.question15 = StringField.fromFirestore(data.question15);
    this.question16 = StringField.fromFirestore(data.question16);
    this.question17 = BooleanField.fromFirestore(data.question17);
    this.question18 = StringArrayField.fromFirestore(data.question18);
    this.question19 = StringField.fromFirestore(data.question19);
    this.question20 = StringField.fromFirestore(data.question20);
    this.question21 = StringField.fromFirestore(data.question21);
    this.question22 = StringField.fromFirestore(data.question22);
    this.question23 = StringField.fromFirestore(data.question23);
    this.question24 = StringField.fromFirestore(data.question24);
    this.question25 = StringField.fromFirestore(data.question25);
    this.question26 = StringField.fromFirestore(data.question26);
    this.question27 = StringField.fromFirestore(data.question27);
    this.question28 = StringField.fromFirestore(data.question28);
    this.question29 = BooleanField.fromFirestore(data.question29);
    this.question30 = StringArrayField.fromFirestore(data.question30);
    this.question31 = StringField.fromFirestore(data.question31);
    this.question32 = StringField.fromFirestore(data.question32);
    this.question33 = StringField.fromFirestore(data.question33);
    this.question34 = StringField.fromFirestore(data.question34);
    this.question35 = StringField.fromFirestore(data.question35);
    this.question36 = StringField.fromFirestore(data.question36);
    this.question37 = StringField.fromFirestore(data.question37);
    this.question38 = StringField.fromFirestore(data.question38);
    this.question39 = StringField.fromFirestore(data.question39);
    this.question40 = StringField.fromFirestore(data.question40);
    this.question41 = BooleanField.fromFirestore(data.question41);
    this.question42 = StringArrayField.fromFirestore(data.question42);
    this.question43 = StringField.fromFirestore(data.question43);
    this.question44 = StringField.fromFirestore(data.question44);
    this.question45 = StringField.fromFirestore(data.question45);
    this.question46 = StringField.fromFirestore(data.question46);
    this.question47 = StringField.fromFirestore(data.question47);
    this.question48 = StringField.fromFirestore(data.question48);
    this.question49 = StringField.fromFirestore(data.question49);
    this.question50 = StringField.fromFirestore(data.question50);
    this.question51 = StringField.fromFirestore(data.question51);
    this.question52 = StringField.fromFirestore(data.question52);
    this.question53 = BooleanField.fromFirestore(data.question53);
    this.question53Details = StringField.fromFirestore(data.question53Details);
    this.question54 = BooleanField.fromFirestore(data.question54);
    this.question54Details = StringField.fromFirestore(data.question54Details);
    this.question55 = BooleanField.fromFirestore(data.question55);
    this.question55Details = StringField.fromFirestore(data.question55Details);
    this.question56 = BooleanField.fromFirestore(data.question56);
    this.question56Details = StringField.fromFirestore(data.question56Details);
    this.question57 = StringField.fromFirestore(data.question57);
    this.question58 = StringField.fromFirestore(data.question58);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = StringField.toFirestore(this.question1);
    firestoreData.question2 = StringField.toFirestore(this.question2);
    firestoreData.question3 = BooleanField.toFirestore(this.question3);
    firestoreData.question4 = StringArrayField.toFirestore(this.question4);
    firestoreData.question5 = StringField.toFirestore(this.question5);
    firestoreData.question6 = StringField.toFirestore(this.question6);
    firestoreData.question7 = StringField.toFirestore(this.question7);
    firestoreData.question8 = StringField.toFirestore(this.question8);
    firestoreData.question9 = StringField.toFirestore(this.question9);
    firestoreData.question10 = BooleanField.toFirestore(this.question10);
    firestoreData.question11 = StringArrayField.toFirestore(this.question11);
    firestoreData.question12 = StringField.toFirestore(this.question12);
    firestoreData.question13 = StringField.toFirestore(this.question13);
    firestoreData.question14 = StringField.toFirestore(this.question14);
    firestoreData.question15 = StringField.toFirestore(this.question15);
    firestoreData.question16 = StringField.toFirestore(this.question16);
    firestoreData.question17 = BooleanField.toFirestore(this.question17);
    firestoreData.question18 = StringArrayField.toFirestore(this.question18);
    firestoreData.question19 = StringField.toFirestore(this.question19);
    firestoreData.question20 = StringField.toFirestore(this.question20);
    firestoreData.question21 = StringField.toFirestore(this.question21);
    firestoreData.question22 = StringField.toFirestore(this.question22);
    firestoreData.question23 = StringField.toFirestore(this.question23);
    firestoreData.question24 = StringField.toFirestore(this.question24);
    firestoreData.question25 = StringField.toFirestore(this.question25);
    firestoreData.question26 = StringField.toFirestore(this.question26);
    firestoreData.question27 = StringField.toFirestore(this.question27);
    firestoreData.question28 = StringField.toFirestore(this.question28);
    firestoreData.question29 = BooleanField.toFirestore(this.question29);
    firestoreData.question30 = StringArrayField.toFirestore(this.question30);
    firestoreData.question31 = StringField.toFirestore(this.question31);
    firestoreData.question32 = StringField.toFirestore(this.question32);
    firestoreData.question33 = StringField.toFirestore(this.question33);
    firestoreData.question34 = StringField.toFirestore(this.question34);
    firestoreData.question35 = StringField.toFirestore(this.question35);
    firestoreData.question36 = StringField.toFirestore(this.question36);
    firestoreData.question37 = StringField.toFirestore(this.question37);
    firestoreData.question38 = StringField.toFirestore(this.question38);
    firestoreData.question39 = StringField.toFirestore(this.question39);
    firestoreData.question40 = StringField.toFirestore(this.question40);
    firestoreData.question41 = BooleanField.toFirestore(this.question41);
    firestoreData.question42 = StringArrayField.toFirestore(this.question42);
    firestoreData.question43 = StringField.toFirestore(this.question43);
    firestoreData.question44 = StringField.toFirestore(this.question44);
    firestoreData.question45 = StringField.toFirestore(this.question45);
    firestoreData.question46 = StringField.toFirestore(this.question46);
    firestoreData.question47 = StringField.toFirestore(this.question47);
    firestoreData.question48 = StringField.toFirestore(this.question48);
    firestoreData.question49 = StringField.toFirestore(this.question49);
    firestoreData.question50 = StringField.toFirestore(this.question50);
    firestoreData.question51 = StringField.toFirestore(this.question51);
    firestoreData.question52 = StringField.toFirestore(this.question52);
    firestoreData.question53 = BooleanField.toFirestore(this.question53);
    firestoreData.question53Details = StringField.toFirestore(this.question53Details);
    firestoreData.question54 = BooleanField.toFirestore(this.question54);
    firestoreData.question54Details = StringField.toFirestore(this.question54Details);
    firestoreData.question55 = BooleanField.toFirestore(this.question55);
    firestoreData.question55Details = StringField.toFirestore(this.question55Details);
    firestoreData.question56 = BooleanField.toFirestore(this.question56);
    firestoreData.question56Details = StringField.toFirestore(this.question56Details);
    firestoreData.question57 = StringField.toFirestore(this.question57);
    firestoreData.question58 = StringField.toFirestore(this.question58);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): UpperSurveyForm {
    super.fromOfflineCache(data);

    this.question1 = StringField.fromOfflineCache(data.question1);
    this.question2 = StringField.fromOfflineCache(data.question2);
    this.question3 = BooleanField.fromOfflineCache(data.question3);
    this.question4 = StringArrayField.fromOfflineCache(data.question4);
    this.question5 = StringField.fromOfflineCache(data.question5);
    this.question6 = StringField.fromOfflineCache(data.question6);
    this.question7 = StringField.fromOfflineCache(data.question7);
    this.question8 = StringField.fromOfflineCache(data.question8);
    this.question9 = StringField.fromOfflineCache(data.question9);
    this.question10 = BooleanField.fromOfflineCache(data.question10);
    this.question11 = StringArrayField.fromOfflineCache(data.question11);
    this.question12 = StringField.fromOfflineCache(data.question12);
    this.question13 = StringField.fromOfflineCache(data.question13);
    this.question14 = StringField.fromOfflineCache(data.question14);
    this.question15 = StringField.fromOfflineCache(data.question15);
    this.question16 = StringField.fromOfflineCache(data.question16);
    this.question17 = BooleanField.fromOfflineCache(data.question17);
    this.question18 = StringArrayField.fromOfflineCache(data.question18);
    this.question19 = StringField.fromOfflineCache(data.question19);
    this.question20 = StringField.fromOfflineCache(data.question20);
    this.question21 = StringField.fromOfflineCache(data.question21);
    this.question22 = StringField.fromOfflineCache(data.question22);
    this.question23 = StringField.fromOfflineCache(data.question23);
    this.question24 = StringField.fromOfflineCache(data.question24);
    this.question25 = StringField.fromOfflineCache(data.question25);
    this.question26 = StringField.fromOfflineCache(data.question26);
    this.question27 = StringField.fromOfflineCache(data.question27);
    this.question28 = StringField.fromOfflineCache(data.question28);
    this.question29 = BooleanField.fromOfflineCache(data.question29);
    this.question30 = StringArrayField.fromOfflineCache(data.question30);
    this.question31 = StringField.fromOfflineCache(data.question31);
    this.question32 = StringField.fromOfflineCache(data.question32);
    this.question33 = StringField.fromOfflineCache(data.question33);
    this.question34 = StringField.fromOfflineCache(data.question34);
    this.question35 = StringField.fromOfflineCache(data.question35);
    this.question36 = StringField.fromOfflineCache(data.question36);
    this.question37 = StringField.fromOfflineCache(data.question37);
    this.question38 = StringField.fromOfflineCache(data.question38);
    this.question39 = StringField.fromOfflineCache(data.question39);
    this.question40 = StringField.fromOfflineCache(data.question40);
    this.question41 = BooleanField.fromOfflineCache(data.question41);
    this.question42 = StringArrayField.fromOfflineCache(data.question42);
    this.question43 = StringField.fromOfflineCache(data.question43);
    this.question44 = StringField.fromOfflineCache(data.question44);
    this.question45 = StringField.fromOfflineCache(data.question45);
    this.question46 = StringField.fromOfflineCache(data.question46);
    this.question47 = StringField.fromOfflineCache(data.question47);
    this.question48 = StringField.fromOfflineCache(data.question48);
    this.question49 = StringField.fromOfflineCache(data.question49);
    this.question50 = StringField.fromOfflineCache(data.question50);
    this.question51 = StringField.fromOfflineCache(data.question51);
    this.question52 = StringField.fromOfflineCache(data.question52);
    this.question53 = BooleanField.fromOfflineCache(data.question53);
    this.question53Details = StringField.fromOfflineCache(data.question53Details);
    this.question54 = BooleanField.fromOfflineCache(data.question54);
    this.question54Details = StringField.fromOfflineCache(data.question54Details);
    this.question55 = BooleanField.fromOfflineCache(data.question55);
    this.question55Details = StringField.fromOfflineCache(data.question55Details);
    this.question56 = BooleanField.fromOfflineCache(data.question56);
    this.question56Details = StringField.fromOfflineCache(data.question56Details);
    this.question57 = StringField.fromOfflineCache(data.question57);
    this.question58 = StringField.fromOfflineCache(data.question58);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = StringField.toOfflineCache(this.question1);
    firestoreData.question2 = StringField.toOfflineCache(this.question2);
    firestoreData.question3 = BooleanField.toOfflineCache(this.question3);
    firestoreData.question4 = StringArrayField.toOfflineCache(this.question4);
    firestoreData.question5 = StringField.toOfflineCache(this.question5);
    firestoreData.question6 = StringField.toOfflineCache(this.question6);
    firestoreData.question7 = StringField.toOfflineCache(this.question7);
    firestoreData.question8 = StringField.toOfflineCache(this.question8);
    firestoreData.question9 = StringField.toOfflineCache(this.question9);
    firestoreData.question10 = BooleanField.toOfflineCache(this.question10);
    firestoreData.question11 = StringArrayField.toOfflineCache(this.question11);
    firestoreData.question12 = StringField.toOfflineCache(this.question12);
    firestoreData.question13 = StringField.toOfflineCache(this.question13);
    firestoreData.question14 = StringField.toOfflineCache(this.question14);
    firestoreData.question15 = StringField.toOfflineCache(this.question15);
    firestoreData.question16 = StringField.toOfflineCache(this.question16);
    firestoreData.question17 = BooleanField.toOfflineCache(this.question17);
    firestoreData.question18 = StringArrayField.toOfflineCache(this.question18);
    firestoreData.question19 = StringField.toOfflineCache(this.question19);
    firestoreData.question20 = StringField.toOfflineCache(this.question20);
    firestoreData.question21 = StringField.toOfflineCache(this.question21);
    firestoreData.question22 = StringField.toOfflineCache(this.question22);
    firestoreData.question23 = StringField.toOfflineCache(this.question23);
    firestoreData.question24 = StringField.toOfflineCache(this.question24);
    firestoreData.question25 = StringField.toOfflineCache(this.question25);
    firestoreData.question26 = StringField.toOfflineCache(this.question26);
    firestoreData.question27 = StringField.toOfflineCache(this.question27);
    firestoreData.question28 = StringField.toOfflineCache(this.question28);
    firestoreData.question29 = BooleanField.toOfflineCache(this.question29);
    firestoreData.question30 = StringArrayField.toOfflineCache(this.question30);
    firestoreData.question31 = StringField.toOfflineCache(this.question31);
    firestoreData.question32 = StringField.toOfflineCache(this.question32);
    firestoreData.question33 = StringField.toOfflineCache(this.question33);
    firestoreData.question34 = StringField.toOfflineCache(this.question34);
    firestoreData.question35 = StringField.toOfflineCache(this.question35);
    firestoreData.question36 = StringField.toOfflineCache(this.question36);
    firestoreData.question37 = StringField.toOfflineCache(this.question37);
    firestoreData.question38 = StringField.toOfflineCache(this.question38);
    firestoreData.question39 = StringField.toOfflineCache(this.question39);
    firestoreData.question40 = StringField.toOfflineCache(this.question40);
    firestoreData.question41 = BooleanField.toOfflineCache(this.question41);
    firestoreData.question42 = StringArrayField.toOfflineCache(this.question42);
    firestoreData.question43 = StringField.toOfflineCache(this.question43);
    firestoreData.question44 = StringField.toOfflineCache(this.question44);
    firestoreData.question45 = StringField.toOfflineCache(this.question45);
    firestoreData.question46 = StringField.toOfflineCache(this.question46);
    firestoreData.question47 = StringField.toOfflineCache(this.question47);
    firestoreData.question48 = StringField.toOfflineCache(this.question48);
    firestoreData.question49 = StringField.toOfflineCache(this.question49);
    firestoreData.question50 = StringField.toOfflineCache(this.question50);
    firestoreData.question51 = StringField.toOfflineCache(this.question51);
    firestoreData.question52 = StringField.toOfflineCache(this.question52);
    firestoreData.question53 = BooleanField.toOfflineCache(this.question53);
    firestoreData.question53Details = StringField.toOfflineCache(this.question53Details);
    firestoreData.question54 = BooleanField.toOfflineCache(this.question54);
    firestoreData.question54Details = StringField.toOfflineCache(this.question54Details);
    firestoreData.question55 = BooleanField.toOfflineCache(this.question55);
    firestoreData.question55Details = StringField.toOfflineCache(this.question55Details);
    firestoreData.question56 = BooleanField.toOfflineCache(this.question56);
    firestoreData.question56Details = StringField.toOfflineCache(this.question56Details);
    firestoreData.question57 = StringField.toOfflineCache(this.question57);
    firestoreData.question58 = StringField.toOfflineCache(this.question58);

    return firestoreData;
  }
}
