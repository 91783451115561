export const it = {
  hint: {
    context: "Contesto",
    contextError: "Selezionare un contesto valido",
    contexts: {
      endingMeasures: "Provvedimenti del medico competente",
      farHistory: "Anamnesi patologica remota",
      recentHistory: "Anamnesi patologica prossima",
    },
    hint: "Suggerimento",
    hints: "Suggerimenti",
    text: "Testo",
    textError: "Inserire un testo valido",
  },
  navigation: {
    hint: "Suggerimenti",
  },
};
