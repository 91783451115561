export const it = {
  cloudFault: {
    cloudFault: "Errore cloud",
    cloudFaults: "Errori cloud",
    createdAt: "Data e ora",
    details: "Dettagli",
    function: "Funzione",
    noDetails: "Nessun dettaglio presente",
    notifications: {
      cloudFaultCreated: "Errore cloud creato",
      groupName: "Errori cloud",
    },
  },
  buttons: {
    deleteAll: "Elimina tutto",
    showDetails: "Mostra dettagli",
  },
  navigation: {
    cloudFault: "Errori cloud",
  },
};
