export const it = {
  examinationSession: {
    birthDate: "Nascita",
    branch: "Sede",
    branchError: "Selezionare una sede",
    calculatedExamType: "Calcolato automaticamente",
    candidate: "Lavoratore",
    candidates: "Lavoratori",
    candidateStates: {
      draft: "Bozza",
      created: "Creata",
      error: "Errore",
      view: "Visualizza",
    },
    company: "Azienda",
    companyAndBranch: "Azienda e sede",
    createExaminationSession: "Crea sessione visite mediche",
    date: "Data",
    dateError: "Selezionare una data valida",
    doctor: "Medico",
    doctorError: "Selezionare un medico",
    examinationDate: "Data visita medica",
    examinationDateError: "Selezionare una data di visita medica",
    examinationDuration: "Durata visita medica (minuti)",
    examinations: "Visite mediche",
    examinationSession: "Sessione visite mediche",
    examinationSessions: "Sessioni visite mediche",
    examinationStartTime: "Orario inizio visite mediche",
    lastExamination: "Ultima visita",
    period: "Periodo",
    printExpiration: "Scadenza",
    signature: "Firma",
    setExaminationTime: "Imposta orari visite mediche",
    state: "Stato",
    states: {
      approved: "Approvata",
      completed: "Completata",
      draft: "Bozza",
      error: "Errore",
    },
    time: "Orario",
  },
  navigation: {
    examinationSession: "Sessioni visite mediche",
  },
  buttons: {
    createExaminations: "Crea visite mediche",
    goToExamination: "Vai alla visita medica",
    printWithSignatures: "Stampa foglio firma",
    setApprovedState: "Segna come approvata",
    setDraftState: "Segna come bozza",
  },
  toast: {
    error: {},
    success: {},
    warning: {
      noCandidatesSelected: "Selezionare almeno un lavoratore",
    },
  },
};
