import { AuditShortSurveyForm } from "../objects/AuditShortSurveyForm";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class AuditShortSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private auditShortSurveyForm: AuditShortSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    auditShortSurveyForm: AuditShortSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.auditShortSurveyForm = auditShortSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      auditShortAnswerTable: { fontSize: 8 },
      auditShortAnswerValue: { alignment: "center", bold: true },
      auditShortQuestion: { color: this.textColor, bold: true, fontSize: 10 },
      auditShortNotes: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, DataHelpers.mmToPoints(3)] },
      auditShortResultValue: { border: false, color: this.highlightColor, fontSize: 10, bold: true },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("A.U.D.I.T. C (versione breve dell'Alcohol Use Disorders Identification Test)", isContinuos);

    await this.writeEmployeeData();
    this.writeQuestionA(1);
    this.writeAnswerA5(
      2,
      ["mai", "1 volta al mese o meno", "2-4 volte al mese", "2-3 volte alla settimana", "4 o più volte alla settimana"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(30), DataHelpers.mmToPoints(25), "*", "*"]
    );

    this.writeQuestionA(2);
    this.writeAnswerA6(2, ["Nessuno", "1 o 2", "3 o 4", "5 o 6", "7 o 9", "10 o più"], ["*", "*", "*", "*", "*", "*"]);

    this.writeQuestionA(3);
    this.writeAnswerA5(
      3,
      ["mai", "1 volta al mese o meno", "2-4 volte al mese", "2-3 volte alla settimana", "4 o più volte alla settimana"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(30), DataHelpers.mmToPoints(25), "*", "*"]
    );

    this.writeResultA();

    if (this.auditShortSurveyForm.additionalQuestions === true) {
      this.writeSubtitle("A.U.D.I.T. Clinico");

      this.writeColumnBWithTwoChoices(1, ["No", "Sì"]);

      this.writeColumnBWithTwoChoices(2, ["No", "Sì"]);

      this.writeColumnBWithFourChoices(3, ["Assente", "Lieve", "Moderato", "Severo"]);

      this.writeColumnBWithFourChoices(4, ["Assente", "Lieve", "Moderato", "Severo"]);

      this.writeColumnBWithFourChoices(5, ["Assente", "Lieve", "Moderato", "Severo"]);

      this.writeColumnBWithFourChoices(6, ["Assente", "Lieve", "Moderato", "Severo"]);

      this.writeColumnBWithFourChoices(7, ["Assente", "Lieve", "Moderato", "Severo"]);

      this.writeColumnBWithTwoChoices(8, ["Basso/normale", "Doppio del normale"]);

      this.writeResultB();
    }

    this.writeNotes();
    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeQuestionA(questionIndex: number): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: `${questionIndex}. ${this.t(`surveyForm.auditShortSurveyForm.question${questionIndex}A`)}`,
      style: "auditShortQuestion",
    });
  }

  private writeAnswerA5(questionIndex: number, choices: string[], widths: (number | string)[]): void {
    const answer: number | undefined = this.auditShortSurveyForm[`question${questionIndex}A` as keyof AuditShortSurveyForm] as number | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === 0 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === 1 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
        { text: answer === 2 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[2], border: [false, false, false, false] },
        { text: answer === 3 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[3], border: [false, false, false, false] },
        { text: answer === 4 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[4], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
      style: "auditShortAnswerTable",
      table: {
        body: answerTableBody,
        headerRows: 0,
        widths: [
          DataHelpers.mmToPoints(3),
          widths[0],
          DataHelpers.mmToPoints(3),
          widths[1],
          DataHelpers.mmToPoints(3),
          widths[2],
          DataHelpers.mmToPoints(3),
          widths[3],
          DataHelpers.mmToPoints(3),
          widths[4],
        ],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeAnswerA6(questionIndex: number, choices: string[], widths: (number | string)[]): void {
    const answer: number | undefined = this.auditShortSurveyForm[`question${questionIndex}A` as keyof AuditShortSurveyForm] as number | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === 0 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === 1 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
        { text: answer === 2 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[2], border: [false, false, false, false] },
        { text: answer === 3 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[3], border: [false, false, false, false] },
        { text: answer === 4 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[4], border: [false, false, false, false] },
        { text: answer === 5 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[5], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
      style: "auditShortAnswerTable",
      table: {
        body: answerTableBody,
        headerRows: 0,
        widths: [
          DataHelpers.mmToPoints(3),
          widths[0],
          DataHelpers.mmToPoints(3),
          widths[1],
          DataHelpers.mmToPoints(3),
          widths[2],
          DataHelpers.mmToPoints(3),
          widths[3],
          DataHelpers.mmToPoints(3),
          widths[4],
          DataHelpers.mmToPoints(3),
          widths[5],
        ],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeResultA(): void {
    const resultTableBody: unknown[] = [
      [
        {
          text: this.examination.date !== undefined ? this.d(this.examination.date, "shortDate") : "",
          style: "auditShortResultValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        { text: this.auditShortSurveyForm.pointsA ?? "-", style: "auditShortResultValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.auditShortSurveyForm.resultA ?? "-", style: "auditShortResultValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("examination.date"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("surveyForm.auditShortSurveyForm.points"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("surveyForm.result"), style: "employeeDataField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "employeeDataTable",
      table: {
        body: resultTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(40), "*", DataHelpers.mmToPoints(40), "*", DataHelpers.mmToPoints(80)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeColumnBWithTwoChoices(questionIndex: number, choices: string[]): void {
    const answer: number | undefined = this.auditShortSurveyForm[`question${questionIndex}B` as keyof AuditShortSurveyForm] as number | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === 0 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === 3 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          width: DataHelpers.mmToPoints(70),
          text: `${questionIndex}. ${this.t(`surveyForm.auditShortSurveyForm.question${questionIndex}B`)}`,
          style: "auditShortQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          style: "auditShortAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeColumnBWithFourChoices(questionIndex: number, choices: string[]): void {
    const answer: number | undefined = this.auditShortSurveyForm[`question${questionIndex}B` as keyof AuditShortSurveyForm] as number | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === 0 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === 1 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
        { text: answer === 2 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[2], border: [false, false, false, false] },
        { text: answer === 3 ? "X" : "", style: "auditShortAnswerValue", border: [true, true, true, true] },
        { text: choices[3], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          width: DataHelpers.mmToPoints(70),
          text: `${questionIndex}. ${this.t(`surveyForm.auditShortSurveyForm.question${questionIndex}B`)}`,
          style: "auditShortQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          style: "auditShortAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeResultB(): void {
    const resultTableBody: unknown[] = [
      [
        {
          text: this.examination.date !== undefined ? this.d(this.examination.date, "shortDate") : "",
          style: "auditShortResultValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        { text: this.auditShortSurveyForm.pointsB ?? "-", style: "auditShortResultValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.auditShortSurveyForm.resultB ?? "-", style: "auditShortResultValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("examination.date"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("surveyForm.auditShortSurveyForm.points"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("surveyForm.result"), style: "employeeDataField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "employeeDataTable",
      table: {
        body: resultTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(40), "*", DataHelpers.mmToPoints(40), "*", DataHelpers.mmToPoints(80)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeNotes(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: "Il lavoratore è stato informato sui rischi per la salute e la sicurezza connessi con il consumo a rischio e dannoso di alcol.",
      style: "auditShortNotes",
    });
  }
}
