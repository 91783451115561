import { AuditSurveyForm } from "../objects/AuditSurveyForm";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class AuditSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private auditSurveyForm: AuditSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    auditSurveyForm: AuditSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.auditSurveyForm = auditSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      auditAnswerTable: { fontSize: 8 },
      auditAnswerValue: { alignment: "center", bold: true },
      auditQuestion: { color: this.textColor, bold: true, fontSize: 10 },
      auditNotes: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, DataHelpers.mmToPoints(3)] },
      auditResultValue: { border: false, color: this.highlightColor, fontSize: 10, bold: true },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("A.U.D.I.T. (Alcohol Use Disorders Identification Test)", isContinuos);

    await this.writeEmployeeData();
    this.writeQuestion(1);
    this.writeAnswerWith5Choices(
      2,
      ["mai", "1 volta al mese o meno", "2-4 volte al mese", "2-3 volte alla settimana", "4 o più volte alla settimana"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(30), DataHelpers.mmToPoints(25), "*", "*"]
    );

    this.writeQuestion(2);
    this.writeAnswerWith5Choices(2, ["Da 0 o 2", "3 o 4", "5 o 6", "7 o 9", "10 o più"], ["*", "*", "*", "*", "*", "*"]);

    this.writeQuestion(3);
    this.writeAnswerWith5Choices(
      3,
      ["mai", "meno di una volta al mese", "1 volta al mese", "1 volta la settimana", "ogni giorno o quasi"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(40), "*", "*", "*"]
    );

    this.writeQuestion(4);
    this.writeAnswerWith5Choices(
      4,
      ["mai", "meno di una volta al mese", "1 volta al mese", "1 volta la settimana", "ogni giorno o quasi"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(40), "*", "*", "*"]
    );

    this.writeQuestion(5);
    this.writeAnswerWith5Choices(
      5,
      ["mai", "meno di una volta al mese", "1 volta al mese", "1 volta la settimana", "ogni giorno o quasi"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(40), "*", "*", "*"]
    );

    this.writeQuestion(6);
    this.writeAnswerWith5Choices(
      6,
      ["mai", "meno di una volta al mese", "1 volta al mese", "1 volta la settimana", "ogni giorno o quasi"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(40), "*", "*", "*"]
    );

    this.writeQuestion(7);
    this.writeAnswerWith5Choices(
      7,
      ["mai", "meno di una volta al mese", "1 volta al mese", "1 volta la settimana", "ogni giorno o quasi"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(40), "*", "*", "*"]
    );

    this.writeQuestion(8);
    this.writeAnswerWith5Choices(
      8,
      ["mai", "meno di una volta al mese", "1 volta al mese", "1 volta la settimana", "ogni giorno o quasi"],
      [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(40), "*", "*", "*"]
    );

    this.writeQuestion(9);
    this.writeAnswerWith3Choices(9, ["no", "sì, ma non nell'ultimo anno", "sì, nell'ultimo anno"], ["*", "*", "*"]);

    this.writeQuestion(10);
    this.writeAnswerWith3Choices(10, ["no", "sì, ma non nell'ultimo anno", "sì, nell'ultimo anno"], ["*", "*", "*"]);

    this.writeResult();

    this.writeNotes();

    await this.writeSignatures();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeQuestion(questionIndex: number): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: `${questionIndex}. ${this.t(`surveyForm.auditSurveyForm.question${questionIndex}`)}`,
      style: "auditQuestion",
    });
  }

  private writeAnswerWith3Choices(questionIndex: number, choices: string[], widths: (number | string)[]): void {
    const answer: number | undefined = this.auditSurveyForm[`question${questionIndex}` as keyof AuditSurveyForm] as number | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === 0 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === 2 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
        { text: answer === 4 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[2], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
      style: "auditAnswerTable",
      table: {
        body: answerTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(3), widths[0], DataHelpers.mmToPoints(3), widths[1], DataHelpers.mmToPoints(3), widths[2]],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeAnswerWith5Choices(questionIndex: number, choices: string[], widths: (number | string)[]): void {
    const answer: number | undefined = this.auditSurveyForm[`question${questionIndex}` as keyof AuditSurveyForm] as number | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === 0 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === 1 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
        { text: answer === 2 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[2], border: [false, false, false, false] },
        { text: answer === 3 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[3], border: [false, false, false, false] },
        { text: answer === 4 ? "X" : "", style: "auditAnswerValue", border: [true, true, true, true] },
        { text: choices[4], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
      style: "auditAnswerTable",
      table: {
        body: answerTableBody,
        headerRows: 0,
        widths: [
          DataHelpers.mmToPoints(3),
          widths[0],
          DataHelpers.mmToPoints(3),
          widths[1],
          DataHelpers.mmToPoints(3),
          widths[2],
          DataHelpers.mmToPoints(3),
          widths[3],
          DataHelpers.mmToPoints(3),
          widths[4],
        ],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeResult(): void {
    const resultTableBody: unknown[] = [
      [
        {
          text: this.examination.date !== undefined ? this.d(this.examination.date, "shortDate") : "",
          style: "auditResultValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        { text: this.auditSurveyForm.points ?? "-", style: "auditResultValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.auditSurveyForm.result ?? "-", style: "auditResultValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("examination.date"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("surveyForm.auditSurveyForm.points"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("surveyForm.result"), style: "employeeDataField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "employeeDataTable",
      table: {
        body: resultTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(40), "*", DataHelpers.mmToPoints(40), "*", DataHelpers.mmToPoints(80)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeNotes(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: "Il lavoratore è stato informato sui rischi per la salute e la sicurezza connessi con il consumo a rischio e dannoso di alcol.",
      style: "auditNotes",
    });
  }
}
