import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { VdtSurveyForm } from "../objects/VdtSurveyForm";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class VdtSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private vdtSurveyForm: VdtSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    vdtSurveyForm: VdtSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.vdtSurveyForm = vdtSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      vdtAnswerTable: { fontSize: 8 },
      vdtAnswerValue: { alignment: "center", bold: true },
      vdtNote: { color: this.textColor, fontSize: 8, alignment: "justify" },
      vdtRow: { color: this.textColor, fontSize: 10 },
      vdtRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      vdtQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("SCHEDA VIDEOTERMINALE", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle("GENERALI");
    this.writeFirstRow();
    this.writeRow(4);
    this.writeRow(5);
    this.writeRow(6);
    this.writeRow(7);

    this.writeSubtitle(this.t("surveyForm.vdtSurveyForm.title1"));
    this.writeColumnWithThreeChoices(8, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(9, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(10, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(11, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(12, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(13, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(14, ["No", "Raramente", "Spesso"]);
    this.writeColumnWithThreeChoices(15, ["No", "Raramente", "Spesso"]);

    this.writeSubtitle(this.t("surveyForm.vdtSurveyForm.title2"));

    this.writeColumnWithOneChoice(16, "Disturbi pregressi della motilità oculare");
    this.writeColumnWithOneChoice(16, "Cataratta");
    this.writeColumnWithOneChoice(16, "Glaucoma");
    this.writeColumnWithOneChoice(16, "Alterazioni recidivanti e/o croniche degli annessi o della superficie oculare");

    if (this.vdtSurveyForm.question17 !== undefined) {
      this.writeSubtitle(this.t("surveyForm.vdtSurveyForm.question17"));
      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.vdtSurveyForm.question17 ?? "-",
        style: "vdtNote",
      });
    }

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeFirstRow(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(70),
          text: this.t("surveyForm.vdtSurveyForm.question1"),
          style: "vdtRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(30),
          text: this.vdtSurveyForm.question1 ?? "-",
          style: "vdtRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.t("surveyForm.vdtSurveyForm.question2"),
          style: "vdtRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.vdtSurveyForm.question2 ?? "-",
          style: "vdtRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.t("surveyForm.vdtSurveyForm.question3"),
          style: "vdtRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.vdtSurveyForm.question3 ?? "-",
          style: "vdtRow",
        },
      ],
    });
  }

  private writeRow(questionIndex: number): void {
    const answer: string | undefined = this.vdtSurveyForm[`question${questionIndex}` as keyof VdtSurveyForm] as string | undefined;

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(70),
          text: this.t(`surveyForm.vdtSurveyForm.question${questionIndex}`),
          style: "vdtRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(110),
          text: answer ?? "-",
          style: "vdtRow",
        },
      ],
    });
  }

  private writeColumnWithThreeChoices(questionIndex: number, choices: string[]): void {
    const answer: string | undefined = this.vdtSurveyForm[`question${questionIndex}` as keyof VdtSurveyForm] as string | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === choices[0] ? "X" : "", style: "vdtAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === choices[1] ? "X" : "", style: "vdtAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
        { text: answer === choices[2] ? "X" : "", style: "vdtAnswerValue", border: [true, true, true, true] },
        { text: choices[2], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(70),
          text: this.t(`surveyForm.vdtSurveyForm.question${questionIndex}`),
          style: "vdtQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "vdtAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeColumnWithOneChoice(questionIndex: number, answer: string): void {
    const answers: string[] = this.vdtSurveyForm[`question${questionIndex}` as keyof VdtSurveyForm] as string[];
    const answerTableBody: unknown[] = [
      [
        { text: answers.includes(answer) ? "X" : "", style: "vdtAnswerValue", border: [true, true, true, true] },
        { text: " ", border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(130),
          text: answer,
          style: "vdtQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "vdtAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }
}
