export const it = {
  testType: {
    badResultLabel: "Etichetta risultato negativo",
    category: "Categoria",
    categoryError: "Selezionare una categoria",
    defaultLabel: "Predefinita",
    formName: "Nome scheda associata",
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    goodResultLabel: "Etichetta risultato positivo",
    name: "Nome",
    nameError: "Inserire un nome valido",
    onlyInExamination: "Solo in visita medica (non esterno)",
    onlyInExaminationColumn: "Esternamente",
    onlyInExaminationError: "Selezionare un valore valido",
    testForm: "Scheda",
    testType: "Tipo accertamento",
    testTypes: "Tipi accertamento",
  },
  navigation: {
    testType: "Tipi accertamento",
  },
  buttons: {
    setNotOnlyInExamination: "Possibile esternamente",
    setOnlyInExamination: "Non possibile esternamente",
  },
};
