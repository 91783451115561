import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Role } from "../objects/Role";
import { roleModel } from "../models/RoleModel";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const roleSearch: SearchModule = new SearchModule(
  "role.role",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Role).name ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/roles/${firestoreDocument.id}/edit`,
  "tags",
  roleModel,
  [new SortCriteria("name", "asc", "string")]
);
