import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "../objects/Doctor";
import { doctorModel } from "./DoctorModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class DoctorOfflineModel extends OfflineModuleModel<Doctor> {
  public constructor() {
    super(() => new Doctor(), "doctors", "doctor.doctors", "doctor");
  }

  public async downloadCache(): Promise<Doctor[]> {
    try {
      return doctorModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("DoctorOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const doctorOfflineModel: DoctorOfflineModel = new DoctorOfflineModel();
