import { compareAsc } from "date-fns";
import { DocumentReference } from "firebase/firestore";

import { ContractType } from "./ContractType";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";

import { ArrayByKeyField, BooleanField, DateField, EnumField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class Contract extends FirestoreOfflineDocument {
  public firm: LinkedFirm | undefined = undefined;
  public company: LinkedCompany | undefined = undefined;
  public branches: Record<string, LinkedBranch> = {};
  public doctor: LinkedDoctor | undefined = undefined;
  public from: Date | undefined = undefined;
  public fromSort = "0000-00-00";
  public to: Date | undefined = undefined;
  public toSort = "9999-99-99";
  public type: ContractType = ContractType.Competent;
  public temporary = false;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Contract {
    super.fromFirestore(data, id, firestoreRef);

    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.branches = ArrayByKeyField.fromFirestore<LinkedBranch>(data.branches, (value) => new LinkedBranch(value));
    this.doctor = ObjectField.fromFirestore<LinkedDoctor>(data.doctor, (value) => new LinkedDoctor(value));
    this.from = DateField.fromFirestore(data.from);
    this.fromSort = StringStrictField.fromFirestore(data.fromSort, "0000-00-00");
    this.to = DateField.fromFirestore(data.to);
    this.toSort = StringStrictField.fromFirestore(data.toSort, "9999-99-99");
    this.type = EnumField.fromFirestore<ContractType>(data.type, Object.values(ContractType), ContractType.Competent);
    this.temporary = BooleanField.fromFirestore(data.temporary);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.branches = ArrayByKeyField.toFirestore<LinkedBranch>(this.branches, (value) => value.toFirestore());
    firestoreData.doctor = ObjectField.toFirestore<LinkedDoctor>(this.doctor, (value) => value.toFirestore());
    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.type = EnumField.toFirestore<ContractType>(this.type, ContractType.Competent);
    firestoreData.temporary = BooleanField.toFirestore(this.temporary);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Contract {
    super.fromOfflineCache(data);

    this.firm = ObjectField.fromOfflineCache<LinkedFirm>(data.firm, (value) => {
      const linkedFirm: LinkedFirm = new LinkedFirm();
      return linkedFirm.fromOfflineCache(value);
    });
    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => {
      const linkedCompany: LinkedCompany = new LinkedCompany();
      return linkedCompany.fromOfflineCache(value);
    });
    this.branches = ArrayByKeyField.fromOfflineCache<LinkedBranch>(data.branches, (value) => {
      const linkedBranch: LinkedBranch = new LinkedBranch();
      return linkedBranch.fromOfflineCache(value);
    });
    this.doctor = ObjectField.fromOfflineCache<LinkedDoctor>(data.doctor, (value) => {
      const linkedDoctor: LinkedDoctor = new LinkedDoctor();
      return linkedDoctor.fromOfflineCache(value);
    });
    this.from = DateField.fromOfflineCache(data.from);
    this.fromSort = StringStrictField.fromOfflineCache(data.fromSort, "0000-00-00");
    this.to = DateField.fromOfflineCache(data.to);
    this.toSort = StringStrictField.fromOfflineCache(data.toSort, "9999-99-99");
    this.type = EnumField.fromOfflineCache<ContractType>(data.type, Object.values(ContractType), ContractType.Competent);
    this.temporary = BooleanField.fromOfflineCache(data.temporary);
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.firm = ObjectField.toOfflineCache<LinkedFirm>(this.firm, (value) => value.toOfflineCache());
    firestoreData.company = ObjectField.toOfflineCache<LinkedCompany>(this.company, (value) => value.toOfflineCache());
    firestoreData.branches = ArrayByKeyField.toOfflineCache<LinkedBranch>(this.branches, (value) => value.toOfflineCache());
    firestoreData.doctor = ObjectField.toOfflineCache<LinkedDoctor>(this.doctor, (value) => value.toOfflineCache());
    firestoreData.from = DateField.toOfflineCache(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toOfflineCache(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.type = EnumField.toOfflineCache<ContractType>(this.type, ContractType.Competent);
    firestoreData.temporary = BooleanField.toOfflineCache(this.temporary);
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }

  public getLinkedBranches(): LinkedBranch[] {
    return Object.values(this.branches)
      .sort((a, b) => {
        if (a.name === undefined || b.name === undefined) return 0;
        if (a.name === b.name) return 0;
        return a.name > b.name ? 1 : -1;
      })
      .sort((a, b) => {
        if (a.legalBranch === true) return -1;
        if (b.legalBranch === true) return 1;
        return 0;
      });
  }

  public setLinkedBranches(linkedBranches: LinkedBranch[]): void {
    this.branches = DataHelpers.sortedArrayToObject<LinkedBranch>(linkedBranches);
  }

  public addLinkedBranch(linkedBranch: LinkedBranch): void {
    this.branches[linkedBranch.id] = linkedBranch;
  }

  public removeLinkedBranch(linkedBranch: LinkedBranch): void {
    delete this.branches[linkedBranch.id];
  }

  public isCurrentlyActive(): boolean {
    const now: Date = new Date();
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, now) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(now, to) > 0) return false;
    }
    return true;
  }

  public isActiveOnDate(selectedDate: Date): boolean {
    selectedDate.setHours(0, 0, 0, 0);
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, selectedDate) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(selectedDate, to) > 0) return false;
    }
    return true;
  }
}
