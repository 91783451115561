import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const reorderDocuments = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  documents: T[],
  parentId?: string
): Promise<void> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (model.offlineModuleModel === undefined) throw new Error("offlineModuleModelNotSaveable");
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");
      if (model.offlineModuleModel.canUpdate === false) throw new Error("offlineModuleModelNotSaveable");

      let order = 1;
      for (const document of documents as FirestoreOfflineDocument[]) {
        (document as unknown as Record<string, unknown>).order = order;
        await indexedDbModel.setDocument(model.collectionName, document.id, document.toOfflineCache());
        await model.offlineModuleModel.updateDocumentToQueue(document);
        order++;
      }
    }

    // check if user can update the collection
    if (user.canUpdate(model.roleModule) !== true) throw new Error(`Unable to reorder documents in collection ${model.collectionName}`);

    let order = 1;
    for (const document of documents) {
      batch.update(model.getDocumentReference(document.id, parentId), { order: order });
      order++;
    }
    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.reorderDocuments", { model, documents, parentId }, error);
  }
};
