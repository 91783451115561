import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getInvoicesByYearlyPaymentLumpSum = async (companyId: string, year: number): Promise<Invoice[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") return [];

    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<Invoice> = query(
      invoiceModel.getPathReference().withConverter(invoiceModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("year", "==", year),
      where("yearlyPaymentsLumpSum", "array-contains", companyId)
    );

    const snapshot: QuerySnapshot<Invoice> = await getDocs(invoiceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getInvoicesByYearlyPaymentLumpSum", { companyId, year }, error);
    return [];
  }
};
