import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ExamType } from "../objects/ExamType";
import { examTypeModel } from "./ExamTypeModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class ExamTypeOfflineModel extends OfflineModuleModel<ExamType> {
  public constructor() {
    super(() => new ExamType(), "examTypes", "examType.examTypes", undefined);
  }

  public async downloadCache(): Promise<ExamType[]> {
    try {
      return examTypeModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("ExamTypeOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const examTypeOfflineModel: ExamTypeOfflineModel = new ExamTypeOfflineModel();
