import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";

export class ExaminationScheduleModel {
  public async searchExaminations(
    startDate: Date,
    endDate: Date,
    companyId: string,
    doctorId: string,
    state: "all" | "done"
  ): Promise<Examination[]> {
    try {
      if (startDate === undefined) throw new Error("periodUndefined");
      if (endDate === undefined) throw new Error("periodUndefined");
      if (companyId === undefined) throw new Error("companyIdUndefined");
      if (doctorId === undefined) throw new Error("doctorIdUndefined");

      const examinations: Examination[] = await examinationModel.getExaminationsWithFilters(startDate, endDate, companyId, "all", doctorId, "all");

      if (state === "done")
        return examinations.filter(
          (examination: Examination) => examination.state !== ExaminationState.Draft && examination.state !== ExaminationState.Pending
        );

      return examinations;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExaminationScheduleModel.searchExaminations", { startDate, endDate, companyId, doctorId, state }, error);
      return [];
    }
  }
}

export const examinationScheduleModel: ExaminationScheduleModel = new ExaminationScheduleModel();
