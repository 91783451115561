import { Component } from "vue";

export class UtilityModule {
  public group: string;
  public name: string;
  public requiredModule: string | undefined;
  public component: Component;

  public constructor(group: string, name: string, requiredModule: string | undefined, component: Component) {
    this.group = group;
    this.name = name;
    this.component = component;
    this.requiredModule = requiredModule;
  }
}
