import { RouteRecordRaw } from "vue-router";

export const serviceRoutes: RouteRecordRaw[] = [
  {
    path: "/services",
    name: "ServiceList",
    component: () => import("../views/ServiceList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "service",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/services/:startDate/:endDate/:companyId/:doctorId/:state",
    name: "ServiceListWithQueryParams",
    component: () => import("../views/ServiceList.vue"),
    props: (route) => ({
      startDate: route.params.startDate,
      endDate: route.params.endDate,
      companyId: route.params.companyId,
      doctorId: route.params.doctorId,
      state: route.params.state,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "service",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/services/:id/edit",
    name: "ServiceEdit",
    component: () => import("../views/ServiceEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "service",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/services/:id",
    name: "ServiceView",
    component: () => import("../views/ServiceView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "service",
      requiredRight: "viewUI",
    },
  },
];
