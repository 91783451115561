import { deleteObject, ref } from "firebase/storage";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

export const deleteFile = async (path: string, name: string): Promise<void> => {
  try {
    await deleteObject(ref(firebase.storage, `${path}/${name}`));
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageModel.deleteFile", { path, name }, error);
  }
};
