
import { defineComponent, onMounted, Ref, ref } from "vue";
import { compareAsc } from "date-fns";
import JSZip from "jszip";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationHelpers } from "@/features/modules/examination/helpers/ExaminationHelpers";
import { examinationScheduleModel } from "../models/ExaminationScheduleModel";
import { ExaminationSchedulePaper } from "../papers/ExaminationSchedulePaper";
import { ExaminationScheduleWithSignaturePaper } from "../papers/ExaminationScheduleWithSignaturePaper";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

import ExaminationStateValue from "@/features/modules/examination/components/ExaminationStateValue.vue";

export default defineComponent({
  name: "ExaminationScheduleView",
  components: {
    ExaminationStateValue,
  },
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<Examination[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companies: Ref<Company[]> = ref([]);
    const companyId: Ref<string | undefined> = ref("all");
    const doctorId: Ref<string | undefined> = ref("all");
    const doctors: Ref<Doctor[]> = ref([]);
    const startDate: Date = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate: Date = new Date();
    endDate.setHours(23, 59, 59, 999);
    const period: Ref<Date[]> = ref([startDate, endDate]);
    const state: Ref<"all" | "done"> = ref("all");

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;
    };

    const loadAction = async () => {
      const allCompany: Company = new Company();
      allCompany.id = "all";
      allCompany.name = t("gen.all");
      companies.value = [allCompany, ...(await companyModel.getActiveCompanies())];

      const allDoctor: Doctor = new Doctor();
      allDoctor.id = "all";
      allDoctor.fullName = t("gen.all");
      doctors.value = [allDoctor, ...(await doctorModel.getDocuments())];
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value === undefined) throw new Error("statExaminationSchedule.companyIdUndefined");
      if (doctorId.value === undefined) throw new Error("statExaminationSchedule.doctorIdUndefined");
      if (period.value.length !== 2 || period.value[0] === undefined || period.value[1] === undefined) {
        throw new Error("statExaminationSchedule.periodUndefined");
      }
      if (compareAsc(period.value[0], period.value[1]) > 0) throw new Error("statExaminationSchedule.periodInvalid");

      const examinations: Examination[] = await examinationScheduleModel.searchExaminations(
        period.value[0],
        period.value[1],
        companyId.value,
        doctorId.value,
        state.value
      );

      const sorter: FirestoreSorter<Examination> = new FirestoreSorter(examinations);
      sorter.addSortCriteria("time", "asc", "date");
      sorter.addSortCriteria("codeSort", "asc", "string");

      results.value = sorter.sort();

      showResults.value = true;
    };

    const printPaper = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const examinationSchedulePaper: ExaminationSchedulePaper = new ExaminationSchedulePaper(
            companyId.value as string,
            doctorId.value as string,
            period.value[0],
            period.value[1],
            results.value,
            d,
            n,
            t
          );
          examinationSchedulePaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const printPaperWithSignatures = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const examinationScheduleWithSignaturePaper: ExaminationScheduleWithSignaturePaper = new ExaminationScheduleWithSignaturePaper(
            companyId.value as string,
            doctorId.value as string,
            period.value[0],
            period.value[1],
            results.value,
            d,
            n,
            t
          );
          examinationScheduleWithSignaturePaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const downloadFiles = async (field: string): Promise<void> => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const zip: JSZip = new JSZip();
          for (const examination of results.value) {
            if (examination[field as keyof Examination] === undefined) continue;

            const file: StorageFile = examination[field as keyof Examination] as StorageFile;
            const fileUrl: string = await storageModel.getDownloadLink(
              ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
              file.uniqueName as string
            );

            const response = await fetch(fileUrl);
            if (response.status !== 200) throw new Error(`Unable to download file. HTTP status: ${response.status}`);
            const fileBlob: Blob = await response.blob();

            const fileBase64: string | undefined = await StorageHelpers.blobToBase64(fileBlob);
            if (fileBase64 === undefined) throw new Error("Unable to convert file to base64");

            zip.file(file.uniqueName as string, fileBase64, { base64: true });
          }

          if (Object.keys(zip.files).length > 0) {
            const content: string = await zip.generateAsync({ type: "base64" });

            const element: HTMLAnchorElement = document.createElement("a");
            element.setAttribute("href", `data:application/zip;base64,${content}`);
            element.setAttribute("download", "allegati.zip");
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      companies,
      companyId,
      d,
      dataChanged,
      doctorId,
      doctors,
      downloadFiles,
      handleKeydown,
      handleSubmit,
      n,
      period,
      printPaper,
      printPaperWithSignatures,
      results,
      showResults,
      state,
      t,
    };
  },
});
