import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CostList } from "@/features/modules/costList/objects/CostList";
import { costListModel } from "../CostListModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const cloneCostList = async (costList: CostList): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    const newCostList: CostList = new CostList(costList.toFirestore());
    newCostList.id = "new";

    return costListModel.createDocument(newCostList);
  } catch (error: unknown) {
    appFaultModel.catchAppError("CloneListModel.cloneCostList", { costList }, error);
    return "ERROR";
  }
};
