export const it = {
  externalTest: {
    branch: "Sede",
    code: "Identificativo",
    company: "Azienda",
    companyAndBranch: "Azienda e sede",
    date: "Data",
    dateError: "Selezionare una data valida",
    detail: "Dettagli",
    employee: "Lavoratore",
    externalTest: "Accertamento esterno",
    externalTests: "Accertamenti esterni",
    examination: "Incluso in visita medica",
    examinationDetails: "Visita medica n. {codeDisplay} del {date}",
    file: "Allegato",
    importableExternalTests: "Accertamenti esterni importabili",
    invoiced: "Fatturato",
    period: "Periodo",
    readOnlyTestForm: "Scheda accertamento non modificabile in quanto l'accertamento esterno è già incluso in una visita medica",
    state: "Stato",
    states: {
      completed: "Completato",
      draft: "Bozza",
      included: "Incluso",
    },
    supplier: "Fornitore",
    testForm: "Stato scheda",
    testType: "Accertamento",
  },
  navigation: {
    externalTest: "Accertamenti esterni",
  },
  buttons: {
    goToExamination: "Vai alla visita medica",
    setCompletedState: "Segna come completato",
  },
};
