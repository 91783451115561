import { Examination } from "./Examination";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { DateStrictField, NumberField, ObjectField, StringStrictField } from "@/core/fields";

export class LinkedExamination extends LinkedFirestoreOfflineDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public date: Date = new Date();
  public company: LinkedCompany | undefined = undefined;
  public employee: LinkedEmployee | undefined = undefined;
  public doctor: LinkedDoctor | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromExamination(examination: Examination): LinkedExamination {
    const linkedExamination: LinkedExamination = new LinkedExamination();
    linkedExamination.id = examination.id;
    linkedExamination.code = examination.code;
    linkedExamination.codeSort = examination.codeSort;
    linkedExamination.codeDisplay = examination.codeDisplay;
    linkedExamination.year = examination.year;
    linkedExamination.date = examination.date;
    linkedExamination.company = examination.company;
    linkedExamination.employee = examination.employee;
    linkedExamination.doctor = examination.doctor;

    return linkedExamination;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedExamination {
    super.fromFirestore(data);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.employee = ObjectField.fromFirestore<LinkedEmployee>(data.employee, (value) => new LinkedEmployee(value));
    this.doctor = ObjectField.fromFirestore<LinkedDoctor>(data.doctor, (value) => new LinkedDoctor(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.employee = ObjectField.toFirestore<LinkedEmployee>(this.employee, (value) => value.toFirestore());
    firestoreData.doctor = ObjectField.toFirestore<LinkedDoctor>(this.doctor, (value) => value.toFirestore());

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedExamination {
    super.fromOfflineCache(data);

    this.code = NumberField.fromOfflineCache(data.code);
    this.codeSort = NumberField.fromOfflineCache(data.codeSort);
    this.codeDisplay = StringStrictField.fromOfflineCache(data.codeDisplay, "");
    this.year = NumberField.fromOfflineCache(data.year);
    this.date = DateStrictField.fromOfflineCache(data.date, new Date());
    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => {
      const company: LinkedCompany = new LinkedCompany();
      return company.fromOfflineCache(value);
    });
    this.employee = ObjectField.fromOfflineCache<LinkedEmployee>(data.employee, (value) => {
      const employee: LinkedEmployee = new LinkedEmployee();
      return employee.fromOfflineCache(value);
    });
    this.doctor = ObjectField.fromOfflineCache<LinkedDoctor>(data.doctor, (value) => {
      const doctor: LinkedDoctor = new LinkedDoctor();
      return doctor.fromOfflineCache(value);
    });

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.code = NumberField.toOfflineCache(this.code);
    firestoreData.codeSort = NumberField.toOfflineCache(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toOfflineCache(this.codeDisplay);
    firestoreData.year = NumberField.toOfflineCache(this.year);
    firestoreData.date = DateStrictField.toOfflineCache(this.date);
    firestoreData.company = ObjectField.toOfflineCache<LinkedCompany>(this.company, (value) => value.toOfflineCache());
    firestoreData.employee = ObjectField.toOfflineCache<LinkedEmployee>(this.employee, (value) => value.toOfflineCache());
    firestoreData.doctor = ObjectField.toOfflineCache<LinkedDoctor>(this.doctor, (value) => value.toOfflineCache());

    return firestoreData;
  }
}
