export const it = {
  service: {
    branch: "Sede",
    branchError: "Selezionare una sede valida",
    code: "Identificativo",
    company: "Azienda",
    companyAndBranch: "Azienda e sede",
    companyError: "Selezionare una azienda valida",
    date: "Data",
    dateError: "Selezionare una data valida",
    doctor: "Medico",
    doctorError: "Selezionare un medico valido",
    doctorTitle: "Dott.",
    duration: "Durata (ore)",
    durationError: "Inserire una durata valida",
    invoice: "Fattura",
    nextServiceDate: "Data prossima prestazione",
    nextServiceDateError: "Selezionare una data valida",
    notes: "Note",
    of: "del",
    period: "Periodo",
    service: "Prestazione",
    services: "Prestazioni",
    state: "Stato",
    states: {
      billed: "Fatturata",
      completed: "Completata",
      draft: "Bozza",
    },
    type: "Tipo",
    typeError: "Selezionare un tipo di prestazione valido",
  },
  navigation: {
    service: "Prestazioni",
  },
};
