import { RouteRecordRaw } from "vue-router";

export const vaccinationRoutes: RouteRecordRaw[] = [
  {
    path: "/employees/:employeeId/vaccinations/:vaccinationId/edit",
    name: "VaccinationEdit",
    component: () => import("../views/VaccinationEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vaccination",
      requiredRight: "viewUI",
    },
  },
];
