import * as _ from "lodash";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Duty } from "@/features/modules/duty/objects/Duty";
import { dutyModel } from "@/features/modules/duty/models/DutyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const exportDuty = async (duty: Duty, importableDuties: Duty[]): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (exportDuty == undefined || importableDuties == undefined) throw new Error("invalid parameters");

    // create new
    const newDuty: Duty = _.cloneDeep(duty);

    // save
    return dutyModel.createDocument(newDuty);
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyDutyModel.exportDuty", { duty, importableDuties }, error);
    return "ERROR";
  }
};
