import { RouteRecordRaw } from "vue-router";

export const companyDutyRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:companyId/duties/:companyDutyId/edit",
    name: "CompanyDutyEdit",
    component: () => import("../views/CompanyDutyEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "companyDuty",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:companyId/duties/:companyDutyId",
    name: "CompanyDutyView",
    component: () => import("../views/CompanyDutyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "companyDuty",
      requiredRight: "viewUI",
    },
  },
];
