import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";

export const getQueueCount = async (): Promise<number> => {
  try {
    const documents: Record<string, unknown>[] = await indexedDbModel.getDocuments("offlineEdits");
    return documents.length;
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.getQueueCount", {}, error);
    return 0;
  }
};
