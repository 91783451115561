import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { externalTestModel } from "../ExternalTestModel";
import { ExternalTestState } from "@/features/modules/externalTest/objects/ExternalTestState";
import { firmModel } from "@/features/modules/firm/models/FirmModel";

export const getExternalTestsToIncludeByCompanyAndEmployee = async (companyId: string, employeeId: string): Promise<ExternalTest[]> => {
  try {
    if (companyId === undefined) throw new Error("companyId is undefined");
    if (employeeId === undefined) throw new Error("employeeId is undefined");

    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<ExternalTest> = query(
      externalTestModel.getPathReference().withConverter(externalTestModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("company.id", "==", companyId),
      where("employee.id", "==", employeeId),
      where("state", "==", ExternalTestState.Completed)
    );

    const snapshot: QuerySnapshot<ExternalTest> = await getDocs(externalTestModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExternalTestModel.getExternalTestsToIncludeByCompanyAndEmployee", { companyId, employeeId }, error);
    return [];
  }
};
