import { CompanyPriceList } from "../objects/CompanyPriceList";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import { getCompanyPriceListsByCompanyAndFirm, isPeriodAvailable } from "./methods";

export class CompanyPriceListModel extends FirestoreModel<CompanyPriceList> {
  public constructor() {
    super(() => new CompanyPriceList(), "companyPriceLists", LockPolicy.DiscardUnsyncedChanges, "companyPriceList");
    this.parentCollectionName = "companies";
  }

  public async getDocument(companyPriceListId: string, parentId?: string): Promise<CompanyPriceList> {
    return super.getDocument(companyPriceListId, parentId);
  }

  public async createDocument(companyPriceList: CompanyPriceList, parentId?: string): Promise<string> {
    const firm: Firm = await firmModel.getSelectedFirm();
    companyPriceList.firm = LinkedFirm.createFromFirm(firm);

    return super.createDocument(companyPriceList, parentId);
  }

  public async updateDocument(companyPriceList: CompanyPriceList, parentId?: string): Promise<void> {
    return super.updateDocument(companyPriceList, parentId);
  }

  public async deleteDocument(companyPriceList: CompanyPriceList, parentId?: string): Promise<boolean> {
    return super.deleteDocument(companyPriceList, parentId);
  }

  public async getCompanyPriceListsByCompanyAndFirm(companyId: string): Promise<CompanyPriceList[]> {
    return getCompanyPriceListsByCompanyAndFirm(companyId);
  }

  public async isPeriodAvailable(companyId: string, from: Date, to: Date | undefined, companyPriceListId?: string): Promise<boolean> {
    return isPeriodAvailable(companyId, from, to, companyPriceListId);
  }
}

export const companyPriceListModel: CompanyPriceListModel = new CompanyPriceListModel();
