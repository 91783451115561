
import { computed, defineComponent, onMounted, Ref, ref, WritableComputedRef } from "vue";
import { useRoute } from "vue-router";

import { DataTableFilterMeta, DataTableFilterMetaData } from "primevue/datatable";
import { FilterMatchMode } from "primevue/api";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { KoruBrowserModule } from "../objects/KoruBrowserModule";
import { config } from "@/core/modules/config/objects/Config";
import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { Filter } from "../objects/Filter";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

import Filters from "../components/Filters.vue";

export default defineComponent({
  name: "KoruBrowser",
  components: {
    Filters,
  },
  props: {
    additionalClass: { type: String, default: "" },
    module: { type: KoruBrowserModule, required: true },
  },
  emits: ["update:module"],
  setup(props, { emit }) {
    const { t } = useLocale();
    const store = useStore();

    const user: User = store.getters[storeTypes.getters.getUser];

    const moduleRef = computed({
      get: () => props.module as KoruBrowserModule<unknown>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string =
      moduleRef.value.explicitRequiredModule !== undefined ? moduleRef.value.explicitRequiredModule : (route.meta.requiredModule as string);

    const genFilters: Ref<DataTableFilterMeta> = ref({ global: { value: null, matchMode: FilterMatchMode.CONTAINS } as DataTableFilterMetaData });
    const filtersRef: WritableComputedRef<DataTableFilterMeta> = computed({
      get: () => {
        const allFilters: DataTableFilterMeta = { ...genFilters.value };
        if (moduleRef.value.filters.value === undefined) return allFilters;
        for (const filter of moduleRef.value.filters.value) {
          allFilters[filter.name] = filter.browserFilter;
        }
        return allFilters;
      },
      set: (val) => {
        genFilters.value = { global: val.global };
        for (const key of Object.keys(val)) {
          if (key === "global") continue;
          const filter: Filter | undefined = moduleRef.value.filters.value.find((f) => f.name === key);
          if (filter === undefined) continue;
          filter.browserFilter = val[key] as DataTableFilterMetaData;
        }
      },
    });

    const lastSelectedId: string = localStorage.getItem(`koruLastSelectedId-${props.module.suffix}`) ?? "";
    const rowClass: (document: FirestoreDocument) => string = (document: FirestoreDocument) => {
      const localResult: string | undefined = document.id === lastSelectedId ? "last-selected-document" : undefined;
      const objectResult: string | undefined = props.module.rowClass !== undefined ? props.module.rowClass(document) : undefined;

      const combinedResult = `${localResult ?? ""} ${objectResult ?? ""}`.trim();

      return combinedResult;
    };

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          try {
            await moduleRef.value.onMountedAction();
            await moduleRef.value.onLoadAction();
          } catch (error: unknown) {
            if (error instanceof AppError) {
              error.addToStack("KoruBrowser.handleLoad", {}, error);
            }
            throw error;
          }
        },
        () => {
          if (moduleRef.value.loadingStop) store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const loadWithFilters = async () => {
      await AppHelpers.tryOrError(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);
          if (moduleRef.value.onLoadWithFiltersAction !== undefined) await moduleRef.value.onLoadWithFiltersAction();
        },
        () => {
          if (moduleRef.value.loadingStop) store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const deleteAction = async (document: FirestoreDocument) => {
      await AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);
          const result: boolean = await moduleRef.value.onDeleteAction(document);

          if (result === true) {
            const deleteIndex = moduleRef.value.documents.value.indexOf(document, 0);
            if (deleteIndex > -1) {
              moduleRef.value.documents.value.splice(deleteIndex, 1);
            }
            ToastHelpers.showToastWithSuccess("delete", t);
          } else {
            ToastHelpers.showToastWithError("delete", undefined, t);
          }
        },
        "delete",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const createAction = () => {
      if (moduleRef.value.createPath !== undefined) {
        Navigation.navigateTo(moduleRef.value.createPath);
      } else {
        Navigation.navigateTo(`${moduleRef.value.path}/new/edit`);
      }
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem: Ref<FirestoreDocument | undefined> = ref(undefined);

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      if (deleteItem.value === undefined) return;
      deleteAction(deleteItem.value);
    };

    const getContextCommands = (document: FirestoreDocument) => {
      const renderContextCommands: ContextCommand[] = [];

      if (moduleRef.value.canView && (requiredModule == undefined || user.canRead(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.view"),
          icon: "pi pi-eye",
          command: (object: Record<string, unknown>) => {
            Navigation.navigateTo(`${moduleRef.value.path}/${(object.reference as Record<string, unknown>).id}`);
          },
        } as ContextCommand);
      }

      if (moduleRef.value.canEdit && (requiredModule == undefined || user.canUpdate(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.edit"),
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            Navigation.navigateTo(`${moduleRef.value.path}/${(object.reference as Record<string, unknown>).id}/edit`);
          },
        } as ContextCommand);
      }

      if (moduleRef.value.canDelete && (requiredModule == undefined || user.canDelete(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.delete"),
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as FirestoreDocument;
            showDeleteItemDialog.value = true;
          },
        } as ContextCommand);
      }

      if (moduleRef.value.contextCommands !== undefined && moduleRef.value.contextCommands.length > 0) {
        const contextCommandsLength = renderContextCommands.length;
        for (const contextCommand of moduleRef.value.contextCommands) {
          if (contextCommand.condition(document) == true) {
            if (renderContextCommands.length == contextCommandsLength && contextCommandsLength > 0) {
              renderContextCommands.push({ label: "separator" } as ContextCommand);
            }
            renderContextCommands.push(contextCommand);
          }
        }
      }

      if (renderContextCommands.length === 0) {
        renderContextCommands.push({
          label: t("buttons.noAction"),
          icon: "pi pi-times",
          condition: () => true,
          command: () => {
            return;
          },
        } as ContextCommand);
      }

      return renderContextCommands;
    };

    const toggleFilterSelectors = (event: Event) => {
      eventBus.emit(`toggleFilterSelectors-${moduleRef.value.suffix}`, event);
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      config,
      createAction,
      DataHelpers,
      deleteItemAction,
      deleteItem,
      filtersRef,
      getContextCommands,
      loadWithFilters,
      moduleRef,
      requiredModule,
      rowClass,
      showDeleteItemDialog,
      t,
      toggleFilterSelectors,
      user,
    };
  },
});
