import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Service } from "@/features/modules/service/objects/Service";
import { serviceModel } from "../ServiceModel";

export const getServicesWithFilters = async (
  startDate: Date,
  endDate: Date,
  companyId: string,
  doctorId: string,
  state: string
): Promise<Service[]> => {
  try {
    if (startDate == undefined) throw new Error("startDate is undefined");
    if (endDate == undefined) throw new Error("endDate is undefined");

    const firmId: string = firmModel.getSelectedFirmId();

    let snapshotQuery: Query<Service> = query(
      serviceModel.getPathReference().withConverter(serviceModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("firm.id", "==", firmId)
    );

    if (companyId !== "all") snapshotQuery = query(snapshotQuery, where("company.id", "==", companyId));
    if (doctorId !== "all") snapshotQuery = query(snapshotQuery, where("doctor.id", "==", doctorId));
    if (state !== "all") snapshotQuery = query(snapshotQuery, where("state", "==", state));

    const snapshot: QuerySnapshot<Service> = await getDocs(serviceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("ServiceModel.getServicesWithFilters", { startDate, endDate, companyId, doctorId, state }, error);
    return [];
  }
};
