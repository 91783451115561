import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Duty } from "../objects/Duty";
import { dutyModel } from "./DutyModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class DutyOfflineModel extends OfflineModuleModel<Duty> {
  public constructor() {
    super(() => new Duty(), "duties", "duty.duties", undefined);
  }

  public async downloadCache(): Promise<Duty[]> {
    try {
      return dutyModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("DutyOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const dutyOfflineModel: DutyOfflineModel = new DutyOfflineModel();
