export const en = {
  role: {
    allowNewUser: "Allow new users",
    allowNewUserError: "Select a value",
    hasKoruAccess: "Allow KORU access",
    hasKoruAccessError: "Select a value",
    module: "Module",
    name: "Name",
    nameError: "Insert a valid name",
    role: "Role",
    rights: {
      create: "Create",
      delete: "Delete",
      fieldError: "Select a value",
      modes: {
        all: "All",
        custom: "Custom",
        owned: "Owned",
        none: "None",
      },
      read: "Read",
      toggle: "Action",
      update: "Update",
      viewUI: "View UI",
    },
    roles: "Roles",
    types: {
      core: "Core",
      features: "Features",
    },
  },
  navigation: {
    role: "Roles",
  },
};
