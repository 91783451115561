import { AuthCredential, EmailAuthProvider, reauthenticateWithCredential, updateEmail as updateFirebaseEmail } from "firebase/auth";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/UserModel";

import { DateTimeField, StringField } from "@/core/fields";

export const updateEmail = async (newEmail: string, password: string): Promise<void> => {
  try {
    if (firebase.auth.currentUser === null || firebase.auth.currentUser.email === null) {
      throw new Error("Invalid data");
    }

    const credentials: AuthCredential = EmailAuthProvider.credential(firebase.auth.currentUser.email, password);
    await reauthenticateWithCredential(firebase.auth.currentUser, credentials);

    if (firebase.auth.currentUser == null) {
      throw new Error("Unable to reauthenticate");
    }

    await updateFirebaseEmail(firebase.auth.currentUser, newEmail);

    const user: User = store.getters[storeTypes.getters.getUser];
    if (user === undefined) {
      throw new Error("Unable to get user");
    }

    batch.update(userModel.getDocumentReference(user.id), {
      email: StringField.toFirestore(newEmail),
      updatedAt: DateTimeField.toFirestore(new Date()),
      updatedBy: StringField.toFirestore(user.id),
    });
    batch.commit();

    const newCredentials: AuthCredential = EmailAuthProvider.credential(newEmail, password);
    await reauthenticateWithCredential(firebase.auth.currentUser, newCredentials);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AuthModel.updateEmail", { newEmail, password: "HIDDEN" }, error);
  }
};
