import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Service } from "../objects/Service";

import {
  getDraftServicesByFirm,
  getNextServiceCodeByDateAndFirm,
  getServicesForDashboard,
  getServicesToInvoiceByCompanyAndPeriodAndFirm,
  getServicesToInvoiceByPeriodAndFirm,
  getServicesWithFilters,
} from "./methods";

export class ServiceModel extends FirestoreModel<Service> {
  public constructor() {
    super(() => new Service(), "services", LockPolicy.DiscardUnsyncedChanges, "service");
  }

  public async getDocument(serviceId: string): Promise<Service> {
    return super.getDocument(serviceId);
  }

  public async createDocument(service: Service): Promise<string> {
    await service.setCodes();
    return super.createDocument(service);
  }

  public async updateDocument(service: Service): Promise<void> {
    return super.updateDocument(service);
  }

  public async deleteDocument(service: Service): Promise<boolean> {
    return super.deleteDocument(service);
  }

  public async getDraftServicesByFirm(): Promise<Service[]> {
    return getDraftServicesByFirm();
  }

  public async getNextServiceCodeByDateAndFirm(date: Date): Promise<number> {
    return getNextServiceCodeByDateAndFirm(date);
  }

  public async getServicesForDashboard(): Promise<Service[]> {
    return getServicesForDashboard();
  }

  public async getServicesToInvoiceByCompanyAndPeriodAndFirm(companyId: string, startDate: Date, endDate: Date): Promise<Service[]> {
    return getServicesToInvoiceByCompanyAndPeriodAndFirm(companyId, startDate, endDate);
  }

  public async getServicesToInvoiceByPeriodAndFirm(startDate: Date, endDate: Date): Promise<Service[]> {
    return getServicesToInvoiceByPeriodAndFirm(startDate, endDate);
  }

  public async getServicesWithFilters(startDate: Date, endDate: Date, companyId: string, doctorId: string, state: string): Promise<Service[]> {
    return getServicesWithFilters(startDate, endDate, companyId, doctorId, state);
  }
}

export const serviceModel: ServiceModel = new ServiceModel();
