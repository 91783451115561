import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { Survey } from "../objects/Survey";
import { surveyOfflineModel } from "./SurveyOfflineModel";

export class SurveyModel extends FirestoreModel<Survey> {
  public constructor() {
    super(() => new Survey(), "surveys", LockPolicy.DiscardUnsyncedChanges, "survey");
    this.offlineModuleModel = surveyOfflineModel;
  }

  public async getDocuments(): Promise<Survey[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(surveyId: string): Promise<Survey> {
    return super.getDocument(surveyId);
  }

  public async createDocument(survey: Survey): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    survey.order = documentCounter + 1;

    return super.createDocument(survey);
  }

  public async updateDocument(survey: Survey): Promise<void> {
    return super.updateDocument(survey);
  }

  public async deleteDocument(survey: Survey): Promise<boolean> {
    return super.deleteDocument(survey);
  }
}

export const surveyModel: SurveyModel = new SurveyModel();
