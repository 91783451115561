import { CollectionReference, DocumentData, collection } from "firebase/firestore";

import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";

export const getPathReference = <T extends FirestoreDocument>(model: FirestoreModel<T>, parentId?: string): CollectionReference<DocumentData> => {
  let collectionPath: string = model.collectionName;
  if (model.parentCollectionName !== undefined && parentId !== undefined) {
    collectionPath = `${model.parentCollectionName}/${parentId}/${model.collectionName}`;
  }
  return collection(firebase.firestore, collectionPath);
};
