import { Query, query, where } from "firebase/firestore";

import { Doc } from "@/features/modules/doc/objects/Doc";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocModel } from "@/features/modules/doc/models/DocModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDocHelpers } from "../helpers/EmployeeDocHelpers";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getExpiredEmployeeDocs, getExpiringEmployeeDocs, getMissingEmployeeDocs } from "./methods";

export class EmployeeDocModel extends DocModel {
  public constructor() {
    super();
    this.collectionName = "employeeDocs";
    this.roleModule = "employeeDoc";
    this.parentCollectionName = "employees";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<Doc[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    let customQuery: Query<Doc> | undefined;
    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      const employee: Employee = await employeeModel.getDocument(parentId);
      const employeeCompanies: string[] = Object.keys(employee.companies);
      if (doctor === undefined || doctor.authorizedCompaniesIds.filter((id) => employeeCompanies.includes(id)).length === 0)
        throw new Error(`User doesn't have rights to read employee #${parentId}`);
      customQuery = query(
        this.getPathReference(parentId).withConverter(this.firestoreConverter),
        where("type.visibleFor", "array-contains", DocTypeSource.Doctor)
      );
    }

    return super.getDocuments(sortCriterias, parentId, customQuery);
  }

  public async deleteDocument(employeeDoc: Doc, parentId?: string): Promise<boolean> {
    const result: boolean = await super.deleteDocument(employeeDoc, parentId);
    if (result === false) return false;

    for (const file of employeeDoc.files) {
      await storageModel.deleteFile(EmployeeDocHelpers.getFolderPath(parentId), file.uniqueName as string);
    }
    return true;
  }

  public async getExpiredEmployeeDocs(): Promise<DocExpiration[]> {
    return getExpiredEmployeeDocs();
  }

  public async getExpiringEmployeeDocs(): Promise<DocExpiration[]> {
    return getExpiringEmployeeDocs();
  }

  public async getMissingEmployeeDocs(): Promise<DocExpiration[]> {
    return getMissingEmployeeDocs();
  }
}

export const employeeDocModel: EmployeeDocModel = new EmployeeDocModel();
