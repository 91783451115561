import { User as FirebaseUser, onAuthStateChanged } from "firebase/auth";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { authModel } from "../AuthModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/UserModel";

export const init = async (): Promise<void> => {
  try {
    return new Promise<void>((resolve) => {
      onAuthStateChanged(firebase.auth, async (user: FirebaseUser | null): Promise<void> => {
        if (user) {
          const dbUser: User = await userModel.getDocument(user.uid);
          store.commit(storeTypes.mutations.setUser, dbUser);
          resolve();
        } else {
          store.commit(storeTypes.mutations.setUser, undefined);
          resolve();
        }
      });
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("AuthModel.init", {}, error);
    await authModel.logout();
  } finally {
    store.commit(storeTypes.mutations.loadingStop);
  }
};
