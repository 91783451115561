
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { Firm } from "../objects/Firm";
import { firmModel } from "../models/FirmModel";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";

export default defineComponent({
  name: "FirmSelector",
  setup() {
    const firmId = ref();
    const firms: Ref<Firm[]> = ref([]);

    const loadFirms = async () => {
      AppHelpers.tryOrError(async () => {
        firms.value = await firmModel.getDocuments();
        firmId.value = firmModel.getSelectedFirmId();
      });
    };

    const firmChanged = async () => {
      AppHelpers.tryOrError(async () => {
        const selectedFirm: Firm | undefined = firms.value.find((firm) => firm.id == firmId.value);
        if (selectedFirm === undefined) return;

        firmModel.setSelectedFirmId(selectedFirm.id);

        await Navigation.navigateTo("/");
        eventBus.emit("dashboardRefresh");
      });
    };

    onMounted(() => {
      loadFirms();
    });

    return {
      firmChanged,
      firmId,
      firms,
    };
  },
});
