import { DocumentReference } from "firebase/firestore";

import { ExternalTestState } from "./ExternalTestState";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { LinkedExamination } from "@/features/modules/examination/objects/LinkedExamination";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LinkedSupplier } from "@/features/modules/supplier/objects/LinkedSupplier";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { TestForm } from "@/features/modules/testForm/objects/TestForm";

import { DateField, EnumField, NumberField, ObjectField, StringStrictField } from "@/core/fields";

import { AudioTestForm } from "@/features/modules/testForm/objects/AudioTestForm";
import { GenTestForm } from "@/features/modules/testForm/objects/GenTestForm";
import { LinkedInvoice } from "@/features/modules/invoice/objects/LinkedInvoice";
import { SpiroTestForm } from "@/features/modules/testForm/objects/SpiroTestForm";
import { VisioTestForm } from "@/features/modules/testForm/objects/VisioTestForm";

export class ExternalTest extends FirestoreDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public firm: LinkedFirm | undefined = undefined;
  public employee: LinkedEmployee | undefined = undefined;
  public company: LinkedCompany | undefined = undefined;
  public branch: LinkedBranch | undefined = undefined;
  public supplier: LinkedSupplier | undefined = undefined;
  public testType: LinkedTestType | undefined = undefined;
  public date: Date | undefined = undefined;
  public file: StorageFile | undefined = undefined;
  public testForm: TestForm | undefined = undefined;
  public state: ExternalTestState = ExternalTestState.Draft;
  public examination: LinkedExamination | undefined = undefined;
  public invoice: LinkedInvoice | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): ExternalTest {
    super.fromFirestore(data, id, firestoreRef);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.employee = ObjectField.fromFirestore<LinkedEmployee>(data.employee, (value) => new LinkedEmployee(value));
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.branch = ObjectField.fromFirestore<LinkedBranch>(data.branch, (value) => new LinkedBranch(value));
    this.supplier = ObjectField.fromFirestore<LinkedSupplier>(data.supplier, (value) => new LinkedSupplier(value));
    this.testType = ObjectField.fromFirestore<LinkedTestType>(data.testType, (value) => new LinkedTestType(value));
    this.date = DateField.fromFirestore(data.date);
    this.file = ObjectField.fromFirestore<StorageFile>(data.file, (value) => new StorageFile(value));
    this.testFormFromFirestore(data);
    this.state = EnumField.fromFirestore<ExternalTestState>(data.state, Object.values(ExternalTestState), ExternalTestState.Draft);
    this.examination = ObjectField.fromFirestore<LinkedExamination>(data.examination, (value) => new LinkedExamination(value));
    this.invoice = ObjectField.fromFirestore<LinkedInvoice>(data.invoice, (value) => new LinkedInvoice(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.employee = ObjectField.toFirestore<LinkedEmployee>(this.employee, (value) => value.toFirestore());
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.branch = ObjectField.toFirestore<LinkedBranch>(this.branch, (value) => value.toFirestore());
    firestoreData.supplier = ObjectField.toFirestore<LinkedSupplier>(this.supplier, (value) => value.toFirestore());
    firestoreData.testType = ObjectField.toFirestore<LinkedTestType>(this.testType, (value) => value.toFirestore());
    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.file = ObjectField.toFirestore<StorageFile>(this.file, (value) => value.toFirestore());
    firestoreData.testForm = this.testFormToFirestore();
    firestoreData.state = EnumField.toFirestore<ExternalTestState>(this.state, ExternalTestState.Draft);
    firestoreData.examination = ObjectField.toFirestore<LinkedExamination>(this.examination, (value) => value.toFirestore());
    firestoreData.invoice = ObjectField.toFirestore<LinkedInvoice>(this.invoice, (value) => value.toFirestore());

    return firestoreData;
  }

  public setCodes(code: number): void {
    this.code = code;
    this.codeSort = ((this.date?.getFullYear() ?? new Date().getFullYear()) - 2000) * 1000000 + this.code;
    this.codeDisplay = `${this.code}/${this.date?.getFullYear() ?? new Date().getFullYear()}`;
    this.year = this.date?.getFullYear() ?? new Date().getFullYear();
  }

  public createTestForm(): void {
    if (this.testType?.formName === undefined) return;
    if (this.testType?.formName === "audioTestForm") {
      this.testForm = new AudioTestForm();
    } else if (this.testType?.formName === "genTestForm") {
      this.testForm = new GenTestForm();
    } else if (this.testType?.formName === "spiroTestForm") {
      this.testForm = new SpiroTestForm();
    } else if (this.testType?.formName === "visioTestForm") {
      this.testForm = new VisioTestForm();
    }
  }

  public testFormFromFirestore(data: Record<string, unknown>): void {
    if (this.testType?.formName === undefined) {
      this.testForm = undefined;
      return;
    }
    if (this.testType?.formName === "audioTestForm") {
      this.testForm = ObjectField.fromFirestore<AudioTestForm>(data.testForm, (value) => new AudioTestForm(value));
    } else if (this.testType?.formName === "genTestForm") {
      this.testForm = ObjectField.fromFirestore<GenTestForm>(data.testForm, (value) => new GenTestForm(value));
    } else if (this.testType?.formName === "spiroTestForm") {
      this.testForm = ObjectField.fromFirestore<SpiroTestForm>(data.testForm, (value) => new SpiroTestForm(value));
    } else if (this.testType?.formName === "visioTestForm") {
      this.testForm = ObjectField.fromFirestore<VisioTestForm>(data.testForm, (value) => new VisioTestForm(value));
    }
  }

  public testFormToFirestore(): Record<string, unknown> | null {
    if (this.testType?.formName === undefined) return null;
    if (this.testType?.formName === "audioTestForm") {
      return ObjectField.toFirestore<AudioTestForm>(this.testForm as AudioTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "genTestForm") {
      return ObjectField.toFirestore<GenTestForm>(this.testForm as GenTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "spiroTestForm") {
      return ObjectField.toFirestore<SpiroTestForm>(this.testForm as SpiroTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "visioTestForm") {
      return ObjectField.toFirestore<VisioTestForm>(this.testForm as VisioTestForm, (value) => value.toFirestore());
    }
    return null;
  }
}
