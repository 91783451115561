import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Firm } from "../objects/Firm";
import { firmModel } from "./FirmModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class FirmOfflineModel extends OfflineModuleModel<Firm> {
  public constructor() {
    super(() => new Firm(), "firms", "firm.firms", undefined);
  }

  public async downloadCache(): Promise<Firm[]> {
    try {
      return firmModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("FirmOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const firmOfflineModel: FirmOfflineModel = new FirmOfflineModel();
