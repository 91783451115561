
import { computed, defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { KoruTinyBrowserModule } from "../objects/KoruTinyBrowserModule";
import { config } from "@/core/modules/config/objects/Config";
import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "KoruTinyBrowser",
  props: {
    additionalClass: { type: String, default: "" },
    module: { type: KoruTinyBrowserModule, required: true },
  },
  emits: ["update:module"],
  setup(props, { emit }) {
    const { t } = useLocale();
    const store = useStore();

    const user: User = store.getters[storeTypes.getters.getUser];

    const moduleRef = computed({
      get: () => props.module as KoruTinyBrowserModule<unknown>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string =
      moduleRef.value.explicitRequiredModule !== undefined ? moduleRef.value.explicitRequiredModule : (route.meta.requiredModule as string);

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          try {
            await moduleRef.value.onMountedAction();
            await moduleRef.value.onLoadAction();
          } catch (error: unknown) {
            if (error instanceof AppError) {
              error.addToStack("KoruBrowser.handleLoad", {}, error);
            }
            throw error;
          }
        },
        () => {
          if (moduleRef.value.loadingStop) store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const getContextCommands = (document: FirestoreDocument) => {
      const renderContextCommands: ContextCommand[] = [];

      if (moduleRef.value.canView && (requiredModule == undefined || user.canRead(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.view"),
          icon: "pi pi-eye",
          command: (object: Record<string, unknown>) => {
            Navigation.navigateTo(`${moduleRef.value.path}/${(object.reference as Record<string, unknown>).id}`);
          },
        } as ContextCommand);
      }

      if (moduleRef.value.canEdit && (requiredModule == undefined || user.canUpdate(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.edit"),
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            Navigation.navigateTo(`${moduleRef.value.path}/${(object.reference as Record<string, unknown>).id}/edit`);
          },
        } as ContextCommand);
      }

      if (moduleRef.value.contextCommands !== undefined && moduleRef.value.contextCommands.length > 0) {
        const contextCommandsLength = renderContextCommands.length;
        for (const contextCommand of moduleRef.value.contextCommands) {
          if (contextCommand.condition(document) == true) {
            if (renderContextCommands.length == contextCommandsLength && contextCommandsLength > 0) {
              renderContextCommands.push({ label: "separator" } as ContextCommand);
            }
            renderContextCommands.push(contextCommand);
          }
        }
      }

      if (renderContextCommands.length === 0) {
        renderContextCommands.push({
          label: t("buttons.noAction"),
          icon: "pi pi-times",
          condition: () => true,
          command: () => {
            return;
          },
        } as ContextCommand);
      }

      return renderContextCommands;
    };

    onMounted(() => handleLoad());

    return {
      config,
      getContextCommands,
      moduleRef,
      t,
    };
  },
});
