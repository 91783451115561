export const it = {
  profile: {
    changeEmail: "Cambio E-mail",
    changePassword: "Cambio Password",
    changePasswordButton: "Cambia Password",
    email: "E-mail",
    oldPassword: "Vecchia Password",
    oldPasswordBlank: "Inserisci la vecchia password",
    newEmail: "Nuovo Indirizzo E-mail",
    newEmailError: "Inserisci un indirizzo e-mail valido",
    newPassword: "Nuova Password",
    newPasswordBlank: "Inserisci la nuova password",
  },
};
