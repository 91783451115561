import { RachisSection } from "./RachisSection";
import { SurveyForm } from "./SurveyForm";

import { BooleanField, DateField, ObjectStrictField, StringField } from "@/core/fields";

export class RachisSurveyForm extends SurveyForm {
  public cervicalSection: RachisSection = new RachisSection();
  public dorsalSection: RachisSection = new RachisSection();
  public lumbosacralSection: RachisSection = new RachisSection();
  // lombalgie acute
  public question1 = false;
  public question2 = false;
  public question3: string | undefined = undefined;
  public question4: string | undefined = undefined;
  public question5: string | undefined = undefined;
  public question6: string | undefined = undefined;
  // diagnosi
  public question7 = false;
  public question8: Date | undefined = undefined;
  public question9 = false;
  public question10: Date | undefined = undefined;
  public question11: string | undefined = undefined;
  public question12: string | undefined = undefined;
  public question13: string | undefined = undefined;
  public question14: string | undefined = undefined;
  public question15: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): RachisSurveyForm {
    super.fromFirestore(data);

    this.cervicalSection = ObjectStrictField.fromFirestore<RachisSection>(data.cervicalSection, (value) => {
      const rachisSection: RachisSection = new RachisSection();
      return rachisSection.fromOfflineCache(value);
    });
    this.dorsalSection = ObjectStrictField.fromFirestore<RachisSection>(data.dorsalSection, (value) => {
      const rachisSection: RachisSection = new RachisSection();
      return rachisSection.fromOfflineCache(value);
    });
    this.lumbosacralSection = ObjectStrictField.fromFirestore<RachisSection>(data.lumbosacralSection, (value) => {
      const rachisSection: RachisSection = new RachisSection();
      return rachisSection.fromOfflineCache(value);
    });
    this.question1 = BooleanField.fromFirestore(data.question1);
    this.question2 = BooleanField.fromFirestore(data.question2);
    this.question3 = StringField.fromFirestore(data.question3);
    this.question4 = StringField.fromFirestore(data.question4);
    this.question5 = StringField.fromFirestore(data.question5);
    this.question6 = StringField.fromFirestore(data.question6);
    this.question7 = BooleanField.fromFirestore(data.question7);
    this.question8 = DateField.fromFirestore(data.question8);
    this.question9 = BooleanField.fromFirestore(data.question9);
    this.question10 = DateField.fromFirestore(data.question10);
    this.question11 = StringField.fromFirestore(data.question11);
    this.question12 = StringField.fromFirestore(data.question12);
    this.question13 = StringField.fromFirestore(data.question13);
    this.question14 = StringField.fromFirestore(data.question14);
    this.question15 = StringField.fromFirestore(data.question15);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.cervicalSection = ObjectStrictField.toFirestore<RachisSection>(this.cervicalSection, (value) => value.toFirestore());
    firestoreData.dorsalSection = ObjectStrictField.toFirestore<RachisSection>(this.dorsalSection, (value) => value.toFirestore());
    firestoreData.lumbosacralSection = ObjectStrictField.toFirestore<RachisSection>(this.lumbosacralSection, (value) => value.toFirestore());
    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question2 = BooleanField.toFirestore(this.question2);
    firestoreData.question3 = StringField.toFirestore(this.question3);
    firestoreData.question4 = StringField.toFirestore(this.question4);
    firestoreData.question5 = StringField.toFirestore(this.question5);
    firestoreData.question6 = StringField.toFirestore(this.question6);
    firestoreData.question7 = BooleanField.toFirestore(this.question7);
    firestoreData.question8 = DateField.toFirestore(this.question8);
    firestoreData.question9 = BooleanField.toFirestore(this.question9);
    firestoreData.question10 = DateField.toFirestore(this.question10);
    firestoreData.question11 = StringField.toFirestore(this.question11);
    firestoreData.question12 = StringField.toFirestore(this.question12);
    firestoreData.question13 = StringField.toFirestore(this.question13);
    firestoreData.question14 = StringField.toFirestore(this.question14);
    firestoreData.question15 = StringField.toFirestore(this.question15);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): RachisSurveyForm {
    super.fromOfflineCache(data);

    this.cervicalSection = ObjectStrictField.fromOfflineCache<RachisSection>(data.cervicalSection, (value) => new RachisSection(value));
    this.dorsalSection = ObjectStrictField.fromOfflineCache<RachisSection>(data.dorsalSection, (value) => new RachisSection(value));
    this.lumbosacralSection = ObjectStrictField.fromOfflineCache<RachisSection>(data.lumbosacralSection, (value) => new RachisSection(value));
    this.question1 = BooleanField.fromOfflineCache(data.question1);
    this.question2 = BooleanField.fromOfflineCache(data.question2);
    this.question3 = StringField.fromOfflineCache(data.question3);
    this.question4 = StringField.fromOfflineCache(data.question4);
    this.question5 = StringField.fromOfflineCache(data.question5);
    this.question6 = StringField.fromOfflineCache(data.question6);
    this.question7 = BooleanField.fromOfflineCache(data.question7);
    this.question8 = DateField.fromOfflineCache(data.question8);
    this.question9 = BooleanField.fromOfflineCache(data.question9);
    this.question10 = DateField.fromOfflineCache(data.question10);
    this.question11 = StringField.fromOfflineCache(data.question11);
    this.question12 = StringField.fromOfflineCache(data.question12);
    this.question13 = StringField.fromOfflineCache(data.question13);
    this.question14 = StringField.fromOfflineCache(data.question14);
    this.question15 = StringField.fromOfflineCache(data.question15);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.cervicalSection = ObjectStrictField.toOfflineCache<RachisSection>(this.cervicalSection, (value) => value.toOfflineCache());
    firestoreData.dorsalSection = ObjectStrictField.toOfflineCache<RachisSection>(this.dorsalSection, (value) => value.toOfflineCache());
    firestoreData.lumbosacralSection = ObjectStrictField.toOfflineCache<RachisSection>(this.lumbosacralSection, (value) => value.toOfflineCache());
    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question2 = BooleanField.toOfflineCache(this.question2);
    firestoreData.question3 = StringField.toOfflineCache(this.question3);
    firestoreData.question4 = StringField.toOfflineCache(this.question4);
    firestoreData.question5 = StringField.toOfflineCache(this.question5);
    firestoreData.question6 = StringField.toOfflineCache(this.question6);
    firestoreData.question7 = BooleanField.toOfflineCache(this.question7);
    firestoreData.question8 = DateField.toOfflineCache(this.question8);
    firestoreData.question9 = BooleanField.toOfflineCache(this.question9);
    firestoreData.question10 = DateField.toOfflineCache(this.question10);
    firestoreData.question11 = StringField.toOfflineCache(this.question11);
    firestoreData.question12 = StringField.toOfflineCache(this.question12);
    firestoreData.question13 = StringField.toOfflineCache(this.question13);
    firestoreData.question14 = StringField.toOfflineCache(this.question14);
    firestoreData.question15 = StringField.toOfflineCache(this.question15);

    return firestoreData;
  }
}
