import { CostList } from "@/features/modules/costList/objects/CostList";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { PriceList } from "@/features/modules/priceList/objects/PriceList";

export class CompanyWithLists {
  public linkedCompany: LinkedCompany;
  public priceList: PriceList;
  public costList: CostList;

  public constructor(linkedCompany: LinkedCompany, priceList: PriceList, costList: CostList) {
    this.linkedCompany = linkedCompany;
    this.priceList = priceList;
    this.costList = costList;
  }
}
