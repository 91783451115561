import { activityLocaleModule } from "@/features/modules/activity/locales/module";
import { assistLocaleModule } from "@/features/modules/assist/locales/module";
import { branchLegalRecordLocaleModule } from "@/features/modules/branchLegalRecord/locales/module";
import { branchLocaleModule } from "@/features/modules/branch/locales/module";
import { brokerLocaleModule } from "@/features/modules/broker/locales/module";
import { calendarLocaleModule } from "@/features/modules/calendar/locales/module";
import { companyCostListLocaleModule } from "@/features/modules/companyCostList/locales/module";
import { companyDutyLocaleModule } from "@/features/modules/companyDuty/locales/module";
import { companyLocaleModule } from "@/features/modules/company/locales/module";
import { companyPriceListLocaleModule } from "@/features/modules/companyPriceList/locales/module";
import { configurationLocaleModule } from "@/features/modules/configuration/locales/module";
import { contractLocaleModule } from "@/features/modules/contract/locales/module";
import { costListLocaleModule } from "@/features/modules/costList/locales/module";
import { dashboardLocaleModule } from "@/features/modules/dashboard/locales/module";
import { docFolderLocaleModule } from "@/features/modules/docFolder/locales/module";
import { docLocaleModule } from "@/features/modules/doc/locales/module";
import { doctorAgendaLocaleModule } from "@/features/modules/doctorAgenda/locales/module";
import { doctorLocaleModule } from "@/features/modules/doctor/locales/module";
import { docTypeLocaleModule } from "@/features/modules/docType/locales/module";
import { dutyLocaleModule } from "@/features/modules/duty/locales/module";
import { employeeLocaleModule } from "@/features/modules/employee/locales/module";
import { employeeDutyLocaleModule } from "@/features/modules/employeeDuty/locales/module";
import { employerLocaleModule } from "@/features/modules/employer/locales/module";
import { examinationLocaleModule } from "@/features/modules/examination/locales/module";
import { examinationSessionLocaleModule } from "@/features/modules/examinationSession/locales/module";
import { examTypeLocaleModule } from "@/features/modules/examType/locales/module";
import { externalTestLocaleModule } from "@/features/modules/externalTest/locales/module";
import { externalTestSessionLocaleModule } from "@/features/modules/externalTestSession/locales/module";
import { firmLocaleModule } from "@/features/modules/firm/locales/module";
import { hintLocaleModule } from "@/features/modules/hint/locales/module";
import { invoiceLocaleModule } from "@/features/modules/invoice/locales/module";
import { legalRecordSessionLocaleModule } from "@/features/modules/legalRecordSession/locales/module";
import { limitationLocaleModule } from "@/features/modules/limitation/locales/module";
import { normLocaleModule } from "@/features/modules/norm/locales/module";
import { optionLocaleModule } from "@/features/modules/option/locales/module";
import { prescriptionLocaleModule } from "@/features/modules/prescription/locales/module";
import { priceListLocaleModule } from "@/features/modules/priceList/locales/module";
import { referentLocaleModule } from "@/features/modules/referent/locales/module";
import { riskLocaleModule } from "@/features/modules/risk/locales/module";
import { serviceLocaleModule } from "@/features/modules/service/locales/module";
import { plannerLocaleModule } from "@/features/modules/planner/locales/module";
import { serviceTypeLocaleModule } from "@/features/modules/serviceType/locales/module";
import { supplierLocaleModule } from "@/features/modules/supplier/locales/module";
import { surveyLocaleModule } from "@/features/modules/survey/locales/module";
import { surveyFormLocaleModule } from "@/features/modules/surveyForm/locales/module";
import { testTypeCategoryLocaleModule } from "@/features/modules/testTypeCategory/locales/module";
import { testFormLocaleModule } from "@/features/modules/testForm/locales/module";
import { testTypeLocaleModule } from "@/features/modules/testType/locales/module";
import { vaccinationLocaleModule } from "@/features/modules/vaccination/locales/module";
import { vaccineTypeLocaleModule } from "@/features/modules/vaccineType/locales/module";

import { statModuleLocaleModule } from "@/features/modules/stat/locales/module";
import { statEmployeeByDutyModule } from "@/features/modules/stat/modules/employeeByDuty/locales/module";
import { statEmployeeLastExaminationModule } from "@/features/modules/stat/modules/employeeLastExamination/locales/module";
import { statExaminationCounterModule } from "@/features/modules/stat/modules/examinationCounter/locales/module";
import { statExaminationScheduleModule } from "@/features/modules/stat/modules/examinationSchedule/locales/module";
import { statServiceCounterModule } from "@/features/modules/stat/modules/serviceCounter/locales/module";

import { utilityModuleLocaleModule } from "@/features/modules/utility/locales/module";
import { utilityAssignEmployeeDutyLocaleModule } from "@/features/modules/utility/modules/assignEmployeeDuty/locales/module";
import { utilityDownloadHealthFolderLocaleModule } from "@/features/modules/utility/modules/downloadHealthFolder/locales/module";
import { utilityExportEmployeeLocaleModule } from "@/features/modules/utility/modules/exportEmployee/locales/module";

export const featuresLocales = [
  activityLocaleModule,
  assistLocaleModule,
  branchLegalRecordLocaleModule,
  branchLocaleModule,
  brokerLocaleModule,
  calendarLocaleModule,
  companyCostListLocaleModule,
  companyDutyLocaleModule,
  companyLocaleModule,
  companyPriceListLocaleModule,
  configurationLocaleModule,
  contractLocaleModule,
  costListLocaleModule,
  dashboardLocaleModule,
  docFolderLocaleModule,
  docLocaleModule,
  doctorAgendaLocaleModule,
  doctorLocaleModule,
  docTypeLocaleModule,
  dutyLocaleModule,
  employeeLocaleModule,
  employeeDutyLocaleModule,
  employerLocaleModule,
  examinationLocaleModule,
  examinationSessionLocaleModule,
  examTypeLocaleModule,
  externalTestLocaleModule,
  externalTestSessionLocaleModule,
  firmLocaleModule,
  hintLocaleModule,
  invoiceLocaleModule,
  legalRecordSessionLocaleModule,
  limitationLocaleModule,
  normLocaleModule,
  optionLocaleModule,
  prescriptionLocaleModule,
  priceListLocaleModule,
  referentLocaleModule,
  riskLocaleModule,
  serviceLocaleModule,
  plannerLocaleModule,
  serviceTypeLocaleModule,
  supplierLocaleModule,
  surveyLocaleModule,
  surveyFormLocaleModule,
  testFormLocaleModule,
  testTypeCategoryLocaleModule,
  testTypeLocaleModule,
  vaccinationLocaleModule,
  vaccineTypeLocaleModule,
  statModuleLocaleModule,
  statEmployeeByDutyModule,
  statEmployeeLastExaminationModule,
  statExaminationCounterModule,
  statExaminationScheduleModule,
  statServiceCounterModule,
  utilityModuleLocaleModule,
  utilityAssignEmployeeDutyLocaleModule,
  utilityDownloadHealthFolderLocaleModule,
  utilityExportEmployeeLocaleModule,
];
