import { IDBPDatabase, openDB } from "idb";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { config } from "@/core/modules/config/objects/Config";

export class IndexedDbModel {
  private databaseName: string;
  public db: IDBPDatabase | undefined;

  public constructor(databaseName: string) {
    this.databaseName = databaseName;
    const collections: string[] = ["offlineEdits", "offlineUploads"];
    for (const collection of config.database.collections) {
      collections.push(collection.name);
      for (const subCollection of collection.subCollections) {
        collections.push(subCollection);
      }
    }

    if (config.database.offlineMode === true) {
      try {
        this.createDatabase(collections);
      } catch (error: unknown) {
        if (error instanceof AppError) {
          error.addToStack("IndexedDbModel.constructor", { databaseName }, error);
          console.log(error);
          appFaultModel.createAppFaultFromAppError(error);
        }
      }
    }
  }

  public async createDatabase(tableNames: string[]): Promise<void> {
    try {
      this.db = await openDB(this.databaseName, config.database.offlineVersion, {
        upgrade(db: IDBPDatabase) {
          for (const tableName of tableNames) {
            if (db.objectStoreNames.contains(tableName)) {
              continue;
            }
            db.createObjectStore(tableName);
          }
        },
      });
    } catch (error: unknown) {
      appFaultModel.catchAppError("IndexedDbModel.createDatabase", { tableNames }, error);
    }
  }

  public async getDocuments(tableName: string): Promise<Record<string, unknown>[]> {
    try {
      if (config.database.offlineMode === false) return [];
      if (this.db === undefined) return [];

      const transaction = this.db.transaction(tableName, "readonly");
      const store = transaction.objectStore(tableName);
      return store.getAll();
    } catch (error: unknown) {
      appFaultModel.catchAppError("IndexedDbModel.getDocuments", { tableName }, error);
      return [];
    }
  }

  public async getDocumentsKeys(tableName: string): Promise<IDBValidKey[]> {
    try {
      if (config.database.offlineMode === false) return [];
      if (this.db === undefined) return [];

      const transaction = this.db.transaction(tableName, "readonly");
      const store = transaction.objectStore(tableName);
      return store.getAllKeys();
    } catch (error: unknown) {
      appFaultModel.catchAppError("IndexedDbModel.getDocumentsKeys", { tableName }, error);
      return [];
    }
  }

  public async getDocument(tableName: string, id: string): Promise<Record<string, unknown> | undefined> {
    try {
      if (config.database.offlineMode === false) return undefined;
      if (this.db === undefined) return undefined;

      const transaction = this.db.transaction(tableName, "readonly");
      const store = transaction.objectStore(tableName);
      return store.get(id);
    } catch (error: unknown) {
      appFaultModel.catchAppError("IndexedDbModel.getDocument", { tableName, id }, error);
      return undefined;
    }
  }

  public async setDocument(tableName: string, id: string, value: Record<string, unknown>): Promise<void> {
    try {
      if (config.database.offlineMode === false) return;
      if (this.db === undefined) return;

      const transaction = this.db.transaction(tableName, "readwrite");
      const store = transaction.objectStore(tableName);
      await store.put(value, id);
    } catch (error: unknown) {
      appFaultModel.catchAppError("IndexedDbModel.setDocument", { tableName, id, value }, error);
    }
  }

  public async deleteDocument(tableName: string, id: string): Promise<void> {
    try {
      if (config.database.offlineMode === false) return;
      if (this.db === undefined) return;

      const transaction = this.db.transaction(tableName, "readwrite");
      const store = transaction.objectStore(tableName);
      const result = await store.get(id);
      if (result === undefined) return;

      return store.delete(id);
    } catch (error: unknown) {
      appFaultModel.catchAppError("IndexedDbModel.deleteDocument", { tableName, id }, error);
    }
  }
}

export const indexedDbModel: IndexedDbModel = new IndexedDbModel("koruCache");
