export class BooleanField {
  public static fromFirestore(value: unknown): boolean {
    if (value === null || value === undefined) return false;

    return Boolean(value);
  }

  public static toFirestore(value: boolean): boolean {
    if (value === null || value === undefined) return false;

    return Boolean(value);
  }

  public static fromOfflineCache(value: unknown): boolean {
    return BooleanField.fromFirestore(value);
  }

  public static toOfflineCache(value: boolean): boolean {
    return BooleanField.toFirestore(value);
  }
}
