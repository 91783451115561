import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12" }
const _hoisted_3 = { class: "col-12 mt-4" }
const _hoisted_4 = { class: "field col-12 text-right mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("company.company")), 1),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.companyId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyId) = $event)),
        options: _ctx.companies,
        optionLabel: "name",
        optionValue: "id",
        placeholder: "-",
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.companyChanged())),
        filter: true
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DataTable, {
        dataKey: "id",
        responsiveLayout: "scroll",
        selection: _ctx.selectedEmployees,
        "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedEmployees) = $event)),
        showGridlines: "",
        sortField: "fullName",
        sortOrder: 1,
        stripedRows: "",
        value: _ctx.employees
      }, {
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("gen.noData")), 1)
        ]),
        loading: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("gen.loading")), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "id",
            selectionMode: "multiple",
            style: {"width":"10%"}
          }),
          _createVNode(_component_Column, {
            field: "fullName",
            header: _ctx.t('employee.fullName'),
            style: {"width":"60%"}
          }, {
            body: _withCtx(({ data, field }) => [
              _createTextVNode(_toDisplayString(data[field] ?? "-"), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "fiscalCode",
            header: _ctx.t('employee.fiscalCode'),
            style: {"width":"30%"}
          }, {
            body: _withCtx(({ data, field }) => [
              _createTextVNode(_toDisplayString(data[field] ?? "-"), 1)
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["selection", "value"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Button, {
        label: _ctx.t('buttons.download'),
        icon: "pi pi-save",
        type: "button",
        class: "p-button-primary p-uppercase",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.downloadHealthFolder()))
      }, null, 8, ["label"])
    ])
  ]))
}