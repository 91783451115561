import { BooleanField, StringField } from "@/core/fields";

export class RachisSection {
  public question1 = false;
  public question2: string | undefined = undefined;
  public question3: string | undefined = undefined;
  public question4: string | undefined = undefined;
  public question5: string | undefined = undefined;
  public question6: string | undefined = undefined;
  public question7 = false;
  public question8 = false;
  public question9: string | undefined = undefined;
  public question10: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): RachisSection {
    this.question1 = BooleanField.fromFirestore(data.question1);
    this.question2 = StringField.fromFirestore(data.question2);
    this.question3 = StringField.fromFirestore(data.question3);
    this.question4 = StringField.fromFirestore(data.question4);
    this.question5 = StringField.fromFirestore(data.question5);
    this.question6 = StringField.fromFirestore(data.question6);
    this.question7 = BooleanField.fromFirestore(data.question7);
    this.question8 = BooleanField.fromFirestore(data.question8);
    this.question9 = StringField.fromFirestore(data.question9);
    this.question10 = StringField.fromFirestore(data.question10);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question2 = StringField.toFirestore(this.question2);
    firestoreData.question3 = StringField.toFirestore(this.question3);
    firestoreData.question4 = StringField.toFirestore(this.question4);
    firestoreData.question5 = StringField.toFirestore(this.question5);
    firestoreData.question6 = StringField.toFirestore(this.question6);
    firestoreData.question7 = BooleanField.toFirestore(this.question7);
    firestoreData.question8 = BooleanField.toFirestore(this.question8);
    firestoreData.question9 = StringField.toFirestore(this.question9);
    firestoreData.question10 = StringField.toFirestore(this.question10);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): RachisSection {
    this.question1 = BooleanField.fromOfflineCache(data.question1);
    this.question2 = StringField.fromOfflineCache(data.question2);
    this.question3 = StringField.fromOfflineCache(data.question3);
    this.question4 = StringField.fromOfflineCache(data.question4);
    this.question5 = StringField.fromOfflineCache(data.question5);
    this.question6 = StringField.fromOfflineCache(data.question6);
    this.question7 = BooleanField.fromOfflineCache(data.question7);
    this.question8 = BooleanField.fromOfflineCache(data.question8);
    this.question9 = StringField.fromOfflineCache(data.question9);
    this.question10 = StringField.fromOfflineCache(data.question10);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question2 = StringField.toOfflineCache(this.question2);
    firestoreData.question3 = StringField.toOfflineCache(this.question3);
    firestoreData.question4 = StringField.toOfflineCache(this.question4);
    firestoreData.question5 = StringField.toOfflineCache(this.question5);
    firestoreData.question6 = StringField.toOfflineCache(this.question6);
    firestoreData.question7 = BooleanField.toOfflineCache(this.question7);
    firestoreData.question8 = BooleanField.toOfflineCache(this.question8);
    firestoreData.question9 = StringField.toOfflineCache(this.question9);
    firestoreData.question10 = StringField.toOfflineCache(this.question10);

    return firestoreData;
  }
}
