import { EmptyState } from "../interfaces/EmptyState";
import { store } from "../module";
import { StoreModule } from "../objects/StoreModule";

export class StoreHelpers {
  public static registerModule<T extends EmptyState>(storeModule: StoreModule<T>): void {
    if (store.hasModule(storeModule.namespace) === false) {
      store.registerModule(storeModule.namespace, storeModule);
    }
  }
}
