import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { setting } from "@/core/modules/setting/Setting";

export const getSelectedFirmId = (): string => {
  try {
    const localStorageFirmId: string | null = localStorage.getItem("koruFirmId");

    return localStorageFirmId !== null && localStorageFirmId.trim().length > 0 ? localStorageFirmId : setting.getSetting<string>("defaultFirmId");
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirmModel.getSelectedFirmId", {}, error);
    return "ERROR";
  }
};
