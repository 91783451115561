import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getNextExaminationCodeByDateAndFirm = async (date: Date): Promise<number> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();
    const currentYear: number = date.getFullYear() - 2000;

    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (examinationModel.offlineModuleModel === undefined) return 0;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      const filteredDocuments: Examination[] = documents
        .filter(
          (examination) =>
            examination.firm?.id === firmId && examination.codeSort >= currentYear * 1000000 && examination.codeSort < (currentYear + 1) * 1000000
        )
        .sort((a, b) => (a.codeSort > b.codeSort ? -1 : 1));

      if (filteredDocuments.length === 0) return 1;
      return filteredDocuments[0].code + 1;
    }

    return FeaturesHelpers.runNextCodeFunction("featuresExaminationNextCode", currentYear, firmId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getNextExaminationCodeByDateAndFirm", { date }, error);
    return 0;
  }
};
