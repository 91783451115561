import * as _ from "lodash";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Duty } from "@/features/modules/duty/objects/Duty";
import { dutyModel } from "../DutyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const cloneDuty = async (duty: Duty): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    const newDuty: Duty = _.cloneDeep(duty);

    return dutyModel.createDocument(newDuty);
  } catch (error: unknown) {
    appFaultModel.catchAppError("DutyModel.cloneDuty", { duty }, error);
    return "ERROR";
  }
};
