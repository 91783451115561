import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";

import { ObjectStrictField, StringField } from "@/core/fields";

export class Widget extends FirestoreDocument {
  public source: string | undefined = undefined;
  public sourceId: string | undefined = undefined;
  public type: string | undefined = undefined;
  public values: Record<string, unknown> = {};

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Widget {
    super.fromFirestore(data, id, firestoreRef);

    this.source = StringField.fromFirestore(data.source);
    this.sourceId = StringField.fromFirestore(data.sourceId);
    this.type = StringField.fromFirestore(data.type);
    this.values = ObjectStrictField.fromFirestore<Record<string, unknown>>(data.values, (value) => value);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.source = StringField.toFirestore(this.source);
    firestoreData.sourceId = StringField.toFirestore(this.sourceId);
    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.values = ObjectStrictField.toFirestore<Record<string, unknown>>(this.values, (value) => value);

    return firestoreData;
  }
}
