
import { computed, defineComponent, onMounted, WritableComputedRef } from "vue";

import { geohashForLocation } from "geofire-common";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { maps } from "@/core/plugins/maps/Maps";
import { useLocale } from "@/core/modules/locale/module";

import { AddressField } from "@/core/fields";

export default defineComponent({
  name: "KoruAddress",
  props: {
    modelValue: { type: AddressField, default: new AddressField() },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const elementId = `koru-address-${DataHelpers.uniqueId()}`;
    const { t } = useLocale();

    const addressRef: WritableComputedRef<AddressField> = computed({
      get: () => props.modelValue as AddressField,
      set: (val) => emit("update:modelValue", val),
    });

    const init = async (): Promise<void> => {
      if (maps.isLoaded() === false) await maps.load();
      await AppHelpers.delay(2000);

      const addressField = document.querySelector(`#${elementId}`) as HTMLInputElement;
      const autocomplete = new window.google.maps.places.Autocomplete(addressField, {
        componentRestrictions: { country: ["it"] },
        fields: ["address_components", "geometry"],
      });
      addressField.focus();
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        addressRef.value = new AddressField();
        let streetNumber = "";

        let street: string | undefined;
        let town: string | undefined;
        let zipCode: string | undefined;
        let province: string | undefined;
        let country: string | undefined;

        if (place.address_components !== undefined) {
          for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
            const componentType = component.types[0];
            switch (componentType) {
              case "street_number":
                streetNumber = ", " + component.long_name;
                break;
              case "route":
                street = component.long_name + streetNumber;
                break;
              case "administrative_area_level_3":
                town = component.long_name;
                break;
              case "postal_code":
                zipCode = component.long_name;
                break;
              case "administrative_area_level_2":
                province = component.short_name;
                break;
              case "country":
                country = component.long_name;
                break;
            }
          }
        }
        const latitude: number | undefined = place.geometry?.location.lat();
        const longitude: number | undefined = place.geometry?.location.lng();
        const geoHash: string | undefined = latitude !== undefined && longitude !== undefined ? geohashForLocation([latitude, longitude]) : undefined;

        addressRef.value = new AddressField(street, town, zipCode, province, country, latitude, longitude, geoHash);
      });
    };

    onMounted(() => init());

    return {
      addressRef,
      elementId,
      t,
    };
  },
});
