import { TestForm } from "./TestForm";

import { BooleanField, StringField } from "@/core/fields";

export class GenTestForm extends TestForm {
  public result = true;
  public value: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): GenTestForm {
    super.fromFirestore(data);

    this.result = BooleanField.fromFirestore(data.result);
    this.value = StringField.fromFirestore(data.value);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.result = BooleanField.toFirestore(this.result);
    firestoreData.value = StringField.toFirestore(this.value);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): GenTestForm {
    super.fromOfflineCache(data);

    this.result = BooleanField.fromOfflineCache(data.result);
    this.value = StringField.fromOfflineCache(data.value);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.result = BooleanField.toOfflineCache(this.result);
    firestoreData.value = StringField.toOfflineCache(this.value);

    return firestoreData;
  }
}
