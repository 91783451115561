export const en = {
  who: {
    details1: "We develop software for the web and for mobile devices.",
    details2: "We love the well-written code and teamwork.",
    details3: "For any information you can write to us at",
    who: "Who we are",
  },
  navigation: {
    who: "Who we are",
  },
};
