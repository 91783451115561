export const en = {
  history: {
    actions: {
      browse: "Browse",
      create: "Create",
      edit: "Edit",
      search: "Search",
      view: "View",
    },
    history: "History",
    noHistory: "History is empty",
  },
};
