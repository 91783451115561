import { Query, query, where } from "firebase/firestore";

import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "../objects/User";
import { userOfflineModel } from "./UserOfflineModel";

import { isEmailUnique } from "./methods";

export class UserModel extends FirestoreModel<User> {
  public constructor() {
    super(() => new User(), "users", LockPolicy.DiscardUnsyncedChanges, "user");
    this.beforeDeleteFunction = "coreUserBeforeDelete";
    this.offlineModuleModel = userOfflineModel;
  }

  public async getDocuments(): Promise<User[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    const customQuery: Query<User> = query(this.getPathReference().withConverter(this.firestoreConverter), where("id", "==", user.id));

    return super.getDocuments([], undefined, customQuery);
  }

  public async getDocument(userId: string): Promise<User> {
    return super.getDocument(userId);
  }

  public async createDocument(user: User): Promise<string> {
    user.setFullNames();
    return super.createDocument(user);
  }

  public async updateDocument(user: User): Promise<void> {
    user.setFullNames();
    return super.updateDocument(user);
  }

  public async deleteDocument(user: User): Promise<boolean> {
    return super.deleteDocument(user);
  }

  public async isEmailUnique(email: string, userId?: string): Promise<boolean> {
    return isEmailUnique(email, userId);
  }
}

export const userModel: UserModel = new UserModel();
