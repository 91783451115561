import { activityRoutes } from "@/features/modules/activity/router/routes";
import { branchLegalRecordRoutes } from "@/features/modules/branchLegalRecord/router/routes";
import { branchRoutes } from "@/features/modules/branch/router/routes";
import { brokerDocRoutes } from "@/features/modules/brokerDoc/router/routes";
import { brokerRoutes } from "@/features/modules/broker/router/routes";
import { calendarRoutes } from "@/features/modules/calendar/router/routes";
import { companyCostListRoutes } from "@/features/modules/companyCostList/router/routes";
import { companyDocRoutes } from "@/features/modules/companyDoc/router/routes";
import { companyDutyRoutes } from "@/features/modules/companyDuty/router/routes";
import { companyPriceListRoutes } from "@/features/modules/companyPriceList/router/routes";
import { companyRoutes } from "@/features/modules/company/router/routes";
import { configurationRoutes } from "@/features/modules/configuration/router/routes";
import { contractRoutes } from "@/features/modules/contract/router/routes";
import { costListRoutes } from "@/features/modules/costList/router/routes";
import { dashboardRoutes } from "@/features/modules/dashboard/router/routes";
import { docFolderRoutes } from "@/features/modules/docFolder/router/routes";
import { doctorAgendaRoutes } from "@/features/modules/doctorAgenda/router/routes";
import { doctorDocRoutes } from "@/features/modules/doctorDoc/router/routes";
import { doctorRoutes } from "@/features/modules/doctor/router/routes";
import { docTypeRoutes } from "@/features/modules/docType/router/routes";
import { dutyRoutes } from "@/features/modules/duty/router/routes";
import { employeeDocRoutes } from "@/features/modules/employeeDoc/router/routes";
import { employeeDutyRoutes } from "@/features/modules/employeeDuty/router/routes";
import { employeeRoutes } from "@/features/modules/employee/router/routes";
import { employerRoutes } from "@/features/modules/employer/router/routes";
import { examinationRoutes } from "@/features/modules/examination/router/routes";
import { examinationSessionRoutes } from "@/features/modules/examinationSession/router/routes";
import { examTypeRoutes } from "@/features/modules/examType/router/routes";
import { externalTestRoutes } from "@/features/modules/externalTest/router/routes";
import { externalTestSessionRoutes } from "@/features/modules/externalTestSession/router/routes";
import { firmRoutes } from "@/features/modules/firm/router/routes";
import { hintRoutes } from "@/features/modules/hint/router/routes";
import { invoiceRoutes } from "@/features/modules/invoice/router/routes";
import { legalRecordSessionRoutes } from "@/features/modules/legalRecordSession/router/routes";
import { limitationRoutes } from "@/features/modules/limitation/router/routes";
import { normRoutes } from "@/features/modules/norm/router/routes";
import { optionRoutes } from "@/features/modules/option/router/routes";
import { plannerRoutes } from "@/features/modules/planner/router/routes";
import { prescriptionRoutes } from "@/features/modules/prescription/router/routes";
import { priceListRoutes } from "@/features/modules/priceList/router/routes";
import { riskRoutes } from "@/features/modules/risk/router/routes";
import { serviceRoutes } from "@/features/modules/service/router/routes";
import { serviceTypeRoutes } from "@/features/modules/serviceType/router/routes";
import { supplierDocRoutes } from "@/features/modules/supplierDoc/router/routes";
import { supplierRoutes } from "@/features/modules/supplier/router/routes";
import { surveyRoutes } from "@/features/modules/survey/router/routes";
import { testTypeCategoryRoutes } from "@/features/modules/testTypeCategory/router/routes";
import { testTypeRoutes } from "@/features/modules/testType/router/routes";
import { vaccinationRoutes } from "@/features/modules/vaccination/router/routes";
import { vaccineTypeRoutes } from "@/features/modules/vaccineType/router/routes";

export const featuresRoutes = [
  ...activityRoutes,
  ...branchLegalRecordRoutes,
  ...branchRoutes,
  ...brokerDocRoutes,
  ...brokerRoutes,
  ...calendarRoutes,
  ...companyCostListRoutes,
  ...companyDocRoutes,
  ...companyDutyRoutes,
  ...companyPriceListRoutes,
  ...companyRoutes,
  ...configurationRoutes,
  ...contractRoutes,
  ...costListRoutes,
  ...dashboardRoutes,
  ...docFolderRoutes,
  ...doctorAgendaRoutes,
  ...doctorDocRoutes,
  ...doctorRoutes,
  ...docTypeRoutes,
  ...dutyRoutes,
  ...employeeDocRoutes,
  ...employeeDutyRoutes,
  ...employeeRoutes,
  ...employerRoutes,
  ...examinationRoutes,
  ...examinationSessionRoutes,
  ...examTypeRoutes,
  ...externalTestRoutes,
  ...externalTestSessionRoutes,
  ...firmRoutes,
  ...hintRoutes,
  ...invoiceRoutes,
  ...legalRecordSessionRoutes,
  ...limitationRoutes,
  ...normRoutes,
  ...optionRoutes,
  ...plannerRoutes,
  ...prescriptionRoutes,
  ...priceListRoutes,
  ...riskRoutes,
  ...serviceRoutes,
  ...serviceTypeRoutes,
  ...supplierDocRoutes,
  ...supplierRoutes,
  ...surveyRoutes,
  ...testTypeCategoryRoutes,
  ...testTypeRoutes,
  ...vaccinationRoutes,
  ...vaccineTypeRoutes,
];
