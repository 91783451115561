import { CollectionReference, DocumentData, DocumentReference, FirestoreDataConverter, Query, Transaction } from "firebase/firestore";

import { Clause } from "../objects/Clause";
import { FirestoreDocument } from "../objects/FirestoreDocument";
import { FirestoreOfflineDocument } from "../objects/FirestoreOfflineDocument";
import { LockPolicy } from "../objects/LockPolicy";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { SortCriteria } from "../objects/SortCriteria";

import {
  addReadConditionToQuery,
  countDocuments,
  createFirestoreConverter,
  createDocument,
  deleteDocument,
  getDocument,
  getDocumentReference,
  getDocuments,
  getGroupPathReference,
  getNewId,
  getPathReference,
  reorderDocuments,
  runFirestoreTransaction,
  searchDocuments,
  updateDocument,
} from "./methods";

/**
 * a model for a firestore collection
 */
export class FirestoreModel<T extends FirestoreDocument> {
  public beforeDeleteFunction: string | undefined = undefined;
  public collectionName: string;
  public documentSubscriptionClose: () => void = () => null;
  public firestoreConverter: FirestoreDataConverter<T>;
  public lockPolicy: LockPolicy = LockPolicy.None;
  public offlineModuleModel: OfflineModuleModel<FirestoreOfflineDocument> | undefined = undefined; // TODOOFFLINE
  public parentCollectionName: string | undefined = undefined;
  public roleModule: string;

  /**
   * create a new FirestoreModel instance
   * @param newIstance the new instance function
   * @param collectionName the collection name
   * @param lockPolicy the lock policy
   * @param roleModule the role module
   */
  public constructor(newIstance: () => T, collectionName: string, lockPolicy: LockPolicy, roleModule: string) {
    this.collectionName = collectionName;
    this.lockPolicy = lockPolicy;
    this.roleModule = roleModule;

    this.firestoreConverter = this.createFirestoreConverter(newIstance);
  }

  /**
   * get all documents
   * @param sortCriterias the sort criterias
   * @param parentId the parent id
   * @param customQuery the custom query for conditional reads
   * @returns the documents
   */
  protected async getDocuments(sortCriterias: SortCriteria[] = [], parentId?: string, customQuery?: Query<T>): Promise<T[]> {
    return getDocuments(this, sortCriterias, parentId, customQuery);
  }

  /**
   * get a document
   * @param firestoreDocumentId the document id
   * @param parentId the parent id
   * @returns the document
   */
  protected async getDocument(firestoreDocumentId: string, parentId?: string): Promise<T> {
    return getDocument(this, firestoreDocumentId, parentId);
  }

  /**
   * create a document
   * @param firestoreDocument the document to create
   * @param parentId the parent id
   * @returns the created document id
   */
  protected async createDocument(firestoreDocument: T, parentId?: string, writeLog = true): Promise<string> {
    return createDocument(this, firestoreDocument, parentId, writeLog);
  }

  /**
   * update a document
   * @param firestoreDocument the document to update
   * @param parentId the parent id
   */
  protected async updateDocument(firestoreDocument: T, parentId?: string, writeLog = true): Promise<void> {
    return updateDocument(this, firestoreDocument, parentId, writeLog);
  }

  /**
   * delete a document
   * @param firestoreDocument the document to delete
   * @param parentId the parent id
   * @returns true if the document was deleted, false otherwise
   */
  protected async deleteDocument(firestoreDocument: T, parentId?: string, writeLog = true): Promise<boolean> {
    return deleteDocument(this, firestoreDocument, parentId, writeLog);
  }

  /**
   * search for documents
   * @param searchText the text to search
   * @param sortCriterias the sort criterias
   * @param clauses the additional clauses
   * @param customQuery the custom query for conditional reads
   * @returns the found documents
   */
  public async searchDocuments(searchText: string, sortCriterias: SortCriteria[], clauses?: Clause[], customQuery?: Query<T>): Promise<T[]> {
    return searchDocuments(this, searchText, sortCriterias, clauses, customQuery);
  }

  /**
   * count the documents in a collection
   * @param clauses the additional clauses
   * @param parentId the parent id
   * @returns the number of documents
   */
  public async countDocuments(clauses?: Clause[], parentId?: string): Promise<number> {
    return countDocuments(this, clauses, parentId);
  }

  /**
   * reorder documents in a collection
   * @param documents the documents in the new order
   * @param parentId the parent id
   */
  public async reorderDocuments(documents: T[], parentId?: string): Promise<void> {
    return reorderDocuments(this, documents, parentId);
  }

  /**
   * get the document reference
   * @param documentId the document id
   * @param parentId the parent id
   * @returns the document reference
   */
  public getDocumentReference(documentId?: string, parentId?: string): DocumentReference {
    if (documentId === undefined) documentId = this.getNewId(parentId);
    return getDocumentReference(this, documentId, parentId);
  }

  /**
   * get the path reference
   * @param parentId the parent id
   * @returns the path reference
   */
  public getPathReference(parentId?: string): CollectionReference<DocumentData> {
    return getPathReference(this, parentId);
  }

  /**
   * get the group path reference
   * @returns the group path reference
   */
  public getGroupPathReference(): Query<DocumentData> {
    return getGroupPathReference(this);
  }

  /**
   * get a new id
   * @param parentId the parent id
   * @returns the new id
   */
  public getNewId(parentId?: string): string {
    return getNewId(this, parentId);
  }

  /**
   * create a firestore converter
   * @param newIstance the new instance function
   * @returns the firestore converter
   */
  public createFirestoreConverter(newIstance: () => T): FirestoreDataConverter<T> {
    return createFirestoreConverter(newIstance);
  }

  /**
   * add a read condition to a query
   * @param query the query
   * @returns the query with the read condition
   */
  public addReadConditionToQuery(query: Query<T>): Query<T> {
    return addReadConditionToQuery(this, query);
  }

  /**
   * run a transaction
   * @param performActions the actions to perform
   */
  public static runTransaction(performActions: (transaction: Transaction) => Promise<void>): Promise<unknown> {
    return runFirestoreTransaction(performActions);
  }
}
