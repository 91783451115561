import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(_component_InputText, {
    id: _ctx.elementId,
    modelValue: _ctx.addressRef.street,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addressRef.street) = $event)),
    type: "text",
    placeholder: _ctx.t('gen.inputAddress')
  }, null, 8, ["id", "modelValue", "placeholder"]))
}