import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { Invoice } from "../objects/Invoice";
import { InvoiceCandidate } from "../objects/InvoiceCandidate";
import { InvoiceState } from "../objects/InvoiceState";
import { InvoiceType } from "../objects/InvoiceType";
import { InvoicingType } from "../objects/InvoicingType";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Service } from "@/features/modules/service/objects/Service";

import {
  calculateInvoiceRows,
  calculateInvoiceTotals,
  createCustomInvoice,
  createInvoice,
  getInvoiceCandidates,
  getInvoicesByBroker,
  getInvoicesByCompany,
  getInvoicesByYear,
  getInvoicesByYearlyPaymentLumpSum,
  getInvoicesWithFilters,
  getLastInvoiceByBroker,
  getLastInvoiceByCompany,
  getNextInvoice,
  getNextInvoiceCodeByDateAndFirm,
  getPreviousInvoice,
  markAs,
} from "./methods";

export class InvoiceModel extends FirestoreModel<Invoice> {
  public constructor() {
    super(() => new Invoice(), "invoices", LockPolicy.DiscardUnsyncedChanges, "invoice");
  }

  public async getDocuments(): Promise<Invoice[]> {
    return super.getDocuments();
  }

  public async getDocument(invoiceId: string): Promise<Invoice> {
    return super.getDocument(invoiceId);
  }

  public async createDocument(invoice: Invoice): Promise<string> {
    await invoice.setCodes();

    return super.createDocument(invoice);
  }

  public async updateDocument(invoice: Invoice): Promise<void> {
    invoice.setState();

    return super.updateDocument(invoice);
  }

  public async deleteDocument(invoice: Invoice): Promise<boolean> {
    return super.deleteDocument(invoice);
  }

  public async calculateInvoiceRows(
    invoice: Invoice,
    t: (entry: string, params?: Record<string, unknown>) => string,
    d: (date: Date, format: string) => string
  ): Promise<void> {
    return calculateInvoiceRows(invoice, t, d);
  }

  public async calculateInvoiceTotals(invoice: Invoice): Promise<void> {
    return calculateInvoiceTotals(invoice);
  }

  public async createCustomInvoice(type: InvoiceType, brokerId: string | undefined, companyId: string | undefined): Promise<string> {
    return createCustomInvoice(type, brokerId, companyId);
  }

  public async createInvoice(
    invoicingType: InvoicingType,
    recipientId: string,
    examinations: Examination[],
    externalTests: ExternalTest[],
    services: Service[],
    startDate: Date,
    endDate: Date,
    isVirtual: boolean,
    t: (entry: string, params?: Record<string, unknown>) => string,
    d: (date: Date, format: string) => string
  ): Promise<string> {
    return createInvoice(invoicingType, recipientId, examinations, externalTests, services, startDate, endDate, isVirtual, t, d);
  }

  public async getInvoiceCandidates(startDate: Date, endDate: Date, invoicingType: InvoicingType): Promise<InvoiceCandidate[]> {
    return getInvoiceCandidates(startDate, endDate, invoicingType);
  }

  public async getInvoicesByBroker(brokerId: string): Promise<Invoice[]> {
    return getInvoicesByBroker(brokerId);
  }

  public async getInvoicesByCompany(companyId: string): Promise<Invoice[]> {
    return getInvoicesByCompany(companyId);
  }

  public async getInvoicesByYear(year: number): Promise<Invoice[]> {
    return getInvoicesByYear(year);
  }

  public async getInvoicesByYearlyPaymentLumpSum(companyId: string, year: number): Promise<Invoice[]> {
    return getInvoicesByYearlyPaymentLumpSum(companyId, year);
  }

  public async getInvoicesWithFilters(
    startDate: Date,
    endDate: Date,
    companyId: string | undefined,
    brokerId: string | undefined,
    state: string | undefined
  ): Promise<Invoice[]> {
    return getInvoicesWithFilters(startDate, endDate, companyId, brokerId, state);
  }

  public async getLastInvoiceByBroker(brokerId: string): Promise<Invoice | undefined> {
    return getLastInvoiceByBroker(brokerId);
  }

  public async getLastInvoiceByCompany(companyId: string): Promise<Invoice | undefined> {
    return getLastInvoiceByCompany(companyId);
  }

  public async getNextInvoice(codeSort: number): Promise<Invoice | undefined> {
    return getNextInvoice(codeSort);
  }

  public async getNextInvoiceCodeByDateAndFirm(date: Date, isVirtual: boolean): Promise<number> {
    return getNextInvoiceCodeByDateAndFirm(date, isVirtual);
  }

  public async getPreviousInvoice(codeSort: number): Promise<Invoice | undefined> {
    return getPreviousInvoice(codeSort);
  }

  public async markAs(invoice: Invoice, state: InvoiceState): Promise<void> {
    return markAs(invoice, state);
  }
}

export const invoiceModel: InvoiceModel = new InvoiceModel();
