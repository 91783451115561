import { format } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";

export const exportEdits = async (): Promise<void> => {
  try {
    const rawEdits: unknown[] | undefined = await indexedDbModel.getDocuments("offlineEdits");
    const rawUploads: unknown[] | undefined = await indexedDbModel.getDocuments("offlineUploads");

    const jsonData: Record<string, unknown> = {
      offlineEdits: rawEdits,
      offlineUploads: rawUploads,
    };

    const link = document.createElement("a");
    link.download = `offline-${format(new Date(), "yyyy-MM-dd-HH-mm-ss")}.json`;
    const blob = new Blob([JSON.stringify(jsonData)], { type: "application/json" });
    link.href = window.URL.createObjectURL(blob);
    link.click();
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.exportEdits", {}, error);
  }
};
