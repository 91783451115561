import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";

export class PaperHelpers {
  public static getCustomFooter(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    leftText?: string,
    rightText?: string
  ): (currentPage: number, pageCount: number) => Record<string, unknown> {
    return (currentPage: number, pageCount: number) => {
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(15), 0],
        columns: [
          {
            style: "footerText",
            text: leftText ?? "",
            width: DataHelpers.mmToPoints(120),
          },
          {
            alignment: "right",
            style: "footerText",
            text:
              rightText !== undefined
                ? `${rightText} - ${t("paper.pagination", { current: currentPage, count: pageCount })}`
                : t("paper.pagination", { current: currentPage, count: pageCount }),
            width: "*",
          },
        ],
      };
    };
  }

  public static async getCustomHeader(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    reference?: string,
    isAccounting = false
  ): Promise<() => Record<string, unknown>> {
    // get logo
    const firmId: string = firmModel.getSelectedFirmId();
    const logoData: string | undefined = await StorageHelpers.blobToBase64(
      await StorageHelpers.fetchAsBlob(`/assets/features/images/paper-logo-${firmId}.png`)
    );
    if (logoData === undefined) throw new Error("Unable to read logo");

    // get firm
    const firm: Firm = await firmModel.getDocument(firmId);

    const columns: Record<string, unknown>[] = [
      {
        fit: [DataHelpers.mmToPoints(20), DataHelpers.mmToPoints(20)],
        height: DataHelpers.mmToPoints(20),
        image: `data:image/png;base64,${logoData}`,
        width: DataHelpers.mmToPoints(25),
      },
      {
        stack: [
          { text: firm.businessName ?? "", style: "headerText", bold: true },
          { text: `${firm.address.street} - ${firm.address.zipCode} ${firm.address.town} (${firm.address.province})`, style: "headerText" },
          {
            text: `${t("gen.paper.vatCodePrefix")}: ${firm.vatCode} - ${t("gen.paper.fiscalCodePrefix")}: ${firm.fiscalCode}`,
            style: "headerText",
          },
          {
            text: `${t("gen.paper.phonePrefix")}: ${firm.phone} - ${t("gen.paper.emailPrefix")}: ${
              isAccounting === true ? firm.accountingEmail : firm.email
            }`,
            style: "headerText",
          },
        ],
        width: "*",
      },
    ];

    if (reference !== undefined) {
      const referenceTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          { text: reference, style: "referenceText", border: [true, true, true, true] },
        ],
      ];

      columns.push({
        table: {
          body: referenceTableBody,
          headerRows: 0,
          widths: ["*", "auto"],
        },
        layout: {
          hLineWidth: () => 0.5,
          hLineColor: () => "black",
        },
        width: DataHelpers.mmToPoints(30),
      });
    }

    return () => {
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(15), 0],
        columns: columns,
      };
    };
  }
}
