import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";
import { storageModel } from "../StorageModel";

export const uploadBase64 = async (path: string, fileName: string, base64File: string, metadata?: Record<string, string>): Promise<StorageFile> => {
  try {
    return storageModel.uploadFile(path, await StorageHelpers.base64StringToFile(base64File, fileName), fileName, metadata);
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageModel.uploadBase64", { path, fileName, base64File, metadata }, error);
    return new StorageFile();
  }
};
