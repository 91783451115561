import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Contract } from "../objects/Contract";
import { contractModel } from "./ContractModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class ContractOfflineModel extends OfflineModuleModel<Contract> {
  public constructor() {
    super(() => new Contract(), "contracts", "contract.contracts", "contract");
  }

  public async downloadCache(): Promise<Contract[]> {
    try {
      if (companyModel.offlineModuleModel === undefined) return [];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let contracts: Contract[] = [];
      for (const company of companies) {
        contracts = [...contracts, ...(await contractModel.getContractsByCompany(company.id))];
      }

      return contracts;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ContractOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const contractOfflineModel: ContractOfflineModel = new ContractOfflineModel();
