import { DocumentReference } from "firebase/firestore";

import { Attachment } from "./Attachment";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Ending } from "../objects/Ending";
import { examinationModel } from "../models/ExaminationModel";
import { ExaminationState } from "./ExaminationState";
import { ExaminationTest } from "./ExaminationTest";
import { FamilyHistory } from "../objects/FamilyHistory";
import { FarHistory } from "../objects/FarHistory";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { JudgmentState } from "./JudgmentState";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { LinkedExamType } from "@/features/modules/examType/objects/LinkedExamType";
import { LinkedExternalTest } from "@/features/modules/externalTest/objects/LinkedExternalTest";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LinkedInvoice } from "@/features/modules/invoice/objects/LinkedInvoice";
import { LinkedRisk } from "@/features/modules/risk/objects/LinkedRisk";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { PhysicalExam } from "../objects/PhysicalExam";
import { PhysioHistory } from "../objects/PhysioHistory";
import { RecentHistory } from "../objects/RecentHistory";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { WorkHistory } from "../objects/WorkHistory";

import {
  ArrayByKeyField,
  ArrayField,
  BooleanField,
  DateField,
  DateStrictField,
  DateTimeField,
  EnumField,
  NumberField,
  NumberWithDefaultField,
  ObjectField,
  ObjectStrictField,
  StringField,
  StringStrictField,
} from "@/core/fields";

export class Examination extends FirestoreOfflineDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public firm: LinkedFirm | undefined = undefined;
  public date: Date = new Date();
  public time: Date | undefined = undefined;
  public hireDate: Date | undefined = undefined;
  public dutyName: string | undefined = undefined;
  public dutyDate: Date | undefined = undefined;
  public frequency = 999;
  public employee: LinkedEmployee | undefined = undefined;
  public company: LinkedCompany | undefined = undefined;
  public branch: LinkedBranch | undefined = undefined;
  public doctor: LinkedDoctor | undefined = undefined;
  public type: LinkedExamType | undefined = undefined;
  public state: ExaminationState = ExaminationState.Draft;
  public isBillable = true;
  public invoice: LinkedInvoice | undefined = undefined;
  public risks: Record<string, LinkedRisk> = {};
  public risksNotes: string | undefined = undefined;
  public examinationTests: Record<string, ExaminationTest> = {};
  public externalTests: Record<string, LinkedExternalTest> = {};
  public testsNotes: string | undefined = undefined;
  public surveys: Record<string, LinkedSurvey> = {};
  public surveysNotes: string | undefined = undefined;
  public workHistory: WorkHistory = new WorkHistory();
  public familyHistory: FamilyHistory = new FamilyHistory();
  public physioHistory: PhysioHistory = new PhysioHistory();
  public farHistory: FarHistory = new FarHistory();
  public recentHistory: RecentHistory = new RecentHistory();
  public physicalExam: PhysicalExam = new PhysicalExam();
  public employeeJudgmentFile: StorageFile | undefined = undefined;
  public companyJudgmentFile: StorageFile | undefined = undefined;
  public healthRecordFile: StorageFile | undefined = undefined;
  public attachments: Attachment[] = [];
  public ending: Ending = new Ending();
  public testsState = false;
  public surveysState = false;
  public judgmentState = false;
  public isPublic = false;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Examination {
    super.fromFirestore(data, id, firestoreRef);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.time = DateTimeField.fromFirestore(data.time);
    this.hireDate = DateField.fromFirestore(data.hireDate);
    this.dutyName = StringField.fromFirestore(data.dutyName);
    this.dutyDate = DateField.fromFirestore(data.dutyDate);
    this.frequency = NumberWithDefaultField.fromFirestore(data.frequency, 999);
    this.employee = ObjectField.fromFirestore<LinkedEmployee>(data.employee, (value) => new LinkedEmployee(value));
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.branch = ObjectField.fromFirestore<LinkedBranch>(data.branch, (value) => new LinkedBranch(value));
    this.doctor = ObjectField.fromFirestore<LinkedDoctor>(data.doctor, (value) => new LinkedDoctor(value));
    this.type = ObjectField.fromFirestore<LinkedExamType>(data.type, (value) => new LinkedExamType(value));
    this.state = EnumField.fromFirestore<ExaminationState>(data.state, Object.values(ExaminationState), ExaminationState.Draft);
    this.isBillable = BooleanField.fromFirestore(data.isBillable);
    this.invoice = ObjectField.fromFirestore<LinkedInvoice>(data.invoice, (value) => new LinkedInvoice(value));
    this.risks = ArrayByKeyField.fromFirestore<LinkedRisk>(data.risks, (value) => new LinkedRisk(value));
    this.risksNotes = StringField.fromFirestore(data.risksNotes);
    this.examinationTests = ArrayByKeyField.fromFirestore<ExaminationTest>(data.examinationTests, (value) => new ExaminationTest(value));
    this.externalTests = ArrayByKeyField.fromFirestore<LinkedExternalTest>(data.externalTests, (value) => new LinkedExternalTest(value));
    this.testsNotes = StringField.fromFirestore(data.testsNotes);
    this.surveys = ArrayByKeyField.fromFirestore<LinkedSurvey>(data.surveys, (value) => new LinkedSurvey(value));
    this.surveysNotes = StringField.fromFirestore(data.surveysNotes);
    this.workHistory = ObjectStrictField.fromFirestore<WorkHistory>(data.workHistory, (value) => new WorkHistory(value));
    this.familyHistory = ObjectStrictField.fromFirestore<FamilyHistory>(data.familyHistory, (value) => new FamilyHistory(value));
    this.physioHistory = ObjectStrictField.fromFirestore<PhysioHistory>(data.physioHistory, (value) => new PhysioHistory(value));
    this.farHistory = ObjectStrictField.fromFirestore<FarHistory>(data.farHistory, (value) => new FarHistory(value));
    this.recentHistory = ObjectStrictField.fromFirestore<RecentHistory>(data.recentHistory, (value) => new RecentHistory(value));
    this.physicalExam = ObjectStrictField.fromFirestore<PhysicalExam>(data.physicalExam, (value) => new PhysicalExam(value));
    this.employeeJudgmentFile = ObjectField.fromFirestore<StorageFile>(data.employeeJudgmentFile, (value) => new StorageFile(value));
    this.companyJudgmentFile = ObjectField.fromFirestore<StorageFile>(data.companyJudgmentFile, (value) => new StorageFile(value));
    this.healthRecordFile = ObjectField.fromFirestore<StorageFile>(data.healthRecordFile, (value) => new StorageFile(value));
    this.attachments = ArrayField.fromFirestore<Attachment>(data.attachments, (value) => new Attachment(value));
    this.ending = ObjectStrictField.fromFirestore<Ending>(data.ending, (value) => new Ending(value));
    this.testsState = BooleanField.fromFirestore(data.testsState);
    this.surveysState = BooleanField.fromFirestore(data.surveysState);
    this.judgmentState = BooleanField.fromFirestore(data.judgmentState);
    this.isPublic = BooleanField.fromFirestore(data.isPublic);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.time = DateTimeField.toFirestore(this.time);
    firestoreData.hireDate = DateField.toFirestore(this.hireDate);
    firestoreData.dutyName = StringField.toFirestore(this.dutyName);
    firestoreData.dutyDate = DateField.toFirestore(this.dutyDate);
    firestoreData.frequency = NumberWithDefaultField.toFirestore(this.frequency, 999);
    firestoreData.employee = ObjectField.toFirestore<LinkedEmployee>(this.employee, (value) => value.toFirestore());
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.branch = ObjectField.toFirestore<LinkedBranch>(this.branch, (value) => value.toFirestore());
    firestoreData.doctor = ObjectField.toFirestore<LinkedDoctor>(this.doctor, (value) => value.toFirestore());
    firestoreData.type = ObjectField.toFirestore<LinkedExamType>(this.type, (value) => value.toFirestore());
    firestoreData.state = EnumField.toFirestore<ExaminationState>(this.state, ExaminationState.Draft);
    firestoreData.isBillable = BooleanField.toFirestore(this.isBillable);
    firestoreData.invoice = ObjectField.toFirestore<LinkedInvoice>(this.invoice, (value) => value.toFirestore());
    firestoreData.risks = ArrayByKeyField.toFirestore<LinkedRisk>(this.risks, (value) => value.toFirestore());
    firestoreData.risksNotes = StringField.toFirestore(this.risksNotes);
    firestoreData.examinationTests = ArrayByKeyField.toFirestore<ExaminationTest>(this.examinationTests, (value) => value.toFirestore());
    firestoreData.externalTests = ArrayByKeyField.toFirestore<LinkedExternalTest>(this.externalTests, (value) => value.toFirestore());
    firestoreData.testsNotes = StringField.toFirestore(this.testsNotes);
    // set firestoreData.testTypesIds with this.examinationTests testType Id but avoid undefined and avoid duplicates
    firestoreData.testTypesIds = Object.values(this.examinationTests).reduce((accumulator: string[], currentValue: ExaminationTest) => {
      if (currentValue.testType !== undefined && !accumulator.includes(currentValue.testType.id)) {
        accumulator.push(currentValue.testType.id);
      }
      return accumulator;
    }, []);
    firestoreData.surveys = ArrayByKeyField.toFirestore<LinkedSurvey>(this.surveys, (value) => value.toFirestore());
    firestoreData.surveysNotes = StringField.toFirestore(this.surveysNotes);
    firestoreData.workHistory = ObjectStrictField.toFirestore<WorkHistory>(this.workHistory, (value) => value.toFirestore());
    firestoreData.familyHistory = ObjectStrictField.toFirestore<FamilyHistory>(this.familyHistory, (value) => value.toFirestore());
    firestoreData.physioHistory = ObjectStrictField.toFirestore<PhysioHistory>(this.physioHistory, (value) => value.toFirestore());
    firestoreData.farHistory = ObjectStrictField.toFirestore<FarHistory>(this.farHistory, (value) => value.toFirestore());
    firestoreData.recentHistory = ObjectStrictField.toFirestore<RecentHistory>(this.recentHistory, (value) => value.toFirestore());
    firestoreData.physicalExam = ObjectStrictField.toFirestore<PhysicalExam>(this.physicalExam, (value) => value.toFirestore());
    firestoreData.employeeJudgmentFile = ObjectField.toFirestore<StorageFile>(this.employeeJudgmentFile, (value) => value.toFirestore());
    firestoreData.companyJudgmentFile = ObjectField.toFirestore<StorageFile>(this.companyJudgmentFile, (value) => value.toFirestore());
    firestoreData.healthRecordFile = ObjectField.toFirestore<StorageFile>(this.healthRecordFile, (value) => value.toFirestore());
    firestoreData.attachments = ArrayField.toFirestore<Attachment>(this.attachments, (value) => value.toFirestore());
    firestoreData.ending = ObjectStrictField.toFirestore<Ending>(this.ending, (value) => value.toFirestore());
    firestoreData.testsState = BooleanField.toFirestore(this.testsState);
    firestoreData.surveysState = BooleanField.toFirestore(this.surveysState);
    firestoreData.judgmentState = BooleanField.toFirestore(this.judgmentState);
    firestoreData.isPublic = BooleanField.toFirestore(this.isPublic);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Examination {
    super.fromOfflineCache(data);

    this.code = NumberField.fromOfflineCache(data.code);
    this.codeSort = NumberField.fromOfflineCache(data.codeSort);
    this.codeDisplay = StringStrictField.fromOfflineCache(data.codeDisplay, "");
    this.year = NumberField.fromOfflineCache(data.year);
    this.firm = ObjectField.fromOfflineCache<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.date = DateStrictField.fromOfflineCache(data.date, new Date());
    this.time = DateTimeField.fromOfflineCache(data.time);
    this.hireDate = DateField.fromOfflineCache(data.hireDate);
    this.dutyName = StringField.fromOfflineCache(data.dutyName);
    this.dutyDate = DateField.fromOfflineCache(data.dutyDate);
    this.frequency = NumberWithDefaultField.fromOfflineCache(data.frequency, 999);
    this.employee = ObjectField.fromOfflineCache<LinkedEmployee>(data.employee, (value) => {
      const linkedEmployee: LinkedEmployee = new LinkedEmployee();
      return linkedEmployee.fromOfflineCache(value);
    });
    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => {
      const linkedCompany: LinkedCompany = new LinkedCompany();
      return linkedCompany.fromOfflineCache(value);
    });
    this.branch = ObjectField.fromOfflineCache<LinkedBranch>(data.branch, (value) => {
      const linkedBranch: LinkedBranch = new LinkedBranch();
      return linkedBranch.fromOfflineCache(value);
    });
    this.doctor = ObjectField.fromOfflineCache<LinkedDoctor>(data.doctor, (value) => {
      const linkedDoctor: LinkedDoctor = new LinkedDoctor();
      return linkedDoctor.fromOfflineCache(value);
    });
    this.type = ObjectField.fromOfflineCache<LinkedExamType>(data.type, (value) => {
      const linkedExamType: LinkedExamType = new LinkedExamType();
      return linkedExamType.fromOfflineCache(value);
    });
    this.state = EnumField.fromOfflineCache<ExaminationState>(data.state, Object.values(ExaminationState), ExaminationState.Draft);
    this.isBillable = BooleanField.fromOfflineCache(data.isBillable);
    this.invoice = ObjectField.fromOfflineCache<LinkedInvoice>(data.invoice, (value) => {
      const linkedInvoice: LinkedInvoice = new LinkedInvoice();
      return linkedInvoice.fromFirestore(value);
    });
    this.risks = ArrayByKeyField.fromOfflineCache<LinkedRisk>(data.risks, (value) => {
      const linkedRisk: LinkedRisk = new LinkedRisk();
      return linkedRisk.fromOfflineCache(value);
    });
    this.risksNotes = StringField.fromOfflineCache(data.risksNotes);
    this.examinationTests = ArrayByKeyField.fromOfflineCache<ExaminationTest>(data.examinationTests, (value) => {
      const examinationTest: ExaminationTest = new ExaminationTest();
      return examinationTest.fromFirestore(value);
    });
    this.externalTests = ArrayByKeyField.fromOfflineCache<LinkedExternalTest>(data.externalTests, (value) => {
      const linkedExternalTest: LinkedExternalTest = new LinkedExternalTest();
      return linkedExternalTest.fromFirestore(value);
    });
    this.testsNotes = StringField.fromOfflineCache(data.testsNotes);
    this.surveys = ArrayByKeyField.fromOfflineCache<LinkedSurvey>(data.surveys, (value) => {
      const linkedSurvey: LinkedSurvey = new LinkedSurvey();
      return linkedSurvey.fromOfflineCache(value);
    });
    this.surveysNotes = StringField.fromOfflineCache(data.surveysNotes);
    this.workHistory = ObjectStrictField.fromOfflineCache<WorkHistory>(data.workHistory, (value) => {
      const workHistory: WorkHistory = new WorkHistory();
      return workHistory.fromOfflineCache(value);
    });
    this.familyHistory = ObjectStrictField.fromOfflineCache<FamilyHistory>(data.familyHistory, (value) => {
      const familyHistory: FamilyHistory = new FamilyHistory();
      return familyHistory.fromOfflineCache(value);
    });
    this.physioHistory = ObjectStrictField.fromOfflineCache<PhysioHistory>(data.physioHistory, (value) => {
      const physioHistory: PhysioHistory = new PhysioHistory();
      return physioHistory.fromOfflineCache(value);
    });
    this.farHistory = ObjectStrictField.fromOfflineCache<FarHistory>(data.farHistory, (value) => {
      const farHistory: FarHistory = new FarHistory();
      return farHistory.fromOfflineCache(value);
    });
    this.recentHistory = ObjectStrictField.fromOfflineCache<RecentHistory>(data.recentHistory, (value) => {
      const recentHistory: RecentHistory = new RecentHistory();
      return recentHistory.fromOfflineCache(value);
    });
    this.physicalExam = ObjectStrictField.fromOfflineCache<PhysicalExam>(data.physicalExam, (value) => {
      const physicalExam: PhysicalExam = new PhysicalExam();
      return physicalExam.fromOfflineCache(value);
    });
    this.employeeJudgmentFile = ObjectField.fromOfflineCache<StorageFile>(data.employeeJudgmentFile, (value) => {
      const storageFile: StorageFile = new StorageFile();
      return storageFile.fromOfflineCache(value);
    });
    this.companyJudgmentFile = ObjectField.fromOfflineCache<StorageFile>(data.companyJudgmentFile, (value) => {
      const storageFile: StorageFile = new StorageFile();
      return storageFile.fromOfflineCache(value);
    });
    this.healthRecordFile = ObjectField.fromOfflineCache<StorageFile>(data.healthRecordFile, (value) => {
      const storageFile: StorageFile = new StorageFile();
      return storageFile.fromOfflineCache(value);
    });
    this.attachments = ArrayField.fromOfflineCache<Attachment>(data.attachments, (value) => {
      const attachment: Attachment = new Attachment();
      return attachment.fromOfflineCache(value);
    });
    this.ending = ObjectStrictField.fromOfflineCache<Ending>(data.ending, (value) => {
      const ending: Ending = new Ending();
      return ending.fromOfflineCache(value);
    });
    this.testsState = BooleanField.fromOfflineCache(data.testsState);
    this.surveysState = BooleanField.fromOfflineCache(data.surveysState);
    this.judgmentState = BooleanField.fromOfflineCache(data.judgmentState);
    this.isPublic = BooleanField.fromOfflineCache(data.isPublic);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.code = NumberField.toOfflineCache(this.code);
    firestoreData.codeSort = NumberField.toOfflineCache(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toOfflineCache(this.codeDisplay);
    firestoreData.year = NumberField.toOfflineCache(this.year);
    firestoreData.firm = ObjectField.toOfflineCache<LinkedFirm>(this.firm, (value) => value.toOfflineCache());
    firestoreData.date = DateStrictField.toOfflineCache(this.date);
    firestoreData.time = DateTimeField.toOfflineCache(this.time);
    firestoreData.hireDate = DateField.toOfflineCache(this.hireDate);
    firestoreData.dutyName = StringField.toOfflineCache(this.dutyName);
    firestoreData.dutyDate = DateField.toOfflineCache(this.dutyDate);
    firestoreData.frequency = NumberWithDefaultField.toOfflineCache(this.frequency, 999);
    firestoreData.employee = ObjectField.toOfflineCache<LinkedEmployee>(this.employee, (value) => value.toOfflineCache());
    firestoreData.company = ObjectField.toOfflineCache<LinkedCompany>(this.company, (value) => value.toOfflineCache());
    firestoreData.branch = ObjectField.toOfflineCache<LinkedBranch>(this.branch, (value) => value.toOfflineCache());
    firestoreData.doctor = ObjectField.toOfflineCache<LinkedDoctor>(this.doctor, (value) => value.toOfflineCache());
    firestoreData.type = ObjectField.toOfflineCache<LinkedExamType>(this.type, (value) => value.toOfflineCache());
    firestoreData.state = EnumField.toOfflineCache<ExaminationState>(this.state, ExaminationState.Draft);
    firestoreData.isBillable = BooleanField.toOfflineCache(this.isBillable);
    firestoreData.invoice = ObjectField.toOfflineCache<LinkedInvoice>(this.invoice, (value) => value.toFirestore());
    firestoreData.risks = ArrayByKeyField.toOfflineCache<LinkedRisk>(this.risks, (value) => value.toOfflineCache());
    firestoreData.risksNotes = StringField.toOfflineCache(this.risksNotes);
    firestoreData.examinationTests = ArrayByKeyField.toOfflineCache<ExaminationTest>(this.examinationTests, (value) => value.toFirestore());
    firestoreData.externalTests = ArrayByKeyField.toOfflineCache<LinkedExternalTest>(this.externalTests, (value) => value.toFirestore());
    firestoreData.testsNotes = StringField.toOfflineCache(this.testsNotes);
    firestoreData.surveys = ArrayByKeyField.toOfflineCache<LinkedSurvey>(this.surveys, (value) => value.toOfflineCache());
    firestoreData.surveysNotes = StringField.toOfflineCache(this.surveysNotes);
    firestoreData.workHistory = ObjectStrictField.toOfflineCache<WorkHistory>(this.workHistory, (value) => value.toOfflineCache());
    firestoreData.familyHistory = ObjectStrictField.toOfflineCache<FamilyHistory>(this.familyHistory, (value) => value.toOfflineCache());
    firestoreData.physioHistory = ObjectStrictField.toOfflineCache<PhysioHistory>(this.physioHistory, (value) => value.toOfflineCache());
    firestoreData.farHistory = ObjectStrictField.toOfflineCache<FarHistory>(this.farHistory, (value) => value.toOfflineCache());
    firestoreData.recentHistory = ObjectStrictField.toOfflineCache<RecentHistory>(this.recentHistory, (value) => value.toOfflineCache());
    firestoreData.physicalExam = ObjectStrictField.toOfflineCache<PhysicalExam>(this.physicalExam, (value) => value.toOfflineCache());
    firestoreData.employeeJudgmentFile = ObjectField.toOfflineCache<StorageFile>(this.employeeJudgmentFile, (value) => value.toOfflineCache());
    firestoreData.companyJudgmentFile = ObjectField.toOfflineCache<StorageFile>(this.companyJudgmentFile, (value) => value.toOfflineCache());
    firestoreData.healthRecordFile = ObjectField.toOfflineCache<StorageFile>(this.healthRecordFile, (value) => value.toOfflineCache());
    firestoreData.attachments = ArrayField.toOfflineCache<Attachment>(this.attachments, (value) => value.toOfflineCache());
    firestoreData.ending = ObjectStrictField.toOfflineCache<Ending>(this.ending, (value) => value.toOfflineCache());
    firestoreData.testsState = BooleanField.toOfflineCache(this.testsState);
    firestoreData.surveysState = BooleanField.toOfflineCache(this.surveysState);
    firestoreData.judgmentState = BooleanField.toOfflineCache(this.judgmentState);
    firestoreData.isPublic = BooleanField.toOfflineCache(this.isPublic);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.doctor !== undefined) {
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.doctor.lastName)];
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.doctor.firstName)];
    }
    if (this.employee !== undefined) {
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.employee.lastName)];
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.employee.firstName)];
    }
    if (this.company !== undefined) {
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.company.name)];
    }
    if (this.codeDisplay !== undefined) this.searchKeys.push(this.codeDisplay.toLowerCase());
  }

  public async setCodes(): Promise<void> {
    this.code = await examinationModel.getNextExaminationCodeByDateAndFirm(this.date);
    this.codeSort = (this.date.getFullYear() - 2000) * 1000000 + this.code;
    this.codeDisplay = `${this.code}/${this.date.getFullYear()}`;
    this.year = this.date.getFullYear();
  }

  public setGoodHealthFields(): void {
    this.farHistory.goodHealthCheck = true;

    this.physicalExam.generalCondition = "Buone";
    this.physicalExam.skinCheck = true;
    this.physicalExam.mucousMembranesCheck = true;
    this.physicalExam.annexesCheck = true;
    this.physicalExam.lymphGlandsCheck = true;
    this.physicalExam.headNeckCheck = true;
    this.physicalExam.thyroidCheck = true;
    this.physicalExam.chestCheck = true;
    this.physicalExam.expandabilityCheck = true;
    this.physicalExam.fvtCheck = true;
    this.physicalExam.percussionCheck = true;
    this.physicalExam.mvCheck = true;
    this.physicalExam.tonesCheck = true;
    this.physicalExam.breaksCheck = true;
    this.physicalExam.wristsCheck = true;
    this.physicalExam.varicesCheck = true;
    this.physicalExam.shapeCheck = true;
    this.physicalExam.palpationCheck = true;
    this.physicalExam.listeningCheck = true;
    this.physicalExam.liverCheck = true;
    this.physicalExam.spleenCheck = true;
    this.physicalExam.mobilityCheck = true;
    this.physicalExam.articularityCheck = true;
    this.physicalExam.musculatureCheck = true;
    this.physicalExam.rachisCheck = true;
    this.physicalExam.lasegueCheck = true;
    this.physicalExam.wassermanCheck = true;
    this.physicalExam.mmCheck = true;
    this.physicalExam.sensitivityCheck = true;
    this.physicalExam.rotCheck = true;
    this.physicalExam.humorCheck = true;
    this.physicalExam.tinelCheck = true;
    this.physicalExam.phalenCheck = true;
    this.physicalExam.giordanoCheck = true;
  }

  public beforeSaveActions(): void {
    // sync date and time
    this.time?.setDate(this.date.getDate() as number);
    this.time?.setMonth(this.date.getMonth() as number);
    this.time?.setFullYear(this.date.getFullYear() as number);

    // if the examination is already completed or billed, set everything as completed without checking
    if (this.state === ExaminationState.Completed || this.state === ExaminationState.Billed) {
      this.testsState = true;
      this.surveysState = true;
      this.judgmentState = true;
      return;
    }

    // set tests state
    this.testsState = true;
    for (const examinationTest of this.getExaminationTests()) {
      if (examinationTest.testForm === undefined) continue;
      if (examinationTest.testForm.isCompleted === false) {
        this.testsState = false;
        break;
      }
    }
    for (const externalTest of this.getExternalTests()) {
      if (externalTest.testForm === undefined) continue;
      if (externalTest.testForm.isCompleted === false) {
        this.testsState = false;
        break;
      }
    }

    // set surveys state
    this.surveysState = true;
    for (const linkedSurvey of this.getLinkedSurveys()) {
      if (linkedSurvey.surveyForm === undefined) continue;
      if (linkedSurvey.surveyForm.isCompleted === false) {
        this.surveysState = false;
        break;
      }
    }

    // set judgment state
    this.judgmentState = this.ending.judgment !== JudgmentState.NotEvaluated && this.ending.judgment !== JudgmentState.NotExpressed;

    // set global state
    this.state = ExaminationState.Draft;
    if (this.testsState === true && this.surveysState === true && this.judgmentState === true) {
      this.state = ExaminationState.Completed;
      if (this.invoice !== undefined) this.state = ExaminationState.Billed;
    } else if (this.ending.judgment !== JudgmentState.NotEvaluated) {
      this.state = ExaminationState.Pending;
    }
  }

  public getLinkedRisks(): LinkedRisk[] {
    return DataHelpers.objectToSortedArray<LinkedRisk>(this.risks);
  }

  public setLinkedRisks(linkedRisks: LinkedRisk[]): void {
    this.risks = DataHelpers.sortedArrayToObject<LinkedRisk>(linkedRisks);
  }

  public addLinkedRisk(linkedRisk: LinkedRisk): void {
    linkedRisk.order = Object.keys(this.risks).length + 1;
    this.risks[linkedRisk.id] = linkedRisk;
  }

  public removeLinkedRisk(linkedRisk: LinkedRisk): void {
    delete this.risks[linkedRisk.id];
  }

  public getExaminationTests(): ExaminationTest[] {
    return DataHelpers.objectToSortedArray<ExaminationTest>(this.examinationTests);
  }

  public setExaminationTests(examinationTests: ExaminationTest[]): void {
    this.examinationTests = DataHelpers.sortedArrayToObject<ExaminationTest>(examinationTests);
  }

  public addExaminationTest(examinationTest: ExaminationTest): void {
    // examinationTest.order = Object.keys(this.examinationTests).length + 1;
    this.examinationTests[examinationTest.id] = examinationTest;
  }

  public removeExaminationTest(examinationTest: ExaminationTest): void {
    delete this.examinationTests[examinationTest.id];
  }

  public getExternalTests(): LinkedExternalTest[] {
    return DataHelpers.objectToSortedArray<LinkedExternalTest>(this.externalTests);
  }

  public setExternalTests(externalTests: LinkedExternalTest[]): void {
    this.externalTests = DataHelpers.sortedArrayToObject<LinkedExternalTest>(externalTests);
  }

  public addExternalTest(externalTest: LinkedExternalTest): void {
    // externalTest.order = Object.keys(this.externalTests).length + 1;
    this.externalTests[externalTest.id] = externalTest;
  }

  public removeExternalTest(externalTest: LinkedExternalTest): void {
    delete this.externalTests[externalTest.id];
  }

  public getLinkedSurveys(): LinkedSurvey[] {
    return DataHelpers.objectToSortedArray<LinkedSurvey>(this.surveys);
  }

  public setLinkedSurveys(linkedSurveys: LinkedSurvey[]): void {
    this.surveys = DataHelpers.sortedArrayToObject<LinkedSurvey>(linkedSurveys);
  }

  public addLinkedSurvey(linkedSurvey: LinkedSurvey): void {
    linkedSurvey.order = Object.keys(this.surveys).length + 1;
    this.surveys[linkedSurvey.id] = linkedSurvey;
  }

  public removeLinkedSurvey(linkedSurvey: LinkedSurvey): void {
    delete this.surveys[linkedSurvey.id];
  }

  public getAllTests(): ExaminationTest[] {
    return [...this.getExaminationTests(), ...this.getExternalTests()];
  }

  public setTestsSummary(): void {
    const allTests: ExaminationTest[] = [...this.getExternalTests(), ...this.getExaminationTests()];

    const testsSummary: string[] = [];
    const testTypesCategories: string[] = [];

    for (const test of allTests) {
      if (testTypesCategories.includes(test.testType?.category?.name as string)) continue;
      testTypesCategories.push(test.testType?.category?.name as string);
    }

    for (const testTypeCategory of testTypesCategories) {
      const categorySummary: string[] = [];
      for (const test of allTests) {
        if (testTypeCategory !== test.testType?.category?.name) continue;
        categorySummary.push(test.testType?.name as string);
      }
      if (categorySummary.length > 0) testsSummary.push(`${testTypeCategory}: ${categorySummary.join(", ")}`);
    }

    if (testsSummary.length > 0) {
      this.testsNotes = testsSummary.join("; ");
    } else {
      this.testsNotes = undefined;
    }
  }

  public setSurveysSummary(): void {
    if (this.getLinkedSurveys().length > 0) {
      this.surveysNotes = this.getLinkedSurveys()
        .map((survey) => survey.name)
        .join(", ");
    } else {
      this.surveysNotes = undefined;
    }
  }
}
