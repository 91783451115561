import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { ServiceInvoicingType } from "./ServiceInvoicingType";
import { ServiceType } from "./ServiceType";

import { EnumField, NumberWithDefaultField, StringField } from "@/core/fields";

export class LinkedServiceType extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;
  public frequency = 999;
  public invoicingType: ServiceInvoicingType = ServiceInvoicingType.ByService;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromServiceType(serviceType: ServiceType): LinkedServiceType {
    const linkedServiceType: LinkedServiceType = new LinkedServiceType();
    linkedServiceType.id = serviceType.id;
    linkedServiceType.name = serviceType.name;
    linkedServiceType.frequency = serviceType.frequency;
    linkedServiceType.invoicingType = serviceType.invoicingType;

    return linkedServiceType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedServiceType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.frequency = NumberWithDefaultField.fromFirestore(data.frequency, 999);
    this.invoicingType = EnumField.fromFirestore<ServiceInvoicingType>(
      data.invoicingType,
      Object.values(ServiceInvoicingType),
      ServiceInvoicingType.ByService
    );

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.frequency = NumberWithDefaultField.toFirestore(this.frequency, 999);
    firestoreData.invoicingType = EnumField.toFirestore<ServiceInvoicingType>(this.invoicingType, ServiceInvoicingType.ByService);

    return firestoreData;
  }
}
