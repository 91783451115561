export const it = {
  vaccination: {
    date: "Data",
    dateError: "Selezionare una data valida",
    expiration: "Scadenza",
    type: "Tipo",
    typeError: "Selezionare un tipo",
    vaccination: "Vaccinazione",
    vaccinationOf: "Vaccinazione di {parent}",
    vaccinations: "Vaccinazioni",
    vaccinationsOf: "Vaccinazioni di {parent}",
  },
};
