
import { computed, defineComponent, onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { koruApp } from "@/main";
import { KoruEditorModule } from "../objects/KoruEditorModule";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "KoruEditor",
  props: {
    formSubmitted: { type: Boolean, default: false },
    isFormValid: { type: Boolean, default: false },
    module: { type: KoruEditorModule, default: undefined },
  },
  emits: ["update:formSubmitted", "update:module"],
  setup(props, { emit }) {
    const { t } = useLocale();
    const store = useStore();
    let eventBusIdSave: string | undefined = undefined;
    let eventBusIdSubmit: string | undefined = undefined;

    const user: User = store.getters[storeTypes.getters.getUser];

    const moduleRef = computed({
      get: () => props.module as KoruEditorModule<FirestoreDocument>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string = route.meta.requiredModule as string;

    const formSubmittedRef = computed({
      get: () => props.formSubmitted,
      set: (val) => emit("update:formSubmitted", val),
    });

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          eventBusIdSave = eventBus.on("koruEditorSave", (saveCommand?: () => Promise<void>) => handleSave(saveCommand));
          eventBusIdSubmit = eventBus.on("koruEditorSubmit", (submitCommand?: () => Promise<void>) => handleSubmit(submitCommand));

          await moduleRef.value.onMountedAction();
          await moduleRef.value.onLoadAction();

          if (moduleRef.value.suffix !== undefined) {
            localStorage.setItem(`koruLastSelectedId-${moduleRef.value.suffix}`, moduleRef.value.document.value.id);
          }
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
          store.commit(storeTypes.mutations.editingStart);
        }
      );
    };

    const handleUnload = async () => {
      await AppHelpers.tryOrError(async () => {
        if (eventBusIdSave !== undefined) eventBus.off(eventBusIdSave);
        if (eventBusIdSubmit !== undefined) eventBus.off(eventBusIdSubmit);

        await moduleRef.value.onUnloadAction();
      });
    };

    const handleSave = async (saveCommand?: () => void): Promise<void> => {
      formSubmittedRef.value = true;
      if (!props.isFormValid) {
        ToastHelpers.showToastWithWarning("formInvalid", t);
        return;
      }
      store.commit(storeTypes.mutations.editingStop);
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await moduleRef.value.onSubmitAction();
          if (saveCommand !== undefined) saveCommand();
        },
        "save",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleSubmit = async (submitCommand?: () => void): Promise<void> => {
      formSubmittedRef.value = true;
      if (!props.isFormValid) {
        ToastHelpers.showToastWithWarning("formInvalid", t);
        return;
      }
      store.commit(storeTypes.mutations.editingStop);
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await moduleRef.value.onSubmitAction();

          if (moduleRef.value.afterSubmitAction !== undefined) {
            await (moduleRef.value.afterSubmitAction as () => Promise<void>)();
          } else {
            Navigation.navigateTo(store.getters[storeTypes.getters.getPreviousRoute]);
          }

          if (submitCommand !== undefined) submitCommand();
        },
        "save",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    const closeEditor = () => {
      store.commit(storeTypes.mutations.editingStop);
      Navigation.navigateBack();
    };

    const getSaveCommands = () => {
      const renderSaveCommands: ContextCommand[] = [];

      if (moduleRef.value.saveCommands !== undefined && moduleRef.value.saveCommands.length > 0) {
        for (const saveCommand of moduleRef.value.saveCommands) {
          if (saveCommand.condition(moduleRef.value.document.value) === true) {
            renderSaveCommands.push(saveCommand);
          }
        }
      }

      return renderSaveCommands;
    };

    const confirmClose = (event: Event) => {
      const confirm = koruApp.config.globalProperties.$confirm;

      confirm.require({
        target: event.currentTarget as HTMLElement,
        message: t != undefined ? t("dialog.confirmCloseWithoutSave") : "dialog.confirmCloseWithoutSave",
        icon: "pi pi-exclamation-circle",
        accept: () => closeEditor(),
        acceptIcon: "pi pi-check",
        acceptLabel: t("gen.yes"),
        rejectIcon: "pi pi-times",
        rejectLabel: t("gen.no"),
      });
    };

    onMounted(() => {
      handleLoad();
    });

    onBeforeUnmount(() => {
      handleUnload();
    });

    return {
      closeEditor,
      confirmClose,
      getSaveCommands,
      handleKeydown,
      handleSubmit,
      moduleRef,
      requiredModule,
      t,
      user,
    };
  },
});
