export const it = {
  companyPriceList: {
    companyPriceList: "Listino prezzi",
    companyPriceListOf: "Listino prezzi di {parent}",
    companyPriceLists: "Listini prezzi",
    companyPriceListsOf: "Listini prezzi di {parent}",
    from: "Inizio validità",
    fromError: "Selezionare una data di inizio valida",
    priceList: "Listino prezzi",
    to: "Fine validità",
    toError: "Selezionare una data di fine valida",
    unlimited: "Illimitata",
  },
};
