
import { defineComponent } from "vue";

import { DomHandler } from "primevue/utils";

export default defineComponent({
  name: "KoruProgress",
  emits: ["block", "unblock"],
  props: {
    blocked: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    baseZIndex: {
      type: Number,
      default: 0,
    },
    autoZIndex: {
      type: Boolean,
      default: true,
    },
  },
  mounted(): void {
    if (this.blocked) {
      this.block();
    }
  },
  watch: {
    blocked(newValue: boolean): void {
      if (newValue === true) {
        this.block();
      } else {
        this.unblock();
      }
    },
  },
  methods: {
    block(): void {
      DomHandler.addClass(document.body, "p-overflow-hidden");
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      this.$emit("block");
    },
    unblock() {
      DomHandler.removeClass(document.body, "p-overflow-hidden");
      this.$emit("unblock");
    },
  },
});
