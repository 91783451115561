import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { OfflineModuleModel } from "../objects/OfflineModuleModel";

import {
  deleteCache,
  emptyQueue,
  exportEdits,
  getCacheByCollection,
  getOfflineState,
  getQueueCount,
  setCache,
  setOfflineState,
  uploadCache,
} from "./methods";

export class OfflineModel {
  public async deleteCache(): Promise<void> {
    return deleteCache();
  }

  public async emptyQueue(): Promise<void> {
    return emptyQueue();
  }

  public async exportEdits(): Promise<void> {
    return exportEdits();
  }

  public getCacheByCollection<T extends FirestoreOfflineDocument>(module: OfflineModuleModel<T>): Promise<T[]> {
    return getCacheByCollection(module);
  }

  public getOfflineState(): "online" | "offline" {
    return getOfflineState();
  }

  public async getQueueCount(): Promise<number> {
    return getQueueCount();
  }

  public async setCache(): Promise<void> {
    return setCache();
  }

  public setOfflineState(state: "online" | "offline"): void {
    return setOfflineState(state);
  }

  public async uploadCache(): Promise<void> {
    return uploadCache();
  }
}

export const offlineModel: OfflineModel = new OfflineModel();
