import { deleteDoc } from "firebase/firestore";
import { HttpsCallable, httpsCallable, HttpsCallableResult } from "firebase/functions";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { log } from "@/core/modules/log/Log";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const deleteDocument = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  firestoreDocument: T,
  parentId?: string,
  writeLog?: boolean
): Promise<boolean> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    // offline mode
    if (offlineModel.getOfflineState() === "offline" && firestoreDocument instanceof FirestoreOfflineDocument)
      return deleteOfflineDocument(user, model as FirestoreModel<FirestoreOfflineDocument>, firestoreDocument);

    // check if user can delete the document
    if (user.canDelete(model.roleModule, firestoreDocument) === false)
      throw new Error(`Unable to delete document #${firestoreDocument.id} in collection ${model.collectionName}`);

    // check if document is locked
    if (model.beforeDeleteFunction !== undefined) {
      const result: boolean = await runBeforeDeleteFunction(model.beforeDeleteFunction, firestoreDocument.id);
      if (result === false) return false;
    }

    // delete online document
    await deleteDoc(model.getDocumentReference(firestoreDocument.id, parentId));

    if (writeLog === true) log.notice(`Document ${firestoreDocument.id} deleted in collection ${model.collectionName}`);

    return true;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.deleteDocument", { model, firestoreDocument, parentId }, error);
    return false;
  }
};

const deleteOfflineDocument = async <T extends FirestoreOfflineDocument>(
  user: User,
  model: FirestoreModel<T>,
  firestoreDocument: T
): Promise<boolean> => {
  try {
    if (model.offlineModuleModel === undefined) throw new Error("offlineModuleModelNotSaveable");
    if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");
    if (model.offlineModuleModel.canDelete === false) throw new Error("offlineModuleModelNotSaveable");

    await indexedDbModel.deleteDocument(model.collectionName, firestoreDocument.id);
    await model.offlineModuleModel.deleteDocumentToQueue(firestoreDocument);
    return true;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.deleteOfflineDocument", { user, model, firestoreDocument }, error);
    return false;
  }
};

const runBeforeDeleteFunction = async (functionName: string, snapshotId: string): Promise<boolean> => {
  try {
    const beforeDeleteFunction: HttpsCallable = httpsCallable(firebase.functions, functionName);
    const functionResult: HttpsCallableResult = await beforeDeleteFunction({ snapshotId: snapshotId });
    const response: { result: string; where?: string } = functionResult.data as { result: string; where?: string };

    if (response == undefined || response.result == undefined) {
      console.log("actionError");
      return false;
    }
    if (response.result == "children") {
      console.log("children");
      return false;
    }
    if (response.result != "ok") {
      console.log("actionError");
      return false;
    }

    return true;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.runBeforeDeleteFunction", { functionName, snapshotId }, error);
    return false;
  }
};
