import { CompanyCostList } from "../objects/CompanyCostList";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import { getCompanyCostListsByCompanyAndFirm, isPeriodAvailable } from "./methods";

export class CompanyCostListModel extends FirestoreModel<CompanyCostList> {
  public constructor() {
    super(() => new CompanyCostList(), "companyCostLists", LockPolicy.DiscardUnsyncedChanges, "companyCostList");
    this.parentCollectionName = "companies";
  }

  public async getDocument(companyCostListId: string, parentId?: string): Promise<CompanyCostList> {
    return super.getDocument(companyCostListId, parentId);
  }

  public async createDocument(companyCostList: CompanyCostList, parentId?: string): Promise<string> {
    const firm: Firm = await firmModel.getSelectedFirm();
    companyCostList.firm = LinkedFirm.createFromFirm(firm);

    return super.createDocument(companyCostList, parentId);
  }

  public async updateDocument(companyCostList: CompanyCostList, parentId?: string): Promise<void> {
    return super.updateDocument(companyCostList, parentId);
  }

  public async deleteDocument(companyCostList: CompanyCostList, parentId?: string): Promise<boolean> {
    return super.deleteDocument(companyCostList, parentId);
  }

  public async getCompanyCostListsByCompanyAndFirm(companyId: string): Promise<CompanyCostList[]> {
    return getCompanyCostListsByCompanyAndFirm(companyId);
  }

  public async isPeriodAvailable(companyId: string, from: Date, to: Date | undefined, companyCostListId?: string): Promise<boolean> {
    return isPeriodAvailable(companyId, from, to, companyCostListId);
  }
}

export const companyCostListModel: CompanyCostListModel = new CompanyCostListModel();
