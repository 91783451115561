export const it = {
  legalRecordSession: {
    associatedBranch: "Sede associata",
    associateCompanyAndBranch: "Associa azienda e sede",
    associatedCompany: "Azienda associata",
    associatedCompanyAndBranch: "Azienda e sede associate",
    branchAddress: "Indirizzo sede",
    branchIdError: "Selezionare una sede",
    branchName: "Nome sede INAIL",
    companyIdError: "Selezionare un'azienda",
    createdAt: "Creazione",
    detail: "Dettagli",
    file: "File INAIL di partenza",
    fileError: "Selezionare un file",
    fiscalCode: "Codice fiscale",
    inailAssociationId: "ID Associazione INAIL",
    inailBranch: "Unità produttiva INAIL",
    inailBranchId: "ID unità produttiva INAIL",
    inailCompany: "Azienda INAIL",
    inailCompanyAndBranch: "Azienda e unità produttiva INAIL",
    inailFiscalCode: "Codice fiscale INAIL",
    inailVatCode: "Partita IVA INAIL",
    inputBranches: "Unità produttive",
    inputBranchesError: "Tutte le unità produttive devono essere associate ad aziende e sedi univoche",
    legalRecordSession: "Sessione allegati 3B",
    legalRecordSessions: "Sessioni allegati 3B",
    state: "Stato",
    states: {
      completed: "Completata",
      draft: "Bozza",
      ready: "Pronta",
    },
    vatCode: "Partita IVA",
    year: "Anno di riferimento",
    yearError: "Selezionare un anno di riferimento",
  },
  navigation: {
    legalRecordSession: "Sessioni allegati 3B",
  },
  buttons: {
    branchLegalRecordEdit: "Compila",
    companyAndBranchAssociate: "Associa",
    companyAndBranchAssociated: "Associata",
    downloadInailXml: "Scarica XML per INAIL",
    setCompletedState: "Segna come completata",
  },
  toast: {
    error: {
      undefinedYear: "Selezionare un anno di riferimento",
    },
    success: {},
    warning: {},
  },
};
