import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_ctx.expirationClasses)
  }, [
    _createTextVNode(_toDisplayString(_ctx.expirationText), 1)
  ], 2)), [
    [
      _directive_tooltip,
      _ctx.expirationTooltip,
      void 0,
      { top: true }
    ]
  ])
}