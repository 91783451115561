import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { RoleRight } from "./RoleRight";

import { ArrayByKeyField, BooleanField, StringField } from "@/core/fields";

export class Role extends FirestoreDocument {
  public name: string | undefined = undefined;
  public hasKoruAccess = true;
  public allowNewUser = true;
  public roleRights: Record<string, RoleRight> = {};

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Role {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.hasKoruAccess = BooleanField.fromFirestore(data.hasKoruAccess);
    this.allowNewUser = BooleanField.fromFirestore(data.allowNewUser);
    this.roleRights = ArrayByKeyField.fromFirestore<RoleRight>(data.roleRights, (value) => new RoleRight(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.hasKoruAccess = BooleanField.toFirestore(this.hasKoruAccess);
    firestoreData.allowNewUser = BooleanField.toFirestore(this.allowNewUser);
    firestoreData.roleRights = ArrayByKeyField.toFirestore(this.roleRights, (value) => value.toFirestore());

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
  }
}
