import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { Broker } from "@/features/modules/broker/objects/Broker";
import { brokerModel } from "@/features/modules/broker/models/BrokerModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { InvoiceType } from "@/features/modules/invoice/objects/InvoiceType";
import { InvoicingType } from "@/features/modules/invoice/objects/InvoicingType";
import { LinkedBroker } from "@/features/modules/broker/objects/LinkedBroker";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedExamination } from "@/features/modules/examination/objects/LinkedExamination";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LinkedService } from "@/features/modules/service/objects/LinkedService";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { Service } from "@/features/modules/service/objects/Service";
import { TinyLinkedExternalTest } from "@/features/modules/externalTest/objects/TinyLinkedExternalTest";

export const createInvoice = async (
  invoicingType: InvoicingType,
  recipientId: string,
  examinations: Examination[],
  externalTests: ExternalTest[],
  services: Service[],
  startDate: Date,
  endDate: Date,
  isVirtual: boolean,
  t: (entry: string, params?: Record<string, unknown>) => string,
  d: (date: Date, format: string) => string
): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (invoicingType === undefined) throw new Error("createInvoiceMissingParameter");
    if (recipientId === undefined) throw new Error("createInvoiceMissingParameter");
    if (examinations === undefined) throw new Error("createInvoiceMissingParameter");
    if (externalTests === undefined) throw new Error("createInvoiceMissingParameter");
    if (services === undefined) throw new Error("createInvoiceMissingParameter");
    if (startDate === undefined) throw new Error("createInvoiceMissingParameter");
    if (endDate === undefined) throw new Error("createInvoiceMissingParameter");
    if (isVirtual === undefined) throw new Error("createInvoiceMissingParameter");

    // load firm
    const firmId: string = firmModel.getSelectedFirmId();
    const firm: Firm = await firmModel.getSelectedFirm();
    const linkedFirm: LinkedFirm = LinkedFirm.createFromFirm(firm);

    if (firmId === undefined || linkedFirm === undefined || firm === undefined) throw new Error("createInvoiceNoFirm");

    const invoice: Invoice = new Invoice();
    invoice.firm = linkedFirm;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    invoice.date = today;
    invoice.type = InvoiceType.Invoice;
    invoice.isVirtual = isVirtual;

    if (invoicingType === InvoicingType.ByCompany) {
      // load company
      const company: Company = await companyModel.getDocument(recipientId);

      if (company === undefined) throw new Error("createInvoiceInvalidCompany");

      invoice.company = LinkedCompany.createFromCompany(company);

      const legalBranch: Branch | undefined = await branchModel.getLegalBranchByCompany(company.id);

      if (legalBranch !== undefined) {
        invoice.recipientName = company.name;
        invoice.address = legalBranch.address;
        invoice.vatCode = legalBranch.vatCode;
        invoice.fiscalCode = legalBranch.fiscalCode;
        invoice.atecoCode = legalBranch.atecoCode;
        invoice.recipientCode = legalBranch.recipientCode;
        invoice.invoiceEmail = legalBranch.invoiceEmail;
        invoice.certifiedEmail = legalBranch.certifiedEmail;
      }
    } else if (invoicingType === InvoicingType.ByBroker) {
      // load broker
      const broker: Broker = await brokerModel.getDocument(recipientId);

      if (broker == undefined) throw new Error("createInvoiceInvalidBroker");

      invoice.broker = LinkedBroker.createFromBroker(broker);

      invoice.recipientName = broker.name;
      invoice.address = broker.address;
      invoice.vatCode = broker.vatCode;
      invoice.fiscalCode = broker.fiscalCode;
      invoice.recipientCode = broker.recipientCode;
      invoice.invoiceEmail = broker.communicationEmail;
      invoice.certifiedEmail = broker.certifiedEmail;
    }

    // examinations
    for (const examination of examinations) {
      invoice.addLinkedExamination(LinkedExamination.createFromExamination(examination));
    }

    // externalTests
    for (const externalTest of externalTests) {
      invoice.addTinyLinkedExternalTest(TinyLinkedExternalTest.createFromExternalTest(externalTest));
    }

    // services
    for (const service of services) {
      invoice.addLinkedService(LinkedService.createFromService(service));
    }

    await invoiceModel.calculateInvoiceRows(invoice, t, d);

    return invoiceModel.createDocument(invoice);
  } catch (error: unknown) {
    appFaultModel.catchAppError(
      "InvoiceModel.createInvoice",
      { invoicingType, recipientId, examinations, externalTests, services, startDate, endDate, t, d },
      error
    );
    return "ERROR";
  }
};
