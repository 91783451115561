export const it = {
  branch: {
    activeContracts: "Nomine attive",
    addCompanyReferent: "Aggiungi referente aziendale",
    addSafetyReferent: "Aggiungi referente sicurezza",
    address: "Indirizzo",
    atecoCode: "Codice ATECO",
    atecoDescription: "Descrizione ATECO",
    branch: "Sede",
    branchOf: "Sede di {parent}",
    branches: "Sedi",
    branchesOf: "Sedi di {parent}",
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserire un indirizzo e-mail valido",
    communicationEmail: "E-mail comunicazione",
    communicationEmailError: "Inserire un indirizzo e-mail valido",
    companyReferents: "Referenti aziendali",
    contractState: "Nomine",
    contractStates: {
      active: "Nomina Attiva | Nomine Attive",
      alwaysValid: "Valido sempre",
      dueDate: "{ date } - { days } giorni rimasti",
      expired: "Scaduto",
      expiring: "In scadenza",
      notAssigned: "Non assegnata",
      valid: "Valida",
    },
    email: "Indirizzo e-mail",
    emailError: "Inserire un indirizzo e-mail valido",
    filters: {
      noContract: "Sedi senza nomina",
      noContractExcluded: "Senza nomina escluse",
      noContractIncluded: "Senza nomina incluse",
    },
    fiscalCode: "Codice fiscale",
    info: "Informazioni",
    invoiceEmail: "E-mail fatturazione",
    invoiceEmailError: "Inserire un indirizzo e-mail valido",
    legalBranch: "Sede legale",
    legalBranchData: "Dati sede legale",
    legalBranchError: "Selezionare un valore",
    legalRepresentative: "Rappresentante legale",
    mobile: "Cellulare",
    name: "Denominazione",
    nameError: "Inserire una denominazione valida",
    notes: "Note",
    phone: "Telefono",
    position: "Posizione",
    positionError: "Inserire una posizione valida",
    province: "Provincia",
    recipientCode: "Codice destinatario",
    referentName: "Nome",
    referentNameError: "Inserire un nome valido",
    safetyReferents: "Referenti sicurezza",
    town: "Comune",
    type: "Tipo",
    types: {
      legal: "Sede legale",
      operative: "Sede operativa",
    },
    vatCode: "Partita IVA",
    vatCodeError: "Inserire una partita IVA valida e univoca",
    zipCode: "CAP",
  },
  navigation: {
    branch: "Sedi",
  },
  toast: {
    error: {
      noLegalBranch: "Deve esistere almeno una sede legale",
    },
    success: {},
    warning: {},
  },
};
