import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";
import { Prescription } from "./Prescription";
import { PrescriptionDuration } from "./PrescriptionDuration";

import { EnumField, NumberWithDefaultField, StringField } from "@/core/fields";

export class LinkedPrescription extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public duration: PrescriptionDuration = PrescriptionDuration.Permanent;
  public temporaryDuration: string | undefined = undefined;
  public text: string | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromPrescription(prescription: Prescription): LinkedPrescription {
    const linkedPrescription: LinkedPrescription = new LinkedPrescription();
    linkedPrescription.id = prescription.id;
    linkedPrescription.name = prescription.name;
    linkedPrescription.duration = prescription.duration;

    return linkedPrescription;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedPrescription {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.duration = EnumField.fromFirestore<PrescriptionDuration>(data.duration, Object.values(PrescriptionDuration), PrescriptionDuration.Permanent);
    this.temporaryDuration = StringField.fromFirestore(data.temporaryDuration);
    this.text = StringField.fromFirestore(data.text);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.duration = EnumField.toFirestore<PrescriptionDuration>(this.duration, PrescriptionDuration.Permanent);
    firestoreData.temporaryDuration = StringField.toFirestore(this.temporaryDuration);
    firestoreData.text = StringField.toFirestore(this.text);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedPrescription {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.duration = EnumField.fromFirestore<PrescriptionDuration>(data.duration, Object.values(PrescriptionDuration), PrescriptionDuration.Permanent);
    this.temporaryDuration = StringField.fromOfflineCache(data.temporaryDuration);
    this.text = StringField.fromOfflineCache(data.text);
    this.order = NumberWithDefaultField.fromOfflineCache(data.order, 999);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.duration = EnumField.toOfflineCache<PrescriptionDuration>(this.duration, PrescriptionDuration.Permanent);
    firestoreData.temporaryDuration = StringField.toOfflineCache(this.temporaryDuration);
    firestoreData.text = StringField.toOfflineCache(this.text);
    firestoreData.order = NumberWithDefaultField.toOfflineCache(this.order, 999);

    return firestoreData;
  }
}
