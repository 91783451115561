import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Limitation } from "../objects/Limitation";
import { limitationModel } from "./LimitationModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class LimitationOfflineModel extends OfflineModuleModel<Limitation> {
  public constructor() {
    super(() => new Limitation(), "limitations", "limitation.limitations", undefined);
  }

  public async downloadCache(): Promise<Limitation[]> {
    try {
      return limitationModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("LimitationOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const limitationOfflineModel: LimitationOfflineModel = new LimitationOfflineModel();
