export enum JudgmentState {
  Fit = "fit",
  FitWithLenses = "fitWithLenses",
  FitWithLimitations = "fitWithLimitations",
  FitWithPrescriptions = "fitWithPrescriptions",
  FitWithPrescriptionsAndLimitations = "fitWithPrescriptionsAndLimitations",
  Unfit = "unfit",
  NotExpressed = "notExpressed",
  NotEvaluated = "notEvaluated",
}
