import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { Survey } from "../objects/Survey";
import { surveyModel } from "./SurveyModel";

export class SurveyOfflineModel extends OfflineModuleModel<Survey> {
  public constructor() {
    super(() => new Survey(), "surveys", "survey.surveys", undefined);
  }

  public async downloadCache(): Promise<Survey[]> {
    try {
      return surveyModel.getDocuments();
    } catch (error: unknown) {
      appFaultModel.catchAppError("SurveyOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const surveyOfflineModel: SurveyOfflineModel = new SurveyOfflineModel();
