export const fr = {
  role: {
    allowNewUser: "Autoriser les nouveaux utilisateurs",
    allowNewUserError: "Sélectionnez une valeur",
    hasKoruAccess: "Autoriser l'accès à KORU",
    hasKoruAccessError: "Sélectionnez une valeur",
    module: "Module",
    name: "Nom",
    nameError: "Veuillez entrer un nom valide",
    role: "Rôle",
    rights: {
      create: "Créer",
      delete: "Supprimer",
      fieldError: "Sélectionnez une valeur",
      modes: {
        all: "Tous",
        custom: "Personnalisé",
        owned: "Propres",
        none: "Aucun",
      },
      read: "Lire",
      toggle: "Act",
      update: "Modi",
      viewUI: "Interface",
    },
    roles: "Rôles",
    types: {
      core: "Core",
      features: "Features",
    },
  },
  navigation: {
    role: "Rôles",
  },
};
