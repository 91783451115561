export const it = {
  employeeDuty: {
    company: "Azienda",
    companyError: "Seleziona un'azienda",
    companyDuties: "Mansioni aziendali applicate",
    employeeDuties: "Mansioni",
    employeeDutiesOf: "Mansioni di {parent}",
    employeeDuty: "Mansione",
    employeeDutyOf: "Mansione di {parent}",
    employeeDutyPaperTitle: "Protocollo di sorveglianza sanitaria",
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    from: "Inizio validità",
    fromError: "Il periodo si sovrappone ad un'altra mansione",
    importDuty: "Importa mansione",
    name: "Nome",
    nameError: "Inserire un nome valido",
    notes: "Note",
    risks: "Rischi",
    surveys: "Questionari",
    testTypes: "Accertamenti",
    to: "Fine validità",
    toError: "Il periodo si sovrappone ad un'altra mansione",
    unlimited: "Illimitata",
  },
  toast: {
    error: {
      setPersonalDuty: "Impossibile impostare la mansione personale",
    },
    success: {},
    warning: {},
  },
};
