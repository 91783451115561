import { StatModule } from "@/core/modules/stat/objects/StatModule";

import EmployeeLastExaminationView from "./views/EmployeeLastExaminationView.vue";

export const employeeLastExamination: StatModule = new StatModule(
  "examination",
  "EmployeeLastExamination",
  "examination",
  EmployeeLastExaminationView
);
