export const it = {
  vaccineType: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    vaccineType: "Tipo di vaccino",
    vaccineTypes: "Tipi di vaccino",
  },
  navigation: {
    vaccineType: "Tipi di vaccino",
  },
};
