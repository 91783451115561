import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export function getFolderPath(
  firmId: string | undefined,
  companyId: string | undefined,
  employeeId: string | undefined,
  examinationId: string | undefined
): string {
  try {
    if (firmId === undefined) throw new Error("firmId is undefined");
    if (companyId === undefined) throw new Error("companyId is undefined");
    if (employeeId === undefined) throw new Error("employeeId is undefined");
    if (examinationId === undefined) throw new Error("examinationId is undefined");
    return `examinations/${firmId}/${companyId}/${employeeId}/${examinationId}/`;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationHelpers.getFolderPath", { firmId, companyId, employeeId, examinationId }, error);
    throw error;
  }
}
