import { firmModel } from "@/features/modules/firm/models/FirmModel";

import { StringField } from "@/core/fields";

export class NextExaminationDatesRecord {
  public record: Record<string, Record<string, string>> = {};

  public fromFirestore(value: unknown): void {
    this.record = {};
    if (value === null) return;

    for (const firmId of Object.keys(value as Record<string, unknown>)) {
      this.record[firmId] = {};

      const innerValue: Record<string, string> = (value as Record<string, unknown>)[firmId] as Record<string, string>;
      for (const companyId of Object.keys(innerValue)) {
        this.record[firmId][companyId] = StringField.fromFirestore(innerValue[companyId]) as string;
      }
    }
  }

  public toFirestore(): Record<string, unknown> {
    const nextExaminationsDate: Record<string, Record<string, string>> = {};
    for (const firmId of Object.keys(this.record)) {
      nextExaminationsDate[firmId] = {};
      for (const companyId of Object.keys(this.record[firmId])) {
        nextExaminationsDate[firmId][companyId] = StringField.toFirestore(this.record[firmId][companyId]) as string;
      }
    }

    return nextExaminationsDate;
  }

  public fromOfflineCache(value: unknown): void {
    this.record = {};
    if (value === null) return;

    for (const firmId of Object.keys(value as Record<string, unknown>)) {
      this.record[firmId] = {};

      const innerValue: Record<string, string> = (value as Record<string, unknown>)[firmId] as Record<string, string>;
      for (const companyId of Object.keys(innerValue)) {
        this.record[firmId][companyId] = StringField.fromOfflineCache(innerValue[companyId]) as string;
      }
    }
  }

  public toOfflineCache(): Record<string, unknown> {
    const nextExaminationsDate: Record<string, Record<string, string>> = {};
    for (const firmId of Object.keys(this.record)) {
      nextExaminationsDate[firmId] = {};
      for (const companyId of Object.keys(this.record[firmId])) {
        nextExaminationsDate[firmId][companyId] = StringField.toOfflineCache(this.record[firmId][companyId]) as string;
      }
    }

    return nextExaminationsDate;
  }

  public getEarliestNextExaminationDateByFirm(): string | undefined {
    const firmId: string = firmModel.getSelectedFirmId();

    if (this.record[firmId] === undefined) return undefined;
    if (this.record[firmId]["all"] === undefined) return undefined;
    return this.record[firmId]["all"];
  }

  public getNextExaminationDateByCompanyAndFirm(companyId: string): string | undefined {
    const firmId: string = firmModel.getSelectedFirmId();

    if (this.record[firmId] === undefined) return undefined;
    if (this.record[firmId][companyId] === undefined) return undefined;
    return this.record[firmId][companyId];
  }

  public getRecord(): Record<string, Record<string, string>> {
    return this.record;
  }
}
