import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedContract } from "@/features/modules/contract/objects/LinkedContract";
import { Referent } from "@/features/modules/referent/objects/Referent";

import { AddressField, ArrayField, BooleanField, ObjectField, StringField } from "@/core/fields";
import { ActiveContractsRecord } from "@/features/fields";

export class Branch extends FirestoreOfflineDocument {
  public company: LinkedCompany | undefined = undefined;
  public name: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public phone: string | undefined = undefined;
  public notes: string | undefined = undefined;
  public companyReferents: Referent[] = [];
  public safetyReferents: Referent[] = [];
  public legalBranch = false;
  public legalRepresentative: string | undefined = undefined;
  public vatCode: string | undefined = undefined;
  public fiscalCode: string | undefined = undefined;
  public atecoCode: string | undefined = undefined;
  public recipientCode: string | undefined = undefined;
  public communicationEmail: string | undefined = undefined;
  public invoiceEmail: string | undefined = undefined;
  public certifiedEmail: string | undefined = undefined;
  public activeContracts: ActiveContractsRecord = new ActiveContractsRecord();
  public inailId: string | undefined = undefined;
  public oldName: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Branch {
    super.fromFirestore(data, id, firestoreRef);

    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.name = StringField.fromFirestore(data.name);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.notes = StringField.fromFirestore(data.notes);
    this.companyReferents = ArrayField.fromFirestore<Referent>(data.companyReferents, (value) => new Referent(value));
    this.safetyReferents = ArrayField.fromFirestore<Referent>(data.safetyReferents, (value) => new Referent(value));
    this.legalBranch = BooleanField.fromFirestore(data.legalBranch);
    this.legalRepresentative = StringField.fromFirestore(data.legalRepresentative);
    this.vatCode = StringField.fromFirestore(data.vatCode);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.atecoCode = StringField.fromFirestore(data.atecoCode);
    this.recipientCode = StringField.fromFirestore(data.recipientCode);
    this.communicationEmail = StringField.fromFirestore(data.communicationEmail);
    this.invoiceEmail = StringField.fromFirestore(data.invoiceEmail);
    this.certifiedEmail = StringField.fromFirestore(data.certifiedEmail);
    this.activeContracts.fromFirestore(data.activeContracts);
    this.inailId = StringField.fromFirestore(data.inailId);
    this.oldName = StringField.fromFirestore(data.oldName);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.companyReferents = ArrayField.toFirestore<Referent>(this.companyReferents, (value) => value.toFirestore());
    firestoreData.safetyReferents = ArrayField.toFirestore<Referent>(this.safetyReferents, (value) => value.toFirestore());
    firestoreData.legalBranch = BooleanField.toFirestore(this.legalBranch);
    firestoreData.legalRepresentative = StringField.toFirestore(this.legalRepresentative);
    firestoreData.vatCode = StringField.toFirestore(this.vatCode);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.atecoCode = StringField.toFirestore(this.atecoCode);
    firestoreData.recipientCode = StringField.toFirestore(this.recipientCode);
    firestoreData.communicationEmail = StringField.toFirestore(this.communicationEmail);
    firestoreData.invoiceEmail = StringField.toFirestore(this.invoiceEmail);
    firestoreData.certifiedEmail = StringField.toFirestore(this.certifiedEmail);
    firestoreData.activeContracts = this.activeContracts.toFirestore();
    firestoreData.inailId = StringField.toFirestore(this.inailId);
    firestoreData.oldName = StringField.toFirestore(this.oldName);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Branch {
    super.fromOfflineCache(data);

    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.name = StringField.fromOfflineCache(data.name);
    this.address.fromOfflineCache(data.address);
    this.phone = StringField.fromOfflineCache(data.phone);
    this.notes = StringField.fromOfflineCache(data.notes);
    this.companyReferents = ArrayField.fromOfflineCache<Referent>(data.companyReferents, (value) => {
      const referent: Referent = new Referent();
      return referent.fromOfflineCache(value);
    });
    this.safetyReferents = ArrayField.fromOfflineCache<Referent>(data.safetyReferents, (value) => {
      const referent: Referent = new Referent();
      return referent.fromOfflineCache(value);
    });
    this.legalBranch = BooleanField.fromOfflineCache(data.legalBranch);
    this.legalRepresentative = StringField.fromOfflineCache(data.legalRepresentative);
    this.vatCode = StringField.fromOfflineCache(data.vatCode);
    this.fiscalCode = StringField.fromOfflineCache(data.fiscalCode);
    this.atecoCode = StringField.fromOfflineCache(data.atecoCode);
    this.recipientCode = StringField.fromOfflineCache(data.recipientCode);
    this.communicationEmail = StringField.fromOfflineCache(data.communicationEmail);
    this.invoiceEmail = StringField.fromOfflineCache(data.invoiceEmail);
    this.certifiedEmail = StringField.fromOfflineCache(data.certifiedEmail);
    this.activeContracts.fromOfflineCache(data.activeContracts);
    this.inailId = StringField.fromOfflineCache(data.inailId);
    this.oldName = StringField.fromOfflineCache(data.oldName);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.company = ObjectField.toOfflineCache<LinkedCompany>(this.company, (value) => value.toOfflineCache());
    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.address = this.address.toOfflineCache();
    firestoreData.phone = StringField.toOfflineCache(this.phone);
    firestoreData.notes = StringField.toOfflineCache(this.notes);
    firestoreData.companyReferents = ArrayField.toOfflineCache<Referent>(this.companyReferents, (value) => value.toOfflineCache());
    firestoreData.safetyReferents = ArrayField.toOfflineCache<Referent>(this.safetyReferents, (value) => value.toOfflineCache());
    firestoreData.legalBranch = BooleanField.toOfflineCache(this.legalBranch);
    firestoreData.legalRepresentative = StringField.toOfflineCache(this.legalRepresentative);
    firestoreData.vatCode = StringField.toOfflineCache(this.vatCode);
    firestoreData.fiscalCode = StringField.toOfflineCache(this.fiscalCode);
    firestoreData.atecoCode = StringField.toOfflineCache(this.atecoCode);
    firestoreData.recipientCode = StringField.toOfflineCache(this.recipientCode);
    firestoreData.communicationEmail = StringField.toOfflineCache(this.communicationEmail);
    firestoreData.invoiceEmail = StringField.toOfflineCache(this.invoiceEmail);
    firestoreData.certifiedEmail = StringField.toOfflineCache(this.certifiedEmail);
    firestoreData.activeContracts = this.activeContracts.toOfflineCache();
    firestoreData.inailId = StringField.toOfflineCache(this.inailId);
    firestoreData.oldName = StringField.toOfflineCache(this.oldName);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
    if (this.vatCode !== undefined) this.searchKeys.push(this.vatCode.toLowerCase());
    if (this.fiscalCode !== undefined) this.searchKeys.push(this.fiscalCode.toLowerCase());
  }

  public getActiveContractsByFirm(): LinkedContract[] {
    const firmId: string = firmModel.getSelectedFirmId();
    return this.activeContracts.getActiveContractsByFirm(firmId);
  }
}
