import { addDays, compareAsc, subDays } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { setting } from "@/core/modules/setting/Setting";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getExaminationsForDashboard = async (doctorId?: string): Promise<Examination[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const minDate: Date = subDays(new Date(), setting.getSetting<number>("lastExaminationsDaysNotice"));
    minDate.setHours(0, 0, 0, 0);
    const maxDate: Date = addDays(new Date(), 1);
    maxDate.setHours(0, 0, 0, 0);

    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (examinationModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      return documents.filter(
        (examination) =>
          examination.firm?.id === firmId && compareAsc(examination.date, minDate) >= 0 && examination.state === ExaminationState.Completed
      );
    }

    let snapshotQuery: Query<Examination>;
    if (doctorId === undefined) {
      snapshotQuery = query(
        examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
        where("firm.id", "==", firmId),
        where("date", ">=", Timestamp.fromDate(minDate)),
        where("date", "<=", Timestamp.fromDate(maxDate))
      );
    } else {
      snapshotQuery = query(
        examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
        where("firm.id", "==", firmId),
        where("doctor.id", "==", doctorId),
        where("date", ">=", Timestamp.fromDate(minDate)),
        where("date", "<=", Timestamp.fromDate(maxDate))
      );
    }

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data()).filter((examination) => examination.state === ExaminationState.Completed);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsForDashboard", {}, error);
    return [];
  }
};
