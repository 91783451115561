export class NotificationModule {
  public formatMessage: (
    message: string,
    parameters: Record<string, unknown>,
    d: (date: Date, format: string) => string,
    n: (number: number, format: string) => string,
    t: (text: string, params?: Record<string, unknown>) => string
  ) => string;
  public groupId: string;
  public groupLabel: string;
  public icon: string;
  public requiredModule: string | undefined;
  public requiredRight: string | undefined;
  public url: string | undefined;

  public constructor(
    formatMessage: (
      message: string,
      parameters: Record<string, unknown>,
      d: (date: Date, format: string) => string,
      n: (number: number, format: string) => string,
      t: (text: string, params?: Record<string, unknown>) => string
    ) => string,
    groupId: string,
    groupLabel: string,
    icon: string,
    requiredModule?: string,
    requiredRight?: string,
    url?: string
  ) {
    this.formatMessage = formatMessage;
    this.groupId = groupId;
    this.groupLabel = groupLabel;
    this.icon = icon;
    this.requiredModule = requiredModule;
    this.requiredRight = requiredRight;
    this.url = url;
  }
}
