import { RouteRecordRaw } from "vue-router";

export const utilityRoutes: RouteRecordRaw[] = [
  {
    path: "/utilities",
    name: "UtilityView",
    component: () => import("../views/UtilityView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "utility",
      requiredRight: "viewUI",
    },
  },
];
