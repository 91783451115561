import { DocType } from "../objects/DocType";
import { docTypeOfflineModel } from "./DocTypeOfflineModel";
import { DocTypeSource } from "../objects/DocTypeSource";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getAvailableDocTypesBySource, getVisibleDocTypesBySource } from "./methods";

export class DocTypeModel extends FirestoreModel<DocType> {
  public constructor() {
    super(() => new DocType(), "docTypes", LockPolicy.DiscardUnsyncedChanges, "docType");
    this.beforeDeleteFunction = "featuresDocTypeBeforeDelete";
    this.offlineModuleModel = docTypeOfflineModel;
  }

  public async getDocuments(): Promise<DocType[]> {
    const user: User = store.getters[storeTypes.getters.getUser];
    if (user.role?.name === "Medico") {
      return this.getVisibleDocTypesBySource(DocTypeSource.Doctor);
    }
    return super.getDocuments();
  }

  public async getDocument(docTypeId: string): Promise<DocType> {
    return super.getDocument(docTypeId);
  }

  public async createDocument(docType: DocType): Promise<string> {
    return super.createDocument(docType);
  }

  public async updateDocument(docType: DocType): Promise<void> {
    return super.updateDocument(docType);
  }

  public async deleteDocument(docType: DocType): Promise<boolean> {
    return super.deleteDocument(docType);
  }

  public async getAvailableDocTypesBySource(source: DocTypeSource): Promise<DocType[]> {
    return getAvailableDocTypesBySource(source);
  }

  public async getVisibleDocTypesBySource(source: DocTypeSource): Promise<DocType[]> {
    return getVisibleDocTypesBySource(source);
  }
}

export const docTypeModel: DocTypeModel = new DocTypeModel();
