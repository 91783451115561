import { LinkedExamination } from "@/features/modules/examination/objects/LinkedExamination";

import { ObjectField } from "@/core/fields";

export class LastValidExaminationsRecord {
  public record: Record<string, Record<string, LinkedExamination>> = {};

  public fromFirestore(value: unknown): void {
    this.record = {};
    if (value === null) return;

    for (const firmId of Object.keys(value as Record<string, unknown>)) {
      const innerValue: Record<string, Date> = (value as Record<string, unknown>)[firmId] as Record<string, Date>;
      for (const companyId of Object.keys(innerValue)) {
        this.record[firmId][companyId] = ObjectField.fromFirestore<LinkedExamination>(
          innerValue[companyId],
          (objectValue) => new LinkedExamination(objectValue)
        ) as LinkedExamination;
      }
    }
  }

  public toFirestore(): Record<string, unknown> {
    const lastValidExaminations: Record<string, Record<string, Record<string, unknown>>> = {};
    for (const firmId of Object.keys(this.record)) {
      for (const companyId of Object.keys(this.record[firmId])) {
        lastValidExaminations[firmId][companyId] = ObjectField.toFirestore<LinkedExamination>(this.record[firmId][companyId], (objectValue) =>
          objectValue.toFirestore()
        ) as Record<string, unknown>;
      }
    }

    return lastValidExaminations;
  }

  public getLastValidExaminationByCompanyAndFirm(companyId: string, firmId: string): LinkedExamination | undefined {
    if (this.record[firmId] === undefined) return undefined;
    if (this.record[firmId][companyId] === undefined) return undefined;
    return this.record[firmId][companyId];
  }

  public getRecord(): Record<string, Record<string, LinkedExamination>> {
    return this.record;
  }
}
