export const it = {
  docFolder: {
    category: "Categoria",
    docFolder: "Fascicolo",
    docFolders: "Fascicoli",
    docType: "Documento",
    item: {
      addDocType: "Aggiungi",
      docFolderItem: "Elemento fascicolo",
      docType: "Tipo documento",
      name: "Nome",
      nameError: "Inserire un nome valido",
      newDocFolderItem: "Nuova categoria",
    },
    source: "Elemento",
    sourceError: "Scegliere un elemento valido",
  },
  navigation: {
    docFolder: "Fascicoli",
  },
  buttons: {
    addDocTypes: "Aggiungi tipi documento",
    moveDown: "Sposta giù",
    moveUp: "Sposta su",
  },
  toast: {
    error: {
      duplicatedDocFolderSource: "Esiste già un fascicolo per questo elemento",
    },
    success: {},
    warning: {
      parentDocFolderItemNotValid: "La categoria di inserimento non è valida",
    },
  },
};
