import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";

import { ObjectField, StringField } from "@/core/fields";

export class Attachment {
  public id: string = DataHelpers.uniqueId();
  public name: string | undefined = undefined;
  public file: StorageFile | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): Attachment {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromFirestore(data.name);
    this.file = ObjectField.fromFirestore<StorageFile>(data.file, (value) => new StorageFile(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.file = ObjectField.toFirestore<StorageFile>(this.file, (value) => value.toFirestore());

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Attachment {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromOfflineCache(data.name);
    this.file = ObjectField.fromOfflineCache<StorageFile>(data.file, (value) => {
      const storageFile: StorageFile = new StorageFile();
      return storageFile.fromOfflineCache(value);
    });

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.file = ObjectField.toOfflineCache<StorageFile>(this.file, (value) => value.toOfflineCache());

    return firestoreData;
  }
}
