import { getDownloadURL, ref } from "firebase/storage";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineUpload } from "@/core/modules/offline/objects/OfflineUpload";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getDownloadLink = async (path: string, name: string): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      // offline
      const user: User = store.getters[storeTypes.getters.getUser];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const offlineUploads: OfflineUpload[] = (await indexedDbModel.getDocuments("offlineUploads")).map(
        (rawOfflineUpload) => new OfflineUpload(rawOfflineUpload)
      );

      const offlineUpload: OfflineUpload | undefined = offlineUploads.find(
        (offlineUpload: OfflineUpload) => offlineUpload.path === path && offlineUpload.uniqueName === name
      );
      if (offlineUpload === undefined) throw new Error("offline upload not found");

      const file: File = await StorageHelpers.base64StringToFile(offlineUpload.content as string, offlineUpload.uniqueName as string);
      return URL.createObjectURL(file);
    } else {
      // online
      const url: string = await getDownloadURL(ref(firebase.storage, `${path}/${name}`));
      return url;
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageModel.getDownloadLink", { path, name }, error);
    return "ERROR";
  }
};
