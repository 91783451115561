import { compareAsc } from "date-fns";
import { getDocs, limit, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getLastCompletedExaminationByEmployeeAndCompanyAndFirm = async (
  employeeId: string,
  companyId: string
): Promise<Examination | undefined> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (examinationModel.offlineModuleModel === undefined) return undefined;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      const examinations: Examination[] = documents.filter(
        (examination) =>
          examination.firm?.id === firmId &&
          examination.invoice === undefined &&
          examination.company?.id === companyId &&
          examination.state === ExaminationState.Completed
      );
      if (examinations.length > 0) {
        examinations.sort((a, b) => compareAsc(a.date, b.date));
        return examinations[0];
      }
      return undefined;
    }

    if (companyId === undefined) throw new Error("companyId is undefined");

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(companyId) === false)
        throw new Error(`User doesn't have rights to read company #${companyId}`);
    }

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("employee.id", "==", employeeId),
      where("company.id", "==", companyId),
      limit(1)
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    const examinations: Examination[] = snapshot.docs
      .map((doc) => doc.data())
      .filter((examination) => examination.state === ExaminationState.Completed);
    examinations.sort((a, b) => compareAsc(a.date, b.date));
    if (examinations.length > 0) return examinations[0];
    return undefined;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getLastCompletedExaminationByEmployeeAndCompanyAndFirm", { employeeId, companyId }, error);
    return undefined;
  }
};
