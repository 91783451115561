import { FirestoreDocument } from "./FirestoreDocument";

import { DateTimeStrictField, StringArrayField, StringStrictField } from "@/core/fields";

export class FirestoreOfflineDocument extends FirestoreDocument {
  public fromOfflineCache(data: Record<string, unknown>): FirestoreOfflineDocument {
    this.id = StringStrictField.fromOfflineCache(data.id, "new");
    this.firestoreRef = undefined;

    this.searchKeys = StringArrayField.fromOfflineCache(data.searchKeys);
    this.createdAt = DateTimeStrictField.fromOfflineCache(data.createdAt, new Date());
    this.createdBy = StringStrictField.fromOfflineCache(data.createdBy, "");
    this.updatedAt = DateTimeStrictField.fromOfflineCache(data.updatedAt, new Date());
    this.updatedBy = StringStrictField.fromOfflineCache(data.updatedBy, "");

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const data: Record<string, unknown> = {
      id: this.id,
      searchKeys: StringArrayField.toOfflineCache(this.searchKeys),
      createdAt: DateTimeStrictField.toOfflineCache(this.createdAt),
      createdBy: StringStrictField.toOfflineCache(this.createdBy),
      updatedAt: DateTimeStrictField.toOfflineCache(this.updatedAt),
      updatedBy: StringStrictField.toOfflineCache(this.updatedBy),
    };

    return data;
  }
}
