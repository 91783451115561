import { LocaleModule } from "@/core/modules/locale/objects/LocaleModule";

import { deCoreLabels } from "./de";
import { enCoreLabels } from "./en";
import { frCoreLabels } from "./fr";
import { itCoreLabels } from "./it";

export const coreLabels: LocaleModule = {
  name: "core",
  messages: { de: deCoreLabels, en: enCoreLabels, fr: frCoreLabels, it: itCoreLabels },
};
