import { compareAsc, differenceInYears } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { externalTestModel } from "@/features/modules/externalTest/models/ExternalTestModel";
import { InvoiceCandidate } from "@/features/modules/invoice/objects/InvoiceCandidate";
import { InvoicingType } from "@/features/modules/invoice/objects/InvoicingType";
import { Service } from "@/features/modules/service/objects/Service";
import { serviceModel } from "@/features/modules/service/models/ServiceModel";

export const getInvoiceCandidates = async (startDate: Date, endDate: Date, invoicingType: InvoicingType): Promise<InvoiceCandidate[]> => {
  try {
    if (startDate === undefined) throw new Error("createInvoiceMissingData");
    if (endDate === undefined) throw new Error("createInvoiceMissingData");
    if (invoicingType === undefined) throw new Error("createInvoiceMissingData");

    if (compareAsc(startDate, endDate) > 0) throw new Error("createInvoiceStartDateAfterEndDate");
    if (differenceInYears(endDate, startDate) > 0) throw new Error("createInvoicePeriodTooLong");

    const invoiceCandidates: InvoiceCandidate[] = [];
    if (invoicingType === InvoicingType.ByCompany) {
      await processExaminationsByCompany(invoiceCandidates, startDate, endDate);
      await processExternalTestsByCompany(invoiceCandidates, startDate, endDate);
      await processServicesByCompany(invoiceCandidates, startDate, endDate);
    } else if (invoicingType === InvoicingType.ByBroker) {
      const companies: Company[] = await companyModel.getDocuments();
      await processExaminationsByBroker(invoiceCandidates, startDate, endDate, companies);
      await processExternalTestsByBroker(invoiceCandidates, startDate, endDate, companies);
      await processServicesByBroker(invoiceCandidates, startDate, endDate, companies);
    }

    return invoiceCandidates;
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getInvoiceCandidates", { startDate, endDate, invoicingType }, error);
    return [];
  }
};

async function processExaminationsByCompany(invoiceCandidates: InvoiceCandidate[], startDate: Date, endDate: Date): Promise<void> {
  const examinationsToInvoice: Examination[] = await examinationModel.getExaminationsToInvoiceByPeriodAndFirm(startDate, endDate);

  for (const examination of examinationsToInvoice) {
    const invoiceCandidate: InvoiceCandidate | undefined = invoiceCandidates.find(
      (iC) => iC.recipientType === "company" && iC.recipientId === examination.company?.id
    );

    if (invoiceCandidate !== undefined) {
      invoiceCandidate.examinationsCount++;
      continue;
    }

    const newInvoiceCandidate: InvoiceCandidate = new InvoiceCandidate("company", examination.company?.id, examination.company?.name);
    newInvoiceCandidate.examinationsCount = 1;
    invoiceCandidates.push(newInvoiceCandidate);
  }
}

async function processExternalTestsByCompany(invoiceCandidates: InvoiceCandidate[], startDate: Date, endDate: Date): Promise<void> {
  const externalTestsToInvoice: ExternalTest[] = await externalTestModel.getExternalTestsToInvoiceByPeriodAndFirm(startDate, endDate);

  for (const externalTest of externalTestsToInvoice) {
    const invoiceCandidate: InvoiceCandidate | undefined = invoiceCandidates.find(
      (iC) => iC.recipientType === "company" && iC.recipientId === externalTest.company?.id
    );

    if (invoiceCandidate !== undefined) {
      invoiceCandidate.externalTestsCount++;
      continue;
    }

    const newInvoiceCandidate: InvoiceCandidate = new InvoiceCandidate("company", externalTest.company?.id, externalTest.company?.name);
    newInvoiceCandidate.externalTestsCount = 1;
    invoiceCandidates.push(newInvoiceCandidate);
  }
}

async function processServicesByCompany(invoiceCandidates: InvoiceCandidate[], startDate: Date, endDate: Date): Promise<void> {
  const servicesToInvoice: Service[] = await serviceModel.getServicesToInvoiceByPeriodAndFirm(startDate, endDate);

  for (const service of servicesToInvoice) {
    const invoiceCandidate: InvoiceCandidate | undefined = invoiceCandidates.find(
      (iC) => iC.recipientType === "company" && iC.recipientId === service.company?.id
    );

    if (invoiceCandidate !== undefined) {
      invoiceCandidate.servicesCount++;
      continue;
    }

    const newInvoiceCandidate: InvoiceCandidate = new InvoiceCandidate("company", service.company?.id, service.company?.name);
    newInvoiceCandidate.servicesCount = 1;
    invoiceCandidates.push(newInvoiceCandidate);
  }
}

async function processExaminationsByBroker(
  invoiceCandidates: InvoiceCandidate[],
  startDate: Date,
  endDate: Date,
  companies: Company[]
): Promise<void> {
  const examinationsToInvoice: Examination[] = await examinationModel.getExaminationsToInvoiceByPeriodAndFirm(startDate, endDate);

  for (const examination of examinationsToInvoice) {
    const company: Company | undefined = companies.find((c) => c.id === examination.company?.id);
    if (company === undefined) continue;
    if (company.broker === undefined) continue;

    const invoiceCandidate: InvoiceCandidate | undefined = invoiceCandidates.find(
      (iC) => iC.recipientType === "broker" && iC.recipientId === company.broker?.id
    );

    if (invoiceCandidate !== undefined) {
      invoiceCandidate.examinationsCount++;
      continue;
    }

    const newInvoiceCandidate: InvoiceCandidate = new InvoiceCandidate("broker", company.broker?.id, company.broker?.name);
    newInvoiceCandidate.examinationsCount = 1;
    invoiceCandidates.push(newInvoiceCandidate);
  }
}

async function processExternalTestsByBroker(
  invoiceCandidates: InvoiceCandidate[],
  startDate: Date,
  endDate: Date,
  companies: Company[]
): Promise<void> {
  const externalTestsToInvoice: ExternalTest[] = await externalTestModel.getExternalTestsToInvoiceByPeriodAndFirm(startDate, endDate);

  for (const externalTest of externalTestsToInvoice) {
    const company: Company | undefined = companies.find((c) => c.id === externalTest.company?.id);
    if (company === undefined) continue;
    if (company.broker === undefined) continue;

    const invoiceCandidate: InvoiceCandidate | undefined = invoiceCandidates.find(
      (iC) => iC.recipientType === "broker" && iC.recipientId === company.broker?.id
    );

    if (invoiceCandidate !== undefined) {
      invoiceCandidate.externalTestsCount++;
      continue;
    }

    const newInvoiceCandidate: InvoiceCandidate = new InvoiceCandidate("broker", company.broker?.id, company.broker?.name);
    newInvoiceCandidate.externalTestsCount = 1;
    invoiceCandidates.push(newInvoiceCandidate);
  }
}

async function processServicesByBroker(invoiceCandidates: InvoiceCandidate[], startDate: Date, endDate: Date, companies: Company[]): Promise<void> {
  const servicesToInvoice: Service[] = await serviceModel.getServicesToInvoiceByPeriodAndFirm(startDate, endDate);

  for (const service of servicesToInvoice) {
    const company: Company | undefined = companies.find((c) => c.id === service.company?.id);
    if (company === undefined) continue;
    if (company.broker === undefined) continue;

    const invoiceCandidate: InvoiceCandidate | undefined = invoiceCandidates.find(
      (iC) => iC.recipientType === "broker" && iC.recipientId === company.broker?.id
    );

    if (invoiceCandidate !== undefined) {
      invoiceCandidate.servicesCount++;
      continue;
    }

    const newInvoiceCandidate: InvoiceCandidate = new InvoiceCandidate("broker", company.broker?.id, company.broker?.name);
    newInvoiceCandidate.servicesCount = 1;
    invoiceCandidates.push(newInvoiceCandidate);
  }
}
