import { RouteRecordRaw } from "vue-router";

export const branchLegalRecordRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:companyId/branches/:branchId/legal-records/:branchLegalRecordId/edit",
    name: "BranchLegalRecordEdit",
    component: () => import("../views/BranchLegalRecordEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "branchLegalRecord",
      requiredRight: "viewUI",
    },
  },
];
