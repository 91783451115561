import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { InvoiceState } from "@/features/modules/invoice/objects/InvoiceState";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const markAs = async (invoice: Invoice, state: InvoiceState): Promise<void> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    invoice.state = state;

    return invoiceModel.updateDocument(invoice);
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.markAs", { invoice, state }, error);
  }
};
