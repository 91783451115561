import { Timestamp } from "firebase/firestore";

import { addMonths, compareAsc, format, subDays } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { ExaminationTest } from "@/features/modules/examination/objects/ExaminationTest";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { companyDutyModel } from "@/features/modules/companyDuty/models/CompanyDutyModel";
import { LinkedCompanyDuty } from "@/features/modules/companyDuty/objects/LinkedCompanyDuty";
import { LinkedExternalTest } from "@/features/modules/externalTest/objects/LinkedExternalTest";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export const changeDuty = async (examination: Examination, newDutyName: string, selectedCompanyDutiesIds: string[]): Promise<void> => {
  try {
    if (examination.company === undefined) throw new Error("companyUndefined");
    if (examination.employee === undefined) throw new Error("employeeUndefined");

    const employeeDutiesByCompany: EmployeeDuty[] = await employeeDutyModel.getDocuments([], examination.employee.id);
    const previousEmployeeDuty: EmployeeDuty | undefined = employeeDutiesByCompany.find((employeeDuty: EmployeeDuty) => {
      return (
        employeeDuty.company !== undefined &&
        employeeDuty.company.id === examination.company?.id &&
        employeeDuty.fromSort < format(examination.date, "yyyy-MM-dd") &&
        employeeDuty.toSort >= format(examination.date, "yyyy-MM-dd")
      );
    });
    if (previousEmployeeDuty === undefined) throw new Error("previousEmployeeDutyUndefined");

    // update previous duty
    const previousDutyTo: Date = subDays(new Date(examination.date.getTime()), 1);
    previousDutyTo.setHours(0, 0, 0, 0);
    batch.update(employeeDutyModel.getDocumentReference(previousEmployeeDuty.id, examination.employee.id), {
      to: Timestamp.fromDate(previousDutyTo),
      toSort: format(previousDutyTo, "yyyy-MM-dd"),
    });

    // create new duty
    const companyDuties: CompanyDuty[] = await companyDutyModel.getActiveCompanyDuties(examination.company.id);
    const newEmployeeDuty: EmployeeDuty = new EmployeeDuty();
    newEmployeeDuty.name = newDutyName;
    newEmployeeDuty.company = examination.company;
    for (const companyDutyId of selectedCompanyDutiesIds) {
      const companyDuty: CompanyDuty | undefined = companyDuties.find((companyDuty: CompanyDuty) => companyDuty.id === companyDutyId);
      if (companyDuty === undefined) throw new Error("companyDutyUndefined");
      newEmployeeDuty.companyDuties[companyDutyId] = LinkedCompanyDuty.createFromCompanyDuty(companyDuty);
      // frequency
      if (companyDuty.frequency < newEmployeeDuty.frequency) newEmployeeDuty.frequency = companyDuty.frequency;
      // risks
      for (const riskId of Object.keys(companyDuty.risks)) {
        if (newEmployeeDuty.risks[riskId] === undefined) newEmployeeDuty.risks[riskId] = companyDuty.risks[riskId];
      }
      // risksNotes
      if (companyDuty.risksNotes !== undefined) {
        if (newEmployeeDuty.risksNotes === undefined) {
          newEmployeeDuty.risksNotes = companyDuty.risksNotes;
        } else {
          newEmployeeDuty.risksNotes += "\n" + companyDuty.risksNotes;
        }
      }
      // testTypes
      for (const testTypeId of Object.keys(companyDuty.testTypes)) {
        if (newEmployeeDuty.testTypes[testTypeId] === undefined) newEmployeeDuty.testTypes[testTypeId] = companyDuty.testTypes[testTypeId];
      }
      // surveys
      for (const surveyId of Object.keys(companyDuty.surveys)) {
        if (newEmployeeDuty.surveys[surveyId] === undefined) newEmployeeDuty.surveys[surveyId] = companyDuty.surveys[surveyId];
      }
    }
    newEmployeeDuty.from = new Date(examination.date.getTime());
    newEmployeeDuty.fromSort = format(examination.date, "yyyy-MM-dd");
    batch.set(employeeDutyModel.getDocumentReference(undefined, examination.employee.id), newEmployeeDuty.toFirestore());

    await batch.commit();

    // update examination with new duty and recalc stuff
    examination.dutyName = newEmployeeDuty.name;
    examination.dutyDate = new Date(newEmployeeDuty.from.getTime());
    examination.frequency = newEmployeeDuty.frequency;
    examination.risks = newEmployeeDuty.risks;
    examination.risksNotes = newEmployeeDuty.risksNotes;
    examination.examinationTests = {};
    examination.testsNotes = undefined;
    examination.surveys = {};
    examination.surveysNotes = undefined;

    const expectedNextExaminationDate: Date = subDays(addMonths(examination.date, examination.frequency), 1);
    // get employees old examinations for selected company
    const oldExaminations: Examination[] = (
      await examinationModel.getExaminationsByEmployeeAndCompanyAndFirm(examination.employee.id, examination.company.id)
    ).filter((oldExamination: Examination) => oldExamination.id !== examination.id);

    if (oldExaminations.length === 0) {
      // no old examinations
      // create tests from employee duty
      let testOrder = 1;
      for (const testType of newEmployeeDuty.getLinkedTestTypes()) {
        // if the test is already included as an external test, skip it
        if (examination.getExternalTests().filter((externalTest) => externalTest.testType?.id === testType.id).length > 0) continue;

        const examinationTest: ExaminationTest = new ExaminationTest();
        examinationTest.date = new Date(examination.date.getTime());
        examinationTest.testType = testType;
        examinationTest.createTestForm();
        examinationTest.order = testOrder;
        examination.addExaminationTest(examinationTest);
        testOrder++;
      }
      examination.setTestsSummary();

      // insert all surveys
      for (const survey of newEmployeeDuty.getLinkedSurveys()) {
        survey.date = new Date(examination.date.getTime());
        survey.createSurveyForm();
        examination.addLinkedSurvey(survey);
      }
      examination.setSurveysSummary();
    } else {
      // there is at least one old examination
      const lastExamination: Examination = oldExaminations[oldExaminations.length - 1];
      // copy last examination data to the new examination
      examination.workHistory = lastExamination.workHistory;
      examination.familyHistory = lastExamination.familyHistory;
      examination.physioHistory = lastExamination.physioHistory;
      examination.farHistory = lastExamination.farHistory;
      // last recent history goes to new far history
      if (lastExamination.recentHistory.notes !== undefined) {
        examination.farHistory.goodHealthCheck = false;
        if (examination.farHistory.healthDetails === undefined) examination.farHistory.healthDetails = "";
        examination.farHistory.healthDetails += `\n\n${format(lastExamination.date, "dd/MM/yyyy")} - ${lastExamination.recentHistory.notes}`;
        examination.farHistory.healthDetails = examination.farHistory.healthDetails.trim();
      }
      examination.physicalExam = lastExamination.physicalExam;
      examination.ending.ending = lastExamination.ending.ending;
      examination.ending.tags = lastExamination.ending.tags;

      // create tests according to expiration
      let testOrder = 1;
      for (const testType of newEmployeeDuty.getLinkedTestTypes()) {
        // if the test is already included as an external test, skip it
        if (examination.getExternalTests().filter((externalTest) => externalTest.testType?.id === testType.id).length > 0) continue;
        // if frequency is not expected, skip the testType
        if (testType.frequency > 900) continue;
        // search for previously done testTypes
        let lastPerformedDate: Date | undefined = undefined;
        for (const oldExamination of oldExaminations) {
          const oldTest: ExaminationTest | undefined = oldExamination.getExaminationTests().find((oldTest) => oldTest.testType?.id === testType.id);
          if (oldTest !== undefined && oldTest.date !== undefined) {
            if (lastPerformedDate === undefined || compareAsc(oldTest.date, lastPerformedDate) > 0) lastPerformedDate = oldTest.date;
          } else {
            // maybe it is an external test
            const oldExternalTest: LinkedExternalTest | undefined = oldExamination
              .getExternalTests()
              .find((oldTest) => oldTest.testType?.id === testType.id);
            if (oldExternalTest !== undefined && oldExternalTest.date !== undefined) {
              if (lastPerformedDate === undefined || compareAsc(oldExternalTest.date, lastPerformedDate) > 0) {
                lastPerformedDate = oldExternalTest.date;
              }
            }
          }
        }
        // if testType has never been performed or it is going to expire before the next examination, add it to the examination
        if (lastPerformedDate === undefined || compareAsc(expectedNextExaminationDate, addMonths(lastPerformedDate, testType.frequency))) {
          const examinationTest: ExaminationTest = new ExaminationTest();
          examinationTest.date = new Date(examination.date.getTime());
          examinationTest.testType = testType;
          examinationTest.createTestForm();
          examinationTest.order = testOrder;
          examination.addExaminationTest(examinationTest);
          testOrder++;
        }
      }
      examination.setTestsSummary();

      // insert surveys according to expiration
      for (const survey of newEmployeeDuty.getLinkedSurveys()) {
        // if frequency is not expected, skip the survey
        if (survey.frequency > 900) continue;
        // search for previously done surveys
        let lastPerformedSurvey: LinkedSurvey | undefined = undefined;
        for (const oldExamination of oldExaminations) {
          const oldSurvey: LinkedSurvey | undefined = oldExamination.getLinkedSurveys().find((oldSurvey) => oldSurvey.id === survey.id);
          if (oldSurvey === undefined) continue;
          if (oldSurvey.date === undefined) continue;
          if (
            lastPerformedSurvey === undefined ||
            (lastPerformedSurvey.date !== undefined && compareAsc(oldSurvey.date, lastPerformedSurvey.date) > 0)
          ) {
            lastPerformedSurvey = oldSurvey;
            if (oldSurvey.surveyForm !== undefined) oldSurvey.surveyForm.isCompleted = false;
          }
        }
        if (lastPerformedSurvey === undefined) {
          // if survey has never been performed, add it to the examination
          survey.date = new Date(examination.date.getTime());
          survey.createSurveyForm();
          examination.addLinkedSurvey(survey);
        } else if (
          lastPerformedSurvey.date !== undefined &&
          compareAsc(expectedNextExaminationDate, addMonths(lastPerformedSurvey.date, survey.frequency))
        ) {
          // if survey is going to expire before the next examination, add it to the examination cloning the last performed one
          lastPerformedSurvey.date = new Date(examination.date.getTime());
          if (lastPerformedSurvey.surveyForm !== undefined) lastPerformedSurvey.surveyForm.isCompleted = false;
          survey.createSurveyForm();
          examination.addLinkedSurvey(survey);
        }
      }
      examination.setSurveysSummary();
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.changeDuty", { examination, newDutyName, selectedCompanyDutiesIds }, error);
  }
};
