import { Timestamp } from "firebase/firestore";

export class DateStrictField {
  public static fromFirestore(value: unknown, defaultValue: Date): Date {
    if (value === null || value === undefined) return defaultValue;

    const date: Date = (value as Timestamp).toDate();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public static toFirestore(value: Date): Timestamp {
    value.setHours(0, 0, 0, 0);
    return Timestamp.fromDate(value);
  }

  public static fromOfflineCache(value: unknown, defaultValue: Date): Date {
    if (value === null || value === undefined) return defaultValue;

    const date: Date = new Date(value as string);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public static toOfflineCache(value: Date): string {
    value.setHours(0, 0, 0, 0);
    return value.toISOString();
  }
}
