export const it = {
  docType: {
    availableFor: "Disponibile per",
    availableForError: "Selezionare almeno un valore",
    mandatoryFor: "Obbligatorio per",
    name: "Nome",
    nameError: "Inserire un nome valido",
    docType: "Tipo di documento",
    docTypes: "Tipi di documento",
    sources: {
      broker: "Intermediario",
      company: "Azienda",
      doctor: "Medico",
      employee: "Lavoratore",
      supplier: "Fornitore",
    },
    visibleFor: "Visibile da",
  },
  navigation: {
    docType: "Tipi di documento",
  },
};
