import { Timestamp } from "firebase/firestore";

export class DateTimeStrictField {
  public static fromFirestore(value: unknown, defaultValue: Date): Date {
    if (value === null || value === undefined) return defaultValue;

    return (value as Timestamp).toDate();
  }

  public static toFirestore(value: Date): Timestamp {
    return Timestamp.fromDate(value);
  }

  public static fromOfflineCache(value: unknown, defaultValue: Date): Date {
    if (value === null || value === undefined) return defaultValue;

    return new Date(value as string);
  }

  public static toOfflineCache(value: Date): string {
    return value.toISOString();
  }
}
