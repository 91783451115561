import { Ref, ref } from "vue";

import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { Filter } from "./Filter";

export class KoruBrowserModule<T> {
  public canCreate = true;
  public canDelete = true;
  public canEdit = true;
  public canView = true;
  public contextCommands: ContextCommand[] = [];
  public createCommands: ContextCommand[] = [];
  public createPath: string | undefined = undefined;
  public deleteDescriptionField = "";
  public documents: Ref<T[]> = ref([]);
  public explicitRequiredModule: string | undefined = undefined;
  public filters: Ref<Filter[]> = ref([]);
  public loadingStop = true;
  public path = "";
  public rowClass: ((data: T) => string | undefined) | undefined = undefined;
  public searchFields: string[] = [];
  public sortField = "";
  public sortOrder = 1;
  public suffix = "";

  public onDeleteAction(document: T): Promise<boolean> {
    console.log(document);
    return Promise.resolve(true);
  }

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onLoadWithFiltersAction: (() => Promise<void>) | undefined = undefined;

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }
}
