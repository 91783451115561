import { AppError } from "@/core/modules/appFault/objects/AppError";
import { AppFault } from "@/core/modules/appFault/objects/AppFault";
import { appFaultModel } from "../AppFaultModel";
import { LinkedUser } from "@/core/modules/user/objects/LinkedUser";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const createAppFaultFromAppError = async (appError: AppError, rethrows?: boolean): Promise<void> => {
  const appFault: AppFault = new AppFault();
  appFault.message = appError.message;
  appFault.stack = appError.infos;

  const user: User = store.getters[storeTypes.getters.getUser];
  if (user !== undefined) appFault.user = LinkedUser.createFromUser(user);

  await appFaultModel.createDocument(appFault);

  if (rethrows === true) throw appError as Error;
};
