import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isEmailUnique = async (email: string, userId = "empty"): Promise<boolean> => {
  try {
    if (email === undefined) return false;

    return CloudHelpers.runValidatorFunction("coreUserEmailValidator", email, userId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("UserModel.isEmailUnique", { email, userId }, error);
    return false;
  }
};
