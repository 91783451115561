export const it = {
  serviceType: {
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    invoicingType: "Tipo di fatturazione",
    invoicingTypeError: "Selezionare un tipo di fatturazione",
    invoicingTypes: {
      byHour: "A ora",
      byService: "A prestazione",
    },
    name: "Nome",
    nameError: "Inserire un nome valido",
    serviceType: "Tipo di prestazione",
    serviceTypes: "Tipi di prestazione",
  },
  navigation: {
    serviceType: "Tipi di prestazione",
  },
};
