import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "../BranchModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getBranchByInailId = async (inailId: string): Promise<Branch | undefined> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (branchModel.offlineModuleModel === undefined) return undefined;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Branch[] = (await offlineModel.getCacheByCollection(branchModel.offlineModuleModel)) as Branch[];
      const filteredDocuments: Branch[] = documents.filter((branch) => branch.inailId === inailId);
      if (filteredDocuments.length === 0) return undefined;
      return filteredDocuments[0];
    }

    const snapshotQuery: Query<Branch> = query(
      branchModel.getPathReference().withConverter(branchModel.firestoreConverter),
      where("inailId", "==", inailId)
    );

    const snapshot: QuerySnapshot<Branch> = await getDocs(branchModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("BranchModel.getBranchByInailId", { inailId }, error);
    return undefined;
  }
};
