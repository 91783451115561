import { StoreState } from "./interfaces/StoreState";
import { storeTypes } from "./types";
import { User } from "@/core/modules/user/objects/User";

export const mutations = {
  [storeTypes.mutations.closeEditingDialog]: (state: StoreState): void => {
    state.editingDialog = false;
    state.route = undefined;
  },
  [storeTypes.mutations.editingStart]: (state: StoreState): void => {
    state.editing = true;
  },
  [storeTypes.mutations.editingStop]: (state: StoreState): void => {
    state.editing = false;
    state.editingDialog = false;
  },
  [storeTypes.mutations.loadingStart]: (state: StoreState): void => {
    state.loading = true;
  },
  [storeTypes.mutations.loadingStop]: (state: StoreState): void => {
    setTimeout(() => {
      state.loading = false;
    }, state.loadingTimeout);
  },
  [storeTypes.mutations.openEditingDialog]: (state: StoreState, route: string): void => {
    state.route = route;
    state.editingDialog = true;
  },
  [storeTypes.mutations.resetUploadedData]: (state: StoreState): void => {
    state.uploadedData = undefined;
  },
  [storeTypes.mutations.setPreviousRoute]: (state: StoreState, route: string): void => {
    state.previousRoute = route;
  },
  [storeTypes.mutations.setUploadedData]: (state: StoreState, uploadedData: string): void => {
    state.uploadedData = uploadedData;
  },
  [storeTypes.mutations.setUser]: (state: StoreState, user: User | undefined): void => {
    state.user = user;
  },
};
