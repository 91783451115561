import { RouteRecordRaw } from "vue-router";

export const legalRecordSessionRoutes: RouteRecordRaw[] = [
  {
    path: "/legal-record-sessions",
    name: "LegalRecordSessionList",
    component: () => import("../views/LegalRecordSessionList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "legalRecord",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/legal-record-sessions/:id/edit",
    name: "LegalRecordSessionEdit",
    component: () => import("../views/LegalRecordSessionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "legalRecord",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/legal-record-sessions/:id",
    name: "LegalRecordSessionView",
    component: () => import("../views/LegalRecordSessionView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "legalRecord",
      requiredRight: "viewUI",
    },
  },
];
