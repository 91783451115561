export const en = {
  appFault: {
    appFault: "App error",
    appFaults: "App errors",
    createdAt: "Date and time",
    message: "Message",
    noDetails: "No details available",
    notifications: {
      appFaultCreated: "App error created",
      groupName: "App errors",
    },
    stack: "Details",
    user: "User",
  },
  buttons: {
    deleteAll: "Delete all",
    showDetails: "Show details",
  },
  navigation: {
    appFault: "App errors",
  },
};
