import { RouteRecordRaw } from "vue-router";

export const contractRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:companyId/contracts/:id/edit",
    name: "ContractEdit",
    component: () => import("../views/ContractEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "contract",
      requiredRight: "viewUI",
    },
  },
];
