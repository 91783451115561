import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { Risk } from "../objects/Risk";
import { riskModel } from "./RiskModel";

export class RiskOfflineModel extends OfflineModuleModel<Risk> {
  public constructor() {
    super(() => new Risk(), "risks", "risk.risks", undefined);
  }

  public async downloadCache(): Promise<Risk[]> {
    try {
      return riskModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("RiskOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const riskOfflineModel: RiskOfflineModel = new RiskOfflineModel();
