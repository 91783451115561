export const fr = {
  utility: {
    choose: "Choisissez un outil utilitaire",
    utility: "Utilitaire",
  },
  navigation: {
    utility: "Utilitaire",
  },
  toast: {
    error: {},
    success: {
      utilityDone: "Opération effectuée avec succès",
    },
    warning: {},
  },
};
