import { TestForm } from "./TestForm";

import { NullableNumberField, StringField } from "@/core/fields";

export class SpiroTestForm extends TestForm {
  public class: number | undefined = undefined;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): SpiroTestForm {
    super.fromFirestore(data);

    this.class = NullableNumberField.fromFirestore(data.class);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.class = NullableNumberField.toFirestore(this.class);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): SpiroTestForm {
    super.fromOfflineCache(data);

    this.class = NullableNumberField.fromOfflineCache(data.class);
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.class = NullableNumberField.toOfflineCache(this.class);
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
