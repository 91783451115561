import { SurveyForm } from "./SurveyForm";

import { BooleanField, StringField } from "@/core/fields";

export class AllergySurveyForm extends SurveyForm {
  public question1 = false;
  public question2 = false;
  public question3 = false;
  public question4 = false;
  public question5 = false;
  public question6: string | undefined = undefined;
  public question7 = false;
  public question8 = false;
  public question9 = false;
  public question10 = false;
  public question11 = false;
  public question12 = false;
  public question13: string | undefined = undefined;
  public question14 = false;
  public question15 = false;
  public question16 = false;
  public question17: string | undefined = undefined;
  public question18 = false;
  public question19 = false;
  public question20 = false;
  public question21 = false;
  public question22: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): AllergySurveyForm {
    super.fromFirestore(data);

    this.question1 = BooleanField.fromFirestore(data.question1);
    this.question2 = BooleanField.fromFirestore(data.question2);
    this.question3 = BooleanField.fromFirestore(data.question3);
    this.question4 = BooleanField.fromFirestore(data.question4);
    this.question5 = BooleanField.fromFirestore(data.question5);
    this.question6 = StringField.fromFirestore(data.question6);
    this.question7 = BooleanField.fromFirestore(data.question7);
    this.question8 = BooleanField.fromFirestore(data.question8);
    this.question9 = BooleanField.fromFirestore(data.question9);
    this.question10 = BooleanField.fromFirestore(data.question10);
    this.question11 = BooleanField.fromFirestore(data.question11);
    this.question12 = BooleanField.fromFirestore(data.question12);
    this.question13 = StringField.fromFirestore(data.question13);
    this.question14 = BooleanField.fromFirestore(data.question14);
    this.question15 = BooleanField.fromFirestore(data.question15);
    this.question16 = BooleanField.fromFirestore(data.question16);
    this.question17 = StringField.fromFirestore(data.question17);
    this.question18 = BooleanField.fromFirestore(data.question18);
    this.question19 = BooleanField.fromFirestore(data.question19);
    this.question20 = BooleanField.fromFirestore(data.question20);
    this.question21 = BooleanField.fromFirestore(data.question21);
    this.question22 = StringField.fromFirestore(data.question22);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question2 = BooleanField.toFirestore(this.question2);
    firestoreData.question3 = BooleanField.toFirestore(this.question3);
    firestoreData.question4 = BooleanField.toFirestore(this.question4);
    firestoreData.question5 = BooleanField.toFirestore(this.question5);
    firestoreData.question6 = StringField.toFirestore(this.question6);
    firestoreData.question7 = BooleanField.toFirestore(this.question7);
    firestoreData.question8 = BooleanField.toFirestore(this.question8);
    firestoreData.question9 = BooleanField.toFirestore(this.question9);
    firestoreData.question10 = BooleanField.toFirestore(this.question10);
    firestoreData.question11 = BooleanField.toFirestore(this.question11);
    firestoreData.question12 = BooleanField.toFirestore(this.question12);
    firestoreData.question13 = StringField.toFirestore(this.question13);
    firestoreData.question14 = BooleanField.toFirestore(this.question14);
    firestoreData.question15 = BooleanField.toFirestore(this.question15);
    firestoreData.question16 = BooleanField.toFirestore(this.question16);
    firestoreData.question17 = StringField.toFirestore(this.question17);
    firestoreData.question18 = BooleanField.toFirestore(this.question18);
    firestoreData.question19 = BooleanField.toFirestore(this.question19);
    firestoreData.question20 = BooleanField.toFirestore(this.question20);
    firestoreData.question21 = BooleanField.toFirestore(this.question21);
    firestoreData.question22 = StringField.toFirestore(this.question22);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): AllergySurveyForm {
    super.fromOfflineCache(data);

    this.question1 = BooleanField.fromOfflineCache(data.question1);
    this.question2 = BooleanField.fromOfflineCache(data.question2);
    this.question3 = BooleanField.fromOfflineCache(data.question3);
    this.question4 = BooleanField.fromOfflineCache(data.question4);
    this.question5 = BooleanField.fromOfflineCache(data.question5);
    this.question6 = StringField.fromOfflineCache(data.question6);
    this.question7 = BooleanField.fromOfflineCache(data.question7);
    this.question8 = BooleanField.fromOfflineCache(data.question8);
    this.question9 = BooleanField.fromOfflineCache(data.question9);
    this.question10 = BooleanField.fromOfflineCache(data.question10);
    this.question11 = BooleanField.fromOfflineCache(data.question11);
    this.question12 = BooleanField.fromOfflineCache(data.question12);
    this.question13 = StringField.fromOfflineCache(data.question13);
    this.question14 = BooleanField.fromOfflineCache(data.question14);
    this.question15 = BooleanField.fromOfflineCache(data.question15);
    this.question16 = BooleanField.fromOfflineCache(data.question16);
    this.question17 = StringField.fromOfflineCache(data.question17);
    this.question18 = BooleanField.fromOfflineCache(data.question18);
    this.question19 = BooleanField.fromOfflineCache(data.question19);
    this.question20 = BooleanField.fromOfflineCache(data.question20);
    this.question21 = BooleanField.fromOfflineCache(data.question21);
    this.question22 = StringField.fromOfflineCache(data.question22);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question2 = BooleanField.toOfflineCache(this.question2);
    firestoreData.question3 = BooleanField.toOfflineCache(this.question3);
    firestoreData.question4 = BooleanField.toOfflineCache(this.question4);
    firestoreData.question5 = BooleanField.toOfflineCache(this.question5);
    firestoreData.question6 = StringField.toOfflineCache(this.question6);
    firestoreData.question7 = BooleanField.toOfflineCache(this.question7);
    firestoreData.question8 = BooleanField.toOfflineCache(this.question8);
    firestoreData.question9 = BooleanField.toOfflineCache(this.question9);
    firestoreData.question10 = BooleanField.toOfflineCache(this.question10);
    firestoreData.question11 = BooleanField.toOfflineCache(this.question11);
    firestoreData.question12 = BooleanField.toOfflineCache(this.question12);
    firestoreData.question13 = StringField.toOfflineCache(this.question13);
    firestoreData.question14 = BooleanField.toOfflineCache(this.question14);
    firestoreData.question15 = BooleanField.toOfflineCache(this.question15);
    firestoreData.question16 = BooleanField.toOfflineCache(this.question16);
    firestoreData.question17 = StringField.toOfflineCache(this.question17);
    firestoreData.question18 = BooleanField.toOfflineCache(this.question18);
    firestoreData.question19 = BooleanField.toOfflineCache(this.question19);
    firestoreData.question20 = BooleanField.toOfflineCache(this.question20);
    firestoreData.question21 = BooleanField.toOfflineCache(this.question21);
    firestoreData.question22 = StringField.toOfflineCache(this.question22);

    return firestoreData;
  }
}
