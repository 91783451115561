export class ArrayField {
  public static fromFirestore<T>(value: unknown, converter: (value: Record<string, unknown>) => T): T[] {
    if (value === null || value === undefined) return [];

    return (value as Record<string, unknown>[]).map((item) => converter(item));
  }

  public static toFirestore<T>(value: T[], converter: (value: T) => unknown): unknown[] {
    return value.map((item) => converter(item));
  }

  public static fromOfflineCache<T>(value: unknown, converter: (value: Record<string, unknown>) => T): T[] {
    return ArrayField.fromFirestore<T>(value, converter);
  }

  public static toOfflineCache<T>(value: T[], converter: (value: T) => unknown): unknown[] {
    return ArrayField.toFirestore<T>(value, converter);
  }

  public static getIds(value: unknown): string[] {
    if (value === null || value === undefined) return [];

    return (value as Record<string, unknown>[]).map((item) => item.id as string);
  }
}
