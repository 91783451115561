import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class SupplierDocHelpers {
  public static getFolderPath(supplierId: string | undefined): string {
    try {
      if (supplierId === undefined) throw new Error("supplierId is undefined");
      return `suppliers/${supplierId}/docs/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("SupplierDocHelpers.getFolderPath", { supplierId }, error);
      throw error;
    }
  }
}
