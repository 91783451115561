import { getDoc, setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Examination } from "../objects/Examination";
import { examinationModel } from "./ExaminationModel";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class ExaminationOfflineModel extends OfflineModuleModel<Examination> {
  public constructor() {
    super(() => new Examination(), "examinations", "examination.examinations", "examination");
    this.canCreate = true;
    this.canUpdate = true;
  }

  public async downloadCache(): Promise<Examination[]> {
    try {
      if (firmModel.offlineModuleModel === undefined) return [];
      if (companyModel.offlineModuleModel === undefined) return [];
      const firms: Firm[] = (await offlineModel.getCacheByCollection(firmModel.offlineModuleModel)) as Firm[];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let examinations: Examination[] = [];

      for (const firm of firms) {
        for (const company of companies) {
          examinations = [...examinations, ...(await examinationModel.getExaminationsByCompanyAndFirm(company.id, firm.id))];
        }
      }

      return examinations;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExaminationOfflineModel.downloadCache", {}, error);
      return [];
    }
  }

  public async createDocumentCallback(document: Examination): Promise<boolean> {
    try {
      const doc = await getDoc(examinationModel.getDocumentReference(document.id));
      if (doc.exists() === true) throw new Error("id duplicated");

      await document.setCodes();

      document.setSearchKeys();
      document.setTimestampFields("create");

      await setDoc(examinationModel.getDocumentReference(document.id).withConverter(examinationModel.firestoreConverter), document);

      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExaminationOfflineModel.createDocumentCallback", { document }, error);
      return false;
    }
  }

  public async updateDocumentCallback(document: Examination): Promise<boolean> {
    try {
      const oldDocument: Examination = await examinationModel.getDocument(document.id);
      if (document.hasChangedFrom(oldDocument)) throw new Error("sync");

      await examinationModel.updateDocument(document);
      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExaminationOfflineModel.updateDocumentCallback", { document }, error);
      return false;
    }
  }
}

export const examinationOfflineModel: ExaminationOfflineModel = new ExaminationOfflineModel();
