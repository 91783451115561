import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "file-list-detail" }
const _hoisted_3 = { class: "file-name" }
const _hoisted_4 = { class: "file-description" }
const _hoisted_5 = { class: "file-list-action" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "file-list-detail" }
const _hoisted_8 = { class: "file-name" }
const _hoisted_9 = { class: "file-description" }
const _hoisted_10 = { class: "file-list-action" }
const _hoisted_11 = {
  key: 0,
  class: "text-center text-italic"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createBlock(_component_FileUpload, {
    accept: _ctx.accept !== undefined ? _ctx.accept : '.doc,.docx,.jpg,.jpeg,.odt,.pdf,.png',
    fileLimit: 10,
    maxFileSize: _ctx.maxFileSize !== undefined ? _ctx.maxFileSize : _ctx.config.storage.maxFileSize,
    multiple: true,
    showUploadButton: false,
    onSelect: _ctx.onFileSelected
  }, {
    content: _withCtx(({ files, removeFileCallback }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filesRef, (file, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "file-list-item",
          key: file.uniqueName
        }, [
          _createElementVNode("img", {
            src: `/assets/core/file-icons/${file.type?.toLowerCase()}.svg`,
            alt: "file"
          }, null, 8, _hoisted_1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(file.displayName), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("gen.file")) + " " + _toDisplayString(file.type) + " - " + _toDisplayString(_ctx.FormatHelpers.formatFileSize(file.size)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              icon: "pi pi-eye",
              label: _ctx.t('buttons.view'),
              onClick: ($event: any) => (_ctx.openFile(index))
            }, null, 8, ["label", "onClick"]),
            (_ctx.canDelete === true)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  icon: "pi pi-trash",
                  label: _ctx.t('buttons.delete'),
                  class: "p-button-warning",
                  onClick: ($event: any) => (_ctx.deleteFile(index))
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files, (file, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "file-list-item",
          key: file.name + file.type + file.size
        }, [
          _createElementVNode("img", {
            src: `/assets/core/file-icons/${file.name.split('.').pop()}.svg`,
            alt: "file"
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(file.name), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("gen.file")) + " " + _toDisplayString(file.type) + " - " + _toDisplayString(_ctx.FormatHelpers.formatFileSize(file.size)), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Button, {
              icon: "pi pi-trash",
              label: _ctx.t('buttons.delete'),
              class: "p-button-warning",
              onClick: ($event: any) => (removeFileCallback(index))
            }, null, 8, ["label", "onClick"])
          ])
        ]))
      }), 128))
    ]),
    empty: _withCtx(() => [
      (_ctx.filesRef.length === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.t("gen.filePlaceholder")), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["accept", "maxFileSize", "onSelect"]))
}