import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { ServiceCount } from "../objects/ServiceCount";
import { ServiceType } from "../objects/ServiceType";

export class ServiceCounterModel {
  public async countServices(startDate: Date, endDate: Date, companiesIds: string[], doctorId: string): Promise<ServiceCount[]> {
    try {
      if (startDate === undefined) throw new Error("periodUndefined");
      if (endDate === undefined) throw new Error("periodUndefined");
      if (companiesIds === undefined || companiesIds.length === 0) throw new Error("companiesIdsUndefined");
      if (doctorId === undefined) throw new Error("doctorIdUndefined");

      const serviceCounts: ServiceCount[] = [];

      for (const companyId of companiesIds) {
        const examinations: Examination[] = await examinationModel.getExaminationsWithFilters(startDate, endDate, companyId, "all", doctorId, "all");
        if (examinations.length === 0) continue;

        for (const examination of examinations) {
          if (examination.state === ExaminationState.Draft) continue;

          if (examination.type !== undefined) {
            const examinationCount: ServiceCount | undefined = serviceCounts.find(
              (serviceCount: ServiceCount) => serviceCount.type === ServiceType.Examination && serviceCount.id === examination.type?.id
            );
            if (examinationCount === undefined) {
              const examinationCount: ServiceCount = new ServiceCount(
                1,
                ServiceType.Examination,
                examination.type.id,
                examination.type.name ?? "-",
                1
              );
              serviceCounts.push(examinationCount);
            } else {
              examinationCount.count++;
            }
          }

          const testTypeCategoriesIds: string[] = [];
          for (const examinationTest of examination.getAllTests()) {
            const testTypeCount: ServiceCount | undefined = serviceCounts.find(
              (serviceCount: ServiceCount) => serviceCount.type === ServiceType.TestType && serviceCount.id === examinationTest.testType?.id
            );
            if (testTypeCount === undefined) {
              const testTypeCount: ServiceCount = new ServiceCount(
                3,
                ServiceType.TestType,
                examinationTest.testType?.id ?? "",
                examinationTest.testType?.name ?? "-",
                1
              );
              serviceCounts.push(testTypeCount);
            } else {
              testTypeCount.count++;
            }

            if (examinationTest.testType?.category === undefined || testTypeCategoriesIds.includes(examinationTest.testType?.category?.id)) continue;

            const testTypeCategoryCount: ServiceCount | undefined = serviceCounts.find(
              (serviceCount: ServiceCount) =>
                serviceCount.type === ServiceType.TestTypeCategory && serviceCount.id === examinationTest.testType?.category?.id
            );
            if (testTypeCategoryCount === undefined) {
              const testTypeCategoryCount: ServiceCount = new ServiceCount(
                2,
                ServiceType.TestTypeCategory,
                examinationTest.testType?.category?.id ?? "-",
                examinationTest.testType?.category?.name ?? "-",
                1
              );
              serviceCounts.push(testTypeCategoryCount);
            } else {
              testTypeCategoryCount.count++;
            }
            testTypeCategoriesIds.push(examinationTest.testType?.category?.id ?? "-");
          }

          for (const survey of examination.getLinkedSurveys()) {
            const surveyCount: ServiceCount | undefined = serviceCounts.find(
              (serviceCount: ServiceCount) => serviceCount.type === ServiceType.Survey && serviceCount.id === survey.id
            );
            if (surveyCount === undefined) {
              const surveyCount: ServiceCount = new ServiceCount(4, ServiceType.Survey, survey.id, survey.name ?? "-", 1);
              serviceCounts.push(surveyCount);
            } else {
              surveyCount.count++;
            }
          }
        }
      }

      return serviceCounts;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ServiceCounterModel.countServices", { startDate, endDate, companiesIds, doctorId }, error);
      return [];
    }
  }
}

export const serviceCounterModel: ServiceCounterModel = new ServiceCounterModel();
