import { Norm } from "../objects/Norm";
import { normModel } from "./NormModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class NormOfflineModel extends OfflineModuleModel<Norm> {
  public constructor() {
    super(() => new Norm(), "norms", "norm.norms", undefined);
  }

  public async downloadCache(): Promise<Norm[]> {
    return normModel.getDocuments();
  }
}

export const normOfflineModel: NormOfflineModel = new NormOfflineModel();
