export const it = {
  utilityAssignEmployeeDuty: {
    available: "Disponibile",
    name: "Assegna mansioni a lavoratori",
    notAvailable: "Non disponibile",
    selected: "Selezionato",
  },
  toast: {
    error: {},
    success: {},
    warning: {
      noCompany: "Nessuna azienda selezionata",
      noCompanyDuty: "Nessuna mansione aziendale selezionata",
      noEmployees: "Nessun lavoratore selezionato",
    },
  },
};
