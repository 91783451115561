
import { defineComponent } from "vue";

import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "DevBar",
  setup() {
    const { t } = useLocale();

    return {
      t,
    };
  },
});
