import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { Supplier } from "../objects/Supplier";

import { getActiveSuppliers, getSuppliersWithTrackExpirations, isVatCodeUnique } from "./methods";

export class SupplierModel extends FirestoreModel<Supplier> {
  public constructor() {
    super(() => new Supplier(), "suppliers", LockPolicy.DiscardUnsyncedChanges, "supplier");
    this.beforeDeleteFunction = "featuresSupplierBeforeDelete";
  }

  public async getDocuments(): Promise<Supplier[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(supplierId: string): Promise<Supplier> {
    return super.getDocument(supplierId);
  }

  public async createDocument(supplier: Supplier): Promise<string> {
    try {
      await supplier.handleArchive();

      return super.createDocument(supplier);
    } catch (error: unknown) {
      appFaultModel.catchAppError("SupplierModel.createSupplier", { supplier }, error);
      return "ERROR";
    }
  }

  public async updateDocument(supplier: Supplier): Promise<void> {
    try {
      await supplier.handleArchive();

      return super.updateDocument(supplier);
    } catch (error: unknown) {
      appFaultModel.catchAppError("SupplierModel.updateSupplier", { supplier }, error);
    }
  }

  public async deleteDocument(supplier: Supplier): Promise<boolean> {
    return super.deleteDocument(supplier);
  }

  public async getActiveSuppliers(): Promise<Supplier[]> {
    return getActiveSuppliers();
  }

  public async getSuppliersWithTrackExpirations(): Promise<Supplier[]> {
    return getSuppliersWithTrackExpirations();
  }

  public async isVatCodeUnique(vatCode: string, supplierId?: string): Promise<boolean> {
    return isVatCodeUnique(vatCode, supplierId);
  }
}

export const supplierModel: SupplierModel = new SupplierModel();
