import { Loader } from "@googlemaps/js-api-loader";

import { config } from "@/core/modules/config/objects/Config";

export class Maps {
  private loaded = false;

  public async load(): Promise<void> {
    this.loaded = true;
    if (window.google != undefined) return;

    const loader = new Loader({
      apiKey: config.api.googleMaps.key,
      version: "weekly",
      libraries: ["maps", "places"],
    });
    await loader.load();
    // await loader.importLibrary("maps");
    // await loader.importLibrary("places");
  }

  public isLoaded(): boolean {
    return this.loaded;
  }
}

export const maps: Maps = new Maps();
