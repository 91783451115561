import { compareAsc, subDays } from "date-fns";
import { DocumentReference } from "firebase/firestore";

import { EmployerType } from "./EmployerType";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";

import { DateField, EnumField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class Employer extends FirestoreOfflineDocument {
  public company: LinkedCompany | undefined = undefined;
  public branch: LinkedBranch | undefined = undefined;
  public type: EmployerType = EmployerType.Permanent;
  public registrationNumber: string | undefined = undefined;
  public from: Date | undefined = undefined;
  public fromSort = "0000-00-00";
  public to: Date | undefined = undefined;
  public toSort = "9999-99-99";
  public healthFolderDeliverDate: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Employer {
    super.fromFirestore(data, id, firestoreRef);

    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.branch = ObjectField.fromFirestore<LinkedBranch>(data.branch, (value) => new LinkedBranch(value));
    this.type = EnumField.fromFirestore<EmployerType>(data.type, Object.values(EmployerType), EmployerType.Permanent);
    this.registrationNumber = StringField.fromFirestore(data.registrationNumber);
    this.from = DateField.fromFirestore(data.from);
    this.fromSort = StringStrictField.fromFirestore(data.fromSort, "0000-00-00");
    this.to = DateField.fromFirestore(data.to);
    this.toSort = StringStrictField.fromFirestore(data.toSort, "9999-99-99");
    this.healthFolderDeliverDate = DateField.fromFirestore(data.healthFolderDeliverDate);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.branch = ObjectField.toFirestore<LinkedBranch>(this.branch, (value) => value.toFirestore());
    firestoreData.type = EnumField.toFirestore<EmployerType>(this.type, EmployerType.Permanent);
    firestoreData.registrationNumber = StringField.toFirestore(this.registrationNumber);
    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.healthFolderDeliverDate = DateField.toFirestore(this.healthFolderDeliverDate);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Employer {
    super.fromOfflineCache(data);

    this.parentId = StringField.fromOfflineCache(data.parentId);
    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => {
      const linkedCompany: LinkedCompany = new LinkedCompany(value);
      return linkedCompany.fromOfflineCache(value);
    });
    this.branch = ObjectField.fromOfflineCache<LinkedBranch>(data.branch, (value) => {
      const linkedBranch: LinkedBranch = new LinkedBranch(value);
      return linkedBranch.fromOfflineCache(value);
    });
    this.type = EnumField.fromOfflineCache<EmployerType>(data.type, Object.values(EmployerType), EmployerType.Permanent);
    this.registrationNumber = StringField.fromOfflineCache(data.registrationNumber);
    this.from = DateField.fromOfflineCache(data.from);
    this.fromSort = StringStrictField.fromOfflineCache(data.fromSort, "0000-00-00");
    this.to = DateField.fromOfflineCache(data.to);
    this.toSort = StringStrictField.fromOfflineCache(data.toSort, "9999-99-99");
    this.healthFolderDeliverDate = DateField.fromOfflineCache(data.healthFolderDeliverDate);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.parentId = this.firestoreRef !== undefined ? this.firestoreRef.parent.parent?.id : StringField.toOfflineCache(this.parentId);
    firestoreData.company = ObjectField.toOfflineCache<LinkedCompany>(this.company, (value) => value.toOfflineCache());
    firestoreData.branch = ObjectField.toOfflineCache<LinkedBranch>(this.branch, (value) => value.toOfflineCache());
    firestoreData.type = EnumField.toOfflineCache<EmployerType>(this.type, EmployerType.Permanent);
    firestoreData.registrationNumber = StringField.toOfflineCache(this.registrationNumber);
    firestoreData.from = DateField.toOfflineCache(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toOfflineCache(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");
    firestoreData.healthFolderDeliverDate = DateField.toOfflineCache(this.healthFolderDeliverDate);

    return firestoreData;
  }

  public isCurrentlyActive(): boolean {
    const now: Date = new Date();
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, now) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(now, to) > 0) return false;
    }
    return true;
  }

  public isEndedAtDate(): Date | undefined {
    if (this.to === undefined || this.to === null) return undefined;

    const now: Date = new Date();
    const to: Date = new Date(this.to.getTime());
    to.setHours(23, 59, 59, 999);
    if (compareAsc(now, to) > 0) return this.to;
    return undefined;
  }

  public isEndedAtThresholdDate(): Date | undefined {
    if (this.to === undefined || this.to === null) return undefined;

    const thresholdDate: Date = subDays(new Date(), 30);
    const to: Date = new Date(this.to.getTime());
    to.setHours(23, 59, 59, 999);
    if (compareAsc(thresholdDate, to) > 0) return this.to;
    return undefined;
  }
}
