import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { BooleanField } from "@/core/fields";

export class TestForm {
  public id: string = DataHelpers.uniqueId();
  public isCompleted = false;
  public isFit = false;
  public isPrintable = false;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): TestForm {
    this.id = DataHelpers.uniqueId();

    this.isCompleted = BooleanField.fromFirestore(data.isCompleted);
    this.isFit = BooleanField.fromFirestore(data.isFit);
    this.isPrintable = BooleanField.fromFirestore(data.isPrintable);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.isCompleted = BooleanField.toFirestore(this.isCompleted);
    firestoreData.isFit = BooleanField.toFirestore(this.isFit);
    firestoreData.isPrintable = BooleanField.toFirestore(this.isPrintable);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): TestForm {
    this.id = DataHelpers.uniqueId();

    this.isCompleted = BooleanField.fromOfflineCache(data.isCompleted);
    this.isFit = BooleanField.fromOfflineCache(data.isFit);
    this.isPrintable = BooleanField.fromOfflineCache(data.isPrintable);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.isCompleted = BooleanField.toOfflineCache(this.isCompleted);
    firestoreData.isFit = BooleanField.toOfflineCache(this.isFit);
    firestoreData.isPrintable = BooleanField.toOfflineCache(this.isPrintable);

    return firestoreData;
  }
}
