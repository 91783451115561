import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { UpperExamSurveyForm } from "../objects/UpperExamSurveyForm";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class UpperExamSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private upperExamSurveyForm: UpperExamSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    upperExamSurveyForm: UpperExamSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.upperExamSurveyForm = upperExamSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      upperExamAnswerTable: { fontSize: 8 },
      upperExamAnswerValue: { alignment: "center", bold: true },
      upperExamNote: {
        color: this.textColor,
        fontSize: 10,
        alignment: "justify",
        margin: [DataHelpers.mmToPoints(60), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
      },
      upperExamRow: { color: this.textColor, fontSize: 10 },
      upperExamRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      upperExamQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("VALUTAZIONE FUNZIONALE DEGLI ARTI SUPERIORI", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle(this.t("surveyForm.upperExamSurveyForm.title1"));

    this.writeOneBooleanQuestion(1);
    if (this.upperExamSurveyForm.question1 === true) {
      this.writeOneArrayQuestion(2);
      this.writeTwoQuestionsWithTitle(this.t("surveyForm.upperExamSurveyForm.title2"), 3, 4);
      this.writeTwoQuestionsWithTitle("", 5, 6);
      this.writeOneArrayQuestion(7);
      this.writeRow(8);

      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.upperExamSurveyForm.question9 ?? "-",
        style: "upperExamNote",
      });
    }

    this.writeSubtitle(this.t("surveyForm.upperExamSurveyForm.title3"));
    this.writeOneBooleanQuestion(10);
    if (this.upperExamSurveyForm.question10 === true) {
      this.writeOneArrayQuestion(11);
      this.writeOneArrayQuestion(12);
      this.writeOneArrayQuestion(13);
      this.writeOneArrayQuestion(14);
      this.writeOneArrayQuestion(15);
      this.writeRow(16);

      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.upperExamSurveyForm.question17 ?? "-",
        style: "upperExamNote",
      });
    }

    this.writeSubtitle(this.t("surveyForm.upperExamSurveyForm.title4"));
    this.writeOneBooleanQuestion(18);
    if (this.upperExamSurveyForm.question18 === true) {
      this.writeOneArrayQuestion(19);
      this.writeOneArrayQuestion(20);
      this.writeTwoQuestionsWithTitle(this.t("surveyForm.upperExamSurveyForm.title5"), 21, 22);
      this.writeOneArrayQuestion(23);
      this.writeOneArrayQuestion(24);
      this.writeOneArrayQuestion(25);
      this.writeOneArrayQuestion(26);
      this.writeRow(27);

      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.upperExamSurveyForm.question28 ?? "-",
        style: "upperExamNote",
      });
    }

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeRow(questionIndex: number): void {
    const answer: string | undefined = this.upperExamSurveyForm[`question${questionIndex}` as keyof UpperExamSurveyForm] as string | undefined;

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(60),
          text: this.t(`surveyForm.upperExamSurveyForm.question${questionIndex}`),
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: "*",
          text: answer ?? "-",
          style: "upperExamRow",
        },
      ],
    });
  }

  private writeOneBooleanQuestion(questionA: number): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(60),
          text: questionA !== undefined ? this.t(`surveyForm.upperExamSurveyForm.question${questionA}`) : "",
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: "*",
          text: (this.upperExamSurveyForm[`question${questionA}` as keyof UpperExamSurveyForm] as boolean) === true ? "Sì" : "No",
          style: "upperExamRow",
        },
      ],
    });
  }

  private writeOneArrayQuestion(questionA: number): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(60),
          text: questionA !== undefined ? this.t(`surveyForm.upperExamSurveyForm.question${questionA}`) : "",
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: "*",
          text: (this.upperExamSurveyForm[`question${questionA}` as keyof UpperExamSurveyForm] as string[]).join(", ") ?? "-",
          style: "upperExamRow",
        },
      ],
    });
  }

  private writeTwoQuestionsWithTitle(title: string, questionA: number | undefined, questionB: number | undefined): void {
    let columnWidth = 30;
    if (questionB === undefined) columnWidth = 60;
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(60),
          text: title,
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: "*",
          text: "",
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionA !== undefined ? this.t(`surveyForm.upperExamSurveyForm.question${questionA}`) : "",
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionA !== undefined ? this.upperExamSurveyForm[`question${questionA}` as keyof UpperExamSurveyForm] ?? "-" : "",
          style: "upperExamRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionB !== undefined ? this.t(`surveyForm.upperExamSurveyForm.question${questionB}`) : "",
          style: "upperExamRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(3)],
          width: DataHelpers.mmToPoints(columnWidth),
          text: questionB !== undefined ? this.upperExamSurveyForm[`question${questionB}` as keyof UpperExamSurveyForm] ?? "-" : "",
          style: "upperExamRow",
        },
      ],
    });
  }
}
