import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getNextInvoiceCodeByDateAndFirm = async (date: Date, isVirtual: boolean): Promise<number> => {
  try {
    if (offlineModel.getOfflineState() === "offline") return 0;

    const firmId: string = firmModel.getSelectedFirmId();
    const currentYear: number = date.getFullYear() - 2000;

    if (isVirtual === true) {
      return FeaturesHelpers.runNextCodeFunction("featuresInvoiceNextCodeVirtual", currentYear, firmId);
    }
    return FeaturesHelpers.runNextCodeFunction("featuresInvoiceNextCode", currentYear, firmId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getNextInvoiceCodeByDateAndFirm", { date, isVirtual }, error);
    return 0;
  }
};
