import { createApp, h } from "vue";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { authModel } from "@/core/modules/auth/models/AuthModel";
import { Boot } from "@/core/modules/boot/Boot";
import { config } from "@/core/modules/config/objects/Config";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { Layout } from "@/core/modules/layout/objects/Layout";
import { LocaleHelpers } from "@/core/modules/locale/helpers/LocaleHelpers";
import { Pwa } from "@/core/modules/pwa/objects/Pwa";
import { router } from "@/core/modules/router/module";
import { store } from "@/core/modules/store/module";

import Base from "@/core/layout/Base.vue";
import Bedrock from "@/core/layout/Bedrock.vue";

/////////////////////////////////////////////////////////////
//                       create app                        //
/////////////////////////////////////////////////////////////

export const koruApp = createApp({
  computed: {
    ViewComponent() {
      const coreBaseRoutes = ["AccessDenied", "Error", "Forgot", "Installer", "Login", "Maintenance", "NotFound", "Recover"];

      const baseRoutes = coreBaseRoutes.concat(config.app.baseRoutes);

      if (this.$route.name == undefined) return Base;

      if (baseRoutes.includes(this.$route.name)) return Base;

      return Bedrock;
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});

/////////////////////////////////////////////////////////////
//                        init app                         //
/////////////////////////////////////////////////////////////

async function init() {
  try {
    const boot: Boot = new Boot();
    boot.start();

    Pwa.register();

    await firebase.initialize();

    await authModel.init();

    if (koruApp.config.globalProperties.mounted !== true) {
      Layout.init(koruApp);

      koruApp.use(LocaleHelpers.initLocales());
      koruApp.use(router);
      koruApp.use(store);

      koruApp.mount("#app");
      koruApp.config.globalProperties.mounted = true;

      AppHelpers.resetLocalStorageIfNeeded();

      AppHelpers.initErrorHandling();

      AppHelpers.preventWindowClose();

      koruApp.config.errorHandler = (err, vm, info) => {
        try {
          appFaultModel.catchAppError("App.errorHandler", { vm, info }, err);
        } catch (error: unknown) {
          if (error instanceof AppError) {
            error.addToStack("App.errorHandler", {}, error);
            console.log(error);
            appFaultModel.createAppFaultFromAppError(error);
          }
        }
      };
    }
  } catch (error: unknown) {
    console.error("Unable to boot KORU");
    console.log(error);
  }
}

init();
