import { RouteRecordRaw } from "vue-router";

export const examinationRoutes: RouteRecordRaw[] = [
  {
    path: "/examinations",
    name: "ExaminationList",
    component: () => import("../views/ExaminationList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/examinations/:startDate/:endDate/:companyId/:doctorId/:state",
    name: "ExaminationListWithQueryParams",
    component: () => import("../views/ExaminationList.vue"),
    props: (route) => ({
      startDate: route.params.startDate,
      endDate: route.params.endDate,
      companyId: route.params.companyId,
      doctorId: route.params.doctorId,
      state: route.params.state,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/examinations/:id/edit",
    name: "ExaminationEdit",
    component: () => import("../views/ExaminationEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/examinations/:id",
    name: "ExaminationView",
    component: () => import("../views/ExaminationView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
];
