import { NullableBooleanField, NullableNumberField, StringField } from "@/core/fields";

export class PhysicalExam {
  public weight: number | undefined = undefined;
  public height: number | undefined = undefined;
  public generalCondition: string | undefined = undefined;
  public skinCheck: boolean | undefined = undefined;
  public skinDetails: string | undefined = undefined;
  public mucousMembranesCheck: boolean | undefined = undefined;
  public mucousMembranesDetails: string | undefined = undefined;
  public annexesCheck: boolean | undefined = undefined;
  public annexesDetails: string | undefined = undefined;
  public lymphGlandsCheck: boolean | undefined = undefined;
  public lymphGlandsDetails: string | undefined = undefined;
  public headNeckCheck: boolean | undefined = undefined;
  public headNeckDetails: string | undefined = undefined;
  public thyroidCheck: boolean | undefined = undefined;
  public thyroidDetails: string | undefined = undefined;
  public chestCheck: boolean | undefined = undefined;
  public chestDetails: string | undefined = undefined;
  public expandabilityCheck: boolean | undefined = undefined;
  public expandabilityDetails: string | undefined = undefined;
  public fvtCheck: boolean | undefined = undefined;
  public fvtDetails: string | undefined = undefined;
  public percussionCheck: boolean | undefined = undefined;
  public percussionDetails: string | undefined = undefined;
  public mvCheck: boolean | undefined = undefined;
  public mvDetails: string | undefined = undefined;
  public pao: string | undefined = undefined;
  public fc: string | undefined = undefined;
  public tonesCheck: boolean | undefined = undefined;
  public tonesDetails: string | undefined = undefined;
  public breaksCheck: boolean | undefined = undefined;
  public breaksDetails: string | undefined = undefined;
  public wristsCheck: boolean | undefined = undefined;
  public wristsDetails: string | undefined = undefined;
  public varicesCheck: boolean | undefined = undefined;
  public varicesDetails: string | undefined = undefined;
  public shapeCheck: boolean | undefined = undefined;
  public shapeDetails: string | undefined = undefined;
  public palpationCheck: boolean | undefined = undefined;
  public palpationDetails: string | undefined = undefined;
  public listeningCheck: boolean | undefined = undefined;
  public listeningDetails: string | undefined = undefined;
  public liverCheck: boolean | undefined = undefined;
  public liverDetails: string | undefined = undefined;
  public spleenCheck: boolean | undefined = undefined;
  public spleenDetails: string | undefined = undefined;
  public otherAbdomenNotes: string | undefined = undefined;
  public mobilityCheck: boolean | undefined = undefined;
  public mobilityDetails: string | undefined = undefined;
  public articularityCheck: boolean | undefined = undefined;
  public articularityDetails: string | undefined = undefined;
  public musculatureCheck: boolean | undefined = undefined;
  public musculatureDetails: string | undefined = undefined;
  public rachisCheck: boolean | undefined = undefined;
  public rachisDetails: string | undefined = undefined;
  public lasegueCheck: boolean | undefined = undefined;
  public lasegueDetails: string | undefined = undefined;
  public wassermanCheck: boolean | undefined = undefined;
  public wassermanDetails: string | undefined = undefined;
  public mmCheck: boolean | undefined = undefined;
  public mmDetails: string | undefined = undefined;
  public otherMusculoskeletalNotes: string | undefined = undefined;
  public sensitivityCheck: boolean | undefined = undefined;
  public sensitivityDetails: string | undefined = undefined;
  public rotCheck: boolean | undefined = undefined;
  public rotDetails: string | undefined = undefined;
  public humorCheck: boolean | undefined = undefined;
  public humorDetails: string | undefined = undefined;
  public tinelCheck: boolean | undefined = undefined;
  public tinelDetails: string | undefined = undefined;
  public phalenCheck: boolean | undefined = undefined;
  public phalenDetails: string | undefined = undefined;
  public hearingNotes: string | undefined = undefined;
  public sightNotes: string | undefined = undefined;
  public giordanoCheck: boolean | undefined = undefined;
  public giordanoDetails: string | undefined = undefined;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): PhysicalExam {
    this.weight = NullableNumberField.fromFirestore(data.weight);
    this.height = NullableNumberField.fromFirestore(data.height);
    this.generalCondition = StringField.fromFirestore(data.generalCondition);
    this.skinCheck = NullableBooleanField.fromFirestore(data.skinCheck);
    this.skinDetails = StringField.fromFirestore(data.skinDetails);
    this.mucousMembranesCheck = NullableBooleanField.fromFirestore(data.mucousMembranesCheck);
    this.mucousMembranesDetails = StringField.fromFirestore(data.mucousMembranesDetails);
    this.annexesCheck = NullableBooleanField.fromFirestore(data.annexesCheck);
    this.annexesDetails = StringField.fromFirestore(data.annexesDetails);
    this.lymphGlandsCheck = NullableBooleanField.fromFirestore(data.lymphGlandsCheck);
    this.lymphGlandsDetails = StringField.fromFirestore(data.lymphGlandsDetails);
    this.headNeckCheck = NullableBooleanField.fromFirestore(data.headNeckCheck);
    this.headNeckDetails = StringField.fromFirestore(data.headNeckDetails);
    this.thyroidCheck = NullableBooleanField.fromFirestore(data.thyroidCheck);
    this.thyroidDetails = StringField.fromFirestore(data.thyroidDetails);
    this.chestCheck = NullableBooleanField.fromFirestore(data.chestCheck);
    this.chestDetails = StringField.fromFirestore(data.chestDetails);
    this.expandabilityCheck = NullableBooleanField.fromFirestore(data.expandabilityCheck);
    this.expandabilityDetails = StringField.fromFirestore(data.expandabilityDetails);
    this.fvtCheck = NullableBooleanField.fromFirestore(data.fvtCheck);
    this.fvtDetails = StringField.fromFirestore(data.fvtDetails);
    this.percussionCheck = NullableBooleanField.fromFirestore(data.percussionCheck);
    this.percussionDetails = StringField.fromFirestore(data.percussionDetails);
    this.mvCheck = NullableBooleanField.fromFirestore(data.mvCheck);
    this.mvDetails = StringField.fromFirestore(data.mvDetails);
    this.pao = StringField.fromFirestore(data.pao);
    this.fc = StringField.fromFirestore(data.fc);
    this.tonesCheck = NullableBooleanField.fromFirestore(data.tonesCheck);
    this.tonesDetails = StringField.fromFirestore(data.tonesDetails);
    this.breaksCheck = NullableBooleanField.fromFirestore(data.breaksCheck);
    this.breaksDetails = StringField.fromFirestore(data.breaksDetails);
    this.wristsCheck = NullableBooleanField.fromFirestore(data.wristsCheck);
    this.wristsDetails = StringField.fromFirestore(data.wristsDetails);
    this.varicesCheck = NullableBooleanField.fromFirestore(data.varicesCheck);
    this.varicesDetails = StringField.fromFirestore(data.varicesDetails);
    this.shapeCheck = NullableBooleanField.fromFirestore(data.shapeCheck);
    this.shapeDetails = StringField.fromFirestore(data.shapeDetails);
    this.palpationCheck = NullableBooleanField.fromFirestore(data.palpationCheck);
    this.palpationDetails = StringField.fromFirestore(data.palpationDetails);
    this.listeningCheck = NullableBooleanField.fromFirestore(data.listeningCheck);
    this.listeningDetails = StringField.fromFirestore(data.listeningDetails);
    this.liverCheck = NullableBooleanField.fromFirestore(data.liverCheck);
    this.liverDetails = StringField.fromFirestore(data.liverDetails);
    this.spleenCheck = NullableBooleanField.fromFirestore(data.spleenCheck);
    this.spleenDetails = StringField.fromFirestore(data.spleenDetails);
    this.otherAbdomenNotes = StringField.fromFirestore(data.otherAbdomenNotes);
    this.mobilityCheck = NullableBooleanField.fromFirestore(data.mobilityCheck);
    this.mobilityDetails = StringField.fromFirestore(data.mobilityDetails);
    this.articularityCheck = NullableBooleanField.fromFirestore(data.articularityCheck);
    this.articularityDetails = StringField.fromFirestore(data.articularityDetails);
    this.musculatureCheck = NullableBooleanField.fromFirestore(data.musculatureCheck);
    this.musculatureDetails = StringField.fromFirestore(data.musculatureDetails);
    this.rachisCheck = NullableBooleanField.fromFirestore(data.rachisCheck);
    this.rachisDetails = StringField.fromFirestore(data.rachisDetails);
    this.lasegueCheck = NullableBooleanField.fromFirestore(data.lasegueCheck);
    this.lasegueDetails = StringField.fromFirestore(data.lasegueDetails);
    this.wassermanCheck = NullableBooleanField.fromFirestore(data.wassermanCheck);
    this.wassermanDetails = StringField.fromFirestore(data.wassermanDetails);
    this.mmCheck = NullableBooleanField.fromFirestore(data.mmCheck);
    this.mmDetails = StringField.fromFirestore(data.mmDetails);
    this.otherMusculoskeletalNotes = StringField.fromFirestore(data.otherMusculoskeletalNotes);
    this.sensitivityCheck = NullableBooleanField.fromFirestore(data.sensitivityCheck);
    this.sensitivityDetails = StringField.fromFirestore(data.sensitivityDetails);
    this.rotCheck = NullableBooleanField.fromFirestore(data.rotCheck);
    this.rotDetails = StringField.fromFirestore(data.rotDetails);
    this.humorCheck = NullableBooleanField.fromFirestore(data.humorCheck);
    this.humorDetails = StringField.fromFirestore(data.humorDetails);
    this.tinelCheck = NullableBooleanField.fromFirestore(data.tinelCheck);
    this.tinelDetails = StringField.fromFirestore(data.tinelDetails);
    this.phalenCheck = NullableBooleanField.fromFirestore(data.phalenCheck);
    this.phalenDetails = StringField.fromFirestore(data.phalenDetails);
    this.hearingNotes = StringField.fromFirestore(data.hearingNotes);
    this.sightNotes = StringField.fromFirestore(data.sightNotes);
    this.giordanoCheck = NullableBooleanField.fromFirestore(data.giordanoCheck);
    this.giordanoDetails = StringField.fromFirestore(data.giordanoDetails);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.weight = NullableNumberField.toFirestore(this.weight);
    firestoreData.height = NullableNumberField.toFirestore(this.height);
    firestoreData.generalCondition = StringField.toFirestore(this.generalCondition);
    firestoreData.skinCheck = NullableBooleanField.toFirestore(this.skinCheck);
    firestoreData.skinDetails = StringField.toFirestore(this.skinDetails);
    firestoreData.mucousMembranesCheck = NullableBooleanField.toFirestore(this.mucousMembranesCheck);
    firestoreData.mucousMembranesDetails = StringField.toFirestore(this.mucousMembranesDetails);
    firestoreData.annexesCheck = NullableBooleanField.toFirestore(this.annexesCheck);
    firestoreData.annexesDetails = StringField.toFirestore(this.annexesDetails);
    firestoreData.lymphGlandsCheck = NullableBooleanField.toFirestore(this.lymphGlandsCheck);
    firestoreData.lymphGlandsDetails = StringField.toFirestore(this.lymphGlandsDetails);
    firestoreData.headNeckCheck = NullableBooleanField.toFirestore(this.headNeckCheck);
    firestoreData.headNeckDetails = StringField.toFirestore(this.headNeckDetails);
    firestoreData.thyroidCheck = NullableBooleanField.toFirestore(this.thyroidCheck);
    firestoreData.thyroidDetails = StringField.toFirestore(this.thyroidDetails);
    firestoreData.chestCheck = NullableBooleanField.toFirestore(this.chestCheck);
    firestoreData.chestDetails = StringField.toFirestore(this.chestDetails);
    firestoreData.expandabilityCheck = NullableBooleanField.toFirestore(this.expandabilityCheck);
    firestoreData.expandabilityDetails = StringField.toFirestore(this.expandabilityDetails);
    firestoreData.fvtCheck = NullableBooleanField.toFirestore(this.fvtCheck);
    firestoreData.fvtDetails = StringField.toFirestore(this.fvtDetails);
    firestoreData.percussionCheck = NullableBooleanField.toFirestore(this.percussionCheck);
    firestoreData.percussionDetails = StringField.toFirestore(this.percussionDetails);
    firestoreData.mvCheck = NullableBooleanField.toFirestore(this.mvCheck);
    firestoreData.mvDetails = StringField.toFirestore(this.mvDetails);
    firestoreData.pao = StringField.toFirestore(this.pao);
    firestoreData.fc = StringField.toFirestore(this.fc);
    firestoreData.tonesCheck = NullableBooleanField.toFirestore(this.tonesCheck);
    firestoreData.tonesDetails = StringField.toFirestore(this.tonesDetails);
    firestoreData.breaksCheck = NullableBooleanField.toFirestore(this.breaksCheck);
    firestoreData.breaksDetails = StringField.toFirestore(this.breaksDetails);
    firestoreData.wristsCheck = NullableBooleanField.toFirestore(this.wristsCheck);
    firestoreData.wristsDetails = StringField.toFirestore(this.wristsDetails);
    firestoreData.varicesCheck = NullableBooleanField.toFirestore(this.varicesCheck);
    firestoreData.varicesDetails = StringField.toFirestore(this.varicesDetails);
    firestoreData.shapeCheck = NullableBooleanField.toFirestore(this.shapeCheck);
    firestoreData.shapeDetails = StringField.toFirestore(this.shapeDetails);
    firestoreData.palpationCheck = NullableBooleanField.toFirestore(this.palpationCheck);
    firestoreData.palpationDetails = StringField.toFirestore(this.palpationDetails);
    firestoreData.listeningCheck = NullableBooleanField.toFirestore(this.listeningCheck);
    firestoreData.listeningDetails = StringField.toFirestore(this.listeningDetails);
    firestoreData.liverCheck = NullableBooleanField.toFirestore(this.liverCheck);
    firestoreData.liverDetails = StringField.toFirestore(this.liverDetails);
    firestoreData.spleenCheck = NullableBooleanField.toFirestore(this.spleenCheck);
    firestoreData.spleenDetails = StringField.toFirestore(this.spleenDetails);
    firestoreData.otherAbdomenNotes = StringField.toFirestore(this.otherAbdomenNotes);
    firestoreData.mobilityCheck = NullableBooleanField.toFirestore(this.mobilityCheck);
    firestoreData.mobilityDetails = StringField.toFirestore(this.mobilityDetails);
    firestoreData.articularityCheck = NullableBooleanField.toFirestore(this.articularityCheck);
    firestoreData.articularityDetails = StringField.toFirestore(this.articularityDetails);
    firestoreData.musculatureCheck = NullableBooleanField.toFirestore(this.musculatureCheck);
    firestoreData.musculatureDetails = StringField.toFirestore(this.musculatureDetails);
    firestoreData.rachisCheck = NullableBooleanField.toFirestore(this.rachisCheck);
    firestoreData.rachisDetails = StringField.toFirestore(this.rachisDetails);
    firestoreData.lasegueCheck = NullableBooleanField.toFirestore(this.lasegueCheck);
    firestoreData.lasegueDetails = StringField.toFirestore(this.lasegueDetails);
    firestoreData.wassermanCheck = NullableBooleanField.toFirestore(this.wassermanCheck);
    firestoreData.wassermanDetails = StringField.toFirestore(this.wassermanDetails);
    firestoreData.mmCheck = NullableBooleanField.toFirestore(this.mmCheck);
    firestoreData.mmDetails = StringField.toFirestore(this.mmDetails);
    firestoreData.otherMusculoskeletalNotes = StringField.toFirestore(this.otherMusculoskeletalNotes);
    firestoreData.sensitivityCheck = NullableBooleanField.toFirestore(this.sensitivityCheck);
    firestoreData.sensitivityDetails = StringField.toFirestore(this.sensitivityDetails);
    firestoreData.rotCheck = NullableBooleanField.toFirestore(this.rotCheck);
    firestoreData.rotDetails = StringField.toFirestore(this.rotDetails);
    firestoreData.humorCheck = NullableBooleanField.toFirestore(this.humorCheck);
    firestoreData.humorDetails = StringField.toFirestore(this.humorDetails);
    firestoreData.tinelCheck = NullableBooleanField.toFirestore(this.tinelCheck);
    firestoreData.tinelDetails = StringField.toFirestore(this.tinelDetails);
    firestoreData.phalenCheck = NullableBooleanField.toFirestore(this.phalenCheck);
    firestoreData.phalenDetails = StringField.toFirestore(this.phalenDetails);
    firestoreData.hearingNotes = StringField.toFirestore(this.hearingNotes);
    firestoreData.sightNotes = StringField.toFirestore(this.sightNotes);
    firestoreData.giordanoCheck = NullableBooleanField.toFirestore(this.giordanoCheck);
    firestoreData.giordanoDetails = StringField.toFirestore(this.giordanoDetails);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): PhysicalExam {
    this.weight = NullableNumberField.fromOfflineCache(data.weight);
    this.height = NullableNumberField.fromOfflineCache(data.height);
    this.generalCondition = StringField.fromOfflineCache(data.generalCondition);
    this.skinCheck = NullableBooleanField.fromOfflineCache(data.skinCheck);
    this.skinDetails = StringField.fromOfflineCache(data.skinDetails);
    this.mucousMembranesCheck = NullableBooleanField.fromOfflineCache(data.mucousMembranesCheck);
    this.mucousMembranesDetails = StringField.fromOfflineCache(data.mucousMembranesDetails);
    this.annexesCheck = NullableBooleanField.fromOfflineCache(data.annexesCheck);
    this.annexesDetails = StringField.fromOfflineCache(data.annexesDetails);
    this.lymphGlandsCheck = NullableBooleanField.fromOfflineCache(data.lymphGlandsCheck);
    this.lymphGlandsDetails = StringField.fromOfflineCache(data.lymphGlandsDetails);
    this.headNeckCheck = NullableBooleanField.fromOfflineCache(data.headNeckCheck);
    this.headNeckDetails = StringField.fromOfflineCache(data.headNeckDetails);
    this.thyroidCheck = NullableBooleanField.fromOfflineCache(data.thyroidCheck);
    this.thyroidDetails = StringField.fromOfflineCache(data.thyroidDetails);
    this.chestCheck = NullableBooleanField.fromOfflineCache(data.chestCheck);
    this.chestDetails = StringField.fromOfflineCache(data.chestDetails);
    this.expandabilityCheck = NullableBooleanField.fromOfflineCache(data.expandabilityCheck);
    this.expandabilityDetails = StringField.fromOfflineCache(data.expandabilityDetails);
    this.fvtCheck = NullableBooleanField.fromOfflineCache(data.fvtCheck);
    this.fvtDetails = StringField.fromOfflineCache(data.fvtDetails);
    this.percussionCheck = NullableBooleanField.fromOfflineCache(data.percussionCheck);
    this.percussionDetails = StringField.fromOfflineCache(data.percussionDetails);
    this.mvCheck = NullableBooleanField.fromOfflineCache(data.mvCheck);
    this.mvDetails = StringField.fromOfflineCache(data.mvDetails);
    this.pao = StringField.fromOfflineCache(data.pao);
    this.fc = StringField.fromOfflineCache(data.fc);
    this.tonesCheck = NullableBooleanField.fromOfflineCache(data.tonesCheck);
    this.tonesDetails = StringField.fromOfflineCache(data.tonesDetails);
    this.breaksCheck = NullableBooleanField.fromOfflineCache(data.breaksCheck);
    this.breaksDetails = StringField.fromOfflineCache(data.breaksDetails);
    this.wristsCheck = NullableBooleanField.fromOfflineCache(data.wristsCheck);
    this.wristsDetails = StringField.fromOfflineCache(data.wristsDetails);
    this.varicesCheck = NullableBooleanField.fromOfflineCache(data.varicesCheck);
    this.varicesDetails = StringField.fromOfflineCache(data.varicesDetails);
    this.shapeCheck = NullableBooleanField.fromOfflineCache(data.shapeCheck);
    this.shapeDetails = StringField.fromOfflineCache(data.shapeDetails);
    this.palpationCheck = NullableBooleanField.fromOfflineCache(data.palpationCheck);
    this.palpationDetails = StringField.fromOfflineCache(data.palpationDetails);
    this.listeningCheck = NullableBooleanField.fromOfflineCache(data.listeningCheck);
    this.listeningDetails = StringField.fromOfflineCache(data.listeningDetails);
    this.liverCheck = NullableBooleanField.fromOfflineCache(data.liverCheck);
    this.liverDetails = StringField.fromOfflineCache(data.liverDetails);
    this.spleenCheck = NullableBooleanField.fromOfflineCache(data.spleenCheck);
    this.spleenDetails = StringField.fromOfflineCache(data.spleenDetails);
    this.otherAbdomenNotes = StringField.fromOfflineCache(data.otherAbdomenNotes);
    this.mobilityCheck = NullableBooleanField.fromOfflineCache(data.mobilityCheck);
    this.mobilityDetails = StringField.fromOfflineCache(data.mobilityDetails);
    this.articularityCheck = NullableBooleanField.fromOfflineCache(data.articularityCheck);
    this.articularityDetails = StringField.fromOfflineCache(data.articularityDetails);
    this.musculatureCheck = NullableBooleanField.fromOfflineCache(data.musculatureCheck);
    this.musculatureDetails = StringField.fromOfflineCache(data.musculatureDetails);
    this.rachisCheck = NullableBooleanField.fromOfflineCache(data.rachisCheck);
    this.rachisDetails = StringField.fromOfflineCache(data.rachisDetails);
    this.lasegueCheck = NullableBooleanField.fromOfflineCache(data.lasegueCheck);
    this.lasegueDetails = StringField.fromOfflineCache(data.lasegueDetails);
    this.wassermanCheck = NullableBooleanField.fromOfflineCache(data.wassermanCheck);
    this.wassermanDetails = StringField.fromOfflineCache(data.wassermanDetails);
    this.mmCheck = NullableBooleanField.fromOfflineCache(data.mmCheck);
    this.mmDetails = StringField.fromOfflineCache(data.mmDetails);
    this.otherMusculoskeletalNotes = StringField.fromOfflineCache(data.otherMusculoskeletalNotes);
    this.sensitivityCheck = NullableBooleanField.fromOfflineCache(data.sensitivityCheck);
    this.sensitivityDetails = StringField.fromOfflineCache(data.sensitivityDetails);
    this.rotCheck = NullableBooleanField.fromOfflineCache(data.rotCheck);
    this.rotDetails = StringField.fromOfflineCache(data.rotDetails);
    this.humorCheck = NullableBooleanField.fromOfflineCache(data.humorCheck);
    this.humorDetails = StringField.fromOfflineCache(data.humorDetails);
    this.tinelCheck = NullableBooleanField.fromOfflineCache(data.tinelCheck);
    this.tinelDetails = StringField.fromOfflineCache(data.tinelDetails);
    this.phalenCheck = NullableBooleanField.fromOfflineCache(data.phalenCheck);
    this.phalenDetails = StringField.fromOfflineCache(data.phalenDetails);
    this.hearingNotes = StringField.fromOfflineCache(data.hearingNotes);
    this.sightNotes = StringField.fromOfflineCache(data.sightNotes);
    this.giordanoCheck = NullableBooleanField.fromOfflineCache(data.giordanoCheck);
    this.giordanoDetails = StringField.fromOfflineCache(data.giordanoDetails);
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.weight = NullableNumberField.toOfflineCache(this.weight);
    firestoreData.height = NullableNumberField.toOfflineCache(this.height);
    firestoreData.generalCondition = StringField.toOfflineCache(this.generalCondition);
    firestoreData.skinCheck = NullableBooleanField.toOfflineCache(this.skinCheck);
    firestoreData.skinDetails = StringField.toOfflineCache(this.skinDetails);
    firestoreData.mucousMembranesCheck = NullableBooleanField.toOfflineCache(this.mucousMembranesCheck);
    firestoreData.mucousMembranesDetails = StringField.toOfflineCache(this.mucousMembranesDetails);
    firestoreData.annexesCheck = NullableBooleanField.toOfflineCache(this.annexesCheck);
    firestoreData.annexesDetails = StringField.toOfflineCache(this.annexesDetails);
    firestoreData.lymphGlandsCheck = NullableBooleanField.toOfflineCache(this.lymphGlandsCheck);
    firestoreData.lymphGlandsDetails = StringField.toOfflineCache(this.lymphGlandsDetails);
    firestoreData.headNeckCheck = NullableBooleanField.toOfflineCache(this.headNeckCheck);
    firestoreData.headNeckDetails = StringField.toOfflineCache(this.headNeckDetails);
    firestoreData.thyroidCheck = NullableBooleanField.toOfflineCache(this.thyroidCheck);
    firestoreData.thyroidDetails = StringField.toOfflineCache(this.thyroidDetails);
    firestoreData.chestCheck = NullableBooleanField.toOfflineCache(this.chestCheck);
    firestoreData.chestDetails = StringField.toOfflineCache(this.chestDetails);
    firestoreData.expandabilityCheck = NullableBooleanField.toOfflineCache(this.expandabilityCheck);
    firestoreData.expandabilityDetails = StringField.toOfflineCache(this.expandabilityDetails);
    firestoreData.fvtCheck = NullableBooleanField.toOfflineCache(this.fvtCheck);
    firestoreData.fvtDetails = StringField.toOfflineCache(this.fvtDetails);
    firestoreData.percussionCheck = NullableBooleanField.toOfflineCache(this.percussionCheck);
    firestoreData.percussionDetails = StringField.toOfflineCache(this.percussionDetails);
    firestoreData.mvCheck = NullableBooleanField.toOfflineCache(this.mvCheck);
    firestoreData.mvDetails = StringField.toOfflineCache(this.mvDetails);
    firestoreData.pao = StringField.toOfflineCache(this.pao);
    firestoreData.fc = StringField.toOfflineCache(this.fc);
    firestoreData.tonesCheck = NullableBooleanField.toOfflineCache(this.tonesCheck);
    firestoreData.tonesDetails = StringField.toOfflineCache(this.tonesDetails);
    firestoreData.breaksCheck = NullableBooleanField.toOfflineCache(this.breaksCheck);
    firestoreData.breaksDetails = StringField.toOfflineCache(this.breaksDetails);
    firestoreData.wristsCheck = NullableBooleanField.toOfflineCache(this.wristsCheck);
    firestoreData.wristsDetails = StringField.toOfflineCache(this.wristsDetails);
    firestoreData.varicesCheck = NullableBooleanField.toOfflineCache(this.varicesCheck);
    firestoreData.varicesDetails = StringField.toOfflineCache(this.varicesDetails);
    firestoreData.shapeCheck = NullableBooleanField.toOfflineCache(this.shapeCheck);
    firestoreData.shapeDetails = StringField.toOfflineCache(this.shapeDetails);
    firestoreData.palpationCheck = NullableBooleanField.toOfflineCache(this.palpationCheck);
    firestoreData.palpationDetails = StringField.toOfflineCache(this.palpationDetails);
    firestoreData.listeningCheck = NullableBooleanField.toOfflineCache(this.listeningCheck);
    firestoreData.listeningDetails = StringField.toOfflineCache(this.listeningDetails);
    firestoreData.liverCheck = NullableBooleanField.toOfflineCache(this.liverCheck);
    firestoreData.liverDetails = StringField.toOfflineCache(this.liverDetails);
    firestoreData.spleenCheck = NullableBooleanField.toOfflineCache(this.spleenCheck);
    firestoreData.spleenDetails = StringField.toOfflineCache(this.spleenDetails);
    firestoreData.otherAbdomenNotes = StringField.toOfflineCache(this.otherAbdomenNotes);
    firestoreData.mobilityCheck = NullableBooleanField.toOfflineCache(this.mobilityCheck);
    firestoreData.mobilityDetails = StringField.toOfflineCache(this.mobilityDetails);
    firestoreData.articularityCheck = NullableBooleanField.toOfflineCache(this.articularityCheck);
    firestoreData.articularityDetails = StringField.toOfflineCache(this.articularityDetails);
    firestoreData.musculatureCheck = NullableBooleanField.toOfflineCache(this.musculatureCheck);
    firestoreData.musculatureDetails = StringField.toOfflineCache(this.musculatureDetails);
    firestoreData.rachisCheck = NullableBooleanField.toOfflineCache(this.rachisCheck);
    firestoreData.rachisDetails = StringField.toOfflineCache(this.rachisDetails);
    firestoreData.lasegueCheck = NullableBooleanField.toOfflineCache(this.lasegueCheck);
    firestoreData.lasegueDetails = StringField.toOfflineCache(this.lasegueDetails);
    firestoreData.wassermanCheck = NullableBooleanField.toOfflineCache(this.wassermanCheck);
    firestoreData.wassermanDetails = StringField.toOfflineCache(this.wassermanDetails);
    firestoreData.mmCheck = NullableBooleanField.toOfflineCache(this.mmCheck);
    firestoreData.mmDetails = StringField.toOfflineCache(this.mmDetails);
    firestoreData.otherMusculoskeletalNotes = StringField.toOfflineCache(this.otherMusculoskeletalNotes);
    firestoreData.sensitivityCheck = NullableBooleanField.toOfflineCache(this.sensitivityCheck);
    firestoreData.sensitivityDetails = StringField.toOfflineCache(this.sensitivityDetails);
    firestoreData.rotCheck = NullableBooleanField.toOfflineCache(this.rotCheck);
    firestoreData.rotDetails = StringField.toOfflineCache(this.rotDetails);
    firestoreData.humorCheck = NullableBooleanField.toOfflineCache(this.humorCheck);
    firestoreData.humorDetails = StringField.toOfflineCache(this.humorDetails);
    firestoreData.tinelCheck = NullableBooleanField.toOfflineCache(this.tinelCheck);
    firestoreData.tinelDetails = StringField.toOfflineCache(this.tinelDetails);
    firestoreData.phalenCheck = NullableBooleanField.toOfflineCache(this.phalenCheck);
    firestoreData.phalenDetails = StringField.toOfflineCache(this.phalenDetails);
    firestoreData.hearingNotes = StringField.toOfflineCache(this.hearingNotes);
    firestoreData.sightNotes = StringField.toOfflineCache(this.sightNotes);
    firestoreData.giordanoCheck = NullableBooleanField.toOfflineCache(this.giordanoCheck);
    firestoreData.giordanoDetails = StringField.toOfflineCache(this.giordanoDetails);
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
