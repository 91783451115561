import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { DateField, DateStrictField, NumberField } from "@/core/fields";

export class InvoiceDeadline {
  public id: string = DataHelpers.uniqueId();
  public amount = 0;
  public amountPercentage = 0;
  public date: Date = new Date();
  public daysToExpiration = 0;
  public paid: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): InvoiceDeadline {
    this.id = DataHelpers.uniqueId();

    this.amount = NumberField.fromFirestore(data.amount);
    this.amountPercentage = NumberField.fromFirestore(data.amountPercentage);
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.daysToExpiration = NumberField.fromFirestore(data.daysToExpiration);
    this.paid = DateField.fromFirestore(data.paid);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.amount = NumberField.toFirestore(this.amount);
    firestoreData.amountPercentage = NumberField.toFirestore(this.amountPercentage);
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.daysToExpiration = NumberField.toFirestore(this.daysToExpiration);
    firestoreData.paid = DateField.toFirestore(this.paid);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): InvoiceDeadline {
    this.id = DataHelpers.uniqueId();

    this.date = DateStrictField.fromOfflineCache(data.date, new Date());
    this.daysToExpiration = NumberField.fromOfflineCache(data.daysToExpiration);
    this.amount = NumberField.fromOfflineCache(data.amount);
    this.amountPercentage = NumberField.fromOfflineCache(data.amountPercentage);
    this.paid = DateField.fromOfflineCache(data.paid);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.date = DateStrictField.toOfflineCache(this.date);
    firestoreData.daysToExpiration = NumberField.toOfflineCache(this.daysToExpiration);
    firestoreData.amount = NumberField.toOfflineCache(this.amount);
    firestoreData.amountPercentage = NumberField.toOfflineCache(this.amountPercentage);
    firestoreData.paid = DateField.toOfflineCache(this.paid);

    return firestoreData;
  }
}
