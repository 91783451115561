import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Hint } from "../objects/Hint";
import { hintModel } from "./HintModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class HintOfflineModel extends OfflineModuleModel<Hint> {
  public constructor() {
    super(() => new Hint(), "hints", "hint.hints", undefined);
  }

  public async downloadCache(): Promise<Hint[]> {
    try {
      return hintModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("HintOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const hintOfflineModel: HintOfflineModel = new HintOfflineModel();
