import { createRouter, createWebHistory, Router } from "vue-router";

import { authModel } from "@/core/modules/auth/models/AuthModel";
import { basicRoutes } from "./objects/basicRoutes";
import { config } from "@/core/modules/config/objects/Config";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: basicRoutes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();

  if (to.path === "/maintenance") {
    if (config.app.maintenance === false) next("/");
  } else if (to.path !== "/login") {
    if (config.app.maintenance === true) {
      if (authModel.isLoggedIn()) {
        const user: User = store.getters[storeTypes.getters.getUser];
        if (user?.role?.name !== "Master") next("/maintenance");
      } else {
        next("/maintenance");
      }
    }
  }

  window.scrollTo(0, 0);

  store.commit(storeTypes.mutations.setPreviousRoute, from);

  if (to.name == "Error" || to.name == "NotFound" || to.name == "AccessDenied") {
    store.commit(storeTypes.mutations.editingStop);
  }

  if (store.getters[storeTypes.getters.isEditing]) {
    store.commit(storeTypes.mutations.openEditingDialog, to);
    return;
  }

  if (from.path != to.path) {
    store.commit(storeTypes.mutations.loadingStart);
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (authModel.isLoggedIn() && to.path == "/login") {
      next("/");
    } else if (authModel.isLoggedIn()) {
      const user: User = store.getters[storeTypes.getters.getUser];
      const requiredModule: string = to.meta.requiredModule as string;
      const requiredRight: string = to.meta.requiredRight as string;

      if (requiredModule === "" || requiredRight === "") {
        next();
      } else if (requiredRight === "viewUI" && user.canViewUI(requiredModule)) {
        next();
      } else if (requiredRight === "create" && user.canCreate(requiredModule)) {
        next();
      } else if (user.getModuleRight(requiredModule, requiredRight) !== undefined) {
        next();
      } else {
        next("/access-denied");
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export const useRouter = (): Router => {
  return router;
};
