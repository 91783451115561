import { RouteRecordRaw } from "vue-router";

export const dutyRoutes: RouteRecordRaw[] = [
  {
    path: "/duties/:id/edit",
    name: "DutyEdit",
    component: () => import("../views/DutyEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "duty",
      requiredRight: "viewUI",
    },
  },
];
