import { ExternalTest } from "./ExternalTest";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedSupplier } from "@/features/modules/supplier/objects/LinkedSupplier";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";

import { DateField, NumberField, ObjectField, StringStrictField } from "@/core/fields";

export class TinyLinkedExternalTest extends LinkedFirestoreDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public company: LinkedCompany | undefined = undefined;
  public employee: LinkedEmployee | undefined = undefined;
  public testType: LinkedTestType | undefined = undefined;
  public supplier: LinkedSupplier | undefined = undefined;
  public date: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromExternalTest(externalTest: ExternalTest): TinyLinkedExternalTest {
    const tinyLinkedExternalTest: TinyLinkedExternalTest = new TinyLinkedExternalTest();
    tinyLinkedExternalTest.id = externalTest.id;
    tinyLinkedExternalTest.code = externalTest.code;
    tinyLinkedExternalTest.codeSort = externalTest.codeSort;
    tinyLinkedExternalTest.codeDisplay = externalTest.codeDisplay;
    tinyLinkedExternalTest.year = externalTest.year;
    tinyLinkedExternalTest.company = externalTest.company;
    tinyLinkedExternalTest.employee = externalTest.employee;
    tinyLinkedExternalTest.testType = externalTest.testType;
    tinyLinkedExternalTest.supplier = externalTest.supplier;
    tinyLinkedExternalTest.date = externalTest.date;

    return tinyLinkedExternalTest;
  }

  public fromFirestore(data: Record<string, unknown>): TinyLinkedExternalTest {
    super.fromFirestore(data);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.employee = ObjectField.fromFirestore<LinkedEmployee>(data.employee, (value) => new LinkedEmployee(value));
    this.testType = ObjectField.fromFirestore<LinkedTestType>(data.testType, (value) => new LinkedTestType(value));
    this.supplier = ObjectField.fromFirestore<LinkedSupplier>(data.supplier, (value) => new LinkedSupplier(value));
    this.date = DateField.fromFirestore(data.date);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.employee = ObjectField.toFirestore<LinkedEmployee>(this.employee, (value) => value.toFirestore());
    firestoreData.testType = ObjectField.toFirestore<LinkedTestType>(this.testType, (value) => value.toFirestore());
    firestoreData.supplier = ObjectField.toFirestore<LinkedSupplier>(this.supplier, (value) => value.toFirestore());
    firestoreData.date = DateField.toFirestore(this.date);

    return firestoreData;
  }
}
