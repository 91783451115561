
import { defineComponent } from "vue";

import { DomHandler, ZIndexUtils } from "primevue/utils";

import { config } from "@/core/modules/config/objects/Config";
import { koruApp } from "@/main";

export default defineComponent({
  name: "KoruBlock",
  emits: ["block", "unblock"],
  props: {
    blocked: {
      type: Boolean,
      default: false,
    },
    baseZIndex: {
      type: Number,
      default: 0,
    },
    autoZIndex: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const mask: HTMLElement = null as unknown as HTMLElement;

    const loadingLogoUrl: string =
      config.app.logos.loadingImage === "features" ? "/assets/features/images/logo-white.svg" : "/assets/core/images/koru-logo-white.svg";

    return {
      config,
      loadingLogoUrl,
      mask,
    };
  },
  mounted(): void {
    if (this.blocked) {
      this.block();
    }
  },
  watch: {
    blocked(newValue: boolean): void {
      if (newValue === true) {
        this.block();
      } else {
        this.unblock();
      }
    },
  },
  methods: {
    block(): void {
      this.mask = document.createElement("div");
      this.mask.innerHTML = `<div class="koru-loading"><img src="${this.loadingLogoUrl}" alt="" /></div>`;
      this.mask.setAttribute("class", "p-blockui p-blockui-document");
      document.body.appendChild(this.mask);
      DomHandler.addClass(document.body, "p-overflow-hidden");
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      if (this.mask) {
        setTimeout(() => {
          DomHandler.addClass(this.mask, "p-component-overlay");
        }, 1);
      }

      if (this.autoZIndex) {
        ZIndexUtils.set("modal", this.mask, this.baseZIndex + (koruApp.config.globalProperties.$primevue.config.zIndex?.modal ?? 0));
      }

      this.$emit("block");
    },
    unblock() {
      if (this.mask != null) {
        DomHandler.addClass(this.mask, "p-blockui-leave");
        this.removeMask();
      }
    },
    removeMask() {
      ZIndexUtils.clear(this.mask);
      document.body.removeChild(this.mask);
      DomHandler.removeClass(document.body, "p-overflow-hidden");

      this.$emit("unblock");
    },
  },
});
