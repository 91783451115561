export const en = {
  notification: {
    alert: "You have {count} unread notification | You have {count} unread notifications",
    none: "No unread notification",
    notifications: "Notifications",
    test: {
      groupName: "Test group",
      message: "Test notification",
    },
  },
  buttons: {
    notificationGoToUrl: "Go to page",
  },
};
