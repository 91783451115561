import { RouteRecordRaw } from "vue-router";

export const examinationSessionRoutes: RouteRecordRaw[] = [
  {
    path: "/examination-sessions",
    name: "ExaminationSessionList",
    component: () => import("../views/ExaminationSessionList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examinationSession",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/examination-sessions/create/:companyId/:branchId/:expirationDate",
    name: "ExaminationSessionCreate",
    component: () => import("../views/ExaminationSessionCreate.vue"),
    props: (route) => ({
      companyId: route.params.companyId,
      branchId: route.params.branchId,
      expirationDate: route.params.expirationDate,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "examinationSession",
      requiredRight: "create",
    },
  },
  {
    path: "/examination-sessions/:id/edit",
    name: "ExaminationSessionEdit",
    component: () => import("../views/ExaminationSessionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examinationSession",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/examination-sessions/:id/:section?",
    name: "ExaminationSessionView",
    component: () => import("../views/ExaminationSessionView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examinationSession",
      requiredRight: "viewUI",
    },
  },
];
