import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationCount } from "../objects/ExaminationCount";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { Contract } from "@/features/modules/contract/objects/Contract";
import { contractModel } from "@/features/modules/contract/models/ContractModel";

export class ExaminationCounterModel {
  public async countExaminations(startDate: Date, endDate: Date): Promise<ExaminationCount[]> {
    try {
      if (startDate === undefined) throw new Error("periodUndefined");
      if (endDate === undefined) throw new Error("periodUndefined");

      const examinationCounts: ExaminationCount[] = [];

      const companies: Company[] = await companyModel.getActiveCompanies();

      let companyIndex = 1;
      const companiesWithContract: Company[] = [];
      for (const company of companies) {
        console.log(`${companyIndex++}/${companies.length}`);

        const contracts: Contract[] = await contractModel.getContractsByFirmAndCompany(company.id);
        if (contracts.length === 0) continue;

        for (const contract of contracts) {
          if (contract.from !== undefined && contract.to !== undefined) {
            if (compareAsc(contract.from, startDate) < 0 && compareAsc(contract.to, startDate) < 0) {
              continue;
            } else if (compareAsc(contract.from, endDate) > 0 && compareAsc(contract.to, endDate) > 0) {
              continue;
            }
          } else if (contract.from === undefined && contract.to !== undefined && compareAsc(startDate, contract.to) < 0) {
            continue;
          } else if (contract.to === undefined && contract.from !== undefined && compareAsc(endDate, contract.from) < 0) {
            continue;
          }

          companiesWithContract.push(company);
          break;
        }
      }

      const examinations: Examination[] = await examinationModel.getExaminationsWithFilters(startDate, endDate, "all", "all", "all", "all");

      for (const company of companiesWithContract) {
        const companyExaminations: Examination[] = examinations.filter(
          (examination: Examination) =>
            examination.company?.id === company.id &&
            (examination.state === ExaminationState.Completed || examination.state === ExaminationState.Billed)
        );
        const examinationCount: ExaminationCount = new ExaminationCount(company, companyExaminations.length);
        examinationCounts.push(examinationCount);
      }

      return examinationCounts;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExaminationCounterModel.countExaminations", { startDate, endDate }, error);
      return [];
    }
  }
}

export const examinationCounterModel: ExaminationCounterModel = new ExaminationCounterModel();
