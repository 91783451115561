export const fr = {
  changelog: {
    addItem: "Ajouter un élément",
    changelog: "Notes de version",
    date: "Date",
    dateError: "Veuillez entrer une date valide",
    empty: "Aucune note de version à afficher",
    item: "Article",
    itemAdded: "Ajouté",
    itemChanged: "Modifié",
    itemFixed: "Correct",
    itemRemoved: "Supprimé",
    newItem: "Nouvel élément",
    public: "Public",
    release: "Libérer",
    releasedOn: "Libéré",
    tabGeneral: "Général",
    version: "Version",
    versionError: "Entrez la version",
  },
  navigation: {
    changelog: "Notes de version",
  },
};
