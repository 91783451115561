import { RouteRecordRaw } from "vue-router";

import { BrokerDocHelpers } from "../helpers/BrokerDocHelpers";
import { brokerDocModel } from "../models/BrokerDocModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";

export const brokerDocRoutes: RouteRecordRaw[] = [
  {
    path: "/brokers/:sourceId/docs/:docId/edit",
    name: "BrokerDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: BrokerDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Broker,
      sourceModel: brokerDocModel,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "brokerDoc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/brokers/:sourceId/docs/:docId",
    name: "BrokerDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      filesPath: BrokerDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Broker,
      sourceId: route.params.sourceId,
      sourceModel: brokerDocModel,
      sourcePath: "brokers",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "brokerDoc",
      requiredRight: "viewUI",
    },
  },
];
