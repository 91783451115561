import { RouteRecordRaw } from "vue-router";

export const changelogRoutes: RouteRecordRaw[] = [
  {
    path: "/changelog",
    name: "ChangelogList",
    component: () => import("../views/ChangelogList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "changelog",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/changelog/:id/edit",
    name: "ChangelogEdit",
    component: () => import("../views/ChangelogEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "changelog",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/changelog-view",
    name: "ChangelogView",
    component: () => import("../views/ChangelogView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRight: "",
    },
  },
];
