import { Ref } from "vue";

import { Examination } from "../objects/Examination";
import { JudgmentState } from "../objects/JudgmentState";
import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";

import { getFolderPath, getListContextCommands, judgmentChanged, printMergedAttachments, renderEndingTags } from "./methods";

export class ExaminationHelpers {
  public static getFolderPath(
    firmId: string | undefined,
    companyId: string | undefined,
    employeeId: string | undefined,
    examinationId: string | undefined
  ): string {
    return getFolderPath(firmId, companyId, employeeId, examinationId);
  }

  public static getListContextCommands(
    documents: Ref<Examination[]>,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): ContextCommand[] {
    return getListContextCommands(documents, d, n, t);
  }

  public static judgmentChanged(examinationRef: Ref<Examination>, lastJudgment: JudgmentState, fromLastJudgment = false): JudgmentState {
    return judgmentChanged(examinationRef, lastJudgment, fromLastJudgment);
  }

  public static async printMergedAttachments(
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<void> {
    return printMergedAttachments(examination, d, n, t);
  }

  public static renderEndingTags(): Record<string, unknown>[] {
    return renderEndingTags();
  }
}
