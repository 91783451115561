import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { User } from "./User";

import { StringField } from "@/core/fields";

export class LinkedUser extends LinkedFirestoreDocument {
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public fullNameReversed: string | undefined = undefined;
  public email: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromUser(user: User): LinkedUser {
    const linkedUser: LinkedUser = new LinkedUser();
    linkedUser.id = user.id;
    linkedUser.firstName = user.firstName;
    linkedUser.lastName = user.lastName;
    linkedUser.fullName = user.fullName;
    linkedUser.fullNameReversed = user.fullNameReversed;
    linkedUser.email = user.email;

    return linkedUser;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedUser {
    super.fromFirestore(data);

    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.fullNameReversed = StringField.fromFirestore(data.fullNameReversed);
    this.email = StringField.fromFirestore(data.email);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.fullNameReversed = StringField.toFirestore(this.fullNameReversed);
    firestoreData.email = StringField.toFirestore(this.email);

    return firestoreData;
  }
}
