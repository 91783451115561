import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "../CompanyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/UserModel";

export const isEmailUnique = async (email: string, companyId = "empty", companyUserId = "empty"): Promise<boolean> => {
  try {
    if (email === undefined) return false;

    if (offlineModel.getOfflineState() === "offline") {
      if (companyModel.offlineModuleModel === undefined) return false;

      const user: User = store.getters[storeTypes.getters.getUser];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const companyDocuments: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];
      const filteredCompanyDocuments: Company[] = companyDocuments.filter((company) => company.email === email);
      const filteredCompanyDocumentsIds: string[] = filteredCompanyDocuments.map((company) => company.id);
      if (filteredCompanyDocuments.length > 1) return false;
      if (filteredCompanyDocuments.length === 1 && filteredCompanyDocumentsIds.includes(companyId) === false) return false;

      if (userModel.offlineModuleModel === undefined) return true;
      const userDocuments: User[] = (await offlineModel.getCacheByCollection(userModel.offlineModuleModel)) as User[];
      const filteredUserDocuments: User[] = userDocuments.filter((user) => user.email === email);
      const filteredUserDocumentsIds: string[] = filteredUserDocuments.map((user) => user.id);

      if (filteredUserDocuments.length > 1) return false;
      if (filteredUserDocuments.length === 1 && filteredUserDocumentsIds.includes(companyUserId) === false) return false;

      return true;
    }

    const companyResult: boolean = await CloudHelpers.runValidatorFunction("featuresCompanyEmailValidator", email, companyId);
    const userResult: boolean = await CloudHelpers.runValidatorFunction("coreUserEmailValidator", email, companyUserId);

    return companyResult && userResult;
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyModel.isEmailUnique", { email, companyId, companyUserId }, error);
    return false;
  }
};
