import { compareAsc, subDays } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { setting } from "@/core/modules/setting/Setting";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getLastCompletedExaminationsByDoctorAndFirm = async (doctorId?: string): Promise<Examination[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];
    const firmId: string = firmModel.getSelectedFirmId();

    const minDate: Date = subDays(new Date(), setting.getSetting<number>("lastExaminationsDaysNotice"));
    minDate.setHours(0, 0, 0, 0);

    if (offlineModel.getOfflineState() === "offline") {
      if (examinationModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      return documents.filter(
        (examination) =>
          examination.firm?.id === firmId &&
          compareAsc(examination.date, minDate) >= 0 &&
          examination.invoice === undefined &&
          examination.doctor?.id === user.id &&
          examination.state === ExaminationState.Completed
      );
    }

    if (doctorId === undefined) {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined) throw new Error(`User ${user.id} doesn't have a linked doctor document`);
      doctorId = doctor.id;
    }

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("doctor.id", "==", doctorId),
      where("date", ">=", Timestamp.fromDate(minDate))
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data()).filter((examination) => examination.state === ExaminationState.Completed);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getLastCompletedExaminationsByDoctorAndFirm", { doctorId }, error);
    return [];
  }
};
