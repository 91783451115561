import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { externalTestModel } from "../ExternalTestModel";
import { ExternalTestState } from "@/features/modules/externalTest/objects/ExternalTestState";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getExternalTestsToInvoiceByCompanyAndPeriodAndFirm = async (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<ExternalTest[]> => {
  try {
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const firmId: string = firmModel.getSelectedFirmId();

    if (offlineModel.getOfflineState() === "offline") return [];

    const snapshotQuery: Query<ExternalTest> = query(
      externalTestModel.getPathReference().withConverter(externalTestModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("invoice", "==", null),
      where("state", "in", [ExternalTestState.Completed, ExternalTestState.Included]),
      where("company.id", "==", companyId)
    );

    const snapshot: QuerySnapshot<ExternalTest> = await getDocs(externalTestModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data())).filter((externalTest) => externalTest.supplier !== undefined);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExternalTestModel.getExternalTestsToInvoiceByCompanyAndPeriodAndFirm", { companyId, startDate, endDate }, error);
    return [];
  }
};

function sortDocuments(documents: ExternalTest[]): ExternalTest[] {
  const sorter: FirestoreSorter<ExternalTest> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "string");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
