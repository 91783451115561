export const it = {
  history: {
    actions: {
      browse: "Sfoglia",
      create: "Crea",
      edit: "Modifica",
      search: "Cerca",
      view: "Visualizza",
    },
    history: "Cronologia",
    noHistory: "La cronologia è vuota",
  },
};
