import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { User } from "@/core/modules/user/objects/User";

export const getExaminationsForLegalRecord = async (companyId: string, branchId: string, doctorId: string, year: number): Promise<Examination[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];
    const firmId: string = firmModel.getSelectedFirmId();

    if (offlineModel.getOfflineState() === "offline") throw new Error("offline state not supported");

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(companyId) === false)
        throw new Error(`User doesn't have rights to read company #${companyId}`);
    }

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("company.id", "==", companyId),
      where("branch.id", "==", branchId),
      where("doctor.id", "==", doctorId),
      where("year", "==", year),
      where("state", "!=", ExaminationState.Draft)
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsForLegalRecord", { companyId, branchId, doctorId, year }, error);
    return [];
  }
};

function sortDocuments(documents: Examination[]): Examination[] {
  const sorter: FirestoreSorter<Examination> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "date");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
