import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { EmployeeSex } from "./EmployeeSex";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedBranchWithStatus } from "@/features/modules/branch/objects/LinkedBranchWithStatus";
import { LinkedCompanyWithStatus } from "@/features/modules/company/objects/LinkedCompanyWithStatus";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { AddressField, ArrayByKeyField, BooleanField, DateField, EnumField, NumberField, StringField } from "@/core/fields";
import { NextExaminationDatesRecord } from "@/features/fields";

export class Employee extends FirestoreOfflineDocument {
  public companies: Record<string, LinkedCompanyWithStatus> = {};
  public branches: Record<string, LinkedBranchWithStatus> = {};
  public lastName: string | undefined = undefined;
  public firstName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public birthDate: Date | undefined = undefined;
  public birthMonth: number | undefined = undefined;
  public birthPlace: string | undefined = undefined;
  public sex: EmployeeSex = EmployeeSex.Male;
  public fiscalCode: string | undefined = undefined;
  public nationality: string | undefined = "Italiana";
  public address: AddressField = new AddressField();
  public phone: string | undefined = undefined;
  public email: string | undefined = undefined;
  public doctorName: string | undefined = undefined;
  public doctorAddress: AddressField = new AddressField();
  public doctorPhone: string | undefined = undefined;
  public notes: string | undefined = undefined;
  public nextExaminationDates: NextExaminationDatesRecord = new NextExaminationDatesRecord();
  public isExaminationExempted = false;
  public trackExpirations = true;

  public oldDutyName: string | undefined = undefined;
  public oldBranchName: string | undefined = undefined;
  public oldFolderName: string | undefined = undefined;

  public nextExaminationSort = 0;
  public activeDutiesSort = 0;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Employee {
    super.fromFirestore(data, id, firestoreRef);

    this.companies = ArrayByKeyField.fromFirestore<LinkedCompanyWithStatus>(data.companies, (value) => new LinkedCompanyWithStatus(value));
    this.branches = ArrayByKeyField.fromFirestore<LinkedBranchWithStatus>(data.branches, (value) => new LinkedBranchWithStatus(value));
    this.lastName = StringField.fromFirestore(data.lastName);
    this.firstName = StringField.fromFirestore(data.firstName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.birthDate = DateField.fromFirestore(data.birthDate);
    this.birthMonth = NumberField.fromFirestore(data.birthMonth);
    this.birthPlace = StringField.fromFirestore(data.birthPlace);
    this.sex = EnumField.fromFirestore<EmployeeSex>(data.sex, Object.values(EmployeeSex), EmployeeSex.Male);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.nationality = StringField.fromFirestore(data.nationality);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.email = StringField.fromFirestore(data.email);
    this.doctorName = StringField.fromFirestore(data.doctorName);
    this.doctorAddress.fromFirestore(data.doctorAddress);
    this.doctorPhone = StringField.fromFirestore(data.doctorPhone);
    this.notes = StringField.fromFirestore(data.notes);
    this.nextExaminationDates.fromFirestore(data.nextExaminationDates);
    this.isExaminationExempted = BooleanField.fromFirestore(data.isExaminationExempted);
    this.trackExpirations = BooleanField.fromFirestore(data.trackExpirations);
    this.oldDutyName = StringField.fromFirestore(data.oldDutyName);
    this.oldBranchName = StringField.fromFirestore(data.oldBranchName);
    this.oldFolderName = StringField.fromFirestore(data.oldFolderName);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.companies = ArrayByKeyField.toFirestore(this.companies, (value) => value.toFirestore());
    firestoreData.branches = ArrayByKeyField.toFirestore(this.branches, (value) => value.toFirestore());
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.birthDate = DateField.toFirestore(this.birthDate);
    firestoreData.birthMonth = this.birthDate !== undefined ? this.birthDate.getMonth() + 1 : null;
    firestoreData.birthPlace = StringField.toFirestore(this.birthPlace);
    firestoreData.sex = EnumField.toFirestore<EmployeeSex>(this.sex, EmployeeSex.Male);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.nationality = StringField.toFirestore(this.nationality);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.doctorName = StringField.toFirestore(this.doctorName);
    firestoreData.doctorAddress = this.doctorAddress.toFirestore();
    firestoreData.doctorPhone = StringField.toFirestore(this.doctorPhone);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.nextExaminationDates = this.nextExaminationDates.toFirestore();
    firestoreData.isExaminationExempted = BooleanField.toFirestore(this.isExaminationExempted);
    firestoreData.trackExpirations = BooleanField.toFirestore(this.trackExpirations);
    firestoreData.oldDutyName = StringField.toFirestore(this.oldDutyName);
    firestoreData.oldBranchName = StringField.toFirestore(this.oldBranchName);
    firestoreData.oldFolderName = StringField.toFirestore(this.oldFolderName);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Employee {
    super.fromOfflineCache(data);

    this.companies = ArrayByKeyField.fromOfflineCache<LinkedCompanyWithStatus>(data.companies, (value) => {
      const linkedCompanyWithStatus: LinkedCompanyWithStatus = new LinkedCompanyWithStatus();
      return linkedCompanyWithStatus.fromOfflineCache(value);
    });
    this.branches = ArrayByKeyField.fromOfflineCache<LinkedBranchWithStatus>(data.branches, (value) => {
      const linkedBranchWithStatus: LinkedBranchWithStatus = new LinkedBranchWithStatus();
      return linkedBranchWithStatus.fromOfflineCache(value);
    });
    this.lastName = StringField.fromOfflineCache(data.lastName);
    this.firstName = StringField.fromOfflineCache(data.firstName);
    this.fullName = StringField.fromOfflineCache(data.fullName);
    this.birthDate = DateField.fromOfflineCache(data.birthDate);
    this.birthMonth = NumberField.fromOfflineCache(data.birthMonth);
    this.birthPlace = StringField.fromOfflineCache(data.birthPlace);
    this.sex = EnumField.fromOfflineCache<EmployeeSex>(data.sex, Object.values(EmployeeSex), EmployeeSex.Male);
    this.fiscalCode = StringField.fromOfflineCache(data.fiscalCode);
    this.nationality = StringField.fromOfflineCache(data.nationality);
    this.address.fromOfflineCache(data.address);
    this.phone = StringField.fromOfflineCache(data.phone);
    this.email = StringField.fromOfflineCache(data.email);
    this.doctorName = StringField.fromOfflineCache(data.doctorName);
    this.doctorAddress.fromOfflineCache(data.doctorAddress);
    this.doctorPhone = StringField.fromOfflineCache(data.doctorPhone);
    this.notes = StringField.fromOfflineCache(data.notes);
    this.nextExaminationDates.fromOfflineCache(data.nextExaminationDates);
    this.isExaminationExempted = BooleanField.fromOfflineCache(data.isExaminationExempted);
    this.trackExpirations = BooleanField.fromOfflineCache(data.trackExpirations);
    this.oldDutyName = StringField.fromOfflineCache(data.oldDutyName);
    this.oldBranchName = StringField.fromOfflineCache(data.oldBranchName);
    this.oldFolderName = StringField.fromOfflineCache(data.oldFolderName);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.companies = ArrayByKeyField.toOfflineCache(this.companies, (value) => value.toOfflineCache());
    firestoreData.branches = ArrayByKeyField.toOfflineCache(this.branches, (value) => value.toOfflineCache());
    firestoreData.lastName = StringField.toOfflineCache(this.lastName);
    firestoreData.firstName = StringField.toOfflineCache(this.firstName);
    firestoreData.fullName = StringField.toOfflineCache(this.fullName);
    firestoreData.birthDate = DateField.toOfflineCache(this.birthDate);
    firestoreData.birthMonth = this.birthDate !== undefined ? this.birthDate.getMonth() + 1 : null;
    firestoreData.birthPlace = StringField.toOfflineCache(this.birthPlace);
    firestoreData.sex = EnumField.toOfflineCache<EmployeeSex>(this.sex, EmployeeSex.Male);
    firestoreData.fiscalCode = StringField.toOfflineCache(this.fiscalCode);
    firestoreData.nationality = StringField.toOfflineCache(this.nationality);
    firestoreData.address = this.address.toOfflineCache();
    firestoreData.phone = StringField.toOfflineCache(this.phone);
    firestoreData.email = StringField.toOfflineCache(this.email);
    firestoreData.doctorName = StringField.toOfflineCache(this.doctorName);
    firestoreData.doctorAddress = this.doctorAddress.toOfflineCache();
    firestoreData.doctorPhone = StringField.toOfflineCache(this.doctorPhone);
    firestoreData.notes = StringField.toOfflineCache(this.notes);
    firestoreData.nextExaminationDates = this.nextExaminationDates.toOfflineCache();
    firestoreData.isExaminationExempted = BooleanField.toOfflineCache(this.isExaminationExempted);
    firestoreData.trackExpirations = BooleanField.toOfflineCache(this.trackExpirations);
    firestoreData.oldDutyName = StringField.toOfflineCache(this.oldDutyName);
    firestoreData.oldBranchName = StringField.toOfflineCache(this.oldBranchName);
    firestoreData.oldFolderName = StringField.toOfflineCache(this.oldFolderName);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.lastName)];
    this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.firstName)];
    if (this.email !== undefined) this.searchKeys.push(this.email.toLowerCase());
    if (this.fiscalCode !== undefined) this.searchKeys.push(this.fiscalCode.toLowerCase());
  }

  public setFullNames(): void {
    if (this.lastName != undefined && this.firstName != undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
    } else {
      this.fullName = undefined;
    }
  }

  public getLinkedCompaniesWithStatus(): LinkedCompanyWithStatus[] {
    return DataHelpers.objectToSortedArray<LinkedCompanyWithStatus>(this.companies, new SortCriteria("name", "asc", "string"));
  }

  public setLinkedCompaniesWithStatus(linkedCompaniesWithStatus: LinkedCompanyWithStatus[]): void {
    this.companies = DataHelpers.sortedArrayToObject<LinkedCompanyWithStatus>(linkedCompaniesWithStatus);
  }

  public addLinkedCompanyWithStatus(linkedCompanyWithStatus: LinkedCompanyWithStatus): void {
    this.companies[linkedCompanyWithStatus.id] = linkedCompanyWithStatus;
  }

  public removeLinkedCompanyWithStatus(linkedCompanyWithStatus: LinkedCompanyWithStatus): void {
    delete this.companies[linkedCompanyWithStatus.id];
  }

  public getLinkedBranchesWithStatus(): LinkedBranchWithStatus[] {
    return DataHelpers.objectToSortedArray<LinkedBranchWithStatus>(this.branches, new SortCriteria("name", "asc", "string"));
  }

  public setLinkedBranchesWithStatus(linkedBranchesWithStatus: LinkedBranchWithStatus[]): void {
    this.branches = DataHelpers.sortedArrayToObject<LinkedBranchWithStatus>(linkedBranchesWithStatus);
  }

  public addLinkedBranch(linkedBranchWithStatus: LinkedBranchWithStatus): void {
    this.branches[linkedBranchWithStatus.id] = linkedBranchWithStatus;
  }

  public removeLinkedBranch(linkedBranchWithStatus: LinkedBranchWithStatus): void {
    delete this.branches[linkedBranchWithStatus.id];
  }
}
