import { getDocs, orderBy, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Clause } from "@/core/modules/firestore/objects/Clause";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { User } from "@/core/modules/user/objects/User";

export const searchDocuments = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  searchText: string,
  sortCriterias: SortCriteria[],
  clauses?: Clause[],
  customQuery?: Query<T>
): Promise<T[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    // offline mode
    if (offlineModel.getOfflineState() === "offline")
      return searchOfflineDocuments(user, model as FirestoreModel<FirestoreOfflineDocument>, searchText, sortCriterias, clauses) as Promise<T[]>;

    // user can read all documents
    if (user.canReadAll(model.roleModule) === true) return searchOnlineAllDocuments(model, searchText, sortCriterias, clauses);
    // user can read only documents created by him
    if (user.canReadOwned(model.roleModule) === true) return searchOnlineOwnDocuments(model, searchText, sortCriterias, clauses);
    // user can read only documents with custom query
    if (user.canReadCustom(model.roleModule) === true) {
      if (customQuery === undefined) throw new Error("customQuery is undefined");
      return searchOnlineCustomDocuments(model, searchText, sortCriterias, customQuery, clauses);
    }

    return [];
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.searchDocuments", { model, searchText, sortCriterias, clauses }, error);
    return [];
  }
};

const searchOfflineDocuments = async <T extends FirestoreOfflineDocument>(
  user: User,
  model: FirestoreModel<T>,
  searchText: string,
  sortCriterias: SortCriteria[],
  clauses?: Clause[]
): Promise<T[]> => {
  try {
    if (model.offlineModuleModel === undefined) return [];
    if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

    let documents: T[] = (await offlineModel.getCacheByCollection(model.offlineModuleModel)) as T[];
    documents = documents.filter((doc) => doc.searchKeys.includes(searchText));

    if (clauses !== undefined) {
      for (const clause of clauses) {
        documents = documents.filter((doc) => doc[clause.field as keyof T] === clause.value);
      }
    }

    const firestoreSorter: FirestoreSorter<T> = new FirestoreSorter(documents);
    firestoreSorter.setSortCriterias(sortCriterias);
    return firestoreSorter.sort();
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.searchOfflineDocuments", { user, model, searchText, sortCriterias, clauses }, error);
    return [];
  }
};

const searchOnlineAllDocuments = async <T extends FirestoreDocument>(
  model: FirestoreModel<T>,
  searchText: string,
  sortCriterias: SortCriteria[],
  clauses?: Clause[]
): Promise<T[]> => {
  try {
    let buildingQuery: Query<T> = query(
      model.getPathReference().withConverter(model.firestoreConverter),
      where("searchKeys", "array-contains", searchText)
    );

    if (clauses !== undefined) {
      for (const clause of clauses) {
        buildingQuery = query(buildingQuery, where(clause.field, clause.condition, clause.value));
      }
    }

    for (const sortCriteria of sortCriterias) {
      buildingQuery = query(buildingQuery, orderBy(sortCriteria.field, sortCriteria.direction));
    }

    const snapshot: QuerySnapshot<T> = await getDocs(buildingQuery);
    if (snapshot == undefined || snapshot.empty) return [];
    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.searchOnlineAllDocuments", { model, searchText, sortCriterias, clauses }, error);
    return [];
  }
};

const searchOnlineOwnDocuments = async <T extends FirestoreDocument>(
  model: FirestoreModel<T>,
  searchText: string,
  sortCriterias: SortCriteria[],
  clauses?: Clause[]
): Promise<T[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    let buildingQuery: Query<T> = query(
      model.getPathReference().withConverter(model.firestoreConverter),
      where("createdBy", "==", user?.id),
      where("searchKeys", "array-contains", searchText)
    );

    if (clauses !== undefined) {
      for (const clause of clauses) {
        buildingQuery = query(buildingQuery, where(clause.field, clause.condition, clause.value));
      }
    }

    for (const sortCriteria of sortCriterias) {
      buildingQuery = query(buildingQuery, orderBy(sortCriteria.field, sortCriteria.direction));
    }

    const snapshot: QuerySnapshot<T> = await getDocs(buildingQuery);
    if (snapshot == undefined || snapshot.empty) return [];
    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.searchOnlineOwnDocuments", { model, searchText, sortCriterias, clauses }, error);
    return [];
  }
};

const searchOnlineCustomDocuments = async <T extends FirestoreDocument>(
  model: FirestoreModel<T>,
  searchText: string,
  sortCriterias: SortCriteria[],
  customQuery: Query<T>,
  clauses?: Clause[]
): Promise<T[]> => {
  try {
    let buildingQuery: Query<T> = customQuery;

    if (clauses !== undefined) {
      for (const clause of clauses) {
        buildingQuery = query(buildingQuery, where(clause.field, clause.condition, clause.value));
      }
    }

    const snapshot: QuerySnapshot<T> = await getDocs(buildingQuery);

    if (snapshot == undefined || snapshot.empty) return [];

    let documents: T[] = snapshot.docs.map((doc) => doc.data());
    documents = documents.filter((doc) => doc.searchKeys.includes(searchText));

    const firestoreSorter: FirestoreSorter<T> = new FirestoreSorter(documents);
    firestoreSorter.setSortCriterias(sortCriterias);
    return firestoreSorter.sort();
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.searchOnlineDocumentsWithRight", { model, searchText, sortCriterias, clauses }, error);
    return [];
  }
};
