
import { defineComponent, onMounted, Ref, ref } from "vue";

import { config } from "@/core/modules/config/objects/Config";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "Footer",
  setup() {
    const { t } = useLocale();

    const footerLogoUrl: Ref<string> = ref("");

    onMounted(() => {
      footerLogoUrl.value =
        config.app.logos.footerImage === "features" ? "/assets/features/images/logo-dark.svg" : "/assets/core/images/koru-logo-dark.svg";
    });

    return {
      footerLogoUrl,
      config,
      t,
    };
  },
});
