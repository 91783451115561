import { WorkHistoryItem } from "./WorkHistoryItem";

import { ArrayField, StringField } from "@/core/fields";

export class WorkHistory {
  public items: WorkHistoryItem[] = [];
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): WorkHistory {
    this.items = ArrayField.fromFirestore<WorkHistoryItem>(data.workHistoryItems, (value) => new WorkHistoryItem(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.workHistoryItems = ArrayField.toFirestore<WorkHistoryItem>(this.items, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): WorkHistory {
    this.items = ArrayField.fromOfflineCache<WorkHistoryItem>(data.workHistoryItems, (value) => {
      const workHistoryItem: WorkHistoryItem = new WorkHistoryItem();
      return workHistoryItem.fromOfflineCache(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.workHistoryItems = ArrayField.toOfflineCache<WorkHistoryItem>(this.items, (value) => value.toOfflineCache());
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
