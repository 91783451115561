import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { assistModel } from "../AssistModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { websocketClient } from "@/features/modules/assist/objects/WebsocketClient";

export const setExamination = (examination: Examination | undefined): void => {
  try {
    assistModel.examination = examination;
    websocketClient.sendRequest("setExaminationId", { examinationId: examination?.id ?? undefined });
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.setExamination", { examination }, error);
  }
};
