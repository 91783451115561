import { appFaultLocaleModule } from "@/core/modules/appFault/locales/module";
import { authLocaleModule } from "@/core/modules/auth/locales/module";
import { backupLocaleModule } from "@/core/modules/backup/locales/module";
import { changelogLocaleModule } from "@/core/modules/changelog/locales/module";
import { cloudFaultLocaleModule } from "@/core/modules/cloudFault/locales/module";
import { feedbackLocaleModule } from "@/core/modules/feedback/locales/module";
import { firestoreLocaleModule } from "@/core/modules/firestore/locales/module";
import { guideLocaleModule } from "@/core/modules/guide/locales/module";
import { historyLocaleModule } from "@/core/modules/history/locales/module";
import { notificationLocaleModule } from "@/core/modules/notification/locales/module";
import { offlineLocaleModule } from "@/core/modules/offline/locales/module";
import { paperLocaleModule } from "@/core/modules/paper/locales/module";
import { profileLocaleModule } from "@/core/modules/profile/locales/module";
import { pwaLocaleModule } from "@/core/modules/pwa/locales/module";
import { roleLocaleModule } from "@/core/modules/role/locales/module";
import { searchLocaleModule } from "@/core/modules/search/locales/module";
import { statLocaleModule } from "@/core/modules/stat/locales/module";
import { userLocaleModule } from "@/core/modules/user/locales/module";
import { utilityLocaleModule } from "@/core/modules/utility/locales/module";
import { whoLocaleModule } from "@/core/modules/who/locales/module";

export const coreLocales = [
  appFaultLocaleModule,
  authLocaleModule,
  backupLocaleModule,
  changelogLocaleModule,
  cloudFaultLocaleModule,
  feedbackLocaleModule,
  firestoreLocaleModule,
  guideLocaleModule,
  historyLocaleModule,
  notificationLocaleModule,
  offlineLocaleModule,
  paperLocaleModule,
  profileLocaleModule,
  pwaLocaleModule,
  roleLocaleModule,
  searchLocaleModule,
  statLocaleModule,
  userLocaleModule,
  utilityLocaleModule,
  whoLocaleModule,
];
