import { DocumentReference } from "firebase/firestore";

import { CostListItem } from "./CostListItem";
import { CostListItemType } from "./CostListItemType";
import { CostListOwnerType } from "./CostListOwnerType";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";

import { ArrayField, ObjectField, StringArrayField, StringField } from "@/core/fields";

export class CostList extends FirestoreDocument {
  public firm: LinkedFirm | undefined = undefined;
  public name: string | undefined = undefined;
  public costs: CostListItem[] = [];
  public examTypesIds: string[] = [];
  public testTypesIds: string[] = [];
  public surveysIds: string[] = [];
  public serviceTypesIds: string[] = [];
  public doctorsIds: string[] = [];
  public suppliersIds: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): CostList {
    super.fromFirestore(data, id, firestoreRef);

    this.firm = ObjectField.fromFirestore(data.firm, (value) => new LinkedFirm(value));
    this.name = StringField.fromFirestore(data.name);
    this.costs = ArrayField.fromFirestore(data.costs, (value) => new CostListItem(value));
    this.examTypesIds = StringArrayField.fromFirestore(data.examTypesIds);
    this.testTypesIds = StringArrayField.fromFirestore(data.testTypesIds);
    this.surveysIds = StringArrayField.fromFirestore(data.surveysIds);
    this.serviceTypesIds = StringArrayField.fromFirestore(data.serviceTypesIds);
    this.doctorsIds = StringArrayField.fromFirestore(data.doctorsIds);
    this.suppliersIds = StringArrayField.fromFirestore(data.suppliersIds);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    let examTypesIds: string[] = [];
    let testTypesIds: string[] = [];
    let surveysIds: string[] = [];
    let serviceTypesIds: string[] = [];
    let doctorsIds: string[] = [];
    let suppliersIds: string[] = [];
    if (this.costs != null) {
      for (const cost of this.costs) {
        if (cost.type === CostListItemType.ExamType) examTypesIds = [...examTypesIds, ...(cost.itemsIds as string[])];
        if (cost.type === CostListItemType.TestType) testTypesIds = [...testTypesIds, ...(cost.itemsIds as string[])];
        if (cost.type === CostListItemType.Survey) surveysIds = [...surveysIds, ...(cost.itemsIds as string[])];
        if (cost.type === CostListItemType.ServiceType) serviceTypesIds = [...serviceTypesIds, ...(cost.itemsIds as string[])];
        if (cost.ownerType == CostListOwnerType.Doctor) doctorsIds.push(cost.ownerId as string);
        if (cost.ownerType == CostListOwnerType.Supplier) suppliersIds.push(cost.ownerId as string);
      }

      examTypesIds = examTypesIds.filter((item, index) => examTypesIds.indexOf(item) === index);
      testTypesIds = testTypesIds.filter((item, index) => testTypesIds.indexOf(item) === index);
      surveysIds = surveysIds.filter((item, index) => surveysIds.indexOf(item) === index);
      serviceTypesIds = serviceTypesIds.filter((item, index) => serviceTypesIds.indexOf(item) === index);
      doctorsIds = doctorsIds.filter((item, index) => doctorsIds.indexOf(item) === index);
      suppliersIds = suppliersIds.filter((item, index) => suppliersIds.indexOf(item) === index);
    }

    firestoreData.firm = ObjectField.toFirestore(this.firm, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.costs = ArrayField.toFirestore(this.costs, (value) => value.toFirestore());
    firestoreData.examTypesIds = examTypesIds;
    firestoreData.testTypesIds = testTypesIds;
    firestoreData.surveysIds = surveysIds;
    firestoreData.serviceTypesIds = serviceTypesIds;
    firestoreData.doctorsIds = doctorsIds;
    firestoreData.suppliersIds = suppliersIds;

    return firestoreData;
  }

  public async setFirm(): Promise<void> {
    const firmId: string = firmModel.getSelectedFirmId();
    const linkedFirm: LinkedFirm = LinkedFirm.createFromFirm(await firmModel.getSelectedFirm());
    if (firmId == undefined || linkedFirm == undefined) throw new Error("createPriceListNoFirm");

    this.firm = linkedFirm;
  }
}
