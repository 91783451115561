import { RouteRecordRaw } from "vue-router";

export const priceListRoutes: RouteRecordRaw[] = [
  {
    path: "/price-lists",
    name: "PriceListList",
    component: () => import("../views/PriceListList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "priceList",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/price-lists/:id/edit",
    name: "PriceListEdit",
    component: () => import("../views/PriceListEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "priceList",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/price-lists/:id",
    name: "PriceListView",
    component: () => import("../views/PriceListView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "priceList",
      requiredRight: "viewUI",
    },
  },
];
