import { environment } from "@/core/modules/config/objects/environment";

import settingDev from "@/features/setting/appDev.json";
import settingProd from "@/features/setting/appProd.json";

export class Setting {
  public settings: Record<string, unknown>;

  public constructor() {
    this.settings = environment === "production" ? settingProd : settingDev;
  }

  public getSetting<T>(key: string): T {
    return this.settings[key] as T;
  }
}

export const setting: Setting = new Setting();
