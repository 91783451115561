import { RouteRecordRaw } from "vue-router";

export const limitationRoutes: RouteRecordRaw[] = [
  {
    path: "/limitations/:id/edit",
    name: "LimitationEdit",
    component: () => import("../views/LimitationEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "limitation",
      requiredRight: "viewUI",
    },
  },
];
