import { DocumentReference } from "firebase/firestore";

import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedNorm } from "@/features/modules/norm/objects/LinkedNorm";

import { NumberWithDefaultField, ObjectField, StringField } from "@/core/fields";

export class Risk extends FirestoreOfflineDocument {
  public norm: LinkedNorm | undefined = undefined;
  public section: string | undefined = undefined;
  public name: string | undefined = undefined;
  public inailId: string | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Risk {
    super.fromFirestore(data, id, firestoreRef);

    this.norm = ObjectField.fromFirestore<LinkedNorm>(data.norm, (value) => new LinkedNorm(value));
    this.section = StringField.fromFirestore(data.section);
    this.name = StringField.fromFirestore(data.name);
    this.inailId = StringField.fromFirestore(data.inailId);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.norm = ObjectField.toFirestore<LinkedNorm>(this.norm, (value) => value.toFirestore());
    firestoreData.section = StringField.toFirestore(this.section);
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.inailId = StringField.toFirestore(this.inailId);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Risk {
    super.fromOfflineCache(data);

    this.norm = ObjectField.fromOfflineCache<LinkedNorm>(data.norm, (value) => {
      const linkedNorm: LinkedNorm = new LinkedNorm();
      return linkedNorm.fromOfflineCache(value);
    });
    this.section = StringField.fromOfflineCache(data.section);
    this.name = StringField.fromOfflineCache(data.name);
    this.inailId = StringField.fromOfflineCache(data.inailId);
    this.order = NumberWithDefaultField.fromOfflineCache(data.order, 999);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.norm = ObjectField.toOfflineCache<LinkedNorm>(this.norm, (value) => value.toOfflineCache());
    firestoreData.section = StringField.toOfflineCache(this.section);
    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.inailId = StringField.toOfflineCache(this.inailId);
    firestoreData.order = NumberWithDefaultField.toOfflineCache(this.order, 999);

    return firestoreData;
  }
}
