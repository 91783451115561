export const de = {
  cloudFault: {
    cloudFault: "Wolkenereignis",
    cloudFaults: "Cloud-Ereignisse",
    createdAt: "Datum und Uhrzeit",
    details: "Details",
    function: "Funktion",
    noDetails: "Keine Details vorhanden",
    notifications: {
      cloudFaultCreated: "Cloud-Ereignis erstellt",
      groupName: "Cloud-Ereignisse",
    },
  },
  buttons: {
    deleteAll: "Alle löschen",
    showDetails: "Details anzeigen",
  },
  navigation: {
    cloudFault: "Cloud-Ereignisse",
  },
};
