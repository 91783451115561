import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { assistModel } from "../AssistModel";
import { Attachment } from "@/features/modules/examination/objects/Attachment";
import { ExaminationHelpers } from "@/features/modules/examination/helpers/ExaminationHelpers";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { storageModel } from "@/core/modules/storage/models/StorageModel";

export const uploadAttachment = async (base64File: string, name: string, type: string): Promise<void> => {
  try {
    if (base64File === undefined) throw new Error("base64File is undefined");
    if (assistModel.examination === undefined) throw new Error("examination is undefined");

    let fileName: string | undefined = undefined;
    if (type === "CS" || type === "GD" || type === "GL") {
      fileName = FeaturesHelpers.renderExaminationDocumentFileName(assistModel.examination, type);
    } else if (type === "attachment") {
      fileName = FeaturesHelpers.renderExaminationAttachmentFileName(assistModel.examination);
    } else {
      throw new Error("Attachment uploaded has unknown type");
    }

    const storageFile: StorageFile = await storageModel.uploadBase64(
      ExaminationHelpers.getFolderPath(
        assistModel.examination.firm?.id,
        assistModel.examination.company?.id,
        assistModel.examination.employee?.id,
        assistModel.examination.id
      ),
      `${fileName}.pdf`,
      base64File,
      { examination: assistModel.examination.id, company: assistModel.examination.company?.id ?? "null" }
    );

    if (type === "CS") {
      assistModel.examination.healthRecordFile = storageFile;
    } else if (type === "GD") {
      assistModel.examination.companyJudgmentFile = storageFile;
    } else if (type === "GL") {
      assistModel.examination.employeeJudgmentFile = storageFile;
    } else if (type === "attachment") {
      const attachment: Attachment = new Attachment();
      attachment.file = storageFile;
      attachment.name = name;
      assistModel.examination.attachments.push(attachment);
    } else {
      throw new Error("Attachment uploaded has unknown type");
    }

    console.log(`Attachment uploaded with filename: ${storageFile.uniqueName}`);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.uploadAttachment", { base64File, name, type }, error);
  }
};
