import { RouteRecordRaw } from "vue-router";

export const searchRoutes: RouteRecordRaw[] = [
  {
    path: "/search/:query",
    name: "SearchView",
    component: () => import("../views/SearchView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "search",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/search/:query/module/:module",
    name: "SearchModuleView",
    component: () => import("../views/SearchModuleView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "search",
      requiredRight: "viewUI",
    },
  },
];
