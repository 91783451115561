export const it = {
  firm: {
    accountingEmail: "Indirizzo e-mail amministrazione",
    accountingEmailError: "Inserire un indirizzo e-mail valido",
    address: "Indirizzo",
    addresses: "Recapiti",
    businessName: "Ragione sociale",
    email: "Indirizzo e-mail",
    emailError: "Inserire un indirizzo e-mail valido",
    firm: "Ditta",
    firms: "Ditte",
    fiscalCode: "Codice fiscale",
    hasWithHoldingTax: "Ritenuta d'acconto",
    invoicing: "Fatturazione",
    name: "Nome",
    nameError: "Inserire un nome valido",
    payment: "Pagamento",
    paymentBank: "Istituto bancario",
    paymentIban: "IBAN",
    paymentIbanError: "Inserire un IBAN valido",
    paymentRecipient: "Intestatario conto",
    phone: "Telefono",
    province: "Provincia",
    stampThreshold: "Soglia bollo",
    stampValue: "Valore bollo",
    town: "Comune",
    vatCode: "Partita IVA",
    zipCode: "CAP",
  },
  navigation: {
    firm: "Ditte",
  },
};
