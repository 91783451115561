export class InvoiceCandidate {
  public recipientType: "company" | "broker";
  public recipientId: string | undefined;
  public recipientName: string | undefined;
  public examinationsCount = 0;
  public externalTestsCount = 0;
  public servicesCount = 0;

  public constructor(recipientType: "company" | "broker", recipientId: string | undefined, recipientName: string | undefined) {
    this.recipientType = recipientType;
    this.recipientId = recipientId;
    this.recipientName = recipientName;
  }
}
