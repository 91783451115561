export class NullableNumberField {
  public static fromFirestore(value: unknown): number | undefined {
    if (value === null || value === undefined) return undefined;

    return Number(value);
  }

  public static toFirestore(value: number | undefined): number | null {
    if (value === null || value === undefined) return null;

    return Number(value);
  }

  public static fromOfflineCache(value: unknown): number | undefined {
    return NullableNumberField.fromFirestore(value);
  }

  public static toOfflineCache(value: number | undefined): number | null {
    return NullableNumberField.toFirestore(value);
  }
}
