import { compareAsc } from "date-fns";
import { DocumentReference, onSnapshot } from "firebase/firestore";

import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { DateTimeStrictField, StringArrayField, StringStrictField } from "@/core/fields";

export class FirestoreDocument {
  public id: string;
  public parentId: string | undefined;
  public searchKeys: string[] = [];
  public createdAt: Date = new Date();
  public createdBy = "";
  public updatedAt: Date = new Date();
  public updatedBy = "";

  public firestoreRef: DocumentReference | undefined = undefined;

  public constructor(id?: string) {
    const user: User = store.getters[storeTypes.getters.getUser];

    this.id = id ?? "new";
    this.createdBy = user?.id ?? "unknown";
    this.updatedBy = user?.id ?? "unknown";
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): FirestoreDocument {
    this.id = id !== undefined ? id : StringStrictField.fromFirestore(data.id, "new");
    this.firestoreRef = firestoreRef;
    this.parentId = firestoreRef?.parent?.parent?.id ?? undefined;

    this.searchKeys = StringArrayField.fromFirestore(data.searchKeys);
    this.createdAt = DateTimeStrictField.fromFirestore(data.createdAt, new Date());
    this.createdBy = StringStrictField.fromFirestore(data.createdBy, "");
    this.updatedAt = DateTimeStrictField.fromFirestore(data.updatedAt, new Date());
    this.updatedBy = StringStrictField.fromFirestore(data.updatedBy, "");

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const data: Record<string, unknown> = {
      searchKeys: StringArrayField.toFirestore(this.searchKeys),
      createdAt: DateTimeStrictField.toFirestore(this.createdAt),
      createdBy: StringStrictField.toFirestore(this.createdBy),
      updatedAt: DateTimeStrictField.toFirestore(this.updatedAt),
      updatedBy: StringStrictField.toFirestore(this.updatedBy),
    };

    return data;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
  }

  public listenForChanges(callback: (firestoreDocument: FirestoreDocument) => void): () => void {
    if (this.firestoreRef === undefined) return () => null;

    return onSnapshot(this.firestoreRef, (snapshot) => {
      if (snapshot.exists() === true) {
        const firestoreDocument = this.fromFirestore(snapshot.data() as Record<string, unknown>, snapshot.id, snapshot.ref);
        callback(firestoreDocument);
      }
    });
  }

  public hasChangedFrom(oldFirestoreDocument: FirestoreDocument): boolean {
    return compareAsc(this.updatedAt, oldFirestoreDocument.updatedAt) != 0 || this.updatedBy != oldFirestoreDocument.updatedBy;
  }

  public setTimestampFields(mode: "create" | "update"): void {
    const user: User = store.getters[storeTypes.getters.getUser];

    const now = new Date();
    if (mode == "create") {
      this.createdAt = now;
      this.createdBy = user?.id ?? "unknown";
    }
    this.updatedAt = now;
    this.updatedBy = user?.id ?? "unknown";
  }
}
