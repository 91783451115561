import { SurveyForm } from "./SurveyForm";

import { BooleanField, StringField } from "@/core/fields";

export class GasSurveyForm extends SurveyForm {
  public question1 = false;
  public question2 = false;
  public question3 = false;
  public question4 = false;
  public question5 = false;
  public question6l: string | undefined = undefined;
  public question6r: string | undefined = undefined;
  public question7l: string | undefined = undefined;
  public question7r: string | undefined = undefined;
  public question8l: string | undefined = undefined;
  public question8r: string | undefined = undefined;
  public question9 = true;
  public question10 = false;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): GasSurveyForm {
    super.fromFirestore(data);

    this.question1 = BooleanField.fromFirestore(data.question1);
    this.question2 = BooleanField.fromFirestore(data.question2);
    this.question3 = BooleanField.fromFirestore(data.question3);
    this.question4 = BooleanField.fromFirestore(data.question4);
    this.question5 = BooleanField.fromFirestore(data.question5);
    this.question6l = StringField.fromFirestore(data.question6l);
    this.question6r = StringField.fromFirestore(data.question6r);
    this.question7l = StringField.fromFirestore(data.question7l);
    this.question7r = StringField.fromFirestore(data.question7r);
    this.question8l = StringField.fromFirestore(data.question8l);
    this.question8r = StringField.fromFirestore(data.question8r);
    this.question9 = BooleanField.fromFirestore(data.question9);
    this.question10 = BooleanField.fromFirestore(data.question10);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question2 = BooleanField.toFirestore(this.question2);
    firestoreData.question3 = BooleanField.toFirestore(this.question3);
    firestoreData.question4 = BooleanField.toFirestore(this.question4);
    firestoreData.question5 = BooleanField.toFirestore(this.question5);
    firestoreData.question6l = StringField.toFirestore(this.question6l);
    firestoreData.question6r = StringField.toFirestore(this.question6r);
    firestoreData.question7l = StringField.toFirestore(this.question7l);
    firestoreData.question7r = StringField.toFirestore(this.question7r);
    firestoreData.question8l = StringField.toFirestore(this.question8l);
    firestoreData.question8r = StringField.toFirestore(this.question8r);
    firestoreData.question9 = BooleanField.toFirestore(this.question9);
    firestoreData.question10 = BooleanField.toFirestore(this.question10);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): GasSurveyForm {
    super.fromOfflineCache(data);

    this.question1 = BooleanField.fromOfflineCache(data.question1);
    this.question2 = BooleanField.fromOfflineCache(data.question2);
    this.question3 = BooleanField.fromOfflineCache(data.question3);
    this.question4 = BooleanField.fromOfflineCache(data.question4);
    this.question5 = BooleanField.fromOfflineCache(data.question5);
    this.question6l = StringField.fromOfflineCache(data.question6l);
    this.question6r = StringField.fromOfflineCache(data.question6r);
    this.question7l = StringField.fromOfflineCache(data.question7l);
    this.question7r = StringField.fromOfflineCache(data.question7r);
    this.question8l = StringField.fromOfflineCache(data.question8l);
    this.question8r = StringField.fromOfflineCache(data.question8r);
    this.question9 = BooleanField.fromOfflineCache(data.question9);
    this.question10 = BooleanField.fromOfflineCache(data.question10);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question2 = BooleanField.toOfflineCache(this.question2);
    firestoreData.question3 = BooleanField.toOfflineCache(this.question3);
    firestoreData.question4 = BooleanField.toOfflineCache(this.question4);
    firestoreData.question5 = BooleanField.toOfflineCache(this.question5);
    firestoreData.question6l = StringField.toOfflineCache(this.question6l);
    firestoreData.question6r = StringField.toOfflineCache(this.question6r);
    firestoreData.question7l = StringField.toOfflineCache(this.question7l);
    firestoreData.question7r = StringField.toOfflineCache(this.question7r);
    firestoreData.question8l = StringField.toOfflineCache(this.question8l);
    firestoreData.question8r = StringField.toOfflineCache(this.question8r);
    firestoreData.question9 = BooleanField.toOfflineCache(this.question9);
    firestoreData.question10 = BooleanField.toOfflineCache(this.question10);

    return firestoreData;
  }
}
