import JSZip from "jszip";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { ExaminationHelpers } from "@/features/modules/examination/helpers/ExaminationHelpers";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { ExternalTestHelpers } from "@/features/modules/externalTest/helpers/ExternalTestHelpers";
import { HealthRecordPaper } from "@/features/modules/examination/paper/HealthRecordPaper";
import { JudgmentForCompanyPaper } from "@/features/modules/examination/paper/JudgmentForCompanyPaper";
import { JudgmentForEmployeePaper } from "@/features/modules/examination/paper/JudgmentForEmployeePaper";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { SurveyFormHelpers } from "@/features/modules/surveyForm/helpers/SurveyFormHelpers";
import { TestFormHelpers } from "@/features/modules/testForm/helpers/TestFormHelpers";

export const createHealthRecord = async (
  employee: Employee,
  companyId: string,
  d: (date: Date, format: string) => string,
  n: (value: number, format: string) => string,
  t: (entry: string, params?: Record<string, unknown>) => string
): Promise<string> => {
  try {
    const zip = new JSZip();
    const employeeFolder = zip.folder(prepareString(`${employee.fullName}_${d(employee.birthDate as Date, "shortDate")}`));
    if (employeeFolder === null) throw new Error("createFolderFailed");

    const examinations: Examination[] = await examinationModel.getExaminationsByEmployeeAndCompanyAndFirm(employee.id, companyId);
    for (const examination of examinations) {
      if (examination.state !== ExaminationState.Completed && examination.state !== ExaminationState.Billed) continue;

      const yearFolder = employeeFolder.folder(String(examination.year));
      if (yearFolder === null) throw new Error("createFolderFailed");
      const examinationFolder = yearFolder.folder(prepareString(`${t("examination.examination")}_${d(examination.date, "shortDate")}`));
      if (examinationFolder === null) throw new Error("createFolderFailed");

      // GL
      if (examination.employeeJudgmentFile !== undefined) {
        const base64Content: string = await storageModel.getBase64(
          ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
          examination.employeeJudgmentFile.uniqueName as string
        );
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_GL`)}.pdf`, base64Content, { base64: true });
      } else {
        const judgmentForEmployeePaper: JudgmentForEmployeePaper = new JudgmentForEmployeePaper(examination, d, n, t);
        const base64Content: string = await judgmentForEmployeePaper.outputBase64();
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_GL`)}.pdf`, base64Content, { base64: true });
      }
      // GD
      if (examination.companyJudgmentFile !== undefined) {
        const base64Content: string = await storageModel.getBase64(
          ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
          examination.companyJudgmentFile.uniqueName as string
        );
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_GD`)}.pdf`, base64Content, { base64: true });
      } else {
        const judgmentForCompanyPaper: JudgmentForCompanyPaper = new JudgmentForCompanyPaper(examination, d, n, t);
        const base64Content: string = await judgmentForCompanyPaper.outputBase64();
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_GD`)}.pdf`, base64Content, { base64: true });
      }
      // CS
      if (examination.healthRecordFile !== undefined) {
        const base64Content: string = await storageModel.getBase64(
          ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
          examination.healthRecordFile.uniqueName as string
        );
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_CS`)}.pdf`, base64Content, { base64: true });
      } else {
        const healthRecordPaper: HealthRecordPaper = new HealthRecordPaper(examination, d, n, t);
        const base64Content: string = await healthRecordPaper.outputBase64();
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_CS`)}.pdf`, base64Content, { base64: true });
      }
      // examination tests
      for (const examinationTest of examination.getExaminationTests()) {
        if (examinationTest.file !== undefined) {
          const base64Content: string = await storageModel.getBase64(
            ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
            examinationTest.file.uniqueName as string
          );
          examinationFolder.file(`${prepareString(`${examination.codeDisplay}_${examinationTest.testType?.name}`)}.pdf`, base64Content, {
            base64: true,
          });
        } else if (
          examinationTest.testForm !== undefined &&
          examinationTest.testForm.isCompleted === true &&
          examinationTest.testForm.isPrintable === true
        ) {
          const base64Content: string | undefined = await TestFormHelpers.outputTestForm(examinationTest, examination, d, n, t);
          if (base64Content !== undefined) {
            examinationFolder.file(`${prepareString(`${examination.codeDisplay}_${examinationTest.testType?.name}`)}.pdf`, base64Content, {
              base64: true,
            });
          }
        }
      }
      // external tests
      for (const externalTest of examination.getExternalTests()) {
        if (externalTest.file !== undefined) {
          const base64Content: string = await storageModel.getBase64(
            ExternalTestHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id),
            externalTest.file.uniqueName as string
          );
          examinationFolder.file(`${prepareString(`${examination.codeDisplay}_${externalTest.testType?.name}`)}.pdf`, base64Content, {
            base64: true,
          });
        } else if (externalTest.testForm !== undefined && externalTest.testForm.isCompleted === true && externalTest.testForm.isPrintable === true) {
          const base64Content: string | undefined = await TestFormHelpers.outputTestForm(externalTest, examination, d, n, t);
          if (base64Content !== undefined) {
            examinationFolder.file(`${prepareString(`${examination.codeDisplay}_${externalTest.testType?.name}`)}.pdf`, base64Content, {
              base64: true,
            });
          }
        }
      }
      // attachments
      for (const attachment of examination.attachments) {
        const base64Content: string = await storageModel.getBase64(
          ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
          attachment.file?.uniqueName as string
        );
        examinationFolder.file(`${prepareString(`${examination.codeDisplay}_${attachment.name}`)}.pdf`, base64Content, {
          base64: true,
        });
      }
      // surveys
      for (const survey of examination.getLinkedSurveys()) {
        if (survey.surveyForm !== undefined && survey.surveyForm.isCompleted === true && survey.surveyForm.isPrintable === true) {
          const base64Content: string | undefined = await SurveyFormHelpers.outputSurveyForm(survey, examination, d, n, t);
          if (base64Content !== undefined) {
            examinationFolder.file(`${prepareString(`${examination.codeDisplay}_${survey.name}`)}.pdf`, base64Content, {
              base64: true,
            });
          }
        }
      }
    }

    return zip.generateAsync({ type: "base64" });
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.createHealthRecord", { employee, companyId, d, n, t }, error);
    return "ERROR";
  }
};

function prepareString(entry: string | undefined): string {
  if (entry === undefined) return "";

  return entry
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "_")
    .replace(/\//g, "_")
    .toLocaleUpperCase();
}
