import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { PriceList } from "@/features/modules/priceList/objects/PriceList";
import { priceListModel } from "../PriceListModel";

export const getPriceListsByFirm = async (): Promise<PriceList[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<PriceList> = query(
      priceListModel.getPathReference().withConverter(priceListModel.firestoreConverter),
      where("firm.id", "==", firmId)
    );

    const snapshot: QuerySnapshot<PriceList> = await getDocs(priceListModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("PriceListModel.getPriceListsByFirm", {}, error);
    return [];
  }
};

function sortDocuments(documents: PriceList[]): PriceList[] {
  const sorter: FirestoreSorter<PriceList> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
