import { getDownloadURL, ref } from "firebase/storage";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineUpload } from "@/core/modules/offline/objects/OfflineUpload";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";
import { storageModel } from "../StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const uploadFile = async (path: string, file: File, name: string | undefined, metadata?: Record<string, string>): Promise<StorageFile> => {
  try {
    if (path === undefined) throw new Error("undefinedPath");
    if (file === undefined) throw new Error("undefinedFile");

    const extension = file.name.split(".").pop()?.toLowerCase();

    let uniqueName = "";
    let exists = true;
    let suffix = 0;

    if (offlineModel.getOfflineState() === "offline") {
      // TODO with metadata (or maybe do it during upload to online storage?)
      // offline
      const user: User = store.getters[storeTypes.getters.getUser];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const offlineUploads: OfflineUpload[] = (await indexedDbModel.getDocuments("offlineUploads")).map(
        (rawOfflineUpload) => new OfflineUpload(rawOfflineUpload)
      );

      const fileName: string = name !== undefined ? name : DataHelpers.uniqueId();

      while (exists) {
        uniqueName = suffix > 0 ? `${fileName}_${suffix}.${extension}` : `${fileName}.${extension}`;
        exists = offlineUploads.find((offlineUpload) => offlineUpload.uniqueName === uniqueName) !== undefined;
        suffix++;
      }

      const offlineUpload: OfflineUpload = new OfflineUpload();
      offlineUpload.id = DataHelpers.uniqueId();
      offlineUpload.path = path;
      offlineUpload.uniqueName = uniqueName;
      offlineUpload.content = await StorageHelpers.blobToBase64(file);

      await indexedDbModel.setDocument("offlineUploads", offlineUpload.id, offlineUpload.toOfflineCache());
    } else {
      // online
      const fileName: string = name !== undefined ? name : DataHelpers.uniqueId();

      while (exists) {
        uniqueName = suffix > 0 ? `${fileName}_${suffix}.${extension}` : `${fileName}.${extension}`;
        exists = await storageModel.fileExists(path, uniqueName);
        suffix++;
      }

      await storageModel.upload(
        path,
        uniqueName,
        file,
        () => {
          return;
        },
        metadata
      );
    }

    await getDownloadURL(ref(firebase.storage, `${path}/${uniqueName}`));

    const storageFile: StorageFile = new StorageFile();
    storageFile.uniqueName = uniqueName;
    storageFile.displayName = file.name;
    storageFile.type = extension?.toUpperCase() || "";
    storageFile.size = file.size;

    return storageFile;
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageModel.uploadFile", { path, file, name, metadata }, error);
    return new StorageFile();
  }
};
