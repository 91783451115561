import { DocumentReference, DocumentSnapshot, getDoc, setDoc } from "firebase/firestore";

import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Option } from "../objects/Option";

export class OptionModel extends FirestoreModel<Option> {
  public constructor() {
    super(() => new Option(), "options", LockPolicy.None, "option");
  }

  public async getDocument(): Promise<Option> {
    const optionId = "options";
    const documentReference: DocumentReference = this.getDocumentReference(optionId);

    const doc: DocumentSnapshot<Option> = await getDoc(documentReference.withConverter(this.firestoreConverter));
    if (doc.exists() === true) return doc.data() as Option;

    const option = new Option();
    option.setTimestampFields("create");
    await setDoc(documentReference.withConverter(this.firestoreConverter), option);

    const newDoc: DocumentSnapshot<Option> = await getDoc(documentReference.withConverter(this.firestoreConverter));
    if (newDoc.exists() === true) return newDoc.data() as Option;

    throw new Error(`Unable to get document ${optionId} in collection ${this.collectionName}`);
  }

  public async updateDocument(option: Option): Promise<void> {
    return super.updateDocument(option);
  }
}

export const optionModel: OptionModel = new OptionModel();
