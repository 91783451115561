import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { format } from "date-fns";

export const isChangeDutyPossible = async (examination: Examination): Promise<boolean> => {
  try {
    if (examination.company === undefined) throw new Error("companyUndefined");
    if (examination.employee === undefined) throw new Error("employeeUndefined");

    const employeeDutiesByCompany: EmployeeDuty[] = await employeeDutyModel.getDocuments([], examination.employee.id);

    const previousEmployeeDuties: EmployeeDuty[] = employeeDutiesByCompany.filter((employeeDuty: EmployeeDuty) => {
      return (
        employeeDuty.company !== undefined &&
        employeeDuty.company.id === examination.company?.id &&
        employeeDuty.fromSort < format(examination.date, "yyyy-MM-dd") &&
        employeeDuty.toSort >= format(examination.date, "yyyy-MM-dd")
      );
    });
    if (previousEmployeeDuties.length !== 1) return false;

    return true;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.isChangeDutyPossible", { examination }, error);
    return false;
  }
};
