import { HttpsCallable, httpsCallable, HttpsCallableResult } from "firebase/functions";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export class Log {
  /*
  public async debug(message: string, data: Record<string, unknown> = {}): Promise<void> {
    await this.write("DEBUG", message, data);
  }

  public async error(message: string, data: Record<string, unknown> = {}): Promise<void> {
    await this.write("ERROR", message, data);
  }

  public async info(message: string, data: Record<string, unknown> = {}): Promise<void> {
    await this.write("INFO", message, data);
  }

  public async warn(message: string, data: Record<string, unknown> = {}): Promise<void> {
    await this.write("WARN", message, data);
  }
  */

  public async notice(message: string, data: Record<string, unknown> = {}): Promise<void> {
    await this.write("NOTICE", message, data);
  }

  private async write(severity: string, message: string, data: Record<string, unknown> = {}): Promise<boolean> {
    try {
      const user: User = store.getters[storeTypes.getters.getUser];

      const logFunction: HttpsCallable = httpsCallable(firebase.functions, "coreLogWrite");
      const functionResult: HttpsCallableResult = await logFunction({
        severity,
        message,
        user: { id: user.id, email: user.email, firstName: user.firstName, lastName: user.lastName },
        data: data,
      });
      const response: { result: string } = functionResult.data as { result: string };

      if (response === undefined || response.result === undefined) return Promise.resolve(false);
      if (response.result === "error") return Promise.resolve(false);
      if (response.result !== "ok") return Promise.resolve(false);

      return Promise.resolve(true);
    } catch (error: unknown) {
      appFaultModel.catchAppError("Log.write", { severity, message, data }, error);
      return false;
    }
  }
}

export const log: Log = new Log();
