
import { defineComponent } from "vue";

export default defineComponent({
  name: "KoruFieldValue",
  props: {
    width: {
      type: String,
      default: "col-12 md:col-6",
    },
  },
});
