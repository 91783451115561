
import { computed, ComputedRef, defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { TreeNode } from "primevue/tree";
import { useRoute } from "vue-router";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { KoruTreeModule } from "../objects/KoruTreeModule";
import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "KoruTree",
  props: {
    additionalClass: { type: String, default: "" },
    module: { type: KoruTreeModule, default: undefined },
  },
  emits: ["update:module"],
  setup(props, { emit }) {
    const { t } = useLocale();
    const store = useStore();
    let eventBusId: string | undefined = undefined;

    const user: User = store.getters[storeTypes.getters.getUser];

    const moduleRef = computed({
      get: () => props.module as KoruTreeModule<unknown>,
      set: (val) => emit("update:module", val),
    });

    const route = useRoute();
    const requiredModule: string =
      moduleRef.value.explicitRequiredModule !== undefined ? moduleRef.value.explicitRequiredModule : (route.meta.requiredModule as string);

    const nodes: ComputedRef<TreeNode[]> = computed(() => moduleRef.value.computeNodes());

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await moduleRef.value.onMountedAction();
          await moduleRef.value.onLoadAction();
        },
        () => {
          if (moduleRef.value.loadingStop) store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const createAction = async (parent: unknown | undefined) => {
      await AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);
          await moduleRef.value.onCreateAction(parent);
        },
        "create",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const updateAction = async (document: Record<string, unknown>) => {
      await AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);
          await moduleRef.value.onUpdateAction(document);
        },
        "update",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const deleteAction = async (document: FirestoreDocument) => {
      await AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);
          const result: boolean = await moduleRef.value.onDeleteAction(document);

          if (result !== true) {
            ToastHelpers.showToastWithError("delete", undefined, t);
          }
          await moduleRef.value.onLoadAction();
        },
        "delete",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem: Ref<FirestoreDocument | undefined> = ref(undefined);

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      if (deleteItem.value === undefined) return;
      deleteAction(deleteItem.value);
    };

    const getContextCommands = (document: FirestoreDocument) => {
      const renderContextCommands: ContextCommand[] = [];

      if (moduleRef.value.canCreate && (requiredModule == undefined || user.canCreate(requiredModule))) {
        renderContextCommands.push({
          label: t("buttons.create"),
          icon: "pi pi-plus",
          command: (object: Record<string, unknown>) => {
            createAction(object.reference);
          },
        } as ContextCommand);
      }

      if (moduleRef.value.canEdit && (requiredModule == undefined || user.canUpdate(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.edit"),
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            updateAction(object.reference as Record<string, unknown>);
          },
        } as ContextCommand);
      }

      if (moduleRef.value.canDelete && (requiredModule == undefined || user.canDelete(requiredModule, document) !== false)) {
        renderContextCommands.push({
          label: t("buttons.delete"),
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as FirestoreDocument;
            showDeleteItemDialog.value = true;
          },
        } as ContextCommand);
      }

      if (moduleRef.value.contextCommands !== undefined && moduleRef.value.contextCommands.length > 0) {
        const contextCommandsLength = renderContextCommands.length;
        for (const contextCommand of moduleRef.value.contextCommands) {
          if (contextCommand.condition(document) == true) {
            if (renderContextCommands.length == contextCommandsLength && contextCommandsLength > 0) {
              renderContextCommands.push({ label: "separator" } as ContextCommand);
            }
            renderContextCommands.push(contextCommand);
          }
        }
      }

      if (renderContextCommands.length === 0) {
        renderContextCommands.push({
          label: t("buttons.noAction"),
          icon: "pi pi-times",
          condition: () => true,
          command: () => {
            return;
          },
        } as ContextCommand);
      }

      return renderContextCommands;
    };

    onMounted(() => {
      eventBusId = eventBus.on("koruTreeReload", () => handleLoad());

      handleLoad();
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      createAction,
      deleteItemAction,
      DataHelpers,
      deleteItem,
      getContextCommands,
      moduleRef,
      nodes,
      requiredModule,
      showDeleteItemDialog,
      t,
      user,
    };
  },
});
