import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { TestType } from "../objects/TestType";
import { testTypeModel } from "./TestTypeModel";

export class TestTypeOfflineModel extends OfflineModuleModel<TestType> {
  public constructor() {
    super(() => new TestType(), "testTypes", "testType.testTypes", undefined);
  }

  public async downloadCache(): Promise<TestType[]> {
    try {
      return testTypeModel.getDocuments();
    } catch (error: unknown) {
      appFaultModel.catchAppError("TestTypeOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const testTypeOfflineModel: TestTypeOfflineModel = new TestTypeOfflineModel();
