export const it = {
  testTypeCategory: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    testTypeCategory: "Categoria tipo accertamento",
    testTypeCategories: "Categorie tipi accertamento",
  },
  navigation: {
    testTypeCategory: "Categorie tipi accertamento",
  },
};
