export const enCoreLabels = {
  buttons: {
    back: "Back",
    cancel: "Cancel",
    clear: "Clear",
    close: "Close",
    create: "Create",
    createUser: "Create user",
    delete: "Delete",
    download: "Download",
    disableUser: "Disable user",
    edit: "Edit",
    enableUser: "Enable user",
    export: "Export",
    import: "Import",
    load: "Load",
    markAsRead: "Mark as read",
    noAction: "No action available",
    print: "Print",
    run: "Run",
    save: "Save",
    search: "Search",
    send: "Send",
    sendNotification: "Send notification",
    toggleUser: "Toggle state",
    upload: "Upload file",
    view: "View",
  },
  dialog: {
    confirmCloseWithoutSave: "Close without saving?",
    confirmDelete: "Are You sure to delete?",
    editingConfirm: "Unsaved data will be lost, are You sure?",
    editingTitle: "Exit withou save",
  },
  gen: {
    actions: "Actions",
    any: "Any",
    all: "All",
    backTo: "Back to",
    browse: "Browse",
    confirmActionText: "Confirm the execution of the selected action?",
    confirmActionTitle: "Confirm Action",
    dashboard: "Dashboard",
    deleteConfirm: "Are You sure to delete",
    deleteObject: "the selected object?",
    deleteTitle: "Delete Confirmation",
    devAlert: "Development version",
    developedby: "developed by",
    document: "Document",
    editPrefix: "Edit",
    empty: "Empty",
    errorContact: "An error occurred, contact the developer",
    expirationDaysLeft: "days left",
    expirationExpired: "Expired",
    expirationNotAvailable: "Not available",
    file: "Attachment",
    filePlaceholder: "Select a file or drag it here",
    files: "Attachments",
    filter: "Filter",
    findAddress: "Find address",
    generals: "General",
    guest: "Guest",
    info: "Information",
    inputAddress: "Type an address",
    loading: "Loading in progress",
    logout: "Logout",
    maintenanceDescription: "Sorry for the inconvenience. Maintenance is underway, KORU will be back online soon.",
    maintenanceTitle: "We'll be back soon!",
    missingPrivileges: "You don't have the required permission to view the page",
    newPrefix: "New",
    no: "No",
    noData: "No data available",
    ok: "Ok",
    oops: "OOPS!",
    pageNotFound: "The requested page is not available",
    pageResults: "Results from [[first]] to [[last]] of [[totalRecords]] total records",
    profile: "Profile",
    reorder: "Order",
    search: "Search",
    unknown: "unknown",
    user: "User",
    viewPrefix: "View",
    yes: "Yes",
  },
  navigation: {
    about: "About",
    administration: "Admin",
    content: "Content",
    dashboard: "Board",
    development: "Develop",
    general: "General",
    structure: "Structure",
    tools: "Tools",
  },
  rights: {
    create: "Create",
    deleteAll: "Delete All",
    deleteAllowed: "Delete Allowed",
    deleteOwned: "Delete Owned",
    readAll: "Read All",
    readAllowed: "Read Allowed",
    readOwned: "Read Owned",
    updateAll: "Edit All",
    updateAllowed: "Edit Allowed",
    updateOwned: "Edit Owned",
    viewUI: "View UI",
  },
  themes: {
    dim: "Night",
    light: "Light",
    solarized: "Solarized",
  },
  toast: {
    error: {
      actionError: "Unable to complete the action",
      backupDataNotSet: "Backup data not set",
      children: "Linked object still present",
      create: "Unable to create the element",
      delete: "Unable to delete the element",
      deleteFile: "Unable to delete the file",
      emailChanged: "Unable to change the e-mail address",
      feedbackSent: "Unable to send the feedback",
      fiscalCode: "Unable to calculate the fiscal code",
      generic: "Unexpected error",
      noAction: "No action has been selected",
      notificationSent: "Unable to send the notification",
      notSupportedFileExtension: "Unsupported file extension",
      passwordChanged: "Unable to change the password",
      readFile: "Unable to read the file",
      reorder: "Unable to reorder the elements",
      save: "Unable to save the object",
      search: "Unable to perform the search",
      sync: "Data has been edited by another user",
      title: "Error!",
      toggleUser: "Unable to save the state",
      uploadFile: "Unable to upload the file",
    },
    success: {
      actionSuccess: "Action completed successfully",
      backupDownloadSuccess: "Backup generated successfully",
      backupRestoreSuccess: "Backup restored successfully",
      create: "Element created successfully",
      delete: "Element deleted successfully",
      deleteFile: "File deleted successfully",
      emailChanged: "E-mail address changed successfully",
      feedbackSent: "Feedback sent successfully",
      generic: "Operation completed successfully",
      notificationSent: "Notification sent successfully",
      passwordChanged: "Password changed successfully",
      save: "Element saved successfully",
      title: "Completed!",
      toggleUser: "State updated successfully",
      uploadFile: "File uploaded successfully",
    },
    warning: {
      filterMissing: "Select all the filter fields",
      formInvalid: "Check the inserted fields",
      reverseGeocodingError: "Unable to find the address",
      searchNoResults: "No results found",
      searchTextTooShort: "Insert at least 3 characters",
      seederNoModules: "Seleziona almeno una collezione",
      title: "Warning!",
    },
  },
};
