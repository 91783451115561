import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KoruBreadcrumb = _resolveComponent("KoruBreadcrumb")!
  const _component_KoruContainer = _resolveComponent("KoruContainer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.breadcrumbs)
      ? (_openBlock(), _createBlock(_component_KoruBreadcrumb, {
          key: 0,
          items: _ctx.breadcrumbs,
          editRoute: _ctx.editRoute,
          goBack: true
        }, null, 8, ["items", "editRoute"]))
      : _createCommentVNode("", true),
    _createVNode(_component_KoruContainer, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _: 3
    })
  ], 64))
}