import { RouteRecordRaw } from "vue-router";

export const statRoutes: RouteRecordRaw[] = [
  {
    path: "/stats",
    name: "StatView",
    component: () => import("../views/StatView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "stat",
      requiredRight: "viewUI",
    },
  },
];
