import { convertArrayToCSV } from "convert-array-to-csv";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { LinkedCompanyWithStatus } from "@/features/modules/company/objects/LinkedCompanyWithStatus";

import { renderAudioAppRows } from "./methods";

export class ExportEmployeeModel {
  public async exportEmployees(companiesIds: string[]): Promise<string> {
    try {
      let employees: Employee[] = [];

      for (const companyId of companiesIds) {
        let companyEmployees: Employee[] = await employeeModel.getEmployeesByCompany(companyId);
        companyEmployees = companyEmployees.filter((employee: Employee) => {
          if (employee.isExaminationExempted === false) return true;
          const company: LinkedCompanyWithStatus | undefined = employee.getLinkedCompaniesWithStatus().find((company) => company.id === companyId);
          if (company === undefined) return false;
          if (company.isArchived === undefined) return true;
          return false;
        });
        employees = [...employees, ...companyEmployees];
      }

      if (employees.length === 0) throw new Error("noEmployees");

      employees.sort((a: Employee, b: Employee) => {
        if (a.fullName === undefined || b.fullName === undefined) return 0;
        if (a.fullName === b.fullName) return 0;
        return a.fullName > b.fullName ? 1 : -1;
      });

      const csvRows: string[][] = renderAudioAppRows(employees);

      // render csv
      return convertArrayToCSV(csvRows, { separator: ";" }).replaceAll('""', "");
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExportEmployeeModel.exportEmployees", { companiesIds }, error);
      return "ERROR";
    }
  }
}

export const exportEmployeeModel: ExportEmployeeModel = new ExportEmployeeModel();
