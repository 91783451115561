import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";
import { format } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doc } from "../objects/Doc";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class DocModel extends FirestoreModel<Doc> {
  public constructor() {
    super(() => new Doc(), "docs", LockPolicy.DiscardUnsyncedChanges, "doc");
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string, customQuery?: Query<Doc>): Promise<Doc[]> {
    return super.getDocuments(sortCriterias, parentId, customQuery);
  }

  public async getDocument(docId: string, parentId?: string): Promise<Doc> {
    return super.getDocument(docId, parentId);
  }

  public async createDocument(doc: Doc, parentId?: string): Promise<string> {
    return super.createDocument(doc, parentId);
  }

  public async updateDocument(doc: Doc, parentId?: string): Promise<void> {
    return super.updateDocument(doc, parentId);
  }

  public async deleteDocument(doc: Doc, parentId?: string): Promise<boolean> {
    return super.deleteDocument(doc, parentId);
  }

  public async getDocsByPeriod(startDate: Date, endDate: Date): Promise<Doc[]> {
    try {
      if (startDate === undefined || endDate === undefined) throw new Error("invalid parameters");

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const snapshotQuery: Query<Doc> = query(
        this.getPathReference().withConverter(this.firestoreConverter),
        where("date", ">=", Timestamp.fromDate(startDate)),
        where("date", "<=", Timestamp.fromDate(endDate))
      );

      const snapshot: QuerySnapshot<Doc> = await getDocs(this.addReadConditionToQuery(snapshotQuery));

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      appFaultModel.catchAppError("DocModel.getDocsByPeriod", { startDate, endDate }, error);
      return [];
    }
  }

  public async getDocsInForce(parentId?: string): Promise<Doc[]> {
    try {
      const today: Date = new Date();

      const snapshotQuery: Query<Doc> = query(
        this.getPathReference(parentId).withConverter(this.firestoreConverter),
        where("expirationSort", ">=", format(today, "yyyy-MM-dd"))
      );

      const snapshot: QuerySnapshot<Doc> = await getDocs(this.addReadConditionToQuery(snapshotQuery));

      if (snapshot == undefined || snapshot.empty) return [];

      const sorter: FirestoreSorter<Doc> = new FirestoreSorter(snapshot.docs.map((doc) => doc.data()));
      sorter.addSortCriteria("date", "desc", "date");
      return sorter.sort();
    } catch (error: unknown) {
      appFaultModel.catchAppError("DocModel.getDocsInForce", {}, error);
      return [];
    }
  }
}
