import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export const getAssistState = (): "connected" | "disconnected" => {
  try {
    const localStorageAssistState: string | null = localStorage.getItem("koruAssistState");

    return localStorageAssistState !== null && localStorageAssistState.trim().length > 0
      ? (localStorageAssistState as "connected" | "disconnected")
      : "disconnected";
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.getAssistState", {}, error);
    return "disconnected";
  }
};
