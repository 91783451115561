import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { TestForm } from "@/features/modules/testForm/objects/TestForm";

import { DateField, NumberWithDefaultField, ObjectField, StringStrictField } from "@/core/fields";

import { AudioTestForm } from "@/features/modules/testForm/objects/AudioTestForm";
import { GenTestForm } from "@/features/modules/testForm/objects/GenTestForm";
import { SpiroTestForm } from "@/features/modules/testForm/objects/SpiroTestForm";
import { VisioTestForm } from "@/features/modules/testForm/objects/VisioTestForm";

export class ExaminationTest {
  public id: string = DataHelpers.uniqueId();
  public testType: LinkedTestType | undefined = undefined;
  public date: Date | undefined = undefined;
  public file: StorageFile | undefined = undefined;
  public testForm: TestForm | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): ExaminationTest {
    this.id = StringStrictField.fromFirestore(data.id, DataHelpers.uniqueId());
    this.testType = ObjectField.fromFirestore<LinkedTestType>(data.testType, (value) => new LinkedTestType(value));
    this.date = DateField.fromFirestore(data.date);
    this.file = ObjectField.fromFirestore<StorageFile>(data.file, (value) => new StorageFile(value));
    this.testFormFromFirestore(data);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.id = StringStrictField.toFirestore(this.id);
    firestoreData.testType = ObjectField.toFirestore<LinkedTestType>(this.testType, (value) => value.toFirestore());
    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.file = ObjectField.toFirestore<StorageFile>(this.file, (value) => value.toFirestore());
    firestoreData.testForm = this.testFormToFirestore();
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public createTestForm(): void {
    if (this.testType?.formName === undefined) return;
    if (this.testType?.formName === "audioTestForm") {
      this.testForm = new AudioTestForm();
    } else if (this.testType?.formName === "genTestForm") {
      this.testForm = new GenTestForm();
    } else if (this.testType?.formName === "spiroTestForm") {
      this.testForm = new SpiroTestForm();
    } else if (this.testType?.formName === "visioTestForm") {
      this.testForm = new VisioTestForm();
    }
  }

  public testFormFromFirestore(data: Record<string, unknown>): void {
    if (this.testType?.formName === undefined) {
      this.testForm = undefined;
      return;
    }
    if (this.testType?.formName === "audioTestForm") {
      this.testForm = ObjectField.fromFirestore<AudioTestForm>(data.testForm, (value) => new AudioTestForm(value));
    } else if (this.testType?.formName === "genTestForm") {
      this.testForm = ObjectField.fromFirestore<GenTestForm>(data.testForm, (value) => new GenTestForm(value));
    } else if (this.testType?.formName === "spiroTestForm") {
      this.testForm = ObjectField.fromFirestore<SpiroTestForm>(data.testForm, (value) => new SpiroTestForm(value));
    } else if (this.testType?.formName === "visioTestForm") {
      this.testForm = ObjectField.fromFirestore<VisioTestForm>(data.testForm, (value) => new VisioTestForm(value));
    }
  }

  public testFormToFirestore(): Record<string, unknown> | null {
    if (this.testType?.formName === undefined) return null;
    if (this.testType?.formName === "audioTestForm") {
      return ObjectField.toFirestore<AudioTestForm>(this.testForm as AudioTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "genTestForm") {
      return ObjectField.toFirestore<GenTestForm>(this.testForm as GenTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "spiroTestForm") {
      return ObjectField.toFirestore<SpiroTestForm>(this.testForm as SpiroTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "visioTestForm") {
      return ObjectField.toFirestore<VisioTestForm>(this.testForm as VisioTestForm, (value) => value.toFirestore());
    }
    return null;
  }
}
