
import { defineComponent, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { config } from "@/core/modules/config/objects/Config";
import { Theme } from "../objects/Theme";
import { themeModel } from "../models/ThemeModel";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "ThemeSelector",
  setup() {
    const { t } = useLocale();

    const selectedTheme: Ref<Theme> = ref(themeModel.getSelectedTheme());
    const themes: Theme[] = themeModel.getThemes();

    return {
      config,
      selectedTheme,
      t,
      themeModel,
      themes,
    };
  },
  mounted() {
    this.selectTheme(this.selectedTheme);
  },
  methods: {
    async selectTheme(theme: Theme): Promise<void> {
      AppHelpers.tryOrError(async () => {
        this.selectedTheme = theme;
        this.themeModel.setSelectedTheme(theme);
        this.changeColorScheme(theme);
      });
    },
    changeColorScheme(theme: Theme): void {
      this.changeStyleSheetUrl("layout-css", "layout-" + theme.name + ".css", 1);
      this.changeStyleSheetUrl("theme-css", "theme-" + theme.name + ".css", 1);
      this.changeLogo(theme.icon);
    },
    changeStyleSheetUrl(id: string, value: string, from: number): void {
      const element = document.getElementById(id);
      const urlTokens = element?.getAttribute("href")?.split("/");
      if (urlTokens !== undefined) {
        if (from === 1) {
          // which function invoked this function
          urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
          // which function invoked this function
          if (value !== null) {
            urlTokens[urlTokens.length - 2] = value;
          }
        } else if (from === 3) {
          // which function invoked this function
          urlTokens[urlTokens.length - 2] = value;
        }
        const newURL = urlTokens.join("/");
        this.replaceLink(element, newURL);
      }
    },
    replaceLink(linkElement: Element | null, href: string): void {
      if (linkElement == null) {
        return;
      }
      if (this.isIE()) {
        linkElement.setAttribute("href", href);
      } else {
        const id = linkElement.getAttribute("id");
        const cloneLinkElement = linkElement.cloneNode(true) as HTMLElement;
        cloneLinkElement.setAttribute("href", href);
        cloneLinkElement.setAttribute("id", id + "-clone");
        linkElement.parentNode?.insertBefore(cloneLinkElement, linkElement.nextSibling);
        cloneLinkElement.addEventListener("load", () => {
          linkElement.remove();
          if (id !== null) {
            cloneLinkElement.setAttribute("id", id);
          }
        });
      }
    },
    changeLogo(themeIcon: string): void {
      const mobileLogoLink = document.getElementById("logo-mobile") as HTMLImageElement;
      const mobileLogoUrl = `/assets/features/images/logo-${themeIcon}.svg`;
      if (mobileLogoLink) mobileLogoLink.src = mobileLogoUrl;

      const footerLogoLink = document.getElementById("footer-logo") as HTMLImageElement;
      const footerLogoUrl = `/assets/features/images/logo-${themeIcon}.svg`;
      if (footerLogoLink) footerLogoLink.src = footerLogoUrl;
    },
    isIE(): boolean {
      return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    },
  },
});
