import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { assistModel } from "../AssistModel";
import { ExaminationHelpers } from "@/features/modules/examination/helpers/ExaminationHelpers";
import { ExaminationTest } from "@/features/modules/examination/objects/ExaminationTest";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { setting } from "@/core/modules/setting/Setting";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { storageModel } from "@/core/modules/storage/models/StorageModel";

export const uploadDrugTest = async (base64File: string): Promise<void> => {
  try {
    if (base64File === undefined) throw new Error("base64File is undefined");
    if (assistModel.examination === undefined) throw new Error("examination is undefined");

    const drugTest: ExaminationTest | undefined = assistModel.examination
      .getExaminationTests()
      .find((examinationTest: ExaminationTest) => examinationTest.testType?.id === setting.getSetting<string>("drugTestTypeId"));
    if (drugTest === undefined) throw new Error("drug test type not found in examination");

    const fileName: string = FeaturesHelpers.renderExaminationTestFileName(assistModel.examination, drugTest);

    const storageFile: StorageFile = await storageModel.uploadBase64(
      ExaminationHelpers.getFolderPath(
        assistModel.examination.firm?.id,
        assistModel.examination.company?.id,
        assistModel.examination.employee?.id,
        assistModel.examination.id
      ),
      `${fileName}.pdf`,
      base64File,
      { examination: assistModel.examination.id, company: assistModel.examination.company?.id ?? "null" }
    );

    assistModel.examination.examinationTests[drugTest.id].file = storageFile;

    console.log(`Drug test uploaded with filename: ${storageFile.uniqueName}`);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.uploadDrugTest", { base64File }, error);
  }
};
