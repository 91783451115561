
import { defineComponent } from "vue";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { AppError } from "../modules/appFault/objects/AppError";

export default defineComponent({
  name: "Base",
  errorCaptured(error: unknown) {
    if (error instanceof AppError) {
      error.addToStack("Vue.errorCaptured", {}, error);
      console.log(error);
      appFaultModel.createAppFaultFromAppError(error);
    }
    return false;
  },
});
