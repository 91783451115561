export const it = {
  offline: {
    cacheRowName: "Sezione",
    cacheRowCount: "Conteggio",
    downloadTitle: "Scarica dati per uso offline",
    offline: "Dati offline",
    queueRowName: "Sezione",
    queueRowCreate: "Crea",
    queueRowDelete: "Elimina",
    queueRowUpdate: "Modifica",
    lastUpdate: "Ultimo aggiornamento",
    networkStates: {
      online: "Online",
      offline: "Offline",
    },
    noActionsWhileOffline: "Non puoi eseguire azioni mentre sei offline",
    offlineDetected: "La connessione a internet è stata interrotta, ma il tuo stato è impostato a online. Cosa vuoi fare?",
    onlineDetected: "È stata rilevata la connessione ad internet, ma il tuo stato è impostato a offline. Cosa vuoi fare?",
    presentState: "Stato attuale",
    states: {
      online: "Online",
      offline: "Offline",
    },
    toggleTitle: "Cambio di stato rilevato",
    uploadTitle: "Carica dati offline su cloud",
  },
  navigation: {
    offline: "Dati offline",
  },
  buttons: {
    deleteOfflineData: "Elimina dati",
    downloadOfflineData: "Scarica dati",
    uploadOfflineData: "Carica dati",
    setOffline: "Vai offline",
    setOnline: "Vai online",
    stayOffline: "Rimani offline",
    stayOnline: "Rimani online",
  },
};
