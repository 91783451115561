import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { OfflineEdit } from "./OfflineEdit";
import { OfflineHelpers } from "../helpers/OfflineHelpers";

export class OfflineModuleModel<T extends FirestoreOfflineDocument> {
  public canCreate = false;
  public canDelete = false;
  public canUpdate = false;
  public collectionName: string;
  public displayNameEntry: string;
  public newIstance: () => T;
  public requiredModule: string | undefined = undefined;

  public constructor(newIstance: () => T, collectionName: string, displayNameEntry: string, requiredModule: string | undefined) {
    this.collectionName = collectionName;
    this.displayNameEntry = displayNameEntry;
    this.newIstance = newIstance;
    this.requiredModule = requiredModule;
  }

  public async createDocumentCallback(document: T): Promise<boolean> {
    console.log("createDocumentCallback", document);
    return Promise.resolve(true);
  }

  public async createDocumentToQueue(document: T): Promise<void> {
    AppHelpers.tryOrSilent(async () => {
      const offlineEdit: OfflineEdit = new OfflineEdit();
      offlineEdit.type = "create";
      offlineEdit.collectionName = this.collectionName;
      offlineEdit.id = document.id;
      offlineEdit.object = document.toOfflineCache();

      await indexedDbModel.setDocument("offlineEdits", offlineEdit.id, offlineEdit.toOfflineCache());

      eventBus.emit("offlineDocumentQueueChanged");
    });
  }

  public async deleteDocumentCallback(document: T): Promise<boolean> {
    console.log("deleteDocumentCallback", document);
    return Promise.resolve(true);
  }

  public async deleteDocumentToQueue(document: T): Promise<void> {
    AppHelpers.tryOrSilent(async () => {
      // search for previous edits
      const previousOfflineEdit: OfflineEdit | undefined = await OfflineHelpers.getPreviousOfflineEdit(this.collectionName, document.id);

      if (previousOfflineEdit === undefined) {
        // no previous edits, delete
        const offlineEdit: OfflineEdit = new OfflineEdit();
        offlineEdit.type = "delete";
        offlineEdit.collectionName = this.collectionName;
        offlineEdit.id = document.id;
        offlineEdit.object = {};

        return await indexedDbModel.setDocument("offlineEdits", document.id, offlineEdit.toOfflineCache());
      }

      if (previousOfflineEdit.type === "create") {
        // delete previous create
        return await indexedDbModel.deleteDocument("offlineEdits", document.id);
      } else if (previousOfflineEdit.type === "update") {
        // delete previous update and create new delete
        await indexedDbModel.deleteDocument("offlineEdits", document.id);

        const offlineEdit: OfflineEdit = new OfflineEdit();
        offlineEdit.type = "delete";
        offlineEdit.collectionName = this.collectionName;
        offlineEdit.id = document.id;
        offlineEdit.object = {};

        return await indexedDbModel.setDocument("offlineEdits", document.id, offlineEdit.toOfflineCache());
      }

      eventBus.emit("offlineDocumentQueueChanged");
    });
  }

  public async downloadCache(): Promise<T[]> {
    return Promise.resolve([]);
  }

  public async updateDocumentCallback(document: T): Promise<boolean> {
    console.log("updateDocumentCallback", document);
    return Promise.resolve(true);
  }

  public async updateDocumentToQueue(document: T): Promise<void> {
    AppHelpers.tryOrSilent(async () => {
      // search for previous edits
      const previousOfflineEdit: OfflineEdit | undefined = await OfflineHelpers.getPreviousOfflineEdit(this.collectionName, document.id);

      if (previousOfflineEdit === undefined) {
        // no previous edits, create new
        const offlineEdit: OfflineEdit = new OfflineEdit();
        offlineEdit.type = "update";
        offlineEdit.collectionName = this.collectionName;
        offlineEdit.id = document.id;
        offlineEdit.object = document.toOfflineCache();

        return await indexedDbModel.setDocument("offlineEdits", document.id, offlineEdit.toOfflineCache());
      }

      if (previousOfflineEdit.type === "create" || previousOfflineEdit.type === "update") {
        // update fields of previous create
        previousOfflineEdit.object = document.toOfflineCache();

        return await indexedDbModel.setDocument("offlineEdits", document.id, previousOfflineEdit.toOfflineCache());
      }

      eventBus.emit("offlineDocumentQueueChanged");
    });
  }
}
