export const itFeaturesLabels = {
  buttons: {
    createExamination: "Nuova visita medica",
    cloneExamination: "Duplica visita medica",
    disableTrackExpirations: "Disabilita controllo scadenze",
    enableTrackExpirations: "Abilita controllo scadenze",
    exportCompanyDuty: "Esporta mansione",
    openFile: "Apri allegato",
    openFiles: "Apri allegati",
  },
  frequencies: {
    "0": "Da mansione",
    "1": "Mensile",
    "3": "Trimestrale",
    "6": "Semestrale",
    "12": "Annuale",
    "24": "Biennale",
    "36": "Triennale",
    "48": "Quadriennale",
    "60": "Quinquennale",
    "999": "Non prevista",
  },
  gen: {
    expiration: "Scadenza",
    noExpiration: "Non prevista",
    paper: {
      emailPrefix: "e-mail",
      fiscalCodePrefix: "C.F.",
      phonePrefix: "Tel.",
      vatCodePrefix: "P.IVA",
    },
  },
  navigation: {
    accounting: "Finanze",
    register: "Registro",
    services: "Servizi",
    tools: "Strumenti",
  },
  sexs: {
    female: "Femmina",
    male: "Maschio",
  },
  toast: {
    error: {
      assignDutyIdEmpty: "Selezionare una mansione da assegnare",
      companyFindDuplicated: "La partita IVA è già presente",
      companyFindError: "Impossibile ottenere i dati",
      dutyDuplicated: "La mansione selezionata è già stata inserita",
      createContractNoFirm: "Selezionare una ditta per proseguire",
      editEvent: "Non è stato possibile modificare l'evento",
      employerAtLeastOne: "Deve esserci almeno un datore di lavoro",
      factoryCalendar: "Calendario bloccato",
      importDutyIdEmpty: "Selezionare una mansione da importare",
      invoiceAddNoSelected: "Non è stata selezionata alcuna prestazione",
      getPecFromVatError: "Impossibile recuperare il dato",
      print: "Non è stato possibile stampare il documento",
      riskDuplicated: "Il rischio selezionato è già stata inserito",
      testTypeDuplicated: "L'accertamento selezionato è già stata inserito",
    },
    success: {
      dutyExported: "La mansione è stata salvata come modello",
      editEvent: "Evento modificato con successo",
    },
    warning: {
      companyFindEmpty: "Inserire la partita IVA",
      companyFindNotFound: "Nessuna corrispondenza trovata",
      getPecFromVatEmpty: "Inserire la partita IVA",
      getPecFromVatNotFound: "Indirizzo non trovato",
    },
  },
  widgets: {
    expiredCount: "Scaduti",
    expiringCount: "In scadenza",
    missingCount: "Mancanti",
    openCount: "Aperti",
  },
};
