import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

import { AllergySurveyForm } from "../objects/AllergySurveyForm";
import { AllergySurveyFormPaper } from "../papers/AllergySurveyFormPaper";
import { AuditShortSurveyFormPaper } from "../papers/AuditShortSurveyFormPaper";
import { AuditShortSurveyForm } from "../objects/AuditShortSurveyForm";
import { AuditSurveyForm } from "../objects/AuditSurveyForm";
import { AuditSurveyFormPaper } from "../papers/AuditSurveyFormPaper";
import { BronchitisSurveyForm } from "../objects/BronchitisSurveyForm";
import { BronchitisSurveyFormPaper } from "../papers/BronchitisSurveyFormPaper";
import { EmSurveyForm } from "../objects/EmSurveyForm";
import { EmSurveyFormPaper } from "../papers/EmSurveyFormPaper";
import { EquilibriumSurveyForm } from "../objects/EquilibriumSurveyForm";
import { EquilibriumSurveyFormPaper } from "../papers/EquilibriumSurveyFormPaper";
import { GasSurveyForm } from "../objects/GasSurveyForm";
import { GasSurveyFormPaper } from "../papers/GasSurveyFormPaper";
import { NoseSurveyForm } from "../objects/NoseSurveyForm";
import { NoseSurveyFormPaper } from "../papers/NoseSurveyFormPaper";
import { RachisExamSurveyForm } from "../objects/RachisExamSurveyForm";
import { RachisExamSurveyFormPaper } from "../papers/RachisExamSurveyFormPaper";
import { RachisSurveyForm } from "../objects/RachisSurveyForm";
import { RachisSurveyFormPaper } from "../papers/RachisSurveyFormPaper";
import { UpperExamSurveyForm } from "../objects/UpperExamSurveyForm";
import { UpperExamSurveyFormPaper } from "../papers/UpperExamSurveyFormPaper";
import { UpperSurveyForm } from "../objects/UpperSurveyForm";
import { UpperSurveyFormPaper } from "../papers/UpperSurveyFormPaper";
import { VdtSurveyForm } from "../objects/VdtSurveyForm";
import { VdtSurveyFormPaper } from "../papers/VdtSurveyFormPaper";

export class SurveyFormHelpers {
  public static async printSurveyForm(
    survey: LinkedSurvey,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<void> {
    if (survey.formName === "allergySurveyForm") {
      const allergySurveyFormPaper: AllergySurveyFormPaper = new AllergySurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AllergySurveyForm,
        d,
        n,
        t
      );
      await allergySurveyFormPaper.open();
    } else if (survey.formName === "auditSurveyForm") {
      const auditSurveyFormPaper: AuditSurveyFormPaper = new AuditSurveyFormPaper(examination, survey, survey.surveyForm as AuditSurveyForm, d, n, t);
      await auditSurveyFormPaper.open();
    } else if (survey.formName === "auditShortSurveyForm") {
      const auditShortSurveyFormPaper: AuditShortSurveyFormPaper = new AuditShortSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AuditShortSurveyForm,
        d,
        n,
        t
      );
      await auditShortSurveyFormPaper.open();
    } else if (survey.formName === "bronchitisSurveyForm") {
      const bronchitisSurveyFormPaper: BronchitisSurveyFormPaper = new BronchitisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as BronchitisSurveyForm,
        d,
        n,
        t
      );
      await bronchitisSurveyFormPaper.open();
    } else if (survey.formName === "emSurveyForm") {
      const emSurveyFormPaper: EmSurveyFormPaper = new EmSurveyFormPaper(examination, survey, survey.surveyForm as EmSurveyForm, d, n, t);
      await emSurveyFormPaper.open();
    } else if (survey.formName === "equilibriumSurveyForm") {
      const equilibriumSurveyFormPaper: EquilibriumSurveyFormPaper = new EquilibriumSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as EquilibriumSurveyForm,
        d,
        n,
        t
      );
      await equilibriumSurveyFormPaper.open();
    } else if (survey.formName === "gasSurveyForm") {
      const gasSurveyFormPaper: GasSurveyFormPaper = new GasSurveyFormPaper(examination, survey, survey.surveyForm as GasSurveyForm, d, n, t);
      await gasSurveyFormPaper.open();
    } else if (survey.formName === "noseSurveyForm") {
      const noseSurveyFormPaper: NoseSurveyFormPaper = new NoseSurveyFormPaper(examination, survey, survey.surveyForm as NoseSurveyForm, d, n, t);
      await noseSurveyFormPaper.open();
    } else if (survey.formName === "rachisExamSurveyForm") {
      const rachisExamSurveyFormPaper: RachisExamSurveyFormPaper = new RachisExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisExamSurveyForm,
        d,
        n,
        t
      );
      return await rachisExamSurveyFormPaper.open();
    } else if (survey.formName === "rachisSurveyForm") {
      const rachisSurveyFormPaper: RachisSurveyFormPaper = new RachisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisSurveyForm,
        d,
        n,
        t
      );
      return await rachisSurveyFormPaper.open();
    } else if (survey.formName === "upperExamSurveyForm") {
      const upperExamSurveyFormPaper: UpperExamSurveyFormPaper = new UpperExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as UpperExamSurveyForm,
        d,
        n,
        t
      );
      return await upperExamSurveyFormPaper.open();
    } else if (survey.formName === "upperSurveyForm") {
      const upperSurveyFormPaper: UpperSurveyFormPaper = new UpperSurveyFormPaper(examination, survey, survey.surveyForm as UpperSurveyForm, d, n, t);
      return await upperSurveyFormPaper.open();
    } else if (survey.formName === "vdtSurveyForm") {
      const vdtSurveyFormPaper: VdtSurveyFormPaper = new VdtSurveyFormPaper(examination, survey, survey.surveyForm as VdtSurveyForm, d, n, t);
      return await vdtSurveyFormPaper.open();
    }
  }

  public static async outputSurveyForm(
    survey: LinkedSurvey,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<string | undefined> {
    if (survey.formName === "allergySurveyForm") {
      const allergySurveyFormPaper: AllergySurveyFormPaper = new AllergySurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AllergySurveyForm,
        d,
        n,
        t
      );
      return await allergySurveyFormPaper.outputBase64();
    } else if (survey.formName === "auditSurveyForm") {
      const auditSurveyFormPaper: AuditSurveyFormPaper = new AuditSurveyFormPaper(examination, survey, survey.surveyForm as AuditSurveyForm, d, n, t);
      return await auditSurveyFormPaper.outputBase64();
    } else if (survey.formName === "auditShortSurveyForm") {
      const auditShortSurveyFormPaper: AuditShortSurveyFormPaper = new AuditShortSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AuditShortSurveyForm,
        d,
        n,
        t
      );
      return await auditShortSurveyFormPaper.outputBase64();
    } else if (survey.formName === "emSurveyForm") {
      const emSurveyFormPaper: EmSurveyFormPaper = new EmSurveyFormPaper(examination, survey, survey.surveyForm as EmSurveyForm, d, n, t);
      return await emSurveyFormPaper.outputBase64();
    } else if (survey.formName === "bronchitisSurveyForm") {
      const bronchitisSurveyFormPaper: BronchitisSurveyFormPaper = new BronchitisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as BronchitisSurveyForm,
        d,
        n,
        t
      );
      return await bronchitisSurveyFormPaper.outputBase64();
    } else if (survey.formName === "equilibriumSurveyForm") {
      const equilibriumSurveyFormPaper: EquilibriumSurveyFormPaper = new EquilibriumSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as EquilibriumSurveyForm,
        d,
        n,
        t
      );
      return await equilibriumSurveyFormPaper.outputBase64();
    } else if (survey.formName === "gasSurveyForm") {
      const gasSurveyFormPaper: GasSurveyFormPaper = new GasSurveyFormPaper(examination, survey, survey.surveyForm as GasSurveyForm, d, n, t);
      return await gasSurveyFormPaper.outputBase64();
    } else if (survey.formName === "noseSurveyForm") {
      const noseSurveyFormPaper: NoseSurveyFormPaper = new NoseSurveyFormPaper(examination, survey, survey.surveyForm as NoseSurveyForm, d, n, t);
      return await noseSurveyFormPaper.outputBase64();
    } else if (survey.formName === "rachisExamSurveyForm") {
      const rachisExamSurveyFormPaper: RachisExamSurveyFormPaper = new RachisExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisExamSurveyForm,
        d,
        n,
        t
      );
      return await rachisExamSurveyFormPaper.outputBase64();
    } else if (survey.formName === "rachisSurveyForm") {
      const rachisSurveyFormPaper: RachisSurveyFormPaper = new RachisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisSurveyForm,
        d,
        n,
        t
      );
      return await rachisSurveyFormPaper.outputBase64();
    } else if (survey.formName === "upperExamSurveyForm") {
      const upperExamSurveyFormPaper: UpperExamSurveyFormPaper = new UpperExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as UpperExamSurveyForm,
        d,
        n,
        t
      );
      return await upperExamSurveyFormPaper.outputBase64();
    } else if (survey.formName === "upperSurveyForm") {
      const upperSurveyFormPaper: UpperSurveyFormPaper = new UpperSurveyFormPaper(examination, survey, survey.surveyForm as UpperSurveyForm, d, n, t);
      return await upperSurveyFormPaper.outputBase64();
    } else if (survey.formName === "vdtSurveyForm") {
      const vdtSurveyFormPaper: VdtSurveyFormPaper = new VdtSurveyFormPaper(examination, survey, survey.surveyForm as VdtSurveyForm, d, n, t);
      return await vdtSurveyFormPaper.outputBase64();
    }
    return undefined;
  }

  public static async writeSurveyForm(
    survey: LinkedSurvey,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    isContinuous = true
  ): Promise<Record<string, unknown>[]> {
    if (survey.formName === "allergySurveyForm") {
      const allergySurveyFormPaper: AllergySurveyFormPaper = new AllergySurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AllergySurveyForm,
        d,
        n,
        t
      );
      return await allergySurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "auditSurveyForm") {
      const auditSurveyFormPaper: AuditSurveyFormPaper = new AuditSurveyFormPaper(examination, survey, survey.surveyForm as AuditSurveyForm, d, n, t);
      return await auditSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "auditShortSurveyForm") {
      const auditShortSurveyFormPaper: AuditShortSurveyFormPaper = new AuditShortSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AuditShortSurveyForm,
        d,
        n,
        t
      );
      return await auditShortSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "emSurveyForm") {
      const emSurveyFormPaper: EmSurveyFormPaper = new EmSurveyFormPaper(examination, survey, survey.surveyForm as EmSurveyForm, d, n, t);
      return await emSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "bronchitisSurveyForm") {
      const bronchitisSurveyFormPaper: BronchitisSurveyFormPaper = new BronchitisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as BronchitisSurveyForm,
        d,
        n,
        t
      );
      return await bronchitisSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "equilibriumSurveyForm") {
      const equilibriumSurveyFormPaper: EquilibriumSurveyFormPaper = new EquilibriumSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as EquilibriumSurveyForm,
        d,
        n,
        t
      );
      return await equilibriumSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "gasSurveyForm") {
      const gasSurveyFormPaper: GasSurveyFormPaper = new GasSurveyFormPaper(examination, survey, survey.surveyForm as GasSurveyForm, d, n, t);
      return await gasSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "noseSurveyForm") {
      const noseSurveyFormPaper: NoseSurveyFormPaper = new NoseSurveyFormPaper(examination, survey, survey.surveyForm as NoseSurveyForm, d, n, t);
      return await noseSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "rachisSurveyForm") {
      const rachisSurveyFormPaper: RachisSurveyFormPaper = new RachisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisSurveyForm,
        d,
        n,
        t
      );
      return await rachisSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "rachisExamSurveyForm") {
      const rachisExamSurveyFormPaper: RachisExamSurveyFormPaper = new RachisExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisExamSurveyForm,
        d,
        n,
        t
      );
      return await rachisExamSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "upperSurveyForm") {
      const upperSurveyFormPaper: UpperSurveyFormPaper = new UpperSurveyFormPaper(examination, survey, survey.surveyForm as UpperSurveyForm, d, n, t);
      return await upperSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "upperExamSurveyForm") {
      const upperExamSurveyFormPaper: UpperExamSurveyFormPaper = new UpperExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as UpperExamSurveyForm,
        d,
        n,
        t
      );
      return await upperExamSurveyFormPaper.write(isContinuous);
    } else if (survey.formName === "vdtSurveyForm") {
      const vdtSurveyFormPaper: VdtSurveyFormPaper = new VdtSurveyFormPaper(examination, survey, survey.surveyForm as VdtSurveyForm, d, n, t);
      return await vdtSurveyFormPaper.write(isContinuous);
    }

    return [];
  }

  public static async setStylesSurveyForm(
    survey: LinkedSurvey,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<Record<string, unknown>> {
    if (survey.formName === "allergySurveyForm") {
      const allergySurveyFormPaper: AllergySurveyFormPaper = new AllergySurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AllergySurveyForm,
        d,
        n,
        t
      );
      return allergySurveyFormPaper.setStyles();
    } else if (survey.formName === "auditSurveyForm") {
      const auditSurveyFormPaper: AuditSurveyFormPaper = new AuditSurveyFormPaper(examination, survey, survey.surveyForm as AuditSurveyForm, d, n, t);
      return auditSurveyFormPaper.setStyles();
    } else if (survey.formName === "auditShortSurveyForm") {
      const auditShortSurveyFormPaper: AuditShortSurveyFormPaper = new AuditShortSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as AuditShortSurveyForm,
        d,
        n,
        t
      );
      return auditShortSurveyFormPaper.setStyles();
    } else if (survey.formName === "bronchitisSurveyForm") {
      const bronchitisSurveyFormPaper: BronchitisSurveyFormPaper = new BronchitisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as BronchitisSurveyForm,
        d,
        n,
        t
      );
      return bronchitisSurveyFormPaper.setStyles();
    } else if (survey.formName === "emSurveyForm") {
      const emSurveyFormPaper: EmSurveyFormPaper = new EmSurveyFormPaper(examination, survey, survey.surveyForm as EmSurveyForm, d, n, t);
      return emSurveyFormPaper.setStyles();
    } else if (survey.formName === "equilibriumSurveyForm") {
      const equilibriumSurveyFormPaper: EquilibriumSurveyFormPaper = new EquilibriumSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as EquilibriumSurveyForm,
        d,
        n,
        t
      );
      return equilibriumSurveyFormPaper.setStyles();
    } else if (survey.formName === "gasSurveyForm") {
      const gasSurveyFormPaper: GasSurveyFormPaper = new GasSurveyFormPaper(examination, survey, survey.surveyForm as GasSurveyForm, d, n, t);
      return gasSurveyFormPaper.setStyles();
    } else if (survey.formName === "noseSurveyForm") {
      const noseSurveyFormPaper: NoseSurveyFormPaper = new NoseSurveyFormPaper(examination, survey, survey.surveyForm as NoseSurveyForm, d, n, t);
      return noseSurveyFormPaper.setStyles();
    } else if (survey.formName === "rachisExamSurveyForm") {
      const rachisExamSurveyFormPaper: RachisExamSurveyFormPaper = new RachisExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisExamSurveyForm,
        d,
        n,
        t
      );
      return rachisExamSurveyFormPaper.setStyles();
    } else if (survey.formName === "rachisSurveyForm") {
      const rachisSurveyFormPaper: RachisSurveyFormPaper = new RachisSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as RachisSurveyForm,
        d,
        n,
        t
      );
      return rachisSurveyFormPaper.setStyles();
    } else if (survey.formName === "upperExamSurveyForm") {
      const upperExamSurveyFormPaper: UpperExamSurveyFormPaper = new UpperExamSurveyFormPaper(
        examination,
        survey,
        survey.surveyForm as UpperExamSurveyForm,
        d,
        n,
        t
      );
      return upperExamSurveyFormPaper.setStyles();
    } else if (survey.formName === "upperSurveyForm") {
      const upperSurveyFormPaper: UpperSurveyFormPaper = new UpperSurveyFormPaper(examination, survey, survey.surveyForm as UpperSurveyForm, d, n, t);
      return upperSurveyFormPaper.setStyles();
    } else if (survey.formName === "vdtSurveyForm") {
      const vdtSurveyFormPaper: VdtSurveyFormPaper = new VdtSurveyFormPaper(examination, survey, survey.surveyForm as VdtSurveyForm, d, n, t);
      return vdtSurveyFormPaper.setStyles();
    }
    return {};
  }
}
