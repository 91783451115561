import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "../EmployeeModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const isFiscalCodeUnique = async (fiscalCode: string, employeeId = "empty"): Promise<boolean> => {
  try {
    if (fiscalCode === undefined) return false;

    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (employeeModel.offlineModuleModel === undefined) return false;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Employee[] = (await offlineModel.getCacheByCollection(employeeModel.offlineModuleModel)) as Employee[];
      const filteredDocuments: Employee[] = documents.filter((employee) => employee.fiscalCode === fiscalCode);
      return filteredDocuments.length === 0 || (filteredDocuments.length === 1 && filteredDocuments[0].id === employeeId);
    }

    return CloudHelpers.runValidatorFunction("featuresEmployeeFiscalCodeValidator", fiscalCode, employeeId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.isFiscalCodeUnique", { fiscalCode, employeeId }, error);
    return false;
  }
};
