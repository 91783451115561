import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { notificationModel } from "../NotificationModel";

export const stopNotificationListener = (): void => {
  try {
    if (notificationModel.notificationListenerHandler !== undefined) notificationModel.notificationListenerHandler();
  } catch (error: unknown) {
    appFaultModel.catchAppError("NotificationModel.stopNotificationListener", {}, error);
  }
};
