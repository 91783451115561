import { LinkedFirestoreDocument } from "./LinkedFirestoreDocument";

export class LinkedFirestoreOfflineDocument extends LinkedFirestoreDocument {
  public fromOfflineCache(data: Record<string, unknown>): LinkedFirestoreDocument {
    this.id = (data.id as string) ?? undefined;
    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    return {
      id: this.id ?? null,
    };
  }
}
