import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { Supplier } from "@/features/modules/supplier/objects/Supplier";
import { supplierModel } from "../SupplierModel";
import { User } from "@/core/modules/user/objects/User";

export const getActiveSuppliers = async (): Promise<Supplier[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (supplierModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Supplier[] = (await offlineModel.getCacheByCollection(supplierModel.offlineModuleModel)) as Supplier[];
      return sortDocuments(documents.filter((supplier) => supplier.archived === false));
    }

    const snapshotQuery: Query<Supplier> = query(
      supplierModel.getPathReference().withConverter(supplierModel.firestoreConverter),
      where("archived", "==", false)
    );

    const snapshot: QuerySnapshot<Supplier> = await getDocs(supplierModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("SupplierModel.getActiveSuppliers", {}, error);
    return [];
  }
};

function sortDocuments(documents: Supplier[]): Supplier[] {
  const sorter: FirestoreSorter<Supplier> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
