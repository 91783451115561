import { RouteRecordRaw } from "vue-router";

export const externalTestRoutes: RouteRecordRaw[] = [
  {
    path: "/external-tests",
    name: "ExternalTestList",
    component: () => import("../views/ExternalTestList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "externalTest",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/external-tests/:id/edit",
    name: "ExternalTestEdit",
    component: () => import("../views/ExternalTestEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "externalTest",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/external-tests/:id",
    name: "ExternalTestView",
    component: () => import("../views/ExternalTestView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "externalTest",
      requiredRight: "viewUI",
    },
  },
];
