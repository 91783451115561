import { StringField } from "@/core/fields";

export class OfflineUpload {
  public id: string | undefined = undefined;
  public path: string | undefined = undefined;
  public uniqueName: string | undefined = undefined;
  public content: string | undefined = undefined;

  public constructor(data?: Record<string, unknown>) {
    if (data !== undefined) this.fromOfflineCache(data);
  }

  public fromOfflineCache(data: Record<string, unknown>): OfflineUpload {
    this.id = StringField.fromOfflineCache(data.id);
    this.path = StringField.fromOfflineCache(data.path);
    this.uniqueName = StringField.fromOfflineCache(data.uniqueName);
    this.content = StringField.fromOfflineCache(data.content);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    return {
      id: StringField.toOfflineCache(this.id),
      path: StringField.toOfflineCache(this.path),
      uniqueName: StringField.toOfflineCache(this.uniqueName),
      content: StringField.toOfflineCache(this.content),
    };
  }
}
