export const it = {
  utility: {
    choose: "Scegli uno strumento di utilità",
    utility: "Utilità",
  },
  navigation: {
    utility: "Utilità",
  },
  toast: {
    error: {},
    success: {
      utilityDone: "Operazione eseguita con successo",
    },
    warning: {},
  },
};
