import { Limitation } from "./Limitation";
import { LimitationDuration } from "./LimitationDuration";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { EnumField, NumberWithDefaultField, StringField } from "@/core/fields";

export class LinkedLimitation extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public duration: LimitationDuration = LimitationDuration.Permanent;
  public temporaryDuration: string | undefined = undefined;
  public text: string | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromLimitation(limitation: Limitation): LinkedLimitation {
    const linkedLimitation: LinkedLimitation = new LinkedLimitation();
    linkedLimitation.id = limitation.id;
    linkedLimitation.name = limitation.name;
    linkedLimitation.duration = limitation.duration;

    return linkedLimitation;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedLimitation {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.duration = EnumField.fromFirestore<LimitationDuration>(data.duration, Object.values(LimitationDuration), LimitationDuration.Permanent);
    this.temporaryDuration = StringField.fromFirestore(data.temporaryDuration);
    this.text = StringField.fromFirestore(data.text);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.duration = EnumField.toFirestore<LimitationDuration>(this.duration, LimitationDuration.Permanent);
    firestoreData.temporaryDuration = StringField.toFirestore(this.temporaryDuration);
    firestoreData.text = StringField.toFirestore(this.text);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedLimitation {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.duration = EnumField.fromFirestore<LimitationDuration>(data.duration, Object.values(LimitationDuration), LimitationDuration.Permanent);
    this.temporaryDuration = StringField.fromOfflineCache(data.temporaryDuration);
    this.text = StringField.fromOfflineCache(data.text);
    this.order = NumberWithDefaultField.fromOfflineCache(data.order, 999);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.duration = EnumField.toOfflineCache<LimitationDuration>(this.duration, LimitationDuration.Permanent);
    firestoreData.temporaryDuration = StringField.toOfflineCache(this.temporaryDuration);
    firestoreData.text = StringField.toOfflineCache(this.text);
    firestoreData.order = NumberWithDefaultField.toOfflineCache(this.order, 999);

    return firestoreData;
  }
}
