import { RouteRecordRaw } from "vue-router";

export const feedbackRoutes: RouteRecordRaw[] = [
  {
    path: "/feedback",
    name: "FeedbackView",
    component: () => import("../views/FeedbackView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRight: "",
    },
  },
];
