import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "../objects/Company";
import { companyModel } from "./CompanyModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class CompanyOfflineModel extends OfflineModuleModel<Company> {
  public constructor() {
    super(() => new Company(), "companies", "company.companies", "company");
  }

  public async downloadCache(): Promise<Company[]> {
    try {
      const companiesIdsString: string | null = sessionStorage.getItem("koruOfflineCompaniesIds");
      if (companiesIdsString === null) return [];

      const companiesIds: string[] = JSON.parse(companiesIdsString);

      const companies: Company[] = [];
      for (const companyId of companiesIds) {
        const company: Company = await companyModel.getDocument(companyId);
        companies.push(company);
      }

      return companies;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const companyOfflineModel: CompanyOfflineModel = new CompanyOfflineModel();
