export class ObjectStrictField {
  public static fromFirestore<T>(value: unknown, converter: (value: Record<string, unknown>) => T): T {
    return converter(value as Record<string, unknown>);
  }

  public static toFirestore<T>(value: T, converter: (value: T) => Record<string, unknown>): Record<string, unknown> {
    return converter(value);
  }

  public static fromOfflineCache<T>(value: unknown, converter: (value: Record<string, unknown>) => T): T {
    return ObjectStrictField.fromFirestore<T>(value, converter);
  }

  public static toOfflineCache<T>(value: T, converter: (value: T) => Record<string, unknown>): Record<string, unknown> {
    return ObjectStrictField.toFirestore<T>(value, converter);
  }
}
