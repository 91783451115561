import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.firmId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firmId) = $event)),
    options: _ctx.firms,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.firmChanged())),
    optionLabel: "name",
    optionValue: "id",
    placeholder: "-"
  }, null, 8, ["modelValue", "options"]))
}