import { AppFaultInfo } from "./AppFaultInfo";

export class AppError extends Error {
  public infos: AppFaultInfo[] = [];

  constructor(error: unknown) {
    super((error as Error).message);
    Object.setPrototypeOf(this, AppError.prototype);
    this.name = this.constructor.name;
  }

  addToStack(functionName: string | undefined, parameters: Record<string, unknown> | undefined, error: unknown) {
    const appFaultInfo: AppFaultInfo = new AppFaultInfo();

    appFaultInfo.functionName = functionName;
    if (parameters !== undefined) appFaultInfo.parameters = JSON.stringify(parameters);
    if (error !== undefined && error instanceof Error) appFaultInfo.errorMessage = error.message;

    this.infos.push(appFaultInfo);
  }
}
