import { Doctor } from "../objects/Doctor";
import { doctorModel } from "../models/DoctorModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const doctorSearch: SearchModule = new SearchModule(
  "doctor.doctor",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Doctor).fullName ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/doctors/${firestoreDocument.id}/edit`,
  "briefcase",
  doctorModel,
  [new SortCriteria("fullName", "asc", "string")]
);
