
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { usePrimeVue } from "primevue/config";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Locale } from "@/core/modules/locale/objects/Locale";
import { localeModel } from "@/core/modules/locale/models/LocaleModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

import { dePrimevue } from "@/core/locales/primevue/de";
import { enPrimevue } from "@/core/locales/primevue/en";
import { frPrimevue } from "@/core/locales/primevue/fr";
import { itPrimevue } from "@/core/locales/primevue/it";

export default defineComponent({
  name: "LocaleSelector",
  setup() {
    const { t } = useLocale();
    const store = useStore();
    const primeVue = usePrimeVue();
    const i18n = useI18n();

    const locales: Locale[] = localeModel.getLocales();
    const selectedLocale: Ref<Locale> = ref(localeModel.getSelectedLocale());

    const selectLocale = (locale: Locale): void => {
      try {
        selectedLocale.value = locale;
        localeModel.setSelectedLocale(locale);
        i18n.locale.value = locale.name;
        if (locale.name === "de") {
          primeVue.config.locale = dePrimevue;
        } else if (locale.name === "en") {
          primeVue.config.locale = enPrimevue;
        } else if (locale.name === "fr") {
          primeVue.config.locale = frPrimevue;
        } else if (locale.name === "it") {
          primeVue.config.locale = itPrimevue;
        }
      } catch (error: unknown) {
        appFaultModel.catchAppError("LocaleSelector.selectLocale", { locale }, error);
      }
    };

    onMounted(() => {
      try {
        const user: User = store.getters[storeTypes.getters.getUser];
        for (const locale of locales) {
          if (locale.name == user.detail.locale) {
            selectLocale(locale);
            break;
          }
        }
      } catch (error: unknown) {
        if (error instanceof AppError) {
          error.addToStack("LocaleSelector.onMounted", {}, error);
          console.log(error);
          appFaultModel.createAppFaultFromAppError(error);
        }
      }
    });

    return {
      locales,
      primeVue,
      selectedLocale,
      selectLocale,
      t,
    };
  },
});
