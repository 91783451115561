import { User } from "@/core/modules/user/objects/User";

import { forgotPassword, init, isLoggedIn, login, logout, recoverPassword, updateEmail, updatePassword } from "./methods";

/**
 * authentication model
 */
export class AuthModel {
  /**
   * init the authentication model and set the onAuthStateChanged listener
   */
  async init(): Promise<void> {
    return init();
  }

  /**
   * login with email and password
   * @param email the user email address
   * @param password the user password
   * @returns the user object if the login was successful, otherwise throws an error
   */
  async login(email: string, password: string): Promise<User> {
    return login(email, password);
  }

  /**
   * logout the current user
   */
  async logout(): Promise<void> {
    return logout();
  }

  /**
   * send a password reset email
   * @param email the user email address
   */
  async forgotPassword(email: string): Promise<void> {
    return forgotPassword(email);
  }

  /**
   * reset the user password
   * @param code the password reset code
   * @param newPassword the user new password
   */
  async recoverPassword(code: string, newPassword: string): Promise<void> {
    return recoverPassword(code, newPassword);
  }

  /**
   * update the user email address
   * @param newEmail the user new email address
   * @param password the user password
   */
  async updateEmail(newEmail: string, password: string): Promise<void> {
    return updateEmail(newEmail, password);
  }

  /**
   * update the user password
   * @param oldPassword the user old password
   * @param newPassword the user new password
   */
  async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    return updatePassword(oldPassword, newPassword);
  }

  /**
   * check if the user is logged in
   * @returns true if the user is logged in, otherwise false
   */
  isLoggedIn(): boolean {
    return isLoggedIn();
  }
}

export const authModel: AuthModel = new AuthModel();
