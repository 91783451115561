export const it = {
  examType: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    examType: "Tipo di visita",
    examTypes: "Tipi di visita",
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    mode: "Modalità",
    modeError: "Selezionare una modalità",
    modes: {
      extraordinary: "Straordinaria",
      recurring: "Periodica",
    },
  },
  navigation: {
    examType: "Tipi di visita",
  },
};
