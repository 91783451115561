import { getDoc, setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { CompanyDuty } from "../objects/CompanyDuty";
import { companyDutyModel } from "./CompanyDutyModel";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class CompanyDutyOfflineModel extends OfflineModuleModel<CompanyDuty> {
  public constructor() {
    super(() => new CompanyDuty(), "companyDuties", "companyDuty.companyDuties", "companyDuty");
    this.canCreate = true;
    this.canUpdate = true;
  }

  public async downloadCache(): Promise<CompanyDuty[]> {
    try {
      if (companyModel.offlineModuleModel === undefined) return [];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let companyDuties: CompanyDuty[] = [];
      for (const company of companies) {
        companyDuties = [...companyDuties, ...(await companyDutyModel.getDocuments([], company.id))];
      }

      return companyDuties;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyDutyOfflineModel.downloadCache", {}, error);
      return [];
    }
  }

  public async createDocumentCallback(document: CompanyDuty): Promise<boolean> {
    try {
      const doc = await getDoc(companyDutyModel.getDocumentReference(document.id, document.parentId));
      if (doc.exists() === true) throw new Error("id duplicated");

      document.setSearchKeys();
      document.setTimestampFields("create");

      await setDoc(
        companyDutyModel.getDocumentReference(document.id, document.parentId).withConverter(companyDutyModel.firestoreConverter),
        document
      );

      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyDutyOfflineModel.createDocumentCallback", { document }, error);
      return false;
    }
  }

  public async updateDocumentCallback(document: CompanyDuty): Promise<boolean> {
    try {
      const oldDocument: CompanyDuty = await companyDutyModel.getDocument(document.id, document.parentId);
      if (document.hasChangedFrom(oldDocument)) throw new Error("sync");

      await companyDutyModel.updateDocument(document, document.parentId);
      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("CompanyDutyOfflineModel.updateDocumentCallback", { document }, error);
      return false;
    }
  }
}

export const companyDutyOfflineModel: CompanyDutyOfflineModel = new CompanyDutyOfflineModel();
