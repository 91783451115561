export const fr = {
  guide: {
    browse: "Parcourir les guides disponibles",
    description: "Description",
    descriptionError: "Entrez une description",
    guide: "Guide",
    guides: "Guides",
    isEnabled: "Activé",
    isEnabledError: "Sélectionnez une valeur",
    noGuides: "Aucun guide disponible",
    title: "Titre",
    titleError: "Entrez un titre",
  },
  navigation: {
    guide: "Guides",
  },
  toast: {
    error: {
      guideFileError: "Erreur lors de la lecture du fichier",
      guideFileUndefined: "Fichier introuvable",
    },
    success: {},
    warning: {},
  },
};
