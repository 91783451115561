import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Broker } from "@/features/modules/broker/objects/Broker";
import { brokerModel } from "@/features/modules/broker/models/BrokerModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getExpiredBrokerDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const brokers: Broker[] = await brokerModel.getBrokersWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("expiredDocs", DocTypeSource.Broker);

    for (const broker of brokers) {
      if (broker.trackExpirations === false) continue;

      const brokerWidget: Widget | undefined = widgets.find((widget) => widget.source === DocTypeSource.Broker && widget.sourceId === broker.id);
      if (brokerWidget === undefined) continue;
      if (brokerWidget.values.count === 0) continue;
      if (brokerWidget.values.expirations == undefined) continue;

      const expirations: Record<string, Timestamp> = brokerWidget.values.expirations as Record<string, Timestamp>;
      for (const docTypeId of Object.keys(expirations)) {
        docExpirations.push(new DocExpiration(broker.id, broker.name ?? "-", expirations[docTypeId].toDate(), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("BrokerDocModel.getExpiredBrokerDocs", {}, error);
    return [];
  }
};
