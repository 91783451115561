import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getExpiringEmployeeDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const employees: Employee[] = await employeeModel.getEmployeesWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("expiringDocs", DocTypeSource.Employee);

    for (const employee of employees) {
      if (employee.trackExpirations === false) continue;

      const employeeWidget: Widget | undefined = widgets.find(
        (widget) => widget.source === DocTypeSource.Employee && widget.sourceId === employee.id
      );
      if (employeeWidget === undefined) continue;
      if (employeeWidget.values.count === 0) continue;
      if (employeeWidget.values.expirations == undefined) continue;

      const expirations: Record<string, Timestamp> = employeeWidget.values.expirations as Record<string, Timestamp>;
      for (const docTypeId of Object.keys(expirations)) {
        docExpirations.push(new DocExpiration(employee.id, employee.fullName ?? "-", expirations[docTypeId].toDate(), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeDocModel.getExpiringEmployeeDocs", {}, error);
    return [];
  }
};
