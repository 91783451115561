export const de = {
  role: {
    allowNewUser: "Neue Benutzer zulassen",
    allowNewUserError: "Wert auswählen",
    hasKoruAccess: "KORU-Zugriff zulassen",
    hasKoruAccessError: "Wert auswählen",
    module: "Modul",
    name: "Name",
    nameError: "Bitte geben Sie einen gültigen Namen ein",
    role: "Rolle",
    rights: {
      create: "Erstellen",
      delete: "Löschen",
      fieldError: "Wert auswählen",
      modes: {
        all: "Alle",
        custom: "Benutzerdefiniert",
        owned: "Eigene",
        none: "Keine",
      },
      read: "Lesen",
      toggle: "Aktion",
      update: "Aktualisieren",
      viewUI: "Benutzeroberfläche",
    },
    roles: "Rollen",
    types: {
      core: "Core",
      features: "Features",
    },
  },
  navigation: {
    role: "Rollen",
  },
};
