export const en = {
  cloudFault: {
    cloudFault: "Cloud event",
    cloudFaults: "Cloud events",
    createdAt: "Date and time",
    details: "Details",
    function: "Function",
    noDetails: "No details available",
    notifications: {
      cloudFaultCreated: "Cloud event created",
      groupName: "Cloud events",
    },
  },
  buttons: {
    deleteAll: "Delete all",
    showDetails: "Show details",
  },
  navigation: {
    cloudFault: "Cloud events",
  },
};
