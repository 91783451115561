import { BrokerDocHelpers } from "../helpers/BrokerDocHelpers";
import { Doc } from "@/features/modules/doc/objects/Doc";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocModel } from "@/features/modules/doc/models/DocModel";
import { storageModel } from "@/core/modules/storage/models/StorageModel";

import { getExpiredBrokerDocs, getExpiringBrokerDocs, getMissingBrokerDocs } from "./methods";

export class BrokerDocModel extends DocModel {
  public constructor() {
    super();
    this.collectionName = "brokerDocs";
    this.roleModule = "brokerDoc";
    this.parentCollectionName = "brokers";
  }

  public async deleteDocument(brokerDoc: Doc, parentId?: string): Promise<boolean> {
    const result: boolean = await super.deleteDocument(brokerDoc, parentId);
    if (result === false) return false;

    for (const file of brokerDoc.files) {
      await storageModel.deleteFile(BrokerDocHelpers.getFolderPath(parentId), file.uniqueName as string);
    }
    return true;
  }

  public async getExpiredBrokerDocs(): Promise<DocExpiration[]> {
    return getExpiredBrokerDocs();
  }

  public async getExpiringBrokerDocs(): Promise<DocExpiration[]> {
    return getExpiringBrokerDocs();
  }

  public async getMissingBrokerDocs(): Promise<DocExpiration[]> {
    return getMissingBrokerDocs();
  }
}

export const brokerDocModel: BrokerDocModel = new BrokerDocModel();
