export class Locale {
  public title: string;
  public name: string;
  public flag: string;

  public constructor(title: string, name: string, flag: string) {
    this.title = title;
    this.name = name;
    this.flag = flag;
  }
}
