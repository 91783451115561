import { assistModel } from "../models/AssistModel";
import { setting } from "@/core/modules/setting/Setting";

export class WebsocketClient {
  public address: string;
  public websocket: WebSocket | undefined = undefined;

  public constructor() {
    this.address = String(setting.getSetting<string>("assistWebsocketAddress"));
  }

  public startWebsocket(successCallback: () => void, errorCallback: () => void): void {
    try {
      this.websocket = new WebSocket(this.address);
      this.websocket.onopen = () => this.handleConnect(successCallback);
      this.websocket.onmessage = async (e) => await this.handleMessage(e);
      this.websocket.onerror = (e) => this.handleError(e);
      this.websocket.onclose = () => this.handleDisconnect(errorCallback);
    } catch (error: unknown) {
      assistModel.setAssistState("disconnected");
      errorCallback();
    }
  }

  public stopWebsocket(): void {
    if (this.websocket === undefined) return;
    this.websocket.close();
  }

  public handleConnect(callback: () => void): void {
    console.log("Websocket connected");
    assistModel.setAssistState("connected");
    callback();
  }

  public handleDisconnect(callback: () => void): void {
    console.log("Websocket disconnected");
    assistModel.setAssistState("disconnected");
    callback();
  }

  public handleError(e: Event): void {
    console.log("Websocket error");
    console.error(e);
  }

  public async handleMessage(e: MessageEvent): Promise<void> {
    if (e === undefined || e.data === undefined) throw new Error("Websocket: nvalid message received");
    const response: Record<string, unknown> = JSON.parse(e.data);

    if ("action" in response === false) throw new Error("Websocket: invalid message received (no action)");

    console.log(response);

    if (response.action === "uploadAttachment") {
      assistModel.uploadAttachment(String(response.file), String(response.name), String(response.type));
    } else if (response.action === "uploadAudioTest") {
      assistModel.uploadAudioTest(String(response.file));
    } else if (response.action === "uploadDrugTest") {
      assistModel.uploadDrugTest(String(response.file));
    } else if (response.action === "uploadSpiroTest") {
      assistModel.uploadSpiroTest(String(response.file));
    }
  }

  public sendRequest(action: string, params: Record<string, unknown> = {}): void {
    if (this.websocket === undefined) return;

    const requestData: Record<string, unknown> = this.createRequestData(action, params);

    if (this.websocket.readyState !== WebSocket.OPEN) return;

    this.websocket.send(JSON.stringify(requestData));
  }

  public createRequestData(action: string, params: Record<string, unknown>): Record<string, unknown> {
    return { action: action, ...params };
  }
}

export const websocketClient: WebsocketClient = new WebsocketClient();
