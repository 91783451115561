import { format } from "date-fns";
import { getDocs, Query, query, QueryDocumentSnapshot, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Employer } from "@/features/modules/employer/objects/Employer";
import { employerModel } from "../EmployerModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getEmployersByEmployeeAndDate = async (employeeId: string, date: Date): Promise<Employer[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (employerModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Employer[] = (await offlineModel.getCacheByCollection(employerModel.offlineModuleModel)) as Employer[];
      return sortDocuments(
        documents.filter(
          (employer) =>
            employer.parentId === employeeId && employer.fromSort <= format(date, "yyyy-MM-dd") && employer.toSort >= format(date, "yyyy-MM-dd")
        )
      );
    }

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      const employee: Employee = await employeeModel.getDocument(employeeId);
      const employeeCompanies: string[] = Object.keys(employee.companies);
      if (doctor === undefined || doctor.authorizedCompaniesIds.filter((id) => employeeCompanies.includes(id)).length === 0)
        throw new Error(`User doesn't have rights to read employee #${employeeId}`);
    }

    const snapshotQuery: Query<Employer> = query(
      employerModel.getPathReference(employeeId).withConverter(employerModel.firestoreConverter),
      where("toSort", ">=", format(date, "yyyy-MM-dd"))
    );

    const toSnapshot: QuerySnapshot<Employer> = await getDocs(employerModel.addReadConditionToQuery(snapshotQuery));

    if (toSnapshot == undefined || toSnapshot.empty) return [];

    return sortDocuments(
      toSnapshot.docs.reduce((result: Employer[], doc: QueryDocumentSnapshot<Employer>) => {
        if (doc.data().fromSort <= format(date, "yyyy-MM-dd")) result.push(doc.data());
        return result;
      }, [])
    );
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployerModel.getEmployersByEmployeeAndDate", { employeeId, date }, error);
    return [];
  }
};

function sortDocuments(documents: Employer[]): Employer[] {
  const sorter: FirestoreSorter<Employer> = new FirestoreSorter(documents);
  sorter.addSortCriteria("toSort", "asc", "string");
  return sorter.sort();
}
