import { addDays, subDays } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Service } from "@/features/modules/service/objects/Service";
import { serviceModel } from "../ServiceModel";
import { ServiceState } from "@/features/modules/service/objects/ServiceState";
import { setting } from "@/core/modules/setting/Setting";

export const getServicesForDashboard = async (): Promise<Service[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const minDate: Date = subDays(new Date(), setting.getSetting<number>("lastServicesDaysNotice"));
    minDate.setHours(0, 0, 0, 0);
    const maxDate: Date = addDays(new Date(), 1);
    maxDate.setHours(0, 0, 0, 0);

    const snapshotQuery: Query<Service> = query(
      serviceModel.getPathReference().withConverter(serviceModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("date", ">=", Timestamp.fromDate(minDate)),
      where("date", "<=", Timestamp.fromDate(maxDate))
    );

    const snapshot: QuerySnapshot<Service> = await getDocs(serviceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data()).filter((service) => service.state === ServiceState.Completed);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ServiceModel.getServicesForDashboard", {}, error);
    return [];
  }
};
