import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isPeriodAvailable = async (companyId: string, from: Date, to: Date | undefined, companyCostListId = "empty"): Promise<boolean> => {
  try {
    if (companyId === undefined) return false;
    if (from === undefined) return false;

    return CloudHelpers.runValidatorFunction(
      "featuresCompanyCostListPeriodValidator",
      { companyId: companyId, from: from.getTime(), to: to?.getTime() ?? null },
      companyCostListId
    );
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyCostListModel.isPeriodAvailable", { companyId, from, to, companyCostListId }, error);
    return false;
  }
};
