import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "../DoctorModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const createDoctor = async (doctor: Doctor): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    const user: User = store.getters[storeTypes.getters.getUser];

    // check if user can create the document
    if (user.canCreate(doctorModel.roleModule) === false) throw new Error(`Unable to create document in collection ${doctorModel.collectionName}`);

    doctor.id = doctorModel.getDocumentReference().id;

    doctor.setFullNames();
    doctor.setSearchKeys();
    doctor.setTimestampFields("create");
    batch.set(doctorModel.getDocumentReference(doctor.id), doctor.toFirestore());

    await batch.commit();

    return doctor.id;
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorModel.createDoctor", { doctor }, error);
    return "ERROR";
  }
};
