export const it = {
  planner: {
    activeSessions: "Sessioni attive",
    company: "Azienda",
    companyAndBranch: "Azienda e sede",
    companyExpirationPaperTitle: "Visite mediche in scadenza per azienda",
    doctor: "Medico",
    employeeList: "Lista lavoratori in scadenza",
    expirationDate: "Scadenza entro la data",
    expirations: "Lavoratori",
    expiredCount: "Scaduti",
    expiringCount: "In scadenza",
    expiringDateEnd: "Scadenza al",
    expiringDateStart: "Scadenza dal",
    expiringExaminations: "Elenco visite mediche in scadenza",
    firstExaminationCount: "Non ancora visitati",
    lastExamination: "Ultima visita",
    planner: "Pianificazione servizi",
    printExpiration: "Scadenza",
  },
  navigation: {
    planner: "Pianificazione servizi",
  },
  buttons: {
    doExaminationSession: "Sessione visite mediche",
    doExternalTestSessionPlan: "Sessione accertamenti esterni",
    printExpiringExaminations: "Stampa scadenze visite mediche",
    showEmployees: "Visualizza lavoratori",
  },
  toast: {
    error: {
      searchExpirations: "Errore durante la ricerca delle scadenze",
    },
    success: {},
    warning: {
      expirationDateRequired: "Selezionare la data di scadenza",
    },
  },
};
