import { Contract } from "../objects/Contract";
import { ContractExpiration } from "../objects/ContractExpiration";
import { contractOfflineModel } from "./ContractOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import {
  createContract,
  deleteContract,
  getContractsByCompany,
  getContractsByFirmAndCompany,
  getExpiredContractsByFirm,
  getExpiringContractsByFirm,
  getMissingContractsByFirm,
  updateContract,
} from "./methods";

export class ContractModel extends FirestoreModel<Contract> {
  public constructor() {
    super(() => new Contract(), "contracts", LockPolicy.DiscardUnsyncedChanges, "contract");
    this.offlineModuleModel = contractOfflineModel;
  }

  public async getDocuments(): Promise<Contract[]> {
    return super.getDocuments();
  }

  public async getDocument(contractId: string): Promise<Contract> {
    return super.getDocument(contractId);
  }

  public async createDocument(contract: Contract): Promise<string> {
    return createContract(contract);
  }

  public async updateDocument(contract: Contract): Promise<void> {
    return updateContract(contract);
  }

  public async deleteDocument(contract: Contract): Promise<boolean> {
    return deleteContract(contract);
  }

  public async getContractsByCompany(companyId: string): Promise<Contract[]> {
    return getContractsByCompany(companyId);
  }

  public async getContractsByFirmAndCompany(companyId: string): Promise<Contract[]> {
    return getContractsByFirmAndCompany(companyId);
  }

  public async getExpiredContractsByFirm(): Promise<ContractExpiration[]> {
    return getExpiredContractsByFirm();
  }

  public async getExpiringContractsByFirm(): Promise<ContractExpiration[]> {
    return getExpiringContractsByFirm();
  }

  public async getMissingContractsByFirm(): Promise<ContractExpiration[]> {
    return getMissingContractsByFirm();
  }
}

export const contractModel: ContractModel = new ContractModel();
