import { format } from "date-fns";

import { Employee } from "@/features/modules/employee/objects/Employee";

export const renderAudioAppRows = (employees: Employee[]): string[][] => {
  const initLine: string[] = ["[AudioConsole/4.0.1 Format]", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
  const headersLine: string[] = [
    "PatientID",
    "Sex",
    "BirthDate",
    "Title",
    "Firstname",
    "Middlename",
    "Lastname",
    "Street",
    "PostalCode",
    "City",
    "Country",
    "Phone",
    "Email",
    "Notes",
    "FirmSchool",
    "Job",
    "Department",
  ];

  return [
    initLine,
    headersLine,
    ...employees.map((employee: Employee) => {
      return [
        employee.id,
        employee.sex ?? "",
        employee.birthDate !== undefined ? format(employee.birthDate, "dd-MM-yyyy") : "",
        employee.sex === "F" ? "Sig.ra" : "Sig.",
        employee.firstName ?? "",
        "",
        employee.lastName ?? "",
        employee.address?.street ?? "",
        employee.address?.zipCode ?? "",
        employee.address?.town ?? "",
        employee.address?.country ?? "",
        employee.phone ?? "",
        employee.email ?? "",
        "",
        "",
        employee.getLinkedCompaniesWithStatus().length > 0 ? employee.getLinkedCompaniesWithStatus()[0].name ?? "" : "",
        "",
      ];
    }),
  ];
};
