import { DocumentReference } from "firebase/firestore";

import { DocTypeSource } from "./DocTypeSource";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";

import { ArrayField, StringField } from "@/core/fields";

export class DocType extends FirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public availableFor: DocTypeSource[] = [];
  public mandatoryFor: DocTypeSource[] = [];
  public visibleFor: DocTypeSource[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): DocType {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.availableFor = ArrayField.fromFirestore<DocTypeSource>(data.availableFor, (value) => value as unknown as DocTypeSource);
    this.mandatoryFor = ArrayField.fromFirestore<DocTypeSource>(data.mandatoryFor, (value) => value as unknown as DocTypeSource);
    this.visibleFor = ArrayField.fromFirestore<DocTypeSource>(data.visibleFor, (value) => value as unknown as DocTypeSource);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.availableFor = ArrayField.toFirestore<DocTypeSource>(this.availableFor, (value) => value as string);
    firestoreData.mandatoryFor = ArrayField.toFirestore<DocTypeSource>(this.mandatoryFor, (value) => value as string);
    firestoreData.visibleFor = ArrayField.toFirestore<DocTypeSource>(this.visibleFor, (value) => value as string);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): DocType {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.availableFor = ArrayField.fromOfflineCache<DocTypeSource>(data.availableFor, (value) => value as unknown as DocTypeSource);
    this.mandatoryFor = ArrayField.fromOfflineCache<DocTypeSource>(data.mandatoryFor, (value) => value as unknown as DocTypeSource);
    this.visibleFor = ArrayField.fromOfflineCache<DocTypeSource>(data.visibleFor, (value) => value as unknown as DocTypeSource);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.availableFor = ArrayField.toOfflineCache<DocTypeSource>(this.availableFor, (value) => value as string);
    firestoreData.mandatoryFor = ArrayField.toOfflineCache<DocTypeSource>(this.mandatoryFor, (value) => value as string);
    firestoreData.visibleFor = ArrayField.toOfflineCache<DocTypeSource>(this.visibleFor, (value) => value as string);

    return firestoreData;
  }
}
