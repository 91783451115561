export const it = {
  search: {
    placeholder: "Inserisci una parola da cercare...",
    noResults: "Nessun risultato trovato",
    results: "Risultati della ricerca",
    resultsFound: "È stato trovato {count} risultato|Sono stati trovati {count} risultati",
    search: "Ricerca",
    searchHistory: "Parola chiave: {keyword}",
    searching: "Sto cercando...",
  },
};
