import { Locale } from "../objects/Locale";

import { getSelectedLocale, getLocales, setSelectedLocale } from "./methods";

export class LocaleModel {
  public getSelectedLocale(): Locale {
    return getSelectedLocale();
  }

  public getLocales(): Locale[] {
    return getLocales();
  }

  public async setSelectedLocale(locale: Locale): Promise<void> {
    return setSelectedLocale(locale);
  }
}

export const localeModel: LocaleModel = new LocaleModel();
