import { compareAsc } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { employeeModel } from "../EmployeeModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getEmployeesToExamineByBranchAndDate = async (
  companyId: string,
  branchId: string,
  examinationDate: Date,
  expirationDate: Date
): Promise<Employee[]> => {
  try {
    let resultEmployees: Employee[] = [];

    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (employeeModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Employee[] = (await offlineModel.getCacheByCollection(employeeModel.offlineModuleModel)) as Employee[];
      resultEmployees = documents
        .filter(
          (employee) =>
            branchId in employee.branches &&
            employee.isExaminationExempted === false &&
            companyId in employee.companies &&
            ("isArchived" in employee.companies[companyId] === false || employee.companies[companyId].isArchived === undefined)
        )
        .filter((employee) => {
          const nextExaminationDateString: string | undefined = employee.nextExaminationDates.getNextExaminationDateByCompanyAndFirm(companyId);
          if (nextExaminationDateString === undefined) return true;
          if (nextExaminationDateString === "9999-99-99") return false;
          const nextExaminationDate: Date = new Date(nextExaminationDateString);
          if (compareAsc(nextExaminationDate, expirationDate) <= 0) return true;
          return false;
        });
    } else {
      const snapshotQuery: Query<Employee> = query(
        employeeModel.getPathReference().withConverter(employeeModel.firestoreConverter),
        where(`branches.${branchId}.id`, "==", branchId),
        where("isExaminationExempted", "==", false)
      );

      const employeesSnapshot: QuerySnapshot<Employee> = await getDocs(employeeModel.addReadConditionToQuery(snapshotQuery));

      if (employeesSnapshot == undefined && employeesSnapshot == undefined) return [];

      resultEmployees = employeesSnapshot.docs
        .map((doc) => doc.data())
        .filter((employee) => {
          if (companyId in employee.companies === false) return false;
          if ("isArchived" in employee.companies[companyId] === true && employee.companies[companyId].isArchived !== undefined) return false;
          const nextExaminationDateString: string | undefined = employee.nextExaminationDates.getNextExaminationDateByCompanyAndFirm(companyId);
          if (nextExaminationDateString === undefined) return true;
          if (nextExaminationDateString === "9999-99-99") return false;
          const nextExaminationDate: Date = new Date(nextExaminationDateString);
          if (compareAsc(nextExaminationDate, expirationDate) <= 0) return true;
          return false;
        });
    }

    // filter by active employee duty
    const hasActiveEmployeeDuty = async (employee: Employee): Promise<boolean> => {
      const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getDocuments([], employee.id);
      const activeEmployeeDuties: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
        if (employeeDuty.from !== undefined && compareAsc(employeeDuty.from, examinationDate) > 0) return false;
        if (employeeDuty.to !== undefined && compareAsc(examinationDate, employeeDuty.to) > 0) return false;
        return true;
      });
      return activeEmployeeDuties.length > 0;
    };
    const hasActiveEmployeeDutyArray: boolean[] = await Promise.all(resultEmployees.map(hasActiveEmployeeDuty));
    resultEmployees = resultEmployees.filter((value, index) => hasActiveEmployeeDutyArray[index]);

    // filter by previous draft examination
    const hasPreviousDraftExamination = async (employee: Employee): Promise<boolean> => {
      const examinations: Examination[] = await examinationModel.getDraftExaminationsByEmployeeAndCompanyAndFirm(employee.id, companyId);
      const draftExaminations: Examination[] = examinations.filter((examination) => examination.state === ExaminationState.Draft);
      return draftExaminations.length === 0;
    };
    const hasPreviousDraftExaminationArray: boolean[] = await Promise.all(resultEmployees.map(hasPreviousDraftExamination));
    resultEmployees = resultEmployees.filter((value, index) => hasPreviousDraftExaminationArray[index]);

    // sort and return
    return resultEmployees.sort((a, b) => {
      const nextExaminationDateStringA: string | undefined = a.nextExaminationDates.getNextExaminationDateByCompanyAndFirm(companyId);
      const nextExaminationDateStringB: string | undefined = b.nextExaminationDates.getNextExaminationDateByCompanyAndFirm(companyId);
      if (nextExaminationDateStringA === undefined) return -1;
      if (nextExaminationDateStringB === undefined) return 1;
      return nextExaminationDateStringA.localeCompare(nextExaminationDateStringB);
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError(
      "EmployeeModel.getEmployeesToExamineByBranchAndDate",
      { companyId, branchId, examinationDate, expirationDate },
      error
    );
    return [];
  }
};
