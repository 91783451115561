import { config } from "@/core/modules/config/objects/Config";
import { koruApp } from "@/main";

export class ToastHelpers {
  /**
   * show a toast with an error message
   * @param error the error message
   * @param fallbackError the fallback error message
   * @param t the translation function
   */
  public static showToastWithError(error: string, fallbackError: string | undefined = undefined, t?: (entry: string) => string): void {
    const toast = koruApp.config.globalProperties.$toast;

    let errorLabel = "";
    if (t != undefined) {
      if (t("toast.error." + error) != undefined && t("toast.error." + error) != "toast.error." + error) {
        errorLabel = t("toast.error." + error);
      } else if (fallbackError != undefined) {
        if (t("toast.error." + fallbackError) != undefined && t("toast.error." + fallbackError) != "toast.error." + fallbackError) {
          errorLabel = t("toast.error." + fallbackError);
        } else {
          errorLabel = t("toast.error.generic");
        }
      } else {
        errorLabel = t("toast.error.generic");
      }
    } else {
      errorLabel = error;
    }

    toast.add({
      severity: "error",
      summary: t != undefined ? t("toast.error.title") : "toast.error.title",
      detail: errorLabel,
      group: "bedrock",
      life: config.app.layout.toastLife,
    });
  }

  /**
   * show a toast with a success message
   * @param success the success message
   * @param t the translation function
   */
  public static showToastWithSuccess(success: string, t?: (entry: string) => string): void {
    const toast = koruApp.config.globalProperties.$toast;

    let successLabel = "";
    if (t != undefined) {
      if (t("toast.success." + success) != undefined && t("toast.success." + success) != "toast.success." + success) {
        successLabel = t("toast.success." + success);
      } else {
        successLabel = t("toast.success.generic");
      }
    } else {
      successLabel = success;
    }

    toast.add({
      severity: "success",
      summary: t != undefined ? t("toast.success.title") : "toast.success.title",
      detail: successLabel,
      group: "bedrock",
      life: config.app.layout.toastLife,
    });
  }

  /**
   * show a toast with a warning message
   * @param warning the warning message
   * @param t the translation function
   */
  public static showToastWithWarning(warning: string, t?: (entry: string) => string): void {
    const toast = koruApp.config.globalProperties.$toast;

    let warningLabel = "";
    if (t != undefined) {
      if (t("toast.warning." + warning) != "toast.warning." + warning) {
        warningLabel = t("toast.warning." + warning);
      } else {
        warningLabel = t("toast.warning.generic");
      }
    } else {
      warningLabel = warning;
    }

    toast.add({
      severity: "warn",
      summary: t != undefined ? t("toast.warning.title") : "toast.warning.title",
      detail: warningLabel,
      group: "bedrock",
      life: config.app.layout.toastLife,
    });
  }
}
