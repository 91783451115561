export const it = {
  assist: {
    connect: "Connetti",
    disconnect: "Disconnetti",
    title: "Assistente KORU",
  },
  buttons: {
    connect: "Connetti",
    disconnect: "Disconnetti",
  },
  toast: {
    error: {
      assistConnection: "Assistente non connesso",
    },
    success: {
      assistConnection: "Assistente connesso",
    },
    warning: {},
  },
};
