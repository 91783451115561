import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "../objects/Employee";
import { employeeOfflineModel } from "./EmployeeOfflineModel";
import { Employer } from "@/features/modules/employer/objects/Employer";
import { employerModel } from "@/features/modules/employer/models/EmployerModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import {
  createHealthRecord,
  getActiveEmployeesByCompany,
  getEmployeesByBranch,
  getEmployeesByCompany,
  getEmployeesToExamineByBranchAndDate,
  getEmployeesWithFilters,
  getEmployeesWithTrackExpirations,
  isFiscalCodeUnique,
} from "./methods";

export class EmployeeModel extends FirestoreModel<Employee> {
  public constructor() {
    super(() => new Employee(), "employees", LockPolicy.DiscardUnsyncedChanges, "employee");
    this.beforeDeleteFunction = "featuresEmployeeBeforeDelete";
    this.offlineModuleModel = employeeOfflineModel;
  }

  public async getDocuments(): Promise<Employee[]> {
    return super.getDocuments([new SortCriteria("fullName", "asc", "string")]);
  }

  public async getDocument(employeeId: string): Promise<Employee> {
    return super.getDocument(employeeId);
  }

  public async createDocument(employee: Employee, parentId?: string, writeLog?: boolean, employers?: Employer[]): Promise<string> {
    try {
      if (employers === undefined || employers.length == 0) throw new Error("employerAtLeastOne");

      employee.setFullNames();

      const employeeId: string = await super.createDocument(employee);

      for (const employer of employers) {
        await employerModel.createDocument(employer, employeeId);
      }

      return employeeId;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeModel.createDocument", { employee, employers }, error);
      return "ERROR";
    }
  }

  public async updateDocument(employee: Employee): Promise<void> {
    employee.setFullNames();
    return super.updateDocument(employee);
  }

  public async deleteDocument(employee: Employee): Promise<boolean> {
    return super.deleteDocument(employee);
  }

  public async createHealthRecord(
    employee: Employee,
    companyId: string,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<string> {
    return createHealthRecord(employee, companyId, d, n, t);
  }

  public async getActiveEmployeesByCompany(companyId: string): Promise<Employee[]> {
    return getActiveEmployeesByCompany(companyId);
  }

  public async getEmployeesByBranch(branchId: string): Promise<Employee[]> {
    return getEmployeesByBranch(branchId);
  }

  public async getEmployeesByCompany(companyId: string): Promise<Employee[]> {
    return getEmployeesByCompany(companyId);
  }

  public async getEmployeesToExamineByBranchAndDate(
    companyId: string,
    branchId: string,
    examinationDate: Date,
    expirationDate: Date
  ): Promise<Employee[]> {
    return getEmployeesToExamineByBranchAndDate(companyId, branchId, examinationDate, expirationDate);
  }

  public async getEmployeesWithFilters(companyId: string, branchesIds: string[]): Promise<Employee[]> {
    return getEmployeesWithFilters(companyId, branchesIds);
  }

  public async getEmployeesWithTrackExpirations(): Promise<Employee[]> {
    return getEmployeesWithTrackExpirations();
  }

  public async isFiscalCodeUnique(fiscalCode: string, employeeId?: string): Promise<boolean> {
    return isFiscalCodeUnique(fiscalCode, employeeId);
  }
}

export const employeeModel: EmployeeModel = new EmployeeModel();
