import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "../FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getFirmByName = async (firmName: string): Promise<Firm | undefined> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (firmModel.offlineModuleModel === undefined) return undefined;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Firm[] = (await offlineModel.getCacheByCollection(firmModel.offlineModuleModel)) as Firm[];
      return documents.find((firm) => firm.name === firmName);
    }

    const snapshotQuery: Query<Firm> = query(firmModel.getPathReference().withConverter(firmModel.firestoreConverter), where("name", "==", firmName));

    const snapshot: QuerySnapshot<Firm> = await getDocs(firmModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirmModel.getFirmByName", { firmName }, error);
    return undefined;
  }
};
