import { NotificationModule } from "../objects/NotificationModule";
import { notificationModules } from "../objects/notificationModules";

export class NotificationHelpers {
  public static registerModule(module: NotificationModule): void {
    if (notificationModules.includes(module) === false) {
      notificationModules.push(module);
    }
  }
}
