
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { assignEmployeeDutyModel } from "../models/AssignEmployeeDutyModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { Company } from "@/features/modules/company/objects/Company";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { companyDutyModel } from "@/features/modules/companyDuty/models/CompanyDutyModel";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeAssign } from "../objects/EmployeeAssign";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { LinkedCompanyWithStatus } from "@/features/modules/company/objects/LinkedCompanyWithStatus";
import { storeTypes } from "@/core/modules/store/types";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "AssignEmployeeDutyView",
  setup() {
    const { t } = useLocale();

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    /* custom */

    const companyId: Ref<string | undefined> = ref(undefined);
    const companies: Ref<Company[]> = ref([]);

    const branches: Ref<Branch[]> = ref([]);
    const branchId: Ref<string | undefined> = ref(undefined);

    const companyDutiesIds: Ref<string[]> = ref([]);
    const companyDuties: Ref<CompanyDuty[]> = ref([]);

    const toDate: Ref<Date | undefined> = ref(undefined);

    const basicEmployees: Ref<Employee[]> = ref([]);
    const employees: Ref<EmployeeAssign[]> = ref([]);

    const selectedEmployeesIds: Ref<string[]> = ref([]);

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const companyChanged = async () => {
      branches.value = [];
      branchId.value = undefined;
      companyDuties.value = [];
      companyDutiesIds.value = [];
      if (companyId.value === undefined) return;

      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrError(
        async () => {
          branches.value = await branchModel.getBranchesByCompany(companyId.value as string);
          if (branches.value.length === 1) branchId.value = branches.value[0].id;
          branchChanged();

          companyDuties.value = await companyDutyModel.getDocuments([], companyId.value);
        },
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const branchChanged = async () => {
      if (branchId.value === undefined) return;
      if (companyId.value === undefined) return;

      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrError(
        async () => {
          employees.value = [];

          basicEmployees.value = (await employeeModel.getEmployeesByBranch(branchId.value as string)).filter((employee) => {
            if (companyId.value === undefined) return true;
            const company: LinkedCompanyWithStatus | undefined = employee
              .getLinkedCompaniesWithStatus()
              .find((company) => company.id === companyId.value);
            if (company === undefined) return false;
            if (company.isArchived !== undefined) return false;
            return true;
          });
          for (const basicEmployee of basicEmployees.value) {
            const employeeAssign: EmployeeAssign | undefined = await EmployeeAssign.createFromEmployee(basicEmployee, companyId.value as string);
            if (employeeAssign !== undefined) employees.value.push(employeeAssign);
          }
        },
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    const toggleEmployee = (employeeId: string) => {
      const index: number = selectedEmployeesIds.value.indexOf(employeeId);
      if (index === -1) {
        selectedEmployeesIds.value.push(employeeId);
      } else {
        selectedEmployeesIds.value.splice(index, 1);
      }
    };

    const assignDuty = async (): Promise<void> => {
      if (toDate.value !== undefined && toDate.value !== null) {
        toDate.value.setHours(0, 0, 0, 0);
      } else {
        toDate.value = undefined;
      }

      if (companyId.value === undefined) {
        ToastHelpers.showToastWithWarning("noCompany", t);
        return;
      }
      if (companyDutiesIds.value === undefined || companyDutiesIds.value.length === 0) {
        ToastHelpers.showToastWithWarning("noCompanyDuty", t);
        return;
      }
      if (selectedEmployeesIds.value === undefined || selectedEmployeesIds.value.length === 0) {
        ToastHelpers.showToastWithWarning("noEmployees", t);
        return;
      }

      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await assignEmployeeDutyModel.assignEmployeeDuty(
            companies.value.find((company) => company.id === companyId.value) as Company,
            basicEmployees.value.filter((employee) => selectedEmployeesIds.value.includes(employee.id ?? "")),
            companyDuties.value.filter((companyDuty) => companyDutiesIds.value.includes(companyDuty.id ?? "")),
            toDate.value
          );

          companyId.value = undefined;
          branches.value = [];
          branchId.value = undefined;
          companyDuties.value = [];
          companyDutiesIds.value = [];
          toDate.value = undefined;
          basicEmployees.value = [];
          employees.value = [];
          selectedEmployeesIds.value = [];
        },
        "utilityDone",
        t,
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      assignDuty,
      branchChanged,
      branches,
      branchId,
      companies,
      companyChanged,
      companyDuties,
      companyDutiesIds,
      companyId,
      employees,
      selectedEmployeesIds,
      t,
      toDate,
      toggleEmployee,
    };
  },
});
