import { Query, query, where } from "firebase/firestore";

import { Doc } from "@/features/modules/doc/objects/Doc";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocModel } from "@/features/modules/doc/models/DocModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { DoctorDocHelpers } from "../helpers/DoctorDocHelpers";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { getExpiredDoctorDocs, getExpiringDoctorDocs, getMissingDoctorDocs } from "./methods";

export class DoctorDocModel extends DocModel {
  public constructor() {
    super();
    this.collectionName = "doctorDocs";
    this.roleModule = "doctorDoc";
    this.parentCollectionName = "doctors";
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<Doc[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    let customQuery: Query<Doc> | undefined;
    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.id !== parentId) throw new Error(`User doesn't have rights to read doctor #${parentId}`);
      customQuery = query(
        this.getPathReference(parentId).withConverter(this.firestoreConverter),
        where("type.visibleFor", "array-contains", DocTypeSource.Doctor)
      );
    }

    return super.getDocuments(sortCriterias, parentId, customQuery);
  }

  public async deleteDocument(doctorDoc: Doc, parentId?: string): Promise<boolean> {
    const result: boolean = await super.deleteDocument(doctorDoc, parentId);
    if (result === false) return false;

    for (const file of doctorDoc.files) {
      await storageModel.deleteFile(DoctorDocHelpers.getFolderPath(parentId), file.uniqueName as string);
    }
    return true;
  }

  public async getExpiredDoctorDocs(): Promise<DocExpiration[]> {
    return getExpiredDoctorDocs();
  }

  public async getExpiringDoctorDocs(): Promise<DocExpiration[]> {
    return getExpiringDoctorDocs();
  }

  public async getMissingDoctorDocs(): Promise<DocExpiration[]> {
    return getMissingDoctorDocs();
  }
}

export const doctorDocModel: DoctorDocModel = new DoctorDocModel();
