
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { offlineModel } from "../models/OfflineModel";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "OfflineToggleComponent",
  setup() {
    const { t } = useLocale();
    let eventBusId: string | undefined = undefined;

    const networkState: Ref<"online" | "offline"> = ref("online");
    const showToggleDialog: Ref<boolean> = ref(false);

    const setOfflineState = async (state: "online" | "offline"): Promise<void> => {
      try {
        offlineModel.setOfflineState(state);
        closeDialog();
      } catch (error: unknown) {
        if (error instanceof AppError) {
          error.addToStack("OfflineToggleComponent.setOfflineState", { state }, error);
          console.log(error);
          await appFaultModel.createAppFaultFromAppError(error);
        }
      }
    };

    const closeDialog = () => {
      showToggleDialog.value = false;
    };

    onMounted(() => {
      eventBusId = eventBus.on("connectionToggleState", async (connectionState: "online" | "offline" | undefined) => {
        if (connectionState === undefined) return Promise.resolve();
        networkState.value = offlineModel.getOfflineState();
        if (networkState.value !== connectionState) showToggleDialog.value = true;
      });

      networkState.value = offlineModel.getOfflineState();
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      closeDialog,
      networkState,
      showToggleDialog,
      setOfflineState,
      t,
    };
  },
});
