import { addDoc, setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { log } from "@/core/modules/log/Log";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const createDocument = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  firestoreDocument: T,
  parentId?: string,
  writeLog?: boolean
): Promise<string> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    // offline mode
    if (offlineModel.getOfflineState() === "offline" && firestoreDocument instanceof FirestoreOfflineDocument)
      return createOfflineDocument(user, model as FirestoreModel<FirestoreOfflineDocument>, firestoreDocument, parentId);

    // check if user can create document
    if (user.canCreate(model.roleModule) === false) throw new Error(`Unable to create document in collection ${model.collectionName}`);

    // create online document
    firestoreDocument.setSearchKeys();
    firestoreDocument.setTimestampFields("create");
    let newDocId: string;
    if (firestoreDocument.id === "new") {
      newDocId = (await addDoc(model.getPathReference(parentId).withConverter(model.firestoreConverter), firestoreDocument)).id;
    } else {
      newDocId = firestoreDocument.id;
      await setDoc(model.getDocumentReference(firestoreDocument.id, parentId).withConverter(model.firestoreConverter), firestoreDocument);
    }

    if (writeLog === true) log.notice(`Document ${newDocId} created in collection ${model.collectionName}`);

    return newDocId;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.createDocument", { model, firestoreDocument, parentId }, error);
    return "ERROR";
  }
};

const createOfflineDocument = async <T extends FirestoreOfflineDocument>(
  user: User,
  model: FirestoreModel<T>,
  firestoreDocument: T,
  parentId?: string
): Promise<string> => {
  try {
    if (model.offlineModuleModel === undefined) throw new Error("offlineModuleModelNotSaveable");
    if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");
    if (model.offlineModuleModel.canCreate === false) throw new Error("offlineModuleModelNotSaveable");

    firestoreDocument.id = await createOfflineUniqueId(model.offlineModuleModel);
    firestoreDocument.parentId = parentId;

    indexedDbModel.setDocument(model.collectionName, firestoreDocument.id, firestoreDocument.toOfflineCache());
    model.offlineModuleModel.createDocumentToQueue(firestoreDocument);
    return firestoreDocument.id;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.createOfflineDocument", { user, model, firestoreDocument, parentId }, error);
    return "ERROR";
  }
};

const createOfflineUniqueId = async <T extends FirestoreOfflineDocument>(offlineModuleModel: OfflineModuleModel<T>): Promise<string> => {
  try {
    let offlineUniqueId: string = DataHelpers.uniqueId(20);
    const documents: T[] = await offlineModel.getCacheByCollection(offlineModuleModel);
    while (documents.filter((document: T) => document.id === offlineUniqueId).length > 0) {
      offlineUniqueId = DataHelpers.uniqueId(20);
    }
    return offlineUniqueId;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.createOfflineUniqueId", { offlineModuleModel }, error);
    return "ERROR";
  }
};
