import { CostListOwnerType } from "@/features/modules/costList/objects/CostListOwnerType";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { BooleanField, EnumField, NumberField, StringArrayField, StringField } from "@/core/fields";

export class InvoiceCostRow {
  public id: string = DataHelpers.uniqueId();
  public name: string | undefined = undefined;
  public ownerType: CostListOwnerType = CostListOwnerType.Doctor;
  public ownerId: string | undefined = undefined;
  public price = 0;
  public isVatApplied = true;
  public quantity = 1;
  public amount = 0;
  public companyId: string | undefined = undefined;
  public details: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): InvoiceCostRow {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromFirestore(data.name);
    this.ownerType = EnumField.fromFirestore<CostListOwnerType>(data.ownerType, Object.values(CostListOwnerType), CostListOwnerType.Doctor);
    this.ownerId = StringField.fromFirestore(data.ownerId);
    this.price = NumberField.fromFirestore(data.price);
    this.isVatApplied = BooleanField.fromFirestore(data.isVatApplied);
    this.quantity = NumberField.fromFirestore(data.quantity);
    this.amount = NumberField.fromFirestore(data.amount);
    this.companyId = StringField.fromFirestore(data.companyId);
    this.details = StringArrayField.fromFirestore(data.details);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.ownerType = EnumField.toFirestore(this.ownerType, CostListOwnerType.Doctor);
    firestoreData.ownerId = StringField.toFirestore(this.ownerId);
    firestoreData.price = NumberField.toFirestore(this.price);
    firestoreData.isVatApplied = BooleanField.toFirestore(this.isVatApplied);
    firestoreData.quantity = NumberField.toFirestore(this.quantity);
    firestoreData.amount = NumberField.toFirestore(this.amount);
    firestoreData.companyId = StringField.toFirestore(this.companyId);
    firestoreData.details = StringArrayField.toFirestore(this.details);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): InvoiceCostRow {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromOfflineCache(data.name);
    this.ownerType = EnumField.fromOfflineCache<CostListOwnerType>(data.ownerType, Object.values(CostListOwnerType), CostListOwnerType.Doctor);
    this.ownerId = StringField.fromOfflineCache(data.ownerId);
    this.price = NumberField.fromOfflineCache(data.price);
    this.isVatApplied = BooleanField.fromOfflineCache(data.isVatApplied);
    this.quantity = NumberField.fromOfflineCache(data.quantity);
    this.amount = NumberField.fromOfflineCache(data.amount);
    this.companyId = StringField.fromOfflineCache(data.companyId);
    this.details = StringArrayField.fromOfflineCache(data.details);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.ownerType = EnumField.toOfflineCache(this.ownerType, CostListOwnerType.Doctor);
    firestoreData.ownerId = StringField.toOfflineCache(this.ownerId);
    firestoreData.price = NumberField.toOfflineCache(this.price);
    firestoreData.isVatApplied = BooleanField.toOfflineCache(this.isVatApplied);
    firestoreData.quantity = NumberField.toOfflineCache(this.quantity);
    firestoreData.amount = NumberField.toOfflineCache(this.amount);
    firestoreData.companyId = StringField.toOfflineCache(this.companyId);
    firestoreData.details = StringArrayField.toOfflineCache(this.details);

    return firestoreData;
  }
}
