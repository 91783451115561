import { format, subDays } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Company } from "@/features/modules/company/objects/Company";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedCompanyDuty } from "@/features/modules/companyDuty/objects/LinkedCompanyDuty";
import { Timestamp } from "firebase/firestore";

export class AssignEmployeeDutyModel {
  public async assignEmployeeDuty(company: Company, employees: Employee[], companyDuties: CompanyDuty[], toDate: Date | undefined): Promise<void> {
    try {
      for (const employee of employees) {
        const employeeDuty: EmployeeDuty = new EmployeeDuty();
        employeeDuty.company = LinkedCompany.createFromCompany(company);
        for (const companyDuty of companyDuties) {
          employeeDuty.addCompanyDuty(LinkedCompanyDuty.createFromCompanyDuty(companyDuty));
        }
        employeeDutyModel.setPersonalDuty(employeeDuty, companyDuties);

        // search for previous duties
        const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getDocuments([], employee.id);

        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);

        const previousEmployeeDuties: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
          return employeeDuty.company !== undefined && employeeDuty.company.id === company.id;
        });

        const previousEmployeeDutiesByDate: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
          return (
            employeeDuty.company !== undefined &&
            employeeDuty.company.id === company.id &&
            employeeDuty.fromSort < format(today, "yyyy-MM-dd") &&
            employeeDuty.toSort >= format(today, "yyyy-MM-dd")
          );
        });
        const isAssignPossible: boolean = previousEmployeeDuties.length === 0 || previousEmployeeDutiesByDate.length === 1;

        if (isAssignPossible === false) continue;

        /*
        // search employers to set the from date
        let fromDate: Date | undefined = undefined;
        const employers: Employer[] = await employerModel.getDocuments([], employee.id);
        for (const employer of employers) {
          if (employer.company === undefined) continue;
          if (employer.company.id !== company.id) continue;
          if (employer.from === undefined) continue;
          if (toDate !== undefined && compareAsc(employer.from, toDate) > 0) continue;
          fromDate = employer.from;
          fromDate.setHours(0, 0, 0, 0);
        }
        */

        if (previousEmployeeDutiesByDate.length === 1) {
          // update previous duty
          const previousDutyTo: Date = subDays(today, 1);
          previousDutyTo.setHours(0, 0, 0, 0);
          batch.update(employeeDutyModel.getDocumentReference(previousEmployeeDutiesByDate[0].id, employee.id), {
            to: Timestamp.fromDate(previousDutyTo),
            toSort: format(previousDutyTo, "yyyy-MM-dd"),
          });
        }

        employeeDuty.from = today;
        await employeeDutyModel.createDocument(employeeDuty, employee.id);
      }
    } catch (error: unknown) {
      appFaultModel.catchAppError("AssignEmployeeDutyModel.assignEmployeeDuty", { company, employees, companyDuties, toDate }, error);
    }
  }
}

export const assignEmployeeDutyModel: AssignEmployeeDutyModel = new AssignEmployeeDutyModel();
