import { SurveyForm } from "./SurveyForm";

import { BooleanField, StringField } from "@/core/fields";

export class NoseSurveyForm extends SurveyForm {
  public question1 = false;
  public question1Details: string | undefined = undefined;
  public question2 = false;
  public question2Details: string | undefined = undefined;
  public question3 = false;
  public question3Details: string | undefined = undefined;
  public question4 = false;
  public question4Details: string | undefined = undefined;
  public question5 = false;
  public question5Details: string | undefined = undefined;
  public question6 = false;
  public question6Details: string | undefined = undefined;
  public question7 = false;
  public question7Details: string | undefined = undefined;
  public question8 = false;
  public question8Details: string | undefined = undefined;
  public question9 = false;
  public question9Details: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): NoseSurveyForm {
    super.fromFirestore(data);

    this.question1 = BooleanField.fromFirestore(data.question1);
    this.question1Details = StringField.fromFirestore(data.question1Details);
    this.question2 = BooleanField.fromFirestore(data.question2);
    this.question2Details = StringField.fromFirestore(data.question2Details);
    this.question3 = BooleanField.fromFirestore(data.question3);
    this.question3Details = StringField.fromFirestore(data.question3Details);
    this.question4 = BooleanField.fromFirestore(data.question4);
    this.question4Details = StringField.fromFirestore(data.question4Details);
    this.question5 = BooleanField.fromFirestore(data.question5);
    this.question5Details = StringField.fromFirestore(data.question5Details);
    this.question6 = BooleanField.fromFirestore(data.question6);
    this.question6Details = StringField.fromFirestore(data.question6Details);
    this.question7 = BooleanField.fromFirestore(data.question7);
    this.question7Details = StringField.fromFirestore(data.question7Details);
    this.question8 = BooleanField.fromFirestore(data.question8);
    this.question8Details = StringField.fromFirestore(data.question8Details);
    this.question9 = BooleanField.fromFirestore(data.question9);
    this.question9Details = StringField.fromFirestore(data.question9Details);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question1Details = StringField.toFirestore(this.question1Details);
    firestoreData.question2 = BooleanField.toFirestore(this.question2);
    firestoreData.question2Details = StringField.toFirestore(this.question2Details);
    firestoreData.question3 = BooleanField.toFirestore(this.question3);
    firestoreData.question3Details = StringField.toFirestore(this.question3Details);
    firestoreData.question4 = BooleanField.toFirestore(this.question4);
    firestoreData.question4Details = StringField.toFirestore(this.question4Details);
    firestoreData.question5 = BooleanField.toFirestore(this.question5);
    firestoreData.question5Details = StringField.toFirestore(this.question5Details);
    firestoreData.question6 = BooleanField.toFirestore(this.question6);
    firestoreData.question6Details = StringField.toFirestore(this.question6Details);
    firestoreData.question7 = BooleanField.toFirestore(this.question7);
    firestoreData.question7Details = StringField.toFirestore(this.question7Details);
    firestoreData.question8 = BooleanField.toFirestore(this.question8);
    firestoreData.question8Details = StringField.toFirestore(this.question8Details);
    firestoreData.question9 = BooleanField.toFirestore(this.question9);
    firestoreData.question9Details = StringField.toFirestore(this.question9Details);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): NoseSurveyForm {
    super.fromOfflineCache(data);

    this.question1 = BooleanField.fromOfflineCache(data.question1);
    this.question1Details = StringField.fromOfflineCache(data.question1Details);
    this.question2 = BooleanField.fromOfflineCache(data.question2);
    this.question2Details = StringField.fromOfflineCache(data.question2Details);
    this.question3 = BooleanField.fromOfflineCache(data.question3);
    this.question3Details = StringField.fromOfflineCache(data.question3Details);
    this.question4 = BooleanField.fromOfflineCache(data.question4);
    this.question4Details = StringField.fromOfflineCache(data.question4Details);
    this.question5 = BooleanField.fromOfflineCache(data.question5);
    this.question5Details = StringField.fromOfflineCache(data.question5Details);
    this.question6 = BooleanField.fromOfflineCache(data.question6);
    this.question6Details = StringField.fromOfflineCache(data.question6Details);
    this.question7 = BooleanField.fromOfflineCache(data.question7);
    this.question7Details = StringField.fromOfflineCache(data.question7Details);
    this.question8 = BooleanField.fromOfflineCache(data.question8);
    this.question8Details = StringField.fromOfflineCache(data.question8Details);
    this.question9 = BooleanField.fromOfflineCache(data.question9);
    this.question9Details = StringField.fromOfflineCache(data.question9Details);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question1Details = StringField.toOfflineCache(this.question1Details);
    firestoreData.question2 = BooleanField.toOfflineCache(this.question2);
    firestoreData.question2Details = StringField.toOfflineCache(this.question2Details);
    firestoreData.question3 = BooleanField.toOfflineCache(this.question3);
    firestoreData.question3Details = StringField.toOfflineCache(this.question3Details);
    firestoreData.question4 = BooleanField.toOfflineCache(this.question4);
    firestoreData.question4Details = StringField.toOfflineCache(this.question4Details);
    firestoreData.question5 = BooleanField.toOfflineCache(this.question5);
    firestoreData.question5Details = StringField.toOfflineCache(this.question5Details);
    firestoreData.question6 = BooleanField.toOfflineCache(this.question6);
    firestoreData.question6Details = StringField.toOfflineCache(this.question6Details);
    firestoreData.question7 = BooleanField.toOfflineCache(this.question7);
    firestoreData.question7Details = StringField.toOfflineCache(this.question7Details);
    firestoreData.question8 = BooleanField.toOfflineCache(this.question8);
    firestoreData.question8Details = StringField.toOfflineCache(this.question8Details);
    firestoreData.question9 = BooleanField.toOfflineCache(this.question9);
    firestoreData.question9Details = StringField.toOfflineCache(this.question9Details);

    return firestoreData;
  }
}
