import { RouteRecordRaw } from "vue-router";

import { CompanyDocHelpers } from "../helpers/CompanyDocHelpers";
import { companyDocModel } from "../models/CompanyDocModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";

export const companyDocRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:sourceId/docs/:docId/edit",
    name: "CompanyDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: CompanyDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Company,
      sourceModel: companyDocModel,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "companyDoc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:sourceId/docs/:docId",
    name: "CompanyDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      filesPath: CompanyDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Company,
      sourceId: route.params.sourceId,
      sourceModel: companyDocModel,
      sourcePath: "companies",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "companyDoc",
      requiredRight: "viewUI",
    },
  },
];
