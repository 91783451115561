
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, watch, WritableComputedRef } from "vue";

import * as _ from "lodash";
import { FileUploadSelectEvent } from "primevue/fileupload";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { config } from "@/core/modules/config/objects/Config";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "KoruImageUpload",
  props: {
    accept: { type: String, default: undefined },
    canDelete: { type: Boolean, default: true },
    file: { type: StorageFile, default: undefined },
    maxFileSize: { type: Number, default: undefined },
    metadata: { type: Object, default: undefined },
    path: { type: String, default: undefined },
  },
  emits: ["update:file"],
  setup(props, { emit }) {
    const { t } = useLocale();

    let eventBusId: string | undefined = undefined;
    const placeholderUrl = "/assets/core/images/placeholder.png";

    const fileRef: WritableComputedRef<StorageFile | undefined> = computed({
      get: () => props.file as StorageFile | undefined,
      set: (val) => emit("update:file", val),
    });

    const fileToDelete: Ref<StorageFile | undefined> = ref(undefined);
    const fileToUpload: Ref<File | undefined> = ref(undefined);
    const filePreview: Ref<string | undefined> = ref(undefined);
    const imageUrl: Ref<string> = ref(placeholderUrl);

    const submitAction = async (newName: string | undefined): Promise<void> => {
      if (props.path === undefined) return;
      console.log(props.path);

      if (fileToDelete.value !== undefined && props.canDelete === true && fileToDelete.value.uniqueName !== undefined) {
        await storageModel.deleteFile(props.path, fileToDelete.value.uniqueName);
        fileToDelete.value = undefined;
        fileRef.value = undefined;
      }
      if (fileToUpload.value !== undefined) {
        fileRef.value = await storageModel.uploadFile(props.path, fileToUpload.value, newName, props.metadata);
        fileToUpload.value = undefined;
      }
    };

    const onFileSelected = async (event: FileUploadSelectEvent): Promise<void> => {
      await AppHelpers.tryOrToast(
        async () => {
          if (event.files.length > 0) {
            fileToUpload.value = event.files[0];

            const reader: FileReader = new FileReader();
            reader.onload = function (e) {
              filePreview.value = e.target?.result as string;
            };
            reader.readAsDataURL(event.files[0]);
          } else {
            fileToUpload.value = undefined;
          }
        },
        "uploadFile",
        t,
        undefined,
        false
      );
    };

    const openFile = async () => {
      await AppHelpers.tryOrToast(
        async () => {
          if (fileRef.value === undefined) return;
          if (fileRef.value.uniqueName === undefined) return;
          if (props.path === undefined) return;

          const fileUrl: string = await storageModel.getDownloadLink(props.path, fileRef.value.uniqueName);

          window.open(fileUrl, "_blank");
        },
        "readFile",
        t,
        undefined,
        false
      );
    };

    const deleteFile = () => {
      if (props.canDelete === false) return;
      fileToDelete.value = _.cloneDeep(fileRef.value);
      fileRef.value = undefined;
    };

    watch(fileRef, async () => {
      if (fileRef.value === undefined || fileRef.value.uniqueName === undefined || props.path === undefined) {
        imageUrl.value = placeholderUrl;
        return;
      }
      imageUrl.value = await storageModel.getDownloadLink(props.path, fileRef.value.uniqueName);
    });

    onMounted(() => {
      eventBusId = eventBus.on("koruImageUploadSubmit", submitAction);
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      deleteFile,
      filePreview,
      fileRef,
      imageUrl,
      config,
      onFileSelected,
      openFile,
      placeholderUrl,
      t,
    };
  },
});
