import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { StringField } from "@/core/fields";

export class WorkHistoryItem {
  public id: string = DataHelpers.uniqueId();
  public period: string | undefined = undefined;
  public company: string | undefined = undefined;
  public sector: string | undefined = undefined;
  public duty: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): WorkHistoryItem {
    this.id = DataHelpers.uniqueId();

    this.period = StringField.fromFirestore(data.period);
    this.company = StringField.fromFirestore(data.company);
    this.sector = StringField.fromFirestore(data.sector);
    this.duty = StringField.fromFirestore(data.duty);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.period = StringField.toFirestore(this.period);
    firestoreData.company = StringField.toFirestore(this.company);
    firestoreData.sector = StringField.toFirestore(this.sector);
    firestoreData.duty = StringField.toFirestore(this.duty);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): WorkHistoryItem {
    this.id = DataHelpers.uniqueId();

    this.period = StringField.fromOfflineCache(data.period);
    this.company = StringField.fromOfflineCache(data.company);
    this.sector = StringField.fromOfflineCache(data.sector);
    this.duty = StringField.fromOfflineCache(data.duty);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.period = StringField.toOfflineCache(this.period);
    firestoreData.company = StringField.toOfflineCache(this.company);
    firestoreData.sector = StringField.toOfflineCache(this.sector);
    firestoreData.duty = StringField.toOfflineCache(this.duty);

    return firestoreData;
  }
}
