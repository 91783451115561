import { branchSearch } from "@/features/modules/branch/search/branchSearch";
import { brokerSearch } from "@/features/modules/broker/search/brokerSearch";
import { companySearch } from "@/features/modules/company/search/companySearch";
import { doctorSearch } from "@/features/modules/doctor/search/doctorSearch";
import { employeeSearch } from "@/features/modules/employee/search/employeeSearch";
import { examinationSearch } from "@/features/modules/examination/search/examinationSearch";
import { invoiceSearch } from "@/features/modules/invoice/search/invoiceSearch";
import { supplierSearch } from "@/features/modules/supplier/search/supplierSearch";

export const featuresSearchModules = [
  branchSearch,
  brokerSearch,
  companySearch,
  doctorSearch,
  employeeSearch,
  examinationSearch,
  invoiceSearch,
  supplierSearch,
];
