
import { computed, defineComponent, onMounted, watch, WritableComputedRef } from "vue";

import { geohashForLocation } from "geofire-common";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { config } from "@/core/modules/config/objects/Config";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { maps } from "@/core/plugins/maps/Maps";
import { ToastHelpers } from "@/core/modules/helpers/ToastHelpers";
import { useLocale } from "@/core/modules/locale/module";

import { AddressField } from "@/core/fields";

export default defineComponent({
  name: "KoruAddressMap",
  props: {
    height: { type: Number, default: 600 },
    modelValue: { type: AddressField, default: new AddressField() },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const elementId = `koru-address-map-${DataHelpers.uniqueId()}`;
    const { t } = useLocale();

    const addressRef: WritableComputedRef<AddressField> = computed({
      get: () => props.modelValue as AddressField,
      set: (val) => emit("update:modelValue", val),
    });

    const mapCenter: google.maps.LatLngLiteral = {
      lat: config.app.map.defaultLat,
      lng: config.app.map.defaultLng,
    };
    const mapZoom: number = config.app.map.defaultZoom;

    const init = async (): Promise<void> => {
      if (maps.isLoaded() === false) await maps.load();
      await AppHelpers.delay(2000);

      const mapDiv: HTMLElement = document.getElementById(elementId) as HTMLElement;
      const map: google.maps.Map = new window.google.maps.Map(mapDiv, {
        center: mapCenter,
        zoom: mapZoom,
        streetViewControl: false,
      });

      const svgMarkerIcon: google.maps.ReadonlySymbol = {
        path: "M0,2a2,2 0 1,0 4,0a2,2 0 1,0 -4,0",
        fillColor: "#2196f3",
        fillOpacity: 1.0,
        strokeWeight: 2.0,
        strokeColor: "#ffffff",
        rotation: 0,
        scale: 4,
        anchor: new google.maps.Point(1, 1),
      };

      const marker: google.maps.Marker = new google.maps.Marker({
        icon: svgMarkerIcon,
        map: undefined,
        optimized: true,
      });

      map.addListener("click", (e) => {
        setAddress(e.latLng.lat(), e.latLng.lng());
      });

      watch(addressRef, (address) => {
        drawMarker(address, marker, map);
      });

      drawMarker(addressRef.value, marker, map);
    };

    const drawMarker = (address: AddressField, marker: google.maps.Marker, map: google.maps.Map) => {
      if (address === undefined || address.latitude === undefined || address.longitude === undefined) {
        marker.setMap(null);
        return;
      }

      marker.setMap(map);
      marker.setPosition(new google.maps.LatLng(address.latitude, address.longitude));
      map.setCenter(new google.maps.LatLng(address.latitude, address.longitude));
      map.setZoom(15);
    };

    const setAddress = (latitude: number, longitude: number) => {
      const geoHash: string | undefined = latitude !== undefined && longitude !== undefined ? geohashForLocation([latitude, longitude]) : undefined;

      // perform a reverse geocode request
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === "OK") {
          let street: string | undefined;
          let town: string | undefined;
          let zipCode: string | undefined;
          let province: string | undefined;
          let country: string | undefined;

          if (results[0].address_components !== undefined) {
            for (const component of results[0].address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0];
              switch (componentType) {
                case "street_number":
                  street = component.long_name;
                  break;
                case "route":
                  street = component.long_name;
                  break;
                case "administrative_area_level_3":
                  town = component.long_name;
                  break;
                case "postal_code":
                  zipCode = component.long_name;
                  break;
                case "administrative_area_level_2":
                  province = component.short_name;
                  break;
                case "country":
                  country = component.long_name;
                  break;
              }
            }
          }

          addressRef.value = new AddressField(street, town, zipCode, province, country, latitude, longitude, geoHash);
        } else {
          ToastHelpers.showToastWithWarning("reverseGeocodingError", t);
          addressRef.value = new AddressField(undefined, undefined, undefined, undefined, undefined, latitude, longitude, geoHash);
        }
      });
    };

    onMounted(() => init());

    return {
      elementId,
    };
  },
});
