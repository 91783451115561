import { NumberField, StringStrictField } from "@/core/fields";

export class InvoiceCompanyTotal {
  public companyId: string;
  public subtotalVatExempted = 0;
  public subtotalVatSubjected = 0;
  public subtotal = 0;
  public vat = 0;
  public discount = 0;
  public total = 0;

  public constructor(companyId: string, firestoreData?: Record<string, unknown>) {
    this.companyId = companyId;
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): InvoiceCompanyTotal {
    this.companyId = StringStrictField.fromFirestore(data.companyId, this.companyId);
    this.subtotalVatExempted = NumberField.fromFirestore(data.subtotalVatExempted);
    this.subtotalVatSubjected = NumberField.fromFirestore(data.subtotalVatSubjected);
    this.subtotal = NumberField.fromFirestore(data.subtotal);
    this.vat = NumberField.fromFirestore(data.vat);
    this.discount = NumberField.fromFirestore(data.discount);
    this.total = NumberField.fromFirestore(data.total);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.companyId = StringStrictField.toFirestore(this.companyId);
    firestoreData.subtotalVatExempted = NumberField.toFirestore(this.subtotalVatExempted);
    firestoreData.subtotalVatSubjected = NumberField.toFirestore(this.subtotalVatSubjected);
    firestoreData.subtotal = NumberField.toFirestore(this.subtotal);
    firestoreData.vat = NumberField.toFirestore(this.vat);
    firestoreData.discount = NumberField.toFirestore(this.discount);
    firestoreData.total = NumberField.toFirestore(this.total);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): InvoiceCompanyTotal {
    this.companyId = StringStrictField.fromOfflineCache(data.companyId, this.companyId);
    this.subtotalVatExempted = NumberField.fromOfflineCache(data.subtotalVatExempted);
    this.subtotalVatSubjected = NumberField.fromOfflineCache(data.subtotalVatSubjected);
    this.subtotal = NumberField.fromOfflineCache(data.subtotal);
    this.vat = NumberField.fromOfflineCache(data.vat);
    this.discount = NumberField.fromOfflineCache(data.discount);
    this.total = NumberField.fromOfflineCache(data.total);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.companyId = StringStrictField.toOfflineCache(this.companyId);
    firestoreData.subtotalVatExempted = NumberField.toOfflineCache(this.subtotalVatExempted);
    firestoreData.subtotalVatSubjected = NumberField.toOfflineCache(this.subtotalVatSubjected);
    firestoreData.subtotal = NumberField.toOfflineCache(this.subtotal);
    firestoreData.vat = NumberField.toOfflineCache(this.vat);
    firestoreData.discount = NumberField.toOfflineCache(this.discount);
    firestoreData.total = NumberField.toOfflineCache(this.total);

    return firestoreData;
  }
}
