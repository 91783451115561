
import { defineComponent, onMounted } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { config } from "@/core/modules/config/objects/Config";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { maps } from "@/core/plugins/maps/Maps";

export default defineComponent({
  name: "KoruMap",
  props: {
    height: { type: Number, default: 600 },
    initFunction: { type: Function, default: undefined },
  },
  setup(props) {
    const elementId = "map-" + DataHelpers.uniqueId();

    const mapCenter: google.maps.LatLngLiteral = {
      lat: config.app.map.defaultLat,
      lng: config.app.map.defaultLng,
    };
    const mapZoom: number = config.app.map.defaultZoom;

    const init = async (): Promise<void> => {
      if (maps.isLoaded() === false) await maps.load();
      await AppHelpers.delay(2000);

      const mapDiv: HTMLElement = document.getElementById(elementId) as HTMLElement;
      const map: google.maps.Map = new window.google.maps.Map(mapDiv, {
        center: mapCenter,
        zoom: mapZoom,
        streetViewControl: false,
      });

      if (props.initFunction !== undefined) props.initFunction(map);
    };

    onMounted(() => init());

    return {
      elementId,
    };
  },
});
