export const it = {
  costList: {
    addCostItem: "Aggiungi costo",
    clonedCostListName: "Copia di {name}",
    costList: "Listino costi",
    costLists: "Listini costi",
    costs: "Costi",
    editItem: "Modifica elemento",
    isVatApplied: "IVA applicata",
    isVatAppliedError: "Selezionare un valore",
    itemName: "Descrizione",
    itemNameError: "Inserire una descrizione valida",
    items: "Elementi",
    itemsError: "Selezionare almeno un elemento",
    mode: "Modalità",
    modeError: "Selezionare una modalità",
    modes: {
      all: "Tutti",
      any: "Almeno uno",
      group: "Gruppo",
    },
    name: "Nome",
    nameError: "Inserire un nome valido",
    owner: "Titolare",
    ownerError: "Selezionare un titolare",
    owners: {
      doctor: "Medico",
      supplier: "Fornitore",
    },
    price: "Prezzo",
    priceError: "Inserire un prezzo valido",
    type: "Categoria",
    typeError: "Selezionare una categoria",
    types: {
      examType: "Visite mediche",
      serviceType: "Prestazioni",
      survey: "Questionari",
      testType: "Accertamenti",
    },
    vat: "IVA",
  },
  buttons: {
    cloneCostList: "Duplica listino costi",
  },
  navigation: {
    costList: "Listini costi",
  },
};
