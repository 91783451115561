
import { defineComponent } from "vue";

import { VuePDF, usePDF } from "@tato30/vue-pdf";

export default defineComponent({
  name: "KoruPdf",
  components: {
    VuePDF,
  },
  props: {
    pdfUrl: { type: String, required: true },
  },
  setup(props) {
    const { pdf, pages } = usePDF(props.pdfUrl);

    return {
      pages,
      pdf,
    };
  },
});
