import { ExamType } from "../objects/ExamType";
import { examTypeOfflineModel } from "./ExamTypeOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class ExamTypeModel extends FirestoreModel<ExamType> {
  public constructor() {
    super(() => new ExamType(), "examTypes", LockPolicy.DiscardUnsyncedChanges, "examType");
    this.beforeDeleteFunction = "featuresExamTypeBeforeDelete";
    this.offlineModuleModel = examTypeOfflineModel;
  }

  public async getDocuments(): Promise<ExamType[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(examTypeId: string): Promise<ExamType> {
    return super.getDocument(examTypeId);
  }

  public async createDocument(examType: ExamType): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    examType.order = documentCounter + 1;

    return super.createDocument(examType);
  }

  public async updateDocument(examType: ExamType): Promise<void> {
    return super.updateDocument(examType);
  }

  public async deleteDocument(examType: ExamType): Promise<boolean> {
    return super.deleteDocument(examType);
  }
}

export const examTypeModel: ExamTypeModel = new ExamTypeModel();
