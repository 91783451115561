import { Duty } from "../objects/Duty";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { cloneDuty } from "./methods";

export class DutyModel extends FirestoreModel<Duty> {
  public constructor() {
    super(() => new Duty(), "duties", LockPolicy.DiscardUnsyncedChanges, "duty");
  }

  public async getDocuments(): Promise<Duty[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(dutyId: string): Promise<Duty> {
    return super.getDocument(dutyId);
  }

  public async createDocument(duty: Duty): Promise<string> {
    return super.createDocument(duty);
  }

  public async updateDocument(duty: Duty): Promise<void> {
    return super.updateDocument(duty);
  }

  public async deleteDocument(duty: Duty): Promise<boolean> {
    return super.deleteDocument(duty);
  }

  public async cloneDuty(duty: Duty): Promise<string> {
    return cloneDuty(duty);
  }
}

export const dutyModel: DutyModel = new DutyModel();
