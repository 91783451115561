
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "PwaUpdateComponent",
  setup() {
    const { t } = useLocale();
    let eventBusId: string | undefined = undefined;

    const showUpdateDialog: Ref<boolean> = ref(false);

    const refresh = () => {
      window.location.reload();
    };

    onMounted(() => {
      eventBusId = eventBus.on("pwaUpdate", async () => {
        showUpdateDialog.value = true;
      });
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      refresh,
      showUpdateDialog,
      t,
    };
  },
});
