import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { ContractExpiration } from "@/features/modules/contract/objects/ContractExpiration";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getMissingContractsByFirm = async (): Promise<ContractExpiration[]> => {
  try {
    const contractExpirations: ContractExpiration[] = [];

    const firmId: string = firmModel.getSelectedFirmId();

    const companies: Company[] = await companyModel.getCompaniesWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("missingContracts", firmId);

    for (const company of companies) {
      if (company.trackExpirations === false) continue;

      const companyWidget: Widget | undefined = widgets.find((widget) => widget.source === firmId && widget.sourceId === company.id);
      if (companyWidget === undefined) continue;
      if (companyWidget.values.count === 0) continue;

      contractExpirations.push(new ContractExpiration(company.id, company.name ?? "-", new Date("1900-01-01")));
    }

    return contractExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.getMissingContractsByFirm", {}, error);
    return [];
  }
};
