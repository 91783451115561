import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";
import { TestType } from "@/features/modules/testType/objects/TestType";
import { TestTypeCategory } from "@/features/modules/testTypeCategory/objects/TestTypeCategory";
import { testTypeCategoryModel } from "../TestTypeCategoryModel";
import { TestTypeCategoryWithTestTypes } from "@/features/modules/testTypeCategory/objects/TestTypeCategoryWithTestTypes";
import { testTypeModel } from "@/features/modules/testType/models/TestTypeModel";

export const getTestTypeCategoriesWithTestTypes = async (onlyTestTypesIds: string[] = []): Promise<TestTypeCategoryWithTestTypes[]> => {
  try {
    const testTypeCategoriesWithTestTypes: TestTypeCategoryWithTestTypes[] = [];

    const testTypeCategories: TestTypeCategory[] = await testTypeCategoryModel.getDocuments();
    const testTypes: TestType[] = await testTypeModel.getDocuments();

    for (const testTypeCategory of testTypeCategories) {
      const testTypeCategoryWithTestTypes: TestTypeCategoryWithTestTypes = new TestTypeCategoryWithTestTypes();
      testTypeCategoryWithTestTypes.id = testTypeCategory.id;
      testTypeCategoryWithTestTypes.name = testTypeCategory.name;
      testTypeCategoryWithTestTypes.testTypes = testTypes
        .filter((loopTestType) => loopTestType.category?.id == testTypeCategory.id)
        .filter((loopTestType) => onlyTestTypesIds.length == 0 || onlyTestTypesIds.includes(loopTestType.id))
        .map((testType) => LinkedTestType.createFromTestType(testType));

      testTypeCategoryWithTestTypes.testTypes = testTypeCategoryWithTestTypes.testTypes.sort((a: LinkedTestType, b: LinkedTestType) => {
        if (a.name == undefined || b.name == undefined) return 0;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      if (testTypeCategoryWithTestTypes.testTypes.length > 0) {
        testTypeCategoriesWithTestTypes.push(testTypeCategoryWithTestTypes);
      }
    }

    return testTypeCategoriesWithTestTypes;
  } catch (error: unknown) {
    appFaultModel.catchAppError("TestTypeCategoryModel.getTestTypeCategoriesWithTestTypes", { onlyTestTypesIds }, error);
    return [];
  }
};
