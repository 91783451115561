import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { companyDutyModel } from "@/features/modules/companyDuty/models/CompanyDutyModel";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";

export const cascadeUpdateCompanyDuty = async (companyDuty: CompanyDuty, companyId: string): Promise<void> => {
  try {
    const availableCompanyDuties: CompanyDuty[] = await companyDutyModel.getDocuments([], companyId);

    const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getEmployeeDutiesByCompany(companyId);
    for (const employeeDuty of employeeDuties) {
      if (
        employeeDuty
          .getCompanyDuties()
          .flatMap((linkedDuty) => linkedDuty.id)
          .includes(companyDuty.id)
      ) {
        const selectedCompanyDuties: CompanyDuty[] = [];
        for (const availableCompanyDuty of availableCompanyDuties) {
          if (
            employeeDuty
              .getCompanyDuties()
              .flatMap((companyDuty) => companyDuty.id)
              .includes(availableCompanyDuty.id)
          ) {
            selectedCompanyDuties.push(availableCompanyDuty);
          }
        }

        employeeDutyModel.setPersonalDuty(employeeDuty, selectedCompanyDuties);
        batch.update(employeeDutyModel.getDocumentReference(employeeDuty.id, employeeDuty.parentId), employeeDuty.toFirestore());
      }
    }

    await batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyDutyModel.cascadeUpdateCompanyDuty", { companyDuty, companyId }, error);
  }
};
