export const frCoreLabels = {
  buttons: {
    back: "En arrière",
    cancel: "Annuler",
    clear: "Effacer",
    close: "Fermer",
    create: "Créer",
    createUser: "Créer un utilisateur",
    delete: "Supprimer",
    download: "Télécharger",
    disableUser: "Désactiver l'utilisateur",
    edit: "Modifier",
    enableUser: "Activer l'utilisateur",
    export: "Exporter",
    import: "Importer",
    load: "Charger",
    markAsRead: "Marquer comme lu",
    noAction: "Aucune action disponible",
    print: "Imprimer",
    run: "Exécuter",
    enregistrer: "Enregistrer",
    search: "Chercher",
    send: "Envoyer",
    sendNotification: "Envoyer une notification",
    toggleUser: "Inverser l'état",
    upload: "Télécharger le fichier",
    view: "Afficher",
  },
  dialog: {
    confirmCloseWithoutSave: "Fermer sans enregistrer?",
    confirmDelete: "Êtes-vous sûr de vouloir supprimer?",
    editingConfirm: "Les données non enregistrées seront perdues, êtes-vous sûr?",
    editingTitle: "Quitter sans enregistrer",
  },
  gen: {
    actions: "Actions",
    any: "N'importe quel",
    all: "Tout",
    backTo: "Retour à",
    browse: "Parcourir",
    confirmActionText: "Confirmez-vous l'exécution de l'action sélectionnée?",
    confirmActionTitle: "Confirmer l'action",
    dashboard: "Tableau de bord",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer",
    deleteObject: "l'objet sélectionné?",
    deleteTitle: "Confirmer la suppression",
    devAlert: "Version de développement",
    developedby: "développé par",
    document: "Document",
    editPrefix: "Modifier",
    empty: "Vide",
    errorContact: "Une erreur s'est produite, contactez le développeur",
    expirationDaysLeft: "jours restants",
    expirationExpired: "Expiré",
    expirationNotAvailable: "Non prévu",
    file: "Document attaché",
    files: "Documents attachés",
    filePlaceholder: "Sélectionnez un fichier ou faites-le glisser ici",
    filter: "Filtre",
    findAddress: "Rechercher l'adresse",
    generals: "Généraux",
    guest: "Invité",
    info: "Informations",
    inputAddress: "Entrez une adresse",
    loading: "Chargement",
    logout: "Quitter",
    maintenanceDescription: "Désolé pour le désagrément. La maintenance est en cours, KORU sera bientôt de retour en ligne.",
    maintenanceTitle: "Nous serons bientôt de retour!",
    missingPrivileges: "Vous n'avez pas les privilèges nécessaires pour afficher la page",
    newPrefix: "Créer",
    no: "Non",
    noData: "Aucune donnée disponible",
    ok: "Ok",
    oops: "OUPS!",
    pageNotFound: "La page demandée n'est pas disponible",
    pageResults: "Résultats du [[first]] au [[last]] sur [[totalRecords]] total",
    profile: "Profil",
    reorder: "Ordre",
    search: "Rechercher",
    unknown: "inconnu",
    user: "Utilisateur",
    viewPrefix: "Afficher",
    yes: "Oui",
  },
  navigation: {
    about: "À propos",
    administration: "Admin",
    content: "Contenu",
    dashboard: "Tableau",
    development: "Développement",
    general: "Général",
    structure: "Structure",
    tools: "Outils",
  },
  rights: {
    create: "Créer",
    deleteAll: "Tout supprimer",
    deleteAllowed: "Supprimer autorisé",
    deleteOwned: "Supprimer le propriétaire",
    readAll: "Tout lire",
    readAllowed: "Lire autorisé",
    readOwned: "Lire propre",
    updateAll: "Tout modifier",
    updateAllowed: "Modifier autorisé",
    updateOwned: "Modifier le propriétaire",
    viewUI: "Afficher UI",
  },
  themes: {
    dim: "Nuit",
    light: "Lumière",
    solarized: "Solarisé",
  },
  toast: {
    error: {
      actionError: "L'action n'a pas pu être terminée",
      backupDataNotSet: "Les données de sauvegarde n'ont pas été chargées",
      children: "Il y a des articles liés",
      create: "Impossible de créer",
      delete: "Impossible de supprimer",
      deleteFile: "Le fichier n'a pas pu être supprimé",
      emailChanged: "L'adresse e-mail n'a pas pu être modifiée",
      feedbackSent: "Le commentaire n'a pas pu être envoyé",
      fiscalCode: "Il n'a pas été possible de calculer le code fiscal",
      generic: "Une erreur inattendue s'est produite",
      noAction: "Aucune action n'a été sélectionnée",
      notificationSent: "La notification n'a pas pu être envoyée",
      notSupportedFileExtension: "Le type de fichier téléchargé n'est pas pris en charge",
      passwordChanged: "Le mot de passe n'a pas pu être modifié",
      readFile: "Le fichier n'a pas pu être lu",
      reorder: "Impossible de réorganiser",
      save: "Impossible d'enregistrer",
      search: "Impossible de rechercher",
      sync: "Les données ont été modifiées par un autre utilisateur",
      title: "Erreur!",
      toggleUser: "Erreur lors du changement d'état",
      uploadFile: "Le fichier n'a pas pu être téléchargé",
    },
    success: {
      actionSuccess: "Action effectuée avec succès",
      backupDownloadSuccess: "Sauvegarde générée avec succès",
      backupRestoreSuccess: "Sauvegarde restaurée avec succès",
      create: "Création réussie",
      delete: "Suppression réussie",
      deleteFile: "Fichier supprimé avec succès",
      emailChanged: "L'adresse e-mail a été modifiée avec succès",
      feedbackSent: "Commentaire envoyé avec succès",
      generic: "L'opération s'est terminée avec succès",
      notificationSent: "Notification envoyée avec succès",
      passwordChanged: "Mot de passe modifié avec succès",
      save: "Enregistrement réussi",
      title: "Terminé!",
      toggleUser: "Statut modifié avec succès",
      uploadFile: "Le fichier a été téléchargé avec succès",
    },
    warning: {
      filterMissing: "Sélectionner tous les filtres",
      formInvalid: "Vérifiez les champs saisis",
      reverseGeocodingError: "Impossible de trouver l'adresse",
      searchNoResults: "Aucun résultat trouvé",
      searchTextTooShort: "Entrez au moins 3caractères",
      seederNoModules: "Sélectionnez au moins une collection",
      title: "Attention!",
    },
  },
};
