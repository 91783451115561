import { TestForm } from "./TestForm";

import { StringField } from "@/core/fields";

export class AudioTestForm extends TestForm {
  public classType: string | undefined = undefined;
  public leftClass: string | undefined = undefined;
  public rightClass: string | undefined = undefined;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): AudioTestForm {
    super.fromFirestore(data);

    this.classType = StringField.fromFirestore(data.classType);
    this.leftClass = StringField.fromFirestore(data.leftClass);
    this.rightClass = StringField.fromFirestore(data.rightClass);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.classType = StringField.toFirestore(this.classType);
    firestoreData.leftClass = StringField.toFirestore(this.leftClass);
    firestoreData.rightClass = StringField.toFirestore(this.rightClass);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): AudioTestForm {
    super.fromOfflineCache(data);

    this.classType = StringField.fromOfflineCache(data.classType);
    this.leftClass = StringField.fromOfflineCache(data.leftClass);
    this.rightClass = StringField.fromOfflineCache(data.rightClass);
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.classType = StringField.toOfflineCache(this.classType);
    firestoreData.leftClass = StringField.toOfflineCache(this.leftClass);
    firestoreData.rightClass = StringField.toOfflineCache(this.rightClass);
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
