export const it = {
  utilityExportEmployee: {
    name: "Esporta lavoratori per strumenti",
  },
  toast: {
    error: {
      noEmployees: "Nessun lavoratore trovato",
    },
    success: {},
    warning: {
      noCompany: "Nessuna azienda selezionata",
    },
  },
};
