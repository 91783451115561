import { SurveyForm } from "./SurveyForm";

import { BooleanField, StringField } from "@/core/fields";

export class BronchitisSurveyForm extends SurveyForm {
  public question1 = false;
  public question2 = false;
  public question3 = false;
  public question3b: string | undefined = undefined;
  public question4 = false;
  public question5 = false;
  public question6 = false;
  public question6b: string | undefined = undefined;
  public question6c = false;
  public question7 = false;
  public question8 = false;
  public question9 = false;
  public question10 = false;
  public question11 = false;
  public question11b: string | undefined = undefined;
  public question12 = false;
  public question13 = false;
  public question14 = false;
  public question15 = false;
  public question16 = false;
  public question17 = false;
  public question17b: string | undefined = undefined;
  public question17c: string | undefined = undefined;
  public question17d = false;
  public question18 = false;
  public question18b: string | undefined = undefined;
  public question18c: string | undefined = undefined;
  public question18d: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): BronchitisSurveyForm {
    super.fromFirestore(data);

    this.question1 = BooleanField.fromFirestore(data.question1);
    this.question2 = BooleanField.fromFirestore(data.question2);
    this.question3 = BooleanField.fromFirestore(data.question3);
    this.question3b = StringField.fromFirestore(data.question3b);
    this.question4 = BooleanField.fromFirestore(data.question4);
    this.question5 = BooleanField.fromFirestore(data.question5);
    this.question6 = BooleanField.fromFirestore(data.question6);
    this.question6b = StringField.fromFirestore(data.question6b);
    this.question6c = BooleanField.fromFirestore(data.question6c);
    this.question7 = BooleanField.fromFirestore(data.question7);
    this.question8 = BooleanField.fromFirestore(data.question8);
    this.question9 = BooleanField.fromFirestore(data.question9);
    this.question10 = BooleanField.fromFirestore(data.question10);
    this.question11 = BooleanField.fromFirestore(data.question11);
    this.question11b = StringField.fromFirestore(data.question11b);
    this.question12 = BooleanField.fromFirestore(data.question12);
    this.question13 = BooleanField.fromFirestore(data.question13);
    this.question14 = BooleanField.fromFirestore(data.question14);
    this.question15 = BooleanField.fromFirestore(data.question15);
    this.question16 = BooleanField.fromFirestore(data.question16);
    this.question17 = BooleanField.fromFirestore(data.question17);
    this.question17b = StringField.fromFirestore(data.question17b);
    this.question17c = StringField.fromFirestore(data.question17c);
    this.question17d = BooleanField.fromFirestore(data.question17d);
    this.question18 = BooleanField.fromFirestore(data.question18);
    this.question18b = StringField.fromFirestore(data.question18b);
    this.question18c = StringField.fromFirestore(data.question18c);
    this.question18d = StringField.fromFirestore(data.question18d);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question2 = BooleanField.toFirestore(this.question2);
    firestoreData.question3 = BooleanField.toFirestore(this.question3);
    firestoreData.question3b = StringField.toFirestore(this.question3b);
    firestoreData.question4 = BooleanField.toFirestore(this.question4);
    firestoreData.question5 = BooleanField.toFirestore(this.question5);
    firestoreData.question6 = BooleanField.toFirestore(this.question6);
    firestoreData.question6b = StringField.toFirestore(this.question6b);
    firestoreData.question6c = BooleanField.toFirestore(this.question6c);
    firestoreData.question7 = BooleanField.toFirestore(this.question7);
    firestoreData.question8 = BooleanField.toFirestore(this.question8);
    firestoreData.question9 = BooleanField.toFirestore(this.question9);
    firestoreData.question10 = BooleanField.toFirestore(this.question10);
    firestoreData.question11 = BooleanField.toFirestore(this.question11);
    firestoreData.question11b = StringField.toFirestore(this.question11b);
    firestoreData.question12 = BooleanField.toFirestore(this.question12);
    firestoreData.question13 = BooleanField.toFirestore(this.question13);
    firestoreData.question14 = BooleanField.toFirestore(this.question14);
    firestoreData.question15 = BooleanField.toFirestore(this.question15);
    firestoreData.question16 = BooleanField.toFirestore(this.question16);
    firestoreData.question17 = BooleanField.toFirestore(this.question17);
    firestoreData.question17b = StringField.toFirestore(this.question17b);
    firestoreData.question17c = StringField.toFirestore(this.question17c);
    firestoreData.question17d = BooleanField.toFirestore(this.question17d);
    firestoreData.question18 = BooleanField.toFirestore(this.question18);
    firestoreData.question18b = StringField.toFirestore(this.question18b);
    firestoreData.question18c = StringField.toFirestore(this.question18c);
    firestoreData.question18d = StringField.toFirestore(this.question18d);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): BronchitisSurveyForm {
    super.fromOfflineCache(data);

    this.question1 = BooleanField.fromOfflineCache(data.question1);
    this.question2 = BooleanField.fromOfflineCache(data.question2);
    this.question3 = BooleanField.fromOfflineCache(data.question3);
    this.question3b = StringField.fromOfflineCache(data.question3b);
    this.question4 = BooleanField.fromOfflineCache(data.question4);
    this.question5 = BooleanField.fromOfflineCache(data.question5);
    this.question6 = BooleanField.fromOfflineCache(data.question6);
    this.question6b = StringField.fromOfflineCache(data.question6b);
    this.question6c = BooleanField.fromOfflineCache(data.question6c);
    this.question7 = BooleanField.fromOfflineCache(data.question7);
    this.question8 = BooleanField.fromOfflineCache(data.question8);
    this.question9 = BooleanField.fromOfflineCache(data.question9);
    this.question10 = BooleanField.fromOfflineCache(data.question10);
    this.question11 = BooleanField.fromOfflineCache(data.question11);
    this.question11b = StringField.fromOfflineCache(data.question11b);
    this.question12 = BooleanField.fromOfflineCache(data.question12);
    this.question13 = BooleanField.fromOfflineCache(data.question13);
    this.question14 = BooleanField.fromOfflineCache(data.question14);
    this.question15 = BooleanField.fromOfflineCache(data.question15);
    this.question16 = BooleanField.fromOfflineCache(data.question16);
    this.question17 = BooleanField.fromOfflineCache(data.question17);
    this.question17b = StringField.fromOfflineCache(data.question17b);
    this.question17c = StringField.fromOfflineCache(data.question17c);
    this.question17d = BooleanField.fromOfflineCache(data.question17d);
    this.question18 = BooleanField.fromOfflineCache(data.question18);
    this.question18b = StringField.fromOfflineCache(data.question18b);
    this.question18c = StringField.fromOfflineCache(data.question18c);
    this.question18d = StringField.fromOfflineCache(data.question18d);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question2 = BooleanField.toOfflineCache(this.question2);
    firestoreData.question3 = BooleanField.toOfflineCache(this.question3);
    firestoreData.question3b = StringField.toOfflineCache(this.question3b);
    firestoreData.question4 = BooleanField.toOfflineCache(this.question4);
    firestoreData.question5 = BooleanField.toOfflineCache(this.question5);
    firestoreData.question6 = BooleanField.toOfflineCache(this.question6);
    firestoreData.question6b = StringField.toOfflineCache(this.question6b);
    firestoreData.question6c = BooleanField.toOfflineCache(this.question6c);
    firestoreData.question7 = BooleanField.toOfflineCache(this.question7);
    firestoreData.question8 = BooleanField.toOfflineCache(this.question8);
    firestoreData.question9 = BooleanField.toOfflineCache(this.question9);
    firestoreData.question10 = BooleanField.toOfflineCache(this.question10);
    firestoreData.question11 = BooleanField.toOfflineCache(this.question11);
    firestoreData.question11b = StringField.toOfflineCache(this.question11b);
    firestoreData.question12 = BooleanField.toOfflineCache(this.question12);
    firestoreData.question13 = BooleanField.toOfflineCache(this.question13);
    firestoreData.question14 = BooleanField.toOfflineCache(this.question14);
    firestoreData.question15 = BooleanField.toOfflineCache(this.question15);
    firestoreData.question16 = BooleanField.toOfflineCache(this.question16);
    firestoreData.question17 = BooleanField.toOfflineCache(this.question17);
    firestoreData.question17b = StringField.toOfflineCache(this.question17b);
    firestoreData.question17c = StringField.toOfflineCache(this.question17c);
    firestoreData.question17d = BooleanField.toOfflineCache(this.question17d);
    firestoreData.question18 = BooleanField.toOfflineCache(this.question18);
    firestoreData.question18b = StringField.toOfflineCache(this.question18b);
    firestoreData.question18c = StringField.toOfflineCache(this.question18c);
    firestoreData.question18d = StringField.toOfflineCache(this.question18d);

    return firestoreData;
  }
}
