import { RouteRecordRaw } from "vue-router";

export const whoRoutes: RouteRecordRaw[] = [
  {
    path: "/who",
    name: "WhoView",
    component: () => import("../views/WhoView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRight: "",
    },
  },
];
