import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DoctorSex } from "./DoctorSex";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";

import { AddressField, ArrayByKeyField, BooleanField, DateField, EnumField, ObjectField, StringArrayField, StringField } from "@/core/fields";

export class Doctor extends FirestoreOfflineDocument {
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public fullNameReversed: string | undefined = undefined;
  public fullNameWithTitle: string | undefined = undefined;
  public birthDate: Date | undefined = undefined;
  public birthPlace: string | undefined = undefined;
  public sex: DoctorSex = DoctorSex.Male;
  public fiscalCode: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public town: string | undefined = undefined;
  public zipCode: string | undefined = undefined;
  public province: string | undefined = undefined;
  public phone: string | undefined = undefined;
  public email: string | undefined = undefined;
  public notes: string | undefined = undefined;
  public firms: Record<string, LinkedFirm> = {};
  public userId: string | undefined = undefined;
  public authorizedCompaniesIds: string[] = [];
  public trackExpirations = true;
  public signatureImage: StorageFile | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Doctor {
    super.fromFirestore(data, id, firestoreRef);

    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.fullNameReversed = StringField.fromFirestore(data.fullNameReversed);
    this.fullNameWithTitle = StringField.fromFirestore(data.fullNameWithTitle);
    this.birthDate = DateField.fromFirestore(data.birthDate);
    this.birthPlace = StringField.fromFirestore(data.birthPlace);
    this.sex = EnumField.fromFirestore<DoctorSex>(data.sex, Object.values(DoctorSex), DoctorSex.Male);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.email = StringField.fromFirestore(data.email);
    this.notes = StringField.fromFirestore(data.notes);
    this.firms = ArrayByKeyField.fromFirestore<LinkedFirm>(data.firms, (firm) => new LinkedFirm(firm));
    this.userId = StringField.fromFirestore(data.userId);
    this.authorizedCompaniesIds = StringArrayField.fromFirestore(data.authorizedCompaniesIds);
    this.trackExpirations = BooleanField.fromFirestore(data.trackExpirations);
    this.signatureImage = ObjectField.fromFirestore<StorageFile>(data.signatureImage, (value) => new StorageFile(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.fullNameReversed = StringField.toFirestore(this.fullNameReversed);
    firestoreData.fullNameWithTitle = StringField.toFirestore(this.fullNameWithTitle);
    firestoreData.birthDate = DateField.toFirestore(this.birthDate);
    firestoreData.birthPlace = StringField.toFirestore(this.birthPlace);
    firestoreData.sex = EnumField.toFirestore<DoctorSex>(this.sex, DoctorSex.Male);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.firms = ArrayByKeyField.toFirestore<LinkedFirm>(this.firms, (value) => value.toFirestore());
    firestoreData.userId = StringField.toFirestore(this.userId);
    firestoreData.authorizedCompaniesIds = StringArrayField.toFirestore(this.authorizedCompaniesIds);
    firestoreData.trackExpirations = BooleanField.toFirestore(this.trackExpirations);
    firestoreData.signatureImage = ObjectField.toFirestore<StorageFile>(this.signatureImage, (value) => value.toFirestore());

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Doctor {
    super.fromOfflineCache(data);

    this.firstName = StringField.fromOfflineCache(data.firstName);
    this.lastName = StringField.fromOfflineCache(data.lastName);
    this.fullName = StringField.fromOfflineCache(data.fullName);
    this.fullNameReversed = StringField.fromOfflineCache(data.fullNameReversed);
    this.fullNameWithTitle = StringField.fromOfflineCache(data.fullNameWithTitle);
    this.birthDate = DateField.fromOfflineCache(data.birthDate);
    this.birthPlace = StringField.fromOfflineCache(data.birthPlace);
    this.sex = EnumField.fromOfflineCache<DoctorSex>(data.sex, Object.values(DoctorSex), DoctorSex.Male);
    this.fiscalCode = StringField.fromOfflineCache(data.fiscalCode);
    this.address.fromOfflineCache(data.address);
    this.phone = StringField.fromOfflineCache(data.phone);
    this.email = StringField.fromOfflineCache(data.email);
    this.notes = StringField.fromOfflineCache(data.notes);
    this.firms = ArrayByKeyField.fromOfflineCache<LinkedFirm>(data.firms, (firm) => {
      const linkedFirm: LinkedFirm = new LinkedFirm(firm);
      return linkedFirm.fromOfflineCache(firm);
    });
    this.userId = StringField.fromOfflineCache(data.userId);
    this.authorizedCompaniesIds = StringArrayField.fromOfflineCache(data.authorizedCompaniesIds);
    this.trackExpirations = BooleanField.fromOfflineCache(data.trackExpirations);
    this.signatureImage = ObjectField.fromOfflineCache<StorageFile>(data.signatureImage, (value) => new StorageFile(value));

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.firstName = StringField.toOfflineCache(this.firstName);
    firestoreData.lastName = StringField.toOfflineCache(this.lastName);
    firestoreData.fullName = StringField.toOfflineCache(this.fullName);
    firestoreData.fullNameReversed = StringField.toOfflineCache(this.fullNameReversed);
    firestoreData.fullNameWithTitle = StringField.toOfflineCache(this.fullNameWithTitle);
    firestoreData.birthDate = DateField.toOfflineCache(this.birthDate);
    firestoreData.birthPlace = StringField.toOfflineCache(this.birthPlace);
    firestoreData.sex = EnumField.toOfflineCache<DoctorSex>(this.sex, DoctorSex.Male);
    firestoreData.fiscalCode = StringField.toOfflineCache(this.fiscalCode);
    firestoreData.address = this.address.toOfflineCache();
    firestoreData.phone = StringField.toOfflineCache(this.phone);
    firestoreData.email = StringField.toOfflineCache(this.email);
    firestoreData.notes = StringField.toOfflineCache(this.notes);
    firestoreData.firms = ArrayByKeyField.toOfflineCache<LinkedFirm>(this.firms, (value) => value.toOfflineCache());
    firestoreData.userId = StringField.toOfflineCache(this.userId);
    firestoreData.authorizedCompaniesIds = StringArrayField.toOfflineCache(this.authorizedCompaniesIds);
    firestoreData.trackExpirations = BooleanField.toOfflineCache(this.trackExpirations);
    firestoreData.signatureImage = ObjectField.toOfflineCache<StorageFile>(this.signatureImage, (value) => value.toOfflineCache());

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.lastName)];
    this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.firstName)];
    if (this.email !== undefined) this.searchKeys.push(this.email.toLowerCase());
    if (this.fiscalCode !== undefined) this.searchKeys.push(this.fiscalCode.toLowerCase());
  }

  public setFullNames(): void {
    if (this.lastName != undefined && this.firstName != undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
      this.fullNameReversed = `${this.firstName} ${this.lastName}`;
      if (this.sex != undefined) {
        this.fullNameWithTitle = this.sex == "F" ? `Dott.ssa ${this.fullName}` : `Dott. ${this.fullName}`;
      }
    } else {
      this.fullName = undefined;
      this.fullNameReversed = undefined;
      this.fullNameWithTitle = undefined;
    }
  }

  public getLinkedFirms(): LinkedFirm[] {
    return DataHelpers.objectToSortedArray<LinkedFirm>(this.firms);
  }

  public setLinkedFirms(linkedFirms: LinkedFirm[]): void {
    this.firms = DataHelpers.sortedArrayToObject<LinkedFirm>(linkedFirms);
  }

  public addLinkedFirm(linkedFirm: LinkedFirm): void {
    this.firms[linkedFirm.id] = linkedFirm;
  }

  public removeLinkedFirm(linkedFirm: LinkedFirm): void {
    delete this.firms[linkedFirm.id];
  }
}
