import { RouteRecordRaw } from "vue-router";

export const hintRoutes: RouteRecordRaw[] = [
  {
    path: "/hints/:id/edit",
    name: "HintEdit",
    component: () => import("../views/HintEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "hint",
      requiredRight: "viewUI",
    },
  },
];
