export const it = {
  statExaminationCounter: {
    company: "Azienda",
    examinationCount: "Conteggio visite mediche",
    name: "Conteggio visite mediche per periodo",
    period: "Periodo",
  },
  toast: {
    error: {
      statServiceCounter: {
        periodInvalid: "Periodo non valido",
        periodUndefined: "Selezionare il periodo",
      },
    },
    success: {},
    warning: {},
  },
};
