import { Branch } from "./Branch";
import { LinkedBranch } from "./LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { ObjectField, StringField, BooleanField } from "@/core/fields";

export class LinkedBranchWithStatus extends LinkedFirestoreOfflineDocument {
  public company: LinkedCompany | undefined = undefined;
  public name: string | undefined = undefined;
  public legalBranch = false;
  public isActive = true;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromBranch(branch: Branch): LinkedBranchWithStatus {
    const linkedBranchWithStatus: LinkedBranchWithStatus = new LinkedBranchWithStatus();
    linkedBranchWithStatus.id = branch.id;
    linkedBranchWithStatus.company = branch.company;
    linkedBranchWithStatus.name = branch.name;
    linkedBranchWithStatus.legalBranch = branch.legalBranch;

    return linkedBranchWithStatus;
  }

  public static createFromLinkedBranch(linkedBranch: LinkedBranch): LinkedBranchWithStatus {
    const linkedBranchWithStatus: LinkedBranchWithStatus = new LinkedBranchWithStatus();
    linkedBranchWithStatus.id = linkedBranch.id;
    linkedBranchWithStatus.company = linkedBranch.company;
    linkedBranchWithStatus.name = linkedBranch.name;
    linkedBranchWithStatus.legalBranch = linkedBranch.legalBranch;

    return linkedBranchWithStatus;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedBranchWithStatus {
    super.fromFirestore(data);

    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.name = StringField.fromFirestore(data.name);
    this.legalBranch = BooleanField.fromFirestore(data.legalBranch);
    this.isActive = BooleanField.fromFirestore(data.isActive);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.legalBranch = BooleanField.toFirestore(this.legalBranch);
    firestoreData.isActive = BooleanField.toFirestore(this.isActive);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedBranchWithStatus {
    super.fromOfflineCache(data);

    this.company = ObjectField.fromOfflineCache<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.name = StringField.fromOfflineCache(data.name);
    this.legalBranch = BooleanField.fromOfflineCache(data.legalBranch);
    this.isActive = BooleanField.fromOfflineCache(data.isActive);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.company = ObjectField.toOfflineCache<LinkedCompany>(this.company, (value) => value.toOfflineCache());
    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.legalBranch = BooleanField.toOfflineCache(this.legalBranch);
    firestoreData.isActive = BooleanField.toOfflineCache(this.isActive);

    return firestoreData;
  }
}
