import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "col-9 mb-0" }
const _hoisted_3 = { class: "col-3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createBlock(_component_OverlayPanel, {
    ref: "overlayPanel",
    appendTo: "body",
    style: {"width":"25vw"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersRef, (filter, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass({
          field: true,
          grid: true,
          'mb-3': index < _ctx.filtersRef.length - 1,
          'mb-1': index == _ctx.filtersRef.length - 1,
        }),
            key: filter.name
          }, [
            _createElementVNode("label", _hoisted_2, _toDisplayString(filter.label), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Button, {
                class: "mr-2",
                icon: filter.browserFilter.value === null ? 'pi pi-eye' : 'pi pi-eye-slash',
                severity: filter.browserFilter.value === null ? 'success' : 'warning',
                onClick: ($event: any) => (filter.toggleAction())
              }, null, 8, ["icon", "severity", "onClick"])
            ])
          ], 2))
        }), 128))
      ])
    ]),
    _: 1
  }, 512))
}