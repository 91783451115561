export const it = {
  calendar: {
    calendar: "Calendario",
    calendars: "Calendari",
    color: "Colore",
    colorError: "Selezionare un colore",
    colors: {
      darkBlue: "Blu",
      darkGreen: "Verde scuro",
      lightBlue: "Celeste",
      lightGreen: "Verde chiaro",
      orange: "Arancio",
      purple: "Viola",
      red: "Rosso",
      yellow: "Giallo",
    },
    initFactoryCalendars: "Inizializza",
    name: "Nome",
    nameError: "Inserire un nome valido",
    roles: "Ruoli autorizzati",
  },
  navigation: {
    calendar: "Calendari",
  },
};
