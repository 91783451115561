import { AllergySurveyForm } from "../objects/AllergySurveyForm";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class AllergySurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private allergySurveyForm: AllergySurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    allergySurveyForm: AllergySurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.allergySurveyForm = allergySurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      allergyChoiceLabel: { color: this.textColor, fontSize: 10 },
      allergyChoiceValue: { color: this.textColor, bold: true, fontSize: 10 },
      allergyNotes: { alignment: "justify", color: this.textColor, fontSize: 10, margin: [0, 0, 0, DataHelpers.mmToPoints(3)] },
      allergyTextLabel: { color: this.textColor, bold: true, fontSize: 10 },
      allergyTextValue: { color: this.textColor, fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("QUESTIONARIO ALLERGOLOGICO SENSIBILIZZANTE", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle("Anamnesi familiare");
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question1"),
      this.allergySurveyForm.question1,
      this.t("surveyForm.allergySurveyForm.question2"),
      this.allergySurveyForm.question2
    );
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question3"),
      this.allergySurveyForm.question3,
      this.t("surveyForm.allergySurveyForm.question4"),
      this.allergySurveyForm.question4
    );
    this.writeChoice(this.t("surveyForm.allergySurveyForm.question5"), this.allergySurveyForm.question5);
    this.writeText(this.t("surveyForm.allergySurveyForm.question6"), this.allergySurveyForm.question6);

    this.writeSubtitle("Anamnesi patologica remota");
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question7"),
      this.allergySurveyForm.question7,
      this.t("surveyForm.allergySurveyForm.question8"),
      this.allergySurveyForm.question8
    );
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question9"),
      this.allergySurveyForm.question9,
      this.t("surveyForm.allergySurveyForm.question10"),
      this.allergySurveyForm.question10
    );
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question11"),
      this.allergySurveyForm.question11,
      this.t("surveyForm.allergySurveyForm.question12"),
      this.allergySurveyForm.question12
    );
    this.writeText(this.t("surveyForm.allergySurveyForm.question13"), this.allergySurveyForm.question13);
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question14"),
      this.allergySurveyForm.question14,
      this.t("surveyForm.allergySurveyForm.question15"),
      this.allergySurveyForm.question15
    );
    this.writeChoice(this.t("surveyForm.allergySurveyForm.question16"), this.allergySurveyForm.question16);
    this.writeText(this.t("surveyForm.allergySurveyForm.question17"), this.allergySurveyForm.question17);
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question18"),
      this.allergySurveyForm.question18,
      this.t("surveyForm.allergySurveyForm.question19"),
      this.allergySurveyForm.question19
    );
    this.writeDoubleChoice(
      this.t("surveyForm.allergySurveyForm.question20"),
      this.allergySurveyForm.question20,
      this.t("surveyForm.allergySurveyForm.question21"),
      this.allergySurveyForm.question21
    );

    if (this.allergySurveyForm.question22 !== undefined) {
      this.writeSubtitle("Note");
      this.writeNotes();
    }

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeChoice(question: string, answer: boolean): void {
    const answerTableBody: unknown[] = [[{ text: answer === true ? "X" : " ", style: "allergyChoiceValue", border: [true, true, true, true] }]];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question,
          style: "allergyChoiceLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "allergyChoiceValue",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeDoubleChoice(question1: string, answer1: boolean, question2: string, answer2: boolean): void {
    const answer1TableBody: unknown[] = [[{ text: answer1 === true ? "X" : " ", style: "allergyChoiceValue", border: [true, true, true, true] }]];
    const answer2TableBody: unknown[] = [[{ text: answer2 === true ? "X" : " ", style: "allergyChoiceValue", border: [true, true, true, true] }]];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question1,
          style: "allergyChoiceLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "allergyChoiceValue",
          table: {
            body: answer1TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question2,
          style: "allergyChoiceLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "allergyChoiceValue",
          table: {
            body: answer2TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeText(question: string, answer: string | undefined): void {
    if (answer === undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: "",
        margin: [0, DataHelpers.mmToPoints(4), 0, 0],
      });
      return;
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(60),
          text: question,
          style: "allergyTextLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          text: answer ?? "-",
          style: "allergyTextValue",
        },
      ],
    });
  }

  private writeNotes(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: this.allergySurveyForm.question22 ?? "-",
      style: "allergyNotes",
    });
  }
}
