
import { defineComponent, onMounted, Ref, ref } from "vue";
import { compareAsc } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { storeTypes } from "@/core/modules/store/types";
import { ServiceCount } from "../objects/ServiceCount";
import { serviceCounterModel } from "../models/ServiceCounterModel";
import { ServiceCounterPaper } from "../papers/ServiceCounterPaper";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "ServiceCounterView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<ServiceCount[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companies: Ref<Company[]> = ref([]);
    const companiesIds: Ref<string[]> = ref([]);
    const doctorId: Ref<string | undefined> = ref("all");
    const doctors: Ref<Doctor[]> = ref([]);
    const startDate: Date = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate: Date = new Date();
    endDate.setHours(23, 59, 59, 999);
    const period: Ref<Date[]> = ref([startDate, endDate]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;

      if (period.value[0] != undefined) sessionStorage.setItem("serviceCounter-startDate", period.value[0].getTime().toString());
      if (period.value[1] != undefined) sessionStorage.setItem("serviceCounter-endDate", period.value[1].getTime().toString());

      if (companiesIds.value !== undefined && companiesIds.value.length > 0) {
        sessionStorage.setItem("serviceCounter-companiesIds", companiesIds.value.join(","));
      } else {
        sessionStorage.removeItem("serviceCounter-companiesIds");
      }
      if (doctorId.value !== undefined) sessionStorage.setItem("serviceCounter-doctorId", doctorId.value);
    };

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();

      const allDoctor: Doctor = new Doctor();
      allDoctor.id = "all";
      allDoctor.fullName = t("gen.all");
      doctors.value = [allDoctor, ...(await doctorModel.getDocuments())];

      const sessionStartDate: string | null = sessionStorage.getItem("serviceCounter-startDate");
      if (sessionStartDate !== null) {
        period.value[0] = new Date(Number(sessionStartDate));
        period.value[0].setHours(0, 0, 0, 0);
      }

      const sessionEndDate: string | null = sessionStorage.getItem("serviceCounter-endDate");
      if (sessionEndDate !== null) {
        period.value[1] = new Date(Number(sessionEndDate));
        period.value[1].setHours(0, 0, 0, 0);
      }

      period.value = [...period.value];

      const sessionCompaniesIds: string | null = sessionStorage.getItem("serviceCounter-companiesIds");
      if (sessionCompaniesIds !== null) companiesIds.value = sessionCompaniesIds.split(",");

      const sessionDoctorId: string | null = sessionStorage.getItem("serviceCounter-doctorId");
      if (sessionDoctorId !== null) doctorId.value = sessionDoctorId;
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companiesIds.value === undefined || companiesIds.value.length === 0) throw new Error("statServiceCounter.companiesIdsUndefined");
      if (doctorId.value === undefined) throw new Error("statServiceCounter.doctorIdUndefined");
      if (period.value.length !== 2 || period.value[0] === undefined || period.value[1] === undefined) {
        throw new Error("statServiceCounter.periodUndefined");
      }
      if (compareAsc(period.value[0], period.value[1]) > 0) throw new Error("statServiceCounter.periodInvalid");

      const serviceCounts: ServiceCount[] = await serviceCounterModel.countServices(
        period.value[0],
        period.value[1],
        companiesIds.value,
        doctorId.value
      );

      results.value = serviceCounts.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      results.value = results.value.sort((a, b) => {
        return a.sort - b.sort;
      });

      showResults.value = true;
    };

    const printPaper = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const serviceCounterPaper: ServiceCounterPaper = new ServiceCounterPaper(
            companiesIds.value,
            doctorId.value as string,
            period.value[0],
            period.value[1],
            results.value,
            d,
            n,
            t
          );
          serviceCounterPaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companiesIds,
      d,
      dataChanged,
      doctorId,
      doctors,
      handleKeydown,
      handleSubmit,
      n,
      period,
      printPaper,
      results,
      showResults,
      t,
    };
  },
});
