export const it = {
  companyCostList: {
    companyCostList: "Listino costi",
    companyCostListOf: "Listino costi di {parent}",
    companyCostLists: "Listini costi",
    companyCostListsOf: "Listini costi di {parent}",
    costList: "Listino costi",
    from: "Inizio validità",
    fromError: "Selezionare una data di inizio valida",
    to: "Fine validità",
    toError: "Selezionare una data di fine valida",
    unlimited: "Illimitata",
  },
};
