import { Query, query, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const addReadConditionToQuery = <T extends FirestoreDocument>(model: FirestoreModel<T>, initialQuery: Query<T>): Query<T> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];
    if (user === undefined) throw new Error("User is undefined");

    // user can read all documents
    if (user.canReadAll(model.roleModule) === true) return initialQuery;
    // user can read only documents created by him
    if (user.canReadOwned(model.roleModule) === true) return query(initialQuery, where("createdBy", "==", user.id));
    // user can read only documents with custom condition
    if (user.canReadCustom(model.roleModule) === true) return initialQuery;

    throw new Error("User has no read permission");
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.addReadConditionToQuery", { model, initialQuery }, error);
    return initialQuery;
  }
};
