import { compareAsc } from "date-fns";

import { LinkedContract } from "@/features/modules/contract/objects/LinkedContract";

import { ArrayByKeyField } from "@/core/fields";

export class ActiveContractsRecord {
  public record: Record<string, Record<string, LinkedContract>> = {};

  public fromFirestore(value: unknown): void {
    this.record = {};
    if (value === null) return;

    for (const firmId of Object.keys(value as Record<string, unknown>)) {
      this.record[firmId] = ArrayByKeyField.fromFirestore<LinkedContract>(
        (value as Record<string, unknown>)[firmId],
        (innerValue) => new LinkedContract(innerValue)
      );
    }
  }

  public toFirestore(): Record<string, unknown> {
    const activeContracts: Record<string, Record<string, Record<string, unknown>>> = {};
    for (const firmId of Object.keys(this.record)) {
      activeContracts[firmId] = ArrayByKeyField.toFirestore<LinkedContract>(this.record[firmId], (innerValue) => innerValue.toFirestore());
    }

    return activeContracts;
  }

  public fromOfflineCache(value: unknown): void {
    this.record = {};
    if (value === null) return;

    for (const firmId of Object.keys(value as Record<string, unknown>)) {
      this.record[firmId] = ArrayByKeyField.fromOfflineCache<LinkedContract>((value as Record<string, unknown>)[firmId], (innerValue) => {
        const linkedContract: LinkedContract = new LinkedContract();
        return linkedContract.fromOfflineCache(innerValue);
      });
    }
  }

  public toOfflineCache(): Record<string, unknown> {
    const activeContracts: Record<string, Record<string, Record<string, unknown>>> = {};
    for (const firmId of Object.keys(this.record)) {
      activeContracts[firmId] = ArrayByKeyField.toOfflineCache<LinkedContract>(this.record[firmId], (innerValue) => innerValue.toOfflineCache());
    }

    return activeContracts;
  }

  public getActiveContractsByFirm(firmId: string): LinkedContract[] {
    if (!(firmId in this.record)) return [];
    return Object.values(this.record[firmId]).sort((a, b) => (a.to === undefined || (b.to !== undefined && compareAsc(a.to, b.to) > 0) ? 1 : -1));
  }

  public getRecord(): Record<string, Record<string, LinkedContract>> {
    return this.record;
  }
}
