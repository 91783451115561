import { RouteRecordRaw } from "vue-router";

export const firmRoutes: RouteRecordRaw[] = [
  {
    path: "/firms/:id/edit",
    name: "FirmEdit",
    component: () => import("../views/FirmEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "firm",
      requiredRight: "viewUI",
    },
  },
];
