
import { ComputedRef, computed, defineComponent } from "vue";

import { InputNumberInputEvent } from "primevue/inputnumber";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Locale } from "@/core/modules/locale/objects/Locale";
import { localeModel } from "@/core/modules/locale/models/LocaleModel";

export default defineComponent({
  name: "KoruPercentField",
  props: {
    class: { type: String },
    digits: { type: Number, default: 2 },
    model: { type: Number, default: 0 },
  },
  emits: ["update:model"],
  setup(props, { emit }) {
    const classesRef: ComputedRef<string> = computed(() => {
      const classes = "flex";
      return props.class != undefined ? `${classes} ${props.class}` : classes;
    });

    const locale: ComputedRef<Locale> = computed(() => localeModel.getSelectedLocale());

    const updateModel = (event: InputNumberInputEvent) => {
      emit("update:model", event.value);
    };

    return {
      AppHelpers,
      classesRef,
      locale,
      updateModel,
    };
  },
});
