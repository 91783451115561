import { format } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { websocketClient } from "@/features/modules/assist/objects/WebsocketClient";

export const executeSpiroTest = async (examination: Examination): Promise<void> => {
  try {
    let employeeJson: Record<string, string> | undefined = undefined;
    if (examination.employee !== undefined) {
      const employee: Employee = await employeeModel.getDocument(examination.employee.id);
      employeeJson = {};
      employeeJson.id = examination.employee.id;
      employeeJson.lastName = String(examination.employee.lastName);
      employeeJson.firstName = String(examination.employee.firstName);
      employeeJson.birthDate = employee.birthDate !== undefined ? format(employee.birthDate, "yyyy-MM-dd") : "";
      employeeJson.duty = examination.dutyName ?? "";
      employeeJson.sex = examination.employee.sex === "F" ? "0" : "1";
      employeeJson.height = examination.physicalExam.height !== undefined ? String(examination.physicalExam.height) : "";
      employeeJson.weight = examination.physicalExam.weight !== undefined ? String(examination.physicalExam.weight) : "";
    }
    websocketClient.sendRequest("executeSpiroTest", { examinationId: examination.id, employee: employeeJson });
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.executeSpiroTest", { examination }, error);
  }
};
