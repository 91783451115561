import { RouteRecordRaw } from "vue-router";

import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { EmployeeDocHelpers } from "../helpers/EmployeeDocHelpers";
import { employeeDocModel } from "../models/EmployeeDocModel";

export const employeeDocRoutes: RouteRecordRaw[] = [
  {
    path: "/employees/:sourceId/docs/:docId/edit",
    name: "EmployeeDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: EmployeeDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Employee,
      sourceModel: employeeDocModel,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeDoc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/:sourceId/docs/:docId",
    name: "EmployeeDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      filesPath: EmployeeDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Employee,
      sourceId: route.params.sourceId,
      sourceModel: employeeDocModel,
      sourcePath: "employees",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeDoc",
      requiredRight: "viewUI",
    },
  },
];
