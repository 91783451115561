import { HttpsCallable, httpsCallable, HttpsCallableResult } from "firebase/functions";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

export class CloudHelpers {
  /**
   * run create user function
   * @param functionName the function name
   * @param snapshotId the snapshot id
   * @returns the function boolean result
   */
  public static async runCreateUserFunction(functionName: string, snapshotId: string): Promise<boolean> {
    try {
      const createUserFunction: HttpsCallable = httpsCallable(firebase.functions, functionName);
      const functionResult: HttpsCallableResult = await createUserFunction({ snapshotId: snapshotId });
      const response: { result: string } = functionResult.data as { result: string };

      if (response === undefined || response.result === undefined) return Promise.resolve(false);
      if (response.result === "error") return Promise.resolve(false);
      if (response.result !== "ok") return Promise.resolve(false);

      return Promise.resolve(true);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CloudHelpers.runCreateUserFunction", { functionName, snapshotId }, error);
      return false;
    }
  }

  /**
   * run a generic function
   * @param functionName the function name
   * @param data the data to pass
   * @returns the result of the function
   */
  public static async runGenericFunction(functionName: string, data: Record<string, unknown>): Promise<Record<string, unknown> | undefined> {
    try {
      const genericFunction: HttpsCallable = httpsCallable(firebase.functions, functionName);
      const functionResult: HttpsCallableResult = await genericFunction(data);
      const response: Record<string, unknown> = functionResult.data as Record<string, unknown>;

      if (response === undefined || response.result === undefined) return Promise.resolve(undefined);

      return Promise.resolve(response.result as Record<string, unknown>);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CloudHelpers.runCreateUserFunction", { functionName, data }, error);
      return undefined;
    }
  }

  /**
   * run a validator function
   * @param functionName the function name
   * @param value the value to validate
   * @param snapshotId the snapshot id
   * @returns the result of the validation
   */
  public static async runValidatorFunction(functionName: string, value: unknown, snapshotId: string): Promise<boolean> {
    try {
      const validatorFunction: HttpsCallable = httpsCallable(firebase.functions, functionName);
      const functionResult: HttpsCallableResult = await validatorFunction({ value: value, snapshotId: snapshotId });
      const response: { result: string; where?: string } = functionResult.data as { result: string; where?: string };

      if (response === undefined || response.result === undefined) return Promise.resolve(false);
      if (response.result === "error") return Promise.resolve(false);
      if (response.result !== "ok") return Promise.resolve(false);

      return Promise.resolve(true);
    } catch (error: unknown) {
      appFaultModel.catchAppError("CloudHelpers.runValidatorFunction", { functionName, value, snapshotId }, error);
      return false;
    }
  }
}
