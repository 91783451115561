import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getExaminationsByEmployeeAndCompanyAndFirm = async (employeeId: string, companyId: string): Promise<Examination[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (examinationModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      return sortDocuments(
        documents.filter(
          (examination) => examination.firm?.id === firmId && examination.employee?.id === employeeId && examination.company?.id === companyId
        )
      );
    }

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(companyId) === false)
        throw new Error(`User doesn't have rights to read company #${companyId}`);
    }

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("employee.id", "==", employeeId),
      where("company.id", "==", companyId)
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsByEmployeeAndCompanyAndFirm", { employeeId, companyId }, error);
    return [];
  }
};

function sortDocuments(documents: Examination[]): Examination[] {
  const sorter: FirestoreSorter<Examination> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "date");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
