import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { TestType } from "../objects/TestType";
import { testTypeOfflineModel } from "./TestTypeOfflineModel";

import { getTestTypesByTestTypeCategory, getTestTypesForExternalTest } from "./methods";

export class TestTypeModel extends FirestoreModel<TestType> {
  public constructor() {
    super(() => new TestType(), "testTypes", LockPolicy.DiscardUnsyncedChanges, "testType");
    this.beforeDeleteFunction = "featuresTestTypeBeforeDelete";
    this.offlineModuleModel = testTypeOfflineModel;
  }

  public async getDocuments(): Promise<TestType[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(testTypeId: string): Promise<TestType> {
    return super.getDocument(testTypeId);
  }

  public async createDocument(testType: TestType): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    testType.order = documentCounter + 1;

    return super.createDocument(testType);
  }

  public async updateDocument(testType: TestType): Promise<void> {
    return super.updateDocument(testType);
  }

  public async deleteDocument(testType: TestType): Promise<boolean> {
    return super.deleteDocument(testType);
  }

  public async getTestTypesByTestTypeCategory(testTypeCategoryId: string): Promise<TestType[]> {
    return getTestTypesByTestTypeCategory(testTypeCategoryId);
  }

  public async getTestTypesForExternalTest(): Promise<TestType[]> {
    return getTestTypesForExternalTest();
  }
}

export const testTypeModel: TestTypeModel = new TestTypeModel();
