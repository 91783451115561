export class NullableBooleanField {
  public static fromFirestore(value: unknown): boolean | undefined {
    if (value === null || value === undefined) return undefined;

    return Boolean(value);
  }

  public static toFirestore(value: boolean | undefined): boolean | null {
    if (value === null || value === undefined) return null;

    return Boolean(value);
  }

  public static fromOfflineCache(value: unknown): boolean | undefined {
    return NullableBooleanField.fromFirestore(value);
  }

  public static toOfflineCache(value: boolean | undefined): boolean | null {
    return NullableBooleanField.toFirestore(value);
  }
}
