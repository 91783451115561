import { getDoc, setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Employee } from "../objects/Employee";
import { employeeModel } from "./EmployeeModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class EmployeeOfflineModel extends OfflineModuleModel<Employee> {
  public constructor() {
    super(() => new Employee(), "employees", "employee.employees", "employee");
    this.canCreate = true;
    this.canUpdate = true;
  }

  public async downloadCache(): Promise<Employee[]> {
    try {
      if (companyModel.offlineModuleModel === undefined) return [];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let employees: Employee[] = [];
      for (const company of companies) {
        employees = [...employees, ...(await employeeModel.getEmployeesByCompany(company.id))];
      }

      return employees;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeOfflineModel.downloadCache", {}, error);
      return [];
    }
  }

  public async createDocumentCallback(document: Employee): Promise<boolean> {
    try {
      const doc = await getDoc(employeeModel.getDocumentReference(document.id));
      if (doc.exists() === true) throw new Error("id duplicated");

      document.setSearchKeys();
      document.setTimestampFields("create");

      await setDoc(employeeModel.getDocumentReference(document.id).withConverter(employeeModel.firestoreConverter), document);

      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeOfflineModel.createDocumentCallback", { document }, error);
      return false;
    }
  }

  public async updateDocumentCallback(document: Employee): Promise<boolean> {
    try {
      const oldDocument: Employee = await employeeModel.getDocument(document.id);
      if (document.hasChangedFrom(oldDocument)) throw new Error("sync");

      await employeeModel.updateDocument(document);
      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeOfflineModel.updateDocumentCallback", { document }, error);
      return false;
    }
  }
}

export const employeeOfflineModel: EmployeeOfflineModel = new EmployeeOfflineModel();
