import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class EmployeeDocHelpers {
  public static getFolderPath(employeeId: string | undefined): string {
    try {
      if (employeeId === undefined) throw new Error("employeeId is undefined");
      return `employees/${employeeId}/docs/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeDocHelpers.getFolderPath", { employeeId }, error);
      throw error;
    }
  }
}
