
import { defineComponent, onMounted, Ref, ref } from "vue";
import { compareAsc } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { EmployeeLastExamination } from "../objects/EmployeeLastExamination";
import { employeeLastExaminationModel } from "../models/EmployeeLastExaminationModel";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "EmployeeLastExaminationView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<EmployeeLastExamination[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId: Ref<string | undefined> = ref(undefined);

    const companies: Ref<Company[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value === undefined) throw new Error("statEmployeeLastExamination.companyIdUndefined");

      results.value = await employeeLastExaminationModel.searchEmployeesByCompany(companyId.value);

      showResults.value = true;
    };

    const dataChanged = async () => {
      showResults.value = false;
      results.value = [];
    };

    onMounted(() => handleLoad());

    return {
      companies,
      companyId,
      compareAsc,
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      n,
      results,
      showResults,
      t,
    };
  },
});
