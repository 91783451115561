import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Broker } from "@/features/modules/broker/objects/Broker";
import { brokerModel } from "../BrokerModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";

export const getActiveBrokers = async (): Promise<Broker[]> => {
  try {
    const snapshotQuery: Query<Broker> = query(
      brokerModel.getPathReference().withConverter(brokerModel.firestoreConverter),
      where("archived", "==", false)
    );

    const snapshot: QuerySnapshot<Broker> = await getDocs(brokerModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("BrokerModel.getActiveBrokers", {}, error);
    return [];
  }
};

function sortDocuments(documents: Broker[]): Broker[] {
  const sorter: FirestoreSorter<Broker> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
