import { Auth, browserSessionPersistence, getAuth, setPersistence } from "firebase/auth";
import { FirebaseApp, initializeApp } from "firebase/app";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Firestore, getFirestore } from "firebase/firestore";
import { Functions, getFunctions } from "firebase/functions";

import { config } from "@/core/modules/config/objects/Config";
import { FirebaseConfigInterface } from "../interfaces/FirebaseConfig.interface";

export class Firebase {
  private firebaseConfig: FirebaseConfigInterface;

  private authInstance: Auth | undefined;
  private firestoreInstance: Firestore | undefined;
  private functionsInstance: Functions | undefined;
  private storageInstance: FirebaseStorage | undefined;

  /**
   * create a new Firebase instance
   */
  public constructor() {
    this.firebaseConfig = {
      apiKey: config.firebase.apiKey,
      appId: config.firebase.appId,
      authDomain: config.firebase.authDomain,
      clientEmail: config.firebase.clientEmail,
      databaseURL: config.firebase.databaseURL,
      messagingSenderId: config.firebase.messagingSenderId,
      projectId: config.firebase.projectId,
      region: config.firebase.region,
      storageBucket: config.firebase.storageBucket,
    };
  }

  /**
   * initialize firebase connection
   */
  public async initialize(): Promise<void> {
    const firebaseApp: FirebaseApp = initializeApp(this.firebaseConfig);
    this.authInstance = getAuth();
    this.firestoreInstance = getFirestore(firebaseApp);
    this.functionsInstance = getFunctions(firebaseApp, config.firebase.region);
    this.storageInstance = getStorage(firebaseApp);

    setPersistence(this.authInstance, browserSessionPersistence);
  }

  public get auth(): Auth {
    if (this.authInstance === undefined) throw new Error("Auth not initialized");
    return this.authInstance;
  }

  public get firestore(): Firestore {
    if (this.firestoreInstance === undefined) throw new Error("Firestore not initialized");
    return this.firestoreInstance;
  }

  public get functions(): Functions {
    if (this.functionsInstance === undefined) throw new Error("Functions not initialized");
    return this.functionsInstance;
  }

  public get storage(): FirebaseStorage {
    if (this.storageInstance === undefined) throw new Error("Storage not initialized");
    return this.storageInstance;
  }
}

export const firebase: Firebase = new Firebase();
