import type { DateTimeFormat, LocaleMessage, NumberFormat } from "@intlify/core-base";
import { createI18n, I18n, VueMessageType } from "vue-i18n";

import { config } from "@/core/modules/config/objects/Config";
import { LocaleModule } from "../objects/LocaleModule";
import { localeModules } from "../objects/localeModules";

import { coreLabels } from "@/core/locales/labels";
import { featuresLabels } from "@/features/locales/labels";

import { allCoreDateTimeFormats, allCoreNumberFormats } from "@/core/locales/formats";
import { allFeaturesDateTimeFormats, allFeaturesNumberFormats } from "@/features/locales/formats";

export class LocaleHelpers {
  public static initLocales(): I18n {
    const labels: { [x: string]: LocaleMessage<VueMessageType> } = {};
    const dateTimeFormats: { [x: string]: DateTimeFormat } = {};
    const numberFormats: { [x: string]: NumberFormat } = {};

    for (const locale of config.app.locale.locales) {
      labels[locale.name] = { ...coreLabels.messages[locale.name], ...featuresLabels.messages[locale.name] };
      labels[locale.name].buttons = {
        ...(coreLabels.messages[locale.name].buttons as Record<string, unknown>),
        ...(featuresLabels.messages[locale.name].buttons as Record<string, unknown>),
      };
      labels[locale.name].gen = {
        ...(coreLabels.messages[locale.name].gen as Record<string, unknown>),
        ...(featuresLabels.messages[locale.name].gen as Record<string, unknown>),
      };
      labels[locale.name].navigation = {
        ...(coreLabels.messages[locale.name].navigation as Record<string, unknown>),
        ...(featuresLabels.messages[locale.name].navigation as Record<string, unknown>),
      };
      labels[locale.name].toast = {
        error: {
          ...((coreLabels.messages[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
          ...((featuresLabels.messages[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
        },
        success: {
          ...((coreLabels.messages[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
          ...((featuresLabels.messages[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
        },
        warning: {
          ...((coreLabels.messages[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
          ...((featuresLabels.messages[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
        },
      };

      dateTimeFormats[locale.name] = { ...allCoreDateTimeFormats, ...allFeaturesDateTimeFormats } as DateTimeFormat;

      numberFormats[locale.name] = { ...allCoreNumberFormats, ...allFeaturesNumberFormats };
    }

    for (const localeModule of localeModules) {
      for (const locale of config.app.locale.locales) {
        labels[locale.name][localeModule.name] = localeModule.messages[locale.name][localeModule.name];

        const sections = ["buttons", "navigation"];
        for (const section of sections) {
          if (Object.prototype.hasOwnProperty.call(localeModule.messages[locale.name], section)) {
            labels[locale.name][section] = {
              ...(labels[locale.name][section] as Record<string, unknown>),
              ...(localeModule.messages[locale.name][section] as Record<string, unknown>),
            };
          }
        }

        if (Object.prototype.hasOwnProperty.call(localeModule.messages[locale.name], "toast")) {
          labels[locale.name].toast = {
            error: {
              ...((labels[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
              ...((localeModule.messages[locale.name].toast as Record<string, unknown>).error as Record<string, unknown>),
            },
            success: {
              ...((labels[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
              ...((localeModule.messages[locale.name].toast as Record<string, unknown>).success as Record<string, unknown>),
            },
            warning: {
              ...((labels[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
              ...((localeModule.messages[locale.name].toast as Record<string, unknown>).warning as Record<string, unknown>),
            },
          };
        }
      }
    }
    return createI18n({
      legacy: false,
      locale: config.app.locale.default,
      fallbackLocale: config.app.locale.fallback,
      messages: labels,
      datetimeFormats: dateTimeFormats,
      numberFormats: numberFormats,
    });
  }

  public static registerModule(module: LocaleModule): void {
    if (localeModules.includes(module) === false) {
      localeModules.push(module);
    }
  }
}
