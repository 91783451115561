export const it = {
  doctor: {
    address: "Indirizzo",
    birthDate: "Data di nascita",
    birthPlace: "Luogo di nascita",
    doctor: "Medico",
    doctorDocs: "Documenti",
    doctors: "Medici",
    email: "Indirizzo e-mail",
    emailError: "Indirizzo e-mail non valido o già presente",
    firms: "Ditte associate",
    firstName: "Nome",
    firstNameError: "Inserire un nome valido",
    fiscalCode: "Codice fiscale",
    fullName: "Nome",
    info: "Informazioni",
    lastName: "Cognome",
    lastNameError: "Inserire un cognome valido",
    name: "Nome",
    notes: "Note",
    phone: "Telefono",
    province: "Provincia",
    sex: "Sesso",
    signatureImage: "Immagine firma",
    town: "Comune",
    userId: "Utente",
    zipCode: "CAP",
  },
  navigation: {
    doctor: "Medici",
  },
};
