import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FeaturesHelpers } from "@/features/modules/helpers/FeaturesHelpers";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getNextServiceCodeByDateAndFirm = async (date: Date): Promise<number> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();
    const currentYear: number = date.getFullYear() - 2000;

    if (offlineModel.getOfflineState() === "offline") throw new Error("offline mode not supported");

    return FeaturesHelpers.runNextCodeFunction("featuresServiceNextCode", currentYear, firmId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ServiceModel.getNextServiceCodeByDateAndFirm", { date }, error);
    return 0;
  }
};
