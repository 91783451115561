import { ExternalTest } from "./ExternalTest";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { TestForm } from "@/features/modules/testForm/objects/TestForm";

import { DateField, NumberWithDefaultField, ObjectField, StringField } from "@/core/fields";

import { AudioTestForm } from "@/features/modules/testForm/objects/AudioTestForm";
import { GenTestForm } from "@/features/modules/testForm/objects/GenTestForm";
import { SpiroTestForm } from "@/features/modules/testForm/objects/SpiroTestForm";
import { VisioTestForm } from "@/features/modules/testForm/objects/VisioTestForm";

export class LinkedExternalTest extends LinkedFirestoreDocument {
  public codeDisplay: string | undefined = undefined;
  public testType: LinkedTestType | undefined = undefined;
  public date: Date | undefined = undefined;
  public company: LinkedCompany | undefined = undefined;
  public file: StorageFile | undefined = undefined;
  public testForm: TestForm | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromExternalTest(externalTest: ExternalTest): LinkedExternalTest {
    const linkedExternalTest: LinkedExternalTest = new LinkedExternalTest();
    linkedExternalTest.id = externalTest.id;
    linkedExternalTest.codeDisplay = externalTest.codeDisplay;
    linkedExternalTest.testType = externalTest.testType;
    linkedExternalTest.date = externalTest.date;
    linkedExternalTest.company = externalTest.company;
    linkedExternalTest.file = externalTest.file;
    linkedExternalTest.testForm = externalTest.testForm;

    return linkedExternalTest;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedExternalTest {
    super.fromFirestore(data);

    this.codeDisplay = StringField.fromFirestore(data.codeDisplay);
    this.testType = ObjectField.fromFirestore<LinkedTestType>(data.testType, (value) => new LinkedTestType(value));
    this.date = DateField.fromFirestore(data.date);
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.file = ObjectField.fromFirestore<StorageFile>(data.file, (value) => new StorageFile(value));
    this.testFormFromFirestore(data);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.codeDisplay = StringField.toFirestore(this.codeDisplay);
    firestoreData.testType = ObjectField.toFirestore<LinkedTestType>(this.testType, (value) => value.toFirestore());
    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.file = ObjectField.toFirestore<StorageFile>(this.file, (value) => value.toFirestore());
    firestoreData.testForm = this.testFormToFirestore();
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public createTestForm(): void {
    if (this.testType?.formName === undefined) return;
    if (this.testType?.formName === "audioTestForm") {
      this.testForm = new AudioTestForm();
    } else if (this.testType?.formName === "genTestForm") {
      this.testForm = new GenTestForm();
    } else if (this.testType?.formName === "spiroTestForm") {
      this.testForm = new SpiroTestForm();
    } else if (this.testType?.formName === "visioTestForm") {
      this.testForm = new VisioTestForm();
    }
  }

  public testFormFromFirestore(data: Record<string, unknown>): void {
    if (this.testType?.formName === undefined) {
      this.testForm = undefined;
      return;
    }
    if (this.testType?.formName === "audioTestForm") {
      this.testForm = ObjectField.fromFirestore<AudioTestForm>(data.testForm, (value) => new AudioTestForm(value));
    } else if (this.testType?.formName === "genTestForm") {
      this.testForm = ObjectField.fromFirestore<GenTestForm>(data.testForm, (value) => new GenTestForm(value));
    } else if (this.testType?.formName === "spiroTestForm") {
      this.testForm = ObjectField.fromFirestore<SpiroTestForm>(data.testForm, (value) => new SpiroTestForm(value));
    } else if (this.testType?.formName === "visioTestForm") {
      this.testForm = ObjectField.fromFirestore<VisioTestForm>(data.testForm, (value) => new VisioTestForm(value));
    }
  }

  public testFormToFirestore(): Record<string, unknown> | null {
    if (this.testType?.formName === undefined) return null;
    if (this.testType?.formName === "audioTestForm") {
      return ObjectField.toFirestore<AudioTestForm>(this.testForm as AudioTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "genTestForm") {
      return ObjectField.toFirestore<GenTestForm>(this.testForm as GenTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "spiroTestForm") {
      return ObjectField.toFirestore<SpiroTestForm>(this.testForm as SpiroTestForm, (value) => value.toFirestore());
    } else if (this.testType?.formName === "visioTestForm") {
      return ObjectField.toFirestore<VisioTestForm>(this.testForm as VisioTestForm, (value) => value.toFirestore());
    }
    return null;
  }
}
