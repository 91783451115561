
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { NavigationItem } from "@/core/modules/navigation/interfaces/NavigationItem";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "submenu",
  emits: ["root-menuitem-click", "menuitem-click"],
  props: {
    items: Array<NavigationItem>,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
    root: {
      type: Boolean,
      default: false,
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useLocale();
    const store = useStore();
    let eventBusId: string | undefined = undefined;

    const user: User = store.getters[storeTypes.getters.getUser];

    const activeIndex: Ref<number | undefined> = ref(undefined);

    const hasVisibleChildren = (item: NavigationItem): boolean => {
      if (item.items == undefined) {
        return false;
      }
      for (let itemsIndex = 0; itemsIndex < item.items.length; itemsIndex++) {
        const child: NavigationItem = item.items[itemsIndex];
        if (isChildVisible(child)) {
          return true;
        }
      }
      return false;
    };

    const isChildVisible = (item: NavigationItem): boolean => {
      if (item.roleModules === undefined) return false;

      return user.hasAnyViewUIRight(item.roleModules);
    };

    const hasZeroChildren = (item: NavigationItem): boolean => {
      return item.items?.length === 0 && (item.roleModules === undefined || user.hasAnyViewUIRight(item.roleModules));
    };

    const isLastItem = (index: number): boolean => {
      return index == Object.keys(props.items as NavigationItem[]).length - 1;
    };

    onMounted(() => {
      eventBusId = eventBus.on("resetActiveIndex", async () => {
        if (props.layoutMode === "slim") activeIndex.value = undefined;
      });
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      activeIndex,
      hasVisibleChildren,
      hasZeroChildren,
      isChildVisible,
      isLastItem,
      t,
    };
  },
  methods: {
    onMenuItemClick(event: Event, item: NavigationItem, index: number): void {
      if (item.to) {
        event.preventDefault();
        Navigation.navigateTo(item.to);
      }
      if (item.items) {
        event.preventDefault();
      }
      if (this.root) {
        this.$emit("root-menuitem-click", {
          originalEvent: event,
        });
      }
      if (item.items) {
        this.activeIndex = index === this.activeIndex ? undefined : index;
      }
      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
    onMenuItemMouseEnter(index: number): void {
      if (this.root && this.menuActive && this.layoutMode === "slim" && !this.isMobile()) {
        this.activeIndex = index;
      }
    },
    isMobile(): boolean {
      return window.innerWidth <= 991;
    },
    isSlim(): boolean {
      return this.layoutMode === "slim";
    },
  },
});
