export class HistoryItem {
  public date: Date;
  public icon: string;
  public subtitle: string;
  public title: string;
  public url: string;

  public constructor(title: string, subtitle: string, icon: string, url: string, date?: string) {
    this.title = title;
    this.subtitle = subtitle;
    this.icon = icon;
    this.url = url;
    this.date = date !== undefined ? new Date(date) : new Date();
  }
}
