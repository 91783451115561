import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "../DoctorModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getDoctorsByFirm = async (): Promise<Doctor[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (doctorModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Doctor[] = (await offlineModel.getCacheByCollection(doctorModel.offlineModuleModel)) as Doctor[];
      return sortDocuments(documents.filter((doctor) => firmId in doctor.firms));
    }

    const firmId: string = firmModel.getSelectedFirmId();

    let snapshotQuery: Query<Doctor> = query(
      doctorModel.getPathReference().withConverter(doctorModel.firestoreConverter),
      where(`firms.${firmId}.id`, "==", firmId)
    );

    if (user.role?.name === "Medico") {
      snapshotQuery = query(snapshotQuery, where("userId", "==", user.id));
    }

    const snapshot: QuerySnapshot<Doctor> = await getDocs(doctorModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorModel.getDoctorsByFirm", {}, error);
    return [];
  }
};

function sortDocuments(documents: Doctor[]): Doctor[] {
  const sorter: FirestoreSorter<Doctor> = new FirestoreSorter(documents);
  sorter.addSortCriteria("fullName", "asc", "string");
  return sorter.sort();
}
