import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";
import { TestTypeCategory } from "./TestTypeCategory";

import { StringField } from "@/core/fields";

export class LinkedTestTypeCategory extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromTestTypeCategory(testTypeCategory: TestTypeCategory): LinkedTestTypeCategory {
    const linkedTestTypeCategory: LinkedTestTypeCategory = new LinkedTestTypeCategory();
    linkedTestTypeCategory.id = testTypeCategory.id;
    linkedTestTypeCategory.name = testTypeCategory.name;

    return linkedTestTypeCategory;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedTestTypeCategory {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedTestTypeCategory {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);

    return firestoreData;
  }
}
