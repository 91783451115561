
import { defineComponent, Ref, ref } from "vue";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "SearchComponent",
  props: {
    searchActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["search-click", "search-hide"],
  setup(props, { emit }) {
    const { t } = useLocale();
    const store = useStore();

    const searchInput: Ref<string> = ref("");

    const onEnter = () => {
      document.getElementById("search-input")?.focus();
    };

    const onSearchContainerClick = (event: MouseEvent) => {
      emit("search-click", event);
    };

    const onInputKeydown = async (event: KeyboardEvent) => {
      const key: string = event.key;

      if (key === "Tab" || key === "Enter") {
        store.commit(storeTypes.mutations.loadingStart);
        store.commit(storeTypes.mutations.editingStop);
        await Navigation.navigateTo("/");
        await Navigation.navigateTo(`/search/${DataHelpers.encodeStringForUrl(searchInput.value)}`);
        close();
      } else if (key === "Escape") {
        close();
      }
    };

    const close = () => {
      emit("search-hide");
      searchInput.value = "";
    };

    return {
      onEnter,
      onInputKeydown,
      onSearchContainerClick,
      searchInput,
      t,
    };
  },
});
