import { setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Notification } from "@/core/modules/notification/objects/Notification";
import { notificationModel } from "../NotificationModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const markNotificationAsDeleted = async (notificationId: string): Promise<void> => {
  try {
    const notification: Notification = await notificationModel.getDocument(notificationId);

    const user: User = store.getters[storeTypes.getters.getUser];

    const ownerIndex = notification.owners.indexOf(user.id);
    if (ownerIndex < 0) {
      throw new Error(`User #${user.id} not found in notification #${notificationId}`);
    }
    notification.owners.splice(ownerIndex, 1);

    const unreadIndex = notification.unread.indexOf(user.id);
    if (unreadIndex >= 0) notification.unread.splice(unreadIndex, 1);

    await setDoc(notificationModel.getDocumentReference(notification.id).withConverter(notificationModel.firestoreConverter), notification);
  } catch (error: unknown) {
    appFaultModel.catchAppError("NotificationModel.markNotificationAsDeleted", { notificationId }, error);
  }
};
