import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "../DoctorModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/UserModel";

export const isEmailUnique = async (email: string, doctorId = "empty"): Promise<boolean> => {
  try {
    if (email === undefined) return false;

    if (offlineModel.getOfflineState() === "offline") {
      if (doctorModel.offlineModuleModel === undefined) return false;

      const user: User = store.getters[storeTypes.getters.getUser];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const doctorDocuments: Doctor[] = (await offlineModel.getCacheByCollection(doctorModel.offlineModuleModel)) as Doctor[];
      const filteredDoctorDocuments: Doctor[] = doctorDocuments.filter((doctor) => doctor.email === email);
      const filteredDoctorDocumentsIds: string[] = filteredDoctorDocuments.map((doctor) => doctor.id);
      if (filteredDoctorDocuments.length > 1) return false;
      if (filteredDoctorDocuments.length === 1 && filteredDoctorDocumentsIds.includes(doctorId) === false) return false;

      if (userModel.offlineModuleModel === undefined) return true;
      const userDocuments: User[] = (await offlineModel.getCacheByCollection(userModel.offlineModuleModel)) as User[];
      const filteredUserDocuments: User[] = userDocuments.filter((user) => user.email === email);
      const filteredUserDocumentsIds: string[] = filteredUserDocuments.map((user) => user.id);

      if (filteredUserDocuments.length > 1) return false;
      if (filteredUserDocuments.length === 1 && filteredUserDocumentsIds.includes(doctorId) === false) return false;

      return true;
    }

    const doctorResult: boolean = await CloudHelpers.runValidatorFunction("featuresDoctorEmailValidator", email, doctorId);
    const userResult: boolean = await CloudHelpers.runValidatorFunction("coreUserEmailValidator", email, doctorId);

    return doctorResult && userResult;
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorModel.isEmailUnique", { email, doctorId }, error);
    return false;
  }
};
