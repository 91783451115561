
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";

import { eventBus } from "@/core/modules/eventBus/EventBus";

import KoruPdf from "./KoruPdf.vue";

export default defineComponent({
  name: "KoruPdfDialog",
  components: {
    KoruPdf,
  },
  setup() {
    let eventBusId: string | undefined = undefined;

    const isDialogVisible: Ref<boolean> = ref(false);

    const pdfUrl: Ref<string> = ref("");

    onMounted(() => {
      eventBusId = eventBus.on("showPdfDialog", async (url?: string): Promise<void> => {
        if (url === undefined) return Promise.resolve();

        pdfUrl.value = url;
        isDialogVisible.value = true;
      });
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      isDialogVisible,
      pdfUrl,
    };
  },
});
