import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { TestTypeCategory } from "../objects/TestTypeCategory";
import { testTypeCategoryOfflineModel } from "./TestTypeCategoryOfflineModel";
import { TestTypeCategoryWithTestTypes } from "../objects/TestTypeCategoryWithTestTypes";

import { getTestTypeCategoriesWithTestTypes, getTestTypeCategoriesWithTestTypesForExternalTest } from "./methods";

export class TestTypeCategoryModel extends FirestoreModel<TestTypeCategory> {
  public constructor() {
    super(() => new TestTypeCategory(), "testTypeCategories", LockPolicy.DiscardUnsyncedChanges, "testTypeCategory");
    this.beforeDeleteFunction = "featuresTestTypeCategoryBeforeDelete";
    this.offlineModuleModel = testTypeCategoryOfflineModel;
  }

  public async getDocuments(): Promise<TestTypeCategory[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(testTypeCategoryId: string): Promise<TestTypeCategory> {
    return super.getDocument(testTypeCategoryId);
  }

  public async createDocument(testTypeCategory: TestTypeCategory): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    testTypeCategory.order = documentCounter + 1;

    return super.createDocument(testTypeCategory);
  }

  public async updateDocument(testTypeCategory: TestTypeCategory): Promise<void> {
    return super.updateDocument(testTypeCategory);
  }

  public async deleteDocument(testTypeCategory: TestTypeCategory): Promise<boolean> {
    return super.deleteDocument(testTypeCategory);
  }

  public async getTestTypeCategoriesWithTestTypes(onlyTestTypesIds: string[] = []): Promise<TestTypeCategoryWithTestTypes[]> {
    return getTestTypeCategoriesWithTestTypes(onlyTestTypesIds);
  }

  public async getTestTypeCategoriesWithTestTypesForExternalTest(): Promise<TestTypeCategoryWithTestTypes[]> {
    return getTestTypeCategoriesWithTestTypesForExternalTest();
  }
}

export const testTypeCategoryModel: TestTypeCategoryModel = new TestTypeCategoryModel();
