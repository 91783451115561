import { SurveyForm } from "./SurveyForm";

import { StringArrayField, StringField } from "@/core/fields";

export class RachisExamSurveyForm extends SurveyForm {
  public question1: string | undefined = "Normale";
  public question2: string | undefined = "Normale";
  public question3: string | undefined = "No";
  public question4: string | undefined = "Normale";
  public question5: string | undefined = "No";
  public question6: string | undefined = "Assente";
  public question7: string | undefined = "Negativo";
  public question8: string | undefined = "Assente";
  public question9: string | undefined = "Negativo";
  public question10: string | undefined = "Assente";
  public question11: string | undefined = "Assente";
  public question12: string | undefined = "Normale";
  public question13: string[] = [];
  public question14: string[] = [];
  public question15: string[] = [];
  public question16: string[] = [];
  public question17: string[] = [];
  public question18: string[] = [];
  public question19: string[] = [];
  public question20: string[] = [];
  public question21: string[] = ["Corretta"];
  public question22: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): RachisExamSurveyForm {
    super.fromFirestore(data);

    this.question1 = StringField.fromFirestore(data.question1);
    this.question2 = StringField.fromFirestore(data.question2);
    this.question3 = StringField.fromFirestore(data.question3);
    this.question4 = StringField.fromFirestore(data.question4);
    this.question5 = StringField.fromFirestore(data.question5);
    this.question6 = StringField.fromFirestore(data.question6);
    this.question7 = StringField.fromFirestore(data.question7);
    this.question8 = StringField.fromFirestore(data.question8);
    this.question9 = StringField.fromFirestore(data.question9);
    this.question10 = StringField.fromFirestore(data.question10);
    this.question11 = StringField.fromFirestore(data.question11);
    this.question12 = StringField.fromFirestore(data.question12);
    this.question13 = StringArrayField.fromFirestore(data.question13);
    this.question14 = StringArrayField.fromFirestore(data.question14);
    this.question15 = StringArrayField.fromFirestore(data.question15);
    this.question16 = StringArrayField.fromFirestore(data.question16);
    this.question17 = StringArrayField.fromFirestore(data.question17);
    this.question18 = StringArrayField.fromFirestore(data.question18);
    this.question19 = StringArrayField.fromFirestore(data.question19);
    this.question20 = StringArrayField.fromFirestore(data.question20);
    this.question21 = StringArrayField.fromFirestore(data.question21);
    this.question22 = StringField.fromFirestore(data.question22);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = StringField.toFirestore(this.question1);
    firestoreData.question2 = StringField.toFirestore(this.question2);
    firestoreData.question3 = StringField.toFirestore(this.question3);
    firestoreData.question4 = StringField.toFirestore(this.question4);
    firestoreData.question5 = StringField.toFirestore(this.question5);
    firestoreData.question6 = StringField.toFirestore(this.question6);
    firestoreData.question7 = StringField.toFirestore(this.question7);
    firestoreData.question8 = StringField.toFirestore(this.question8);
    firestoreData.question9 = StringField.toFirestore(this.question9);
    firestoreData.question10 = StringField.toFirestore(this.question10);
    firestoreData.question11 = StringField.toFirestore(this.question11);
    firestoreData.question12 = StringField.toFirestore(this.question12);
    firestoreData.question13 = StringArrayField.toFirestore(this.question13);
    firestoreData.question14 = StringArrayField.toFirestore(this.question14);
    firestoreData.question15 = StringArrayField.toFirestore(this.question15);
    firestoreData.question16 = StringArrayField.toFirestore(this.question16);
    firestoreData.question17 = StringArrayField.toFirestore(this.question17);
    firestoreData.question18 = StringArrayField.toFirestore(this.question18);
    firestoreData.question19 = StringArrayField.toFirestore(this.question19);
    firestoreData.question20 = StringArrayField.toFirestore(this.question20);
    firestoreData.question21 = StringArrayField.toFirestore(this.question21);
    firestoreData.question22 = StringField.toFirestore(this.question22);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): RachisExamSurveyForm {
    super.fromOfflineCache(data);

    this.question1 = StringField.fromOfflineCache(data.question1);
    this.question2 = StringField.fromOfflineCache(data.question2);
    this.question3 = StringField.fromOfflineCache(data.question3);
    this.question4 = StringField.fromOfflineCache(data.question4);
    this.question5 = StringField.fromOfflineCache(data.question5);
    this.question6 = StringField.fromOfflineCache(data.question6);
    this.question7 = StringField.fromOfflineCache(data.question7);
    this.question8 = StringField.fromOfflineCache(data.question8);
    this.question9 = StringField.fromOfflineCache(data.question9);
    this.question10 = StringField.fromOfflineCache(data.question10);
    this.question11 = StringField.fromOfflineCache(data.question11);
    this.question12 = StringField.fromOfflineCache(data.question12);
    this.question13 = StringArrayField.fromOfflineCache(data.question13);
    this.question14 = StringArrayField.fromOfflineCache(data.question14);
    this.question15 = StringArrayField.fromOfflineCache(data.question15);
    this.question16 = StringArrayField.fromOfflineCache(data.question16);
    this.question17 = StringArrayField.fromOfflineCache(data.question17);
    this.question18 = StringArrayField.fromOfflineCache(data.question18);
    this.question19 = StringArrayField.fromOfflineCache(data.question19);
    this.question20 = StringArrayField.fromOfflineCache(data.question20);
    this.question21 = StringArrayField.fromOfflineCache(data.question21);
    this.question22 = StringField.fromOfflineCache(data.question22);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = StringField.toOfflineCache(this.question1);
    firestoreData.question2 = StringField.toOfflineCache(this.question2);
    firestoreData.question3 = StringField.toOfflineCache(this.question3);
    firestoreData.question4 = StringField.toOfflineCache(this.question4);
    firestoreData.question5 = StringField.toOfflineCache(this.question5);
    firestoreData.question6 = StringField.toOfflineCache(this.question6);
    firestoreData.question7 = StringField.toOfflineCache(this.question7);
    firestoreData.question8 = StringField.toOfflineCache(this.question8);
    firestoreData.question9 = StringField.toOfflineCache(this.question9);
    firestoreData.question10 = StringField.toOfflineCache(this.question10);
    firestoreData.question11 = StringField.toOfflineCache(this.question11);
    firestoreData.question12 = StringField.toOfflineCache(this.question12);
    firestoreData.question13 = StringArrayField.toOfflineCache(this.question13);
    firestoreData.question14 = StringArrayField.toOfflineCache(this.question14);
    firestoreData.question15 = StringArrayField.toOfflineCache(this.question15);
    firestoreData.question16 = StringArrayField.toOfflineCache(this.question16);
    firestoreData.question17 = StringArrayField.toOfflineCache(this.question17);
    firestoreData.question18 = StringArrayField.toOfflineCache(this.question18);
    firestoreData.question19 = StringArrayField.toOfflineCache(this.question19);
    firestoreData.question20 = StringArrayField.toOfflineCache(this.question20);
    firestoreData.question21 = StringArrayField.toOfflineCache(this.question21);
    firestoreData.question22 = StringField.toOfflineCache(this.question22);

    return firestoreData;
  }
}
