import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { companyDutyModel } from "../CompanyDutyModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getActiveCompanyDuties = async (parentId: string): Promise<CompanyDuty[]> => {
  try {
    if (parentId == undefined) throw new Error("parentId is undefined");

    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (companyDutyModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: CompanyDuty[] = (await offlineModel.getCacheByCollection(companyDutyModel.offlineModuleModel)) as CompanyDuty[];
      return sortDocuments(documents.filter((companyDuty) => companyDuty.archived === false));
    }

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(parentId) === false)
        throw new Error(`User doesn't have rights to read company #${parentId}`);
    }

    const snapshotQuery: Query<CompanyDuty> = query(
      companyDutyModel.getPathReference(parentId).withConverter(companyDutyModel.firestoreConverter),
      where("archived", "==", false)
    );

    const snapshot: QuerySnapshot<CompanyDuty> = await getDocs(companyDutyModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyDutyModel.getActiveCompanyDuties", { parentId }, error);
    return [];
  }
};

function sortDocuments(documents: CompanyDuty[]): CompanyDuty[] {
  const sorter: FirestoreSorter<CompanyDuty> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
