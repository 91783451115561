/* eslint-disable prettier/prettier */
export const it = {
  surveyForm: {
    answerError: "Selezionare una risposta",
    allergySurveyForm: {
      question1: "Asma",
      question2: "Rinite/oculorinite",
      question3: "Eczema topico",
      question4: "DAC",
      question5: "Altre patologie",
      question6: "Hobby/attività extralavorative",
      question7: "Asma",
      question8: "Rinite/oculorinite",
      question9: "Eczema topico",
      question10: "Angioedema",
      question11: "Shock anafilattico",
      question12: "Sensibilizzazioni",
      question13: "Asintomatiche",
      question14: "Allergie alimentari",
      question15: "Allergie farmaci",
      question16: "Allergie veleno",
      question17: "Imenotteri",
      question18: "DAC",
      question19: "DIC",
      question20: "Allergia a metalli",
      question21: "Altre patologie cutanee",
      question22: "Note",
      title1: "Anamnesi familiare",
      title2: "Anamnesi patologica remota",
    },
    auditSurveyForm: {
      points: "Punteggio",
      question1: "Con quale frequenza consuma bevande contenenti alcol?",
      question2: "Nei giorni in cui beve, quante bevande alcoliche consuma in media?",
      question3: "Con quale frequenza le capita di consumare 6 o più bevande in una unica occasione?",
      question4: "Con quale frequenza durante l'ultimo anno si è accorto di non riuscire a smettere di bere una volta che aveva inziato?",
      question5: "Con quale frequenza durante l'ultimo anno non è riuscito a fare ciò che normalmente ci si aspetta da lei a causa del bere?",
      question6: "Con quale frequenza durante l'ultimo anno ha avuto bisogno di bere di prima mattina per tirarsi su dopo una bevuta pesante?",
      question7: "Con quale frequenza durante l'ultimo anno ha avuto sensi di colpa o rimorso dopo aver bevuto?",
      question8: "Con quale frequenza durante l'ultimo anno non è riuscito a ricordare quello che era successo la sera precedente perché aveva bevuto?",
      question9: "Lei o qualcun altro è stato mai insultato/ferito come risultato del suo bere?",
      question10: "Un parente, un amico, un medico o altro operatore sanitario si sono occupati del suo bere o le hanno suggerito di smettere?",
    },
    auditShortSurveyForm: {
      additionalQuestions: "Esegui A.U.D.I.T. clinico",
      points: "Punteggio",
      question1A: "Con quale frequenza consuma bevande contenenti alcol?",
      question2A: "Quanti bicchieri standard di bevande alcoliche consuma in media al giorno?",
      question3A: "Con quale frequenza le è capitato di bere 6 o più bicchieri di bevande alcoliche in un'unica occasione?",
      question1B: "Ha avuto traumi cranici dopo i 18 anni?",
      question2B: "Ha avuto fratture dopo i 18 anni?",
      question3B: "Arrossamento delle congiuntive",
      question4B: "Abnorme vascolarizzazione cutanea",
      question5B: "Tremore delle mani",
      question6B: "Tremore della lingua",
      question7B: "Epatomegalia",
      question8B: "Livello di Gamma GT",
    },
    bronchitisSurveyForm: {
      question1: "Tossisce abitualmente quando si alza nella cattiva stagione?",
      question2: "Tossisce abitualmente di giorno o di notte nella cattiva stagione?",
      question3: "Tossisce in tal modo quasi tutti i giorni/notti per 3 mesi all’anno?",
      question3b: "A che età ha cominciato a tossire?",
      question3bHint: "Anni",
      question4: "Espettora abitualmente quando si alza nella cattiva stagione?",
      question5: "Espettora abitualmente di giorno o di notte nella cattiva stagione?",
      question6: "Espettora in tal modo quasi tutti i giorni/notti per 3 mesi all’anno?",
      question6b: "A che età ha incominciato ad espettorare?",
      question6bHint: "Anni",
      question6c: "Negli ultimi 3 anni, ha dovuto interrompere le sue normali attività per almeno 3 settimane a causa di un eccesso di tosse o di espettorazione?",
      question7: "Ha problemi a camminare per un'altra causa che non sia un'affezione cardiaca o polmonare?",
      question8: "Ha difficoltà di respirazione quando sale una rampa di scale a passo normale?",
      question9: "Ha difficoltà di respiro quando cammina con altre persone della sua età ad un passo normale in pianura?",
      question10: "Deve fermarsi per riprendere fiato se cammina in pianura al suo passo normale?",
      question11: "Ha difficoltà di respirazione quando si veste o si sveste?",
      question11b: "A che età ha osservato che il suo respiro non era più normale?",
      question11bHint: "Anni",
      question12: "Ha mai avuto sibili respiratori?",
      question13: "Si è mai svegliato con una sensazione di oppressione toracica?",
      question14: "Ha mai avuto mancanza di respiro durante il giorno mentre a riposo?",
      question15: "Ha mai avuto mancanza di respiro dopo uno sforzo fisico?",
      question16: "È stato mai svegliato da una crisi di mancanza di respiro?",
      question17: "Un medico le ha mai detto che soffre d'asma?",
      question17b: "A che età ha presentato la prima crisi?",
      question17bHint: "Anni",
      question17c: "A che età ha presentato l'ultima crisi?",
      question17cHint: "Anni",
      question17d: "Che lei sappia qualche parente soffre d'asma?",
      question18: "Fuma abitualmente o ha fumato in passato?",
      question18b: "Se si, quante sigarette al giorno?",
      question18bHint: "N° sigarette",
      question18c: "A che età ha cominciato a fumare regolarmente?",
      question18cHint: "Anni",
      question18d: "Se ha smesso di fumare definitivamente, da quando tempo ha smesso?",
      question18dHint: "Anni",
    },
    emSurveyForm: {
      question1: "Frammenti metallici nel corpo",
      question2: "Portatore di pace-maker cardiaco, stent defibrilatori imponatati o altro tipo di cateteri cardiaci",
      question3: "Clip vascolari e valvole cardiache",
      question4: "Distrattori colonna vertebrale",
      question5: "Pompa di infusione per insulina o altri farmaci",
      question6: "Corpi metallici nelle orecchie o impianti per udito",
      question7: "Neuro stimolatori, elettrodi impiantati nel cervello o subdurali",
      question8: "Altre protesi o stimolatori o apparecchiature elettriche",
      question9: "Derivazioni spinali o ventricolari cateteri cardiaci",
      question10: "Protesi metalliche",
      question10Details: "Specificare la localizzazione",
      question11: "Gravidanza",
      question12: "Corpi intrauterini",
      question13: "Patologie SNC",
      question14: "Soggetti con IMA recente e con patologie del sistema cardiovascolare",
    },
    equilibriumSurveyForm: {
      question1: "Patologie otologiche",
      question2: "Patologie neurologiche",
      question3: "Psicopatologie",
      question4: "Patologie dismetaboliche",
      question5: "Patologie odontoiatriche",
      question6: "Patologie ortopediche",
      question7: "Patologie oftalmologiche",
      question8: "Patologie cervicali",
      question9: "Allergie",
      question10: "Traumi cranici",
      question11: "Vertigini rotatorie",
      question12: "Vertigini non rotatorie",
      question13: "Aure",
      question14: "Instabilità",
      question15: "Test di Romberg",
      question16: "Marcia sul posto",
      question17: "Marcia a stella",
      question18: "Marcia funambolo",
      question19: "Prova degli indici",
      question20: "Conclusioni diagnostiche",
      title1: "Anamnesi patologica mirata",
      title2: "Anamnesi vestibolare",
      title3: "Esami clinici posturali non strumentali",
    },
    gasSurveyForm: {
      question1: "È affetto da malattie fisiche o psichiche",
      question2: "Presenta deficienze organiche che gli impediscono di eseguire con sicurezza operazioni relative all'impiego di gas tossici",
      question3: "Presenta segni di intossicazione alcolica o da sostanze stupefacenti",
      question4: "Ha integri il senso olfattorio e la pervietà nasale",
      question5: "Percepisce la voce afona ad almeno otto metri di distanza da ciascun orecchio",
      question6: "Naturale",
      question7: "Corretto",
      question8: "Grado rifrazione lenti",
      question9: "Si ritiene pertanto idoneo all'impiego di gas tossici",
      question10: "Con obbligo di lenti",
      left: "Sinistra",
      right: "Destra",
      title1: "Visus (Tavola di Snellen)",
    },
    noseSurveyForm: {
      question1: "Ha mai subito interventi chirurgici al naso?",
      question2: "Ha mai subito traumi nasali con intervento medico?",
      question3: "Utilizza farmaci per via nasale?",
      question4: "Ha mai avuto perdita di sangue dal naso?",
      question5: "Le sembra di non respirare bene con il naso?",
      question6: "Le capita di sentire meno gli odori?",
      question7: "Le capita di sentire parestesie al volto?",
      question8: "Ha mai notato tumefazione della gengiva superiore?",
      question9: "Soffre di cefalea, soprattutto frontale, per la quale non è stato possibile individuare la causa?",
    },
    rachisExamSurveyForm: {
      question1: "Lordosi cervicale",
      question2: "Cifosi dorsale",
      question3: "Scoliosi dorsale",
      question4: "Lordosi lombare",
      question5: "Scoliosi lombare",
      question6: "Ischio",
      question7: "Lasègue",
      question8: "Psoas",
      question9: "Wasserman",
      question10: "Distanza fronte-ginocchio",
      question11: "Dorso curvo",
      question12: "Ritmo lombo pelvico",
      question13: "Motilità dolente cervicale",
      question14: "Motilità dolente dorsolombare",
      question15: "Motilità ridotta cervicale",
      question16: "Motilità ridotta dorsolombare",
      question17: "Addominali",
      question18: "Achilleo",
      question19: "Rotuleo",
      question20: "Medio plantare",
      question21: "Prova dinamica (15 kg x 125 cm x 30\")",
      question22: "Conclusioni",
      title1: "Apparato osteoarticolare",
      title2: "Retrazione m. ischiocrurali/Lasègue",
      title3: "Retrazione m. ileo-Psoas Wasserman",
      title4: "Retrazione muscoli erettori spinali",
      title5: "Riflessi",
    },
    rachisSurveyForm: {
      cervicalSection: "Cervicale",
      dorsalSection: "Dorsale",
      diagnosisSection: "Diagnosi",
      lowBackPainSection: "Lombalgie acute",
      lumbosacralSection: "Lombosacrale",
      question1: "Lombalgie",
      question2: "Lombosciatalgie",
      question3: "Episodi acuti totali",
      question4: "Episodi acuti ultimo anno",
      question5: "Anno primo episodio",
      question6: "Assenze da lavoro per disturbi",
      question7: "Ernia discale lombosacrale diagnosticata",
      question8: "Data",
      question9: "Ernia discale lombosacrale trattata",
      question10: "Data",
      question11: "Patologie/traumi cervicale",
      question12: "Patologie/traumi dorsale",
      question13: "Patologie/traumi lombosacrale",
      question14: "Giorni malattia ultimi 12 mesi",
      question15: "Note ed osservazioni",
      section: {
        question1: "Disturbi",
        question2: "Anno di inizio",
        question3: "Raramente",
        question4: "Almeno 3-4 episodi ogni 2-3 giorni",
        question5: "Almeno 3-4 episodi con uso farmaci/trattamento",
        question6: "Quasi tutti i giorni",
        question7cervicalSection: "Irradiazione arto superiore destro",
        question7dorsalSection: "Irradiazione emitorace destro",
        question8cervicalSection: "Irradiazione arto superiore sinistro",
        question8dorsalSection: "Irradiazione emitorace sinistro",
        question9: "Giorni di assenza da lavoro per disturbi",
        question10: "Soglia dolore",
      }
    },
    upperExamSurveyForm: {
      question1: "Valutazione eseguita",
      question2: "Palpazione spalla",
      question3: "Flessione",
      question4: "Max abduzione",
      question5: "Rotaz. interna",
      question6: "Rotaz. esterna",
      question7: "Arco doloroso",
      question8: "Valutazione finale",
      // question9 notes
      question10: "Valutazione eseguita",
      question11: "Ispezione",
      question12: "Palp. osteoarticolare",
      question13: "Palp. muscoli",
      question14: "Test epicondilite",
      question15: "Test intrappolamento",
      question16: "Valutazione finale",
      // question17 notes
      question18: "Valutazione eseguita",
      question19: "Osservazione",
      question20: "Palpazione",
      question21: "Sx",
      question22: "Dx",
      question23: "Test di Finkelstein",
      question24: "Flesso-estensione del polso",
      question25: "Phalen test(60\")",
      question26: "Test di pressione",
      question27: "Valutazione finale",
      // question28 notes
      title1: "Scapolo-omerale",
      title2: "Valutazione motilità",
      title3: "Gomito",
      title4: "Polso/mano",
      title5: "Test dito a scatto",
    },
    upperSurveyForm: {
      question1: "Riferisce dolori",
      question2: "Anno di inizio",
      question3: "Ha assunto farmaci",
      question4: "Ha effettuato",
      question5: "Dolore movimenti",
      question6: "Dolore riposo",
      question7: "Soglia dolore",
      question8: "Riferisce dolori",
      question9: "Anno di inizio",
      question10: "Ha assunto farmaci",
      question11: "Ha effettuato",
      question12: "Dolore movimenti",
      question13: "Dolore riposo",
      question14: "Soglia dolore",
      question15: "Riferisce dolori",
      question16: "Anno di inizio",
      question17: "Ha assunto farmaci",
      question18: "Ha effettuato",
      question19: "Dolore movimenti",
      question20: "Dolore riposo",
      question21: "Dolore presa",
      question22: "Dolore 1° dito",
      question23: "Dolore altre dita",
      question24: "Dolore palmo",
      question25: "Dolore dorso",
      question26: "Soglia dolore",
      question27: "Riferisce dolori",
      question28: "Anno di inizio",
      question29: "Ha assunto farmaci",
      question30: "Ha effettuato",
      question31: "Disturbi braccio",
      question32: "Disturbi avambraccio",
      question33: "Disturbi mano",
      question34: "Durano meno di 10 minuti",
      question35: "Durano più di 10 minuti",
      question36: "Compaiono nel sonno",
      question37: "Compaiono al risveglio",
      question38: "Soglia disturbi",
      question39: "Riferisce dolori",
      question40: "Anno di inizio",
      question41: "Ha assunto farmaci",
      question42: "Ha effettuato",
      question43: "Disturbi braccio",
      question44: "Disturbi avambraccio",
      question45: "Disturbi mano",
      question46: "Compaiono appoggio gomito",
      question47: "Durano meno di 10 minuti",
      question48: "Durano più di 10 minuti",
      question49: "Compaiono braccia alzate",
      question50: "Compaiono nelle prese",
      question51: "Soglia disturbi",
      question52: "Assenze dal lavoro per disturbi arti superiori (giorni)",
      question53: "Spalla (periartrite, tendiniti, etc.)",
      question54: "Gomito (epicondilite, epitrocleite, etc.)",
      question55: "Polso / Mano (tendiniti, cisti tendinee, etc.)",
      question56: "Polso / Mano (tunnel carpale, Guyon, etc.)",
      question57: "Numero totale giorni di malattia negli ultimi 12 mesi",
      question58: "Note",
      title1: "Spalla",
      title2: "Gomito",
      title3: "Polso / Mano",
      title4: "Parestesie notturne",
      title5: "Parestesie diurne",
      title6: "Traumi / Diagnosi",
      year: "Anno",
    },
    vdtSurveyForm: {
      question1: "Uso del videoterminale",
      question2: "Anni",
      question3: "Media ore",
      question4: "Tempi di utilizzo",
      question5: "Lavoro alternato",
      question6: "Modalità prevalente di utilizzo",
      question7: "Uso di lenti correttive",
      question8: "Nausea",
      question9: "Cefalea",
      question10: "Prurito",
      question11: "Bruciore",
      question12: "Fotofobia",
      question13: "Lacrimazione",
      question14: "Senso di peso oculare",
      question15: "Visione sfocata",
      question17: "Note",
      title1: "Sintomi o segni di astenopia",
      title2: "Anamnesi ergoftalmologica",
    },
    result: "Esito",
  },
};
