import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge status-grey"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "badge status-blue"
}
const _hoisted_4 = {
  key: 3,
  class: "badge status-green"
}
const _hoisted_5 = {
  key: 4,
  class: "badge status-purple"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.examination.state === _ctx.ExaminationState.Draft)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.t("examination.states.draft")), 1))
    : (_ctx.examination.state === _ctx.ExaminationState.Pending)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("span", {
            class: _normalizeClass({ 'mx-2 badge': true, 'status-green': _ctx.examination.testsState === true, 'status-orange': _ctx.examination.testsState === false }),
            style: {"display":"inline-block","min-width":"2.5rem"}
          }, [
            _createTextVNode(_toDisplayString(_ctx.t("examination.states.shortTests")), 1)
          ], 2)), [
            [
              _directive_tooltip,
              _ctx.t('examination.states.tests'),
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("span", {
            class: _normalizeClass({ 'mx-2 badge': true, 'status-green': _ctx.examination.surveysState === true, 'status-orange': _ctx.examination.surveysState === false }),
            style: {"display":"inline-block","min-width":"2.5rem"}
          }, [
            _createTextVNode(_toDisplayString(_ctx.t("examination.states.shortSurveys")), 1)
          ], 2)), [
            [
              _directive_tooltip,
              _ctx.t('examination.states.surveys'),
              void 0,
              { top: true }
            ]
          ]),
          _withDirectives((_openBlock(), _createElementBlock("span", {
            class: _normalizeClass({ 'mx-2 badge': true, 'status-green': _ctx.examination.judgmentState === true, 'status-orange': _ctx.examination.judgmentState === false }),
            style: {"display":"inline-block","min-width":"2.5rem"}
          }, [
            _createTextVNode(_toDisplayString(_ctx.t("examination.states.shortJudgment")), 1)
          ], 2)), [
            [
              _directive_tooltip,
              _ctx.t('examination.states.judgment'),
              void 0,
              { top: true }
            ]
          ])
        ]))
      : (_ctx.examination.state === _ctx.ExaminationState.Completed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.t("examination.states.completed")), 1))
        : (_ctx.examination.state === _ctx.ExaminationState.Billed)
          ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.t("examination.states.billed")), 1)
            ])), [
              [
                _directive_tooltip,
                _ctx.renderInvoiceTooltip(_ctx.examination.invoice),
                void 0,
                { top: true }
              ]
            ])
          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t("gen.unknown")), 1))
}