import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { NumberField } from "@/core/fields";

export class PaymentItem {
  public id: string = DataHelpers.uniqueId();
  public amount = 0;
  public daysToExpiration = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): PaymentItem {
    this.id = DataHelpers.uniqueId();

    this.amount = NumberField.fromFirestore(data.amount);
    this.daysToExpiration = NumberField.fromFirestore(data.daysToExpiration);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.amount = NumberField.toFirestore(this.amount);
    firestoreData.daysToExpiration = NumberField.toFirestore(this.daysToExpiration);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): PaymentItem {
    this.id = DataHelpers.uniqueId();

    this.amount = NumberField.fromOfflineCache(data.amount);
    this.daysToExpiration = NumberField.fromOfflineCache(data.daysToExpiration);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.amount = NumberField.toOfflineCache(this.amount);
    firestoreData.daysToExpiration = NumberField.toOfflineCache(this.daysToExpiration);

    return firestoreData;
  }
}
