export const it = {
  broker: {
    additionalData: "Dati aggiuntivi",
    address: "Indirizzo",
    broker: "Intermediario",
    brokers: "Intermediari",
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserire un indirizzo e-mail valido",
    communicationEmail: "E-mail comunicazione",
    communicationEmailError: "Inserire un indirizzo e-mail valido",
    companyReferents: "Referenti aziendali",
    costList: "Listino costi",
    email: "Indirizzo e-mail",
    filters: {
      archived: "Intermediari archiviati",
      archivedExcluded: "Archiviati esclusi",
      archivedIncluded: "Archiviati inclusi",
    },
    fiscalCode: "Codice fiscale",
    info: "Informazioni",
    invoiceEmail: "E-mail fatturazione",
    invoiceEmailError: "Inserire un indirizzo e-mail valido",
    invoices: "Fatture",
    legalBranch: "Sede legale",
    legalRepresentative: "Rappresentante legale",
    name: "Ragione sociale",
    nameError: "Inserire la ragione sociale",
    notes: "Note",
    phone: "Telefono",
    priceList: "Listino prezzi",
    province: "Provincia",
    recipientCode: "Codice destinatario",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attivo",
      archived: "Archiviato",
      archivedOn: "Archiviato il { date }",
    },
    town: "Comune",
    vatCode: "Partita IVA",
    vatCodeError: "Inserire una partita IVA valida e univoca",
    zipCode: "CAP",
  },
  navigation: {
    broker: "Intermediari",
  },
  buttons: {
    archiveBroker: "Metti in archivio",
    restoreBroker: "Togli dall'archivio",
  },
};
