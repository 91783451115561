import { RouteRecordRaw } from "vue-router";

export const appFaultRoutes: RouteRecordRaw[] = [
  {
    path: "/app-faults",
    name: "AppFaultList",
    component: () => import("../views/AppFaultList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "appFault",
      requiredRight: "viewUI",
    },
  },
];
