import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Prescription } from "../objects/Prescription";
import { prescriptionOfflineModel } from "./PrescriptionOfflineModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class PrescriptionModel extends FirestoreModel<Prescription> {
  public constructor() {
    super(() => new Prescription(), "prescriptions", LockPolicy.DiscardUnsyncedChanges, "prescription");
    this.beforeDeleteFunction = "featuresPrescriptionBeforeDelete";
    this.offlineModuleModel = prescriptionOfflineModel;
  }

  public async getDocuments(): Promise<Prescription[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(prescriptionId: string): Promise<Prescription> {
    return super.getDocument(prescriptionId);
  }

  public async createDocument(prescription: Prescription): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    prescription.order = documentCounter + 1;

    return super.createDocument(prescription);
  }

  public async updateDocument(prescription: Prescription): Promise<void> {
    return super.updateDocument(prescription);
  }

  public async deleteDocument(prescription: Prescription): Promise<boolean> {
    return super.deleteDocument(prescription);
  }
}

export const prescriptionModel: PrescriptionModel = new PrescriptionModel();
