import { Ref } from "vue";
import { Query, query, Unsubscribe, where } from "firebase/firestore";

import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Notification } from "../objects/Notification";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { markNotificationAsDeleted, markNotificationAsRead, startNotificationListener, stopNotificationListener } from "./methods";

export class NotificationModel extends FirestoreModel<Notification> {
  public notificationListenerHandler: Unsubscribe | undefined = undefined;

  public constructor() {
    super(() => new Notification(), "notifications", LockPolicy.None, "notification");
  }

  public async getDocuments(): Promise<Notification[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    const customQuery: Query<Notification> = query(
      notificationModel.getPathReference().withConverter(notificationModel.firestoreConverter),
      where("owners", "array-contains", user.id)
    );

    return super.getDocuments([new SortCriteria("createdAt", "desc", "date")], undefined, customQuery);
  }

  public async getDocument(notificationId: string): Promise<Notification> {
    return super.getDocument(notificationId);
  }

  public async createDocument(notification: Notification): Promise<string> {
    return super.createDocument(notification);
  }

  public async updateDocument(notification: Notification): Promise<void> {
    if (notification.owners.length === 0 && notification.unread.length === 0) {
      await super.deleteDocument(notification);
      return;
    }
    return super.updateDocument(notification);
  }

  public async deleteDocument(notification: Notification): Promise<boolean> {
    return super.deleteDocument(notification);
  }

  public async markNotificationAsDeleted(notificationId: string): Promise<void> {
    return markNotificationAsDeleted(notificationId);
  }

  public async markNotificationAsRead(notificationId: string): Promise<void> {
    return markNotificationAsRead(notificationId);
  }

  public startNotificationListener(notifications: Ref<Notification[]>): void {
    return startNotificationListener(notifications);
  }

  public stopNotificationListener(): void {
    return stopNotificationListener();
  }
}

export const notificationModel: NotificationModel = new NotificationModel();
