export const it = {
  branchLegalRecord: {
    addRisk: "Aggiungi rischio",
    addSickness: "Aggiungi malattia professionale",
    branchLegalRecord: "Allegato 3B",
    branchLegalRecordOf: "Allegato 3B di {parent}",
    branchLegalRecords: "Allegati 3B",
    branchLegalRecordsOf: "Allegati 3B di {parent}",
    branchValues: "Dati di sede",
    calculations: "Conteggi",
    createdAt: "Data e ora",
    description: "Descrizione",
    doctor: "Medico competente",
    drugs: "Alcool e droghe",
    drugsConfirmed: "Casi di dipendenza confermati",
    drugsSent: "Lavoratori inviati presso SERT o centro alcologico",
    drugsVisited: "Lavoratori controllati nell'anno con test di screening",
    employer: "Datore di lavoro",
    female: "Femmine",
    inailAssociationId: "ID Associazione",
    inailBranchAddress: "Indirizzo unità produttiva",
    inailBranchId: "ID unità produttiva",
    inailBranchName: "Unità produttiva",
    inailCompanyName: "Azienda",
    inailData: "Dati INAIL",
    inailDoctorRole: "Ruolo medico",
    inailFiscalCode: "Codice fiscale",
    inailVatCode: "Partita IVA",
    isCompanyValuesByDoctorLabel: "Dati compilati da",
    male: "Maschi",
    partiallyFit: "Idoneità parziali",
    performCountLabel: "Esegui conteggio automatico per medico",
    risks: "Rischi",
    sicknesses: "Malattie professionali",
    state: "Stato",
    states: {
      completed: "Completato",
      draft: "Bozza",
    },
    surveilled: "Lavoratori sottoposti a sorveglianza sanitaria",
    surveilledYear: "Lavoratori sottoposti a sorveglianza sanitaria nell'anno di riferimento",
    unfit: "Inidoneità",
    visited: "Visitati",
    year: "Anno di riferimento",
  },
  buttons: {
    performCount: "Calcola",
  },
};
