export const itCoreLabels = {
  buttons: {
    back: "Indietro",
    cancel: "Annulla",
    clear: "Pulisci",
    close: "Chiudi",
    create: "Crea",
    createUser: "Crea utente",
    delete: "Elimina",
    download: "Scarica",
    disableUser: "Disattiva utente",
    edit: "Modifica",
    enableUser: "Attiva utente",
    export: "Esporta",
    import: "Importa",
    load: "Carica",
    markAsRead: "Segna come letto",
    noAction: "Nessuna azione disponibile",
    print: "Stampa",
    run: "Esegui",
    save: "Salva",
    search: "Cerca",
    send: "Invia",
    sendNotification: "Invia notifica",
    toggleUser: "Inverti stato",
    upload: "Carica file",
    view: "Visualizza",
  },
  dialog: {
    confirmCloseWithoutSave: "Chiudere senza salvare?",
    confirmDelete: "Confermi l'eliminazione?",
    editingConfirm: "I dati non salvati verranno persi, sei sicuro?",
    editingTitle: "Uscita senza salvataggio",
  },
  gen: {
    actions: "Azioni",
    any: "Qualsiasi",
    all: "Tutti",
    backTo: "Torna a",
    browse: "Sfoglia",
    confirmActionText: "Confermi l'esecuzione dell'azione selezionata?",
    confirmActionTitle: "Conferma Azione",
    dashboard: "Plancia",
    deleteConfirm: "Sei sicuro di voler eliminare",
    deleteObject: "l'oggetto selezionato?",
    deleteTitle: "Conferma Eliminazione",
    devAlert: "Versione di sviluppo",
    developedby: "sviluppato da",
    document: "Documento",
    editPrefix: "Modifica",
    empty: "Vuoto",
    errorContact: "C'è stato un errore, contatta lo sviluppatore",
    expirationDaysLeft: "giorni rimanenti",
    expirationExpired: "Scaduta",
    expirationNotAvailable: "Non prevista",
    file: "Allegato",
    filePlaceholder: "Seleziona un file o trascinalo qui",
    files: "Allegati",
    filter: "Filtra",
    findAddress: "Trova indirizzo",
    generals: "Generali",
    guest: "Ospite",
    info: "Informazioni",
    inputAddress: "Inserire un indirizzo",
    loading: "Caricamento in corso",
    logout: "Esci",
    maintenanceDescription: "Ci scusiamo per l'inconveniente. Sono in corso delle operazioni di manutenzione, KORU tornerà presto online.",
    maintenanceTitle: "Torniamo presto!",
    missingPrivileges: "Non hai i privilegi necessari per visualizzare la pagina",
    newPrefix: "Crea",
    no: "No",
    noData: "Nessun dato disponibile",
    ok: "Ok",
    oops: "OOPS!",
    pageNotFound: "La pagina richiesta non è disponibile",
    pageResults: "Risultati da [[first]] a [[last]] di [[totalRecords]] totali",
    profile: "Profilo",
    reorder: "Ordina",
    search: "Cerca",
    unknown: "sconosciuto",
    user: "Utente",
    viewPrefix: "Visualizza",
    yes: "Sì",
  },
  navigation: {
    about: "Info",
    administration: "Gestione",
    content: "Contenuto",
    dashboard: "Plancia",
    development: "Sviluppo",
    general: "Generale",
    structure: "Struttura",
    tools: "Strumenti",
  },
  rights: {
    create: "Crea",
    deleteAll: "Elimina Tutti",
    deleteAllowed: "Elimina Consentiti",
    deleteOwned: "Elimina Propri",
    readAll: "Leggi Tutti",
    readAllowed: "Leggi Consentiti",
    readOwned: "Leggi Propri",
    updateAll: "Modifica Tutti",
    updateAllowed: "Modifica Consentiti",
    updateOwned: "Modifica Propri",
    viewUI: "Interfaccia",
  },
  themes: {
    dim: "Notte",
    light: "Chiaro",
    solarized: "Solarizzato",
  },
  toast: {
    error: {
      actionError: "Non è stato possibile completare l'azione",
      backupDataNotSet: "I dati di backup non sono stati caricati",
      children: "Sono presenti elementi collegati",
      create: "Non è stato possibile creare l'elemento",
      delete: "Non è stato possibile effettuare l'eliminazione",
      deleteFile: "Non è stato possibile eliminare il file",
      emailChanged: "Non è stato possibile modificare l'indirizzo e-mail",
      feedbackSent: "Non è stato possibile inviare il feedback",
      fiscalCode: "Non è stato possibile calcolare il codice fiscale",
      generic: "È stato riscontrato un errore non previsto",
      noAction: "Nessuna azione è stata selezionata",
      notificationSent: "Non è stato possibile inviare la notifica",
      notSupportedFileExtension: "Il tipo di file caricato non è supportato",
      passwordChanged: "Non è stato possibile modificare la password",
      readFile: "Non è stato possibile leggere il file",
      reorder: "Non è stato possibile ordinare gli elementi",
      save: "Non è stato possibile effettuare il salvataggio",
      search: "Non è stato possibile effettuare la ricerca",
      sync: "I dati sono stati modificati da un altro utente",
      title: "Errore!",
      toggleUser: "Errore durante la modifica dello stato",
      uploadFile: "Non è stato possibile caricare il file",
    },
    success: {
      actionSuccess: "Azione eseguita correttamente",
      backupDownloadSuccess: "Backup generato correttamente",
      backupRestoreSuccess: "Backup ripristinato correttamente",
      create: "Elemento creato con successo",
      delete: "Eliminazione eseguita correttamente",
      deleteFile: "File eliminato con successo",
      emailChanged: "Indirizzo e-mail modificato correttamente",
      feedbackSent: "Feedback inviato correttamente",
      generic: "Operazione completata correttamente",
      notificationSent: "Notifica inviata correttamente",
      passwordChanged: "Password modificata correttamente",
      save: "Salvataggio eseguito correttamente",
      title: "Completato!",
      toggleUser: "Stato modificato correttamente",
      uploadFile: "Il file è stato caricato correttamente",
    },
    warning: {
      filterMissing: "Selezionare tutti i filtri",
      formInvalid: "Verificare i campi inseriti",
      reverseGeocodingError: "Non è stato possibile trovare l'indirizzo",
      searchNoResults: "Nessun risultato trovato",
      searchTextTooShort: "Inserire almeno 3 caratteri",
      seederNoModules: "Seleziona almeno una collezione",
      title: "Attenzione!",
    },
  },
};
