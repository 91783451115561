import { CostList } from "../objects/CostList";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import { cloneCostList, getCostListsByFirm } from "./methods";

export class CostListModel extends FirestoreModel<CostList> {
  public constructor() {
    super(() => new CostList(), "costLists", LockPolicy.DiscardUnsyncedChanges, "costList");
    this.beforeDeleteFunction = "featuresCostListBeforeDelete";
  }

  public async getDocuments(): Promise<CostList[]> {
    return this.getCostListsByFirm();
  }

  public async getDocument(costListId: string): Promise<CostList> {
    return super.getDocument(costListId);
  }

  public async createDocument(costList: CostList): Promise<string> {
    await costList.setFirm();

    return super.createDocument(costList);
  }

  public async updateDocument(costList: CostList): Promise<void> {
    return super.updateDocument(costList);
  }

  public async deleteDocument(costList: CostList): Promise<boolean> {
    return super.deleteDocument(costList);
  }

  public async cloneCostList(costList: CostList): Promise<string> {
    return cloneCostList(costList);
  }

  public async getCostListsByFirm(): Promise<CostList[]> {
    return getCostListsByFirm();
  }
}

export const costListModel: CostListModel = new CostListModel();
