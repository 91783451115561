import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LinkedInvoice } from "@/features/modules/invoice/objects/LinkedInvoice";
import { LinkedServiceType } from "@/features/modules/serviceType/objects/LinkedServiceType";
import { serviceModel } from "../models/ServiceModel";
import { ServiceState } from "./ServiceState";

import { DateField, DateStrictField, EnumField, NumberField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class Service extends FirestoreDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public firm: LinkedFirm | undefined = undefined;
  public date: Date = new Date();
  public company: LinkedCompany | undefined = undefined;
  public branch: LinkedBranch | undefined = undefined;
  public type: LinkedServiceType | undefined = undefined;
  public doctor: LinkedDoctor | undefined = undefined;
  public duration = 0;
  public nextServiceDate: Date | undefined = undefined;
  public notes: string | undefined = undefined;
  public state: ServiceState = ServiceState.Draft;
  public invoice: LinkedInvoice | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Service {
    super.fromFirestore(data, id, firestoreRef);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.branch = ObjectField.fromFirestore<LinkedBranch>(data.branch, (value) => new LinkedBranch(value));
    this.type = ObjectField.fromFirestore<LinkedServiceType>(data.type, (value) => new LinkedServiceType(value));
    this.doctor = ObjectField.fromFirestore<LinkedDoctor>(data.doctor, (value) => new LinkedDoctor(value));
    this.duration = NumberField.fromFirestore(data.duration);
    this.nextServiceDate = DateField.fromFirestore(data.nextServiceDate);
    this.notes = StringField.fromFirestore(data.notes);
    this.state = EnumField.fromFirestore<ServiceState>(data.state, Object.values(ServiceState), ServiceState.Draft);
    this.invoice = ObjectField.fromFirestore<LinkedInvoice>(data.invoice, (value) => new LinkedInvoice(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.branch = ObjectField.toFirestore<LinkedBranch>(this.branch, (value) => value.toFirestore());
    firestoreData.type = ObjectField.toFirestore<LinkedServiceType>(this.type, (value) => value.toFirestore());
    firestoreData.doctor = ObjectField.toFirestore<LinkedDoctor>(this.doctor, (value) => value.toFirestore());
    firestoreData.duration = NumberField.toFirestore(this.duration);
    firestoreData.nextServiceDate = DateField.toFirestore(this.nextServiceDate);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.state = EnumField.toFirestore<ServiceState>(this.state, ServiceState.Draft);
    firestoreData.invoice = ObjectField.toFirestore<LinkedInvoice>(this.invoice, (value) => value.toFirestore());

    return firestoreData;
  }

  public async setCodes(): Promise<void> {
    this.code = await serviceModel.getNextServiceCodeByDateAndFirm(this.date);
    this.codeSort = (this.date.getFullYear() - 2000) * 1000000 + this.code;
    this.codeDisplay = `${this.code}/${this.date.getFullYear()}`;
    this.year = this.date.getFullYear();
  }
}
