import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyCostList } from "@/features/modules/companyCostList/objects/CompanyCostList";
import { companyCostListModel } from "../CompanyCostListModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";

export const getCompanyCostListsByCompanyAndFirm = async (companyId: string): Promise<CompanyCostList[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<CompanyCostList> = query(
      companyCostListModel.getPathReference(companyId).withConverter(companyCostListModel.firestoreConverter),
      where("firm.id", "==", firmId)
    );

    const snapshot: QuerySnapshot<CompanyCostList> = await getDocs(companyCostListModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyCostListModel.getCompanyCostListsByCompanyAndFirm", { companyId }, error);
    return [];
  }
};

function sortDocuments(documents: CompanyCostList[]): CompanyCostList[] {
  const sorter: FirestoreSorter<CompanyCostList> = new FirestoreSorter(documents);
  sorter.addSortCriteria("fromSort", "asc", "string");
  return sorter.sort();
}
