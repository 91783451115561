import { setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { getDocument } from "./getDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { log } from "@/core/modules/log/Log";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const updateDocument = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  firestoreDocument: T,
  parentId?: string,
  writeLog?: boolean
): Promise<void> => {
  const oldUpdatedAt: Date = firestoreDocument.updatedAt;
  const oldUpdatedBy: string = firestoreDocument.updatedBy;

  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    // offline mode
    if (offlineModel.getOfflineState() === "offline" && firestoreDocument instanceof FirestoreOfflineDocument)
      return updateOfflineDocument(user, model as FirestoreModel<FirestoreOfflineDocument>, firestoreDocument);

    // check if user can update the document
    if (user.canUpdate(model.roleModule, firestoreDocument) === false)
      throw new Error(`Unable to update document #${firestoreDocument.id} in collection ${model.collectionName}`);

    // check if document is locked
    if (model.lockPolicy === LockPolicy.DiscardUnsyncedChanges) {
      const oldFirestoreDocument: T = await getDocument(model, firestoreDocument.id, parentId);
      if (firestoreDocument.hasChangedFrom(oldFirestoreDocument)) {
        throw new Error("sync");
      }
    }

    firestoreDocument.setSearchKeys();
    firestoreDocument.setTimestampFields("update");
    await setDoc(model.getDocumentReference(firestoreDocument.id, parentId).withConverter(model.firestoreConverter), firestoreDocument);

    if (writeLog === true) log.notice(`Document ${firestoreDocument.id} updated in collection ${model.collectionName}`);
  } catch (error: unknown) {
    firestoreDocument.updatedAt = oldUpdatedAt;
    firestoreDocument.updatedBy = oldUpdatedBy;
    appFaultModel.catchAppError("FirestoreModel.updateDocument", { model, firestoreDocument, parentId }, error);
  }
};

const updateOfflineDocument = async <T extends FirestoreOfflineDocument>(
  user: User,
  model: FirestoreModel<T>,
  firestoreOfflineDocument: T
): Promise<void> => {
  try {
    if (model.offlineModuleModel === undefined) throw new Error("offlineModuleModelNotSaveable");
    if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");
    if (model.offlineModuleModel.canUpdate === false) throw new Error("offlineModuleModelNotSaveable");

    await indexedDbModel.setDocument(model.collectionName, firestoreOfflineDocument.id, firestoreOfflineDocument.toOfflineCache());
    await model.offlineModuleModel.updateDocumentToQueue(firestoreOfflineDocument);
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.updateOfflineDocument", { user, model, firestoreOfflineDocument }, error);
  }
};
