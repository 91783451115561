
import { defineComponent, onBeforeUnmount, onMounted, Ref, ref } from "vue";

import { Notification } from "../objects/Notification";
import { notificationModel } from "../models/NotificationModel";
import { notificationModules } from "../objects/notificationModules";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "NotificationSelector",
  props: {
    topbarNotificationMenuActive: Boolean,
  },
  emits: ["topbar-notification"],
  setup() {
    const { t } = useLocale();

    const notifications: Ref<Notification[]> = ref([]);

    onMounted(() => {
      notificationModel.startNotificationListener(notifications);
    });

    onBeforeUnmount(() => {
      notificationModel.stopNotificationListener();
    });

    return {
      notifications,
      notificationModules,
      t,
    };
  },
  methods: {
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.$emit("topbar-notification", event);
    },
  },
});
