import { RouteRecordRaw } from "vue-router";

export const guideRoutes: RouteRecordRaw[] = [
  {
    path: "/guides/list",
    name: "GuideList",
    component: () => import("../views/GuideList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "guide",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/guides/:id/edit",
    name: "GuideEdit",
    component: () => import("../views/GuideEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "guide",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/guides",
    name: "GuideBrowse",
    component: () => import("../views/GuideBrowse.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "",
      requiredRight: "",
    },
  },
];
