import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { Hint } from "../objects/Hint";
import { hintOfflineModel } from "./HintOfflineModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class HintModel extends FirestoreModel<Hint> {
  public constructor() {
    super(() => new Hint(), "hints", LockPolicy.DiscardUnsyncedChanges, "hint");
    this.offlineModuleModel = hintOfflineModel;
  }

  public async getDocuments(): Promise<Hint[]> {
    return super.getDocuments([new SortCriteria("order", "asc", "number")]);
  }

  public async getDocument(hintId: string): Promise<Hint> {
    return super.getDocument(hintId);
  }

  public async createDocument(hint: Hint): Promise<string> {
    const documentCounter: number = await this.countDocuments();
    hint.order = documentCounter + 1;

    return super.createDocument(hint);
  }

  public async updateDocument(hint: Hint): Promise<void> {
    return super.updateDocument(hint);
  }

  public async deleteDocument(hint: Hint): Promise<boolean> {
    return super.deleteDocument(hint);
  }
}

export const hintModel: HintModel = new HintModel();
