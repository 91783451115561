import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CostList } from "@/features/modules/costList/objects/CostList";
import { costListModel } from "../CostListModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";

export const getCostListsByFirm = async (): Promise<CostList[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<CostList> = query(
      costListModel.getPathReference().withConverter(costListModel.firestoreConverter),
      where("firm.id", "==", firmId)
    );

    const snapshot: QuerySnapshot<CostList> = await getDocs(costListModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("CloneListModel.getCostListsByFirm", {}, error);
    return [];
  }
};

function sortDocuments(documents: CostList[]): CostList[] {
  const sorter: FirestoreSorter<CostList> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
