import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-blockui p-blockui-document p-component-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_ctx.$props.blocked)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProgressBar, {
          value: _ctx.$props.value,
          class: "koru-progress"
        }, null, 8, ["value"])
      ]))
    : _createCommentVNode("", true)
}