export const it = {
  appFault: {
    appFault: "Errore app",
    appFaults: "Errori app",
    createdAt: "Data e ora",
    message: "Messaggio",
    noDetails: "Nessun dettaglio presente",
    notifications: {
      appFaultCreated: "Errore app creato",
      groupName: "Errori app",
    },
    stack: "Dettagli",
    user: "Utente",
  },
  buttons: {
    deleteAll: "Elimina tutto",
    showDetails: "Mostra dettagli",
  },
  navigation: {
    appFault: "Errori app",
  },
};
