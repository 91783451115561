import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocType } from "../objects/DocType";
import { docTypeModel } from "./DocTypeModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class DocTypeOfflineModel extends OfflineModuleModel<DocType> {
  public constructor() {
    super(() => new DocType(), "docTypes", "docType.docTypes", undefined);
  }

  public async downloadCache(): Promise<DocType[]> {
    try {
      return docTypeModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("DocTypeOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const docTypeOfflineModel: DocTypeOfflineModel = new DocTypeOfflineModel();
