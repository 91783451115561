import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getLastInvoiceByBroker = async (brokerId: string): Promise<Invoice | undefined> => {
  try {
    if (offlineModel.getOfflineState() === "offline") return undefined;

    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<Invoice> = query(
      invoiceModel.getPathReference().withConverter(invoiceModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("broker.id", "==", brokerId)
    );

    const snapshot: QuerySnapshot<Invoice> = await getDocs(invoiceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot === undefined || snapshot.empty) return undefined;

    const documents: Invoice[] = sortDocuments(snapshot.docs.map((doc) => doc.data()));

    return documents.length > 0 ? documents[0] : undefined;
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getLastInvoiceByBroker", { brokerId }, error);
    return undefined;
  }
};

function sortDocuments(documents: Invoice[]): Invoice[] {
  const sorter: FirestoreSorter<Invoice> = new FirestoreSorter(documents);
  sorter.addSortCriteria("codeSort", "desc", "string");
  return sorter.sort();
}
