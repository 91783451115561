import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    closable: true,
    header: _ctx.t('gen.document'),
    modal: true,
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("iframe", {
        src: _ctx.pdfValue,
        style: {"width":"80vw","height":"80vh"}
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}