import { Ref, ref } from "vue";

import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";

export class KoruSortableBrowserModule<T> {
  public canCreate = true;
  public canDelete = true;
  public canEdit = true;
  public canView = true;
  public createPath: string | undefined = undefined;
  public deleteDescriptionField = "";
  public documents: Ref<T[]> = ref([]);
  public explicitRequiredModule: string | undefined = undefined;
  public loadingStop = true;
  public path = "";
  public contextCommands: ContextCommand[] = [];
  public suffix = "";

  public onDeleteAction(document: T): Promise<boolean> {
    console.log(document);
    return Promise.resolve(true);
  }

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onLoadWithFiltersAction(): Promise<void> {
    return Promise.resolve();
  }

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }

  public onReorderAction(documents: T[]): Promise<void> {
    console.log(documents);
    return Promise.resolve();
  }
}
