import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuePDF = _resolveComponent("VuePDF")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
    return (_openBlock(), _createElementBlock("div", {
      key: page,
      class: "pt-4"
    }, [
      _createVNode(_component_VuePDF, {
        pdf: _ctx.pdf,
        page: page,
        "fit-parent": ""
      }, null, 8, ["pdf", "page"])
    ]))
  }), 128))
}