import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { websocketClient } from "@/features/modules/assist/objects/WebsocketClient";

export const signDocument = (name: string, base64File: string, examination: Examination): void => {
  try {
    if (name === undefined) throw new Error("name is undefined");
    if (base64File === undefined) throw new Error("base64File is undefined");

    websocketClient.sendRequest("signDocument", { name: name, file: base64File, examinationId: examination.id });
    console.log({ name: name, file: base64File, examinationId: examination.id });

    console.log(`File sent for signature: ${name}`);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.signDocument", { name, base64File, examination }, error);
  }
};
