export const it = {
  activity: {
    activity: "Attività",
    activities: "Attività",
    icon: "Icona",
    iconError: "Inserire un'icona valida",
    name: "Nome",
    nameError: "Inserire un nome valido",
  },
  navigation: {
    activity: "Attività",
  },
};
