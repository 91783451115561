
import { ComputedRef, computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRoute } from "vue-router";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { storeTypes } from "@/core/modules/store/types";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "KoruTabbedViewer",
  props: {
    breadcrumbs: { type: Object, default: undefined },
    currentSection: { type: String, default: "" },
    editRoute: { type: String },
    loadAction: { type: Function },
    onSectionChanged: {
      type: Function,
      default: () => {
        return;
      },
    },
    sections: { type: Array, default: () => [] },
  },
  emits: ["update:sectionIndex"],
  setup(props) {
    const route = useRoute();
    const store = useStore();

    const sectionIndex: Ref<number> = ref(0);

    const sectionsRef: ComputedRef<string[]> = computed(() => props.sections as string[]);

    const sectionChanged = async (event: Record<string, unknown>) => {
      let routePieces: string[] = route.path.split("/");
      routePieces = routePieces.filter((piece) => piece);

      const newSection: string = sectionsRef.value[event.index as number];

      if (newSection == "info") {
        routePieces.splice(routePieces.length - 1, 1);
      } else {
        if (sectionsRef.value.indexOf(routePieces[routePieces.length - 1]) >= 0) {
          routePieces[routePieces.length - 1] = newSection;
        } else {
          routePieces.push(newSection);
        }
      }

      await Navigation.navigateUndercoverTo(`/${routePieces.join("/")}`);

      props.onSectionChanged(newSection);

      store.commit(storeTypes.mutations.loadingStop);
    };

    const selectSection = () => {
      for (let index = 0; index < sectionsRef.value.length; index++) {
        if (sectionsRef.value[index] == props.currentSection && index > 0) {
          sectionIndex.value = index;
          return;
        }
      }
    };

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          selectSection();
          if (props.loadAction != undefined) await props.loadAction();
          props.onSectionChanged(props.currentSection !== undefined && props.currentSection.trim().length > 0 ? props.currentSection : undefined);
        },
        () => store.commit(storeTypes.mutations.loadingStop)
      );
    };

    onMounted(() => handleLoad());

    return {
      sectionIndex,
      sectionChanged,
    };
  },
});
