import { RouteRecordRaw } from "vue-router";

export const roleRoutes: RouteRecordRaw[] = [
  {
    path: "/roles",
    name: "RoleList",
    component: () => import("../views/RoleList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "role",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/roles/:id/edit",
    name: "RoleEdit",
    component: () => import("../views/RoleEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "role",
      requiredRight: "viewUI",
    },
  },
];
