import { compareAsc } from "date-fns";
import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getExaminationsWithFilters = async (
  startDate: Date,
  endDate: Date,
  companyId: string,
  employeeId: string,
  doctorId: string,
  state: string
): Promise<Examination[]> => {
  try {
    if (startDate == undefined) throw new Error("startDate is undefined");
    if (endDate == undefined) throw new Error("endDate is undefined");

    const firmId: string = firmModel.getSelectedFirmId();
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (examinationModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      let documents: Examination[] = ((await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[]).filter(
        (examination) => compareAsc(examination.date, startDate) >= 0 && compareAsc(examination.date, endDate) <= 0 && examination.firm?.id === firmId
      );

      if (companyId !== "all") documents = documents.filter((examination) => examination.company?.id === companyId);
      if (employeeId !== "all") documents = documents.filter((examination) => examination.employee?.id === employeeId);
      if (doctorId !== "all") documents = documents.filter((examination) => examination.doctor?.id === doctorId);
      if (state !== "all") documents = documents.filter((examination) => examination.state === state);

      return documents;
    }

    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined) throw new Error(`User ${user.id} doesn't have a linked doctor document`);
      if (doctorId !== "all" && doctorId !== doctor.id) throw new Error(`User doesn't have rights to read doctor #${doctorId}`);
      if (companyId !== "all" && doctor.authorizedCompaniesIds.includes(companyId) === false)
        throw new Error(`User doesn't have rights to read company #${companyId}`);
    }

    let snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("firm.id", "==", firmId)
    );

    if (companyId !== "all") snapshotQuery = query(snapshotQuery, where("company.id", "==", companyId));
    if (employeeId !== "all") snapshotQuery = query(snapshotQuery, where("employee.id", "==", employeeId));
    if (doctorId !== "all") snapshotQuery = query(snapshotQuery, where("doctor.id", "==", doctorId));
    if (state !== "all") snapshotQuery = query(snapshotQuery, where("state", "==", state));

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsWithFilters", { startDate, endDate, companyId, doctorId, state }, error);
    return [];
  }
};
