import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";

export class DateField {
  public static fromFirestore(value: unknown): Date | undefined {
    if (value === null || value === undefined) return undefined;

    const date: Date = (value as Timestamp).toDate();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public static toFirestore(value: Date | undefined): Timestamp | null {
    if (value === null || value === undefined) return null;

    value.setHours(0, 0, 0, 0);

    return Timestamp.fromDate(value);
  }

  public static fromOfflineCache(value: unknown): Date | undefined {
    if (value === null || value === undefined) return undefined;

    const date: Date = new Date(value as string);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  public static toOfflineCache(value: Date | undefined): string | null {
    if (value === null || value === undefined) return null;

    value.setHours(0, 0, 0, 0);

    return value.toISOString();
  }

  public static toStringValue(value: Date | undefined, defaultValue: string, dateFormat = "yyyy-MM-dd"): string {
    if (value === null || value === undefined) return defaultValue;

    return format(value, dateFormat);
  }
}
