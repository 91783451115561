import { SearchModule } from "../objects/SearchModule";
import { searchModules } from "../objects/searchModules";

export class SearchHelpers {
  public static registerModule(module: SearchModule): void {
    if (searchModules.includes(module) === false) {
      searchModules.push(module);
    }
  }
}
