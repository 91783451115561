import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";

export const isPeriodAvailableByCompany = async (
  employeeId: string,
  from: Date | undefined,
  to: Date | undefined,
  employeeDutyId = "empty",
  companyId = "empty"
): Promise<boolean> => {
  try {
    if (companyId === undefined) return false;

    return CloudHelpers.runValidatorFunction(
      "featuresEmployeeDutyPeriodValidator",
      { employeeId: employeeId, from: from?.getTime() ?? null, to: to?.getTime() ?? null, companyId: companyId },
      employeeDutyId
    );
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeDutyModel.isPeriodAvailableByCompany", { employeeId, from, to, employeeDutyId, companyId }, error);
    return false;
  }
};
