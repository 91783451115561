import { Company } from "../objects/Company";
import { companyModel } from "../models/CompanyModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const companySearch: SearchModule = new SearchModule(
  "company.companies",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Company).name ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/companies/${firestoreDocument.id}`,
  "building",
  companyModel,
  [new SortCriteria("name", "asc", "string")]
);
