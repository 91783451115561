export const it = {
  referent: {
    addCompanyReferent: "Aggiungi referente aziendale",
    addSafetyReferent: "Aggiungi referente sicurezza",
    companyReferents: "Referenti aziendali",
    email: "Indirizzo e-mail",
    emailError: "Inserire un indirizzo e-mail valido",
    mobile: "Cellulare",
    name: "Nome",
    nameError: "Inserire un nome valido",
    phone: "Telefono",
    position: "Posizione",
    positionError: "Inserire una posizione valida",
    safetyReferents: "Referenti sicurezza",
  },
};
