import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { NullableNumberField, StringField } from "@/core/fields";

export class DisabilityItem {
  public id: string = DataHelpers.uniqueId();
  public type: string | undefined = undefined;
  public startYear: number | undefined = undefined;
  public percent: number | undefined = undefined;
  public cause: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): DisabilityItem {
    this.id = DataHelpers.uniqueId();

    this.type = StringField.fromFirestore(data.type);
    this.startYear = NullableNumberField.fromFirestore(data.startYear);
    this.percent = NullableNumberField.fromFirestore(data.percent);
    this.cause = StringField.fromFirestore(data.cause);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.startYear = NullableNumberField.toFirestore(this.startYear);
    firestoreData.percent = NullableNumberField.toFirestore(this.percent);
    firestoreData.cause = StringField.toFirestore(this.cause);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): DisabilityItem {
    this.id = DataHelpers.uniqueId();

    this.type = StringField.fromOfflineCache(data.type);
    this.startYear = NullableNumberField.fromOfflineCache(data.startYear);
    this.percent = NullableNumberField.fromOfflineCache(data.percent);
    this.cause = StringField.fromOfflineCache(data.cause);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.type = StringField.toOfflineCache(this.type);
    firestoreData.startYear = NullableNumberField.toOfflineCache(this.startYear);
    firestoreData.percent = NullableNumberField.toOfflineCache(this.percent);
    firestoreData.cause = StringField.toOfflineCache(this.cause);

    return firestoreData;
  }
}
