import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { EquilibriumSurveyForm } from "../objects/EquilibriumSurveyForm";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class EquilibriumSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private equilibriumSurveyForm: EquilibriumSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    equilibriumSurveyForm: EquilibriumSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.equilibriumSurveyForm = equilibriumSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      equilibriumAnswerTable: { fontSize: 8 },
      equilibriumAnswerValue: { alignment: "center", bold: true },
      equilibriumChoiceLabel: { color: this.textColor, fontSize: 10 },
      equilibriumChoiceValue: { color: this.textColor, bold: true, fontSize: 10 },
      equilibriumQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("QUESTIONARIO ANAMNESTICO PER L'INDIVIDUAZIONE DEI DISTURBI DELL'EQUILIBRIO", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle("Anamnesi patologica mirata");
    this.writeDoubleChoice(
      this.t("surveyForm.equilibriumSurveyForm.question1"),
      this.equilibriumSurveyForm.question1,
      this.t("surveyForm.equilibriumSurveyForm.question2"),
      this.equilibriumSurveyForm.question2
    );
    this.writeDoubleChoice(
      this.t("surveyForm.equilibriumSurveyForm.question3"),
      this.equilibriumSurveyForm.question3,
      this.t("surveyForm.equilibriumSurveyForm.question4"),
      this.equilibriumSurveyForm.question4
    );
    this.writeDoubleChoice(
      this.t("surveyForm.equilibriumSurveyForm.question5"),
      this.equilibriumSurveyForm.question5,
      this.t("surveyForm.equilibriumSurveyForm.question6"),
      this.equilibriumSurveyForm.question6
    );
    this.writeDoubleChoice(
      this.t("surveyForm.equilibriumSurveyForm.question7"),
      this.equilibriumSurveyForm.question7,
      this.t("surveyForm.equilibriumSurveyForm.question8"),
      this.equilibriumSurveyForm.question8
    );
    this.writeChoice(this.t("surveyForm.equilibriumSurveyForm.question9"), this.equilibriumSurveyForm.question9);
    this.writeChoiceWithDetails(
      this.t("surveyForm.equilibriumSurveyForm.question10"),
      this.equilibriumSurveyForm.question10,
      this.equilibriumSurveyForm.question10Details
    );

    this.writeSubtitle("Anamnesi vestibolare");
    this.writeDoubleChoice(
      this.t("surveyForm.equilibriumSurveyForm.question11"),
      this.equilibriumSurveyForm.question11,
      this.t("surveyForm.equilibriumSurveyForm.question12"),
      this.equilibriumSurveyForm.question12
    );
    this.writeChoice(this.t("surveyForm.equilibriumSurveyForm.question13"), this.equilibriumSurveyForm.question13);
    this.writeChoiceWithDetails(
      this.t("surveyForm.equilibriumSurveyForm.question14"),
      this.equilibriumSurveyForm.question14,
      this.equilibriumSurveyForm.question14Details
    );

    this.writeSubtitle("Esami clinici posturali non strumentali");
    this.writeColumnWithTwoChoices(15, ["Negativo", "Positivo"]);
    this.writeColumnWithTwoChoices(16, ["Negativo", "Positivo"]);
    this.writeColumnWithTwoChoices(17, ["Negativo", "Positivo"]);
    this.writeColumnWithTwoChoices(18, ["Negativo", "Positivo"]);
    this.writeColumnWithTwoChoices(19, ["Negativo", "Positivo"]);

    this.writeSubtitle("Conclusioni diagnostiche");
    this.writeEnding(
      ["Normale funzione dell'equilibrio", "Probabili alterazioni vestibolari", "Necessita di ulteriori accertamenti specialistici"],
      this.equilibriumSurveyForm.question20
    );

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeChoice(question: string, answer: boolean | undefined): void {
    const answerTableBody: unknown[] = [[{ text: answer === true ? "X" : " ", style: "equilibriumChoiceValue", border: [true, true, true, true] }]];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question,
          style: "equilibriumChoiceLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "equilibriumChoiceValue",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeChoiceWithDetails(question: string, answer: boolean | undefined, details: string | undefined): void {
    const answerTableBody: unknown[] = [[{ text: answer === true ? "X" : " ", style: "equilibriumChoiceValue", border: [true, true, true, true] }]];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question,
          style: "equilibriumChoiceLabel",
        },
        {
          width: DataHelpers.mmToPoints(10),
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "equilibriumChoiceValue",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [DataHelpers.mmToPoints(10), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: "*",
          text: details ?? "",
          style: "equilibriumChoiceLabel",
        },
      ],
    });
  }

  private writeDoubleChoice(question1: string, answer1: boolean | undefined, question2: string, answer2: boolean | undefined): void {
    const answer1TableBody: unknown[] = [[{ text: answer1 === true ? "X" : " ", style: "equilibriumChoiceValue", border: [true, true, true, true] }]];
    const answer2TableBody: unknown[] = [[{ text: answer2 === true ? "X" : " ", style: "equilibriumChoiceValue", border: [true, true, true, true] }]];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question1,
          style: "equilibriumChoiceLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "equilibriumChoiceValue",
          table: {
            body: answer1TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(60),
          text: question2,
          style: "equilibriumChoiceLabel",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "equilibriumChoiceValue",
          table: {
            body: answer2TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeColumnWithTwoChoices(questionIndex: number, choices: string[]): void {
    const answer: boolean | undefined = this.equilibriumSurveyForm[`question${questionIndex}` as keyof EquilibriumSurveyForm] as boolean | undefined;
    const answerTableBody: unknown[] = [
      [
        { text: answer === false ? "X" : "", style: "equilibriumAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === true ? "X" : "", style: "equilibriumAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(70),
          text: this.t(`surveyForm.equilibriumSurveyForm.question${questionIndex}`),
          style: "equilibriumQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          style: "equilibriumAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeEnding(choices: string[], answer: string | undefined): void {
    for (const choice of choices) {
      const answerTableBody: unknown[] = [
        [{ text: answer === choice ? "X" : " ", style: "equilibriumChoiceValue", border: [true, true, true, true] }],
      ];

      (this.docDefinition.content as Record<string, unknown>[]).push({
        columns: [
          {
            margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
            width: DataHelpers.mmToPoints(100),
            text: choice,
            style: "equilibriumChoiceLabel",
          },
          {
            width: "*",
            margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
            style: "equilibriumChoiceValue",
            table: {
              body: answerTableBody,
              headerRows: 0,
              widths: [DataHelpers.mmToPoints(3)],
            },
            layout: {
              hLineWidth: () => 0.5,
              hLineColor: () => "black",
            },
          },
        ],
      });
    }
  }
}
