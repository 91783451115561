import { RouteRecordRaw } from "vue-router";

export const companyCostListRoutes: RouteRecordRaw[] = [
  {
    path: "/companies/:companyId/cost-lists/:companyCostListId/edit",
    name: "CompanyCostListEdit",
    component: () => import("../views/CompanyCostListEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "companyCostList",
      requiredRight: "viewUI",
    },
  },
];
