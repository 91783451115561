import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { OfflineEdit } from "../objects/OfflineEdit";
import { OfflineModuleModel } from "../objects/OfflineModuleModel";
import { offlineModuleModels } from "../objects/offlineModuleModels";

export class OfflineHelpers {
  public static async getPreviousOfflineEdit(collectionName: string, id: string): Promise<OfflineEdit | undefined> {
    try {
      const offlineEdits: OfflineEdit[] = (await indexedDbModel.getDocuments("offlineEdits")).map((rawOfflineEdit) => {
        const offlineEdit: OfflineEdit = new OfflineEdit();
        return offlineEdit.fromOfflineCache(rawOfflineEdit);
      });

      for (const offlineEdit of offlineEdits) {
        if (offlineEdit.collectionName === collectionName && offlineEdit.id === id) return offlineEdit;
      }
      return undefined;
    } catch (error: unknown) {
      appFaultModel.catchAppError("OfflineHelpers.getPreviousOfflineEdit", { collectionName, id }, error);
      return undefined;
    }
  }

  public static registerModule(offlineModuleModel: OfflineModuleModel<FirestoreOfflineDocument>): void {
    if (offlineModuleModels.includes(offlineModuleModel) === false) {
      offlineModuleModels.push(offlineModuleModel);
    }
  }
}
