import { eventBus } from "@/core/modules/eventBus/EventBus";

import examinationEndingTags from "@/features/data/examinationEndingTags.json";

export const renderEndingTags = (): Record<string, unknown>[] => {
  const tagItems: Record<string, unknown>[] = examinationEndingTags;

  for (const tagItem of tagItems) {
    applyTagCommand(tagItem, []);
  }

  return tagItems;
};

function applyTagCommand(tagItem: Record<string, unknown>, pieces: string[]): void {
  if (tagItem.command !== undefined) return;
  if (tagItem.label === undefined) return;

  pieces.push(tagItem.label as string);

  if (tagItem.items === undefined || (tagItem.items as Record<string, unknown>[]).length === 0) {
    tagItem.tag = pieces.join("%");
    tagItem.command = () => {
      eventBus.emit("examinationEndingTagClicked", tagItem.tag as string);
    };
  } else {
    for (const subTagItem of tagItem.items as Record<string, unknown>[]) {
      applyTagCommand(subTagItem, [...pieces]);
    }
  }
}
