import { confirmPasswordReset } from "firebase/auth";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

export const recoverPassword = async (code: string, newPassword: string): Promise<void> => {
  try {
    await confirmPasswordReset(firebase.auth, code, newPassword);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AuthModel.recoverPassword", { code, newPassword: "HIDDEN" }, error);
    throw new Error("Unable to recover password");
  }
};
