import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class BrokerDocHelpers {
  public static getFolderPath(brokerId: string | undefined): string {
    try {
      if (brokerId === undefined) throw new Error("brokerId is undefined");
      return `brokers/${brokerId}/docs/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("BrokerDocHelpers.getFolderPath", { brokerId }, error);
      throw error;
    }
  }
}
