import { Query } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreHelpers } from "@/core/modules/firestore/helpers/FirestoreHelpers";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { User } from "@/core/modules/user/objects/User";

export const getDocuments = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  sortCriterias: SortCriteria[] = [],
  parentId?: string,
  customQuery?: Query<T>
): Promise<T[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    let documents: T[] = [];

    if (offlineModel.getOfflineState() === "offline") {
      // offline mode
      documents = (await FirestoreHelpers.getOfflineDocuments(user, model as FirestoreModel<FirestoreOfflineDocument>, parentId)) as T[];
    } else if (user.canReadAll(model.roleModule) === true) {
      // user can read all documents
      documents = await FirestoreHelpers.getOnlineAllDocuments(model, parentId);
    } else if (user.canReadOwned(model.roleModule) === true) {
      // user can read only documents created by him
      documents = await FirestoreHelpers.getOnlineOwnDocuments(model, parentId);
    } else if (user.canReadCustom(model.roleModule) === true) {
      // user can read only documents with a custom query
      if (customQuery === undefined) {
        // if the custom query is undefined, probably no change in query is needed
        documents = await FirestoreHelpers.getOnlineAllDocuments(model, parentId);
      } else {
        documents = await FirestoreHelpers.getOnlineCustomDocuments(customQuery);
      }
    }

    if (sortCriterias.length === 0) return documents;

    // sort documents
    const firestoreSorter: FirestoreSorter<T> = new FirestoreSorter(documents);
    firestoreSorter.setSortCriterias(sortCriterias);
    return firestoreSorter.sort();
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.getDocuments", { model, sortCriterias, parentId }, error);
    return [];
  }
};
