import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { offlineModuleModels } from "@/core/modules/offline/objects/offlineModuleModels";

export const setCache = async (): Promise<void> => {
  try {
    let index = 1;
    for (const offlineModuleModel of offlineModuleModels) {
      const documents: FirestoreOfflineDocument[] = await offlineModuleModel.downloadCache();

      for (const document of documents) {
        console.log(index, offlineModuleModel.collectionName, document.id);
        await indexedDbModel.setDocument(offlineModuleModel.collectionName, document.id, document.toOfflineCache());
        index++;
      }
    }

    localStorage.setItem("koruCacheTimestamp", JSON.stringify(new Date()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.setCache", {}, error);
  }
};
