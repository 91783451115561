import { RouteRecordRaw } from "vue-router";

export const userRoutes: RouteRecordRaw[] = [
  {
    path: "/users",
    name: "UserList",
    component: () => import("../views/UserList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "user",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/users/:id/edit",
    name: "UserEdit",
    component: () => import("../views/UserEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "user",
      requiredRight: "viewUI",
    },
  },
];
