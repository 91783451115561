export const de = {
  appFault: {
    appFault: "App-Fehler",
    appFaults: "App-Fehler",
    createdAt: "Datum und Uhrzeit",
    message: "Nachricht",
    noDetails: "Keine Details verfügbar",
    notifications: {
      appFaultCreated: "App-Fehler erstellt",
      groupName: "App-Fehler",
    },
    stack: "Details",
    user: "Benutzer",
  },
  buttons: {
    deleteAll: "Alle löschen",
    showDetails: "Details anzeigen",
  },
  navigation: {
    appFault: "App-Fehler",
  },
};
