export const de = {
  feedback: {
    feedback: "Feedback",
    message: "Nachricht",
    messageError: "Bitte geben Sie Ihre Nachricht ein",
    subject: "Betreff",
    subjectError: "Bitte Betreff eingeben",
  },
  navigation: {
    feedback: "Feedback",
  },
};
