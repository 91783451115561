
import { defineComponent } from "vue";

import FirmSelector from "@/features/modules/firm/components/FirmSelector.vue";

export default defineComponent({
  name: "TopBarLeftHolder",
  components: {
    FirmSelector,
  },
});
