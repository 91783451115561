import { RouteRecordRaw } from "vue-router";

export const backupRoutes: RouteRecordRaw[] = [
  {
    path: "/backups",
    name: "BackupList",
    component: () => import("../views/BackupList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "backup",
      requiredRight: "viewUI",
    },
  },
];
