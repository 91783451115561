<template>
  <div :class="containerClass" :style="style">
    <PVSButton
      type="button"
      v-bind="$attrs"
      :icon="icon"
      :label="label"
      @click="onDropdownButtonClick"
      :disabled="$attrs.disabled"
      aria-haspopup="true"
      :aria-controls="ariaId + '_overlay'"
      class="p-button-primary"
    />
    <PVSMenu
      :id="ariaId + '_overlay'"
      ref="menu"
      :reference="reference"
      :model="model"
      :popup="true"
      :autoZIndex="autoZIndex"
      :baseZIndex="baseZIndex"
      :appendTo="appendTo"
    />
  </div>
</template>

<script>
import Button from "primevue/button";
import { UniqueComponentId } from "primevue/utils";

import KoruMenu from "../KoruMenu/KoruMenu";

export default {
  name: "KoruActionButton",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "pi pi-ellipsis-h",
    },
    model: {
      type: Array,
      default: null,
    },
    reference: {
      type: Object,
      default: null,
    },
    autoZIndex: {
      type: Boolean,
      default: true,
    },
    baseZIndex: {
      type: Number,
      default: 0,
    },
    appendTo: {
      type: String,
      default: "body",
    },
    class: null,
    style: null,
  },
  methods: {
    onDropdownButtonClick() {
      this.$refs.menu.toggle({ currentTarget: this.$el });
    },
    onDefaultButtonClick() {
      this.$refs.menu.hide();
    },
  },
  computed: {
    ariaId() {
      return UniqueComponentId();
    },
    containerClass() {
      return ["p-actionbutton p-component", this.class];
    },
  },
  components: {
    PVSButton: Button,
    PVSMenu: KoruMenu,
  },
};
</script>

<style scoped>
.p-actionbutton {
  display: inline-flex;
  position: relative;
}

.p-actionbutton .p-actionbutton-defaultbutton {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-actionbutton-menubutton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-actionbutton .p-menu {
  min-width: 100%;
}

.p-fluid .p-actionbutton {
  display: flex;
}
</style>
