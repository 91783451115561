export const de = {
  guide: {
    browse: "Durchsuchen Sie die verfügbaren Anleitungen",
    description: "Beschreibung",
    descriptionError: "Geben Sie eine Beschreibung ein",
    guide: "Anleitung",
    guides: "Anleitungen",
    isEnabled: "Aktiviert",
    isEnabledError: "Wähle einen Wert",
    noGuides: "Keine Anleitungen verfügbar",
    title: "Titel",
    titleError: "Geben Sie einen Titel ein",
  },
  navigation: {
    guide: "Anleitungen",
  },
  toast: {
    error: {
      guideFileError: "Fehler beim Lesen der Datei",
      guideFileUndefined: "Datei nicht gefunden",
    },
    success: {},
    warning: {},
  },
};
