import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { PaymentItem } from "./PaymentItem";
import { PriceListItem } from "./PriceListItem";
import { PriceListItemType } from "./PriceListItemType";
import { YearlyPaymentType } from "./YearlyPaymentType";

import { ArrayField, EnumField, NumberField, ObjectField, StringArrayField, StringField } from "@/core/fields";

export class PriceList extends FirestoreDocument {
  public firm: LinkedFirm | undefined = undefined;
  public name: string | undefined = undefined;
  public prices: PriceListItem[] = [];
  public discountValue = 0;
  public yearlyPaymentType: YearlyPaymentType = YearlyPaymentType.None;
  public yearlyPaymentValue = 0;
  public payments: PaymentItem[] = [];
  public examTypesIds: string[] = [];
  public testTypesIds: string[] = [];
  public surveysIds: string[] = [];
  public serviceTypesIds: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): PriceList {
    super.fromFirestore(data, id, firestoreRef);

    this.firm = ObjectField.fromFirestore(data.firm, (value) => new LinkedFirm(value));
    this.name = StringField.fromFirestore(data.name);
    this.prices = ArrayField.fromFirestore(data.prices, (value) => new PriceListItem(value));
    this.discountValue = NumberField.fromFirestore(data.discountValue);
    this.yearlyPaymentType = EnumField.fromFirestore<YearlyPaymentType>(
      data.yearlyPaymentType,
      Object.values(YearlyPaymentType),
      YearlyPaymentType.None
    );
    this.yearlyPaymentValue = NumberField.fromFirestore(data.yearlyPaymentValue);
    this.payments = ArrayField.fromFirestore(data.payments, (value) => new PaymentItem(value));
    this.examTypesIds = StringArrayField.fromFirestore(data.examTypesIds);
    this.testTypesIds = StringArrayField.fromFirestore(data.testTypesIds);
    this.surveysIds = StringArrayField.fromFirestore(data.surveysIds);
    this.serviceTypesIds = StringArrayField.fromFirestore(data.serviceTypesIds);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    let examTypesIds: string[] = [];
    let testTypesIds: string[] = [];
    let surveysIds: string[] = [];
    let serviceTypesIds: string[] = [];
    if (this.prices != null) {
      for (const price of this.prices) {
        if (price.type === PriceListItemType.ExamType) examTypesIds = [...examTypesIds, ...(price.itemsIds as string[])];
        if (price.type === PriceListItemType.TestType) testTypesIds = [...testTypesIds, ...(price.itemsIds as string[])];
        if (price.type === PriceListItemType.Survey) surveysIds = [...surveysIds, ...(price.itemsIds as string[])];
        if (price.type === PriceListItemType.ServiceType) serviceTypesIds = [...serviceTypesIds, ...(price.itemsIds as string[])];
      }

      examTypesIds = examTypesIds.filter((item, index) => examTypesIds.indexOf(item) === index);
      testTypesIds = testTypesIds.filter((item, index) => testTypesIds.indexOf(item) === index);
      surveysIds = surveysIds.filter((item, index) => surveysIds.indexOf(item) === index);
      serviceTypesIds = serviceTypesIds.filter((item, index) => serviceTypesIds.indexOf(item) === index);
    }

    firestoreData.firm = ObjectField.toFirestore(this.firm, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.prices = ArrayField.toFirestore(this.prices, (value) => value.toFirestore());
    firestoreData.discountValue = NumberField.toFirestore(this.discountValue);
    firestoreData.yearlyPaymentType = EnumField.toFirestore<YearlyPaymentType>(this.yearlyPaymentType, YearlyPaymentType.None);
    firestoreData.yearlyPaymentValue = NumberField.toFirestore(this.yearlyPaymentValue);
    firestoreData.payments = ArrayField.toFirestore(this.payments, (value) => value.toFirestore());
    firestoreData.examTypesIds = examTypesIds;
    firestoreData.testTypesIds = testTypesIds;
    firestoreData.surveysIds = surveysIds;
    firestoreData.serviceTypesIds = serviceTypesIds;

    return firestoreData;
  }

  public async setFirm(): Promise<void> {
    const firmId: string = firmModel.getSelectedFirmId();
    const linkedFirm: LinkedFirm = LinkedFirm.createFromFirm(await firmModel.getSelectedFirm());
    if (firmId == undefined || linkedFirm == undefined) throw new Error("createPriceListNoFirm");

    this.firm = linkedFirm;
  }

  public sortPayments(): void {
    if (this.payments.length > 0) {
      this.payments.sort((a, b) => (a.daysToExpiration > b.daysToExpiration ? 1 : b.daysToExpiration > a.daysToExpiration ? -1 : 0));
    }
  }
}
