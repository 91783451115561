import { RouteRecordRaw } from "vue-router";

export const costListRoutes: RouteRecordRaw[] = [
  {
    path: "/cost-lists",
    name: "CostListList",
    component: () => import("../views/CostListList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "costList",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/cost-lists/:id/edit",
    name: "CostListEdit",
    component: () => import("../views/CostListEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "costList",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/cost-lists/:id",
    name: "CostListView",
    component: () => import("../views/CostListView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "costList",
      requiredRight: "viewUI",
    },
  },
];
