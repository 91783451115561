import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Locale } from "@/core/modules/locale/objects/Locale";
import { localeModel } from "../LocaleModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getSelectedLocale = (): Locale => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    const defaultLocale: Locale = localeModel.getLocales()[0];

    if (user === undefined) return defaultLocale;

    const selectedLocale: Locale | undefined = localeModel.getLocales().find((locale: Locale) => locale.name === user.detail.locale);

    if (selectedLocale === undefined) return defaultLocale;

    return selectedLocale;
  } catch (error: unknown) {
    appFaultModel.catchAppError("LocaleModel.getSelectedLocale", {}, error);
    return new Locale("error", "error", "error");
  }
};
