import { Employee } from "./Employee";
import { EmployeeSex } from "./EmployeeSex";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { DateField, EnumField, StringField } from "@/core/fields";

export class LinkedEmployee extends LinkedFirestoreOfflineDocument {
  public lastName: string | undefined = undefined;
  public firstName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public birthDate: Date | undefined = undefined;
  public sex: EmployeeSex = EmployeeSex.Male;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromEmployee(employee: Employee): LinkedEmployee {
    const linkedEmployee: LinkedEmployee = new LinkedEmployee();
    linkedEmployee.id = employee.id;
    linkedEmployee.lastName = employee.lastName;
    linkedEmployee.firstName = employee.firstName;
    linkedEmployee.fullName = employee.fullName;
    linkedEmployee.birthDate = employee.birthDate;
    linkedEmployee.sex = employee.sex;

    return linkedEmployee;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedEmployee {
    super.fromFirestore(data);

    this.lastName = StringField.fromFirestore(data.lastName);
    this.firstName = StringField.fromFirestore(data.firstName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.birthDate = DateField.fromFirestore(data.birthDate);
    this.sex = EnumField.fromFirestore<EmployeeSex>(data.sex, Object.values(EmployeeSex), EmployeeSex.Male);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.birthDate = DateField.toFirestore(this.birthDate);
    firestoreData.sex = EnumField.toFirestore<EmployeeSex>(this.sex, EmployeeSex.Male);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedEmployee {
    super.fromOfflineCache(data);

    this.lastName = StringField.fromOfflineCache(data.lastName);
    this.firstName = StringField.fromOfflineCache(data.firstName);
    this.fullName = StringField.fromOfflineCache(data.fullName);
    this.birthDate = DateField.fromOfflineCache(data.birthDate);
    this.sex = EnumField.fromOfflineCache<EmployeeSex>(data.sex, Object.values(EmployeeSex), EmployeeSex.Male);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.lastName = StringField.toOfflineCache(this.lastName);
    firestoreData.firstName = StringField.toOfflineCache(this.firstName);
    firestoreData.fullName = StringField.toOfflineCache(this.fullName);
    firestoreData.birthDate = DateField.toOfflineCache(this.birthDate);
    firestoreData.sex = EnumField.toOfflineCache<EmployeeSex>(this.sex, EmployeeSex.Male);

    return firestoreData;
  }
}
