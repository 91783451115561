import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { NumberField, StringField } from "@/core/fields";

export class StorageFile {
  public id: string = DataHelpers.uniqueId();
  public uniqueName: string | undefined = undefined;
  public displayName: string | undefined = undefined;
  public type: string | undefined = undefined;
  public size = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): StorageFile {
    this.id = DataHelpers.uniqueId();

    this.uniqueName = StringField.fromFirestore(data.uniqueName);
    this.displayName = StringField.fromFirestore(data.displayName);
    this.type = StringField.fromFirestore(data.type);
    this.size = NumberField.fromFirestore(data.size);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.uniqueName = StringField.toFirestore(this.uniqueName);
    firestoreData.displayName = StringField.toFirestore(this.displayName);
    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.size = NumberField.toFirestore(this.size);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): StorageFile {
    this.id = DataHelpers.uniqueId();

    this.uniqueName = StringField.fromOfflineCache(data.uniqueName);
    this.displayName = StringField.fromOfflineCache(data.displayName);
    this.type = StringField.fromOfflineCache(data.type);
    this.size = NumberField.fromOfflineCache(data.size);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const offlineCacheData: Record<string, unknown> = {};

    offlineCacheData.uniqueName = StringField.toOfflineCache(this.uniqueName);
    offlineCacheData.displayName = StringField.toOfflineCache(this.displayName);
    offlineCacheData.type = StringField.toOfflineCache(this.type);
    offlineCacheData.size = NumberField.toOfflineCache(this.size);

    return offlineCacheData;
  }
}
