import { DocumentReference } from "firebase/firestore";

import { companyModel } from "../models/CompanyModel";
import { CompanyType } from "./CompanyType";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedActivity } from "@/features/modules/activity/objects/LinkedActivity";
import { LinkedBroker } from "@/features/modules/broker/objects/LinkedBroker";
import { LinkedContract } from "@/features/modules/contract/objects/LinkedContract";

import { ArrayByKeyField, BooleanField, DateField, EnumField, ObjectField, StringArrayField, StringField } from "@/core/fields";
import { ActiveContractsRecord } from "@/features/fields";

export class Company extends FirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public email: string | undefined = undefined;
  public type: CompanyType = CompanyType.Private;
  public broker: LinkedBroker | undefined = undefined;
  public contractCode: string | undefined = undefined;
  public orderCode: string | undefined = undefined;
  public cupCode: string | undefined = undefined;
  public cigCode: string | undefined = undefined;
  public activities: Record<string, LinkedActivity> = {};
  public notes: string | undefined = undefined;
  public archived = false;
  public archivedDate: Date | undefined = undefined;
  public trackExpirations = true;
  public activeContracts: ActiveContractsRecord = new ActiveContractsRecord();
  public authorizedDoctorsIds: string[] = [];
  public userId: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Company {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.email = StringField.fromFirestore(data.email);
    this.type = EnumField.fromFirestore<CompanyType>(data.type, Object.values(CompanyType), CompanyType.Private);
    this.broker = ObjectField.fromFirestore<LinkedBroker>(data.broker, (value) => new LinkedBroker(value));
    this.contractCode = StringField.fromFirestore(data.contractCode);
    this.orderCode = StringField.fromFirestore(data.orderCode);
    this.cupCode = StringField.fromFirestore(data.cupCode);
    this.cigCode = StringField.fromFirestore(data.cigCode);
    this.activities = ArrayByKeyField.fromFirestore<LinkedActivity>(data.activities, (value) => new LinkedActivity(value));
    this.notes = StringField.fromFirestore(data.notes);
    this.archived = BooleanField.fromFirestore(data.archived);
    this.archivedDate = DateField.fromFirestore(data.archivedDate);
    this.trackExpirations = BooleanField.fromFirestore(data.trackExpirations);
    this.activeContracts.fromFirestore(data.activeContracts);
    this.authorizedDoctorsIds = StringArrayField.fromFirestore(data.authorizedDoctorsIds);
    this.userId = StringField.fromFirestore(data.userId);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.type = EnumField.toFirestore<CompanyType>(this.type, CompanyType.Private);
    firestoreData.broker = ObjectField.toFirestore<LinkedBroker>(this.broker, (value) => value.toFirestore());
    firestoreData.contractCode = StringField.toFirestore(this.contractCode);
    firestoreData.orderCode = StringField.toFirestore(this.orderCode);
    firestoreData.cupCode = StringField.toFirestore(this.cupCode);
    firestoreData.cigCode = StringField.toFirestore(this.cigCode);
    firestoreData.activities = ArrayByKeyField.toFirestore<LinkedActivity>(this.activities, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.archived = BooleanField.toFirestore(this.archived);
    firestoreData.archivedDate = DateField.toFirestore(this.archivedDate);
    firestoreData.trackExpirations = BooleanField.toFirestore(this.trackExpirations);
    firestoreData.activeContracts = this.activeContracts.toFirestore();
    firestoreData.authorizedDoctorsIds = StringArrayField.toFirestore(this.authorizedDoctorsIds);
    firestoreData.userId = StringField.toFirestore(this.userId);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Company {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.email = StringField.fromOfflineCache(data.email);
    this.type = EnumField.fromOfflineCache<CompanyType>(data.type, Object.values(CompanyType), CompanyType.Private);
    this.broker = ObjectField.fromOfflineCache<LinkedBroker>(data.broker, (value) => {
      const linkedBroker: LinkedBroker = new LinkedBroker();
      return linkedBroker.fromFirestore(value);
    });
    this.contractCode = StringField.fromOfflineCache(data.contractCode);
    this.orderCode = StringField.fromOfflineCache(data.orderCode);
    this.cupCode = StringField.fromOfflineCache(data.cupCode);
    this.cigCode = StringField.fromOfflineCache(data.cigCode);
    this.activities = ArrayByKeyField.fromOfflineCache<LinkedActivity>(data.activities, (value) => {
      const linkedActivity: LinkedActivity = new LinkedActivity();
      return linkedActivity.fromFirestore(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);
    this.archived = BooleanField.fromOfflineCache(data.archived);
    this.archivedDate = DateField.fromOfflineCache(data.archivedDate);
    this.trackExpirations = BooleanField.fromOfflineCache(data.trackExpirations);
    this.activeContracts.fromOfflineCache(data.activeContracts);
    this.authorizedDoctorsIds = StringArrayField.fromOfflineCache(data.authorizedDoctorsIds);
    this.userId = StringField.fromOfflineCache(data.userId);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.email = StringField.toOfflineCache(this.email);
    firestoreData.type = EnumField.toOfflineCache<CompanyType>(this.type, CompanyType.Private);
    firestoreData.broker = ObjectField.toOfflineCache<LinkedBroker>(this.broker, (value) => value.toFirestore());
    firestoreData.contractCode = StringField.toOfflineCache(this.contractCode);
    firestoreData.orderCode = StringField.toOfflineCache(this.orderCode);
    firestoreData.cupCode = StringField.toOfflineCache(this.cupCode);
    firestoreData.cigCode = StringField.toOfflineCache(this.cigCode);
    firestoreData.activities = ArrayByKeyField.toOfflineCache<LinkedActivity>(this.activities, (value) => value.toFirestore());
    firestoreData.notes = StringField.toOfflineCache(this.notes);
    firestoreData.archived = BooleanField.toOfflineCache(this.archived);
    firestoreData.archivedDate = DateField.toOfflineCache(this.archivedDate);
    firestoreData.trackExpirations = BooleanField.toOfflineCache(this.trackExpirations);
    firestoreData.activeContracts = this.activeContracts.toOfflineCache();
    firestoreData.authorizedDoctorsIds = StringArrayField.toOfflineCache(this.authorizedDoctorsIds);
    firestoreData.userId = StringField.toOfflineCache(this.userId);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
  }

  public getActiveContractsByFirm(): LinkedContract[] {
    const firmId: string = firmModel.getSelectedFirmId();
    return this.activeContracts.getActiveContractsByFirm(firmId);
  }

  public async handleArchive(): Promise<void> {
    if (this.id === "new") {
      this.archivedDate = this.archived === true ? new Date() : undefined;
    } else {
      const oldCompany: Company = await companyModel.getDocument(this.id);
      if (oldCompany.archived !== this.archived) {
        this.archivedDate = this.archived === true ? new Date() : undefined;
      }
    }
  }
}
