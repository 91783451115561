import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { Supplier } from "@/features/modules/supplier/objects/Supplier";
import { supplierModel } from "../SupplierModel";
import { User } from "@/core/modules/user/objects/User";

export const isVatCodeUnique = async (vatCode: string, supplierId = "empty"): Promise<boolean> => {
  try {
    if (vatCode === undefined) return false;

    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (supplierModel.offlineModuleModel === undefined) return false;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Supplier[] = (await offlineModel.getCacheByCollection(supplierModel.offlineModuleModel)) as Supplier[];
      const filteredDocuments: Supplier[] = documents.filter((branch) => branch.vatCode === vatCode);
      return filteredDocuments.length === 0 || (filteredDocuments.length === 1 && filteredDocuments[0].id === supplierId);
    }

    return CloudHelpers.runValidatorFunction("featuresSupplierVatCodeValidator", vatCode, supplierId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("SupplierModel.isVatCodeUnique", { vatCode, supplierId }, error);
    return false;
  }
};
