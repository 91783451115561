import { compareAsc } from "date-fns";
import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedCostList } from "@/features/modules/costList/objects/LinkedCostList";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";

import { DateField, ObjectField, StringStrictField } from "@/core/fields";

export class CompanyCostList extends FirestoreDocument {
  public firm: LinkedFirm | undefined = undefined;
  public costList: LinkedCostList | undefined = undefined;
  public from: Date | undefined = undefined;
  public fromSort = "0000-00-00";
  public to: Date | undefined = undefined;
  public toSort = "9999-99-99";

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): CompanyCostList {
    super.fromFirestore(data, id, firestoreRef);

    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.costList = ObjectField.fromFirestore<LinkedCostList>(data.costList, (value) => new LinkedCostList(value));
    this.from = DateField.fromFirestore(data.from);
    this.fromSort = StringStrictField.fromFirestore(data.fromSort, "0000-00-00");
    this.to = DateField.fromFirestore(data.to);
    this.toSort = StringStrictField.fromFirestore(data.toSort, "9999-99-99");

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.costList = ObjectField.toFirestore<LinkedCostList>(this.costList, (value) => value.toFirestore());
    firestoreData.from = DateField.toFirestore(this.from);
    firestoreData.fromSort = DateField.toStringValue(this.from, "0000-00-00");
    firestoreData.to = DateField.toFirestore(this.to);
    firestoreData.toSort = DateField.toStringValue(this.to, "9999-99-99");

    return firestoreData;
  }

  public isCurrentlyActive(): boolean {
    const now: Date = new Date();
    if (this.from !== undefined && this.from !== null) {
      const from: Date = new Date(this.from.getTime());
      from.setHours(0, 0, 0, 0);
      if (compareAsc(from, now) > 0) return false;
    }
    if (this.to !== undefined && this.to !== null) {
      const to: Date = new Date(this.to.getTime());
      to.setHours(23, 59, 59, 999);
      if (compareAsc(now, to) > 0) return false;
    }
    return true;
  }
}
