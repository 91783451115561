import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";
import { Survey } from "./Survey";
import { SurveyForm } from "@/features/modules/surveyForm/objects/SurveyForm";

import { DateField, NumberWithDefaultField, ObjectField, StringField } from "@/core/fields";

import { AllergySurveyForm } from "@/features/modules/surveyForm/objects/AllergySurveyForm";
import { AuditShortSurveyForm } from "@/features/modules/surveyForm/objects/AuditShortSurveyForm";
import { AuditSurveyForm } from "@/features/modules/surveyForm/objects/AuditSurveyForm";
import { BronchitisSurveyForm } from "@/features/modules/surveyForm/objects/BronchitisSurveyForm";
import { EmSurveyForm } from "@/features/modules/surveyForm/objects/EmSurveyForm";
import { EquilibriumSurveyForm } from "@/features/modules/surveyForm/objects/EquilibriumSurveyForm";
import { GasSurveyForm } from "@/features/modules/surveyForm/objects/GasSurveyForm";
import { NoseSurveyForm } from "@/features/modules/surveyForm/objects/NoseSurveyForm";
import { RachisExamSurveyForm } from "@/features/modules/surveyForm/objects/RachisExamSurveyForm";
import { RachisSurveyForm } from "@/features/modules/surveyForm/objects/RachisSurveyForm";
import { UpperSurveyForm } from "@/features/modules/surveyForm/objects/UpperSurveyForm";
import { UpperExamSurveyForm } from "@/features/modules/surveyForm/objects/UpperExamSurveyForm";
import { VdtSurveyForm } from "@/features/modules/surveyForm/objects/VdtSurveyForm";

export class LinkedSurvey extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public frequency = 999;
  public formName: string | undefined = undefined;
  public order = 999;
  public date: Date | undefined = undefined;
  public surveyForm: SurveyForm | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromSurvey(survey: Survey): LinkedSurvey {
    const linkedSurvey: LinkedSurvey = new LinkedSurvey();
    linkedSurvey.id = survey.id;
    linkedSurvey.name = survey.name;
    linkedSurvey.frequency = survey.frequency;
    linkedSurvey.formName = survey.formName;

    return linkedSurvey;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedSurvey {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.frequency = NumberWithDefaultField.fromFirestore(data.frequency, 999);
    this.formName = StringField.fromFirestore(data.formName);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);
    this.date = DateField.fromFirestore(data.date);
    this.surveyFormFromFirestore(data);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.frequency = NumberWithDefaultField.toFirestore(this.frequency, 999);
    firestoreData.formName = StringField.toFirestore(this.formName);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);
    firestoreData.date = DateField.toFirestore(this.date);
    firestoreData.surveyForm = this.surveyFormToFirestore();

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedSurvey {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.frequency = NumberWithDefaultField.fromOfflineCache(data.frequency, 999);
    this.formName = StringField.fromOfflineCache(data.formName);
    this.order = NumberWithDefaultField.fromOfflineCache(data.order, 999);
    this.date = DateField.fromOfflineCache(data.date);
    this.surveyFormFromOfflineCache(data);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.frequency = NumberWithDefaultField.toOfflineCache(this.frequency, 999);
    firestoreData.formName = StringField.toOfflineCache(this.formName);
    firestoreData.order = NumberWithDefaultField.toOfflineCache(this.order, 999);
    firestoreData.date = DateField.toOfflineCache(this.date);
    firestoreData.surveyForm = this.surveyFormToOfflineCache();

    return firestoreData;
  }

  public createSurveyForm(): void {
    if (this.formName === undefined) return;
    if (this.formName === "allergySurveyForm") {
      this.surveyForm = new AllergySurveyForm();
    } else if (this.formName === "auditShortSurveyForm") {
      this.surveyForm = new AuditShortSurveyForm();
    } else if (this.formName === "auditSurveyForm") {
      this.surveyForm = new AuditSurveyForm();
    } else if (this.formName === "bronchitisSurveyForm") {
      this.surveyForm = new BronchitisSurveyForm();
    } else if (this.formName === "emSurveyForm") {
      this.surveyForm = new EmSurveyForm();
    } else if (this.formName === "equilibriumSurveyForm") {
      this.surveyForm = new EquilibriumSurveyForm();
    } else if (this.formName === "gasSurveyForm") {
      this.surveyForm = new GasSurveyForm();
    } else if (this.formName === "noseSurveyForm") {
      this.surveyForm = new NoseSurveyForm();
    } else if (this.formName === "rachisExamSurveyForm") {
      this.surveyForm = new RachisExamSurveyForm();
    } else if (this.formName === "rachisSurveyForm") {
      this.surveyForm = new RachisSurveyForm();
    } else if (this.formName === "upperExamSurveyForm") {
      this.surveyForm = new UpperExamSurveyForm();
    } else if (this.formName === "upperSurveyForm") {
      this.surveyForm = new UpperSurveyForm();
    } else if (this.formName === "vdtSurveyForm") {
      this.surveyForm = new VdtSurveyForm();
    }
  }

  public surveyFormFromFirestore(data: Record<string, unknown>): void {
    if (this.formName === undefined) {
      this.surveyForm = undefined;
      return;
    }
    if (this.formName === "allergySurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<AllergySurveyForm>(data.surveyForm, (value) => new AllergySurveyForm(value));
    } else if (this.formName === "auditShortSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<AuditShortSurveyForm>(data.surveyForm, (value) => new AuditShortSurveyForm(value));
    } else if (this.formName === "auditSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<AuditSurveyForm>(data.surveyForm, (value) => new AuditSurveyForm(value));
    } else if (this.formName === "bronchitisSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<BronchitisSurveyForm>(data.surveyForm, (value) => new BronchitisSurveyForm(value));
    } else if (this.formName === "emSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<EmSurveyForm>(data.surveyForm, (value) => new EmSurveyForm(value));
    } else if (this.formName === "equilibriumSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<EquilibriumSurveyForm>(data.surveyForm, (value) => new EquilibriumSurveyForm(value));
    } else if (this.formName === "gasSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<GasSurveyForm>(data.surveyForm, (value) => new GasSurveyForm(value));
    } else if (this.formName === "noseSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<NoseSurveyForm>(data.surveyForm, (value) => new NoseSurveyForm(value));
    } else if (this.formName === "rachisExamSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<RachisExamSurveyForm>(data.surveyForm, (value) => new RachisExamSurveyForm(value));
    } else if (this.formName === "rachisSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<RachisSurveyForm>(data.surveyForm, (value) => new RachisSurveyForm(value));
    } else if (this.formName === "upperExamSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<UpperExamSurveyForm>(data.surveyForm, (value) => new UpperExamSurveyForm(value));
    } else if (this.formName === "upperSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<UpperSurveyForm>(data.surveyForm, (value) => new UpperSurveyForm(value));
    } else if (this.formName === "vdtSurveyForm") {
      this.surveyForm = ObjectField.fromFirestore<VdtSurveyForm>(data.surveyForm, (value) => new VdtSurveyForm(value));
    }
  }

  public surveyFormToFirestore(): Record<string, unknown> | null {
    if (this.formName === undefined) return null;
    if (this.formName === "allergySurveyForm") {
      return ObjectField.toFirestore<AllergySurveyForm>(this.surveyForm as AllergySurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "auditShortSurveyForm") {
      return ObjectField.toFirestore<AuditShortSurveyForm>(this.surveyForm as AuditShortSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "auditSurveyForm") {
      return ObjectField.toFirestore<AuditSurveyForm>(this.surveyForm as AuditSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "bronchitisSurveyForm") {
      return ObjectField.toFirestore<BronchitisSurveyForm>(this.surveyForm as BronchitisSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "emSurveyForm") {
      return ObjectField.toFirestore<EmSurveyForm>(this.surveyForm as EmSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "equilibriumSurveyForm") {
      return ObjectField.toFirestore<EquilibriumSurveyForm>(this.surveyForm as EquilibriumSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "gasSurveyForm") {
      return ObjectField.toFirestore<GasSurveyForm>(this.surveyForm as GasSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "noseSurveyForm") {
      return ObjectField.toFirestore<NoseSurveyForm>(this.surveyForm as NoseSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "rachisExamSurveyForm") {
      return ObjectField.toFirestore<RachisExamSurveyForm>(this.surveyForm as RachisExamSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "rachisSurveyForm") {
      return ObjectField.toFirestore<RachisSurveyForm>(this.surveyForm as RachisSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "upperExamSurveyForm") {
      return ObjectField.toFirestore<UpperExamSurveyForm>(this.surveyForm as UpperExamSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "upperSurveyForm") {
      return ObjectField.toFirestore<UpperSurveyForm>(this.surveyForm as UpperSurveyForm, (value) => value.toFirestore());
    } else if (this.formName === "vdtSurveyForm") {
      return ObjectField.toFirestore<VdtSurveyForm>(this.surveyForm as VdtSurveyForm, (value) => value.toFirestore());
    }
    return null;
  }

  public surveyFormFromOfflineCache(data: Record<string, unknown>): void {
    if (this.formName === undefined) {
      this.surveyForm = undefined;
      return;
    }
    if (this.formName === "allergySurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<AllergySurveyForm>(data.surveyForm, (value) => {
        const allergySurveyForm = new AllergySurveyForm();
        return allergySurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "auditShortSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<AuditShortSurveyForm>(data.surveyForm, (value) => {
        const auditShortSurveyForm = new AuditShortSurveyForm();
        return auditShortSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "auditSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<AuditSurveyForm>(data.surveyForm, (value) => {
        const auditSurveyForm = new AuditSurveyForm();
        return auditSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "bronchitisSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<BronchitisSurveyForm>(data.surveyForm, (value) => {
        const bronchitisSurveyForm = new BronchitisSurveyForm();
        return bronchitisSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "emSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<EmSurveyForm>(data.surveyForm, (value) => {
        const emSurveyForm = new EmSurveyForm();
        return emSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "equilibriumSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<EquilibriumSurveyForm>(data.surveyForm, (value) => {
        const equilibriumSurveyForm = new EquilibriumSurveyForm();
        return equilibriumSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "gasSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<GasSurveyForm>(data.surveyForm, (value) => {
        const gasSurveyForm = new GasSurveyForm();
        return gasSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "noseSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<NoseSurveyForm>(data.surveyForm, (value) => {
        const noseSurveyForm = new NoseSurveyForm();
        return noseSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "rachisExamSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<RachisExamSurveyForm>(data.surveyForm, (value) => {
        const rachisExamSurveyForm = new RachisExamSurveyForm();
        return rachisExamSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "rachisSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<RachisSurveyForm>(data.surveyForm, (value) => {
        const rachisSurveyForm = new RachisSurveyForm();
        return rachisSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "upperSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<UpperSurveyForm>(data.surveyForm, (value) => {
        const upperSurveyForm = new UpperSurveyForm();
        return upperSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "upperExamSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<UpperExamSurveyForm>(data.surveyForm, (value) => {
        const upperExamSurveyForm = new UpperExamSurveyForm();
        return upperExamSurveyForm.fromOfflineCache(value);
      });
    } else if (this.formName === "vdtSurveyForm") {
      this.surveyForm = ObjectField.fromOfflineCache<VdtSurveyForm>(data.surveyForm, (value) => {
        const vdtSurveyForm = new VdtSurveyForm();
        return vdtSurveyForm.fromOfflineCache(value);
      });
    }
  }

  public surveyFormToOfflineCache(): Record<string, unknown> | null {
    if (this.formName === undefined) return null;
    if (this.formName === "allergySurveyForm") {
      return ObjectField.toOfflineCache<AllergySurveyForm>(this.surveyForm as AllergySurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "auditShortSurveyForm") {
      return ObjectField.toOfflineCache<AuditShortSurveyForm>(this.surveyForm as AuditShortSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "auditSurveyForm") {
      return ObjectField.toOfflineCache<AuditSurveyForm>(this.surveyForm as AuditSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "bronchitisSurveyForm") {
      return ObjectField.toOfflineCache<BronchitisSurveyForm>(this.surveyForm as BronchitisSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "emSurveyForm") {
      return ObjectField.toOfflineCache<EmSurveyForm>(this.surveyForm as EmSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "equilibriumSurveyForm") {
      return ObjectField.toOfflineCache<EquilibriumSurveyForm>(this.surveyForm as EquilibriumSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "gasSurveyForm") {
      return ObjectField.toOfflineCache<GasSurveyForm>(this.surveyForm as GasSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "noseSurveyForm") {
      return ObjectField.toOfflineCache<NoseSurveyForm>(this.surveyForm as NoseSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "rachisExamSurveyForm") {
      return ObjectField.toOfflineCache<RachisExamSurveyForm>(this.surveyForm as RachisExamSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "rachisSurveyForm") {
      return ObjectField.toOfflineCache<RachisSurveyForm>(this.surveyForm as RachisSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "upperExamSurveyForm") {
      return ObjectField.toOfflineCache<UpperExamSurveyForm>(this.surveyForm as UpperExamSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "upperSurveyForm") {
      return ObjectField.toOfflineCache<UpperSurveyForm>(this.surveyForm as UpperSurveyForm, (value) => value.toOfflineCache());
    } else if (this.formName === "vdtSurveyForm") {
      return ObjectField.toOfflineCache<VdtSurveyForm>(this.surveyForm as VdtSurveyForm, (value) => value.toOfflineCache());
    }
    return null;
  }
}
