export const de = {
  profile: {
    changeEmail: "E-Mail ändern",
    changePassword: "Passwort ändern",
    changePasswordButton: "Passwort ändern",
    email: "E-Mail-Adresse",
    oldPassword: "Altes Passwort",
    oldPasswordBlank: "Bitte geben Sie Ihr altes Passwort ein",
    newEmail: "Neue E-Mail-Adresse",
    newEmailError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    newPassword: "Neues Passwort",
    newPasswordBlank: "Bitte geben Sie Ihr neues Passwort ein",
  },
};
