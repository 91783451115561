export const it = {
  doc: {
    addOwner: "Aggiungi proprietario",
    date: "Data",
    dateError: "Inserire una data valida",
    doc: "Documento",
    docDate: "Data documento: {date}",
    docDateUnknown: "Data documento non presente",
    docExpiration: "Scadenza documento: {expiration}",
    docExpirationUnknown: "Nessuna scadenza",
    docFolderOf: "Fascicolo documenti di {parent}",
    docs: "Documenti",
    docsOf: "Documenti di {parent}",
    expiration: "Scadenza",
    expiredDocs: "Documenti scaduti",
    expiringDocs: "Documenti in scadenza",
    expiringDocsCount: "{count} documento scade in meno di {days} giorni | {count} documenti scadono in meno di {days} giorni",
    expiringDocsTitle: "Documenti in scadenza",
    files: "Allegati",
    mandatory: "Obbligatorio",
    missingDocs: "Documenti mancanti",
    name: "Nome",
    nameError: "Inserire un nome valido",
    noDocFolder: "Nessun fascicolo definito per questo elemento",
    noDocs: "Nessun documento disponibile",
    notes: "Note",
    owner: "Proprietario",
    preview: "Anteprima",
    source: "Origine",
    sourceError: "Selezionare un'origine valida",
    sourceId: "Elemento",
    sourceIdError: "Selezionare un elemento valido",
    sourceSelect: "Seleziona prima un'origine",
    state: "Stato",
    states: {
      expired: "Scaduto",
      expiring: "In scadenza",
      inForce: "In vigore",
    },
    type: "Tipo documento",
    typeError: "Selezionare un tipo documento valido",
  },
  navigation: {
    doc: "Documenti",
  },
  buttons: {
    goToDocs: "Vai ai documenti",
  },
};
