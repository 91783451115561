import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Supplier } from "@/features/modules/supplier/objects/Supplier";
import { supplierModel } from "@/features/modules/supplier/models/SupplierModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getMissingSupplierDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const suppliers: Supplier[] = await supplierModel.getSuppliersWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("missingDocs", DocTypeSource.Supplier);

    for (const supplier of suppliers) {
      if (supplier.trackExpirations === false) continue;

      const supplierWidget: Widget | undefined = widgets.find(
        (widget) => widget.source === DocTypeSource.Supplier && widget.sourceId === supplier.id
      );
      if (supplierWidget === undefined) continue;
      if (supplierWidget.values.count === 0) continue;
      if (supplierWidget.values.missings == undefined) continue;

      const missings: string[] = supplierWidget.values.missings as string[];
      for (const docTypeId of missings) {
        docExpirations.push(new DocExpiration(supplier.id, supplier.name ?? "-", new Date("2000-01-01"), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("SupplierDocModel.getMissingSupplierDocs", {}, error);
    return [];
  }
};
