export const it = {
  statServiceCounter: {
    companies: "Aziende",
    doctor: "Medico",
    serviceTypes: {
      examination: "Visita medica",
      testType: "Accertamento",
      testTypeCategory: "Categoria accertamento",
      survey: "Questionario",
    },
    manyCompanies: "Selezionate",
    name: "Riepilogo servizi effettuati",
    paperTitleAll: "Riepilogo servizi effettuati",
    paperTitleByCompanies: "Riepilogo servizi effettuati per aziende selezionate",
    paperTitleByCompaniesAndDoctor: "Riepilogo servizi effettuati per aziende selezionate e medico",
    paperTitleByDoctor: "Riepilogo servizi effettuati per medico",
    period: "Periodo",
    serviceCountCount: "Numero",
    serviceCountName: "Nome",
    serviceCountType: "Tipo",
  },
  toast: {
    error: {
      statServiceCounter: {
        companiesIdsUndefined: "Selezionare almeno un'azienda",
        doctorIdUndefined: "Selezionare un medico",
        periodInvalid: "Periodo non valido",
        periodUndefined: "Selezionare il periodo",
      },
    },
    success: {},
    warning: {},
  },
};
