import { getCountFromServer, Query, query, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Clause } from "@/core/modules/firestore/objects/Clause";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const countDocuments = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  clauses?: Clause[],
  parentId?: string
): Promise<number> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (model.offlineModuleModel === undefined) return 0;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      let documents: T[] = (await offlineModel.getCacheByCollection(model.offlineModuleModel)) as T[];
      if (parentId !== undefined) documents = documents.filter((document) => document.parentId === parentId);

      return documents.length;
    }

    let snapshotQuery: Query<T> = query(model.getPathReference(parentId).withConverter(model.firestoreConverter));

    if (clauses !== undefined) {
      for (const clause of clauses) {
        snapshotQuery = query(snapshotQuery, where(clause.field, clause.condition, clause.value));
      }
    }

    const snapshot = await getCountFromServer(snapshotQuery);
    return snapshot.data().count;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.countDocuments", { model, clauses, parentId }, error);
    return 0;
  }
};
