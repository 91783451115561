import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { JudgmentState } from "./JudgmentState";
import { LinkedLimitation } from "@/features/modules/limitation/objects/LinkedLimitation";
import { LinkedPrescription } from "@/features/modules/prescription/objects/LinkedPrescription";
import { NotExpressedType } from "./NotExpressedType";
import { UnfitType } from "./UnfitType";

import { ArrayByKeyField, DateField, EnumField, StringArrayField, StringField } from "@/core/fields";

export class Ending {
  public tags: string[] = [];
  public ending: string | undefined = undefined;
  public endingMeasures: string | undefined = undefined;
  public judgment: JudgmentState = JudgmentState.NotEvaluated;
  public judgmentNotes: string | undefined = undefined;
  public unfitType: UnfitType = UnfitType.Permanent;
  public notExpressedType: NotExpressedType = NotExpressedType.None;
  public judgmentDate: Date | undefined = undefined;
  public nextExaminationDate: Date | undefined = undefined;
  public companyDate: Date | undefined = undefined;
  public employeeDate: Date | undefined = undefined;
  public prescriptions: Record<string, LinkedPrescription> = {};
  public prescriptionsNotes: string | undefined = undefined;
  public limitations: Record<string, LinkedLimitation> = {};
  public additionalTestsRequired: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): Ending {
    this.tags = StringArrayField.fromFirestore(data.tags);
    this.ending = StringField.fromFirestore(data.ending);
    this.endingMeasures = StringField.fromFirestore(data.endingMeasures);
    this.judgment = EnumField.fromFirestore<JudgmentState>(data.judgment, Object.values(JudgmentState), JudgmentState.NotEvaluated);
    this.judgmentNotes = StringField.fromFirestore(data.judgmentNotes);
    this.unfitType = EnumField.fromFirestore<UnfitType>(data.unfitType, Object.values(UnfitType), UnfitType.Permanent);
    this.notExpressedType = EnumField.fromFirestore<NotExpressedType>(data.notExpressedType, Object.values(UnfitType), NotExpressedType.None);
    this.judgmentDate = DateField.fromFirestore(data.judgmentDate);
    this.nextExaminationDate = DateField.fromFirestore(data.nextExaminationDate);
    this.companyDate = DateField.fromFirestore(data.companyDate);
    this.employeeDate = DateField.fromFirestore(data.employeeDate);
    this.prescriptions = ArrayByKeyField.fromFirestore<LinkedPrescription>(data.prescriptions, (value) => new LinkedPrescription(value));
    this.prescriptionsNotes = StringField.fromFirestore(data.prescriptionsNotes);
    this.limitations = ArrayByKeyField.fromFirestore<LinkedLimitation>(data.limitations, (value) => new LinkedLimitation(value));
    this.additionalTestsRequired = StringField.fromFirestore(data.additionalTestsRequired);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.tags = StringArrayField.toFirestore(this.tags);
    firestoreData.ending = StringField.toFirestore(this.ending);
    firestoreData.endingMeasures = StringField.toFirestore(this.endingMeasures);
    firestoreData.judgment = EnumField.toFirestore<JudgmentState>(this.judgment, JudgmentState.NotEvaluated);
    firestoreData.judgmentNotes = StringField.toFirestore(this.judgmentNotes);
    firestoreData.unfitType = EnumField.toFirestore<UnfitType>(this.unfitType, UnfitType.Permanent);
    firestoreData.notExpressedType = EnumField.toFirestore<NotExpressedType>(this.notExpressedType, NotExpressedType.None);
    firestoreData.judgmentDate = DateField.toFirestore(this.judgmentDate);
    firestoreData.nextExaminationDate = DateField.toFirestore(this.nextExaminationDate);
    firestoreData.companyDate = DateField.toFirestore(this.companyDate);
    firestoreData.employeeDate = DateField.toFirestore(this.employeeDate);
    firestoreData.prescriptions = ArrayByKeyField.toFirestore<LinkedPrescription>(this.prescriptions, (value) => value.toFirestore());
    firestoreData.prescriptionsNotes = StringField.toFirestore(this.prescriptionsNotes);
    firestoreData.limitations = ArrayByKeyField.toFirestore<LinkedLimitation>(this.limitations, (value) => value.toFirestore());
    firestoreData.additionalTestsRequired = StringField.toFirestore(this.additionalTestsRequired);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Ending {
    this.tags = StringArrayField.fromOfflineCache(data.tags);
    this.ending = StringField.fromOfflineCache(data.ending);
    this.endingMeasures = StringField.fromOfflineCache(data.endingMeasures);
    this.judgment = EnumField.fromOfflineCache<JudgmentState>(data.judgment, Object.values(JudgmentState), JudgmentState.NotEvaluated);
    this.judgmentNotes = StringField.fromOfflineCache(data.judgmentNotes);
    this.unfitType = EnumField.fromOfflineCache<UnfitType>(data.unfitType, Object.values(UnfitType), UnfitType.Permanent);
    this.notExpressedType = EnumField.fromOfflineCache<NotExpressedType>(data.notExpressedType, Object.values(UnfitType), NotExpressedType.None);
    this.judgmentDate = DateField.fromOfflineCache(data.judgmentDate);
    this.nextExaminationDate = DateField.fromOfflineCache(data.nextExaminationDate);
    this.companyDate = DateField.fromOfflineCache(data.companyDate);
    this.employeeDate = DateField.fromOfflineCache(data.employeeDate);
    this.prescriptions = ArrayByKeyField.fromOfflineCache<LinkedPrescription>(data.prescriptions, (value) => {
      const linkedPrescription: LinkedPrescription = new LinkedPrescription();
      return linkedPrescription.fromOfflineCache(value);
    });
    this.prescriptionsNotes = StringField.fromOfflineCache(data.prescriptionsNotes);
    this.limitations = ArrayByKeyField.fromOfflineCache<LinkedLimitation>(data.limitations, (value) => {
      const linkedLimitation: LinkedLimitation = new LinkedLimitation();
      return linkedLimitation.fromOfflineCache(value);
    });
    this.additionalTestsRequired = StringField.fromOfflineCache(data.additionalTestsRequired);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.tags = StringArrayField.toOfflineCache(this.tags);
    firestoreData.ending = StringField.toOfflineCache(this.ending);
    firestoreData.endingMeasures = StringField.toOfflineCache(this.endingMeasures);
    firestoreData.judgment = EnumField.toOfflineCache<JudgmentState>(this.judgment, JudgmentState.NotEvaluated);
    firestoreData.judgmentNotes = StringField.toOfflineCache(this.judgmentNotes);
    firestoreData.unfitType = EnumField.toOfflineCache<UnfitType>(this.unfitType, UnfitType.Permanent);
    firestoreData.notExpressedType = EnumField.toOfflineCache<NotExpressedType>(this.notExpressedType, NotExpressedType.None);
    firestoreData.judgmentDate = DateField.toOfflineCache(this.judgmentDate);
    firestoreData.nextExaminationDate = DateField.toOfflineCache(this.nextExaminationDate);
    firestoreData.companyDate = DateField.toOfflineCache(this.companyDate);
    firestoreData.employeeDate = DateField.toOfflineCache(this.employeeDate);
    firestoreData.prescriptions = ArrayByKeyField.toOfflineCache<LinkedPrescription>(this.prescriptions, (value) => value.toOfflineCache());
    firestoreData.prescriptionsNotes = StringField.toOfflineCache(this.prescriptionsNotes);
    firestoreData.limitations = ArrayByKeyField.toOfflineCache<LinkedLimitation>(this.limitations, (value) => value.toOfflineCache());
    firestoreData.additionalTestsRequired = StringField.toOfflineCache(this.additionalTestsRequired);

    return firestoreData;
  }

  public getLinkedPrescriptions(): LinkedPrescription[] {
    return DataHelpers.objectToSortedArray<LinkedPrescription>(this.prescriptions);
  }

  public setLinkedPrescriptions(linkedPrescriptions: LinkedPrescription[]): void {
    this.prescriptions = DataHelpers.sortedArrayToObject<LinkedPrescription>(linkedPrescriptions);
  }

  public addLinkedPrescription(linkedPrescription: LinkedPrescription): void {
    linkedPrescription.order = Object.keys(this.prescriptions).length + 1;
    this.prescriptions[linkedPrescription.id] = linkedPrescription;
  }

  public removeLinkedPrescription(linkedPrescription: LinkedPrescription): void {
    delete this.prescriptions[linkedPrescription.id];
  }

  public getLinkedLimitations(): LinkedLimitation[] {
    return DataHelpers.objectToSortedArray<LinkedLimitation>(this.limitations);
  }

  public setLinkedLimitations(linkedLimitations: LinkedLimitation[]): void {
    this.limitations = DataHelpers.sortedArrayToObject<LinkedLimitation>(linkedLimitations);
  }

  public addLinkedLimitation(linkedLimitation: LinkedLimitation): void {
    linkedLimitation.order = Object.keys(this.limitations).length + 1;
    this.limitations[linkedLimitation.id] = linkedLimitation;
  }

  public removeLinkedLimitation(linkedLimitation: LinkedLimitation): void {
    delete this.limitations[linkedLimitation.id];
  }
}
