import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "../BranchModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const handleLegalBranch = async (branch: Branch): Promise<void> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (branch === undefined || branch.company === undefined) throw new Error("branchOrCompanyUndefined");

    const branches: Branch[] = await branchModel.getBranchesByCompany(branch.company.id);

    if (branch.legalBranch === true) {
      // this branch is the legal branch

      const user: User = store.getters[storeTypes.getters.getUser];
      // check if user can read and update the collection
      if (user.canRead(branchModel.roleModule) === false || user.canUpdate(branchModel.roleModule) === false)
        throw new Error(`Unable to update documents in collection ${branchModel.collectionName}`);

      for (const loopBranch of branches) {
        if (loopBranch.id === branch.id) continue;
        batch.update(branchModel.getDocumentReference(loopBranch.id), {
          legalBranch: false,
          legalRepresentative: null,
          invoiceEmail: null,
          certifiedEmail: null,
          vatCode: null,
          fiscalCode: null,
          recipientCode: null,
          atecoCode: null,
        });
      }
      return batch.commit();
    } else {
      // this branch is an operational branch

      const legalBranch: Branch | undefined = branches.find((loopBranch: Branch) => loopBranch.legalBranch === true);
      if (legalBranch === undefined) throw new Error("noLegalBranch");

      branch.legalRepresentative = undefined;
      branch.invoiceEmail = undefined;
      branch.certifiedEmail = undefined;
      branch.vatCode = undefined;
      branch.fiscalCode = undefined;
      branch.recipientCode = undefined;
      branch.atecoCode = undefined;
    }
  } catch (error: unknown) {
    appFaultModel.catchAppError("BranchModel.handleLegalBranch", { branch }, error);
    return undefined;
  }
};
