export class NumberWithDefaultField {
  public static fromFirestore(value: unknown, defaultValue: number): number {
    if (value === null || value === undefined) return defaultValue;

    return Number(value);
  }

  public static toFirestore(value: number, defaultValue: number): number {
    if (value === null || value === undefined) return defaultValue;

    return Number(value);
  }

  public static fromOfflineCache(value: unknown, defaultValue: number): number {
    return NumberWithDefaultField.fromFirestore(value, defaultValue);
  }

  public static toOfflineCache(value: number, defaultValue: number): number {
    return NumberWithDefaultField.toFirestore(value, defaultValue);
  }
}
