import { addDays, endOfMonth, endOfYear, startOfMonth, startOfYear, subDays, subMonths, subYears } from "date-fns";
import { Ref } from "vue";

import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";

export function getPeriodContextCommands(period: Ref<Date[]>, t: (entry: string, params?: Record<string, unknown>) => string): ContextCommand[] {
  const commands: ContextCommand[] = [];

  const today: Date = new Date();
  today.setHours(0, 0, 0, 0);

  commands.push({
    label: t("buttons.periodToday"),
    icon: "pi pi-calendar-plus",
    condition: () => true,
    command: async () => {
      period.value = [today, today];
    },
  });

  commands.push({
    label: t("buttons.periodTomorrow"),
    icon: "pi pi-calendar-plus",
    condition: () => true,
    command: async () => {
      const tomorrow: Date = addDays(new Date(), 1);
      tomorrow.setHours(0, 0, 0, 0);

      period.value = [tomorrow, tomorrow];
    },
  });

  commands.push({ label: "separator" } as ContextCommand);

  for (const days of [7, 15, 30]) {
    commands.push({
      label: t("buttons.periodDays", { days: days }),
      icon: "pi pi-calendar-plus",
      condition: () => true,
      command: async () => {
        const startDate: Date = subDays(new Date(), days);
        startDate.setHours(0, 0, 0, 0);

        period.value = [startDate, today];
      },
    });
  }

  commands.push({ label: "separator" } as ContextCommand);

  commands.push({
    label: t("buttons.periodCurrentMonth"),
    icon: "pi pi-calendar-plus",
    condition: () => true,
    command: async () => {
      const startDate: Date = startOfMonth(new Date());
      startDate.setHours(0, 0, 0, 0);

      const endDate: Date = endOfMonth(new Date(startDate.getTime()));
      endDate.setHours(0, 0, 0, 0);

      period.value = [startDate, endDate];
    },
  });

  commands.push({
    label: t("buttons.periodLastMonth"),
    icon: "pi pi-calendar-plus",
    condition: () => true,
    command: async () => {
      const startDate: Date = startOfMonth(subMonths(new Date(), 1));
      startDate.setHours(0, 0, 0, 0);

      const endDate: Date = endOfMonth(new Date(startDate.getTime()));
      endDate.setHours(0, 0, 0, 0);

      period.value = [startDate, endDate];
    },
  });

  commands.push({ label: "separator" } as ContextCommand);

  commands.push({
    label: t("buttons.periodCurrentYear"),
    icon: "pi pi-calendar-plus",
    condition: () => true,
    command: async () => {
      const startDate: Date = startOfYear(new Date());
      startDate.setHours(0, 0, 0, 0);

      const endDate: Date = endOfYear(new Date(startDate.getTime()));
      endDate.setHours(0, 0, 0, 0);

      period.value = [startDate, endDate];
    },
  });

  commands.push({
    label: t("buttons.periodLastYear"),
    icon: "pi pi-calendar-plus",
    condition: () => true,
    command: async () => {
      const startDate: Date = startOfYear(subYears(new Date(), 1));
      startDate.setHours(0, 0, 0, 0);

      const endDate: Date = endOfYear(new Date(startDate.getTime()));
      endDate.setHours(0, 0, 0, 0);

      period.value = [startDate, endDate];
    },
  });

  return commands;
}
