import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { InvoiceCompanyTotal } from "./InvoiceCompanyTotal";
import { InvoiceCostRow } from "./InvoiceCostRow";
import { InvoiceDeadline } from "./InvoiceDeadline";
import { invoiceModel } from "../models/InvoiceModel";
import { InvoicePriceRow } from "./InvoicePriceRow";
import { InvoiceState } from "./InvoiceState";
import { InvoiceType } from "./InvoiceType";
import { LinkedBroker } from "@/features/modules/broker/objects/LinkedBroker";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedExamination } from "@/features/modules/examination/objects/LinkedExamination";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LinkedService } from "@/features/modules/service/objects/LinkedService";
import { TinyLinkedExternalTest } from "@/features/modules/externalTest/objects/TinyLinkedExternalTest";

import {
  AddressField,
  ArrayByKeyField,
  ArrayField,
  BooleanField,
  DateStrictField,
  EnumField,
  NumberField,
  ObjectField,
  StringField,
  StringStrictField,
} from "@/core/fields";

export class Invoice extends FirestoreDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public isVirtual = false;
  public type: InvoiceType = InvoiceType.Invoice;
  public firm: LinkedFirm | undefined = undefined;
  public date: Date = new Date();
  public company: LinkedCompany | undefined = undefined;
  public broker: LinkedBroker | undefined = undefined;
  public recipientName: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public vatCode: string | undefined = undefined;
  public fiscalCode: string | undefined = undefined;
  public atecoCode: string | undefined = undefined;
  public recipientCode: string | undefined = undefined;
  public invoiceEmail: string | undefined = undefined;
  public certifiedEmail: string | undefined = undefined;
  public contractCode: string | undefined = undefined;
  public orderCode: string | undefined = undefined;
  public cupCode: string | undefined = undefined;
  public cigCode: string | undefined = undefined;
  public examinations: Record<string, LinkedExamination> = {};
  public externalTests: Record<string, TinyLinkedExternalTest> = {};
  public services: Record<string, LinkedService> = {};
  public priceRows: InvoicePriceRow[] = [];
  public deadlines: InvoiceDeadline[] = [];
  public costRows: InvoiceCostRow[] = [];
  public yearlyPaymentsLumpSum: string[] = [];
  public reference: string | undefined = undefined;
  public subtotalVatExempted = 0;
  public subtotalVatSubjected = 0;
  public subtotal = 0;
  public vat = 0;
  public stamp = 0;
  public discount = 0;
  public total = 0;
  public withHoldingTax = 0;
  public toPayTotal = 0;
  public companyTotals: Record<string, InvoiceCompanyTotal> = {};
  public state: InvoiceState = InvoiceState.Draft;
  public transmissionNumber = 0;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Invoice {
    super.fromFirestore(data, id, firestoreRef);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.isVirtual = BooleanField.fromFirestore(data.isVirtual);
    this.type = EnumField.fromFirestore<InvoiceType>(data.type, Object.values(InvoiceType), InvoiceType.Invoice);
    this.firm = ObjectField.fromFirestore<LinkedFirm>(data.firm, (value) => new LinkedFirm(value));
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.company = ObjectField.fromFirestore<LinkedCompany>(data.company, (value) => new LinkedCompany(value));
    this.broker = ObjectField.fromFirestore<LinkedBroker>(data.broker, (value) => new LinkedBroker(value));
    this.recipientName = StringField.fromFirestore(data.recipientName);
    this.address.fromFirestore(data.address);
    this.vatCode = StringField.fromFirestore(data.vatCode);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.atecoCode = StringField.fromFirestore(data.atecoCode);
    this.recipientCode = StringField.fromFirestore(data.recipientCode);
    this.invoiceEmail = StringField.fromFirestore(data.invoiceEmail);
    this.certifiedEmail = StringField.fromFirestore(data.certifiedEmail);
    this.contractCode = StringField.fromFirestore(data.contractCode);
    this.orderCode = StringField.fromFirestore(data.orderCode);
    this.cupCode = StringField.fromFirestore(data.cupCode);
    this.cigCode = StringField.fromFirestore(data.cigCode);
    this.examinations = ArrayByKeyField.fromFirestore<LinkedExamination>(data.examinations, (value) => new LinkedExamination(value));
    this.externalTests = ArrayByKeyField.fromFirestore<TinyLinkedExternalTest>(data.externalTests, (value) => new TinyLinkedExternalTest(value));
    this.services = ArrayByKeyField.fromFirestore<LinkedService>(data.services, (value) => new LinkedService(value));
    this.priceRows = ArrayField.fromFirestore<InvoicePriceRow>(data.priceRows, (value) => new InvoicePriceRow(value));
    this.deadlines = ArrayField.fromFirestore<InvoiceDeadline>(data.deadlines, (value) => new InvoiceDeadline(value));
    this.costRows = ArrayField.fromFirestore<InvoiceCostRow>(data.costRows, (value) => new InvoiceCostRow(value));
    this.yearlyPaymentsLumpSum = ArrayField.fromFirestore<string>(data.yearlyPaymentsLumpSum, (value) => StringField.fromFirestore(value) ?? "NIL");
    this.reference = StringField.fromFirestore(data.reference);
    this.subtotalVatExempted = NumberField.fromFirestore(data.subtotalVatExempted);
    this.subtotalVatSubjected = NumberField.fromFirestore(data.subtotalVatSubjected);
    this.subtotal = NumberField.fromFirestore(data.subtotal);
    this.vat = NumberField.fromFirestore(data.vat);
    this.stamp = NumberField.fromFirestore(data.stamp);
    this.discount = NumberField.fromFirestore(data.discount);
    this.total = NumberField.fromFirestore(data.total);
    this.withHoldingTax = NumberField.fromFirestore(data.withHoldingTax);
    this.toPayTotal = NumberField.fromFirestore(data.toPayTotal);
    this.companyTotals = ArrayByKeyField.fromFirestore<InvoiceCompanyTotal>(
      data.companyTotals,
      (value) => new InvoiceCompanyTotal(value.companyId as string, value)
    );
    this.state = EnumField.fromFirestore<InvoiceState>(data.state, Object.values(InvoiceState), InvoiceState.Draft);
    this.transmissionNumber = NumberField.fromFirestore(data.transmissionNumber);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.isVirtual = BooleanField.toFirestore(this.isVirtual);
    firestoreData.type = EnumField.toFirestore<InvoiceType>(this.type, InvoiceType.Invoice);
    firestoreData.firm = ObjectField.toFirestore<LinkedFirm>(this.firm, (value) => value.toFirestore());
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.company = ObjectField.toFirestore<LinkedCompany>(this.company, (value) => value.toFirestore());
    firestoreData.broker = ObjectField.toFirestore<LinkedBroker>(this.broker, (value) => value.toFirestore());
    firestoreData.recipientName = StringField.toFirestore(this.recipientName);
    firestoreData.address = this.address.toFirestore();
    firestoreData.vatCode = StringField.toFirestore(this.vatCode);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.atecoCode = StringField.toFirestore(this.atecoCode);
    firestoreData.recipientCode = StringField.toFirestore(this.recipientCode);
    firestoreData.invoiceEmail = StringField.toFirestore(this.invoiceEmail);
    firestoreData.certifiedEmail = StringField.toFirestore(this.certifiedEmail);
    firestoreData.contractCode = StringField.toFirestore(this.contractCode);
    firestoreData.orderCode = StringField.toFirestore(this.orderCode);
    firestoreData.cupCode = StringField.toFirestore(this.cupCode);
    firestoreData.cigCode = StringField.toFirestore(this.cigCode);
    firestoreData.examinations = ArrayByKeyField.toFirestore<LinkedExamination>(this.examinations, (value) => value.toFirestore());
    firestoreData.externalTests = ArrayByKeyField.toFirestore<TinyLinkedExternalTest>(this.externalTests, (value) => value.toFirestore());
    firestoreData.services = ArrayByKeyField.toFirestore<LinkedService>(this.services, (value) => value.toFirestore());
    firestoreData.priceRows = ArrayField.toFirestore<InvoicePriceRow>(this.priceRows, (value) => value.toFirestore());
    firestoreData.deadlines = ArrayField.toFirestore<InvoiceDeadline>(this.deadlines, (value) => value.toFirestore());
    firestoreData.costRows = ArrayField.toFirestore<InvoiceCostRow>(this.costRows, (value) => value.toFirestore());
    firestoreData.yearlyPaymentsLumpSum = ArrayField.toFirestore<string>(this.yearlyPaymentsLumpSum, (value) => StringField.toFirestore(value));
    firestoreData.reference = StringField.toFirestore(this.reference);
    firestoreData.subtotalVatExempted = NumberField.toFirestore(this.subtotalVatExempted);
    firestoreData.subtotalVatSubjected = NumberField.toFirestore(this.subtotalVatSubjected);
    firestoreData.subtotal = NumberField.toFirestore(this.subtotal);
    firestoreData.vat = NumberField.toFirestore(this.vat);
    firestoreData.stamp = NumberField.toFirestore(this.stamp);
    firestoreData.discount = NumberField.toFirestore(this.discount);
    firestoreData.total = NumberField.toFirestore(this.total);
    firestoreData.withHoldingTax = NumberField.toFirestore(this.withHoldingTax);
    firestoreData.toPayTotal = NumberField.toFirestore(this.toPayTotal);
    firestoreData.companyTotals = ArrayByKeyField.toFirestore<InvoiceCompanyTotal>(this.companyTotals, (value) => value.toFirestore());
    firestoreData.state = EnumField.toFirestore<InvoiceState>(this.state, InvoiceState.Draft);
    firestoreData.transmissionNumber = NumberField.toFirestore(this.transmissionNumber);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.company !== undefined) {
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.company.name)];
    }
    if (this.broker !== undefined) {
      this.searchKeys = [...this.searchKeys, ...DataHelpers.createSearchKeys(this.broker.name)];
    }
    if (this.codeDisplay !== undefined) this.searchKeys.push(this.codeDisplay.toLowerCase());
  }

  public async setCodes(): Promise<void> {
    this.code = await invoiceModel.getNextInvoiceCodeByDateAndFirm(this.date, this.isVirtual);
    this.codeSort = (this.date.getFullYear() - 2000) * 1000000 + this.code;
    this.codeDisplay = `${this.isVirtual === true ? "X" : ""}${this.code}/${this.date.getFullYear()}`;
    this.year = this.date.getFullYear();
  }

  public setState(): void {
    if (this.state === InvoiceState.Draft || this.state === InvoiceState.Emitted) return;

    this.state = InvoiceState.Paid;
    for (const deadline of this.deadlines) {
      if (deadline.paid === undefined) {
        this.state = InvoiceState.Sent;
        return;
      }
    }
  }

  public getTransmissionCode(): string {
    return `${this.codeSort}${this.transmissionNumber.toString().padStart(2, "0")}`;
  }

  public getLinkedExaminations(): LinkedExamination[] {
    return DataHelpers.objectToSortedArray<LinkedExamination>(this.examinations);
  }

  public setLinkedExaminations(linkedExaminations: LinkedExamination[]): void {
    this.examinations = DataHelpers.sortedArrayToObject<LinkedExamination>(linkedExaminations);
  }

  public addLinkedExamination(linkedExamination: LinkedExamination): void {
    this.examinations[linkedExamination.id] = linkedExamination;
  }

  public removeLinkedExamination(linkedExamination: LinkedExamination): void {
    delete this.examinations[linkedExamination.id];
  }

  public getTinyLinkedExternalTests(): TinyLinkedExternalTest[] {
    return DataHelpers.objectToSortedArray<TinyLinkedExternalTest>(this.externalTests);
  }

  public setTinyLinkedExternalTests(tinyLinkedExternalTests: TinyLinkedExternalTest[]): void {
    this.externalTests = DataHelpers.sortedArrayToObject<TinyLinkedExternalTest>(tinyLinkedExternalTests);
  }

  public addTinyLinkedExternalTest(tinyLinkedExternalTest: TinyLinkedExternalTest): void {
    this.externalTests[tinyLinkedExternalTest.id] = tinyLinkedExternalTest;
  }

  public removeTinyLinkedExternalTest(tinyLinkedExternalTest: TinyLinkedExternalTest): void {
    delete this.externalTests[tinyLinkedExternalTest.id];
  }

  public getLinkedServices(): LinkedService[] {
    return DataHelpers.objectToSortedArray<LinkedService>(this.services);
  }

  public setLinkedServices(linkedServices: LinkedService[]): void {
    this.services = DataHelpers.sortedArrayToObject<LinkedService>(linkedServices);
  }

  public addLinkedService(linkedService: LinkedService): void {
    this.services[linkedService.id] = linkedService;
  }

  public removeLinkedService(linkedService: LinkedService): void {
    delete this.services[linkedService.id];
  }

  public getCompanyTotals(): InvoiceCompanyTotal[] {
    return DataHelpers.objectToSortedArray<InvoiceCompanyTotal>(this.companyTotals);
  }

  public setCompanyTotals(companyTotals: InvoiceCompanyTotal[]): void {
    this.companyTotals = DataHelpers.sortedArrayToObject<InvoiceCompanyTotal>(companyTotals);
  }

  public addCompanyTotal(companyTotal: InvoiceCompanyTotal): void {
    this.companyTotals[companyTotal.companyId] = companyTotal;
  }

  public removeCompanyTotal(companyTotal: InvoiceCompanyTotal): void {
    delete this.companyTotals[companyTotal.companyId];
  }

  public emptyCompanyTotals(): void {
    this.companyTotals = {};
  }
}
