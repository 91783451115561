export const it = {
  contract: {
    branches: "Sedi",
    branchesError: "Seleziona almeno una sede",
    company: "Azienda",
    contract: "Nomina",
    contractOf: "Nomina di {parent}",
    contracts: "Nomine",
    contractsOf: "Nomine di {parent}",
    doctor: "Medico",
    doctorError: "Seleziona un medico",
    doctorTitle: "Dott.",
    expiredContracts: "Nomine scadute",
    expiringContracts: "Nomine in scadenza",
    from: "Inizio",
    missingContracts: "Nomine mancanti",
    notes: "Note",
    priceList: "Listino prezzi",
    temporary: "Temporaneo",
    temporaryError: "Seleziona se il contratto è temporaneo",
    type: "Tipo mandato",
    typeError: "Seleziona un tipo mandato",
    types: {
      competent: "Medico Competente",
      coordinated: "Medico Competente Coordinato",
      coordinator: "Medico Competente Coordinatore",
    },
    to: "Scadenza",
  },
};
