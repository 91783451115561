
import { defineComponent } from "vue";

import { Examination } from "../objects/Examination";
import { ExaminationState } from "../objects/ExaminationState";
import { LinkedInvoice } from "@/features/modules/invoice/objects/LinkedInvoice";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "ExaminationStateValue",
  props: {
    examination: { type: Object as () => Examination, required: true },
  },
  setup() {
    const { d, t } = useLocale();

    const renderInvoiceTooltip = (invoice: LinkedInvoice | undefined): string => {
      if (invoice === undefined) return "";
      return `${t("examination.invoice")} ${invoice.codeDisplay} ${t("examination.of")} ${d(invoice.date, "shortDate")}`;
    };

    return {
      ExaminationState,
      renderInvoiceTooltip,
      t,
    };
  },
});
