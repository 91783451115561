import { BronchitisSurveyForm } from "../objects/BronchitisSurveyForm";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class BronchitisSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private bronchitisSurveyForm: BronchitisSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    bronchitisSurveyForm: BronchitisSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.bronchitisSurveyForm = bronchitisSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      bronchitisAnswer: { fontSize: 8, bold: false },
      bronchitisAnswerTable: { fontSize: 8 },
      bronchitisAnswerValue: { alignment: "center", bold: true },
      bronchitisQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("QUESTIONARIO PER LA DIAGNOSI DI BRONCHITE CRONICA", isContinuos);

    await this.writeEmployeeData();

    this.writeColumnWithTwoChoices(1, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(2, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(3, ["No", "Sì"]);
    this.writeColumnAdditionalText(3, "b");
    this.writeColumnWithTwoChoices(4, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(5, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(6, ["No", "Sì"]);
    this.writeColumnAdditionalText(6, "b");
    this.writeColumnWithTwoChoices(7, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(8, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(9, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(10, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(11, ["No", "Sì"]);
    this.writeColumnAdditionalText(11, "b");
    this.writeColumnWithTwoChoices(12, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(13, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(14, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(15, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(16, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(17, ["No", "Sì"]);
    this.writeColumnAdditionalText(17, "b");
    this.writeColumnAdditionalText(17, "c");
    this.writeColumnAdditionalChoices(17, "d", ["No", "Sì"]);
    this.writeColumnWithTwoChoices(18, ["No", "Sì"]);
    this.writeColumnAdditionalText(18, "b");
    this.writeColumnAdditionalText(18, "c");
    this.writeColumnAdditionalText(18, "d");

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeColumnWithTwoChoices(questionIndex: number, choices: string[]): void {
    const answer: boolean = this.bronchitisSurveyForm[`question${questionIndex}` as keyof BronchitisSurveyForm] as boolean;
    const answerTableBody: unknown[] = [
      [
        { text: answer === false ? "X" : " ", style: "bronchitisAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === true ? "X" : " ", style: "bronchitisAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(3), 0, 0],
          width: DataHelpers.mmToPoints(140),
          text: `${questionIndex}) ${this.t(`surveyForm.bronchitisSurveyForm.question${questionIndex}`)}`,
          style: "bronchitisQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(3), 0, 0],
          style: "bronchitisAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2), "*", DataHelpers.mmToPoints(2), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeColumnAdditionalText(questionIndex: number, answerLetter: string): void {
    const mainAnswer: boolean = this.bronchitisSurveyForm[`question${questionIndex}` as keyof BronchitisSurveyForm] as boolean;
    if (mainAnswer === false) return;

    const answer: string | undefined = this.bronchitisSurveyForm[`question${questionIndex}${answerLetter}` as keyof BronchitisSurveyForm] as
      | string
      | undefined;

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(2), 0, 0],
          width: DataHelpers.mmToPoints(140),
          text: this.t(`surveyForm.bronchitisSurveyForm.question${questionIndex}${answerLetter}`),
          style: "bronchitisQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(2), 0, 0],
          style: "bronchitisAnswerTable",
          text: `${this.t(`surveyForm.bronchitisSurveyForm.question${questionIndex}${answerLetter}Hint`)}: ${answer ?? "-"}`,
        },
      ],
    });
  }

  private writeColumnAdditionalChoices(questionIndex: number, answerLetter: string, choices: string[]): void {
    const mainAnswer: boolean = this.bronchitisSurveyForm[`question${questionIndex}` as keyof BronchitisSurveyForm] as boolean;
    if (mainAnswer === false) return;

    const answer: boolean = this.bronchitisSurveyForm[`question${questionIndex}${answerLetter}` as keyof BronchitisSurveyForm] as boolean;
    const answerTableBody: unknown[] = [
      [
        { text: answer === false ? "X" : " ", style: "bronchitisAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === true ? "X" : " ", style: "bronchitisAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(2), 0, 0],
          width: DataHelpers.mmToPoints(140),
          text: this.t(`surveyForm.bronchitisSurveyForm.question${questionIndex}${answerLetter}`),
          style: "bronchitisQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(2), 0, 0],
          style: "bronchitisAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2), "*", DataHelpers.mmToPoints(2), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }
}
