import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { Prescription } from "../objects/Prescription";
import { prescriptionModel } from "./PrescriptionModel";

export class PrescriptionOfflineModel extends OfflineModuleModel<Prescription> {
  public constructor() {
    super(() => new Prescription(), "prescriptions", "prescription.prescriptions", undefined);
  }

  public async downloadCache(): Promise<Prescription[]> {
    try {
      return prescriptionModel.getDocuments();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("PrescriptionOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const prescriptionOfflineModel: PrescriptionOfflineModel = new PrescriptionOfflineModel();
