import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

export class DocExpiration {
  public id: string = DataHelpers.uniqueId();
  public ownerId: string;
  public ownerName: string;
  public expiration: Date | undefined;
  public typeId: string | undefined;

  public constructor(ownerId: string, ownerName: string, expiration: Date | undefined, typeId?: string) {
    this.ownerId = ownerId;
    this.ownerName = ownerName;
    this.expiration = expiration;
    this.typeId = typeId;
  }
}
