import { compareAsc, compareDesc } from "date-fns";

import { SortCriteria } from "./SortCriteria";

/**
 * sorts an array of documents by multiple criteria
 */
export class FirestoreSorter<T> {
  documents: T[];
  sortCriterias: SortCriteria[];

  /**
   * creates a new sorter
   * @param documents array of documents to sort
   */
  public constructor(documents: T[] = []) {
    this.documents = documents;
    this.sortCriterias = [];
  }

  /**
   * add a sort criteria
   * @param field the field to sort by
   * @param direction the direction to sort by
   * @param type the type of the field
   */
  public addSortCriteria(field: string, direction: "asc" | "desc", type: "boolean" | "date" | "number" | "string"): void {
    this.sortCriterias.push(new SortCriteria(field, direction, type));
  }

  /**
   * sets the documents to sort
   * @param documents the documents to sort
   */
  public setDocuments(documents: T[]): void {
    this.documents = documents;
  }

  /**
   * sets the sort criterias to use
   * @param sortCriterias the sort criterias to use
   */
  public setSortCriterias(sortCriterias: SortCriteria[]): void {
    this.sortCriterias = sortCriterias;
  }

  /**
   * sorts the documents
   * @returns the sorted documents
   */
  public sort(): T[] {
    if (this.sortCriterias.length === 0) return this.documents;

    const invertedSortCriterias: SortCriteria[] = [...this.sortCriterias].reverse();

    for (const sortCriteria of invertedSortCriterias) {
      if (sortCriteria.type === "boolean") {
        this.sortByBoolean(sortCriteria.field, sortCriteria.direction);
      } else if (sortCriteria.type === "date") {
        this.sortByDate(sortCriteria.field, sortCriteria.direction);
      } else if (sortCriteria.type === "number" || sortCriteria.type === "string") {
        this.sortByCompare(sortCriteria.field, sortCriteria.direction);
      }
    }

    return this.documents;
  }

  private sortByBoolean(field: string, direction: "asc" | "desc"): void {
    this.documents.sort((a, b) => {
      if ((a === undefined && b === undefined) || (a === null && b === null)) return 0;
      if (a === undefined || a === null) return 1;
      if (b === undefined || b === null) return -1;
      if (a === b) return 0;
      if (direction === "asc") {
        return a[field as keyof T] > b[field as keyof T] ? -1 : 1;
      } else {
        return a[field as keyof T] > b[field as keyof T] ? 1 : -1;
      }
    });
  }

  private sortByCompare(field: string, direction: "asc" | "desc"): void {
    this.documents.sort((a, b) => {
      if ((a === undefined && b === undefined) || (a === null && b === null)) return 0;
      if (a === undefined || a === null) return 1;
      if (b === undefined || b === null) return -1;
      if (a === b) return 0;
      if (direction === "asc") {
        return a[field as keyof T] > b[field as keyof T] ? 1 : -1;
      } else {
        return a[field as keyof T] > b[field as keyof T] ? -1 : 1;
      }
    });
  }

  private sortByDate(field: string, direction: "asc" | "desc"): void {
    this.documents.sort((a, b) => {
      if ((a === undefined && b === undefined) || (a === null && b === null)) return 0;
      if (a === undefined || a === null) return 1;
      if (b === undefined || b === null) return -1;
      if (a === b) return 0;
      if (direction === "asc") {
        return compareAsc(a[field as keyof T] as Date, b[field as keyof T] as Date);
      } else {
        return compareDesc(a[field as keyof T] as Date, b[field as keyof T] as Date);
      }
    });
  }
}
