import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "../objects/Examination";
import { JudgmentState } from "../objects/JudgmentState";
import { LimitationDuration } from "@/features/modules/limitation/objects/LimitationDuration";
import { PaperHelpers } from "@/features/modules/paper/helpers/PaperHelpers";
import { PrescriptionDuration } from "@/features/modules/prescription/objects/PrescriptionDuration";

export class JudgmentForCompanyPaper extends DocumentPaper {
  constructor(
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      documentSubtitle: {
        alignment: "center",
        bold: true,
        color: this.highlightColor,
        fontSize: 10,
        margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(1)],
      },
      judgmentDataValue: { border: false, color: this.highlightColor, fontSize: 10 },
      judgmentEmployeeDataField: { fontSize: 6, margin: [0, 0, 0, 2] },
      judgmentEmployeeDataTable: { alignment: "center" },
      judgmentEmployeeDataValue: { border: false, color: this.highlightColor, fontSize: 10 },
      judgmentLimitations: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 5] },
      judgmentPrescriptions: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 5] },
      judgmentRisks: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 5] },
      judgmentNotes: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 0] },
      judgmentAppeal: { alignment: "justify", bold: true, color: this.textColor, fontSize: 8, margin: [0, 10, 0, 5] },
      judgmentTerms: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 5] },
      judgmentTests: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 5] },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("COMUNICAZIONE DEL GIUDIZIO DI IDONEITÀ ALLA MANSIONE SPECIFICA", isContinuos);

    const customFooter = PaperHelpers.getCustomFooter(
      this.d,
      this.n,
      this.t,
      "La copia elettronica è conforme all'originale depositato presso lo studio medico",
      "Copia per il datore di lavoro"
    );
    this.setFooter(customFooter);

    await this.writeEmployeeData();

    this.writeSubtitle("Fattori di rischio valutati (come da protocollo sanitario)");
    this.writeRisks();

    if (this.examination.testsNotes !== undefined || this.examination.surveysNotes !== undefined) {
      this.writeSubtitle("Accertamenti e questionari integrativi eseguiti");
      this.writeTestsAndSurveys();
    }

    if (this.examination.ending.additionalTestsRequired !== undefined && this.examination.ending.additionalTestsRequired.trim() !== "") {
      this.writeSubtitle("Ulteriori accertamenti richiesti dal medico competente");
      this.writeAdditionalTestsRequired();
    }

    this.writePrescriptions();
    this.writeLimitations();

    if (this.examination.ending.prescriptionsNotes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        lineHeight: 1.2,
        text: this.examination.ending.prescriptionsNotes,
        style: "judgmentTests",
      });
    }

    this.writeJudgmentNotes();
    this.writeJudgment();

    this.writeAppeal();
    this.writeTerms();
    await this.writeSignatures();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeRisks(): void {
    const risks: string[] = [];

    for (const risk of this.examination.getLinkedRisks()) {
      let riskText = `${risk.norm?.name ?? ""} - ${risk.section ?? ""}: ${risk.name ?? ""}`;
      if (risk.text !== undefined) riskText += ` (${risk.text})`;
      risks.push(riskText);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: risks.join("\n"),
      style: "judgmentRisks",
    });

    if (this.examination.risksNotes !== undefined && this.examination.risksNotes.trim() !== "") {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        lineHeight: 1.2,
        text: this.examination.risksNotes,
        style: "judgmentRisks",
      });
    }
  }

  private writeTestsAndSurveys(): void {
    if (this.examination.testsNotes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        lineHeight: 1.2,
        text: this.examination.testsNotes,
        style: "judgmentTests",
      });
    }
    if (this.examination.surveysNotes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        lineHeight: 1.2,
        text: this.examination.surveysNotes,
        style: "judgmentTests",
      });
    }
  }

  private writeAdditionalTestsRequired(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: this.examination.ending.additionalTestsRequired,
      style: "judgmentTests",
    });
  }

  private writeJudgment(): void {
    let judgment: string = this.t("examination.endings.judgments.notEvaluated");
    if (this.examination.ending.judgment === JudgmentState.Fit) judgment = this.t("examination.endings.judgments.fit");
    if (this.examination.ending.judgment === JudgmentState.FitWithLenses) judgment = this.t("examination.endings.judgments.fitWithLenses");
    if (this.examination.ending.judgment === JudgmentState.FitWithLimitations) judgment = this.t("examination.endings.judgments.fitWithLimitations");
    if (this.examination.ending.judgment === JudgmentState.FitWithPrescriptions)
      judgment = this.t("examination.endings.judgments.fitWithPrescriptions");
    if (this.examination.ending.judgment === JudgmentState.FitWithPrescriptionsAndLimitations)
      judgment = this.t("examination.endings.judgments.fitWithPrescriptionsAndLimitations");
    if (this.examination.ending.judgment === JudgmentState.Unfit) judgment = this.t("examination.endings.judgments.unfit");
    if (this.examination.ending.judgment === JudgmentState.NotExpressed) judgment = this.t("examination.endings.judgments.notExpressed");

    const dataTableBody: unknown[] = [
      [
        {
          text: judgment.toLocaleUpperCase(),
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        {
          text: this.examination.dutyName?.toLocaleUpperCase() ?? "-",
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
      ],
      [
        { text: "Giudizio di idoneità alla mansione specifica", style: "judgmentEmployeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("examination.dutyName"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
      ],
      [
        {
          text: this.examination.date !== undefined ? this.d(this.examination.date, "shortDate") : "-",
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        {
          text: this.examination.type?.name?.toLocaleUpperCase() ?? "-",
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
      ],
      [
        { text: this.t("examination.examinationDate"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("examination.type"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
      ],
      [
        {
          text: this.t(`frequencies.${this.examination.frequency}`).toLocaleUpperCase(),
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        {
          text: this.examination.ending.judgmentDate !== undefined ? this.d(this.examination.ending.judgmentDate, "shortDate") : "-",
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
      ],
      [
        { text: this.t("examination.frequency"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("examination.endings.judgmentDate"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
      ],
      [
        {
          text: this.examination.ending.employeeDate !== undefined ? this.d(this.examination.ending.employeeDate, "shortDate") : "-",
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        {
          text: this.examination.ending.companyDate !== undefined ? this.d(this.examination.ending.companyDate, "shortDate") : "-",
          style: "judgmentEmployeeDataValue",
          border: [false, false, false, false],
        },
      ],
      [
        { text: this.t("examination.endings.employeeDate"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("examination.endings.companyDate"), style: "judgmentEmployeeDataField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "judgmentEmployeeDataTable",
      table: {
        body: dataTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(82), "*", DataHelpers.mmToPoints(82)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    const nextDate: string =
      this.examination.ending.nextExaminationDate !== undefined ? this.d(this.examination.ending.nextExaminationDate, "shortDate") : "-";

    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: `Da sottoporre a nuova visita medica entro il ${nextDate} previa esecuzione degli accertamenti previsti dal programma di sorveglianza sanitaria predisposto dal medico competente ed in vostro possesso.`,
      style: "judgmentRisks",
    });
  }

  private writePrescriptions(): void {
    if (
      this.examination.ending.judgment !== JudgmentState.FitWithPrescriptions &&
      this.examination.ending.judgment !== JudgmentState.FitWithPrescriptionsAndLimitations &&
      this.examination.ending.judgment !== JudgmentState.FitWithLenses
    )
      return;

    if (this.examination.ending.prescriptions === undefined || Object.keys(this.examination.ending.prescriptions).length === 0) return;

    this.writeSubtitle("Prescrizioni");

    const prescriptions: string[] = [];

    for (const prescription of this.examination.ending.getLinkedPrescriptions()) {
      let prescriptionText =
        prescription.duration === PrescriptionDuration.Temporary
          ? `${this.t(`prescription.durations.${prescription.duration}`)} (${prescription.temporaryDuration ?? "-"}):`
          : `${this.t(`prescription.durations.${prescription.duration}`)}:`;
      prescriptionText += ` ${prescription.name ?? ""}`;
      if (prescription.text !== undefined) prescriptionText += ` (${prescription.text})`;
      prescriptions.push(prescriptionText);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: prescriptions.join("\n"),
      style: "judgmentPrescriptions",
    });
  }

  private writeLimitations(): void {
    if (
      this.examination.ending.judgment !== JudgmentState.FitWithLimitations &&
      this.examination.ending.judgment !== JudgmentState.FitWithPrescriptionsAndLimitations &&
      this.examination.ending.judgment !== JudgmentState.FitWithLenses
    )
      return;

    if (this.examination.ending.limitations === undefined || Object.keys(this.examination.ending.limitations).length === 0) return;

    this.writeSubtitle("Limitazioni");

    const limitations: string[] = [];

    for (const limitation of this.examination.ending.getLinkedLimitations()) {
      let limitationText =
        limitation.duration === LimitationDuration.Temporary
          ? `${this.t(`limitation.durations.${limitation.duration}`)} (${limitation.temporaryDuration ?? "-"}):`
          : `${this.t(`limitation.durations.${limitation.duration}`)}:`;
      limitationText += ` ${limitation.name ?? ""}`;
      if (limitation.text !== undefined) limitationText += ` (${limitation.text})`;
      limitations.push(limitationText);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      lineHeight: 1.2,
      text: limitations.join("\n"),
      style: "judgmentLimitations",
    });
  }

  private writeJudgmentNotes(): void {
    if (this.examination.ending.judgmentNotes == undefined) return;

    this.writeSubtitle("Note sul giudizio di idoneità");

    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: this.examination.ending.judgmentNotes,
      style: "judgmentNotes",
    });
  }

  private writeAppeal(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: "Avverso il giudizio di idoneità è ammesso ricorso all'organo di vigilanza territorialmente competente, ai sensi del comma 9 dell'art. 41 del D. Lgs 81/2008 entro il termine di 30 (trenta) giorni.",
      style: "judgmentAppeal",
    });
  }

  private writeTerms(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: "Il lavoratore conferma la veridicità di quanto dichiarato e si impegna ad informare il Medico Competente, in futuro, su ogni variazione del proprio stato di salute; dichiara di essere stato informato sul significato dei controlli e della sorveglianza sanitaria cui è soggetto e, nel caso di esposizione ad agenti con effetti a lungo termine, sulla necessità di sottoporsi ad accertamenti anche dopo la cessazione della attività. Il lavoratore autorizza il Medico Competente al trattamento dei dati sanitari ai sensi del D. Lgs. 196/2003.",
      style: "judgmentTerms",
    });
  }
}
