export const it = {
  statEmployeeByDuty: {
    company: "Azienda",
    companyDuty: "Mansione",
    fullName: "Lavoratore",
    name: "Lavoratori con accertamenti per azienda e mansione",
    paperTitle: "Lavoratori con accertamenti per azienda e mansione",
    testTypeCategory: "Categoria",
    testTypes: "Accertamenti",
  },
  toast: {
    error: {
      statEmployeeByDuty: {
        companyIdUndefined: "Selezionare un'azienda",
        companyDutyIdUndefined: "Selezionare una mansione",
        createPaper: "Errore durante la creazione del documento",
        testTypeCategoryIdUndefined: "Selezionare una categoria di accertamento",
      },
    },
    success: {},
    warning: {},
  },
};
