import { NotificationModule } from "@/core/modules/notification/objects/NotificationModule";

export const testNotification: NotificationModule = new NotificationModule(
  (
    message: string,
    parameters: Record<string, unknown>,
    d: (date: Date, format: string) => string,
    n: (number: number, format: string) => string,
    t: (text: string, params?: Record<string, unknown>) => string
  ) => t(message, parameters),
  "test",
  "notification.test.groupName",
  "cog",
  "workbench",
  "read"
);
