import { DisabilityItem } from "./DisabilityItem";

import { ArrayField, BooleanField, StringArrayField, StringField } from "@/core/fields";

export class FarHistory {
  public goodHealthCheck = true;
  public healthDetails: string | undefined = undefined;
  public surgery: string[] = [];
  public surgeryDetails: string | undefined = undefined;
  public injuries: string | undefined = undefined;
  public workAccidents: string | undefined = undefined;
  public workDiseases: string | undefined = undefined;
  public disabilities: DisabilityItem[] = [];
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): FarHistory {
    this.goodHealthCheck = BooleanField.fromFirestore(data.goodHealthCheck);
    this.healthDetails = StringField.fromFirestore(data.healthDetails);
    this.surgery = StringArrayField.fromFirestore(data.surgery);
    this.surgeryDetails = StringField.fromFirestore(data.surgeryDetails);
    this.injuries = StringField.fromFirestore(data.injuries);
    this.workAccidents = StringField.fromFirestore(data.workAccidents);
    this.workDiseases = StringField.fromFirestore(data.workDiseases);
    this.disabilities = ArrayField.fromFirestore<DisabilityItem>(data.disabilities, (value) => new DisabilityItem(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.goodHealthCheck = BooleanField.toFirestore(this.goodHealthCheck);
    firestoreData.healthDetails = StringField.toFirestore(this.healthDetails);
    firestoreData.surgery = StringArrayField.toFirestore(this.surgery);
    firestoreData.surgeryDetails = StringField.toFirestore(this.surgeryDetails);
    firestoreData.injuries = StringField.toFirestore(this.injuries);
    firestoreData.workAccidents = StringField.toFirestore(this.workAccidents);
    firestoreData.workDiseases = StringField.toFirestore(this.workDiseases);
    firestoreData.disabilities = ArrayField.toFirestore<DisabilityItem>(this.disabilities, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): FarHistory {
    this.goodHealthCheck = BooleanField.fromOfflineCache(data.goodHealthCheck);
    this.healthDetails = StringField.fromOfflineCache(data.healthDetails);
    this.surgery = StringArrayField.fromOfflineCache(data.surgery);
    this.surgeryDetails = StringField.fromOfflineCache(data.surgeryDetails);
    this.injuries = StringField.fromOfflineCache(data.injuries);
    this.workAccidents = StringField.fromOfflineCache(data.workAccidents);
    this.workDiseases = StringField.fromOfflineCache(data.workDiseases);
    this.disabilities = ArrayField.fromOfflineCache<DisabilityItem>(data.disabilities, (value) => {
      const disabilityItem: DisabilityItem = new DisabilityItem();
      return disabilityItem.fromOfflineCache(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.goodHealthCheck = BooleanField.toOfflineCache(this.goodHealthCheck);
    firestoreData.healthDetails = StringField.toOfflineCache(this.healthDetails);
    firestoreData.surgery = StringArrayField.toOfflineCache(this.surgery);
    firestoreData.surgeryDetails = StringField.toOfflineCache(this.surgeryDetails);
    firestoreData.injuries = StringField.toOfflineCache(this.injuries);
    firestoreData.workAccidents = StringField.toOfflineCache(this.workAccidents);
    firestoreData.workDiseases = StringField.toOfflineCache(this.workDiseases);
    firestoreData.disabilities = ArrayField.toOfflineCache<DisabilityItem>(this.disabilities, (value) => value.toOfflineCache());
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
