export const it = {
  company: {
    activeContracts: "Nomine attive",
    activeContractsInitialsExpirationInfo: "{name} - Scade il {date}",
    activeContractsInitialsWithNoExpiration: "{name} - Nessuna scadenza",
    activeCostList: "Listino costi attivo",
    activePriceList: "Listino prezzi attivo",
    activities: "Attività svolte",
    additionalData: "Dati aggiuntivi",
    address: "Indirizzo",
    branches: "Sedi",
    broker: "Intermediario",
    brokerName: "Ragione sociale",
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserisci un indirizzo e-mail valido",
    cigCode: "Codice CIG",
    communicationEmail: "E-mail comunicazione",
    companyCostLists: "Listini costi",
    companyPriceLists: "Listini prezzi",
    contractCode: "Identificativo contratto",
    contracts: "Nomine",
    createFromVatCode: "Crea Azienda da partita IVA",
    company: "Azienda",
    companyDocs: "Documenti",
    companyDuties: "Mansioni",
    companies: "Aziende",
    contractState: "Nomine",
    contractStates: {
      active: "Nomina Attiva | Nomine Attive",
      alwaysValid: "Valido sempre",
      dueDate: "{ date } - { days } giorni rimasti",
      expired: "Scaduto",
      expiring: "In scadenza",
      filterActive: "Attivi",
      filterNotActive: "Non Attivi",
      notAssigned: "Non assegnata",
      valid: "Valida",
    },
    costList: "Listino costi",
    cupCode: "Codice CUP",
    email: "Indirizzo e-mail",
    emailError: "Indirizzo e-mail non valido o già presente",
    employees: "Lavoratori",
    examinations: "Visite mediche",
    filters: {
      archived: "Aziende archiviate",
      archivedExcluded: "Archiviate escluse",
      archivedIncluded: "Archiviate incluse",
      noContract: "Aziende senza nomina",
      noContractExcluded: "Senza nomina escluse",
      noContractIncluded: "Senza nomina incluse",
    },
    fiscalCode: "Codice fiscale",
    info: "Informazioni",
    invoices: "Fatture",
    legalBranch: "Sede legale",
    legalBranchDefaultName: "SEDE LEGALE",
    legalBranchName: "Denominazione",
    name: "Ragione sociale",
    nameError: "Inserisci la ragione sociale",
    noContract: "Attenzione! Questa azienda non ha nessuna nomina attiva per la ditta selezionata",
    noLegalBranch: "Nessuna sede legale presente",
    notes: "Note",
    orderCode: "Codice commessa",
    otherBranches: "Altre sedi",
    phone: "Telefono",
    priceList: "Listino prezzi",
    province: "Provincia",
    recipientCode: "Codice destinatario",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attiva",
      archived: "Archiviata",
      archivedOn: "Archiviata il { date }",
    },
    town: "Comune",
    type: "Tipo",
    typeError: "Seleziona il tipo",
    types: {
      private: "Privata",
      public: "Pubblica",
    },
    userId: "Utente",
    vatCode: "Partita IVA",
    vatCodeEmpty: "Inserisci la partita IVA",
    vatCodeError: "La partita IVA inserita è già presente",
    vatCodePlaceholder: "Inserisci una partita IVA",
    zipCode: "CAP",
  },
  navigation: {
    company: "Aziende",
  },
  buttons: {
    archiveCompany: "Metti in archivio",
    restoreCompany: "Togli dall'archivio",
  },
};
