import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { config } from "@/core/modules/config/objects/Config";
import { Locale } from "@/core/modules/locale/objects/Locale";

export const getLocales = (): Locale[] => {
  try {
    const locales: Locale[] = config.app.locale.locales;
    return locales;
  } catch (error: unknown) {
    appFaultModel.catchAppError("LocaleModel.getLocales", {}, error);
    return [];
  }
};
