import { Doctor } from "./Doctor";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { StringField } from "@/core/fields";

export class LinkedDoctor extends LinkedFirestoreOfflineDocument {
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public fullNameWithTitle: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromDoctor(doctor: Doctor): LinkedDoctor {
    const linkedDoctor: LinkedDoctor = new LinkedDoctor();
    linkedDoctor.id = doctor.id;
    linkedDoctor.firstName = doctor.firstName;
    linkedDoctor.lastName = doctor.lastName;
    linkedDoctor.fullName = doctor.fullName;
    linkedDoctor.fullNameWithTitle = doctor.fullNameWithTitle;

    return linkedDoctor;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedDoctor {
    super.fromFirestore(data);

    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.fullNameWithTitle = StringField.fromFirestore(data.fullNameWithTitle);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.fullNameWithTitle = StringField.toFirestore(this.fullNameWithTitle);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedDoctor {
    super.fromOfflineCache(data);

    this.firstName = StringField.fromOfflineCache(data.firstName);
    this.lastName = StringField.fromOfflineCache(data.lastName);
    this.fullName = StringField.fromOfflineCache(data.fullName);
    this.fullNameWithTitle = StringField.fromOfflineCache(data.fullNameWithTitle);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.firstName = StringField.toOfflineCache(this.firstName);
    firestoreData.lastName = StringField.toOfflineCache(this.lastName);
    firestoreData.fullName = StringField.toOfflineCache(this.fullName);
    firestoreData.fullNameWithTitle = StringField.toOfflineCache(this.fullNameWithTitle);

    return firestoreData;
  }
}
