import { RouteRecordRaw } from "vue-router";

export const companyRoutes: RouteRecordRaw[] = [
  {
    path: "/companies",
    name: "CompanyList",
    component: () => import("../views/CompanyList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/find",
    name: "CompanyFind",
    component: () => import("../views/CompanyFind.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "create",
    },
  },
  {
    path: "/companies/:id/edit",
    name: "CompanyEdit",
    component: () => import("../views/CompanyEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:id/:section?",
    name: "CompanyView",
    component: () => import("../views/CompanyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "company",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:id/draft-examinations",
    name: "DraftExaminationsByCompanyList",
    component: () => import("@/features/modules/examination/widgets/ExaminationListWidget.vue"),
    props: { type: "draft", who: "company" },
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:id/last-examinations",
    name: "LastExaminationsByCompanyList",
    component: () => import("@/features/modules/examination/widgets/ExaminationListWidget.vue"),
    props: { type: "last", who: "company" },
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:id/today-examinations",
    name: "TodayExaminationsByCompanyList",
    component: () => import("@/features/modules/examination/widgets/ExaminationListWidget.vue"),
    props: { type: "today", who: "company" },
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/companies/:id/tomorrow-examinations",
    name: "TomorrowExaminationsByCompanyList",
    component: () => import("@/features/modules/examination/widgets/ExaminationListWidget.vue"),
    props: { type: "tomorrow", who: "company" },
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
];
