export const it = {
  externalTestSession: {
    birthDate: "Data di nascita",
    branch: "Sede",
    candidate: "Lavoratore",
    candidates: "Lavoratori",
    candidateStates: {
      draft: "Bozza",
      created: "Creata",
      error: "Errore",
      view: "Visualizza",
    },
    company: "Azienda",
    companyAndBranch: "Azienda e sede",
    companyError: "Selezionare un'azienda",
    createExternalTestSession: "Crea sessione accertamenti esterni",
    date: "Data",
    dateError: "Selezionare una data valida",
    externalTestDuration: "Durata accertamenti (minuti)",
    externalTestStartTime: "Orario inizio accertamenti",
    externalTestSession: "Sessione accertamenti esterni",
    externalTestSessions: "Sessioni accertamenti esterni",
    file: "Allegato",
    period: "Periodo",
    setExternalTestTime: "Imposta orari accertamenti",
    selectedTestTypes: "Accertamenti",
    state: "Stato",
    states: {
      approved: "Approvata",
      completed: "Completata",
      draft: "Bozza",
      error: "Errore",
    },
    supplier: "Fornitore",
    supplierError: "Selezionare un fornitore valido",
    testTypeList: "Lista accertamenti",
    testTypes: "Tipi accertamento",
    time: "Orario",
  },
  navigation: {
    externalTestSession: "Sessioni accertamenti esterni",
  },
  buttons: {
    createExternalTests: "Crea accertamenti esterni",
    printByPage: "Stampa per pagina",
    showTestTypes: "Visualizza accertamenti",
  },
  toast: {
    error: {},
    success: {},
    warning: {
      noCandidatesSelected: "Selezionare almeno un lavoratore",
    },
  },
};
