import { DocumentReference, DocumentSnapshot, getDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getDocument = async <T extends FirestoreDocument | FirestoreOfflineDocument>(
  model: FirestoreModel<T>,
  firestoreDocumentId: string,
  parentId?: string
): Promise<T> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    // offline mode
    if (offlineModel.getOfflineState() === "offline")
      return getOfflineDocument(user, model as FirestoreModel<FirestoreOfflineDocument>, firestoreDocumentId) as Promise<T>;

    // get online document
    const documentReference: DocumentReference = model.getDocumentReference(firestoreDocumentId, parentId);
    const doc: DocumentSnapshot<T> = await getDoc(documentReference.withConverter(model.firestoreConverter));
    if (doc.exists() === false) throw new Error(`#${firestoreDocumentId} not found in collection ${model.collectionName}`);

    return doc.data() as T;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.getDocument", { model, firestoreDocumentId, parentId }, error);
    return new FirestoreDocument() as T;
  }
};

const getOfflineDocument = async <T extends FirestoreOfflineDocument>(
  user: User,
  model: FirestoreModel<T>,
  firestoreDocumentId: string
): Promise<T> => {
  try {
    if (model.offlineModuleModel === undefined) throw new Error(`#${firestoreDocumentId} not found in collection ${model.collectionName}`);
    if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

    const documents: T[] = (await offlineModel.getCacheByCollection(model.offlineModuleModel)) as T[];
    const document: T | undefined = documents.find((doc) => doc.id === firestoreDocumentId);
    if (document === undefined) {
      throw new Error(`#${firestoreDocumentId} not found in offline cache ${model.collectionName}`);
    }
    return document;
  } catch (error: unknown) {
    appFaultModel.catchAppError("FirestoreModel.getOfflineDocument", { user, model, firestoreDocumentId }, error);
    return new FirestoreDocument() as T;
  }
};
