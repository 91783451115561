import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";

export const setOfflineState = (state: "online" | "offline"): void => {
  try {
    localStorage.setItem("koruOfflineState", state);

    eventBus.emit("offlineToggleStateComponent");
    eventBus.emit("offlineToggleStateView");
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.setOfflineState", { state }, error);
  }
};
