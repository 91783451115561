import { RouteRecordRaw } from "vue-router";

export const testTypeCategoryRoutes: RouteRecordRaw[] = [
  {
    path: "/test-type-categories/:id/edit",
    name: "TestTypeCategoryEdit",
    component: () => import("../views/TestTypeCategoryEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "testTypeCategory",
      requiredRight: "viewUI",
    },
  },
];
