import { CostListItemMode } from "./CostListItemMode";
import { CostListItemType } from "./CostListItemType";
import { CostListOwnerType } from "./CostListOwnerType";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { BooleanField, EnumField, NumberField, StringArrayField, StringField } from "@/core/fields";

export class CostListItem {
  public id: string = DataHelpers.uniqueId();
  public name: string | undefined = undefined;
  public ownerType: CostListOwnerType = CostListOwnerType.Doctor;
  public ownerId: string | undefined = undefined;
  public type: CostListItemType = CostListItemType.ExamType;
  public itemsIds: string[] = [];
  public mode: CostListItemMode = CostListItemMode.Any;
  public price = 0;
  public isVatApplied = false;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): CostListItem {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromFirestore(data.name);
    this.ownerType = EnumField.fromFirestore<CostListOwnerType>(data.ownerType, Object.values(CostListOwnerType), CostListOwnerType.Doctor);
    this.ownerId = StringField.fromFirestore(data.ownerId);
    this.type = EnumField.fromFirestore<CostListItemType>(data.type, Object.values(CostListItemType), CostListItemType.ExamType);
    this.itemsIds = StringArrayField.fromFirestore(data.itemsIds);
    this.mode = EnumField.fromFirestore<CostListItemMode>(data.mode, Object.values(CostListItemMode), CostListItemMode.Any);
    this.price = NumberField.fromFirestore(data.price);
    this.isVatApplied = BooleanField.fromFirestore(data.isVatApplied);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.ownerType = EnumField.toFirestore(this.ownerType, CostListOwnerType.Doctor);
    firestoreData.ownerId = StringField.toFirestore(this.ownerId);
    firestoreData.type = EnumField.toFirestore(this.type, CostListItemType.ExamType);
    firestoreData.itemsIds = StringArrayField.toFirestore(this.itemsIds);
    firestoreData.mode = EnumField.toFirestore(this.mode, CostListItemMode.Any);
    firestoreData.price = NumberField.toFirestore(this.price);
    firestoreData.isVatApplied = BooleanField.toFirestore(this.isVatApplied);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): CostListItem {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromOfflineCache(data.name);
    this.ownerType = EnumField.fromOfflineCache<CostListOwnerType>(data.ownerType, Object.values(CostListOwnerType), CostListOwnerType.Doctor);
    this.ownerId = StringField.fromOfflineCache(data.ownerId);
    this.type = EnumField.fromOfflineCache<CostListItemType>(data.type, Object.values(CostListItemType), CostListItemType.ExamType);
    this.itemsIds = StringArrayField.fromOfflineCache(data.itemsIds);
    this.mode = EnumField.fromOfflineCache<CostListItemMode>(data.mode, Object.values(CostListItemMode), CostListItemMode.Any);
    this.price = NumberField.fromOfflineCache(data.price);
    this.isVatApplied = BooleanField.fromOfflineCache(data.isVatApplied);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.ownerType = EnumField.toOfflineCache(this.ownerType, CostListOwnerType.Doctor);
    firestoreData.ownerId = StringField.toOfflineCache(this.ownerId);
    firestoreData.type = EnumField.toOfflineCache(this.type, CostListItemType.ExamType);
    firestoreData.itemsIds = StringArrayField.toOfflineCache(this.itemsIds);
    firestoreData.mode = EnumField.toOfflineCache(this.mode, CostListItemMode.Any);
    firestoreData.price = NumberField.toOfflineCache(this.price);
    firestoreData.isVatApplied = BooleanField.toOfflineCache(this.isVatApplied);

    return firestoreData;
  }
}
