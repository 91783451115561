import { BooleanField, StringField } from "@/core/fields";

export class RoleRight {
  public module: string | undefined = undefined;
  public create = false;
  public delete: "all" | "owned" | "custom" | undefined = undefined;
  public read: "all" | "owned" | "custom" | undefined = undefined;
  public update: "all" | "owned" | "custom" | undefined = undefined;
  public viewUI = false;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): RoleRight {
    this.module = StringField.fromFirestore(data.module);
    this.create = BooleanField.fromFirestore(data.create);
    this.delete = StringField.fromFirestore(data.delete) as "all" | "owned" | "custom" | undefined;
    this.read = StringField.fromFirestore(data.read) as "all" | "owned" | "custom" | undefined;
    this.update = StringField.fromFirestore(data.update) as "all" | "owned" | "custom" | undefined;
    this.viewUI = BooleanField.fromFirestore(data.viewUI);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    return {
      module: StringField.toFirestore(this.module),
      create: BooleanField.toFirestore(this.create),
      delete: StringField.toFirestore(this.delete),
      read: StringField.toFirestore(this.read),
      update: StringField.toFirestore(this.update),
      viewUI: BooleanField.toFirestore(this.viewUI),
    };
  }
}
