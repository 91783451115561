import { RouteRecordRaw } from "vue-router";

export const notificationRoutes: RouteRecordRaw[] = [
  {
    path: "/notifications/:notificationGroup?",
    name: "NotificationView",
    component: () => import("../views/NotificationView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "notification",
      requiredRight: "viewUI",
    },
  },
];
