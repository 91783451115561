import { RouteRecordRaw } from "vue-router";

export const serviceTypeRoutes: RouteRecordRaw[] = [
  {
    path: "/service-types/:id/edit",
    name: "ServiceTypeEdit",
    component: () => import("../views/ServiceTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "serviceType",
      requiredRight: "viewUI",
    },
  },
];
