import { ServiceType } from "./ServiceType";

export class ServiceCount {
  public sort: number;
  public type: ServiceType;
  public id: string;
  public name: string;
  public count: number;

  public constructor(sort: number, type: ServiceType, id: string, name: string, count: number) {
    this.sort = sort;
    this.type = type;
    this.id = id;
    this.name = name;
    this.count = count;
  }
}
