import { FamilyHistoryItem } from "./FamilyHistoryItem";

import { ArrayField, StringField } from "@/core/fields";

export class FamilyHistory {
  public items: FamilyHistoryItem[] = [];
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): FamilyHistory {
    this.items = ArrayField.fromFirestore<FamilyHistoryItem>(data.items, (value) => new FamilyHistoryItem(value));
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.items = ArrayField.toFirestore<FamilyHistoryItem>(this.items, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): FamilyHistory {
    this.items = ArrayField.fromOfflineCache<FamilyHistoryItem>(data.items, (value) => {
      const familyHistoryItem: FamilyHistoryItem = new FamilyHistoryItem();
      return familyHistoryItem.fromOfflineCache(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.items = ArrayField.toOfflineCache<FamilyHistoryItem>(this.items, (value) => value.toOfflineCache());
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
