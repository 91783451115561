import { RouteRecordRaw } from "vue-router";

export const vaccineTypeRoutes: RouteRecordRaw[] = [
  {
    path: "/vaccine-types/:id/edit",
    name: "VaccineTypeEdit",
    component: () => import("../views/VaccineTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "vaccineType",
      requiredRight: "viewUI",
    },
  },
];
