import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

export class ExternalTestHelpers {
  public static getFolderPath(firmId: string | undefined, companyId: string | undefined, employeeId: string | undefined): string {
    try {
      if (firmId === undefined) throw new Error("firmId is undefined");
      if (companyId === undefined) throw new Error("companyId is undefined");
      if (employeeId === undefined) throw new Error("employeeId is undefined");
      return `externalTests/${firmId}/${companyId}/${employeeId}/`;
    } catch (error: unknown) {
      appFaultModel.catchAppError("ExternalTestHelpers.getFolderPath", { firmId, companyId, employeeId }, error);
      throw error;
    }
  }
}
