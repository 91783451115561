export const it = {
  supplier: {
    address: "Indirizzo",
    certifiedEmail: "E-mail certificata",
    certifiedEmailError: "Inserire un indirizzo e-mail valido",
    communicationEmail: "E-mail",
    communicationEmailError: "Inserire un indirizzo e-mail valido",
    companyReferents: "Referenti aziendali",
    filters: {
      archived: "Fornitori archiviati",
      archivedExcluded: "Archiviati esclusi",
      archivedIncluded: "Archiviati inclusi",
    },
    fiscalCode: "Codice fiscale",
    info: "Informazioni",
    name: "Ragione sociale",
    nameError: "Inserire la ragione sociale",
    notes: "Note",
    phone: "Telefono",
    priceList: "Listino prezzi",
    province: "Provincia",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attivo",
      archived: "Archiviato",
      archivedOn: "Archiviato il { date }",
    },
    supplier: "Fornitore",
    suppliers: "Fornitori",
    town: "Comune",
    vatCode: "Partita IVA",
    vatCodeError: "Inserire una partita IVA valida e univoca",
    zipCode: "CAP",
  },
  navigation: {
    supplier: "Fornitori",
  },
  buttons: {
    archiveSupplier: "Metti in archivio",
    restoreSupplier: "Togli dall'archivio",
  },
};
