export const it = {
  employee: {
    activeDuties: "Mansioni attive",
    activeDutiesExpirationInfo: "{name} - Scade il {date}",
    activeDutiesWithNoExpiration: "{name} - Nessuna scadenza",
    activeEmployers: "Contratti attivi",
    address: "Indirizzo",
    addresses: "Recapiti",
    archivedOn: "Archiviato il {date}",
    archiving: "Archiviazione",
    birthDate: "Data di nascita",
    birthDateError: "Selezionare una data di nascita valida",
    birthPlace: "Luogo di nascita",
    branch: "Sede",
    branchError: "Selezionare una sede",
    branches: "Sedi",
    company: "Azienda",
    companyError: "Selezionare una azienda",
    companyDuties: "Mansioni aziendali applicate",
    date: "Data",
    dateError: "Selezionare una data valida",
    doctor: "Medico di base",
    doctorName: "Nominativo",
    dutyName: "Nome",
    email: "Indirizzo e-mail",
    emailError: "Inserire un indirizzo e-mail valido",
    employee: "Lavoratore",
    employeeDuties: "Mansioni",
    employees: "Lavoratori",
    employeesOf: "Lavoratori di {parent}",
    employer: "Datore di lavoro",
    employers: "Datori di lavoro",
    endedEmployers: "Contratti terminati",
    examinationCreate: "Crea visita medica",
    examinationCreateErrors: {
      noDutyForCompanyAndDate: "Nessuna mansione disponibile per l'azienda e la data selezionate",
    },
    examinationExempted: "Esente",
    examinations: "Visite mediche",
    examType: "Tipo di visita",
    examTypeError: "Selezionare un tipo di visita",
    expireOn: "scade il",
    filters: {
      archived: "Lavoratori archiviati",
      archivedExcluded: "Archiviati esclusi",
      archivedIncluded: "Archiviati inclusi",
      examinationExempted: "Lavoratori esenti da sorveglianza sanitaria",
      examinationExemptedExcluded: "Esenti esclusi",
      examinationExemptedIncluded: "Esenti inclusi",
      notActive: "Lavoratori cessati",
      notActiveExcluded: "Cessati esclusi",
      notActiveIncluded: "Cessati inclusi",
    },
    firstName: "Nome",
    firstNameError: "Inserire un nome valido",
    fiscalCode: "Codice fiscale",
    fiscalCodeError: "Inserire un codice fiscale valido e univoco",
    frequency: "Periodicità",
    fullName: "Nome",
    info: "Informazioni",
    isExaminationExempted: "Esente da sorveglianza sanitaria",
    isExaminationExemptedError: "Selezionare un valore",
    isSupervisable: "Sorvegliato",
    lastName: "Cognome",
    lastNameError: "Inserire un cognome valido",
    name: "Nome",
    nationality: "Nazionalità",
    nextExaminationDate: "Prossima visita medica",
    noContract: "Attenzione! Questo lavoratore appartiene ad aziende che non hanno nessuna nomina attiva per la ditta selezionata",
    notes: "Note",
    notYetExaminated: "Non ancora visitato",
    personalDuty: "Mansione personale",
    phone: "Telefono",
    province: "Provincia",
    sex: "Sesso",
    sexError: "Selezionare il sesso",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attivo",
      archived: "Archiviato",
      archivedOn: "Archiviato il { date }",
    },
    town: "Comune",
    vaccinations: "Vaccinazioni",
    years: "anni",
    zipCode: "CAP",
  },
  navigation: {
    employee: "Lavoratori",
  },
  buttons: {
    archiveEmployee: "Metti in archivio",
    create: "Crea",
    createExamination: "Crea visita medica",
    downloadEmployeeHealthFolder: "Scarica fascicolo sanitario",
    isExaminationExemptedOn: "Esenta da sorveglianza sanitaria",
    isExaminationExemptedOff: "Attiva sorveglianza sanitaria",
    restoreEmployee: "Togli dall'archivio",
  },
  toast: {
    error: {
      toggleExaminationExempted: "Impossibile aggiornare lo stato",
    },
    success: {
      toggleExaminationExempted: "Stato aggiornato con successo",
    },
    warning: {},
  },
};
