import { Company } from "@/features/modules/company/objects/Company";

export class ExaminationCount {
  public company: Company;
  public count: number;

  public constructor(company: Company, count: number) {
    this.company = company;
    this.count = count;
  }
}
