import { DocumentReference } from "firebase/firestore";

import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedVaccineType } from "@/features/modules/vaccineType/objects/LinkedVaccineType";

import { DateField, DateStrictField, ObjectField, StringField, StringStrictField } from "@/core/fields";

export class Vaccination extends FirestoreOfflineDocument {
  public type: LinkedVaccineType | undefined = undefined;
  public date: Date = new Date();
  public expiration: Date | undefined = undefined;
  public expirationSort = "9999-99-99";

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Vaccination {
    super.fromFirestore(data, id, firestoreRef);

    this.type = ObjectField.fromFirestore<LinkedVaccineType>(data.type, (value) => new LinkedVaccineType(value));
    this.date = DateStrictField.fromFirestore(data.date, new Date());
    this.expiration = DateField.fromFirestore(data.expiration);
    this.expirationSort = StringStrictField.fromFirestore(data.expirationSort, "9999-99-99");

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.type = ObjectField.toFirestore<LinkedVaccineType>(this.type, (value) => value.toFirestore());
    firestoreData.date = DateStrictField.toFirestore(this.date);
    firestoreData.expiration = DateField.toFirestore(this.expiration);
    firestoreData.expirationSort = DateField.toStringValue(this.expiration, "9999-99-99");

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Vaccination {
    super.fromOfflineCache(data);

    this.parentId = StringField.fromOfflineCache(data.parentId);
    this.type = ObjectField.fromOfflineCache<LinkedVaccineType>(data.type, (value) => {
      const linkedVaccineType: LinkedVaccineType = new LinkedVaccineType();
      return linkedVaccineType.fromOfflineCache(value);
    });
    this.date = DateStrictField.fromOfflineCache(data.date, new Date());
    this.expiration = DateField.fromOfflineCache(data.expiration);
    this.expirationSort = StringStrictField.fromOfflineCache(data.expirationSort, "9999-99-99");

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.parentId = this.firestoreRef !== undefined ? this.firestoreRef.parent.parent?.id : StringField.toOfflineCache(this.parentId);
    firestoreData.type = ObjectField.toOfflineCache<LinkedVaccineType>(this.type, (value) => value.toOfflineCache());
    firestoreData.date = DateStrictField.toOfflineCache(this.date);
    firestoreData.expiration = DateField.toOfflineCache(this.expiration);
    firestoreData.expirationSort = DateField.toStringValue(this.expiration, "9999-99-99");

    return firestoreData;
  }
}
