import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KoruActionButton = _resolveComponent("KoruActionButton")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: _normalizeClass(`p-datatable-${_ctx.moduleRef.suffix} ${_ctx.additionalClass}`),
    dataKey: "id",
    filterDisplay: "menu",
    globalFilterFields: _ctx.moduleRef.searchFields,
    responsiveLayout: "scroll",
    showGridlines: "",
    sortField: _ctx.moduleRef.sortField,
    sortOrder: _ctx.moduleRef.sortOrder,
    stateStorage: "session",
    stateKey: `datatable-${_ctx.moduleRef.suffix}-${_ctx.config.app.version.replace(/\./g, '-')}`,
    stripedRows: "",
    value: _ctx.moduleRef.documents.value
  }, {
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("gen.noData")), 1)
    ]),
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("gen.loading")), 1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "columns"),
      (_ctx.moduleRef.canView || _ctx.moduleRef.canEdit || (_ctx.moduleRef.contextCommands !== undefined && _ctx.moduleRef.contextCommands.length > 0))
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            headerStyle: "width: 10%",
            bodyClass: "text-center",
            header: _ctx.t('gen.actions')
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_KoruActionButton, {
                model: _ctx.getContextCommands(data),
                reference: data
              }, null, 8, ["model", "reference"])
            ]),
            _: 1
          }, 8, ["header"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "globalFilterFields", "sortField", "sortOrder", "stateKey", "value"]))
}