import { getDocs, limit, orderBy, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getNextInvoice = async (codeSort: number): Promise<Invoice | undefined> => {
  try {
    if (offlineModel.getOfflineState() === "offline") return undefined;

    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<Invoice> = query(
      invoiceModel.getPathReference().withConverter(invoiceModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("isVirtual", "==", false),
      where("codeSort", ">", codeSort),
      orderBy("codeSort", "asc"),
      limit(1)
    );

    const snapshot: QuerySnapshot<Invoice> = await getDocs(invoiceModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return undefined;

    return snapshot.docs[0].data();
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.getNextInvoice", { codeSort }, error);
    return undefined;
  }
};
