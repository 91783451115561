import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { RachisSurveyForm } from "../objects/RachisSurveyForm";

export class RachisSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private rachisSurveyForm: RachisSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    rachisSurveyForm: RachisSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.rachisSurveyForm = rachisSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      rachisAnswerTable: { fontSize: 8 },
      rachisAnswerValue: { alignment: "center", bold: true },
      rachisNote: {
        color: this.textColor,
        fontSize: 10,
        alignment: "justify",
        margin: [DataHelpers.mmToPoints(60), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
      },
      rachisRow: { color: this.textColor, fontSize: 10 },
      rachisRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      rachisQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("QUESTIONARIO ANAMNESTICO DELLE PATOLOGIE DEL RACHIDE", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle(this.t("surveyForm.rachisSurveyForm.cervicalSection"));
    if (this.rachisSurveyForm.cervicalSection.question1 === true) {
      this.writeThreeQuestions(
        this.t("surveyForm.rachisSurveyForm.section.question1"),
        this.rachisSurveyForm.cervicalSection.question1 === true ? "Sì" : "No",
        this.t("surveyForm.rachisSurveyForm.section.question2"),
        this.rachisSurveyForm.cervicalSection.question2,
        "Assenze per disturbi",
        this.rachisSurveyForm.cervicalSection.question9
      );
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question3"), this.rachisSurveyForm.cervicalSection.question3);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question4"), this.rachisSurveyForm.cervicalSection.question4);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question5"), this.rachisSurveyForm.cervicalSection.question5);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question6"), this.rachisSurveyForm.cervicalSection.question6);
      this.writeOneQuestion(
        this.t("surveyForm.rachisSurveyForm.section.question7cervicalSection"),
        this.rachisSurveyForm.cervicalSection.question7 ? "Sì" : "No"
      );
      this.writeOneQuestion(
        this.t("surveyForm.rachisSurveyForm.section.question8cervicalSection"),
        this.rachisSurveyForm.cervicalSection.question8 ? "Sì" : "No"
      );
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question10"), this.rachisSurveyForm.cervicalSection.question10);
    } else {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question1"), "No");
    }

    this.writeSubtitle(this.t("surveyForm.rachisSurveyForm.dorsalSection"));
    if (this.rachisSurveyForm.dorsalSection.question1 === true) {
      this.writeThreeQuestions(
        this.t("surveyForm.rachisSurveyForm.section.question1"),
        this.rachisSurveyForm.dorsalSection.question1 === true ? "Sì" : "No",
        this.t("surveyForm.rachisSurveyForm.section.question2"),
        this.rachisSurveyForm.dorsalSection.question2,
        "Assenze per disturbi",
        this.rachisSurveyForm.dorsalSection.question9
      );
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question3"), this.rachisSurveyForm.dorsalSection.question3);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question4"), this.rachisSurveyForm.dorsalSection.question4);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question5"), this.rachisSurveyForm.dorsalSection.question5);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question6"), this.rachisSurveyForm.dorsalSection.question6);
      this.writeOneQuestion(
        this.t("surveyForm.rachisSurveyForm.section.question7dorsalSection"),
        this.rachisSurveyForm.dorsalSection.question7 ? "Sì" : "No"
      );
      this.writeOneQuestion(
        this.t("surveyForm.rachisSurveyForm.section.question8dorsalSection"),
        this.rachisSurveyForm.dorsalSection.question8 ? "Sì" : "No"
      );
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question10"), this.rachisSurveyForm.dorsalSection.question10);
    } else {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question1"), "No");
    }

    this.writeSubtitle(this.t("surveyForm.rachisSurveyForm.lumbosacralSection"));
    if (this.rachisSurveyForm.lumbosacralSection.question1 === true) {
      this.writeThreeQuestions(
        this.t("surveyForm.rachisSurveyForm.section.question1"),
        this.rachisSurveyForm.lumbosacralSection.question1 === true ? "Sì" : "No",
        this.t("surveyForm.rachisSurveyForm.section.question2"),
        this.rachisSurveyForm.lumbosacralSection.question2,
        "Assenze per disturbi",
        this.rachisSurveyForm.lumbosacralSection.question9
      );
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question3"), this.rachisSurveyForm.lumbosacralSection.question3);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question4"), this.rachisSurveyForm.lumbosacralSection.question4);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question5"), this.rachisSurveyForm.lumbosacralSection.question5);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question6"), this.rachisSurveyForm.lumbosacralSection.question6);
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question10"), this.rachisSurveyForm.lumbosacralSection.question10);
    } else {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.section.question1"), "No");
    }

    this.writeSubtitle(this.t("surveyForm.rachisSurveyForm.lowBackPainSection"));
    this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question1"), this.rachisSurveyForm.question1 ? "Sì" : "No");
    this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question2"), this.rachisSurveyForm.question2 ? "Sì" : "No");
    if (this.rachisSurveyForm.question3 !== undefined) {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question3"), this.rachisSurveyForm.question3);
    }
    if (this.rachisSurveyForm.question4 !== undefined) {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question4"), this.rachisSurveyForm.question4);
    }
    if (this.rachisSurveyForm.question5 !== undefined) {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question5"), this.rachisSurveyForm.question5);
    }
    if (this.rachisSurveyForm.question6 !== undefined) {
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question6"), this.rachisSurveyForm.question6);
    }

    this.writeSubtitle(this.t("surveyForm.rachisSurveyForm.diagnosisSection"));
    let hasDiagnosisDetails = false;
    if (this.rachisSurveyForm.question7 === true) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(
        this.t("surveyForm.rachisSurveyForm.question7"),
        this.rachisSurveyForm.question8 !== undefined ? this.d(this.rachisSurveyForm.question8, "shortDate") : "-"
      );
    }
    if (this.rachisSurveyForm.question9 === true) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(
        this.t("surveyForm.rachisSurveyForm.question9"),
        this.rachisSurveyForm.question10 !== undefined ? this.d(this.rachisSurveyForm.question10, "shortDate") : "-"
      );
    }
    if (this.rachisSurveyForm.question11 !== undefined) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question11"), this.rachisSurveyForm.question11);
    }
    if (this.rachisSurveyForm.question12 !== undefined) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question12"), this.rachisSurveyForm.question12);
    }
    if (this.rachisSurveyForm.question13 !== undefined) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question13"), this.rachisSurveyForm.question13);
    }
    if (this.rachisSurveyForm.question14 !== undefined) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question14"), this.rachisSurveyForm.question14);
    }
    if (this.rachisSurveyForm.question15 !== undefined) {
      hasDiagnosisDetails = true;
      this.writeOneQuestion(this.t("surveyForm.rachisSurveyForm.question15"), this.rachisSurveyForm.question15);
    }

    if (hasDiagnosisDetails === false) {
      this.writeText("Nulla da segnalare");
    }

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeThreeQuestions(
    textA: string,
    questionA: string | undefined,
    textB: string,
    questionB: string | undefined,
    textC: string,
    questionC: string | undefined
  ): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(40),
          text: textA,
          style: "rachisRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: questionA ?? "-",
          style: "rachisRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: "",
          style: "rachisRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(40),
          text: textB,
          style: "rachisRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: questionB ?? "-",
          style: "rachisRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(40),
          text: textC,
          style: "rachisRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: questionC ?? "-",
          style: "rachisRow",
        },
      ],
    });
  }

  private writeOneQuestion(text: string, value: string | undefined): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(90),
          text: text,
          style: "rachisRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: value ?? "-",
          style: "rachisRow",
        },
      ],
    });
  }

  private writeText(text: string): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
      text: text,
      style: "rachisRow",
    });
  }
}
