import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KoruPdf = _resolveComponent("KoruPdf")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: "dark-dialog",
    closable: true,
    dismissableMask: true,
    modal: true,
    showHeader: false,
    style: {"height":"70vh","width":"30vw"},
    visible: _ctx.isDialogVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDialogVisible) = $event))
  }, {
    default: _withCtx(() => [
      (_ctx.isDialogVisible)
        ? (_openBlock(), _createBlock(_component_KoruPdf, {
            key: 0,
            pdfUrl: _ctx.pdfUrl
          }, null, 8, ["pdfUrl"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}