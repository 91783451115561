export const it = {
  prescription: {
    details: "Dettagli",
    duration: "Durata",
    durationError: "Selezione una durata valida",
    durations: {
      permanent: "Permanente",
      temporary: "Temporanea",
    },
    name: "Nome",
    nameError: "Inserire un nome valido",
    prescription: "Prescrizione",
    prescriptions: "Prescrizioni",
  },
  navigation: {
    prescription: "Prescrizioni",
  },
};
