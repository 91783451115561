export const it = {
  statExaminationSchedule: {
    birthDate: "Data di nascita:",
    company: "Azienda",
    docs: "Documenti",
    doctor: "Medico",
    duty: "Mansione",
    employee: "Lavoratore",
    examination: "Visita medica",
    frequency: "Periodicità",
    name: "Riepilogo visite mediche",
    paperTitleAll: "Riepilogo visite mediche",
    paperTitleByCompany: "Riepilogo visite mediche per azienda",
    paperTitleByCompanyAndDoctor: "Riepilogo visite mediche per azienda e medico",
    paperTitleByDoctor: "Riepilogo visite mediche per medico",
    period: "Periodo",
    signature: "Firma",
    state: "Stato",
    states: {
      all: "Tutte",
      done: "Effettuate",
    },
    time: "Data e ora",
  },
  buttons: {
    statExaminationSchedule: {
      downloadHealthRecordFiles: "Scarica cartelle sanitarie",
      downloadCompanyJudgmentFiles: "Scarica idoneità per datore di lavoro",
      downloadEmployeeJudgmentFiles: "Scarica idoneità per lavoratori",
      print: "Stampa riepilogo",
      printWithSignatures: "Stampa foglio firma",
    },
  },
  toast: {
    error: {
      statExaminationSchedule: {
        companyIdUndefined: "Selezionare un'azienda",
        doctorIdUndefined: "Selezionare un medico",
        periodInvalid: "Periodo non valido",
        periodUndefined: "Selezionare il periodo",
      },
    },
    success: {},
    warning: {},
  },
};
