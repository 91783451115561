import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";

import mimeTypes from "@/core/data/mimeTypes.json";

export class StorageHelpers {
  public static async base64StringToFile(base64String: string, fileName: string): Promise<File> {
    try {
      const mimeTypesObject: Map<string, string> = new Map<string, string>(Object.entries(mimeTypes));

      let mimeType: string | undefined;
      const fileExtension: string | undefined = fileName.split(".").pop()?.toLowerCase();
      if (fileExtension !== undefined && mimeTypesObject.has(fileExtension)) {
        mimeType = mimeTypesObject.get(fileExtension);
      }
      if (mimeType === undefined) {
        throw new Error("file extension not found");
      }

      const blob: Blob = await (await fetch(`data:${mimeType};base64,${base64String}`, { headers: { "Content-Type": mimeType } })).blob();
      return new File([blob], fileName, { type: mimeType });
    } catch (error: unknown) {
      appFaultModel.catchAppError("StorageHelpers.base64StringToFile", { base64String, fileName }, error);
      return new File([], "error");
    }
  }

  public static blobToBase64(blob: Blob): Promise<string | undefined> {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result === null) {
            reject(undefined);
          } else {
            const result: string = reader.result as string;
            resolve(result.slice(result.indexOf(",") + 1));
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error: unknown) {
      appFaultModel.catchAppError("StorageHelpers.blobToBase64", { blob }, error);
      return Promise.resolve(undefined);
    }
  }

  public static async fetchAsBlob(url: string): Promise<Blob> {
    try {
      const response: Response = await fetch(url);
      return response.blob();
    } catch (error: unknown) {
      appFaultModel.catchAppError("StorageHelpers.fetchAsBlob", { url }, error);
      return Promise.resolve(new Blob());
    }
  }
}

/*
public static async convertBlobToBase64(blob: Blob): Promise<unknown> {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageHelpers.convertBlobToBase64", { blob }, error);
    return Promise.resolve("ERROR");
  }
}
*/
