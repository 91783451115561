import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "../objects/Examination";
import { SurveyFormHelpers } from "@/features/modules/surveyForm/helpers/SurveyFormHelpers";
import { TestFormHelpers } from "@/features/modules/testForm/helpers/TestFormHelpers";

export class AttachmentPaper extends DocumentPaper {
  constructor(
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      healthNote: { color: this.textColor, fontSize: 8, alignment: "justify", margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)] },
      healthRow: { color: this.textColor, fontSize: 10 },
      healthRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      healthQuestion: { color: this.textColor, fontSize: 8 },
      healthSmall: { color: this.textColor, fontSize: 8 },
      healthSmallBold: { bold: true, color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("ALLEGATI ALLA CARTELLA SANITARIA", isContinuos);
    this.docDefinition.content = [];

    let isContinuousFirstFalse = false;

    for (const examinationTest of this.examination.getAllTests()) {
      if (examinationTest.testForm === undefined || examinationTest.testForm.isCompleted === false) continue;
      const content: Record<string, unknown>[] = await TestFormHelpers.writeTestForm(
        examinationTest,
        this.examination,
        this.d,
        this.n,
        this.t,
        isContinuousFirstFalse
      );
      this.docDefinition.content = [...(this.docDefinition.content as Record<string, unknown>[]), content];

      const styles: Record<string, unknown> = await TestFormHelpers.setStylesTestForm(examinationTest, this.examination, this.d, this.n, this.t);
      this.docDefinition.styles = { ...(this.docDefinition.styles as Record<string, unknown>), ...styles };

      isContinuousFirstFalse = true;
    }

    for (const survey of this.examination.getLinkedSurveys()) {
      if (survey.surveyForm === undefined || survey.surveyForm.isCompleted === false) continue;
      const content: Record<string, unknown>[] = await SurveyFormHelpers.writeSurveyForm(
        survey,
        this.examination,
        this.d,
        this.n,
        this.t,
        isContinuousFirstFalse
      );
      this.docDefinition.content = [...(this.docDefinition.content as Record<string, unknown>[]), content];

      const styles: Record<string, unknown> = await SurveyFormHelpers.setStylesSurveyForm(survey, this.examination, this.d, this.n, this.t);
      this.docDefinition.styles = { ...(this.docDefinition.styles as Record<string, unknown>), ...styles };

      isContinuousFirstFalse = true;
    }

    return this.docDefinition.content as Record<string, unknown>[];
  }
}
