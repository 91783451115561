export const it = {
  auth: {
    askNewPassword: "Richiedi Nuova Password",
    authenticationSubtitle: "Accedi con il tuo account",
    authenticationTitle: "Autenticazione",
    checkInbox: "Controlla la tua casella e-mail",
    choosePassword: "Scegli una nuova password",
    email: "E-mail",
    emailBlank: "Inserisci l'indirizzo e-mail",
    emailError: "L'indirizzo e-mail non è valido",
    forgotError: "Non è stato possibile recuperare la password",
    forgotPasswordTitle: "Recupera Password",
    forgotPasswordStart: "Non ricordi la password?",
    forgotPasswordMiddle: "Richiedi",
    forgotPasswordEnd: "il recupero subito!",
    inputEmail: "Inserisci il tuo indirizzo e-mail",
    login: "Accedi",
    loginFailed: "Autenticazione non riuscita",
    password: "Password",
    passwordBlank: "Inserisci la nuova password",
    passwordConfirm: "Conferma Nuova Password",
    passwordConfirmBlank: "Inserisci la conferma della nuova password",
    passwordConfirmError: "Le due password non coincidono",
    passwordError: "Inserisci una password valida",
    passwordErrorChars: "La password deve essere di almeno 8 caratteri",
    passwordErrorLowercase: "Inserisci almeno un carattere minuscolo",
    passwordErrorNumber: "Inserisci almeno una cifra numerica",
    passwordErrorUppercase: "Inserisci almeno un carattere maiuscolo",
    passwordRequirementsTitle: "Requisiti",
    passwordRequirementsChars: "Minimo 8 caratteri",
    passwordRequirementsLowercase: "Almeno un carattere minuscolo",
    passwordRequirementsNumber: "Almeno una cifra numerica",
    passwordRequirementsUppercase: "Almeno un carattere maiuscolo",
    passwordRememberedStart: "Ricordi la password? Torna all'",
    passwordRememberedEnd: "autenticazione",
    recoverPasswordTitle: "Ripristina Password",
    resetPassword: "Reimposta Password",
    resetPasswordError: "Non è stato possibile reimpostare la password",
    resetPasswordSuccessStart: "La nuova password è stata impostata. Vai all'",
    resetPasswordSuccessEnd: "autenticazione",
  },
};
