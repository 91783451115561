import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Role } from "../objects/Role";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class RoleModel extends FirestoreModel<Role> {
  public constructor() {
    super(() => new Role(), "roles", LockPolicy.DiscardUnsyncedChanges, "role");
    this.beforeDeleteFunction = "coreRoleBeforeDelete";
  }

  public async getDocuments(): Promise<Role[]> {
    return super.getDocuments([new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(roleId: string): Promise<Role> {
    return super.getDocument(roleId);
  }

  public async createDocument(role: Role): Promise<string> {
    return super.createDocument(role);
  }

  public async updateDocument(role: Role): Promise<void> {
    return super.updateDocument(role);
  }

  public async deleteDocument(role: Role): Promise<boolean> {
    return super.deleteDocument(role);
  }
}

export const roleModel: RoleModel = new RoleModel();
