export class StringStrictField {
  public static fromFirestore(value: unknown, defaultValue: string): string {
    if (value === null || value === undefined) return defaultValue;
    if (String(value).trim().length === 0) return defaultValue;

    return String(value).trim();
  }

  public static toFirestore(value: string): string {
    return value.trim();
  }

  public static fromOfflineCache(value: unknown, defaultValue: string): string {
    return StringStrictField.fromFirestore(value, defaultValue);
  }

  public static toOfflineCache(value: string): string {
    return StringStrictField.toFirestore(value);
  }
}
