import { RouteRecordRaw } from "vue-router";

export const docTypeRoutes: RouteRecordRaw[] = [
  {
    path: "/doc-types/:id/edit",
    name: "DocTypeEdit",
    component: () => import("../views/DocTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "docType",
      requiredRight: "viewUI",
    },
  },
];
