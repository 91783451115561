import { DocumentReference } from "firebase/firestore";

import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { Referent } from "@/features/modules/referent/objects/Referent";
import { supplierModel } from "../models/SupplierModel";

import { AddressField, ArrayField, BooleanField, DateField, StringField } from "@/core/fields";

export class Supplier extends FirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public address: AddressField = new AddressField();
  public town: string | undefined = undefined;
  public zipCode: string | undefined = undefined;
  public province: string | undefined = undefined;
  public phone: string | undefined = undefined;
  public vatCode: string | undefined = undefined;
  public fiscalCode: string | undefined = undefined;
  public communicationEmail: string | undefined = undefined;
  public certifiedEmail: string | undefined = undefined;
  public companyReferents: Referent[] = [];
  public notes: string | undefined = undefined;
  public archived = false;
  public archivedDate: Date | undefined = undefined;
  public trackExpirations = true;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Supplier {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.vatCode = StringField.fromFirestore(data.vatCode);
    this.fiscalCode = StringField.fromFirestore(data.fiscalCode);
    this.communicationEmail = StringField.fromFirestore(data.communicationEmail);
    this.certifiedEmail = StringField.fromFirestore(data.certifiedEmail);
    this.companyReferents = ArrayField.fromFirestore<Referent>(data.companyReferents, (value) => new Referent(value));
    this.notes = StringField.fromFirestore(data.notes);
    this.archived = BooleanField.fromFirestore(data.archived);
    this.archivedDate = DateField.fromFirestore(data.archivedDate);
    this.trackExpirations = BooleanField.fromFirestore(data.trackExpirations);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.vatCode = StringField.toFirestore(this.vatCode);
    firestoreData.fiscalCode = StringField.toFirestore(this.fiscalCode);
    firestoreData.communicationEmail = StringField.toFirestore(this.communicationEmail);
    firestoreData.certifiedEmail = StringField.toFirestore(this.certifiedEmail);
    firestoreData.companyReferents = ArrayField.toFirestore<Referent>(this.companyReferents, (value) => value.toFirestore());
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.archived = BooleanField.toFirestore(this.archived);
    firestoreData.archivedDate = DateField.toFirestore(this.archivedDate);
    firestoreData.trackExpirations = BooleanField.toFirestore(this.trackExpirations);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Supplier {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.address.fromOfflineCache(data.address);
    this.phone = StringField.fromOfflineCache(data.phone);
    this.vatCode = StringField.fromOfflineCache(data.vatCode);
    this.fiscalCode = StringField.fromOfflineCache(data.fiscalCode);
    this.communicationEmail = StringField.fromOfflineCache(data.communicationEmail);
    this.certifiedEmail = StringField.fromOfflineCache(data.certifiedEmail);
    this.companyReferents = ArrayField.fromOfflineCache<Referent>(data.companyReferents, (value) => {
      const referent: Referent = new Referent();
      return referent.fromOfflineCache(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);
    this.archived = BooleanField.fromOfflineCache(data.archived);
    this.archivedDate = DateField.fromOfflineCache(data.archivedDate);
    this.trackExpirations = BooleanField.fromOfflineCache(data.trackExpirations);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.address = this.address.toOfflineCache();
    firestoreData.phone = StringField.toOfflineCache(this.phone);
    firestoreData.vatCode = StringField.toOfflineCache(this.vatCode);
    firestoreData.fiscalCode = StringField.toOfflineCache(this.fiscalCode);
    firestoreData.communicationEmail = StringField.toOfflineCache(this.communicationEmail);
    firestoreData.certifiedEmail = StringField.toOfflineCache(this.certifiedEmail);
    firestoreData.companyReferents = ArrayField.toOfflineCache<Referent>(this.companyReferents, (value) => value.toOfflineCache());
    firestoreData.notes = StringField.toOfflineCache(this.notes);
    firestoreData.archived = BooleanField.toOfflineCache(this.archived);
    firestoreData.archivedDate = DateField.toOfflineCache(this.archivedDate);
    firestoreData.trackExpirations = BooleanField.toOfflineCache(this.trackExpirations);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = DataHelpers.createSearchKeys(this.name);
    if (this.vatCode !== undefined) this.searchKeys.push(this.vatCode.toLowerCase());
    if (this.fiscalCode !== undefined) this.searchKeys.push(this.fiscalCode.toLowerCase());
  }

  public async handleArchive(): Promise<void> {
    if (this.id === "new") {
      this.archivedDate = this.archived === true ? new Date() : undefined;
    } else {
      const oldSupplier: Supplier = await supplierModel.getDocument(this.id);
      if (oldSupplier.archived !== this.archived) {
        this.archivedDate = this.archived === true ? new Date() : undefined;
      }
    }
  }
}
