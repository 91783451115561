import { Ref, ref } from "vue";

import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";

export class KoruEditorModule<T> {
  public document: Ref<T>;

  public constructor(newIstance: T) {
    this.document = ref(newIstance) as Ref<T>;
  }

  public breadcrumbs: Ref<Record<string, unknown>[]> = ref([]);
  public canCreate = true;
  public canEdit = true;
  public rules: Record<string, unknown> = {};
  public saveCommands: ContextCommand[] = [];
  public submitCommands: ContextCommand[] = [];
  public suffix: string | undefined = undefined;

  public afterSubmitAction = undefined;

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }

  public onSubmitAction(): Promise<void> {
    return Promise.resolve();
  }

  public onUnloadAction(): Promise<void> {
    return Promise.resolve();
  }
}
