import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { EventHandler } from "./EventHandler";

export class EventBus {
  private busHandlers: Record<string, unknown>;

  public constructor() {
    this.busHandlers = {};
  }

  public on<T>(type: string, handler: (param?: T) => Promise<void>): string {
    const newHandler: EventHandler<T> = {
      type: type,
      handler: handler,
    };

    let newHandlerId: string = DataHelpers.uniqueId();
    while (newHandlerId in this.busHandlers) {
      newHandlerId = DataHelpers.uniqueId();
    }

    this.busHandlers[newHandlerId] = newHandler;

    return newHandlerId;
  }

  public off(handlerId: string): void {
    if (handlerId in this.busHandlers) delete this.busHandlers[handlerId];
  }

  public async emit<T>(type: string, param?: T): Promise<void> {
    for (const handlerId in this.busHandlers) {
      const handler = this.busHandlers[handlerId] as EventHandler<T>;
      if (handler.type === type) {
        try {
          await handler.handler(param);
        } catch (error: unknown) {
          try {
            appFaultModel.catchAppError("EventBus.emit", { type, param }, error);
          } catch (error: unknown) {
            if (error instanceof AppError) {
              console.log(error);
              appFaultModel.createAppFaultFromAppError(error);
            }
          }
        }
      }
    }
  }

  public has(type: string): boolean {
    for (const handlerId in this.busHandlers) {
      const handler = this.busHandlers[handlerId] as EventHandler<unknown>;
      if (handler.type === type) return true;
    }
    return false;
  }

  public reset(type: string): void {
    for (const handlerId in this.busHandlers) {
      const handler = this.busHandlers[handlerId] as EventHandler<unknown>;
      if (handler.type === type) delete this.busHandlers[handlerId];
    }
  }
}

export const eventBus = new EventBus();
