import { getDoc, setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { EmployeeDuty } from "../objects/EmployeeDuty";
import { employeeDutyModel } from "./EmployeeDutyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class EmployeeDutyOfflineModel extends OfflineModuleModel<EmployeeDuty> {
  public constructor() {
    super(() => new EmployeeDuty(), "employeeDuties", "employeeDuty.employeeDuties", "employeeDuty");
    this.canCreate = true;
    this.canUpdate = true;
  }

  public async downloadCache(): Promise<EmployeeDuty[]> {
    try {
      if (companyModel.offlineModuleModel === undefined) return [];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let employeeDuties: EmployeeDuty[] = [];
      for (const company of companies) {
        employeeDuties = [...employeeDuties, ...(await employeeDutyModel.getEmployeeDutiesByCompany(company.id))];
      }

      return employeeDuties;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeDutyOfflineModel.downloadCache", {}, error);
      return [];
    }
  }

  public async createDocumentCallback(document: EmployeeDuty): Promise<boolean> {
    try {
      const doc = await getDoc(employeeDutyModel.getDocumentReference(document.id, document.parentId));
      if (doc.exists() === true) throw new Error("id duplicated");

      document.setSearchKeys();
      document.setTimestampFields("create");

      await setDoc(
        employeeDutyModel.getDocumentReference(document.id, document.parentId).withConverter(employeeDutyModel.firestoreConverter),
        document
      );

      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeDutyOfflineModel.createDocumentCallback", { document }, error);
      return false;
    }
  }

  public async updateDocumentCallback(document: EmployeeDuty): Promise<boolean> {
    try {
      const oldDocument: EmployeeDuty = await employeeDutyModel.getDocument(document.id, document.parentId);
      if (document.hasChangedFrom(oldDocument)) throw new Error("sync");

      await employeeDutyModel.updateDocument(document, document.parentId);
      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeDutyOfflineModel.updateDocumentCallback", { document }, error);
      return false;
    }
  }
}

export const employeeDutyOfflineModel: EmployeeDutyOfflineModel = new EmployeeDutyOfflineModel();
