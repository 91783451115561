import { runTransaction, Transaction } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

export const runFirestoreTransaction = (performActions: (transaction: Transaction) => Promise<void>): Promise<unknown> => {
  return runTransaction(firebase.firestore, async (transaction: Transaction) => {
    try {
      await performActions(transaction);
    } catch (error: unknown) {
      appFaultModel.catchAppError("FirestoreModel.runFirestoreTransaction", { transaction }, error);
      return [];
    }
  });
};
