
import { defineComponent, onMounted, Ref, ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { Company } from "@/features/modules/company/objects/Company";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { companyDutyModel } from "@/features/modules/companyDuty/models/CompanyDutyModel";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { EmployeeByDuty } from "../objects/EmployeeByDuty";

import { employeeByDutyModel } from "../models/EmployeeByDutyModel";
import { EmployeeByDutyPaper } from "../papers/EmployeeByDutyPaper";
import { storeTypes } from "@/core/modules/store/types";
import { TestTypeCategory } from "@/features/modules/testTypeCategory/objects/TestTypeCategory";
import { testTypeCategoryModel } from "@/features/modules/testTypeCategory/models/TestTypeCategoryModel";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "EmployeeByDutyView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<EmployeeByDuty[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const companyId: Ref<string | undefined> = ref(undefined);
    const companyDutyId: Ref<string | undefined> = ref(undefined);
    const testTypeCategoryId: Ref<string | undefined> = ref(undefined);

    const companies: Ref<Company[]> = ref([]);
    const companyDuties: Ref<CompanyDuty[]> = ref([]);
    const testTypeCategories: Ref<TestTypeCategory[]> = ref([]);

    const showResults: Ref<boolean> = ref(false);

    const loadAction = async () => {
      companies.value = await companyModel.getActiveCompanies();
      testTypeCategories.value = await testTypeCategoryModel.getDocuments();
    };

    const submitAction = async () => {
      showResults.value = false;
      if (companyId.value === undefined) throw new Error("statEmployeeByDuty.companyIdUndefined");
      if (companyDutyId.value === undefined) throw new Error("statEmployeeByDuty.companyDutyIdUndefined");
      if (testTypeCategoryId.value === undefined) throw new Error("statEmployeeByDuty.testTypeCategoryIdUndefined");

      results.value = await employeeByDutyModel.searchEmployeesByDuty(companyId.value, companyDutyId.value, testTypeCategoryId.value);

      showResults.value = true;
    };

    const dataChanged = async () => {
      AppHelpers.tryOrError(
        async () => {
          showResults.value = false;
          if (companyId.value === undefined) return;

          store.commit(storeTypes.mutations.loadingStart);
          companyDuties.value = await companyDutyModel.getDocuments([], companyId.value);
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const printPaper = () => {
      AppHelpers.tryOrToast(
        async () => {
          store.commit(storeTypes.mutations.loadingStart);

          const employeeByDutyPaper: EmployeeByDutyPaper = new EmployeeByDutyPaper(
            companies.value.find((company) => company.id === companyId.value) as Company,
            companyDuties.value.find((companyDuty) => companyDuty.id === companyDutyId.value) as CompanyDuty,
            testTypeCategories.value.find((testTypeCategory) => testTypeCategory.id === testTypeCategoryId.value) as TestTypeCategory,
            results.value,
            d,
            n,
            t
          );
          employeeByDutyPaper.open();
        },
        "actionError",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    onMounted(() => {
      handleLoad();
    });

    return {
      companies,
      companyDuties,
      companyDutyId,
      companyId,
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      n,
      printPaper,
      results,
      showResults,
      t,
      testTypeCategories,
      testTypeCategoryId,
    };
  },
});
