import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getExpiringDoctorDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const doctors: Doctor[] = await doctorModel.getDoctorsWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("expiringDocs", DocTypeSource.Doctor);

    for (const doctor of doctors) {
      if (doctor.trackExpirations === false) continue;

      const doctorWidget: Widget | undefined = widgets.find((widget) => widget.source === DocTypeSource.Doctor && widget.sourceId === doctor.id);
      if (doctorWidget === undefined) continue;
      if (doctorWidget.values.count === 0) continue;
      if (doctorWidget.values.expirations == undefined) continue;

      const expirations: Record<string, Timestamp> = doctorWidget.values.expirations as Record<string, Timestamp>;
      for (const docTypeId of Object.keys(expirations)) {
        docExpirations.push(new DocExpiration(doctor.id, doctor.fullNameWithTitle ?? "-", expirations[docTypeId].toDate(), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorDocModel.getExpiringDoctorDocs", {}, error);
    return [];
  }
};
