import { Activity } from "./Activity";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { NumberWithDefaultField, StringField } from "@/core/fields";

export class LinkedActivity extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;
  public icon: string | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromActivity(activity: Activity): LinkedActivity {
    const linkedActivity: LinkedActivity = new LinkedActivity();
    linkedActivity.id = activity.id;
    linkedActivity.name = activity.name;
    linkedActivity.icon = activity.icon;
    linkedActivity.order = activity.order;

    return linkedActivity;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedActivity {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.icon = StringField.fromFirestore(data.icon);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.icon = StringField.toFirestore(this.icon);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }
}
