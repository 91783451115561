import { RouteRecordRaw } from "vue-router";

export const employerRoutes: RouteRecordRaw[] = [
  {
    path: "/employees/:employeeId/employers/:employerId/edit",
    name: "EmployerEdit",
    component: () => import("../views/EmployerEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employer",
      requiredRight: "viewUI",
    },
  },
];
