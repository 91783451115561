import { RouteRecordRaw } from "vue-router";

export const supplierRoutes: RouteRecordRaw[] = [
  {
    path: "/suppliers",
    name: "SupplierList",
    component: () => import("../views/SupplierList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "supplier",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/suppliers/:id/edit",
    name: "SupplierEdit",
    component: () => import("../views/SupplierEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "supplier",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/suppliers/:id/:section?",
    name: "SupplierView",
    component: () => import("../views/SupplierView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "supplier",
      requiredRight: "viewUI",
    },
  },
];
