import { NullableNumberField, StringField } from "@/core/fields";

export class AddressField {
  public street: string | undefined = undefined;
  public town: string | undefined = undefined;
  public zipCode: string | undefined = undefined;
  public province: string | undefined = undefined;
  public country: string | undefined = undefined;
  public latitude: number | undefined = undefined;
  public longitude: number | undefined = undefined;
  public geoHash: string | undefined = undefined;

  public constructor(
    street?: string,
    town?: string,
    zipCode?: string,
    province?: string,
    country?: string,
    latitude?: number,
    longitude?: number,
    geoHash?: string
  ) {
    this.street = street;
    this.town = town;
    this.zipCode = zipCode;
    this.province = province;
    this.country = country;
    this.latitude = latitude;
    this.longitude = longitude;
    this.geoHash = geoHash;
  }

  public fromFirestore(value: unknown): void {
    if (value == null) {
      this.street = undefined;
      this.town = undefined;
      this.zipCode = undefined;
      this.province = undefined;
      this.country = undefined;
      this.latitude = undefined;
      this.longitude = undefined;
      this.geoHash = undefined;
      return;
    }
    const address: Record<string, unknown> = value as Record<string, unknown>;
    this.street = StringField.fromFirestore(address.street);
    this.town = StringField.fromFirestore(address.town);
    this.zipCode = StringField.fromFirestore(address.zipCode);
    this.province = StringField.fromFirestore(address.province);
    this.country = StringField.fromFirestore(address.country);
    this.latitude = NullableNumberField.fromFirestore(address.latitude);
    this.longitude = NullableNumberField.fromFirestore(address.longitude);
    this.geoHash = StringField.fromFirestore(address.geoHash);
  }

  public toFirestore(): Record<string, unknown> {
    return {
      street: StringField.toFirestore(this.street),
      town: StringField.toFirestore(this.town),
      zipCode: StringField.toFirestore(this.zipCode),
      province: StringField.toFirestore(this.province),
      country: StringField.toFirestore(this.country),
      latitude: NullableNumberField.toFirestore(this.latitude),
      longitude: NullableNumberField.toFirestore(this.longitude),
      geoHash: StringField.toFirestore(this.geoHash),
    };
  }

  public fromOfflineCache(value: unknown): void {
    this.fromFirestore(value);
  }

  public toOfflineCache(): Record<string, unknown> {
    return this.toFirestore();
  }

  public toString(): string {
    return `${this.street}, ${this.town}, ${this.zipCode}, ${this.province}, ${this.country}, ${this.latitude}, ${this.longitude}, ${this.geoHash}`;
  }
}
