import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "../WidgetModel";

export const getWidgetsByTypeAndSource = async (type: string, source: string): Promise<Widget[]> => {
  try {
    if (type == undefined) throw new Error("Type is undefined");
    if (source == undefined) throw new Error("Source is undefined");

    const snapshotQuery: Query<Widget> = query(
      widgetModel.getPathReference().withConverter(widgetModel.firestoreConverter),
      where("type", "==", type),
      where("source", "==", source)
    );

    const snapshot: QuerySnapshot<Widget> = await getDocs(widgetModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("WidgetModel.getWidgetsByTypeAndSource", { type, source }, error);
    return [];
  }
};
