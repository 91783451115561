import { RouteRecordRaw } from "vue-router";

export const employeeRoutes: RouteRecordRaw[] = [
  {
    path: "/employees",
    name: "EmployeeList",
    component: () => import("../views/EmployeeList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/:id/edit",
    name: "EmployeeEdit",
    component: () => import("../views/EmployeeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/:id/:section?",
    name: "EmployeeView",
    component: () => import("../views/EmployeeView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employee",
      requiredRight: "viewUI",
    },
  },
];
