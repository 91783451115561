import { RouteRecordRaw } from "vue-router";

export const offlineRoutes: RouteRecordRaw[] = [
  {
    path: "/offline",
    name: "OfflineView",
    component: () => import("../views/OfflineView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "offline",
      requiredRight: "viewUI",
    },
  },
];
