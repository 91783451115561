import { RouteRecordRaw } from "vue-router";

export const testTypeRoutes: RouteRecordRaw[] = [
  {
    path: "/test-types/:id/edit",
    name: "TestTypeEdit",
    component: () => import("../views/TestTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "testType",
      requiredRight: "viewUI",
    },
  },
];
