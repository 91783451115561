import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationTest } from "@/features/modules/examination/objects/ExaminationTest";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { VisioTestForm } from "../objects/VisioTestForm";
import { VisioTestFormPaper } from "../papers/VisioTestFormPaper";

import examinationAudioTestNotes from "@/features/data/examinationAudioTestNotes.json";

export class TestFormHelpers {
  public static applyNotesItemsCommand(noteItem: Record<string, unknown>, pieces: string[]) {
    if (noteItem.command !== undefined) return;
    if (noteItem.label === undefined) return;
    pieces.push(noteItem.label as string);
    if (noteItem.items === undefined || (noteItem.items as Record<string, unknown>[]).length === 0) {
      const noteItemText: string = pieces.join(" ");
      noteItem.command = () => {
        eventBus.emit("examinationAudioTestNoteClicked", noteItemText);
      };
    } else {
      for (const subTagItem of noteItem.items as Record<string, unknown>[]) {
        TestFormHelpers.applyNotesItemsCommand(subTagItem, [...pieces]);
      }
    }
  }

  public static async printTestForm(
    examinationTest: ExaminationTest,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<void> {
    if (examinationTest.testType?.formName === "visioTestForm") {
      const visioTestFormPaper: VisioTestFormPaper = new VisioTestFormPaper(
        examination,
        examinationTest,
        examinationTest.testForm as VisioTestForm,
        d,
        n,
        t
      );
      await visioTestFormPaper.open();
    }
  }

  public static async outputTestForm(
    examinationTest: ExaminationTest,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<string | undefined> {
    if (examinationTest.testType?.formName === "visioTestForm") {
      const visioTestFormPaper: VisioTestFormPaper = new VisioTestFormPaper(
        examination,
        examinationTest,
        examinationTest.testForm as VisioTestForm,
        d,
        n,
        t
      );
      return await visioTestFormPaper.outputBase64();
    }
    return undefined;
  }

  public static renderAudioTestFormNotes(): Record<string, unknown>[] {
    const noteItems: Record<string, unknown>[] = examinationAudioTestNotes;

    for (const noteItem of noteItems) {
      TestFormHelpers.applyNotesItemsCommand(noteItem, []);
    }

    return noteItems;
  }

  public static async setStylesTestForm(
    examinationTest: ExaminationTest,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ): Promise<Record<string, unknown>> {
    if (examinationTest.testType?.formName === "visioTestForm") {
      const visioTestFormPaper: VisioTestFormPaper = new VisioTestFormPaper(
        examination,
        examinationTest,
        examinationTest.testForm as VisioTestForm,
        d,
        n,
        t
      );
      return visioTestFormPaper.setStyles();
    }
    return {};
  }

  public static async writeTestForm(
    examinationTest: ExaminationTest,
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    isContinuous = true
  ): Promise<Record<string, unknown>[]> {
    if (examinationTest.testType?.formName === "visioTestForm") {
      const visioTestFormPaper: VisioTestFormPaper = new VisioTestFormPaper(
        examination,
        examinationTest,
        examinationTest.testForm as VisioTestForm,
        d,
        n,
        t
      );
      return visioTestFormPaper.write(isContinuous);
    }
    return [];
  }
}
