import { Ref } from "vue";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { batch } from "@/core/modules/batch/objects/Batch";
import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationHelpers } from "../ExaminationHelpers";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { ExaminationState } from "@/features/modules/examination/objects/ExaminationState";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { HealthRecordPaper } from "@/features/modules/examination/paper/HealthRecordPaper";
import { JudgmentForCompanyPaper } from "@/features/modules/examination/paper/JudgmentForCompanyPaper";
import { JudgmentForEmployeePaper } from "@/features/modules/examination/paper/JudgmentForEmployeePaper";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";

export function getListContextCommands(
  documents: Ref<Examination[]>,
  d: (date: Date, format: string) => string,
  n: (value: number, format: string) => string,
  t: (entry: string, params?: Record<string, unknown>) => string
): ContextCommand[] {
  return [
    {
      label: t("buttons.printJudgmentForEmployee"),
      icon: "pi pi-print",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.employeeJudgmentFile === undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            const judgmentForEmployeePaper: JudgmentForEmployeePaper = new JudgmentForEmployeePaper(examination, d, n, t);
            await judgmentForEmployeePaper.open();
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.openJudgmentForEmployee"),
      icon: "pi pi-verified",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.employeeJudgmentFile !== undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            const fileUrl: string = await storageModel.getDownloadLink(
              ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
              examination.employeeJudgmentFile?.uniqueName as string
            );
            window.open(fileUrl, "_blank");
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.printJudgmentForCompany"),
      icon: "pi pi-print",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.companyJudgmentFile === undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            const judgmentForCompanyPaper: JudgmentForCompanyPaper = new JudgmentForCompanyPaper(examination, d, n, t);
            await judgmentForCompanyPaper.open();
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.openJudgmentForCompany"),
      icon: "pi pi-verified",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.companyJudgmentFile !== undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            const fileUrl: string = await storageModel.getDownloadLink(
              ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
              examination.companyJudgmentFile?.uniqueName as string
            );
            window.open(fileUrl, "_blank");
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.printHealthRecord"),
      icon: "pi pi-print",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.healthRecordFile === undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            const healthRecordPaper: HealthRecordPaper = new HealthRecordPaper(examination, d, n, t);
            await healthRecordPaper.open();
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.openHealthRecord"),
      icon: "pi pi-verified",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.healthRecordFile !== undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            const fileUrl: string = await storageModel.getDownloadLink(
              ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
              examination.healthRecordFile?.uniqueName as string
            );
            window.open(fileUrl, "_blank");
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.printAttachments"),
      icon: "pi pi-print",
      condition: (document: FirestoreDocument) => {
        const examination: Examination = document as Examination;
        return examination.healthRecordFile === undefined;
      },
      command: async (object: Record<string, unknown>) => {
        const examination: Examination = object.reference as Examination;

        store.commit(storeTypes.mutations.loadingStart);
        AppHelpers.tryOrToast(
          async () => {
            await ExaminationHelpers.printMergedAttachments(examination, d, n, t);
          },
          "readFile",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: "separator",
      icon: "",
      condition: (firestoreDocument: FirestoreDocument) => {
        const examination: Examination = firestoreDocument as Examination;
        return examination.isPublic === true || examination.state !== ExaminationState.Draft;
      },
      command: () => {
        return;
      },
    },
    {
      label: t("buttons.isPublicEnable"),
      icon: "pi pi-eye",
      condition: (firestoreDocument: FirestoreDocument) => {
        const examination: Examination = firestoreDocument as Examination;
        return examination.isPublic === false && examination.state !== ExaminationState.Draft;
      },
      command: async (object: Record<string, unknown>) => {
        const reference: Examination = object.reference as Examination;
        store.commit(storeTypes.mutations.loadingStart);

        await AppHelpers.tryOrToast(
          async () => {
            await toggleExaminationIsPublic(reference);

            const toggleIndex = documents.value.indexOf(reference, 0);
            documents.value[toggleIndex] = reference;
          },
          "toggleExaminationIsPublic",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
    {
      label: t("buttons.isPublicDisable"),
      icon: "pi pi-eye-slash",
      condition: (firestoreDocument: FirestoreDocument) => {
        const examination: Examination = firestoreDocument as Examination;
        return examination.isPublic === true;
      },
      command: async (object: Record<string, unknown>) => {
        const reference: Examination = object.reference as Examination;
        store.commit(storeTypes.mutations.loadingStart);

        await AppHelpers.tryOrToast(
          async () => {
            await toggleExaminationIsPublic(reference);

            const toggleIndex = documents.value.indexOf(reference, 0);
            documents.value[toggleIndex] = reference;
          },
          "toggleExaminationIsPublic",
          t,
          () => store.commit(storeTypes.mutations.loadingStop)
        );
      },
    },
  ];
}

async function toggleExaminationIsPublic(examination: Examination): Promise<void> {
  examination.isPublic = !examination.isPublic;
  batch.update(examinationModel.getDocumentReference(examination.id), { isPublic: examination.isPublic });
  await batch.commit();
}
