export class SortCriteria {
  public field: string;
  public direction: "asc" | "desc";
  public type: "boolean" | "date" | "number" | "string";

  public constructor(field: string, direction: "asc" | "desc", type: "boolean" | "date" | "number" | "string") {
    this.field = field;
    this.direction = direction;
    this.type = type;
  }
}
