import { onSnapshot, Query, query, where } from "firebase/firestore";
import { Ref } from "vue";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Notification } from "@/core/modules/notification/objects/Notification";
import { notificationModel } from "../NotificationModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const startNotificationListener = (notifications: Ref<Notification[]>): void => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    const snapshotQuery: Query<Notification> = query(
      notificationModel.getPathReference().withConverter(notificationModel.firestoreConverter),
      where("owners", "array-contains", user.id)
    );

    notificationModel.notificationListenerHandler = onSnapshot(snapshotQuery, (snapshot) => {
      const snapshotNotifications: Notification[] = snapshot.docs
        .map((doc) => doc.data())
        .filter((notification) => notification.unread.includes(user.id));

      notifications.value = [...snapshotNotifications];
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("NotificationModel.startNotificationListener", { notifications }, error);
  }
};
