import { Broker } from "./Broker";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { StringField } from "@/core/fields";

export class LinkedBroker extends LinkedFirestoreDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromBroker(broker: Broker): LinkedBroker {
    const linkedBroker: LinkedBroker = new LinkedBroker();
    linkedBroker.id = broker.id;
    linkedBroker.name = broker.name;

    return linkedBroker;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedBroker {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
