export class EnumField {
  public static fromFirestore<T>(value: unknown, enums: string[], defaultValue: T): T {
    if (enums.includes(value as string)) return value as T;

    return defaultValue;
  }

  public static toFirestore<T>(value: T, defaultValue: T): T {
    if (value === null || value === undefined) return defaultValue;

    return value;
  }

  public static fromOfflineCache<T>(value: unknown, enums: string[], defaultValue: T): T {
    return EnumField.fromFirestore<T>(value, enums, defaultValue);
  }

  public static toOfflineCache<T>(value: T, defaultValue: T): T {
    return EnumField.toFirestore<T>(value, defaultValue);
  }
}
