export const it = {
  role: {
    allowNewUser: "Permetti nuovi utenti",
    allowNewUserError: "Seleziona un valore",
    hasKoruAccess: "Permetti accesso a KORU",
    hasKoruAccessError: "Seleziona un valore",
    module: "Modulo",
    name: "Nome",
    nameError: "Inserisci un nome valido",
    role: "Ruolo",
    rights: {
      create: "Crea",
      delete: "Elimina",
      fieldError: "Seleziona un valore",
      modes: {
        all: "Tutti",
        custom: "Personalizzato",
        owned: "Propri",
        none: "Nessuno",
      },
      read: "Leggi",
      toggle: "Azione",
      update: "Modifica",
      viewUI: "Interfaccia",
    },
    roles: "Ruoli",
    types: {
      core: "Core",
      features: "Features",
    },
  },
  navigation: {
    role: "Ruoli",
  },
};
