import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { TestTypeCategory } from "../objects/TestTypeCategory";
import { testTypeCategoryModel } from "./TestTypeCategoryModel";

export class TestTypeCategoryOfflineModel extends OfflineModuleModel<TestTypeCategory> {
  public constructor() {
    super(() => new TestTypeCategory(), "testTypeCategories", "testTypeCategory.testTypeCategories", undefined);
  }

  public async downloadCache(): Promise<TestTypeCategory[]> {
    try {
      return testTypeCategoryModel.getDocuments();
    } catch (error: unknown) {
      appFaultModel.catchAppError("TestTypeCategoryOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const testTypeCategoryOfflineModel: TestTypeCategoryOfflineModel = new TestTypeCategoryOfflineModel();
