export const it = {
  risk: {
    inailId: "Codice INAIL",
    name: "Nome",
    nameError: "Inserire un nome valido",
    norm: "Normativa",
    normError: "Selezionare una normativa",
    risk: "Rischio",
    risks: "Rischi",
    section: "Sezione",
    type: "Tipo",
    typeError: "Selezionare un tipo",
  },
  navigation: {
    risk: "Rischi",
  },
};
