export const it = {
  statEmployeeLastExamination: {
    company: "Azienda",
    fullName: "Lavoratore",
    lastExamination: "Ultima visita",
    name: "Lavoratori con ultima visita per azienda",
    nextExpiration: "Prossima scadenza",
    noExamination: "Non ancora visitato",
  },
  toast: {
    error: {
      statEmployeeLastExamination: {
        companyIdUndefined: "Selezionare un'azienda",
      },
    },
    success: {},
    warning: {},
  },
};
