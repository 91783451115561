import { RouteRecordRaw } from "vue-router";

export const employeeDutyRoutes: RouteRecordRaw[] = [
  {
    path: "/employees/:employeeId/duties/:employeeDutyId/edit",
    name: "EmployeeDutyEdit",
    component: () => import("../views/EmployeeDutyEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeDuty",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/employees/:employeeId/duties/:employeeDutyId",
    name: "EmployeeDutyView",
    component: () => import("../views/EmployeeDutyView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "employeeDuty",
      requiredRight: "viewUI",
    },
  },
];
