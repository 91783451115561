import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { PriceListItemMode } from "./PriceListItemMode";
import { PriceListItemType } from "./PriceListItemType";

import { BooleanField, EnumField, NumberField, StringArrayField, StringField } from "@/core/fields";

export class PriceListItem {
  public id: string = DataHelpers.uniqueId();
  public name: string | undefined = undefined;
  public type: PriceListItemType = PriceListItemType.ExamType;
  public itemsIds: string[] = [];
  public mode: PriceListItemMode = PriceListItemMode.Any;
  public price = 0;
  public isVatApplied = false;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): PriceListItem {
    this.id = DataHelpers.uniqueId();

    this.name = StringField.fromFirestore(data.name);
    this.type = EnumField.fromFirestore<PriceListItemType>(data.type, Object.values(PriceListItemType), PriceListItemType.ExamType);
    this.itemsIds = StringArrayField.fromFirestore(data.itemsIds);
    this.mode = EnumField.fromFirestore<PriceListItemMode>(data.mode, Object.values(PriceListItemMode), PriceListItemMode.Any);
    this.price = NumberField.fromFirestore(data.price);
    this.isVatApplied = BooleanField.fromFirestore(data.isVatApplied);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.type = EnumField.toFirestore<PriceListItemType>(this.type, PriceListItemType.ExamType);
    firestoreData.itemsIds = StringArrayField.toFirestore(this.itemsIds);
    firestoreData.mode = EnumField.toFirestore<PriceListItemMode>(this.mode, PriceListItemMode.Any);
    firestoreData.price = NumberField.toFirestore(this.price);
    firestoreData.isVatApplied = BooleanField.toFirestore(this.isVatApplied);

    return firestoreData;
  }
}
