export const en = {
  auth: {
    askNewPassword: "Ask New Password",
    authenticationSubtitle: "Login with Your account",
    authenticationTitle: "Authentication",
    checkInbox: "Check Your e-mail inbox",
    choosePassword: "Choose a new password",
    email: "E-mail",
    emailBlank: "Insert the e-mail address",
    emailError: "The e-mail address is not valid",
    forgotError: "It was not possible to recover the password",
    forgotPasswordTitle: "Recover Password",
    forgotPasswordStart: "Forgot password?",
    forgotPasswordMiddle: "Ask",
    forgotPasswordEnd: "to recover now!",
    inputEmail: "Insert Your e-mail address",
    login: "Login",
    loginFailed: "Authentication failed",
    password: "Password",
    passwordBlank: "Insert the new password",
    passwordConfirm: "Confirm New Password",
    passwordConfirmBlank: "Insert the new password confirmation",
    passwordConfirmError: "Passwords don't match",
    passwordError: "Insert a valid password",
    passwordErrorChars: "The password should contain at least 8 characters",
    passwordErrorLowercase: "Insert at least one lowercase character",
    passwordErrorNumber: "Insert at least one number",
    passwordErrorUppercase: "Insert at least one uppercase character",
    passwordRequirementsTitle: "Requirements",
    passwordRequirementsChars: "At least 8 character",
    passwordRequirementsLowercase: "At least one lowercase character",
    passwordRequirementsNumber: "At least one number",
    passwordRequirementsUppercase: "At least one uppercase character",
    passwordRememberedStart: "Password remembered? Back to ",
    passwordRememberedEnd: "authentication",
    recoverPasswordTitle: "Recover Password",
    resetPassword: "Reset Password",
    resetPasswordError: "It was not possible to reset the password",
    resetPasswordSuccessStart: "The password has been reset. Go back to ",
    resetPasswordSuccessEnd: "authentication",
  },
};
