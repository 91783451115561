import { Employee } from "../objects/Employee";
import { employeeModel } from "../models/EmployeeModel";
import { Employer } from "@/features/modules/employer/objects/Employer";
import { employerModel } from "@/features/modules/employer/models/EmployerModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const employeeSearch: SearchModule = new SearchModule(
  "employee.employee",
  async (firestoreDocument: FirestoreDocument) => {
    const employee: Employee = firestoreDocument as Employee;
    const today: Date = new Date();

    today.setHours(0, 0, 0, 0);
    const employers: Employer[] = await employerModel.getEmployersByEmployeeAndDate(employee.id, today);
    if (employers.length == 0) {
      return (firestoreDocument as Employee).fullName ?? "-";
    }

    const employersText: string[] = employers.map((employer: Employer) => employer.company?.name ?? "-");

    return `${(firestoreDocument as Employee).fullName ?? "-"}<br>${employersText.join("; ")}`;
  },
  (firestoreDocument: FirestoreDocument) => `/employees/${firestoreDocument.id}`,
  "users",
  employeeModel,
  [new SortCriteria("fullName", "asc", "string")]
);
