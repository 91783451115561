export const it = {
  norm: {
    name: "Nome",
    nameError: "Inserire un nome valido",
    norm: "Normativa",
    norms: "Normative",
    sections: "Sezioni",
    sectionsError: "Inserire almeno una sezione",
  },
  navigation: {
    norm: "Normative",
  },
};
