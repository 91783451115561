export const it = {
  guide: {
    browse: "Visualizza le guide disponibili",
    description: "Descrizione",
    descriptionError: "Inserisci una descrizione",
    guide: "Guida",
    guides: "Guide",
    isEnabled: "Attiva",
    isEnabledError: "Seleziona un valore",
    noGuides: "Nessuna guida disponibile",
    title: "Titolo",
    titleError: "Inserisci un titolo",
  },
  navigation: {
    guide: "Guide",
  },
  toast: {
    error: {
      guideFileError: "Errore durante la lettura del file",
      guideFileUndefined: "Il file non è stato trovato",
    },
    success: {},
    warning: {},
  },
};
