import { BooleanField, StringField } from "@/core/fields";

export class PhysioHistory {
  public school: string | undefined = undefined;
  public food: string | undefined = undefined;
  public sport: string | undefined = undefined;
  public sportType: string | undefined = undefined;
  public allergiesCheck = false;
  public allergiesList: string | undefined = undefined;
  public allergiesSymptoms: string | undefined = undefined;
  public smokeType: string | undefined = undefined;
  public smokeAmount: string | undefined = undefined;
  public smokeFrom: string | undefined = undefined;
  public smokeTo: string | undefined = undefined;
  public wine: string | undefined = undefined;
  public beer: string | undefined = undefined;
  public spirit: string | undefined = undefined;
  public medicineCheck = false;
  public medicineDetails: string | undefined = undefined;
  public intestine: string | undefined = undefined;
  public diuresis: string | undefined = undefined;
  public firstPeriod: string | undefined = undefined;
  public periodType: string | undefined = undefined;
  public menopause: string | undefined = undefined;
  public abortions: string | undefined = undefined;
  public sons: string | undefined = undefined;
  public completedPregnancies: string | undefined = undefined;
  public uncompletedPregnancies: string | undefined = undefined;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): PhysioHistory {
    this.school = StringField.fromFirestore(data.school);
    this.food = StringField.fromFirestore(data.food);
    this.sport = StringField.fromFirestore(data.sport);
    this.sportType = StringField.fromFirestore(data.sportType);
    this.allergiesCheck = BooleanField.fromFirestore(data.allergiesCheck);
    this.allergiesList = StringField.fromFirestore(data.allergiesList);
    this.allergiesSymptoms = StringField.fromFirestore(data.allergiesSymptoms);
    this.smokeType = StringField.fromFirestore(data.smokeType);
    this.smokeAmount = StringField.fromFirestore(data.smokeAmount);
    this.smokeFrom = StringField.fromFirestore(data.smokeFrom);
    this.smokeTo = StringField.fromFirestore(data.smokeTo);
    this.wine = StringField.fromFirestore(data.wine);
    this.beer = StringField.fromFirestore(data.beer);
    this.spirit = StringField.fromFirestore(data.spirit);
    this.medicineCheck = BooleanField.fromFirestore(data.medicineCheck);
    this.medicineDetails = StringField.fromFirestore(data.medicineDetails);
    this.intestine = StringField.fromFirestore(data.intestine);
    this.diuresis = StringField.fromFirestore(data.diuresis);
    this.firstPeriod = StringField.fromFirestore(data.firstPeriod);
    this.periodType = StringField.fromFirestore(data.periodType);
    this.menopause = StringField.fromFirestore(data.menopause);
    this.abortions = StringField.fromFirestore(data.abortions);
    this.sons = StringField.fromFirestore(data.sons);
    this.completedPregnancies = StringField.fromFirestore(data.completedPregnancies);
    this.uncompletedPregnancies = StringField.fromFirestore(data.uncompletedPregnancies);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.school = StringField.toFirestore(this.school);
    firestoreData.food = StringField.toFirestore(this.food);
    firestoreData.sport = StringField.toFirestore(this.sport);
    firestoreData.sportType = StringField.toFirestore(this.sportType);
    firestoreData.allergiesCheck = BooleanField.toFirestore(this.allergiesCheck);
    firestoreData.allergiesList = StringField.toFirestore(this.allergiesList);
    firestoreData.allergiesSymptoms = StringField.toFirestore(this.allergiesSymptoms);
    firestoreData.smokeType = StringField.toFirestore(this.smokeType);
    firestoreData.smokeAmount = StringField.toFirestore(this.smokeAmount);
    firestoreData.smokeFrom = StringField.toFirestore(this.smokeFrom);
    firestoreData.smokeTo = StringField.toFirestore(this.smokeTo);
    firestoreData.wine = StringField.toFirestore(this.wine);
    firestoreData.beer = StringField.toFirestore(this.beer);
    firestoreData.spirit = StringField.toFirestore(this.spirit);
    firestoreData.medicineCheck = BooleanField.toFirestore(this.medicineCheck);
    firestoreData.medicineDetails = StringField.toFirestore(this.medicineDetails);
    firestoreData.intestine = StringField.toFirestore(this.intestine);
    firestoreData.diuresis = StringField.toFirestore(this.diuresis);
    firestoreData.firstPeriod = StringField.toFirestore(this.firstPeriod);
    firestoreData.periodType = StringField.toFirestore(this.periodType);
    firestoreData.menopause = StringField.toFirestore(this.menopause);
    firestoreData.abortions = StringField.toFirestore(this.abortions);
    firestoreData.sons = StringField.toFirestore(this.sons);
    firestoreData.completedPregnancies = StringField.toFirestore(this.completedPregnancies);
    firestoreData.uncompletedPregnancies = StringField.toFirestore(this.uncompletedPregnancies);
    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): PhysioHistory {
    this.school = StringField.fromOfflineCache(data.school);
    this.food = StringField.fromOfflineCache(data.food);
    this.sport = StringField.fromOfflineCache(data.sport);
    this.sportType = StringField.fromOfflineCache(data.sportType);
    this.allergiesCheck = BooleanField.fromOfflineCache(data.allergiesCheck);
    this.allergiesList = StringField.fromOfflineCache(data.allergiesList);
    this.allergiesSymptoms = StringField.fromOfflineCache(data.allergiesSymptoms);
    this.smokeType = StringField.fromOfflineCache(data.smokeType);
    this.smokeAmount = StringField.fromOfflineCache(data.smokeAmount);
    this.smokeFrom = StringField.fromOfflineCache(data.smokeFrom);
    this.smokeTo = StringField.fromOfflineCache(data.smokeTo);
    this.wine = StringField.fromOfflineCache(data.wine);
    this.beer = StringField.fromOfflineCache(data.beer);
    this.spirit = StringField.fromOfflineCache(data.spirit);
    this.medicineCheck = BooleanField.fromOfflineCache(data.medicineCheck);
    this.medicineDetails = StringField.fromOfflineCache(data.medicineDetails);
    this.intestine = StringField.fromOfflineCache(data.intestine);
    this.diuresis = StringField.fromOfflineCache(data.diuresis);
    this.firstPeriod = StringField.fromOfflineCache(data.firstPeriod);
    this.periodType = StringField.fromOfflineCache(data.periodType);
    this.menopause = StringField.fromOfflineCache(data.menopause);
    this.abortions = StringField.fromOfflineCache(data.abortions);
    this.sons = StringField.fromOfflineCache(data.sons);
    this.completedPregnancies = StringField.fromOfflineCache(data.completedPregnancies);
    this.uncompletedPregnancies = StringField.fromOfflineCache(data.uncompletedPregnancies);
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.school = StringField.toOfflineCache(this.school);
    firestoreData.food = StringField.toOfflineCache(this.food);
    firestoreData.sport = StringField.toOfflineCache(this.sport);
    firestoreData.sportType = StringField.toOfflineCache(this.sportType);
    firestoreData.allergiesCheck = BooleanField.toOfflineCache(this.allergiesCheck);
    firestoreData.allergiesList = StringField.toOfflineCache(this.allergiesList);
    firestoreData.allergiesSymptoms = StringField.toOfflineCache(this.allergiesSymptoms);
    firestoreData.smokeType = StringField.toOfflineCache(this.smokeType);
    firestoreData.smokeAmount = StringField.toOfflineCache(this.smokeAmount);
    firestoreData.smokeFrom = StringField.toOfflineCache(this.smokeFrom);
    firestoreData.smokeTo = StringField.toOfflineCache(this.smokeTo);
    firestoreData.wine = StringField.toOfflineCache(this.wine);
    firestoreData.beer = StringField.toOfflineCache(this.beer);
    firestoreData.spirit = StringField.toOfflineCache(this.spirit);
    firestoreData.medicineCheck = BooleanField.toOfflineCache(this.medicineCheck);
    firestoreData.medicineDetails = StringField.toOfflineCache(this.medicineDetails);
    firestoreData.intestine = StringField.toOfflineCache(this.intestine);
    firestoreData.diuresis = StringField.toOfflineCache(this.diuresis);
    firestoreData.firstPeriod = StringField.toOfflineCache(this.firstPeriod);
    firestoreData.periodType = StringField.toOfflineCache(this.periodType);
    firestoreData.menopause = StringField.toOfflineCache(this.menopause);
    firestoreData.abortions = StringField.toOfflineCache(this.abortions);
    firestoreData.sons = StringField.toOfflineCache(this.sons);
    firestoreData.completedPregnancies = StringField.toOfflineCache(this.completedPregnancies);
    firestoreData.uncompletedPregnancies = StringField.toOfflineCache(this.uncompletedPregnancies);
    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
