import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getExaminationsByYear = async (year: number): Promise<Examination[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (examinationModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Examination[] = (await offlineModel.getCacheByCollection(examinationModel.offlineModuleModel)) as Examination[];
      return sortDocuments(documents.filter((examination) => examination.year === year));
    }

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("year", "==", year)
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsByYear", { year }, error);
    return [];
  }
};

function sortDocuments(documents: Examination[]): Examination[] {
  const sorter: FirestoreSorter<Examination> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "date");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
