import { compareAsc } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeByDuty } from "../objects/EmployeeByDuty";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";

export class EmployeeByDutyModel {
  public async searchEmployeesByDuty(companyId: string, companyDutyId: string, testTypeCategoryId: string): Promise<EmployeeByDuty[]> {
    try {
      if (companyId === undefined) throw new Error("statEmployeeByDuty.companyIdUndefined");
      if (companyDutyId === undefined) throw new Error("statEmployeeByDuty.companyDutyIdUndefined");
      if (testTypeCategoryId === undefined) throw new Error("statEmployeeByDuty.testTypeCategoryIdUndefined");

      const today: Date = new Date();
      const employeesByDuty: EmployeeByDuty[] = [];

      const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getEmployeeDutiesByCompany(companyId);
      for (const employeeDuty of employeeDuties) {
        if (employeeDuty.from !== undefined && compareAsc(employeeDuty.from, today) > 0) continue;
        if (employeeDuty.to !== undefined && compareAsc(today, employeeDuty.to) > 0) continue;

        if (
          employeesByDuty.filter((employeeByDuty) => employeeByDuty.employeeId !== undefined && employeeByDuty.employeeId === employeeDuty.parentId)
            .length > 0
        )
          continue;

        if (employeeDuty.getCompanyDuties().filter((companyDuty) => companyDuty.id === companyDutyId).length == 0) continue;

        const testTypes: string[] = employeeDuty
          .getLinkedTestTypes()
          .filter((linkedTestType: LinkedTestType) => linkedTestType.category?.id === testTypeCategoryId)
          .map((linkedTestType: LinkedTestType) => linkedTestType.name as string)
          .sort();

        if (testTypes.length === 0) continue;

        const employee: Employee = await employeeModel.getDocument(employeeDuty.parentId as string);

        const employeeByDuty: EmployeeByDuty = new EmployeeByDuty();
        employeeByDuty.employeeId = employee.id;
        employeeByDuty.fullName = employee.fullName;
        employeeByDuty.fiscalCode = employee.fiscalCode;
        employeeByDuty.testTypes = testTypes;
        employeesByDuty.push(employeeByDuty);
      }

      /*
        const employees: Employee[] = await employeeModel.getEmployeesByCompany(companyId);
        for (const employee of employees) {
          const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getDocuments(employee.id);

          const activeEmployeeDuties: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
            if (employeeDuty.from !== undefined && compareAsc(employeeDuty.from, today) > 0) return false;
            if (employeeDuty.to !== undefined && compareAsc(today, employeeDuty.to) > 0) return false;
            return true;
          });

          if (activeEmployeeDuties.length !== 1) continue;

          const activeEmployeeDuty: EmployeeDuty = activeEmployeeDuties[0];

          const testTypes: string[] = activeEmployeeDuty
            .getLinkedTestTypes()
            .filter((linkedTestType: LinkedTestType) => linkedTestType.category?.id === testTypeCategoryId)
            .map((linkedTestType: LinkedTestType) => linkedTestType.name as string)
            .sort();

          if (testTypes.length === 0) continue;

          const employeeByDuty: EmployeeByDuty = new EmployeeByDuty();
          employeeByDuty.fullName = employee.fullName;
          employeeByDuty.fiscalCode = employee.fiscalCode;
          employeeByDuty.testTypes = testTypes;
          employeesByDuty.push(employeeByDuty);
        }
        */

      return employeesByDuty;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeByDutyModel.searchEmployeesByDuty", { companyId, companyDutyId, testTypeCategoryId }, error);
      return [];
    }
  }
}

export const employeeByDutyModel: EmployeeByDutyModel = new EmployeeByDutyModel();
