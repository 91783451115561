import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "../BranchModel";
import { CloudHelpers } from "@/core/modules/helpers/CloudHelpers";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const isVatCodeUnique = async (vatCode: string, branchId = "empty"): Promise<boolean> => {
  try {
    if (vatCode === undefined) return false;

    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (branchModel.offlineModuleModel === undefined) return false;
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Branch[] = (await offlineModel.getCacheByCollection(branchModel.offlineModuleModel)) as Branch[];
      const filteredDocuments: Branch[] = documents.filter((branch) => branch.vatCode === vatCode);
      return filteredDocuments.length === 0 || (filteredDocuments.length === 1 && filteredDocuments[0].id === branchId);
    }

    return CloudHelpers.runValidatorFunction("featuresBranchVatCodeValidator", vatCode, branchId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("BranchModel.isVatCodeUnique", { vatCode, branchId }, error);
    return false;
  }
};
