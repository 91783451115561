import { createStore, StoreOptions } from "vuex";

import { getters } from "./getters";
import { initialState } from "./initialState";
import { mutations } from "./mutations";
import { StoreState } from "./interfaces/StoreState";
import { StoreModel } from "./models/StoreModel";

const storeOptions: StoreOptions<StoreState> = {
  state: initialState,
  getters: getters,
  mutations: mutations,
};

export const store: StoreModel<StoreState> = createStore<StoreState>(storeOptions);

export const useStore = (): StoreModel<StoreState> => {
  return store;
};
