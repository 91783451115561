import { Company } from "@/features/modules/company/objects/Company";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { EmployeeByDuty } from "../objects/EmployeeByDuty";
import { Paper } from "@/core/modules/paper/objects/Paper";
import { PaperHelpers } from "@/features/modules/paper/helpers/PaperHelpers";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { TestTypeCategory } from "@/features/modules/testTypeCategory/objects/TestTypeCategory";

export class EmployeeByDutyPaper extends Paper {
  private company: Company;
  private companyDuty: CompanyDuty;
  private employeesByDuty: EmployeeByDuty[];
  private testTypeCategory: TestTypeCategory;

  constructor(
    company: Company,
    companyDuty: CompanyDuty,
    testTypeCategory: TestTypeCategory,
    employeesByDuty: EmployeeByDuty[],
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.company = company;
    this.companyDuty = companyDuty;
    this.employeesByDuty = employeesByDuty;
    this.testTypeCategory = testTypeCategory;

    this.employeesByDuty.sort((a: EmployeeByDuty, b: EmployeeByDuty) => {
      if (a.fullName === undefined || b.fullName === undefined) return 0;
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    });
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      documentTitle: { alignment: "center", bold: true, color: this.highlightColor, fontSize: 14, margin: [0, 0, 0, 0] },
      footerText: { color: this.footerColor, fontSize: 8, italics: true },
      fullName: { bold: true },
      headerText: { color: this.textColor, fontSize: 10 },
      headerField: { fontSize: 6 },
      headerTable: { alignment: "center" },
      headerValue: { border: false, color: this.highlightColor, fontSize: 10 },
      listTable: { fontSize: 10, margin: [0, 5, 0, 15] },
      listTableHeader: { color: this.highlightColor, bold: true, fontSize: 12 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    this.setTitle(this.t("statEmployeeByDuty.paperTitle").toUpperCase(), isContinuos);
    this.setAuthor(store.getters[storeTypes.getters.getUser]);
    this.setHighlightColor("#000000");

    const customHeader = await PaperHelpers.getCustomHeader(this.d, this.n, this.t);
    this.setHeader(customHeader);

    this.writeHeaderFields();

    this.writeBodyTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeHeaderFields(): void {
    const headerTableBody: unknown[] = [
      [
        { text: this.company.name?.toLocaleUpperCase() ?? "-", style: "headerValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.companyDuty.name?.toLocaleUpperCase() ?? "-", style: "headerValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.testTypeCategory.name?.toLocaleUpperCase() ?? "-", style: "headerValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("statEmployeeByDuty.company"), style: "headerField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statEmployeeByDuty.companyDuty"), style: "headerField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statEmployeeByDuty.testTypeCategory"), style: "headerField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "headerTable",
      table: {
        body: headerTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(50), DataHelpers.mmToPoints(6), "*", DataHelpers.mmToPoints(6), DataHelpers.mmToPoints(50)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeBodyTable(): void {
    const listTableRows: unknown[] = this.employeesByDuty.map((employeeByDuty: EmployeeByDuty) => {
      if (employeeByDuty.fiscalCode !== undefined) {
        return [
          [{ text: employeeByDuty.fullName, style: "fullName" }, { text: employeeByDuty.fiscalCode }],
          { text: employeeByDuty.testTypes.join(", ") || "-" },
        ];
      }
      return [{ text: employeeByDuty.fullName, style: "fullName" }, { text: employeeByDuty.testTypes.join(", ") || "-" }];
    });

    const listTableBody: unknown[] = [
      [
        { text: this.t("statEmployeeByDuty.fullName"), style: "listTableHeader" },
        { text: this.t("statEmployeeByDuty.testTypes"), style: "listTableHeader" },
      ],
      ...listTableRows,
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      style: "listTable",
      table: {
        body: listTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(60), "*"],
      },
      layout: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hLineWidth: (i: number, node: any) => {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return i === node.table.headerRows ? 2 : 0;
        },
        vLineWidth: () => 0,
        hLineColor: () => this.highlightColor,
        paddingTop: () => 5,
        paddingBottom: () => 5,
        paddingLeft: () => 8,
        paddingRight: () => 0,
        fillColor: (i: number) => (i % 2 === 0 ? null : this.highlightColor),
        fillOpacity: (i: number) => (i % 2 === 0 ? 0 : 0.1),
      },
    });
  }
}
