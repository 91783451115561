import { AppError } from "../objects/AppError";
import { AppFault } from "../objects/AppFault";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import { catchAppError, createAppFaultFromAppError } from "./methods";

export class AppFaultModel extends FirestoreModel<AppFault> {
  public constructor() {
    super(() => new AppFault(), "appFaults", LockPolicy.None, "appFault");
  }

  public async getDocuments(): Promise<AppFault[]> {
    return super.getDocuments();
  }

  public async getDocument(appFaultId: string): Promise<AppFault> {
    return super.getDocument(appFaultId);
  }

  public async createDocument(appFault: AppFault): Promise<string> {
    return super.createDocument(appFault);
  }

  public async deleteDocument(appFault: AppFault): Promise<boolean> {
    return super.deleteDocument(appFault);
  }

  public catchAppError(functionName: string | undefined, parameters: Record<string, unknown> | undefined, error: unknown): void {
    return catchAppError(functionName, parameters, error);
  }

  public async createAppFaultFromAppError(appError: AppError, rethrows?: boolean): Promise<void> {
    return createAppFaultFromAppError(appError, rethrows);
  }
}

export const appFaultModel: AppFaultModel = new AppFaultModel();
