export const fr = {
  history: {
    actions: {
      browse: "Parcourir",
      create: "Créer",
      edit: "Modifier",
      search: "Rechercher",
      view: "Voir",
    },
    history: "Chronologie",
    noHistory: "La chronologie est vide",
  },
};
