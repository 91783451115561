import { RouteRecordRaw } from "vue-router";

export const examTypeRoutes: RouteRecordRaw[] = [
  {
    path: "/exam-types/:id/edit",
    name: "ExamTypeEdit",
    component: () => import("../views/ExamTypeEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "examType",
      requiredRight: "viewUI",
    },
  },
];
