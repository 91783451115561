export const de = {
  backup: {
    backup: "Sicherungen",
    backups: "Sicherungen",
    createdAt: "Erstellung",
    createBackup: "Backup jetzt erstellen",
    createBackupDescription: "Das Backup wird erstellt und erscheint in der Liste, sobald es abgeschlossen ist",
    createBackupTitle: "Backup-Erstellung",
    type: "Typ",
    types: {
      daily: "Täglich",
      monthly: "Monatlich",
      request: "Auf Anfrage",
      weekly: "Wöchentlich",
    },
    user: "Benutzer",
  },
  navigation: {
    backup: "Sicherungen",
  },
};
