import { getDoc, setDoc } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Employer } from "../objects/Employer";
import { employerModel } from "./EmployerModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class EmployerOfflineModel extends OfflineModuleModel<Employer> {
  public constructor() {
    super(() => new Employer(), "employers", "employer.employers", "employer");
    this.canCreate = true;
    this.canUpdate = true;
  }

  public async downloadCache(): Promise<Employer[]> {
    try {
      if (companyModel.offlineModuleModel === undefined) return [];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let employers: Employer[] = [];
      for (const company of companies) {
        employers = [...employers, ...(await employerModel.getEmployersByCompany(company.id))];
      }

      return employers;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployerOfflineModel.downloadCache", {}, error);
      return [];
    }
  }

  public async createDocumentCallback(document: Employer): Promise<boolean> {
    try {
      const doc = await getDoc(employerModel.getDocumentReference(document.id, document.parentId));
      if (doc.exists() === true) throw new Error("id duplicated");

      document.setSearchKeys();
      document.setTimestampFields("create");

      await setDoc(employerModel.getDocumentReference(document.id, document.parentId).withConverter(employerModel.firestoreConverter), document);

      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployerOfflineModel.createDocumentCallback", { document }, error);
      return false;
    }
  }

  public async updateDocumentCallback(document: Employer): Promise<boolean> {
    try {
      const oldDocument: Employer = await employerModel.getDocument(document.id, document.parentId);
      if (document.hasChangedFrom(oldDocument)) throw new Error("sync");

      await employerModel.updateDocument(document, document.parentId);
      return true;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployerOfflineModel.updateDocumentCallback", { document }, error);
      return false;
    }
  }
}

export const employerOfflineModel: EmployerOfflineModel = new EmployerOfflineModel();
