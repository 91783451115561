import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { StringField } from "@/core/fields";

export class AppFaultInfo {
  public id: string = DataHelpers.uniqueId();
  public functionName: string | undefined = undefined;
  public parameters: string | undefined = undefined;
  public errorMessage: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): AppFaultInfo {
    this.id = DataHelpers.uniqueId();

    this.functionName = StringField.fromFirestore(data.functionName);
    this.parameters = StringField.fromFirestore(data.parameters);
    this.errorMessage = StringField.fromFirestore(data.errorMessage);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.functionName = StringField.toFirestore(this.functionName);
    firestoreData.parameters = StringField.toFirestore(this.parameters);
    firestoreData.errorMessage = StringField.toFirestore(this.errorMessage);

    return firestoreData;
  }
}
