
import { computed, defineComponent, onMounted, onUnmounted, Ref, ref, WritableComputedRef } from "vue";

import * as _ from "lodash";
import { FileUploadSelectEvent } from "primevue/fileupload";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { config } from "@/core/modules/config/objects/Config";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { FormatHelpers } from "@/core/modules/helpers/FormatHelpers";
import { StorageFile } from "@/core/modules/storage/objects/StorageFile";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "KoruMultipleUpload",
  props: {
    accept: { type: String, default: undefined },
    canDelete: { type: Boolean, default: true },
    files: { type: Array<StorageFile>, default: [] },
    maxFileSize: { type: Number, default: undefined },
    metadata: { type: Object, default: undefined },
    name: { type: String, default: undefined },
    path: { type: String, default: undefined },
  },
  emits: ["update:files"],
  setup(props, { emit }) {
    const { t } = useLocale();
    let eventBusId: string | undefined = undefined;

    const filesRef: WritableComputedRef<StorageFile[]> = computed({
      get: () => props.files as StorageFile[],
      set: (val) => emit("update:files", val),
    });

    const filesToDelete: Ref<StorageFile[]> = ref([]);
    const filesToUpload: Ref<File[]> = ref([]);

    const submitAction = async (newName: string | undefined): Promise<void> => {
      await AppHelpers.tryOrToast(
        async () => {
          if (props.path === undefined) return;

          if (filesToDelete.value.length > 0 && props.canDelete === true) {
            for (const fileToDelete of filesToDelete.value) {
              if (fileToDelete.uniqueName === undefined) continue;
              await storageModel.deleteFile(props.path, fileToDelete.uniqueName);
              filesRef.value.splice(filesRef.value.indexOf(fileToDelete), 1);
            }
            filesToDelete.value = [];
          }
          if (filesToUpload.value.length > 0) {
            let suffix = filesRef.value.length + 1;
            for (const fileToUpload of filesToUpload.value) {
              if (fileToUpload === undefined) continue;
              filesRef.value.push(await storageModel.uploadFile(props.path, fileToUpload, `${newName ?? props.name}-${suffix}`, props.metadata));
              suffix++;
            }
            filesToUpload.value = [];
          }
        },
        "uploadFile",
        t,
        undefined,
        false
      );
    };

    const onFileSelected = async (event: FileUploadSelectEvent): Promise<void> => {
      await AppHelpers.tryOrToast(
        async () => {
          if (event.files.length > 0) {
            filesToUpload.value = event.files;
          } else {
            filesToUpload.value = [];
          }
        },
        "uploadFile",
        t,
        undefined,
        false
      );
    };

    const openFile = async (index: number) => {
      await AppHelpers.tryOrToast(
        async () => {
          if (filesRef.value[index] === undefined) return;
          if (filesRef.value[index].uniqueName === undefined) return;
          if (props.path === undefined) return;

          const fileUrl: string = await storageModel.getDownloadLink(props.path, filesRef.value[index].uniqueName as string);

          window.open(fileUrl, "_blank");
        },
        "readFile",
        t,
        undefined,
        false
      );
    };

    const deleteFile = (index: number) => {
      if (props.canDelete === false) return;
      filesToDelete.value.push(_.cloneDeep(filesRef.value[index]));
      filesRef.value.splice(index, 1);
    };

    onMounted(() => {
      eventBusId = eventBus.on("koruUploadSubmit", submitAction);
    });

    onUnmounted(() => {
      if (eventBusId !== undefined) eventBus.off(eventBusId);
    });

    return {
      config,
      FormatHelpers,
      deleteFile,
      filesRef,
      onFileSelected,
      openFile,
      t,
    };
  },
});
