import { sendPasswordResetEmail } from "firebase/auth";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

export const forgotPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(firebase.auth, email);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AuthModel.forgotPassword", { email }, error);
  }
};
