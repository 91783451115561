import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { EmployeeLastExamination } from "../objects/EmployeeLastExamination";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "@/features/modules/examination/models/ExaminationModel";
import { LinkedCompanyWithStatus } from "@/features/modules/company/objects/LinkedCompanyWithStatus";

export class EmployeeLastExaminationModel {
  public async searchEmployeesByCompany(companyId: string): Promise<EmployeeLastExamination[]> {
    try {
      if (companyId === undefined) throw new Error("statEmployeeByDuty.companyIdUndefined");

      const employeesLastExaminations: EmployeeLastExamination[] = [];

      const employees: Employee[] = await employeeModel.getEmployeesByCompany(companyId);
      for (const employee of employees) {
        const company: LinkedCompanyWithStatus | undefined = employee.getLinkedCompaniesWithStatus().find((company) => company.id === companyId);
        if (company === undefined) continue;
        if (company.isArchived !== undefined) continue;

        const examinations: Examination[] = await examinationModel.getExaminationsByEmployeeAndCompanyAndFirm(employee.id, companyId);
        const doneExaminations: Examination[] = examinations.filter((examination) => examination.judgmentState === true);

        const employeeLastExamination: EmployeeLastExamination = new EmployeeLastExamination();
        employeeLastExamination.employee = employee;
        employeeLastExamination.lastExamination = doneExaminations.length > 0 ? doneExaminations[examinations.length - 1] : undefined;
        employeesLastExaminations.push(employeeLastExamination);
      }

      return employeesLastExaminations;
    } catch (error: unknown) {
      appFaultModel.catchAppError("EmployeeLastExaminationModel.searchEmployeesByCompany", { companyId }, error);
      return [];
    }
  }
}

export const employeeLastExaminationModel: EmployeeLastExaminationModel = new EmployeeLastExaminationModel();
