import { ref, StorageError, uploadBytesResumable, UploadTask, UploadTaskSnapshot } from "firebase/storage";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import mimeTypes from "@/core/data/mimeTypes.json";

export const upload = async (
  path: string,
  name: string,
  file: File,
  callback: (progress: number) => void,
  metadata?: Record<string, string>
): Promise<void> => {
  try {
    return new Promise<void>((resolve, reject) => {
      const mimeTypesObject: Map<string, string> = new Map<string, string>(Object.entries(mimeTypes));

      const fileMetadata: Record<string, unknown> = { customMetadata: {} };
      const user: User = store.getters[storeTypes.getters.getUser];
      if (user !== undefined) fileMetadata["customMetadata"] = { createdBy: user.id };

      const fileExtension: string | undefined = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension !== undefined && mimeTypesObject.has(fileExtension)) {
        fileMetadata.contentType = mimeTypesObject.get(fileExtension);
      }

      if (metadata !== undefined) {
        for (const key of Object.keys(metadata)) {
          (fileMetadata.customMetadata as Record<string, unknown>)[key] = metadata[key];
        }
      }

      const uploadTask: UploadTask = uploadBytesResumable(ref(firebase.storage, `${path}/${name}`), file, fileMetadata);

      uploadTask.on(
        "state_changed",
        (snapshot: UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          callback(Math.floor(progress));
        },
        (error: StorageError) => {
          console.log(error);
          reject();
        },
        () => {
          resolve();
        }
      );
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageModel.upload", { path, name, file, callback, metadata }, error);
  }
};
