import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = { class: "text-xl text-900 font-medium mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "text-center text-italic"
}
const _hoisted_4 = {
  key: 1,
  class: "widget-timeline"
}
const _hoisted_5 = { class: "timeline-event-icon bg-primary-400" }
const _hoisted_6 = { class: "timeline-event-title" }
const _hoisted_7 = { class: "timeline-event-detail" }
const _hoisted_8 = { class: "timeline-event-detail text-right text-italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout-sidebar-right", { 'layout-sidebar-right-active': _ctx.rightMenuActive }]),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.rightMenuClick && _ctx.rightMenuClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t("history.history")), 1),
      (_ctx.history.historyItems.length > 0)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "pt-0",
            icon: "pi pi-trash",
            text: "",
            rounded: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearHistory()))
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.history.historyItems.length === 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.t("history.noHistory")), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history.historyItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "timeline-event pr-0",
              key: item.url
            }, [
              _createElementVNode("span", _hoisted_5, [
                _createElementVNode("i", {
                  class: _normalizeClass(`pi pi-${item.icon}`)
                }, null, 2)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: item.url,
                  onClick: _ctx.onHistoryClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "onClick"])
              ]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.subtitle), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDistanceToNow(item.date, { locale: _ctx.locale })), 1)
            ]))
          }), 128))
        ]))
  ], 2))
}