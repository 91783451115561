import { format } from "date-fns";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { config } from "@/core/modules/config/objects/Config";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { User } from "@/core/modules/user/objects/User";

export class Paper {
  protected d: (date: Date, format: string) => string;
  protected n: (value: number, format: string) => string;
  protected t: (entry: string, params?: Record<string, unknown>) => string;

  protected header: () => Record<string, unknown> = () => {
    return {};
  };
  protected footer: (currentPage: number, pageCount: number) => Record<string, unknown> = () => {
    return {};
  };

  protected footerColor: string = config.app.paper.footerColor;
  protected highlightColor: string = config.app.paper.highlightColor;
  protected textColor: string = config.app.paper.textColor;

  protected title = "Placeholder title";
  protected author: User | undefined = undefined;

  protected docDefinition: Record<string, unknown> = {};

  constructor(
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string,
    pageSize = "A4",
    pageOrientation = "portrait",
    pageMargins = [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(35), DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(15)]
  ) {
    this.d = d;
    this.n = n;
    this.t = t;

    // install fonts
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      RobotoBlack: {
        normal: "Roboto-Regular.ttf",
        bold: "https://lavori.spinningtop.it/fonts/Roboto-Black.ttf",
        italics: "https://lavori.spinningtop.it/fonts/Roboto-Black-Italic.ttf",
        bolditalics: "https://lavori.spinningtop.it/fonts/Roboto-Black-Italic.ttf",
      },
    };

    // set page size
    this.docDefinition.pageSize = pageSize;
    // set page orientation
    this.docDefinition.pageOrientation = pageOrientation;
    // set margins
    this.docDefinition.pageMargins = pageMargins;

    // set default header
    this.header = () => {
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(15), 0],
        columns: [{ text: "Placeholder text", bold: true }],
      };
    };

    // set default footer
    this.footer = (currentPage: number, pageCount: number) => {
      const now: Date = new Date();
      return {
        margin: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(15), 0],
        columns: [
          {
            style: "footerTitle",
            text: t("paper.printedOn", { date: format(now, "dd/MM/yyyy"), time: format(now, "HH:mm") }),
            width: "*",
          },
          {
            alignment: "right",
            style: "footerTitle",
            text: t("paper.pagination", { current: currentPage, count: pageCount }),
            width: "*",
          },
        ],
      };
    };

    /*
    // set default styles
    this.docDefinition.styles = {
      footer: { color: this.footerColor, fontSize: 8, italics: true },
      title: { alignment: "center", bold: true, color: this.highlightColor, fontSize: 16, margin: [0, 0, 0, 10] },
    };

    // set default content
    this.docDefinition.content = [
      // title
      { text: this.title?.toLocaleUpperCase(), style: "title" },
    ];
    */
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {};

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    console.log(isContinuos);
    return Promise.resolve([]);
  }

  public async open(): Promise<void> {
    this.setStyles();
    await this.write();

    // set header
    this.docDefinition.header = this.header;
    // set footer
    this.docDefinition.footer = this.footer;
    // output
    // check if device is iOS
    const isIOS: boolean = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      pdfMake.createPdf(this.docDefinition).download();
    } else {
      pdfMake.createPdf(this.docDefinition).open();
    }
  }

  public async download(fileName: string): Promise<void> {
    this.setStyles();
    await this.write();

    // set header
    this.docDefinition.header = this.header;
    // set footer
    this.docDefinition.footer = this.footer;
    // output
    pdfMake.createPdf(this.docDefinition).download(fileName);
  }

  public async outputBase64(): Promise<string> {
    this.setStyles();
    await this.write();

    // set header
    this.docDefinition.header = this.header;
    // set footer
    this.docDefinition.footer = this.footer;
    // output
    return new Promise((resolve) => {
      const pdfDocGenerator = pdfMake.createPdf(this.docDefinition);
      pdfDocGenerator.getBase64((data: string) => {
        resolve(data);
      });
    });
  }

  private setMetadata(): void {
    this.docDefinition.info = {
      title: this.title,
      author: this.author?.fullNameReversed ?? "-",
    };
  }

  /* getters and setters */

  public setTitle(title: string, isContinuos = false): void {
    this.title = title;
    this.setMetadata();

    // set initial content
    if (isContinuos === true) {
      this.docDefinition.content = [{ text: this.title, style: "documentTitle", pageBreak: "before" }];
    } else {
      this.docDefinition.content = [{ text: this.title, style: "documentTitle" }];
    }
  }
  public setAuthor(author: User): void {
    this.author = author;
    this.setMetadata();
  }
  public setHeader(header: () => Record<string, unknown>): void {
    this.header = header;
  }
  public setFooter(footer: (currentPage: number, pageCount: number) => Record<string, unknown>): void {
    this.footer = footer;
  }
  public getFooterColor(): string {
    return this.footerColor;
  }
  public setFooterColor(color: string): void {
    this.footerColor = color;
  }
  public getHighlightColor(): string {
    return this.highlightColor;
  }
  public setHighlightColor(color: string): void {
    this.highlightColor = color;
  }
  public getTextColor(): string {
    return this.textColor;
  }
  public setTextColor(color: string): void {
    this.textColor = color;
  }
}
