import { DocumentReference } from "firebase/firestore";

import { AppFaultInfo } from "./AppFaultInfo";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedUser } from "@/core/modules/user/objects/LinkedUser";

import { ArrayField, ObjectField, StringField } from "@/core/fields";

export class AppFault extends FirestoreDocument {
  public user: LinkedUser | undefined = undefined;
  public message: string | undefined = undefined;
  public stack: AppFaultInfo[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): AppFault {
    super.fromFirestore(data, id, firestoreRef);

    this.user = ObjectField.fromFirestore<LinkedUser>(data.user, (value: Record<string, unknown>) => new LinkedUser(value));
    this.message = StringField.fromFirestore(data.message);
    this.stack = ArrayField.fromFirestore<AppFaultInfo>(data.stack, (value) => new AppFaultInfo(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.user = ObjectField.toFirestore<LinkedUser>(this.user, (value: LinkedUser) => value.toFirestore());
    firestoreData.message = StringField.toFirestore(this.message);
    firestoreData.stack = ArrayField.toFirestore<AppFaultInfo>(this.stack, (value) => value.toFirestore());

    return firestoreData;
  }
}
