import { RouteRecordRaw } from "vue-router";

import { DoctorDocHelpers } from "../helpers/DoctorDocHelpers";
import { doctorDocModel } from "../models/DoctorDocModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";

export const doctorDocRoutes: RouteRecordRaw[] = [
  {
    path: "/doctors/:sourceId/docs/:docId/edit",
    name: "DoctorDocEdit",
    component: () => import("@/features/modules/doc/views/SourceDocEdit.vue"),
    props: (route) => ({
      filesPath: DoctorDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Doctor,
      sourceModel: doctorDocModel,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doctorDoc",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/doctors/:sourceId/docs/:docId",
    name: "DoctorDocView",
    component: () => import("@/features/modules/doc/views/SourceDocView.vue"),
    props: (route) => ({
      filesPath: DoctorDocHelpers.getFolderPath(route.params.sourceId as string),
      source: DocTypeSource.Doctor,
      sourceId: route.params.sourceId,
      sourceModel: doctorDocModel,
      sourcePath: "doctors",
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "doctorDoc",
      requiredRight: "viewUI",
    },
  },
];
