
import { defineComponent } from "vue";

import KoruPdfDialog from "@/features/components/KoruPdfDialog.vue";

export default defineComponent({
  name: "BedrockHolder",
  components: {
    KoruPdfDialog,
  },
});
