import { AuthCredential, EmailAuthProvider, reauthenticateWithCredential, updatePassword as updateFirebasePassword } from "firebase/auth";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";

export const updatePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
  try {
    if (firebase.auth.currentUser === null || firebase.auth.currentUser.email === null) {
      throw new Error("Invalid data");
    }

    const credentials: AuthCredential = EmailAuthProvider.credential(firebase.auth.currentUser.email, oldPassword);
    await reauthenticateWithCredential(firebase.auth.currentUser, credentials);

    if (firebase.auth.currentUser === null) {
      throw new Error("Unable to reauthenticate");
    }

    await updateFirebasePassword(firebase.auth.currentUser, newPassword);
  } catch (error: unknown) {
    appFaultModel.catchAppError("AuthModel.updatePassword", { oldPassword: "HIDDEN", newPassword: "HIDDEN" }, error);
  }
};
