import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "../DoctorModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getDoctorsWithTrackExpirations = async (): Promise<Doctor[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (doctorModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Doctor[] = (await offlineModel.getCacheByCollection(doctorModel.offlineModuleModel)) as Doctor[];
      return documents.filter((doctor) => doctor.trackExpirations === true);
    }

    let snapshotQuery: Query<Doctor> = query(
      doctorModel.getPathReference().withConverter(doctorModel.firestoreConverter),
      where("trackExpirations", "==", true)
    );

    if (user.role?.name === "Medico") {
      snapshotQuery = query(snapshotQuery, where("userId", "==", user.id));
    }

    const snapshot: QuerySnapshot<Doctor> = await getDocs(doctorModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorModel.getDoctorsByFirm", {}, error);
    return [];
  }
};
