import { DocumentReference } from "firebase/firestore";

import { companyDutyModel } from "../models/CompanyDutyModel";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Duty } from "@/features/modules/duty/objects/Duty";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedRisk } from "@/features/modules/risk/objects/LinkedRisk";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { LinkedTestType } from "@/features/modules/testType/objects/LinkedTestType";

import { ArrayByKeyField, BooleanField, DateField, NumberWithDefaultField, StringField } from "@/core/fields";

export class CompanyDuty extends FirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public frequency = 999;
  public risks: Record<string, LinkedRisk> = {};
  public risksNotes: string | undefined = undefined;
  public testTypes: Record<string, LinkedTestType> = {};
  public surveys: Record<string, LinkedSurvey> = {};
  public notes: string | undefined = undefined;
  public archived = false;
  public archivedDate: Date | undefined = undefined;
  public oldBranchName: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public static createFromDuty(duty: Duty): CompanyDuty {
    const companyDuty: CompanyDuty = new CompanyDuty();
    companyDuty.name = duty.name;
    companyDuty.frequency = duty.frequency;
    companyDuty.risks = duty.risks;
    companyDuty.risksNotes = duty.risksNotes;
    companyDuty.testTypes = duty.testTypes;
    companyDuty.surveys = duty.surveys;

    return companyDuty;
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): CompanyDuty {
    super.fromFirestore(data, id, firestoreRef);

    this.name = StringField.fromFirestore(data.name);
    this.frequency = NumberWithDefaultField.fromFirestore(data.frequency, 999);
    this.risks = ArrayByKeyField.fromFirestore<LinkedRisk>(data.risks, (value) => new LinkedRisk(value));
    this.risksNotes = StringField.fromFirestore(data.risksNotes);
    this.testTypes = ArrayByKeyField.fromFirestore<LinkedTestType>(data.testTypes, (value) => new LinkedTestType(value));
    this.surveys = ArrayByKeyField.fromFirestore<LinkedSurvey>(data.surveys, (value) => new LinkedSurvey(value));
    this.notes = StringField.fromFirestore(data.notes);
    this.archived = BooleanField.fromFirestore(data.archived);
    this.archivedDate = DateField.fromFirestore(data.archivedDate);
    this.oldBranchName = StringField.fromFirestore(data.oldBranchName);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.frequency = NumberWithDefaultField.toFirestore(this.frequency, 999);
    firestoreData.risks = ArrayByKeyField.toFirestore<LinkedRisk>(this.risks, (value) => value.toFirestore());
    firestoreData.risksIds = ArrayByKeyField.getIds(this.risks);
    firestoreData.risksNotes = StringField.toFirestore(this.risksNotes);
    firestoreData.testTypes = ArrayByKeyField.toFirestore<LinkedTestType>(this.testTypes, (value) => value.toFirestore());
    firestoreData.testTypesIds = ArrayByKeyField.getIds(this.testTypes);
    firestoreData.surveys = ArrayByKeyField.toFirestore<LinkedSurvey>(this.surveys, (value) => value.toFirestore());
    firestoreData.surveysIds = ArrayByKeyField.getIds(this.surveys);
    firestoreData.notes = StringField.toFirestore(this.notes);
    firestoreData.archived = BooleanField.toFirestore(this.archived);
    firestoreData.archivedDate = DateField.toFirestore(this.archivedDate);
    firestoreData.oldBranchName = StringField.toFirestore(this.oldBranchName);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): CompanyDuty {
    super.fromOfflineCache(data);

    this.parentId = StringField.fromOfflineCache(data.parentId);
    this.name = StringField.fromOfflineCache(data.name);
    this.frequency = NumberWithDefaultField.fromOfflineCache(data.frequency, 999);
    this.risks = ArrayByKeyField.fromOfflineCache<LinkedRisk>(data.risks, (value) => {
      const linkedRisk: LinkedRisk = new LinkedRisk();
      return linkedRisk.fromOfflineCache(value);
    });
    this.risksNotes = StringField.fromOfflineCache(data.risksNotes);
    this.testTypes = ArrayByKeyField.fromOfflineCache<LinkedTestType>(data.testTypes, (value) => {
      const linkedTestType: LinkedTestType = new LinkedTestType();
      return linkedTestType.fromOfflineCache(value);
    });
    this.surveys = ArrayByKeyField.fromOfflineCache<LinkedSurvey>(data.surveys, (value) => {
      const linkedSurvey: LinkedSurvey = new LinkedSurvey();
      return linkedSurvey.fromOfflineCache(value);
    });
    this.notes = StringField.fromOfflineCache(data.notes);
    this.archived = BooleanField.fromOfflineCache(data.archived);
    this.archivedDate = DateField.fromOfflineCache(data.archivedDate);
    this.oldBranchName = StringField.fromOfflineCache(data.oldBranchName);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.parentId = this.firestoreRef !== undefined ? this.firestoreRef.parent.parent?.id : StringField.toOfflineCache(this.parentId);
    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.frequency = NumberWithDefaultField.toOfflineCache(this.frequency, 999);
    firestoreData.risks = ArrayByKeyField.toOfflineCache<LinkedRisk>(this.risks, (value) => value.toOfflineCache());
    firestoreData.risksIds = ArrayByKeyField.getIds(this.risks);
    firestoreData.risksNotes = StringField.toOfflineCache(this.risksNotes);
    firestoreData.testTypes = ArrayByKeyField.toOfflineCache<LinkedTestType>(this.testTypes, (value) => value.toOfflineCache());
    firestoreData.testTypesIds = ArrayByKeyField.getIds(this.testTypes);
    firestoreData.surveys = ArrayByKeyField.toOfflineCache<LinkedSurvey>(this.surveys, (value) => value.toOfflineCache());
    firestoreData.surveysIds = ArrayByKeyField.getIds(this.surveys);
    firestoreData.notes = StringField.toOfflineCache(this.notes);
    firestoreData.archived = BooleanField.toOfflineCache(this.archived);
    firestoreData.archivedDate = DateField.toOfflineCache(this.archivedDate);
    firestoreData.oldBranchName = StringField.toOfflineCache(this.oldBranchName);

    return firestoreData;
  }

  public getLinkedRisks(): LinkedRisk[] {
    return DataHelpers.objectToSortedArray<LinkedRisk>(this.risks);
  }

  public setLinkedRisks(linkedRisks: LinkedRisk[]): void {
    this.risks = DataHelpers.sortedArrayToObject<LinkedRisk>(linkedRisks);
  }

  public addLinkedRisk(linkedRisk: LinkedRisk): void {
    linkedRisk.order = Object.keys(this.risks).length + 1;
    this.risks[linkedRisk.id] = linkedRisk;
  }

  public removeLinkedRisk(linkedRisk: LinkedRisk): void {
    delete this.risks[linkedRisk.id];
  }

  public getLinkedTestTypes(): LinkedTestType[] {
    return DataHelpers.objectToSortedArray<LinkedTestType>(this.testTypes);
  }

  public setLinkedTestTypes(linkedTestTypes: LinkedTestType[]): void {
    this.testTypes = DataHelpers.sortedArrayToObject<LinkedTestType>(linkedTestTypes);
  }

  public addLinkedTestType(linkedTestType: LinkedTestType): void {
    linkedTestType.order = Object.keys(this.testTypes).length + 1;
    this.testTypes[linkedTestType.id] = linkedTestType;
  }

  public removeLinkedTestType(linkedTestType: LinkedTestType): void {
    delete this.testTypes[linkedTestType.id];
  }

  public getLinkedSurveys(): LinkedSurvey[] {
    return DataHelpers.objectToSortedArray<LinkedSurvey>(this.surveys);
  }

  public setLinkedSurveys(linkedSurveys: LinkedSurvey[]): void {
    this.surveys = DataHelpers.sortedArrayToObject<LinkedSurvey>(linkedSurveys);
  }

  public addLinkedSurvey(linkedSurvey: LinkedSurvey): void {
    linkedSurvey.order = Object.keys(this.surveys).length + 1;
    this.surveys[linkedSurvey.id] = linkedSurvey;
  }

  public removeLinkedSurvey(linkedSurvey: LinkedSurvey): void {
    delete this.surveys[linkedSurvey.id];
  }

  public async handleArchive(): Promise<void> {
    if (this.id === "new") {
      this.archivedDate = this.archived === true ? new Date() : undefined;
    } else {
      const oldCompanyDuty: CompanyDuty = await companyDutyModel.getDocument(this.id, this.parentId);
      if (oldCompanyDuty.archived !== this.archived) {
        this.archivedDate = this.archived === true ? new Date() : undefined;
      }
    }
  }
}
