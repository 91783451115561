
import { computed, defineComponent } from "vue";

import { authModel } from "@/core/modules/auth/models/AuthModel";
import { config } from "@/core/modules/config/objects/Config";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { storeTypes } from "@/core/modules/store/types";
import { useLocale } from "@/core/modules/locale/module";
import { User } from "@/core/modules/user/objects/User";
import { useStore } from "@/core/modules/store/module";

import LocaleSelector from "@/core/modules/locale/components/LocaleSelector.vue";
import NotificationSelector from "@/core/modules/notification/components/NotificationSelector.vue";
import OfflineComponent from "@/core/modules/offline/components/OfflineComponent.vue";
import ThemeSelector from "@/core/modules/theme/components/ThemeSelector.vue";
import TopBarLeftHolder from "@/features/layout/TopBarLeftHolder.vue";
import TopBarRightHolder from "@/features/layout/TopBarRightHolder.vue";

export default defineComponent({
  name: "TopBar",
  components: {
    LocaleSelector,
    NotificationSelector,
    OfflineComponent,
    ThemeSelector,
    TopBarLeftHolder,
    TopBarRightHolder,
  },
  emits: [
    "menu-button-click",
    "search-click",
    "topbar-notification",
    "topbar-user-menu",
    "topbar-lang-menu",
    "topbar-theme-menu",
    "right-menubutton-click",
  ],
  props: {
    topbarNotificationMenuActive: Boolean,
    topbarUserMenuActive: Boolean,
    topbarLangMenuActive: Boolean,
    topbarThemeMenuActive: Boolean,
    colorScheme: String,
  },
  setup() {
    const { t } = useLocale();
    const store = useStore();

    const user: User = store.getters[storeTypes.getters.getUser];

    const username = computed(() => {
      return user?.firstName || t("gen.guest");
    });

    const logoutAction = async () => {
      await authModel.logout();
      Navigation.navigateTo("/login");
    };

    return {
      config,
      logoutAction,
      t,
      user,
      username,
    };
  },
  methods: {
    onMenuButtonClick(event: Event): void {
      this.$emit("menu-button-click", event);
    },
    onSearchClick(event: Event): void {
      this.$emit("search-click", event);
    },
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.$emit("topbar-notification", event);
    },
    onTopbarUserMenuButtonClick(event: Event): void {
      this.$emit("topbar-user-menu", event);
    },
    onTopbarThemeMenuButtonClick(event: Event): void {
      this.$emit("topbar-theme-menu", event);
    },
    onTopbarLangMenuButtonClick(event: Event): void {
      this.$emit("topbar-lang-menu", event);
    },
    onRightMenuClick(event: Event): void {
      this.$emit("right-menubutton-click", event);
    },
  },
});
