import { Invoice } from "./Invoice";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";

import { DateStrictField, NumberField, StringStrictField } from "@/core/fields";

export class LinkedInvoice extends LinkedFirestoreDocument {
  public code = 0;
  public codeSort = 0;
  public codeDisplay = "";
  public year = 0;
  public date: Date = new Date();

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromInvoice(invoice: Invoice): LinkedInvoice {
    const linkedInvoice: LinkedInvoice = new LinkedInvoice();
    linkedInvoice.id = invoice.id;
    linkedInvoice.code = invoice.code;
    linkedInvoice.codeSort = invoice.codeSort;
    linkedInvoice.codeDisplay = invoice.codeDisplay;
    linkedInvoice.year = invoice.year;
    linkedInvoice.date = invoice.date;

    return linkedInvoice;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedInvoice {
    super.fromFirestore(data);

    this.code = NumberField.fromFirestore(data.code);
    this.codeSort = NumberField.fromFirestore(data.codeSort);
    this.codeDisplay = StringStrictField.fromFirestore(data.codeDisplay, "");
    this.year = NumberField.fromFirestore(data.year);
    this.date = DateStrictField.fromFirestore(data.date, new Date());

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.code = NumberField.toFirestore(this.code);
    firestoreData.codeSort = NumberField.toFirestore(this.codeSort);
    firestoreData.codeDisplay = StringStrictField.toFirestore(this.codeDisplay);
    firestoreData.year = NumberField.toFirestore(this.year);
    firestoreData.date = DateStrictField.toFirestore(this.date);

    return firestoreData;
  }
}
