import { Theme } from "../objects/Theme";

import { getSelectedTheme, getThemes, setSelectedTheme } from "./methods";

export class ThemeModel {
  public getSelectedTheme(): Theme {
    return getSelectedTheme();
  }

  public getThemes(): Theme[] {
    return getThemes();
  }

  public async setSelectedTheme(theme: Theme): Promise<void> {
    return setSelectedTheme(theme);
  }
}

export const themeModel: ThemeModel = new ThemeModel();
