import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { format } from "date-fns";
import { LinkedCompanyWithStatus } from "@/features/modules/company/objects/LinkedCompanyWithStatus";

export class EmployeeAssign {
  public id: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public firstName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public fiscalCode: string | undefined = undefined;
  public isAssignPossible = false;

  public static async createFromEmployee(employee: Employee, companyId: string): Promise<EmployeeAssign | undefined> {
    const employeeAssign: EmployeeAssign = new EmployeeAssign();
    employeeAssign.id = employee.id;
    employeeAssign.lastName = employee.lastName;
    employeeAssign.firstName = employee.firstName;
    employeeAssign.fullName = employee.fullName;
    employeeAssign.fiscalCode = employee.fiscalCode;

    const company: LinkedCompanyWithStatus | undefined = employee.getLinkedCompaniesWithStatus().find((company: LinkedCompanyWithStatus) => {
      return company.id === companyId;
    });

    if (company === undefined || company.isActive === false) return undefined;

    const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getDocuments([], employee.id);

    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);

    const previousEmployeeDuties: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
      return employeeDuty.company !== undefined && employeeDuty.company.id === companyId;
    });

    const previousEmployeeDutiesByDate: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
      return (
        employeeDuty.company !== undefined &&
        employeeDuty.company.id === companyId &&
        employeeDuty.fromSort < format(today, "yyyy-MM-dd") &&
        employeeDuty.toSort >= format(today, "yyyy-MM-dd")
      );
    });
    employeeAssign.isAssignPossible = previousEmployeeDuties.length === 0 || previousEmployeeDutiesByDate.length === 1;

    return employeeAssign;
  }
}
