import { Firm } from "./Firm";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { StringField } from "@/core/fields";

export class LinkedFirm extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromFirm(firm: Firm): LinkedFirm {
    const linkedFirm: LinkedFirm = new LinkedFirm();
    linkedFirm.id = firm.id;
    linkedFirm.name = firm.name;

    return linkedFirm;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedFirm {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedFirm {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);

    return firestoreData;
  }
}
