import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid formgrid grid" }
const _hoisted_2 = { class: "field col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.t('gen.findAddress'),
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isVisible) = $event)),
    style: { width: '50vw' },
    onShow: _ctx.init,
    modal: true,
    closable: false
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.t('buttons.close'),
        icon: "pi pi-times",
        onClick: _ctx.close
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            id: _ctx.elementId,
            type: "text",
            placeholder: _ctx.t('gen.inputAddress')
          }, null, 8, ["id", "placeholder"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onShow"]))
}