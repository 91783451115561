export const it = {
  who: {
    details1: "Ci occupiamo di sviluppo software per il web e per i dispositivi mobili.",
    details2: "Amiamo il codice ben scritto e il lavoro in squadra.",
    details3: "Per qualsiasi informazione puoi scriverci all'indirizzo",
    who: "Chi siamo",
  },
  navigation: {
    who: "Chi siamo",
  },
};
