import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { config } from "@/core/modules/config/objects/Config";
import { environment } from "@/core/modules/config/objects/environment";
import { Navigation } from "@/core/modules/navigation/objects/Navigation";
import { ToastHelpers } from "./ToastHelpers";

export class AppHelpers {
  /**
   * delay the execution for a given time
   * @param ms the delay in milliseconds
   */
  public static async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  /**
   * go to error page
   * @param error the error message
   */
  public static goToErrorPage(error: string | undefined): void {
    if (error === undefined) {
      localStorage.removeItem("lastError");
    } else {
      localStorage.setItem("lastError", error);
    }
    Navigation.navigateTo("/error");
  }

  /**
   * init error handling
   */
  public static initErrorHandling(): void {
    window.onerror = async (message, source, lineno, colno, error): Promise<void> => {
      const appError: AppError = new AppError("window error");
      appError.addToStack("window.onerror", { source: source, lineno: lineno, colno: colno }, error);
      await appFaultModel.createAppFaultFromAppError(appError);
    };
    window.addEventListener("error", async (event) => {
      const appError: AppError = new AppError("window error listener");
      appError.addToStack("window.errorListener", {}, event.error);
      await appFaultModel.createAppFaultFromAppError(appError);
    });
  }

  /**
   * select all text in input
   * @param val the event
   */
  public static inputSelectAll(val: PointerEvent): void {
    const target: HTMLInputElement = val.target as HTMLInputElement;
    if (target != undefined) target.setSelectionRange(0, target.value.length);
  }

  /**
   * prevent window close
   */
  public static preventWindowClose(): void {
    if (environment === "development") return;
    window.onbeforeunload = function (e) {
      e.returnValue = "onbeforeunload";
      return "onbeforeunload";
    };
  }

  /**
   * prompt a file download from content and type
   * @param content the file content as string
   * @param filename the file name
   * @param mimeType the mime type
   */
  public static promptDownloadFromContent(content: string, filename: string, mimeType: string): void {
    const blob = new Blob([content], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  }

  /**
   * prompt a file download from base64 content and type
   * @param base64Content the file base64 content
   * @param filename the file name
   * @param mimeType the mime type
   */
  public static promptDownloadFromBase64(base64Content: string, filename: string, mimeType: string): void {
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
  }

  /**
   * reset local storage if needed
   */
  public static resetLocalStorageIfNeeded(): void {
    if (!localStorage.getItem("koruVersion")) {
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem("koruVersion", config.app.version);
    }
    const currentAppVersion: string = localStorage.getItem("koruVersion") as string;
    console.log("KORU Version", currentAppVersion);
    if (currentAppVersion !== config.app.version) {
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem("koruVersion", config.app.version);
    }
  }

  /**
   * show debug message
   * @param moduleName the module name
   * @param message the debug message
   */
  public static showDebug(moduleName: string, message: string): void {
    console.log(`[DEBUG] ${moduleName}: ${message}`);
  }

  /**
   * simple cell save
   * @param event the event
   */
  public static simpleCellSave(event: Record<string, unknown>): void {
    const { data, newValue, field } = event;
    (data as Record<string, unknown>)[field as string] = newValue;
  }

  /**
   * try or go to error page
   * @param action the action to try
   * @param final the final action
   */
  public static async tryOrError(action: () => Promise<void>, final?: () => void): Promise<void> {
    try {
      await action();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("AppHelpers.tryOrError", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      AppHelpers.goToErrorPage((error as Error).message);
    } finally {
      if (final !== undefined) final();
    }
  }

  /**
   * try or catch the error silently
   * @param action the action to try
   * @param final the final action
   */
  public static async tryOrSilent(action: () => Promise<void>, final?: () => void): Promise<void> {
    try {
      await action();
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("AppHelpers.tryOrError", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
    } finally {
      if (final !== undefined) final();
    }
  }

  /**
   * try or show a toast
   * @param action the action to try
   * @param message the message to show
   * @param t the translation function
   * @param final the final action
   * @param showSuccess the show success flag
   */
  public static async tryOrToast(
    action: () => Promise<void>,
    message: string,
    t: (entry: string) => string,
    final?: () => void,
    showSuccess = true
  ): Promise<void> {
    try {
      await action();
      if (showSuccess) ToastHelpers.showToastWithSuccess(message, t);
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("AppHelpers.tryOrToast", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      ToastHelpers.showToastWithError((error as Error).message, message, t);
    } finally {
      if (final !== undefined) final();
    }
  }

  /**
   * validate field in array
   * @param validation the validation object
   * @param field the field name
   * @param index the index of the array
   * @returns field has error
   */
  public static validationHasErrorAtFieldAndIndex(validation: Record<string, unknown> | undefined, field: string, index: number): boolean {
    if (validation === undefined) return false;
    const each: Record<string, unknown> = validation.$each as Record<string, unknown>;
    const response: Record<string, unknown> = each.$response as Record<string, unknown>;
    const errors: Record<string, unknown>[] = response.$errors as Record<string, unknown>[];
    const fieldErrors: Record<string, unknown>[] = errors[index][field] as Record<string, unknown>[];

    return fieldErrors.length > 0;
  }
}
