import { addMonths, compareAsc, format, subDays } from "date-fns";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Contract } from "@/features/modules/contract/objects/Contract";
import { contractModel } from "@/features/modules/contract/models/ContractModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "@/features/modules/employeeDuty/models/EmployeeDutyModel";
import { EmployeeExamination } from "@/features/modules/employee/objects/EmployeeExamination";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Employer } from "@/features/modules/employer/objects/Employer";
import { employerModel } from "@/features/modules/employer/models/EmployerModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { ExaminationTest } from "@/features/modules/examination/objects/ExaminationTest";
import { ExamType } from "@/features/modules/examType/objects/ExamType";
import { examTypeModel } from "@/features/modules/examType/models/ExamTypeModel";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { externalTestModel } from "@/features/modules/externalTest/models/ExternalTestModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { LinkedBranch } from "@/features/modules/branch/objects/LinkedBranch";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedDoctor } from "@/features/modules/doctor/objects/LinkedDoctor";
import { LinkedEmployee } from "@/features/modules/employee/objects/LinkedEmployee";
import { LinkedExamType } from "@/features/modules/examType/objects/LinkedExamType";
import { LinkedExternalTest } from "@/features/modules/externalTest/objects/LinkedExternalTest";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { setting } from "@/core/modules/setting/Setting";

export const createExaminationForEmployee = async (employeeExamination: EmployeeExamination): Promise<string> => {
  try {
    if (employeeExamination === undefined) throw new Error("createExamination");
    if (employeeExamination.date === undefined) throw new Error("createExaminationMissingData");
    if (employeeExamination.companyId === undefined) throw new Error("createExaminationMissingData");
    if (employeeExamination.branchId === undefined) throw new Error("createExaminationMissingData");
    if (employeeExamination.doctorId === undefined) throw new Error("createExaminationMissingData");
    if (employeeExamination.employeeId === undefined) throw new Error("createExaminationMissingData");

    const examinationDate: Date = employeeExamination.date;
    const company: Company = await companyModel.getDocument(employeeExamination.companyId);
    if (company === undefined) throw new Error("createExamination");
    const branch: Branch = await branchModel.getDocument(employeeExamination.branchId);
    if (branch === undefined) throw new Error("createExamination");
    const doctor: Doctor = await doctorModel.getDocument(employeeExamination.doctorId);
    if (doctor === undefined) throw new Error("createExamination");
    const employee: Employee = await employeeModel.getDocument(employeeExamination.employeeId);
    if (employee === undefined) throw new Error("createExamination");

    let examType: ExamType | undefined = undefined;
    if (employeeExamination.examTypeId !== undefined) {
      examType = await examTypeModel.getDocument(employeeExamination.examTypeId);
      if (examType === undefined) throw new Error("createExamination");
    }

    const linkedFirm: LinkedFirm = LinkedFirm.createFromFirm(await firmModel.getSelectedFirm());
    if (linkedFirm === undefined) throw new Error("createExaminationNoFirm");

    // get employee duties
    const employeeDuties: EmployeeDuty[] = await employeeDutyModel.getDocuments([], employee.id);

    // check active employee duties for examination date
    const activeEmployeeDuties: EmployeeDuty[] = employeeDuties.filter((employeeDuty: EmployeeDuty) => {
      if (employeeDuty.company?.id !== company.id) return false;
      if (employeeDuty.from !== undefined && compareAsc(employeeDuty.from, examinationDate) > 0) return false;
      if (employeeDuty.to !== undefined && compareAsc(examinationDate, employeeDuty.to) > 0) return false;
      return true;
    });
    if (activeEmployeeDuties.length === 0) throw new Error("createExaminationEmployeeNoDuty");
    if (activeEmployeeDuties.length > 1) throw new Error("createExaminationEmployeeMultipleDuties");
    const employeeDuty: EmployeeDuty = activeEmployeeDuties[0];

    // get employers
    const employers: Employer[] = await employerModel.getDocuments([], employee.id);

    // check active employers for examination date
    const activeEmployers: Employer[] = employers.filter((employer: Employer) => {
      if (employer.company?.id !== company.id) return false;
      if (employer.from !== undefined && compareAsc(employer.from, examinationDate) > 0) return false;
      if (employer.to !== undefined && compareAsc(examinationDate, employer.to) > 0) return false;
      return true;
    });
    if (activeEmployers.length === 0) throw new Error("createExaminationEmployeeNoEmployer");
    if (activeEmployers.length > 1) throw new Error("createExaminationEmployeeMultipleEmployers");
    const employer: Employer = activeEmployers[0];

    // get active contracts
    const contracts: Contract[] = await contractModel.getContractsByFirmAndCompany(company.id);
    const activeContracts: Contract[] = contracts.filter((contract: Contract) => {
      if (contract.from !== undefined && compareAsc(contract.from, examinationDate) > 0) return false;
      if (contract.to !== undefined && compareAsc(examinationDate, contract.to) > 0) return false;
      if (contract.getLinkedBranches().find((linkedBranch) => linkedBranch.id === branch.id) === undefined) return false;
      return true;
    });
    if (activeContracts.length === 0) throw new Error("createExaminationNoContract");

    // check available doctors
    const doctors: LinkedDoctor[] = [];
    for (const contract of activeContracts) {
      if (contract.doctor === undefined) continue;
      if (doctors.find((doctor) => doctor.id === contract.doctor?.id) === undefined) {
        doctors.push(contract.doctor);
      }
    }
    if (doctors.find((checkDoctor) => checkDoctor.id === doctor.id) === undefined) throw new Error("createExaminationDoctorNotAvailable");

    // check if another draft examination exists
    const draftExaminations: Examination[] = await examinationModel.getDraftExaminationsByEmployeeAndCompanyAndFirm(employee.id, company.id);
    if (draftExaminations.length > 0) throw new Error("createExaminationDraftExists");

    // create examination
    const examination: Examination = new Examination();
    examination.firm = linkedFirm;
    examination.date = new Date(examinationDate.getTime());
    if (employeeExamination.time === undefined) {
      examination.time = new Date(examination.date.getTime());
      examination.time.setHours(9, 0, 0, 0);
    } else {
      examination.time = new Date(employeeExamination.time.getTime());
      // examination.time = new Date(examination.date.getTime()); old
      // examination.time.setHours(employeeExamination.time.getHours()); old
    }
    examination.hireDate = employer.from;
    examination.dutyName = employeeDuty.name;
    examination.dutyDate = employeeDuty.from;
    examination.frequency = employeeDuty.frequency;
    examination.employee = LinkedEmployee.createFromEmployee(employee);
    examination.company = LinkedCompany.createFromCompany(company);
    examination.branch = LinkedBranch.createFromBranch(branch);
    examination.doctor = LinkedDoctor.createFromDoctor(doctor);
    examination.risks = employeeDuty.risks;
    examination.risksNotes = employeeDuty.risksNotes;

    const expectedNextExaminationDate: Date = subDays(addMonths(examination.date, examination.frequency), 1);

    examination.setGoodHealthFields();

    // search for external tests to include
    const externalTests: ExternalTest[] = await externalTestModel.getExternalTestsToIncludeByCompanyAndEmployee(company.id, employee.id);
    for (const externalTest of externalTests) {
      const linkedExternalTest: LinkedExternalTest = LinkedExternalTest.createFromExternalTest(externalTest);
      linkedExternalTest.order = externalTest.testType?.order ?? 999;
      examination.addExternalTest(linkedExternalTest);
    }

    // get employees old examinations for selected company
    const oldExaminations: Examination[] = await examinationModel.getExaminationsByEmployeeAndCompanyAndFirm(employee.id, company.id);
    if (oldExaminations.length === 0) {
      // no old examinations
      // set the examination type to first
      if (employeeExamination.examTypeId === undefined) {
        examType = await examTypeModel.getDocument(setting.getSetting<string>("firstExamTypeId"));
      }

      // create tests from employee duty
      for (const testType of employeeDuty.getLinkedTestTypes()) {
        // if the test is already included as an external test, skip it
        if (examination.getExternalTests().filter((externalTest) => externalTest.testType?.id === testType.id).length > 0) continue;

        const examinationTest: ExaminationTest = new ExaminationTest();
        examinationTest.date = new Date(examinationDate.getTime());
        examinationTest.testType = testType;
        examinationTest.createTestForm();
        examinationTest.order = testType.order;
        examination.addExaminationTest(examinationTest);
      }
      examination.setTestsSummary();

      // insert all surveys
      for (const survey of employeeDuty.getLinkedSurveys()) {
        survey.date = new Date(examinationDate.getTime());
        survey.createSurveyForm();
        examination.addLinkedSurvey(survey);
      }
      examination.setSurveysSummary();
    } else {
      // there is at least one old examination
      const lastExamination: Examination = oldExaminations[oldExaminations.length - 1];
      // copy last examination data to the new examination
      examination.workHistory = lastExamination.workHistory;
      examination.familyHistory = lastExamination.familyHistory;
      examination.physioHistory = lastExamination.physioHistory;
      examination.farHistory = lastExamination.farHistory;
      // last recent history goes to new far history
      if (lastExamination.recentHistory.notes !== undefined) {
        examination.farHistory.goodHealthCheck = false;
        if (examination.farHistory.healthDetails === undefined) examination.farHistory.healthDetails = "";
        examination.farHistory.healthDetails += `\n\n${format(lastExamination.date, "dd/MM/yyyy")} - ${lastExamination.recentHistory.notes}`;
        examination.farHistory.healthDetails = examination.farHistory.healthDetails.trim();
      }
      examination.physicalExam = lastExamination.physicalExam;
      examination.ending.ending = lastExamination.ending.ending;
      examination.ending.tags = lastExamination.ending.tags;

      // set the examination type to periodic
      if (employeeExamination.examTypeId === undefined) {
        examType = await examTypeModel.getDocument(setting.getSetting<string>("periodicExamTypeId"));
      }
      // create tests according to expiration
      for (const testType of employeeDuty.getLinkedTestTypes()) {
        // if the test is already included as an external test, skip it
        if (examination.getExternalTests().filter((externalTest) => externalTest.testType?.id === testType.id).length > 0) continue;
        // if frequency is not expected, skip the testType
        if (testType.frequency > 900) continue;
        // search for previously done testTypes
        let lastPerformedDate: Date | undefined = undefined;
        for (const oldExamination of oldExaminations) {
          const oldTest: ExaminationTest | undefined = oldExamination.getExaminationTests().find((oldTest) => oldTest.testType?.id === testType.id);
          if (oldTest !== undefined && oldTest.date !== undefined) {
            if (lastPerformedDate === undefined || compareAsc(oldTest.date, lastPerformedDate) > 0) lastPerformedDate = oldTest.date;
          } else {
            // maybe it is an external test
            const oldExternalTest: LinkedExternalTest | undefined = oldExamination
              .getExternalTests()
              .find((oldTest) => oldTest.testType?.id === testType.id);
            if (oldExternalTest !== undefined && oldExternalTest.date !== undefined) {
              if (lastPerformedDate === undefined || compareAsc(oldExternalTest.date, lastPerformedDate) > 0) {
                lastPerformedDate = oldExternalTest.date;
              }
            }
          }
        }
        // if testType has never been performed or it is going to expire before the next examination, add it to the examination
        if (lastPerformedDate === undefined || compareAsc(expectedNextExaminationDate, addMonths(lastPerformedDate, testType.frequency)) >= 0) {
          const examinationTest: ExaminationTest = new ExaminationTest();
          examinationTest.date = new Date(examinationDate.getTime());
          examinationTest.testType = testType;
          examinationTest.createTestForm();
          examinationTest.order = testType.order;
          examination.addExaminationTest(examinationTest);
        }
      }
      examination.setTestsSummary();

      // insert surveys according to expiration
      for (const survey of employeeDuty.getLinkedSurveys()) {
        // if frequency is not expected, skip the survey
        if (survey.frequency > 900) continue;
        // search for previously done surveys
        let lastPerformedSurvey: LinkedSurvey | undefined = undefined;
        for (const oldExamination of oldExaminations) {
          const oldSurvey: LinkedSurvey | undefined = oldExamination.getLinkedSurveys().find((oldSurvey) => oldSurvey.id === survey.id);
          if (oldSurvey === undefined) continue;
          if (oldSurvey.date === undefined) continue;
          if (
            lastPerformedSurvey === undefined ||
            (lastPerformedSurvey.date !== undefined && compareAsc(oldSurvey.date, lastPerformedSurvey.date) > 0)
          ) {
            lastPerformedSurvey = oldSurvey;
            if (oldSurvey.surveyForm !== undefined) oldSurvey.surveyForm.isCompleted = false;
          }
        }
        if (lastPerformedSurvey === undefined) {
          // if survey has never been performed, add it to the examination
          survey.date = new Date(examinationDate.getTime());
          survey.createSurveyForm();
          examination.addLinkedSurvey(survey);
          console.log("new survey");
        } else if (
          lastPerformedSurvey.date !== undefined &&
          compareAsc(expectedNextExaminationDate, addMonths(lastPerformedSurvey.date, survey.frequency))
        ) {
          // if survey is going to expire before the next examination, add it to the examination cloning the last performed one
          lastPerformedSurvey.date = new Date(examinationDate.getTime());
          if (lastPerformedSurvey.surveyForm !== undefined) lastPerformedSurvey.surveyForm.isCompleted = false;
          survey.date = new Date(examinationDate.getTime());
          survey.createSurveyForm();
          survey.surveyForm = lastPerformedSurvey.surveyForm;
          if (survey.surveyForm !== undefined) survey.surveyForm.isCompleted = false;
          examination.addLinkedSurvey(survey);
          console.log("previous survey");
        }
      }
      examination.setSurveysSummary();
    }

    if (examType === undefined) throw new Error("createExamination");
    examination.type = LinkedExamType.createFromExamType(examType);

    return examinationModel.createDocument(examination);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.createExaminationForEmployee", { employeeExamination }, error);
    throw new Error((error as Error).message);
  }
};
