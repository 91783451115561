import { ExamType } from "./ExamType";
import { ExamTypeMode } from "./ExamTypeMode";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { EnumField, StringField } from "@/core/fields";

export class LinkedExamType extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public mode: ExamTypeMode = ExamTypeMode.Recurring;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromExamType(examType: ExamType): LinkedExamType {
    const linkedExamType: LinkedExamType = new LinkedExamType();
    linkedExamType.id = examType.id;
    linkedExamType.name = examType.name;
    linkedExamType.mode = examType.mode;

    return linkedExamType;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedExamType {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.mode = EnumField.fromFirestore<ExamTypeMode>(data.mode, Object.values(ExamTypeMode), ExamTypeMode.Recurring);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.mode = EnumField.toFirestore<ExamTypeMode>(this.mode, ExamTypeMode.Recurring);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedExamType {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.mode = EnumField.fromOfflineCache<ExamTypeMode>(data.mode, Object.values(ExamTypeMode), ExamTypeMode.Recurring);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.mode = EnumField.toOfflineCache<ExamTypeMode>(this.mode, ExamTypeMode.Recurring);

    return firestoreData;
  }
}
