import { LocaleModule } from "@/core/modules/locale/objects/LocaleModule";

import { de } from "./de";
import { en } from "./en";
import { fr } from "./fr";
import { it } from "./it";

export const whoLocaleModule: LocaleModule = {
  name: "who",
  messages: { de: de, en: en, fr: fr, it: it },
};
