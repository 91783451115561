export const de = {
  auth: {
    askNewPassword: "Nach neuem Passwort fragen",
    authenticationSubtitle: "Melden Sie sich mit Ihrem Konto an",
    authenticationTitle: "Authentifizierung",
    checkInbox: "Überprüfen Sie Ihr Postfach",
    choosePassword: "Wähle ein neues Passwort",
    email: "E-Mail-Adresse",
    emailBlank: "Geben Sie Ihre E-Mail-Adresse ein",
    emailError: "Die E-Mail-Adresse ist ungültig",
    forgetError: "Passwort konnte nicht wiederhergestellt werden",
    forgotPasswordTitle: "Passwort wiederherstellen",
    forgetPasswordStart: "Passwort vergessen?",
    forgotPasswordMiddle: "Require",
    forgetPasswordEnd: "Wiederherstellung jetzt!",
    inputEmail: "Geben Sie Ihre E-Mail-Adresse ein",
    login: "Anmeldung",
    loginFailed: "Authentifizierung fehlgeschlagen",
    password: "Passwort",
    passwordBlank: "Neues Passwort eingeben",
    passwordConfirm: "Neues Passwort bestätigen",
    passwordConfirmBlank: "Bitte geben Sie eine neue Passwortbestätigung ein",
    passwordConfirmError: "Die zwei Passwörter stimmen nicht überein",
    passwordError: "Bitte geben Sie ein gültiges Passwort ein",
    passwordErrorChars: "Passwort muss mindestens 8 Zeichen lang sein",
    passwordErrorLowercase: "Bitte geben Sie mindestens einen Kleinbuchstaben ein",
    passwordErrorNumber: "Bitte geben Sie mindestens eine Ziffer ein",
    passwordErrorUppercase: "Bitte geben Sie mindestens einen Großbuchstaben ein",
    passwordRequirementsTitle: "Anforderungen",
    passwordRequirementsChars: "Mindestens 8 Zeichen",
    passwordRequirementsLowercase: "Mindestens ein Kleinbuchstabe",
    passwordRequirementsNumber: "Mindestens eine Ziffer",
    passwordRequirementsUppercase: "Mindestens ein Großbuchstabe",
    passwordRememberedStart: "Passwort merken? Zurück zu",
    passwordRememberedEnd: "Authentifizierung",
    recoveryPasswordTitle: "Passwort wiederherstellen",
    resetPassword: "Passwort zurücksetzen",
    resetPasswordError: "Passwort konnte nicht zurückgesetzt werden",
    resetPasswordSuccessStart: "Neues Passwort wurde festgelegt. Gehe zu",
    resetPasswordSuccessEnd: "Authentifizierung",
  },
};
