import { Ref, ref } from "vue";

import { TreeNode } from "primevue/tree";

import { ContextCommand } from "@/core/modules/layout/interfaces/ContextCommand";

export class KoruTreeModule<T> {
  public canCreate = true;
  public canDelete = true;
  public canEdit = true;
  public deleteDescriptionField = "";
  public documents: Ref<T[]> = ref([]);
  public expandedKeys: Ref<Record<string, unknown>> = ref({});
  public explicitRequiredModule: string | undefined = undefined;
  public loadingStop = true;
  public contextCommands: ContextCommand[] = [];
  public suffix = "";

  public computeNodes(): TreeNode[] {
    return [];
  }

  public onCreateAction(parent: unknown | undefined): Promise<void> {
    console.log(parent);
    return Promise.resolve();
  }

  public onDeleteAction(document: T): Promise<boolean> {
    console.log(document);
    return Promise.resolve(true);
  }

  public onLoadAction(): Promise<void> {
    return Promise.resolve();
  }

  public onMountedAction(): Promise<void> {
    return Promise.resolve();
  }

  public onUpdateAction(document: T): Promise<void> {
    console.log(document);
    return Promise.resolve();
  }
}
