import { config } from "@/core/modules/config/objects/Config";
import { HistoryItem } from "./HistoryItem";

export class History {
  public historyItems: HistoryItem[];

  public constructor() {
    this.historyItems = [];

    const sessionHistory: string | null = localStorage.getItem("koruHistory");
    if (sessionHistory !== null) {
      const sessionItems: Record<string, string>[] = JSON.parse(sessionHistory);
      for (const sessionItem of sessionItems) {
        const historyItem: HistoryItem = new HistoryItem(
          sessionItem.title,
          sessionItem.subtitle,
          sessionItem.icon,
          sessionItem.url,
          sessionItem.date
        );
        this.historyItems.push(historyItem);
      }
    }
  }

  public add(title: string, subtitle: string, icon: string, url: string): void {
    const duplicateItemIndex: number = this.historyItems.findIndex((item: HistoryItem) => item.url === url);
    if (duplicateItemIndex !== -1) this.historyItems.splice(duplicateItemIndex, 1);

    const historyItem: HistoryItem = new HistoryItem(title, subtitle, icon, url);
    this.historyItems.unshift(historyItem);

    if (this.historyItems.length > config.app.history.size) this.historyItems.pop();

    localStorage.setItem("koruHistory", JSON.stringify(this.historyItems));
  }

  public clear(): void {
    this.historyItems = [];
    localStorage.removeItem("koruHistory");
  }

  public getItems(): HistoryItem[] {
    return this.historyItems;
  }
}

export const history: History = new History();
