import { DocumentReference } from "firebase/firestore";

import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { LinkedTestTypeCategory } from "@/features/modules/testTypeCategory/objects/LinkedTestTypeCategory";

import { BooleanField, NumberWithDefaultField, ObjectField, StringField } from "@/core/fields";

export class TestType extends FirestoreOfflineDocument {
  public category: LinkedTestTypeCategory | undefined = undefined;
  public name: string | undefined = undefined;
  public frequency = 999;
  public formName: string | undefined = undefined;
  public goodResultLabel: string | undefined = undefined;
  public badResultLabel: string | undefined = undefined;
  public onlyInExamination = false;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): TestType {
    super.fromFirestore(data, id, firestoreRef);

    this.category = ObjectField.fromFirestore<LinkedTestTypeCategory>(data.category, (value) => new LinkedTestTypeCategory(value));
    this.name = StringField.fromFirestore(data.name);
    this.frequency = NumberWithDefaultField.fromFirestore(data.frequency, 999);
    this.formName = StringField.fromFirestore(data.formName);
    this.goodResultLabel = StringField.fromFirestore(data.goodResultLabel);
    this.badResultLabel = StringField.fromFirestore(data.badResultLabel);
    this.onlyInExamination = BooleanField.fromFirestore(data.onlyInExamination);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.category = ObjectField.toFirestore<LinkedTestTypeCategory>(this.category, (value) => value.toFirestore());
    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.frequency = NumberWithDefaultField.toFirestore(this.frequency, 999);
    firestoreData.formName = StringField.toFirestore(this.formName);
    firestoreData.goodResultLabel = StringField.toFirestore(this.goodResultLabel);
    firestoreData.badResultLabel = StringField.toFirestore(this.badResultLabel);
    firestoreData.onlyInExamination = BooleanField.toFirestore(this.onlyInExamination);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): TestType {
    super.fromOfflineCache(data);

    this.category = ObjectField.fromOfflineCache<LinkedTestTypeCategory>(data.category, (value) => {
      const linkedTestTypeCategory: LinkedTestTypeCategory = new LinkedTestTypeCategory(value);
      return linkedTestTypeCategory.fromOfflineCache(value);
    });
    this.name = StringField.fromOfflineCache(data.name);
    this.frequency = NumberWithDefaultField.fromOfflineCache(data.frequency, 999);
    this.formName = StringField.fromOfflineCache(data.formName);
    this.goodResultLabel = StringField.fromOfflineCache(data.goodResultLabel);
    this.badResultLabel = StringField.fromOfflineCache(data.badResultLabel);
    this.onlyInExamination = BooleanField.fromOfflineCache(data.onlyInExamination);
    this.order = NumberWithDefaultField.fromOfflineCache(data.order, 999);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.category = ObjectField.toOfflineCache<LinkedTestTypeCategory>(this.category, (value) => value.toOfflineCache());
    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.frequency = NumberWithDefaultField.toOfflineCache(this.frequency, 999);
    firestoreData.formName = StringField.toOfflineCache(this.formName);
    firestoreData.goodResultLabel = StringField.toOfflineCache(this.goodResultLabel);
    firestoreData.badResultLabel = StringField.toOfflineCache(this.badResultLabel);
    firestoreData.onlyInExamination = BooleanField.toOfflineCache(this.onlyInExamination);
    firestoreData.order = NumberWithDefaultField.toOfflineCache(this.order, 999);

    return firestoreData;
  }
}
