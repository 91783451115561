import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Paper } from "@/core/modules/paper/objects/Paper";
import { PaperHelpers } from "@/features/modules/paper/helpers/PaperHelpers";
import { ServiceCount } from "../objects/ServiceCount";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";

export class ServiceCounterPaper extends Paper {
  private companiesIds: string[];
  private doctorId: string;
  private doctor: Doctor | undefined;
  private endDate: Date;
  private serviceCounts: ServiceCount[];
  private mode: "ByCompanies" | "ByCompaniesAndDoctor";
  private startDate: Date;

  constructor(
    companiesIds: string[],
    doctorId: string,
    startDate: Date,
    endDate: Date,
    serviceCounts: ServiceCount[],
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.companiesIds = companiesIds;
    this.doctorId = doctorId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.serviceCounts = serviceCounts;
    this.mode = "ByCompanies";
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      documentTitle: { alignment: "center", bold: true, color: this.highlightColor, fontSize: 14, margin: [0, 0, 0, 0] },
      footerText: { color: this.footerColor, fontSize: 8, italics: true },
      fullName: { bold: true },
      headerText: { color: this.textColor, fontSize: 10 },
      headerField: { fontSize: 6 },
      headerTable: { alignment: "center" },
      headerValue: { border: false, color: this.highlightColor, fontSize: 10 },
      listTable: { fontSize: 8, margin: [0, 5, 0, 15] },
      listTableHeader: { color: this.highlightColor, bold: true, fontSize: 10 },
      listTableRight: { alignment: "right", margin: [0, 0, 5, 0] },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    this.mode = "ByCompanies";
    if (this.doctorId !== undefined && this.doctorId !== "all") {
      this.doctor = await doctorModel.getDocument(this.doctorId);
      this.mode = "ByCompaniesAndDoctor";
    }

    this.setTitle(this.t(`statServiceCounter.paperTitle${this.mode}`).toUpperCase(), isContinuos);
    this.setAuthor(store.getters[storeTypes.getters.getUser]);
    this.setHighlightColor("#000000");

    const customHeader = await PaperHelpers.getCustomHeader(this.d, this.n, this.t);
    this.setHeader(customHeader);

    const customFooter = PaperHelpers.getCustomFooter(
      this.d,
      this.n,
      this.t,
      "La copia elettronica è conforme all'originale depositato presso lo studio medico"
    );
    this.setFooter(customFooter);

    if (this.mode === "ByCompanies") {
      this.writeByCompaniesHeaderFields();
    } else if (this.mode === "ByCompaniesAndDoctor") {
      this.writeByCompaniesAndDoctorHeaderFields();
    }

    this.writeBodyTable();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeByCompaniesHeaderFields(): void {
    let period: string = this.d(this.startDate, "shortDate");
    if (this.startDate.getTime() !== this.endDate.getTime()) {
      period += ` - ${this.d(this.endDate, "shortDate")}`;
    }

    const headerTableBody: unknown[] = [
      [
        { text: period ?? "-", style: "headerValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statServiceCounter.manyCompanies").toLocaleUpperCase(), style: "headerValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("statServiceCounter.period"), style: "headerField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statServiceCounter.companies"), style: "headerField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "headerTable",
      table: {
        body: headerTableBody,
        headerRows: 0,
        widths: ["*", DataHelpers.mmToPoints(6), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeByCompaniesAndDoctorHeaderFields(): void {
    let period: string = this.d(this.startDate, "shortDate");
    if (this.startDate.getTime() !== this.endDate.getTime()) {
      period += ` - ${this.d(this.endDate, "shortDate")}`;
    }

    const headerTableBody: unknown[] = [
      [
        { text: period ?? "-", style: "headerValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statServiceCounter.manyCompanies").toLocaleUpperCase(), style: "headerValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.doctor?.fullName?.toLocaleUpperCase() ?? "-", style: "headerValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("statServiceCounter.period"), style: "headerField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statServiceCounter.companies"), style: "headerField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("statServiceCounter.doctor"), style: "headerField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "headerTable",
      table: {
        body: headerTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(50), DataHelpers.mmToPoints(6), "*", DataHelpers.mmToPoints(6), DataHelpers.mmToPoints(50)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeBodyTable(): void {
    const listTableRows: unknown[] = this.serviceCounts.map((serviceCount: ServiceCount) => {
      return [
        { text: this.t(`statServiceCounter.serviceTypes.${serviceCount.type}`) },
        { text: serviceCount.name ?? "-" },
        { text: this.n(serviceCount.count, "number0"), style: "listTableRight" },
      ];
    });

    const listTableBody: unknown[] = [
      [
        { text: this.t("statServiceCounter.serviceCountType"), style: "listTableHeader" },
        { text: this.t("statServiceCounter.serviceCountName"), style: "listTableHeader" },
        { text: this.t("statServiceCounter.serviceCountCount"), style: "listTableHeader" },
      ],
      ...listTableRows,
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      style: "listTable",
      table: {
        body: listTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(35), "*", DataHelpers.mmToPoints(20)],
      },
      layout: {
        hLineColor: () => "#555555",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        hLineWidth: (i: number, node: any) => {
          return i === node.table.headerRows ? 2 : 0.5;
        },
        vLineColor: () => "#555555",
        vLineWidth: () => 0.5,
        paddingTop: () => 5,
        paddingBottom: () => 5,
        paddingLeft: () => 8,
        paddingRight: () => 0,
        fillColor: (i: number) => (i % 2 === 0 ? null : this.highlightColor),
        fillOpacity: (i: number) => (i % 2 === 0 ? 0 : 0.05),
      },
    });
  }
}
