export const de = {
  history: {
    actions: {
      browse: "Durchsuchen",
      create: "Erstellen",
      edit: "Bearbeiten",
      search: "Suchen",
      view: "Anzeigen",
    },
    history: "Verlauf",
    noHistory: "Verlauf ist leer",
  },
};
