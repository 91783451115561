export const it = {
  duty: {
    addRisk: "Aggiungi rischio",
    addSurvey: "Aggiungi questionario",
    addTestType: "Aggiungi accertamento",
    clonedDutyName: "Copia di {name}",
    details: "Dettagli",
    duty: "Mansione",
    duties: "Mansioni",
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    name: "Nome",
    nameError: "Inserire un nome valido",
    norm: "Normativa",
    requiredTestTypes: "Accertamenti previsti",
    risks: "Rischi",
    risksNotes: "Altri rischi valutati",
    surveys: "Questionari",
    testTypes: "Accertamenti",
  },
  buttons: {
    cloneDuty: "Duplica mansione",
  },
  navigation: {
    duty: "Mansioni",
  },
};
