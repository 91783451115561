import { Examination } from "@/features/modules/examination/objects/Examination";

import {
  executeAudioTest,
  executeDrugTest,
  executeSpiroTest,
  getAssistState,
  requestAttachments,
  setAssistState,
  setExamination,
  signDocument,
  uploadAttachment,
  uploadAudioTest,
  uploadDrugTest,
  uploadSpiroTest,
} from "./methods";

export class AssistModel {
  public examination: Examination | undefined;

  public getAssistState(): "connected" | "disconnected" {
    return getAssistState();
  }

  public setAssistState(state: "connected" | "disconnected"): void {
    return setAssistState(state);
  }

  public executeAudioTest(examination: Examination): void {
    return executeAudioTest(examination);
  }

  public executeDrugTest(examination: Examination): void {
    return executeDrugTest(examination);
  }

  public async executeSpiroTest(examination: Examination): Promise<void> {
    return executeSpiroTest(examination);
  }

  public requestAttachments(examination: Examination): void {
    return requestAttachments(examination);
  }

  public setExamination(examination: Examination | undefined): void {
    return setExamination(examination);
  }

  public signDocument(name: string, base64File: string, examination: Examination): void {
    return signDocument(name, base64File, examination);
  }

  public async uploadAttachment(base64File: string, name: string, type: string): Promise<void> {
    return uploadAttachment(base64File, name, type);
  }

  public async uploadAudioTest(base64File: string): Promise<void> {
    return uploadAudioTest(base64File);
  }

  public async uploadDrugTest(base64File: string): Promise<void> {
    return uploadDrugTest(base64File);
  }

  public async uploadSpiroTest(base64File: string): Promise<void> {
    return uploadSpiroTest(base64File);
  }
}

export const assistModel: AssistModel = new AssistModel();
