import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const logout = async (): Promise<void> => {
  try {
    await firebase.auth.signOut();
    offlineModel.setOfflineState("online");
  } catch (error: unknown) {
    appFaultModel.catchAppError("AuthModel.logout", {}, error);
  }
};
