import { RouteRecordRaw } from "vue-router";

export const invoiceRoutes: RouteRecordRaw[] = [
  {
    path: "/invoices",
    name: "InvoiceList",
    component: () => import("../views/InvoiceList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/invoices/create",
    name: "InvoiceSelectCandidate",
    component: () => import("../views/InvoiceSelectCandidate.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "create",
    },
  },
  {
    path: "/invoices/create/:recipientType/:recipientId/:startDate/:endDate",
    name: "InvoiceCreate",
    component: () => import("../views/InvoiceCreate.vue"),
    props: (route) => ({
      recipientType: route.params.recipientType,
      recipientId: route.params.recipientId,
      startDate: route.params.startDate,
      endDate: route.params.endDate,
    }),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "create",
    },
  },
  {
    path: "/invoices/:id/edit",
    name: "InvoiceEdit",
    component: () => import("../views/InvoiceEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/invoices/:id",
    name: "InvoiceView",
    component: () => import("../views/InvoiceView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "invoice",
      requiredRight: "viewUI",
    },
  },
];
