export const it = {
  utilityDownloadHealthFolder: {
    name: "Scarica fascicolo sanitario per azienda",
    notSelected: "Non selezionato",
    selected: "Selezionato",
  },
  toast: {
    error: {},
    success: {},
    warning: {
      noCompany: "Nessuna azienda selezionata",
      noEmployees: "Nessun lavoratore selezionato",
    },
  },
};
