import { deleteField } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Company } from "@/features/modules/company/objects/Company";
import { Contract } from "@/features/modules/contract/objects/Contract";
import { contractModel } from "../ContractModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const deleteContract = async (contract: Contract): Promise<boolean> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    const user: User = store.getters[storeTypes.getters.getUser];

    // check if user can delete the document
    if (user.canDelete(contractModel.roleModule, contract) === false)
      throw new Error(`Unable to delete document #${contract.id} in collection ${contractModel.collectionName}`);

    batch.delete(contractModel.getDocumentReference(contract.id));

    if (contract.firm === undefined) throw new Error("firmUndefined");
    const firmId: string = contract.firm.id;

    if (contract.doctor === undefined) throw new Error("doctorUndefined");

    // company
    if (contract.company !== undefined) {
      const company: Company = await companyModel.getDocument(contract.company.id);

      let doctorHasOtherContracts = false;
      for (const firmId of Object.keys(company.activeContracts.getRecord())) {
        const firmActiveContracts = company.activeContracts.getActiveContractsByFirm(firmId);
        if (firmActiveContracts === undefined) continue;
        for (const loopContract of firmActiveContracts) {
          if (loopContract.id === contract.id) continue;
          if (loopContract.doctor === undefined) continue;
          if (loopContract.doctor.id === contract.doctor.id) {
            doctorHasOtherContracts = true;
          }
        }
      }

      // check if user can update the document
      if (user.canUpdate(companyModel.roleModule, company) === false)
        throw new Error(`Unable to update document #${company.id} in collection ${companyModel.collectionName}`);

      if (doctorHasOtherContracts === true) {
        batch.update(companyModel.getDocumentReference(contract.company.id), {
          [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
        });
      } else {
        if (company.authorizedDoctorsIds.includes(contract.doctor.id) === true) {
          company.authorizedDoctorsIds.splice(company.authorizedDoctorsIds.indexOf(contract.doctor.id), 1);
        }
        batch.update(companyModel.getDocumentReference(contract.company.id), {
          [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
          authorizedDoctorsIds: company.authorizedDoctorsIds,
        });

        // doctor
        if (contract.doctor !== undefined) {
          const doctor: Doctor = await doctorModel.getDocument(contract.doctor.id);

          if (doctor.authorizedCompaniesIds.includes(contract.company.id) === true) {
            doctor.authorizedCompaniesIds.splice(doctor.authorizedCompaniesIds.indexOf(contract.company.id), 1);
          }

          batch.update(doctorModel.getDocumentReference(contract.doctor.id), { authorizedCompaniesIds: doctor.authorizedCompaniesIds });
        }
      }
    }

    // branch
    for (const linkedBranch of contract.getLinkedBranches()) {
      const branch: Branch = await branchModel.getDocument(linkedBranch.id);

      // check if user can update the document
      if (user.canUpdate(branchModel.roleModule, branch) === false)
        throw new Error(`Unable to update document #${branch.id} in collection ${branchModel.collectionName}`);

      batch.update(branchModel.getDocumentReference(branch.id), {
        [`activeContracts.${firmId}.${contract.id}`]: deleteField(),
      });
    }

    await batch.commit();
    return true;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.deleteContract", { contract }, error);
    return false;
  }
};
