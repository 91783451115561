export const it = {
  feedback: {
    feedback: "Feedback",
    message: "Messaggio",
    messageError: "Inserisci il messaggio",
    subject: "Oggetto",
    subjectError: "Inserisci l'oggetto",
  },
  navigation: {
    feedback: "Feedback",
  },
};
