import { ConfigInterface } from "../interfaces/Config.interface";
import { environment } from "./environment";

import apiDev from "@/config/apiDev.json";
import apiProd from "@/config/apiProd.json";
import app from "@/config/app.json";
import authentication from "@/config/authentication.json";
import database from "@/config/database.json";
import firebaseDev from "@/config/firebaseDev.json";
import firebaseProd from "@/config/firebaseProd.json";
import landscapes from "@/config/landscapes.json";
import storage from "@/config/storage.json";
import urlDev from "@/config/urlDev.json";
import urlProd from "@/config/urlProd.json";

const configsMap: Map<string, ConfigInterface> = new Map<string, ConfigInterface>([
  [
    "development",
    {
      api: apiDev,
      app: app,
      authentication: authentication,
      database: {
        collections: [...database.coreCollections, ...database.featuresCollections],
        offlineMode: database.offlineMode,
        offlineVersion: database.offlineVersion,
      },
      firebase: firebaseDev,
      landscapes: landscapes,
      storage: storage,
      url: urlDev,
    },
  ],
  [
    "production",
    {
      api: apiProd,
      app: app,
      authentication: authentication,
      database: {
        collections: [...database.coreCollections, ...database.featuresCollections],
        offlineMode: database.offlineMode,
        offlineVersion: database.offlineVersion,
      },
      firebase: firebaseProd,
      landscapes: landscapes,
      storage: storage,
      url: urlProd,
    },
  ],
]);

if (!configsMap.has(environment)) {
  throw Error(`Could not find config for VUE_APP_ENVIRONMENT key "${environment}"`);
}

export const config: ConfigInterface = configsMap.get(environment) as ConfigInterface;
