
import { defineComponent, onMounted, Ref, ref } from "vue";
import { compareAsc } from "date-fns";

import { AppHelpers } from "@/core/modules/helpers/AppHelpers";
import { storeTypes } from "@/core/modules/store/types";
import { ExaminationCount } from "../objects/ExaminationCount";
import { examinationCounterModel } from "../models/ExaminationCounterModel";
import { useLocale } from "@/core/modules/locale/module";
import { useStore } from "@/core/modules/store/module";

export default defineComponent({
  name: "ExaminationCounterView",
  setup() {
    const { d, n, t } = useLocale();

    const results: Ref<ExaminationCount[]> = ref([]);

    const store = useStore();

    const handleLoad = async () => {
      await AppHelpers.tryOrError(
        async () => {
          await loadAction();
        },
        () => {
          store.commit(storeTypes.mutations.loadingStop);
        }
      );
    };

    const handleSubmit = async () => {
      store.commit(storeTypes.mutations.loadingStart);

      await AppHelpers.tryOrToast(
        async () => {
          await submitAction();
        },
        "",
        t,
        () => store.commit(storeTypes.mutations.loadingStop),
        false
      );
    };

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key == "Enter") {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.localName != "textarea") e.preventDefault();
      }
    };

    /* custom */

    const startDate: Date = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate: Date = new Date();
    endDate.setHours(23, 59, 59, 999);
    const period: Ref<Date[]> = ref([startDate, endDate]);

    const showResults: Ref<boolean> = ref(false);

    const dataChanged = () => {
      showResults.value = false;

      if (period.value[0] != undefined) sessionStorage.setItem("examinationCounter-startDate", period.value[0].getTime().toString());
      if (period.value[1] != undefined) sessionStorage.setItem("examinationCounter-endDate", period.value[1].getTime().toString());
    };

    const loadAction = async () => {
      const sessionStartDate: string | null = sessionStorage.getItem("examinationCounter-startDate");
      if (sessionStartDate !== null) {
        period.value[0] = new Date(Number(sessionStartDate));
        period.value[0].setHours(0, 0, 0, 0);
      }

      const sessionEndDate: string | null = sessionStorage.getItem("examinationCounter-endDate");
      if (sessionEndDate !== null) {
        period.value[1] = new Date(Number(sessionEndDate));
        period.value[1].setHours(0, 0, 0, 0);
      }

      period.value = [...period.value];
    };

    const submitAction = async () => {
      showResults.value = false;
      if (period.value.length !== 2 || period.value[0] === undefined || period.value[1] === undefined) {
        throw new Error("statExaminationCounter.periodUndefined");
      }
      if (compareAsc(period.value[0], period.value[1]) > 0) throw new Error("statExaminationCounter.periodInvalid");

      const examinationCounts: ExaminationCount[] = await examinationCounterModel.countExaminations(period.value[0], period.value[1]);

      results.value = examinationCounts;
      showResults.value = true;
    };

    onMounted(() => handleLoad());

    return {
      d,
      dataChanged,
      handleKeydown,
      handleSubmit,
      n,
      period,
      results,
      showResults,
      t,
    };
  },
});
