import { PrimeVueLocaleOptions } from "primevue/config";

export const itPrimevue: PrimeVueLocaleOptions = {
  startsWith: "Inizia con",
  contains: "Contiene",
  notContains: "Non contiene",
  endsWith: "Finisce con",
  equals: "Equivale",
  notEquals: "Non uguale",
  noFilter: "Senza Filtro",
  lt: "Minore di",
  lte: "Minore o uguale a",
  gt: "Maggiore di",
  gte: "Maggiore o uguale a",
  dateIs: "La data è",
  dateIsNot: "La data non è",
  dateBefore: "La data è precedente",
  dateAfter: "La data è successiva",
  clear: "Cancella tutto",
  apply: "Applica",
  matchAll: "Abbina tutto",
  matchAny: "Abbina alcuni",
  addRule: "Aggiungi regola",
  removeRule: "Rimuovi regola",
  accept: "Si",
  reject: "No",
  choose: "Scegli",
  upload: "Carica",
  cancel: "Annulla",
  completed: "Completato",
  pending: "In corso",
  fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
  dayNames: ["Domenica", "Lunedi", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
  monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
  chooseYear: "Seleziona Anno",
  chooseMonth: "Seleziona Mese",
  chooseDate: "Seleziona Data",
  prevDecade: "Decade precedente",
  nextDecade: "Decade successiva",
  prevYear: "Anno precedente",
  nextYear: "Anno successivo",
  prevMonth: "Mese precedente",
  nextMonth: "Mese successivo",
  prevHour: "Ora precedente",
  nextHour: "Ora successiva",
  prevMinute: "Minuto precedente",
  nextMinute: "Minuto successivo",
  prevSecond: "Secondo precedente",
  nextSecond: "Secondo successivo",
  am: "AM",
  pm: "PM",
  today: "Oggi",
  weekHeader: "Sett",
  firstDayOfWeek: 1,
  showMonthAfterYear: false,
  dateFormat: "dd/mm/yy",
  weak: "Debole",
  medium: "Medio",
  strong: "Forte",
  passwordPrompt: "Inserisci la password",
  emptyFilterMessage: "Nessuna opzione disponibile",
  searchMessage: "{0} risultati disponibili",
  selectionMessage: "{0} elementi selezionati",
  emptySelectionMessage: "Nessun elemento selezionato",
  emptySearchMessage: "Nessun risultato trovato",
  emptyMessage: "Nessun risultato trovato",
};
