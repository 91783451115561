import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { examinationModel } from "../ExaminationModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { JudgmentState } from "@/features/modules/examination/objects/JudgmentState";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const getExaminationsToInvoiceByCompanyAndPeriodAndFirm = async (
  companyId: string,
  startDate: Date,
  endDate: Date
): Promise<Examination[]> => {
  try {
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const firmId: string = firmModel.getSelectedFirmId();

    if (offlineModel.getOfflineState() === "offline") return [];

    const snapshotQuery: Query<Examination> = query(
      examinationModel.getPathReference().withConverter(examinationModel.firestoreConverter),
      where("firm.id", "==", firmId),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("invoice", "==", null),
      where("company.id", "==", companyId)
    );

    const snapshot: QuerySnapshot<Examination> = await getDocs(examinationModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(
      snapshot.docs
        .map((doc) => doc.data())
        .filter((examination) => examination.isBillable === true && examination.ending.judgment !== JudgmentState.NotEvaluated)
    );
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationModel.getExaminationsToInvoiceByCompanyAndPeriodAndFirm", { companyId, startDate, endDate }, error);
    return [];
  }
};

function sortDocuments(documents: Examination[]): Examination[] {
  const sorter: FirestoreSorter<Examination> = new FirestoreSorter(documents);
  sorter.addSortCriteria("date", "asc", "string");
  sorter.addSortCriteria("codeSort", "asc", "string");
  return sorter.sort();
}
