import { Clause } from "@/core/modules/firestore/objects/Clause";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export class SearchModule {
  public title: string;
  public format: (
    firestoreDocument: FirestoreDocument,
    d: (date: Date, format: string) => string,
    n: (number: number, format: string) => string,
    t: (text: string) => string
  ) => Promise<string>;
  public getLink: (firestoreDocument: FirestoreDocument) => string;
  public icon: string;
  public model: FirestoreModel<FirestoreDocument>;
  public sortCriterias: SortCriteria[];
  public getClauses: (() => Clause[]) | undefined;

  public constructor(
    title: string,
    format: (
      firestoreDocument: FirestoreDocument,
      d: (date: Date, format: string) => string,
      n: (number: number, format: string) => string,
      t: (text: string) => string
    ) => Promise<string>,
    getLink: (firestoreDocument: FirestoreDocument) => string,
    icon: string,
    model: FirestoreModel<FirestoreDocument>,
    sortCriterias: SortCriteria[],
    getClauses?: () => Clause[]
  ) {
    this.title = title;
    this.format = format;
    this.getLink = getLink;
    this.icon = icon;
    this.model = model;
    this.sortCriterias = sortCriterias;
    this.getClauses = getClauses;
  }
}
