import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { EmployeeSex } from "@/features/modules/employee/objects/EmployeeSex";
import { EmSurveyForm } from "../objects/EmSurveyForm";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class EmSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private emSurveyForm: EmSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    emSurveyForm: EmSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.emSurveyForm = emSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      emAnswer: { fontSize: 10, bold: false },
      emAnswerTable: { fontSize: 8 },
      emAnswerValue: { alignment: "center", bold: true },
      emQuestion: { color: this.textColor, bold: true, fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("INDAGINE ANAMNESTICA PER RISCHI DA ESPOSIZIONE LAVORATIVA A CAMPI ELETTROMAGNETICI - SOGGETTI SENSIBILI", isContinuos);

    await this.writeEmployeeData();

    this.writeColumnWithTwoChoices(1, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(2, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(3, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(4, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(5, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(6, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(7, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(8, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(9, ["No", "Sì"]);
    this.writeColumnWithTwoChoices(10, ["No", "Sì"]);
    this.writeColumnWithText();

    if (this.examination.employee?.sex === EmployeeSex.Female) {
      this.writeSubtitle("Per le donne");
      this.writeColumnWithTwoChoices(11, ["No", "Sì"]);
      this.writeColumnWithTwoChoices(12, ["No", "Sì"]);
      this.writeColumnWithTwoChoices(13, ["No", "Sì"]);
      this.writeColumnWithTwoChoices(14, ["No", "Sì"]);
    }

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeColumnWithTwoChoices(questionIndex: number, choices: string[]): void {
    const answer: boolean = this.emSurveyForm[`question${questionIndex}` as keyof EmSurveyForm] as boolean;
    const answerTableBody: unknown[] = [
      [
        { text: answer === false ? "X" : " ", style: "emAnswerValue", border: [true, true, true, true] },
        { text: choices[0], border: [false, false, false, false] },
        { text: answer === true ? "X" : " ", style: "emAnswerValue", border: [true, true, true, true] },
        { text: choices[1], border: [false, false, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          width: DataHelpers.mmToPoints(120),
          text: `${questionIndex}. ${this.t(`surveyForm.emSurveyForm.question${questionIndex}`)}`,
          style: "emQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          style: "emAnswerTable",
          table: {
            body: answerTableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(3), "*", DataHelpers.mmToPoints(3), "*"],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
      ],
    });
  }

  private writeColumnWithText(): void {
    if (this.emSurveyForm.question10Details === undefined) return;

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(4), 0, 0],
          width: DataHelpers.mmToPoints(60),
          text: this.t("surveyForm.emSurveyForm.question10Details"),
          style: "emQuestion",
        },
        {
          width: "*",
          margin: [0, DataHelpers.mmToPoints(4), 0, 0],
          text: this.emSurveyForm.question10Details ?? "-",
          style: "emAnswer",
        },
      ],
    });
  }
}
