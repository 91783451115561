import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.fileRef === undefined)
    ? (_openBlock(), _createBlock(_component_FileUpload, {
        key: 0,
        accept: _ctx.accept !== undefined ? _ctx.accept : '.doc,.docx,.jpg,.jpeg,.odt,.pdf,.png',
        fileLimit: 1,
        maxFileSize: _ctx.maxFileSize !== undefined ? _ctx.maxFileSize : _ctx.config.storage.maxFileSize,
        mode: "basic",
        showUploadButton: false,
        onSelect: _ctx.onFileSelected
      }, null, 8, ["accept", "maxFileSize", "onSelect"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Button, {
          icon: "pi pi-eye",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFile()))
        }),
        _createVNode(_component_Button, {
          icon: "pi pi-times",
          class: "p-button-danger ml-4",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteFile()))
        })
      ]))
}