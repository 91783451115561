import { Branch } from "../objects/Branch";
import { branchModel } from "../models/BranchModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

export const branchSearch: SearchModule = new SearchModule(
  "branch.branch",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Branch).name ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/companies/${(firestoreDocument as Branch).company?.id}/branches/${firestoreDocument.id}/edit`,
  "flag",
  branchModel,
  [new SortCriteria("name", "asc", "string")]
);
