import { WhereFilterOp } from "firebase/firestore";

export class Clause {
  public field: string;
  public condition: WhereFilterOp;
  public value: unknown;

  public constructor(field: string, condition: WhereFilterOp, value: unknown) {
    this.field = field;
    this.condition = condition;
    this.value = value;
  }
}
