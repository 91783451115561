import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { Widget } from "../objects/Widget";

import { getWidgetByTypeAndSourceAndSourceId, getWidgetsByTypeAndSource } from "./methods";

export class WidgetModel extends FirestoreModel<Widget> {
  public constructor() {
    super(() => new Widget(), "widgets", LockPolicy.DiscardUnsyncedChanges, "widget");
  }

  public async getDocuments(): Promise<Widget[]> {
    return super.getDocuments();
  }

  public async getDocument(widgetId: string): Promise<Widget> {
    return super.getDocument(widgetId);
  }

  public async getWidgetByTypeAndSourceAndSourceId(type: string, source: string, sourceId: string): Promise<Widget | undefined> {
    return getWidgetByTypeAndSourceAndSourceId(type, source, sourceId);
  }

  public async getWidgetsByTypeAndSource(type: string, source: string): Promise<Widget[]> {
    return getWidgetsByTypeAndSource(type, source);
  }
}

export const widgetModel: WidgetModel = new WidgetModel();
