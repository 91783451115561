import { RouteRecordRaw } from "vue-router";

export const calendarRoutes: RouteRecordRaw[] = [
  {
    path: "/calendars/:id/edit",
    name: "CalendarEdit",
    component: () => import("../views/CalendarEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "calendar",
      requiredRight: "viewUI",
    },
  },
];
