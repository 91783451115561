export const fr = {
  appFault: {
    appFault: "Erreur de l'application",
    appFaults: "Erreurs de l'application",
    createdAt: "Date et heure",
    message: "Message",
    noDetails: "Aucun détail disponible",
    notifications: {
      appFaultCreated: "Erreur de l'application créé",
      groupName: "Erreurs de l'application",
    },
    stack: "Détails",
    user: "Utilisateur",
  },
  buttons: {
    deleteAll: "Supprimer tout",
    showDetails: "Afficher les détails",
  },
  navigation: {
    appFault: "Erreurs de l'application",
  },
};
