import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationTest } from "@/features/modules/examination/objects/ExaminationTest";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { VisioTestForm } from "../objects/VisioTestForm";

export class VisioTestFormPaper extends DocumentPaper {
  private examinationTest: ExaminationTest;
  private visioTestForm: VisioTestForm;

  constructor(
    examination: Examination,
    examinationTest: ExaminationTest,
    visioTestForm: VisioTestForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.examinationTest = examinationTest;
    this.visioTestForm = visioTestForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      visioChoiceLabel: { color: this.textColor, fontSize: 8 },
      visioChoiceValue: { color: this.textColor, bold: true, fontSize: 8 },
      visioTestTableText: { alignment: "center", color: this.textColor, fontSize: 8 },
      visioTestTableTextMarked: { alignment: "center", color: this.textColor, fontSize: 8, decoration: "lineThrough" },
      visioTestTableTitle: { alignment: "center", bold: true, color: this.highlightColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("VISIOTEST", isContinuos);

    await this.writeEmployeeData();

    this.writeTestTable(this.t("testForm.visioTestForm.title1"), 1);

    this.writeTestTable(this.t("testForm.visioTestForm.title2"), 10);

    if (this.visioTestForm.question19 === true) this.writeTestTable(this.t("testForm.visioTestForm.title3"), 20, false);

    this.writeDoubleChoice(this.visioTestForm.question25, ["Buona visione naturale", "Buona visione con le correzioni in uso"]);

    this.writeTripleChoice(this.t("testForm.visioTestForm.question26"), this.visioTestForm.question26, ["Per lontano", "Per vicino"]);
    this.writeTripleChoice(this.t("testForm.visioTestForm.question27"), this.visioTestForm.question27, ["Per lontano", "Per vicino"]);
    this.writeTripleChoice(this.t("testForm.visioTestForm.question28"), this.visioTestForm.question28, ["Per lontano", "Per vicino"]);
    this.writeTripleChoice(this.t("testForm.visioTestForm.question29"), this.visioTestForm.question29, ["Per lontano", "Per vicino"]);
    this.writeTripleChoice(this.t("testForm.visioTestForm.question30"), this.visioTestForm.question30, ["Stereopsi incompleta", "Altro"]);

    await this.writeSignaturesWithDate(this.examinationTest.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeTestTable(title: string, startQuestionIndex: number, completed = true): void {
    let testTableBody: unknown[] = [
      [
        "",
        { text: title.toLocaleUpperCase(), style: "visioTestTableTitle", colSpan: 10 },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        { text: this.getAnswer(startQuestionIndex) === true ? "con lenti" : "senza lenti", style: "visioTestTableText", colSpan: 5 },
        "",
        "",
        "",
        "",
      ],
      [
        { text: this.t("testForm.visioTestForm.question2"), style: "visioTestTableText", alignment: "left" },
        { text: "4 CUBI", style: this.getAnswer(startQuestionIndex + 1) === 4 ? "visioTestTableTextMarked" : "visioTestTableText", colSpan: 5 },
        "",
        "",
        "",
        "",
        { text: "2 CUBI", style: this.getAnswer(startQuestionIndex + 1) === 2 ? "visioTestTableTextMarked" : "visioTestTableText", colSpan: 5 },
        "",
        "",
        "",
        "",
        {
          text: "3 CUBI",
          style: this.getAnswer(startQuestionIndex + 1) === 3 ? "visioTestTableTextMarked" : "visioTestTableText",
          colSpan: 5,
          fillColor: "#dbdbdb",
        },
        "",
        "",
        "",
        "",
      ],
      [
        { text: "", style: "visioTestTableText" },
        { text: "1", style: "visioTestTableText" },
        { text: "2", style: "visioTestTableText" },
        { text: "3", style: "visioTestTableText" },
        { text: "4", style: "visioTestTableText" },
        { text: "5", style: "visioTestTableText" },
        { text: "6", style: "visioTestTableText" },
        { text: "7", style: "visioTestTableText" },
        { text: "8", style: "visioTestTableText" },
        { text: "9", style: "visioTestTableText" },
        { text: "10", style: "visioTestTableText" },
        { text: "11", style: "visioTestTableText" },
        { text: "12", style: "visioTestTableText" },
        { text: "13", style: "visioTestTableText" },
        { text: "14", style: "visioTestTableText" },
        { text: "15", style: "visioTestTableText" },
      ],
      [
        { text: this.t("testForm.visioTestForm.question3"), style: "visioTestTableText", alignment: "left" },
        { text: this.getAnswer(startQuestionIndex + 2) === 1 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 2 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 3 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 4 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 5 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 6 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 7 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 8 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 9 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 10 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 2) === 11 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 2) === 12 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 2) === 13 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 14 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 2) === 15 ? "X" : "", style: "visioTestTableText" },
      ],
      [
        { text: this.t("testForm.visioTestForm.question4"), style: "visioTestTableText", alignment: "left" },
        { text: this.getAnswer(startQuestionIndex + 3) === 1 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 2 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 3 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 4 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 5 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 6 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 7 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 8 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 9 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 10 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 3) === 11 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 3) === 12 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 3) === 13 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 14 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 3) === 15 ? "X" : "", style: "visioTestTableText" },
      ],
      [
        { text: this.t("testForm.visioTestForm.question5"), style: "visioTestTableText", alignment: "left" },
        { text: this.getAnswer(startQuestionIndex + 4) === 1 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 2 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 3 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 4 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 5 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 6 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 7 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 8 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 9 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 10 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 4) === 11 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 4) === 12 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
        { text: this.getAnswer(startQuestionIndex + 4) === 13 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 14 ? "X" : "", style: "visioTestTableText" },
        { text: this.getAnswer(startQuestionIndex + 4) === 15 ? "X" : "", style: "visioTestTableText" },
      ],
    ];

    if (completed === true) {
      testTableBody = [
        ...testTableBody,
        [
          { text: this.t("testForm.visioTestForm.question6"), style: "visioTestTableText", alignment: "left" },
          {
            text: "1B",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("1B") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "2S",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("2S") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "3B",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("3B") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "4A",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("4A") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "5A",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("5A") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "6S",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("6S") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "7D",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("7D") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "8S",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("8S") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          {
            text: "9D",
            style: this.getArrayAnswer(startQuestionIndex + 5).includes("9D") ? "visioTestTableTextMarked" : "visioTestTableText",
            fillColor: "#dbdbdb",
          },
          { text: "", style: "visioTestTableText" },
          { text: "", style: "visioTestTableText" },
          { text: "", style: "visioTestTableText" },
          { text: "", style: "visioTestTableText" },
          { text: "", style: "visioTestTableText" },
          { text: "", style: "visioTestTableText" },
        ],
        [
          { text: this.t("testForm.visioTestForm.question7"), style: "visioTestTableText", alignment: "left" },
          {
            text: "A12",
            style: this.getArrayAnswer(startQuestionIndex + 6).includes("A12") ? "visioTestTableTextMarked" : "visioTestTableText",
            colSpan: 3,
          },
          "",
          "",
          {
            text: "B5",
            style: this.getArrayAnswer(startQuestionIndex + 6).includes("B5") ? "visioTestTableTextMarked" : "visioTestTableText",
            colSpan: 2,
          },
          "",
          {
            text: "C26",
            style: this.getArrayAnswer(startQuestionIndex + 6).includes("C26") ? "visioTestTableTextMarked" : "visioTestTableText",
            colSpan: 3,
          },
          "",
          "",
          {
            text: "D6",
            style: this.getArrayAnswer(startQuestionIndex + 6).includes("D6") ? "visioTestTableTextMarked" : "visioTestTableText",
            colSpan: 2,
          },
          "",
          {
            text: "E16",
            style: this.getArrayAnswer(startQuestionIndex + 6).includes("E16") ? "visioTestTableTextMarked" : "visioTestTableText",
            colSpan: 3,
          },
          "",
          "",
          {
            text: "F0",
            style: this.getArrayAnswer(startQuestionIndex + 6).includes("F0") ? "visioTestTableTextMarked" : "visioTestTableText",
            colSpan: 2,
          },
          "",
        ],
        [
          { text: this.t("testForm.visioTestForm.question8"), style: "visioTestTableText", alignment: "left" },
          { text: this.getAnswer(startQuestionIndex + 7) === 1 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 2 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 7) === 3 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 7) === 4 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 7) === 5 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 6 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 7 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 8 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 9 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 10 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 11 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 12 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 13 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 14 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 7) === 15 ? "X" : "", style: "visioTestTableText" },
        ],
        [
          { text: this.t("testForm.visioTestForm.question9"), style: "visioTestTableText", alignment: "left" },
          { text: this.getAnswer(startQuestionIndex + 8) === 1 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 2 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 3 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 4 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 5 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 6 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 8) === 7 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 8) === 8 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 8) === 9 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 8) === 10 ? "X" : "", style: "visioTestTableText", fillColor: "#dbdbdb" },
          { text: this.getAnswer(startQuestionIndex + 8) === 11 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 12 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 13 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 14 ? "X" : "", style: "visioTestTableText" },
          { text: this.getAnswer(startQuestionIndex + 8) === 15 ? "X" : "", style: "visioTestTableText" },
        ],
      ];
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, DataHelpers.mmToPoints(5)],
      style: "testTable",
      table: {
        body: testTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(50), "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeDoubleChoice(answer: string | undefined, choices: string[]): void {
    const answer1TableBody: unknown[] = [[{ text: answer === choices[0] ? "X" : " ", style: "visioChoiceValue", border: [true, true, true, true] }]];
    const answer2TableBody: unknown[] = [[{ text: answer === choices[1] ? "X" : " ", style: "visioChoiceValue", border: [true, true, true, true] }]];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, DataHelpers.mmToPoints(1)],
          style: "visioChoiceValue",
          table: {
            body: answer1TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [DataHelpers.mmToPoints(1), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(85),
          text: choices[0],
          style: "visioChoiceLabel",
        },
        {
          width: "*",
          margin: [0, 0, 0, DataHelpers.mmToPoints(1)],
          style: "visioChoiceValue",
          table: {
            body: answer2TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [DataHelpers.mmToPoints(1), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(85),
          text: choices[1],
          style: "visioChoiceLabel",
        },
      ],
    });
  }

  private writeTripleChoice(question: string, answers: string[], choices: string[]): void {
    const answer0TableBody: unknown[] = [
      [
        {
          text: answers.includes(choices[0]) || answers.includes(choices[1]) ? "X" : " ",
          style: "visioChoiceValue",
          border: [true, true, true, true],
        },
      ],
    ];
    const answer1TableBody: unknown[] = [
      [{ text: answers.includes(choices[0]) ? "X" : " ", style: "visioChoiceValue", border: [true, true, true, true] }],
    ];
    const answer2TableBody: unknown[] = [
      [{ text: answers.includes(choices[1]) ? "X" : " ", style: "visioChoiceValue", border: [true, true, true, true] }],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          width: "*",
          margin: [0, 0, 0, DataHelpers.mmToPoints(1)],
          style: "visioChoiceValue",
          table: {
            body: answer0TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [DataHelpers.mmToPoints(1), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(85),
          text: question,
          style: "visioChoiceLabel",
        },
        {
          width: "*",
          margin: [0, 0, 0, DataHelpers.mmToPoints(1)],
          style: "visioChoiceValue",
          table: {
            body: answer1TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [DataHelpers.mmToPoints(1), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(40),
          text: choices[0],
          style: "visioChoiceLabel",
        },
        {
          width: "*",
          margin: [0, 0, 0, DataHelpers.mmToPoints(1)],
          style: "visioChoiceValue",
          table: {
            body: answer2TableBody,
            headerRows: 0,
            widths: [DataHelpers.mmToPoints(2)],
          },
          layout: {
            hLineWidth: () => 0.5,
            hLineColor: () => "black",
          },
        },
        {
          margin: [DataHelpers.mmToPoints(1), DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(1)],
          width: DataHelpers.mmToPoints(40),
          text: choices[1],
          style: "visioChoiceLabel",
        },
      ],
    });
  }

  private getAnswer(questionIndex: number): unknown {
    return this.visioTestForm[`question${questionIndex}` as keyof VisioTestForm] ?? undefined;
  }

  private getArrayAnswer(questionIndex: number): unknown[] {
    return (this.visioTestForm[`question${questionIndex}` as keyof VisioTestForm] as unknown[]) ?? [];
  }
}
