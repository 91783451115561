import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Company } from "@/features/modules/company/objects/Company";
import { Contract } from "@/features/modules/contract/objects/Contract";
import { contractModel } from "../ContractModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { LinkedContract } from "@/features/modules/contract/objects/LinkedContract";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const createContract = async (contract: Contract): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (contract.company === undefined) throw new Error("companyUndefined");
    if (contract.doctor === undefined) throw new Error("doctorUndefined");
    if (contract.firm === undefined) throw new Error("firmUndefined");

    const user: User = store.getters[storeTypes.getters.getUser];

    // check if user can create the document
    if (user.canCreate(contractModel.roleModule) === false)
      throw new Error(`Unable to create document in collection ${contractModel.collectionName}`);

    contract.id = contractModel.getDocumentReference().id;

    contract.setSearchKeys();
    contract.setTimestampFields("create");
    batch.set(contractModel.getDocumentReference(contract.id), contract.toFirestore());

    if (contract.isCurrentlyActive()) {
      const firmId: string = contract.firm.id;
      const linkedContract: LinkedContract = LinkedContract.createFromContract(contract);

      // company
      const company: Company = await companyModel.getDocument(contract.company.id);

      // check if user can update the document
      if (user.canUpdate(companyModel.roleModule, company) === false)
        throw new Error(`Unable to update document #${company.id} in collection ${companyModel.collectionName}`);

      if (company.authorizedDoctorsIds.includes(contract.doctor.id) === false) {
        company.authorizedDoctorsIds.push(contract.doctor.id);
      }

      batch.update(companyModel.getDocumentReference(contract.company.id), {
        [`activeContracts.${firmId}.${linkedContract.id}`]: linkedContract.toFirestore(),
        authorizedDoctorsIds: company.authorizedDoctorsIds,
      });

      // branch
      for (const linkedBranch of contract.getLinkedBranches()) {
        const branch: Branch = await branchModel.getDocument(linkedBranch.id);

        // check if user can update the document
        if (user.canUpdate(branchModel.roleModule, branch) === false)
          throw new Error(`Unable to update document #${branch.id} in collection ${branchModel.collectionName}`);

        batch.update(branchModel.getDocumentReference(branch.id), {
          [`activeContracts.${firmId}.${linkedContract.id}`]: linkedContract.toFirestore(),
        });
      }

      // doctor
      const doctor: Doctor = await doctorModel.getDocument(contract.doctor.id);

      // check if user can update the document
      if (user.canUpdate(doctorModel.roleModule, doctor) === false)
        throw new Error(`Unable to update document #${doctor.id} in collection ${doctorModel.collectionName}`);

      if (doctor.authorizedCompaniesIds.includes(contract.company.id) === false) {
        doctor.authorizedCompaniesIds.push(contract.company.id);
      }

      batch.update(doctorModel.getDocumentReference(contract.doctor.id), { authorizedCompaniesIds: doctor.authorizedCompaniesIds });
    }

    await batch.commit();

    return contract.id;
  } catch (error: unknown) {
    appFaultModel.catchAppError("ContractModel.createContract", { contract }, error);
    return "ERROR";
  }
};
