import { Branch } from "../objects/Branch";
import { branchOfflineModel } from "./BranchOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { getBranchByInailId, getBranchesByCompany, getLegalBranchByCompany, handleLegalBranch, isVatCodeUnique } from "./methods";

export class BranchModel extends FirestoreModel<Branch> {
  public constructor() {
    super(() => new Branch(), "branches", LockPolicy.DiscardUnsyncedChanges, "branch");
    this.beforeDeleteFunction = "featuresBranchBeforeDelete";
    this.offlineModuleModel = branchOfflineModel;
  }

  public async getDocuments(): Promise<Branch[]> {
    return super.getDocuments([new SortCriteria("legalBranch", "asc", "boolean"), new SortCriteria("name", "asc", "string")]);
  }

  public async getDocument(branchId: string): Promise<Branch> {
    return super.getDocument(branchId);
  }

  public async createDocument(branch: Branch): Promise<string> {
    await this.handleLegalBranch(branch);
    return super.createDocument(branch);
  }

  public async updateDocument(branch: Branch): Promise<void> {
    await this.handleLegalBranch(branch);
    return super.updateDocument(branch);
  }

  public async deleteDocument(branch: Branch): Promise<boolean> {
    return super.deleteDocument(branch);
  }

  public async getBranchByInailId(inailId: string): Promise<Branch | undefined> {
    return getBranchByInailId(inailId);
  }

  public async getBranchesByCompany(companyId: string): Promise<Branch[]> {
    return getBranchesByCompany(companyId);
  }

  public async getLegalBranchByCompany(companyId: string): Promise<Branch | undefined> {
    return getLegalBranchByCompany(companyId);
  }

  public async handleLegalBranch(branch: Branch): Promise<void> {
    return handleLegalBranch(branch);
  }

  public async isVatCodeUnique(vatCode: string, branchId?: string): Promise<boolean> {
    return isVatCodeUnique(vatCode, branchId);
  }
}

export const branchModel: BranchModel = new BranchModel();
