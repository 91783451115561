import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { companyDutyModel } from "@/features/modules/companyDuty/models/CompanyDutyModel";
import { Duty } from "@/features/modules/duty/objects/Duty";

export const importDuty = async (companyDuties: Duty[], companyId: string, importableDuties: Duty[], importDutyId: string): Promise<string> => {
  try {
    if (importDutyId == undefined) throw new Error("importDutyIdEmpty");

    if (companyDuties == undefined || companyId == undefined || importableDuties == undefined) throw new Error("invalid parameters");

    const importDuty: Duty | undefined = importableDuties.find((duty) => duty.id == importDutyId);

    if (importDuty === undefined) throw new Error("importDutyNotFound");

    // create new
    const newCompanyDuty: CompanyDuty = CompanyDuty.createFromDuty(importDuty);
    // save
    return companyDutyModel.createDocument(newCompanyDuty, companyId);
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyDutyModel.importDuty", { companyDuties, companyId, importableDuties, importDutyId }, error);
    return "ERROR";
  }
};
