import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { Locale } from "@/core/modules/locale/objects/Locale";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { UserDetail } from "@/core/modules/user/objects/UserDetail";
import { userModel } from "@/core/modules/user/models/UserModel";

export const setSelectedLocale = async (locale: Locale): Promise<void> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    const userDetail: UserDetail = user.detail;
    userDetail.locale = locale.name;

    batch.update(userModel.getDocumentReference(user.id), { detail: userDetail.toFirestore() });

    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("LocaleModel.setSelectedLocale", { locale }, error);
  }
};
