export const fr = {
  who: {
    details1: "Nous développons des logiciels pour le Web et les appareils mobiles.",
    details2: "Nous aimons le code bien écrit et le travail d'équipe.",
    details3: "Pour toute information vous pouvez nous écrire à",
    who: "Qui sommes-nous?",
  },
  navigation: {
    who: "Qui sommes-nous?",
  },
};
