import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employer } from "@/features/modules/employer/objects/Employer";
import { employerModel } from "../EmployerModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getEmployersByBranch = async (branchId: string): Promise<Employer[]> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") {
      if (employerModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Employer[] = (await offlineModel.getCacheByCollection(employerModel.offlineModuleModel)) as Employer[];
      return documents.filter((employer) => employer.branch?.id === branchId);
    }

    /*
    if (user.role?.name === "Medico") {
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      if (doctor === undefined || doctor.authorizedCompaniesIds.includes(companyId) === false)
        throw new Error(`User doesn't have rights to read company #${companyId}`);
    }
    */

    const snapshotQuery: Query<Employer> = query(
      employerModel.getGroupPathReference().withConverter(employerModel.firestoreConverter),
      where("branch.id", "==", branchId)
    );

    const snapshot: QuerySnapshot<Employer> = await getDocs(employerModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => {
      const employer: Employer = doc.data();
      employer.parentId = doc.ref.parent.parent?.id;
      return employer;
    });
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployerModel.getEmployersByBranch", { branchId }, error);
    return [];
  }
};
