import { TestForm } from "./TestForm";

import { BooleanField, NullableNumberField, StringArrayField, StringField } from "@/core/fields";

export class VisioTestForm extends TestForm {
  // test da lontano
  public question1 = false;
  public question2: number | undefined = undefined;
  public question3: number | undefined = undefined;
  public question4: number | undefined = undefined;
  public question5: number | undefined = undefined;
  public question6: string[] = [];
  public question7: string[] = [];
  public question8: number | undefined = undefined;
  public question9: number | undefined = undefined;
  // test da vicino
  public question10 = false;
  public question11: number | undefined = undefined;
  public question12: number | undefined = undefined;
  public question13: number | undefined = undefined;
  public question14: number | undefined = undefined;
  public question15: string[] = [];
  public question16: string[] = [];
  public question17: number | undefined = undefined;
  public question18: number | undefined = undefined;
  // test intermedio (opzionale)
  public question19 = false;
  public question20 = false;
  public question21: number | undefined = undefined;
  public question22: number | undefined = undefined;
  public question23: number | undefined = undefined;
  public question24: number | undefined = undefined;
  // ultimi campi
  public question25: string | undefined = undefined;
  public question26: string[] = [];
  public question27: string[] = [];
  public question28: string[] = [];
  public question29: string[] = [];
  public question30: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): VisioTestForm {
    super.fromFirestore(data);

    this.question1 = BooleanField.fromFirestore(data.result);
    this.question2 = NullableNumberField.fromFirestore(data.question2);
    this.question3 = NullableNumberField.fromFirestore(data.question3);
    this.question4 = NullableNumberField.fromFirestore(data.question4);
    this.question5 = NullableNumberField.fromFirestore(data.question5);
    this.question6 = StringArrayField.fromFirestore(data.question6);
    this.question7 = StringArrayField.fromFirestore(data.question7);
    this.question8 = NullableNumberField.fromFirestore(data.question8);
    this.question9 = NullableNumberField.fromFirestore(data.question9);
    this.question10 = BooleanField.fromFirestore(data.question10);
    this.question11 = NullableNumberField.fromFirestore(data.question11);
    this.question12 = NullableNumberField.fromFirestore(data.question12);
    this.question13 = NullableNumberField.fromFirestore(data.question13);
    this.question14 = NullableNumberField.fromFirestore(data.question14);
    this.question15 = StringArrayField.fromFirestore(data.question15);
    this.question16 = StringArrayField.fromFirestore(data.question16);
    this.question17 = NullableNumberField.fromFirestore(data.question17);
    this.question18 = NullableNumberField.fromFirestore(data.question18);
    this.question19 = BooleanField.fromFirestore(data.question19);
    this.question20 = BooleanField.fromFirestore(data.question20);
    this.question21 = NullableNumberField.fromFirestore(data.question21);
    this.question22 = NullableNumberField.fromFirestore(data.question22);
    this.question23 = NullableNumberField.fromFirestore(data.question23);
    this.question24 = NullableNumberField.fromFirestore(data.question24);
    this.question25 = StringField.fromFirestore(data.question25);
    this.question26 = StringArrayField.fromFirestore(data.question26);
    this.question27 = StringArrayField.fromFirestore(data.question27);
    this.question28 = StringArrayField.fromFirestore(data.question28);
    this.question29 = StringArrayField.fromFirestore(data.question29);
    this.question30 = StringArrayField.fromFirestore(data.question30);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = BooleanField.toFirestore(this.question1);
    firestoreData.question2 = NullableNumberField.toFirestore(this.question2);
    firestoreData.question3 = NullableNumberField.toFirestore(this.question3);
    firestoreData.question4 = NullableNumberField.toFirestore(this.question4);
    firestoreData.question5 = NullableNumberField.toFirestore(this.question5);
    firestoreData.question6 = StringArrayField.toFirestore(this.question6);
    firestoreData.question7 = StringArrayField.toFirestore(this.question7);
    firestoreData.question8 = NullableNumberField.toFirestore(this.question8);
    firestoreData.question9 = NullableNumberField.toFirestore(this.question9);
    firestoreData.question10 = BooleanField.toFirestore(this.question10);
    firestoreData.question11 = NullableNumberField.toFirestore(this.question11);
    firestoreData.question12 = NullableNumberField.toFirestore(this.question12);
    firestoreData.question13 = NullableNumberField.toFirestore(this.question13);
    firestoreData.question14 = NullableNumberField.toFirestore(this.question14);
    firestoreData.question15 = StringArrayField.toFirestore(this.question15);
    firestoreData.question16 = StringArrayField.toFirestore(this.question16);
    firestoreData.question17 = NullableNumberField.toFirestore(this.question17);
    firestoreData.question18 = NullableNumberField.toFirestore(this.question18);
    firestoreData.question19 = BooleanField.toFirestore(this.question19);
    firestoreData.question20 = BooleanField.toFirestore(this.question20);
    firestoreData.question21 = NullableNumberField.toFirestore(this.question21);
    firestoreData.question22 = NullableNumberField.toFirestore(this.question22);
    firestoreData.question23 = NullableNumberField.toFirestore(this.question23);
    firestoreData.question24 = NullableNumberField.toFirestore(this.question24);
    firestoreData.question25 = StringField.toFirestore(this.question25);
    firestoreData.question26 = StringArrayField.toFirestore(this.question26);
    firestoreData.question27 = StringArrayField.toFirestore(this.question27);
    firestoreData.question28 = StringArrayField.toFirestore(this.question28);
    firestoreData.question29 = StringArrayField.toFirestore(this.question29);
    firestoreData.question30 = StringArrayField.toFirestore(this.question30);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): VisioTestForm {
    super.fromOfflineCache(data);

    this.question1 = BooleanField.fromOfflineCache(data.result);
    this.question2 = NullableNumberField.fromOfflineCache(data.question2);
    this.question3 = NullableNumberField.fromOfflineCache(data.question3);
    this.question4 = NullableNumberField.fromOfflineCache(data.question4);
    this.question5 = NullableNumberField.fromOfflineCache(data.question5);
    this.question6 = StringArrayField.fromOfflineCache(data.question6);
    this.question7 = StringArrayField.fromOfflineCache(data.question7);
    this.question8 = NullableNumberField.fromOfflineCache(data.question8);
    this.question9 = NullableNumberField.fromOfflineCache(data.question9);
    this.question10 = BooleanField.fromOfflineCache(data.question10);
    this.question11 = NullableNumberField.fromOfflineCache(data.question11);
    this.question12 = NullableNumberField.fromOfflineCache(data.question12);
    this.question13 = NullableNumberField.fromOfflineCache(data.question13);
    this.question14 = NullableNumberField.fromOfflineCache(data.question14);
    this.question15 = StringArrayField.fromOfflineCache(data.question15);
    this.question16 = StringArrayField.fromOfflineCache(data.question16);
    this.question17 = NullableNumberField.fromOfflineCache(data.question17);
    this.question18 = NullableNumberField.fromOfflineCache(data.question18);
    this.question19 = BooleanField.fromOfflineCache(data.question19);
    this.question20 = BooleanField.fromOfflineCache(data.question20);
    this.question21 = NullableNumberField.fromOfflineCache(data.question21);
    this.question22 = NullableNumberField.fromOfflineCache(data.question22);
    this.question23 = NullableNumberField.fromOfflineCache(data.question23);
    this.question24 = NullableNumberField.fromOfflineCache(data.question24);
    this.question25 = StringField.fromOfflineCache(data.question25);
    this.question26 = StringArrayField.fromOfflineCache(data.question26);
    this.question27 = StringArrayField.fromOfflineCache(data.question27);
    this.question28 = StringArrayField.fromOfflineCache(data.question28);
    this.question29 = StringArrayField.fromOfflineCache(data.question29);
    this.question30 = StringArrayField.fromOfflineCache(data.question30);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = BooleanField.toOfflineCache(this.question1);
    firestoreData.question2 = NullableNumberField.toOfflineCache(this.question2);
    firestoreData.question3 = NullableNumberField.toOfflineCache(this.question3);
    firestoreData.question4 = NullableNumberField.toOfflineCache(this.question4);
    firestoreData.question5 = NullableNumberField.toOfflineCache(this.question5);
    firestoreData.question6 = StringArrayField.toOfflineCache(this.question6);
    firestoreData.question7 = StringArrayField.toOfflineCache(this.question7);
    firestoreData.question8 = NullableNumberField.toOfflineCache(this.question8);
    firestoreData.question9 = NullableNumberField.toOfflineCache(this.question9);
    firestoreData.question10 = BooleanField.toOfflineCache(this.question10);
    firestoreData.question11 = NullableNumberField.toOfflineCache(this.question11);
    firestoreData.question12 = NullableNumberField.toOfflineCache(this.question12);
    firestoreData.question13 = NullableNumberField.toOfflineCache(this.question13);
    firestoreData.question14 = NullableNumberField.toOfflineCache(this.question14);
    firestoreData.question15 = StringArrayField.toOfflineCache(this.question15);
    firestoreData.question16 = StringArrayField.toOfflineCache(this.question16);
    firestoreData.question17 = NullableNumberField.toOfflineCache(this.question17);
    firestoreData.question18 = NullableNumberField.toOfflineCache(this.question18);
    firestoreData.question19 = BooleanField.toOfflineCache(this.question19);
    firestoreData.question20 = BooleanField.toOfflineCache(this.question20);
    firestoreData.question21 = NullableNumberField.toOfflineCache(this.question21);
    firestoreData.question22 = NullableNumberField.toOfflineCache(this.question22);
    firestoreData.question23 = NullableNumberField.toOfflineCache(this.question23);
    firestoreData.question24 = NullableNumberField.toOfflineCache(this.question24);
    firestoreData.question25 = StringField.toOfflineCache(this.question25);
    firestoreData.question26 = StringArrayField.toOfflineCache(this.question26);
    firestoreData.question27 = StringArrayField.toOfflineCache(this.question27);
    firestoreData.question28 = StringArrayField.toOfflineCache(this.question28);
    firestoreData.question29 = StringArrayField.toOfflineCache(this.question29);
    firestoreData.question30 = StringArrayField.toOfflineCache(this.question30);

    return firestoreData;
  }
}
