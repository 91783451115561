import { StoreState } from "./interfaces/StoreState";

export const initialState: StoreState = {
  colorScheme: "light",
  editing: false,
  editingDialog: false,
  loading: false,
  loadingTimeout: 300,
  previousRoute: undefined,
  route: undefined,
  uploadedData: undefined,
  user: undefined,
};
