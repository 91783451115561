import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "@/features/modules/branch/objects/Branch";
import { branchModel } from "@/features/modules/branch/models/BranchModel";
import { Broker } from "@/features/modules/broker/objects/Broker";
import { brokerModel } from "@/features/modules/broker/models/BrokerModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { Firm } from "@/features/modules/firm/objects/Firm";
import { firmModel } from "@/features/modules/firm/models/FirmModel";
import { Invoice } from "@/features/modules/invoice/objects/Invoice";
import { invoiceModel } from "../InvoiceModel";
import { InvoiceType } from "@/features/modules/invoice/objects/InvoiceType";
import { LinkedBroker } from "@/features/modules/broker/objects/LinkedBroker";
import { LinkedCompany } from "@/features/modules/company/objects/LinkedCompany";
import { LinkedFirm } from "@/features/modules/firm/objects/LinkedFirm";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";

export const createCustomInvoice = async (type: InvoiceType, brokerId: string | undefined, companyId: string | undefined): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (type === undefined) throw new Error("createInvoiceMissingData");
    if (brokerId === undefined && companyId === undefined) throw new Error("createInvoiceMissingData");

    // load firm
    const firmId: string = firmModel.getSelectedFirmId();
    const firm: Firm = await firmModel.getSelectedFirm();
    const linkedFirm: LinkedFirm = LinkedFirm.createFromFirm(firm);

    if (firmId === undefined || linkedFirm === undefined || firm === undefined) throw new Error("createInvoiceNoFirm");

    const invoice: Invoice = new Invoice();
    invoice.firm = linkedFirm;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    invoice.date = today;
    invoice.type = type;

    if (companyId !== undefined) {
      // load company
      const company: Company = await companyModel.getDocument(companyId);

      if (company === undefined) throw new Error("createInvoiceInvalidCompany");

      invoice.company = LinkedCompany.createFromCompany(company);

      const legalBranch: Branch | undefined = await branchModel.getLegalBranchByCompany(company.id);

      if (legalBranch !== undefined) {
        invoice.recipientName = company.name;
        invoice.address = legalBranch.address;
        invoice.vatCode = legalBranch.vatCode;
        invoice.fiscalCode = legalBranch.fiscalCode;
        invoice.atecoCode = legalBranch.atecoCode;
        invoice.recipientCode = legalBranch.recipientCode;
        invoice.invoiceEmail = legalBranch.invoiceEmail;
        invoice.certifiedEmail = legalBranch.certifiedEmail;
      }
    } else if (brokerId !== undefined) {
      // load broker
      const broker: Broker = await brokerModel.getDocument(brokerId);

      if (broker == undefined) throw new Error("createInvoiceInvalidBroker");

      invoice.broker = LinkedBroker.createFromBroker(broker);

      invoice.recipientName = broker.name;
      invoice.address = broker.address;
      invoice.vatCode = broker.vatCode;
      invoice.fiscalCode = broker.fiscalCode;
      invoice.recipientCode = broker.recipientCode;
      invoice.certifiedEmail = broker.certifiedEmail;
    }

    return invoiceModel.createDocument(invoice);
  } catch (error: unknown) {
    appFaultModel.catchAppError("InvoiceModel.createCustomInvoice", { type, brokerId, companyId }, error);
    return "ERROR";
  }
};
