import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { PriceList } from "@/features/modules/priceList/objects/PriceList";
import { priceListModel } from "../PriceListModel";

export const clonePriceList = async (priceList: PriceList): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    const newPriceList: PriceList = new PriceList(priceList.toFirestore());
    newPriceList.id = "new";

    return priceListModel.createDocument(newPriceList);
  } catch (error: unknown) {
    appFaultModel.catchAppError("PriceListModel.clonePriceList", { priceList }, error);
    return "ERROR";
  }
};
