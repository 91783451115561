import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { Supplier } from "../objects/Supplier";
import { supplierModel } from "../models/SupplierModel";

export const supplierSearch: SearchModule = new SearchModule(
  "supplier.supplier",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as Supplier).name ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/suppliers/${firestoreDocument.id}/edit`,
  "box",
  supplierModel,
  [new SortCriteria("name", "asc", "string")]
);
