import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";
import { Vaccination } from "../objects/Vaccination";
import { vaccinationOfflineModel } from "./VaccinationOfflineModel";

export class VaccinationModel extends FirestoreModel<Vaccination> {
  public constructor() {
    super(() => new Vaccination(), "vaccinations", LockPolicy.DiscardUnsyncedChanges, "vaccination");
    this.parentCollectionName = "employees";
    this.offlineModuleModel = vaccinationOfflineModel;
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<Vaccination[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      const employee: Employee = await employeeModel.getDocument(parentId);
      const employeeCompanies: string[] = Object.keys(employee.companies);
      if (doctor === undefined || doctor.authorizedCompaniesIds.filter((id) => employeeCompanies.includes(id)).length === 0)
        throw new Error(`User doesn't have rights to read employee #${parentId}`);
    }

    return super.getDocuments([new SortCriteria("date", "asc", "date")], parentId);
  }

  public async getDocument(vaccinationId: string, parentId?: string): Promise<Vaccination> {
    return super.getDocument(vaccinationId, parentId);
  }

  public async createDocument(vaccination: Vaccination, parentId?: string): Promise<string> {
    return super.createDocument(vaccination, parentId);
  }

  public async updateDocument(vaccination: Vaccination, parentId?: string): Promise<void> {
    return super.updateDocument(vaccination, parentId);
  }

  public async deleteDocument(vaccination: Vaccination, parentId?: string): Promise<boolean> {
    return super.deleteDocument(vaccination, parentId);
  }
}

export const vaccinationModel: VaccinationModel = new VaccinationModel();
