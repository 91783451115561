import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "../objects/Examination";
import { LimitationDuration } from "@/features/modules/limitation/objects/LimitationDuration";
import { PhysicalExam } from "../objects/PhysicalExam";
import { PrescriptionDuration } from "@/features/modules/prescription/objects/PrescriptionDuration";

export class HealthRecordPaper extends DocumentPaper {
  constructor(
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      healthNote: { color: this.textColor, fontSize: 8, alignment: "justify", margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)] },
      healthRow: { color: this.textColor, fontSize: 10 },
      healthRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      healthQuestion: { color: this.textColor, fontSize: 8 },
      healthSmall: { color: this.textColor, fontSize: 8 },
      healthSmallBold: { bold: true, color: this.textColor, fontSize: 8 },
      healthTableCell: { alignment: "left", fontSize: 8 },
      healthTableCellCenter: { alignment: "center", fontSize: 8 },
      healthTableHead: { alignment: "left", bold: true, color: "white", fillColor: "#aaa", fontSize: 10 },
      judgmentNotes: { alignment: "justify", color: this.textColor, fontSize: 8, margin: [0, 0, 0, 0] },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("CARTELLA SANITARIA", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle(this.t("examination.detail").toLocaleUpperCase());
    this.writeTwoBoxes(
      this.t("examination.type"),
      this.examination.type?.name ?? undefined,
      this.t("examination.frequency"),
      this.t(`frequencies.${this.examination.frequency}`) ?? undefined
    );
    this.writeTwoBoxes(
      this.t("examination.examinationDate"),
      this.examination.date !== undefined ? this.d(this.examination.date, "shortDate") : undefined,
      this.t("examination.hireDate"),
      this.examination.hireDate !== undefined ? this.d(this.examination.hireDate, "shortDate") : undefined
    );
    this.writeTwoBoxes(
      this.t("examination.dutyName"),
      this.examination.dutyName,
      this.t("examination.dutyDate"),
      this.examination.dutyDate !== undefined ? this.d(this.examination.dutyDate, "shortDate") : undefined
    );

    this.writeSubtitle(this.t("examination.risks").toLocaleUpperCase());
    this.renderRisks();

    this.writeSubtitle(this.t("examination.tests.tests").toLocaleUpperCase());
    this.renderTests();

    this.writeSubtitle(this.t("examination.surveys.surveys").toLocaleUpperCase());
    this.renderSurveys();

    this.writeSubtitle(this.t("examination.workHistoryLong").toLocaleUpperCase());
    this.renderWorkHistory();

    this.writeSubtitle(this.t("examination.familyHistoryLong").toLocaleUpperCase());
    this.renderFamilyHistory();

    this.writeSubtitle(this.t("examination.physioHistoryLong").toLocaleUpperCase());
    this.renderPhysioHistory();

    this.writeSubtitle(this.t("examination.farHistoryLong").toLocaleUpperCase());
    this.renderFarHistory();

    this.writeSubtitle(this.t("examination.recentHistoryLong").toLocaleUpperCase());
    this.renderRecentHistory();

    this.writeSubtitle(this.t("examination.physicalExam").toLocaleUpperCase());
    this.renderPhysicalExam();

    this.writeSubtitle(this.t("examination.endings.endingPaper").toLocaleUpperCase());
    this.renderEnding();

    this.writeJudgmentNotes();

    await this.writeSignatures();

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private renderRisks(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("duty.name").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("duty.norm").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("duty.details").toLocaleUpperCase() },
      ],
    ];

    for (const risk of this.examination.getLinkedRisks()) {
      dataTableBody.push([
        { style: "healthTableCell", text: risk.name ?? "-" },
        { style: "healthTableCell", text: risk.norm?.name ?? "-" },
        { style: "healthTableCell", text: risk.text ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(50), DataHelpers.mmToPoints(50), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.risksNotes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(2), 0, 0],
        text: `${this.t("duty.risksNotes")}: ${this.examination.risksNotes.replaceAll("\n", ", ")}`,
        style: "healthNote",
      });
    }
  }

  private renderTests(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.tests.date").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("duty.name").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("duty.frequency").toLocaleUpperCase() },
      ],
    ];

    for (const examinationTest of this.examination.getAllTests()) {
      dataTableBody.push([
        { style: "healthTableCell", text: examinationTest.date !== undefined ? this.d(examinationTest.date, "shortDate") : "-" },
        { style: "healthTableCell", text: `${examinationTest.testType?.category?.name ?? ""} - ${examinationTest.testType?.name}` },
        { style: "healthTableCell", text: this.t(`frequencies.${examinationTest.testType?.frequency}`) ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(75), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private renderSurveys(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.surveys.date").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.surveys.name").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("duty.frequency").toLocaleUpperCase() },
      ],
    ];

    for (const survey of this.examination.getLinkedSurveys()) {
      dataTableBody.push([
        { style: "healthTableCell", text: survey.date !== undefined ? this.d(survey.date, "shortDate") : "-" },
        { style: "healthTableCell", text: survey.name ?? "-" },
        { style: "healthTableCell", text: this.t(`frequencies.${survey.frequency}`) ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(25), DataHelpers.mmToPoints(75), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private renderWorkHistory(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.workHistories.period").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.workHistories.company").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.workHistories.sector").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.workHistories.duty").toLocaleUpperCase() },
      ],
    ];

    for (const item of this.examination.workHistory.items) {
      dataTableBody.push([
        { style: "healthTableCell", text: item.period ?? "-" },
        { style: "healthTableCell", text: item.company ?? "-" },
        { style: "healthTableCell", text: item.sector ?? "-" },
        { style: "healthTableCell", text: item.duty ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 4, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.workHistory.notes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(2), 0, 0],
        text: `${this.t("examination.notes")}: ${this.examination.workHistory.notes.replaceAll("\n", ", ")}`,
        style: "healthNote",
      });
    }
  }

  private renderFamilyHistory(): void {
    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.familyHistories.kinship").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.familyHistories.diseases").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.familyHistories.death").toLocaleUpperCase() },
      ],
    ];

    for (const item of this.examination.familyHistory.items) {
      dataTableBody.push([
        { style: "healthTableCell", text: item.kinship ?? "-" },
        { style: "healthTableCell", text: item.diseases ?? "-" },
        { style: "healthTableCell", text: item.death ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", "*", "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.familyHistory.notes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(2), 0, 0],
        text: `${this.t("examination.notes")}: ${this.examination.familyHistory.notes.replaceAll("\n", ", ")}`,
        style: "healthNote",
      });
    }
  }

  private renderPhysioHistory(): void {
    const fields: { label: string; value: string }[] = [];

    if (this.examination.physioHistory.school !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.school"), value: this.examination.physioHistory.school });
    }
    if (this.examination.physioHistory.food !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.food"), value: this.examination.physioHistory.food });
    }
    if (this.examination.physioHistory.sport !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.sport"), value: this.examination.physioHistory.sport });
    }
    if (this.examination.physioHistory.sportType !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.sportType"), value: this.examination.physioHistory.sportType });
    }
    if (this.examination.physioHistory.allergiesCheck === true) {
      fields.push({ label: this.t("examination.physioHistories.allergies"), value: this.t("gen.yes") });
      if (this.examination.physioHistory.allergiesList !== undefined) {
        fields.push({
          label: `${this.t("examination.physioHistories.allergies")} - ${this.t("examination.physioHistories.allergiesList")}`,
          value: this.examination.physioHistory.allergiesList,
        });
      }
      if (this.examination.physioHistory.allergiesSymptoms !== undefined) {
        fields.push({
          label: `${this.t("examination.physioHistories.allergies")} - ${this.t("examination.physioHistories.allergiesSymptoms")}`,
          value: this.examination.physioHistory.allergiesSymptoms,
        });
      }
    } else {
      fields.push({ label: this.t("examination.physioHistories.allergies"), value: this.t("gen.no") });
    }
    if (this.examination.physioHistory.smokeType !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.smokeType"), value: this.examination.physioHistory.smokeType });
      if (this.examination.physioHistory.smokeAmount !== undefined) {
        fields.push({
          label: `${this.t("examination.physioHistories.smokeType")} - ${this.t("examination.physioHistories.smokeAmount")}`,
          value: this.examination.physioHistory.smokeAmount,
        });
      }
      if (this.examination.physioHistory.smokeFrom !== undefined) {
        fields.push({
          label: `${this.t("examination.physioHistories.smokeType")} - ${this.t("examination.physioHistories.smokeFrom")}`,
          value: this.examination.physioHistory.smokeFrom,
        });
      }
      if (this.examination.physioHistory.smokeTo !== undefined) {
        fields.push({
          label: `${this.t("examination.physioHistories.smokeType")} - ${this.t("examination.physioHistories.smokeTo")}`,
          value: this.examination.physioHistory.smokeTo,
        });
      }
    }
    if (this.examination.physioHistory.wine !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.wine"), value: this.examination.physioHistory.wine });
    }
    if (this.examination.physioHistory.beer !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.beer"), value: this.examination.physioHistory.beer });
    }
    if (this.examination.physioHistory.spirit !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.spirit"), value: this.examination.physioHistory.spirit });
    }
    if (this.examination.physioHistory.medicineCheck === true) {
      let value: string = this.t("gen.yes");
      if (this.examination.physioHistory.medicineDetails !== undefined) {
        value += ` - ${this.examination.physioHistory.medicineDetails}`;
      }
      fields.push({ label: this.t("examination.physioHistories.medicine"), value: value });
    } else {
      fields.push({ label: this.t("examination.physioHistories.medicine"), value: this.t("gen.no") });
    }
    if (this.examination.physioHistory.intestine !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.intestine"), value: this.examination.physioHistory.intestine });
    }
    if (this.examination.physioHistory.diuresis !== undefined) {
      fields.push({ label: this.t("examination.physioHistories.diuresis"), value: this.examination.physioHistory.diuresis });
    }
    if (this.examination.employee?.sex === "F") {
      if (this.examination.physioHistory.firstPeriod !== undefined) {
        fields.push({ label: this.t("examination.physioHistories.firstPeriod"), value: this.examination.physioHistory.firstPeriod });
      }
      if (this.examination.physioHistory.periodType !== undefined) {
        fields.push({ label: this.t("examination.physioHistories.periodType"), value: this.examination.physioHistory.periodType });
      }
      if (this.examination.physioHistory.sons !== undefined) {
        fields.push({ label: this.t("examination.physioHistories.sons"), value: this.examination.physioHistory.sons });
      }
      if (this.examination.physioHistory.abortions !== undefined) {
        fields.push({ label: this.t("examination.physioHistories.abortions"), value: this.examination.physioHistory.abortions });
      }
      if (this.examination.physioHistory.completedPregnancies !== undefined) {
        fields.push({
          label: this.t("examination.physioHistories.completedPregnancies"),
          value: this.examination.physioHistory.completedPregnancies,
        });
      }
      if (this.examination.physioHistory.uncompletedPregnancies !== undefined) {
        fields.push({
          label: this.t("examination.physioHistories.uncompletedPregnancies"),
          value: this.examination.physioHistory.uncompletedPregnancies,
        });
      }
    }

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.paperField").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.paperValue").toLocaleUpperCase() },
      ],
    ];

    for (const field of fields) {
      dataTableBody.push([
        { style: "healthTableCell", text: field.label },
        { style: "healthTableCell", text: field.value },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 2, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(50), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.physioHistory.notes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(2), 0, 0],
        text: this.examination.physioHistory.notes,
        style: "healthNote",
      });
    }
  }

  private renderFarHistory(): void {
    if (this.examination.farHistory.goodHealthCheck === true) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.t("examination.farHistories.goodHealth"),
        style: "healthNote",
      });
      return;
    }

    if (this.examination.farHistory.healthDetails !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, 0, 0, DataHelpers.mmToPoints(2)],
        text: this.examination.farHistory.healthDetails,
        style: "healthNote",
      });
    }

    const fields: { label: string; value: string }[] = [];

    if (this.examination.farHistory.surgery.length > 0) {
      fields.push({ label: this.t("examination.farHistories.surgery"), value: this.examination.farHistory.surgery.join(", ") });
      if (this.examination.farHistory.surgeryDetails !== undefined) {
        fields.push({
          label: `${this.t("examination.farHistories.surgery")} - ${this.t("examination.farHistories.details")}`,
          value: this.examination.farHistory.surgeryDetails,
        });
      }
    }
    if (this.examination.farHistory.injuries !== undefined) {
      fields.push({ label: this.t("examination.farHistories.injuries"), value: this.examination.farHistory.injuries });
    }
    if (this.examination.farHistory.workAccidents !== undefined) {
      fields.push({ label: this.t("examination.farHistories.workAccidents"), value: this.examination.farHistory.workAccidents });
    }
    if (this.examination.farHistory.workDiseases !== undefined) {
      fields.push({ label: this.t("examination.farHistories.workDiseases"), value: this.examination.farHistory.workDiseases });
    }
    if (this.examination.farHistory.notes !== undefined) {
      fields.push({ label: this.t("examination.farHistories.notes"), value: this.examination.farHistory.notes });
    }

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.paperField").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.paperValue").toLocaleUpperCase() },
      ],
    ];

    for (const field of fields) {
      dataTableBody.push([
        { style: "healthTableCell", text: field.label },
        { style: "healthTableCell", text: field.value },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 2, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(50), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.physioHistory.notes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(2), 0, 0],
        text: this.examination.physioHistory.notes,
        style: "healthNote",
      });
    }

    this.renderDisabilities();
  }

  private renderDisabilities(): void {
    if (this.examination.farHistory.disabilities.length === 0) return;

    this.writeSubtitle(this.t("examination.farHistories.disabilities").toLocaleUpperCase());

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.farHistories.disabilityType").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.farHistories.startYear").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.farHistories.percent").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.farHistories.cause").toLocaleUpperCase() },
      ],
    ];

    for (const item of this.examination.farHistory.disabilities) {
      dataTableBody.push([
        { style: "healthTableCell", text: item.type ?? "-" },
        { style: "healthTableCell", text: item.startYear ?? "-" },
        { style: "healthTableCell", text: item.percent !== undefined ? `${item.percent}%` : "-" },
        { style: "healthTableCell", text: item.cause ?? "-" },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 3, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private renderRecentHistory(): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: this.examination.recentHistory.notes ?? "-",
      style: "healthNote",
    });
  }

  private renderPhysicalExam(): void {
    const fields: { label: string; value: string }[] = [];

    if (this.examination.physicalExam.weight !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.weight"), value: Number(this.examination.physicalExam.weight).toFixed(0) });
    }
    if (this.examination.physicalExam.height !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.height"), value: Number(this.examination.physicalExam.height).toFixed(0) });
    }
    if (this.examination.physicalExam.weight !== undefined && this.examination.physicalExam.height !== undefined) {
      let bmi = 0;
      if (this.examination.physicalExam.weight !== undefined && this.examination.physicalExam.height !== undefined) {
        if (this.examination.physicalExam.weight > 0 && this.examination.physicalExam.height > 0) {
          bmi = Math.round(this.examination.physicalExam.weight / (this.examination.physicalExam.height / 100) ** 2);
        }
      }
      if (bmi > 0) fields.push({ label: this.t("examination.physicalExams.bmi"), value: bmi.toFixed(0) });
    }
    if (this.examination.physicalExam.pao !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.pao"), value: this.examination.physicalExam.pao });
    }
    if (this.examination.physicalExam.fc !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.fc"), value: this.examination.physicalExam.fc });
    }
    if (this.examination.physicalExam.generalCondition !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.generalCondition"), value: this.examination.physicalExam.generalCondition });
    }
    if (this.examination.physicalExam.fc !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.fc"), value: this.examination.physicalExam.fc });
    }

    const fields1 = [
      { name: "skin", label: "Cute", select: "Normoelastica, normoidrata" },
      { name: "mucousMembranes", label: "Mucose", select: "Rosee, integre" },
      { name: "annexes", label: "Annessi", select: "Secondo sesso ed età" },
      { name: "lymphGlands", label: "Linfoghiandole superficiali", select: "Non palpabili" },
      { name: "headNeck", label: "Capo / collo", select: "N.d.p." },
      { name: "thyroid", label: "Tiroide", select: "Non palpabile" },
    ];

    for (const field of fields1) {
      if (this.examination.physicalExam[`${field.name}Check` as keyof PhysicalExam] === true) {
        fields.push({ label: field.label, value: field.select });
      } else if (this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] !== undefined) {
        fields.push({ label: field.label, value: this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] as string });
      }
    }

    const fields2 = [
      { name: "chest", label: "Torace", select: "Simmetrico" },
      { name: "expandability", label: "Espansibilità", select: "Regolare" },
      { name: "fvt", label: "FVT", select: "Normotrasmesso" },
      { name: "percussion", label: "Percussione", select: "Suono chiaro polmonare" },
      { name: "mv", label: "MV", select: "Fisiologico", details: "Rumori aggiunti" },
    ];

    for (const field of fields2) {
      if (this.examination.physicalExam[`${field.name}Check` as keyof PhysicalExam] === true) {
        fields.push({ label: field.label, value: field.select });
      } else if (this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] !== undefined) {
        fields.push({ label: field.label, value: this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] as string });
      }
    }

    const fields3 = [
      { name: "tones", label: "Toni", select: "Validi" },
      { name: "breaks", label: "Pause", select: "App. libere", details: "Soffio" },
      { name: "wrists", label: "Polsi art. perif.", select: "Isosfigmici" },
      { name: "varices", label: "Varici / edemi", select: "Assenti", details: "Sede" },
    ];

    for (const field of fields3) {
      if (this.examination.physicalExam[`${field.name}Check` as keyof PhysicalExam] === true) {
        fields.push({ label: field.label, value: field.select });
      } else if (this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] !== undefined) {
        fields.push({ label: field.label, value: this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] as string });
      }
    }

    const fields4 = [
      { name: "shape", label: "Forma", select: "Piana" },
      { name: "palpation", label: "Palpazione", select: "Trattabile, non dolente, non masse" },
      { name: "listening", label: "Ascoltazione", select: "Peristalsi presente" },
      { name: "liver", label: "Fegato", select: "Nei limiti" },
      { name: "spleen", label: "Milza", select: "Non palpabile il polo inferiore" },
    ];

    for (const field of fields4) {
      if (this.examination.physicalExam[`${field.name}Check` as keyof PhysicalExam] === true) {
        fields.push({ label: field.label, value: field.select });
      } else if (this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] !== undefined) {
        fields.push({ label: field.label, value: this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] as string });
      }
    }

    if (this.examination.physicalExam.otherAbdomenNotes !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.other"), value: this.examination.physicalExam.otherAbdomenNotes });
    }

    const fields5 = [
      { name: "mobility", label: "Mobilità / deambulazione", select: "N.d.p." },
      { name: "articularity", label: "Articolarità", select: "Normale" },
      { name: "musculature", label: "Muscolatura", select: "Normotonica / normotrofica" },
      { name: "rachis", label: "Curvatura Rachide", select: "Fisiologica" },
      { name: "lasegue", label: "Lasegue", select: "Negativa bil." },
      { name: "wasserman", label: "Wasserman", select: "Negativa bil." },
      { name: "mm", label: "Palpazione MM Paravertebrali", select: "Regolare" },
    ];

    for (const field of fields5) {
      if (this.examination.physicalExam[`${field.name}Check` as keyof PhysicalExam] === true) {
        fields.push({ label: field.label, value: field.select });
      } else if (this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] !== undefined) {
        fields.push({ label: field.label, value: this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] as string });
      }
    }

    if (this.examination.physicalExam.otherMusculoskeletalNotes !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.other"), value: this.examination.physicalExam.otherMusculoskeletalNotes });
    }

    const fields6 = [
      { name: "sensitivity", label: "Sensibilità", select: "Conservata" },
      { name: "rot", label: "ROT", select: "Normoevocabili" },
      { name: "humor", label: "Stato dell'umore", select: "Conservato", details: "Alterazioni psichiche diagnosticate" },
      { name: "tinel", label: "Tinel", select: "Regolare" },
      { name: "phalen", label: "Phalen", select: "Regolare" },
    ];

    for (const field of fields6) {
      if (this.examination.physicalExam[`${field.name}Check` as keyof PhysicalExam] === true) {
        fields.push({ label: field.label, value: field.select });
      } else if (this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] !== undefined) {
        fields.push({ label: field.label, value: this.examination.physicalExam[`${field.name}Details` as keyof PhysicalExam] as string });
      }
    }

    if (this.examination.physicalExam.hearingNotes !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.hearing"), value: this.examination.physicalExam.hearingNotes });
    }
    if (this.examination.physicalExam.sightNotes !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.sight"), value: this.examination.physicalExam.sightNotes });
    }
    if (this.examination.physicalExam.giordanoCheck === true) {
      fields.push({ label: this.t("examination.physicalExams.giordano"), value: "Negativa bil." });
    } else if (this.examination.physicalExam.giordanoDetails !== undefined) {
      fields.push({ label: this.t("examination.physicalExams.giordano"), value: this.examination.physicalExam.giordanoDetails });
    }

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.paperField").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.paperValue").toLocaleUpperCase() },
      ],
    ];

    for (const field of fields) {
      dataTableBody.push([
        { style: "healthTableCell", text: field.label },
        { style: "healthTableCell", text: field.value },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 2, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(50), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.physicalExam.notes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(2), 0, 0],
        text: this.examination.physicalExam.notes,
        style: "healthNote",
      });
    }
  }

  private renderEnding(): void {
    const fields: { label: string; value: string }[] = [];

    if (this.examination.ending.ending !== undefined) {
      fields.push({ label: this.t("examination.endings.ending"), value: this.examination.ending.ending });
    }
    if (this.examination.ending.endingMeasures !== undefined) {
      fields.push({ label: this.t("examination.endings.endingMeasures"), value: this.examination.ending.endingMeasures });
    }
    if (this.examination.ending.additionalTestsRequired !== undefined) {
      fields.push({ label: this.t("examination.endings.additionalTestsRequired"), value: this.examination.ending.additionalTestsRequired });
    }

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.paperField").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.paperValue").toLocaleUpperCase() },
      ],
    ];

    for (const field of fields) {
      dataTableBody.push([
        { style: "healthTableCell", text: field.label },
        { style: "healthTableCell", text: field.value },
      ]);
    }

    if (dataTableBody.length === 1) {
      dataTableBody.push([{ colSpan: 2, style: "healthTableCellCenter", text: this.t("gen.noData") }]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: [DataHelpers.mmToPoints(50), "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    this.renderPrescriptions();

    this.renderLimitations();

    this.writeSubtitle(this.t("examination.endings.judgmentPaper").toLocaleUpperCase());

    this.writeOneBox(
      this.t("examination.endings.judgment"),
      this.examination.ending.judgment !== undefined
        ? this.t(`examination.endings.judgments.${this.examination.ending.judgment}`).toLocaleUpperCase()
        : "-"
    );
    this.writeTwoBoxes(
      this.t("examination.endings.judgmentDate"),
      this.examination.ending.judgmentDate !== undefined ? this.d(this.examination.ending.judgmentDate, "shortDate") : "-",
      this.t("examination.endings.nextExaminationDate"),
      this.examination.ending.nextExaminationDate !== undefined ? this.d(this.examination.ending.nextExaminationDate, "shortDate") : "-"
    );
    this.writeTwoBoxes(
      this.t("examination.endings.employeeDate"),
      this.examination.ending.employeeDate !== undefined ? this.d(this.examination.ending.employeeDate, "shortDate") : "-",
      this.t("examination.endings.companyDate"),
      this.examination.ending.companyDate !== undefined ? this.d(this.examination.ending.companyDate, "shortDate") : "-"
    );
  }

  private renderPrescriptions(): void {
    if (this.examination.ending.prescriptions === undefined || Object.keys(this.examination.ending.prescriptions).length === 0) return;
    if (this.examination.ending.getLinkedPrescriptions().length === 0) return;

    this.writeSubtitle(this.t("examination.endings.prescriptions").toLocaleUpperCase());

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.endings.prescription").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("prescription.duration").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.endings.details").toLocaleUpperCase() },
      ],
    ];

    for (const prescription of this.examination.ending.getLinkedPrescriptions()) {
      dataTableBody.push([
        { style: "healthTableCell", text: prescription.name ?? "-" },
        {
          style: "healthTableCell",
          text:
            prescription.duration === PrescriptionDuration.Temporary
              ? `${this.t(`prescription.durations.${prescription.duration}`)}: ${prescription.temporaryDuration ?? "-"}`
              : this.t(`prescription.durations.${prescription.duration}`),
        },
        { style: "healthTableCell", text: prescription.text ?? "-" },
      ]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", "*", "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });

    if (this.examination.ending.prescriptionsNotes !== undefined) {
      (this.docDefinition.content as Record<string, unknown>[]).push({
        text: this.examination.ending.prescriptionsNotes,
        style: "healthNote",
      });
    }
  }

  private renderLimitations(): void {
    if (this.examination.ending.limitations === undefined || Object.keys(this.examination.ending.limitations).length === 0) return;
    if (this.examination.ending.getLinkedLimitations().length === 0) return;

    this.writeSubtitle(this.t("examination.endings.limitations").toLocaleUpperCase());

    const dataTableBody: unknown[] = [
      [
        { style: "healthTableHead", text: this.t("examination.endings.limitation").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("limitation.duration").toLocaleUpperCase() },
        { style: "healthTableHead", text: this.t("examination.endings.details").toLocaleUpperCase() },
      ],
    ];

    for (const limitation of this.examination.ending.getLinkedLimitations()) {
      dataTableBody.push([
        { style: "healthTableCell", text: limitation.name ?? "-" },
        {
          style: "healthTableCell",
          text:
            limitation.duration === LimitationDuration.Temporary
              ? `${this.t(`limitation.durations.${limitation.duration}`)}: ${limitation.temporaryDuration ?? "-"}`
              : this.t(`limitation.durations.${limitation.duration}`),
        },
        { style: "healthTableCell", text: limitation.text ?? "-" },
      ]);
    }

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      table: {
        body: dataTableBody,
        headerRows: 1,
        widths: ["*", "*", "*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeJudgmentNotes(): void {
    if (this.examination.ending.judgmentNotes == undefined) return;

    this.writeSubtitle("Note sul giudizio di idoneità");

    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: this.examination.ending.judgmentNotes,
      style: "judgmentNotes",
    });
  }

  private writeOneBox(label: string, value: string | undefined): void {
    const dataTableBody: unknown[] = [
      [{ text: value ?? "-", style: "employeeDataValue", border: [false, false, false, false] }],
      [{ text: label, style: "employeeDataField", border: [false, true, false, false] }],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      style: "employeeDataTable",
      table: {
        body: dataTableBody,
        headerRows: 0,
        widths: ["*"],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  private writeTwoBoxes(labelA: string, valueA: string | undefined, labelB: string, valueB: string | undefined): void {
    const dataTableBody: unknown[] = [
      [
        { text: valueA ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: valueB ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
      ],
      [
        { text: labelA, style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: labelB, style: "employeeDataField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, 0, 0, 0],
      style: "employeeDataTable",
      table: {
        body: dataTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(82), "*", DataHelpers.mmToPoints(82)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }
}
