import { Doc } from "@/features/modules/doc/objects/Doc";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocModel } from "@/features/modules/doc/models/DocModel";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { SupplierDocHelpers } from "../helpers/SupplierDocHelpers";

import { getExpiredSupplierDocs, getExpiringSupplierDocs, getMissingSupplierDocs } from "./methods";

export class SupplierDocModel extends DocModel {
  public constructor() {
    super();
    this.collectionName = "supplierDocs";
    this.roleModule = "supplierDoc";
    this.parentCollectionName = "suppliers";
  }

  public async deleteDocument(supplierDoc: Doc, parentId?: string): Promise<boolean> {
    const result: boolean = await super.deleteDocument(supplierDoc, parentId);
    if (result === false) return false;

    for (const file of supplierDoc.files) {
      await storageModel.deleteFile(SupplierDocHelpers.getFolderPath(parentId), file.uniqueName as string);
    }
    return true;
  }

  public async getExpiredSupplierDocs(): Promise<DocExpiration[]> {
    return getExpiredSupplierDocs();
  }

  public async getExpiringSupplierDocs(): Promise<DocExpiration[]> {
    return getExpiringSupplierDocs();
  }

  public async getMissingSupplierDocs(): Promise<DocExpiration[]> {
    return getMissingSupplierDocs();
  }
}

export const supplierDocModel: SupplierDocModel = new SupplierDocModel();
