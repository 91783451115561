import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { indexedDbModel } from "@/core/modules/indexedDb/IndexedDbModel";
import { offlineModuleModels } from "@/core/modules/offline/objects/offlineModuleModels";

export const deleteCache = async (): Promise<void> => {
  try {
    for (const offlineModuleModel of offlineModuleModels) {
      const keys: IDBValidKey[] = await indexedDbModel.getDocumentsKeys(offlineModuleModel.collectionName);

      for (const key of keys) {
        await indexedDbModel.deleteDocument(offlineModuleModel.collectionName, key.toString());
      }
    }

    localStorage.removeItem("koruCacheTimestamp");
  } catch (error: unknown) {
    appFaultModel.catchAppError("OfflineModel.deleteCache", {}, error);
  }
};
