import { SurveyForm } from "./SurveyForm";

import { BooleanField, NullableNumberField, StringField } from "@/core/fields";

export class AuditShortSurveyForm extends SurveyForm {
  public question1A: number | undefined = 0;
  public question2A: number | undefined = 0;
  public question3A: number | undefined = 0;
  public pointsA: number | undefined = 0;
  public resultA: string | undefined = "Negativo";
  public additionalQuestions = false;
  public question1B: number | undefined = undefined;
  public question2B: number | undefined = undefined;
  public question3B: number | undefined = undefined;
  public question4B: number | undefined = undefined;
  public question5B: number | undefined = undefined;
  public question6B: number | undefined = undefined;
  public question7B: number | undefined = undefined;
  public question8B: number | undefined = undefined;
  public pointsB: number | undefined = undefined;
  public resultB: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): AuditShortSurveyForm {
    super.fromFirestore(data);

    this.question1A = NullableNumberField.fromFirestore(data.question1A);
    this.question2A = NullableNumberField.fromFirestore(data.question2A);
    this.question3A = NullableNumberField.fromFirestore(data.question3A);
    this.pointsA = NullableNumberField.fromFirestore(data.pointsA);
    this.resultA = StringField.fromFirestore(data.resultA);
    this.additionalQuestions = BooleanField.fromFirestore(data.additionalQuestions);
    this.question1B = NullableNumberField.fromFirestore(data.question1B);
    this.question2B = NullableNumberField.fromFirestore(data.question2B);
    this.question3B = NullableNumberField.fromFirestore(data.question3B);
    this.question4B = NullableNumberField.fromFirestore(data.question4B);
    this.question5B = NullableNumberField.fromFirestore(data.question5B);
    this.question6B = NullableNumberField.fromFirestore(data.question6B);
    this.question7B = NullableNumberField.fromFirestore(data.question7B);
    this.question8B = NullableNumberField.fromFirestore(data.question8B);
    this.pointsB = NullableNumberField.fromFirestore(data.pointsB);
    this.resultB = StringField.fromFirestore(data.resultB);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1A = NullableNumberField.toFirestore(this.question1A);
    firestoreData.question2A = NullableNumberField.toFirestore(this.question2A);
    firestoreData.question3A = NullableNumberField.toFirestore(this.question3A);
    firestoreData.pointsA = NullableNumberField.toFirestore(this.pointsA);
    firestoreData.resultA = StringField.toFirestore(this.resultA);
    firestoreData.additionalQuestions = BooleanField.toFirestore(this.additionalQuestions);
    firestoreData.question1B = NullableNumberField.toFirestore(this.question1B);
    firestoreData.question2B = NullableNumberField.toFirestore(this.question2B);
    firestoreData.question3B = NullableNumberField.toFirestore(this.question3B);
    firestoreData.question4B = NullableNumberField.toFirestore(this.question4B);
    firestoreData.question5B = NullableNumberField.toFirestore(this.question5B);
    firestoreData.question6B = NullableNumberField.toFirestore(this.question6B);
    firestoreData.question7B = NullableNumberField.toFirestore(this.question7B);
    firestoreData.question8B = NullableNumberField.toFirestore(this.question8B);
    firestoreData.pointsB = NullableNumberField.toFirestore(this.pointsB);
    firestoreData.resultB = StringField.toFirestore(this.resultB);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): AuditShortSurveyForm {
    super.fromOfflineCache(data);

    this.question1A = NullableNumberField.fromOfflineCache(data.question1A);
    this.question2A = NullableNumberField.fromOfflineCache(data.question2A);
    this.question3A = NullableNumberField.fromOfflineCache(data.question3A);
    this.pointsA = NullableNumberField.fromOfflineCache(data.pointsA);
    this.resultA = StringField.fromOfflineCache(data.resultA);
    this.additionalQuestions = BooleanField.fromOfflineCache(data.additionalQuestions);
    this.question1B = NullableNumberField.fromOfflineCache(data.question1B);
    this.question2B = NullableNumberField.fromOfflineCache(data.question2B);
    this.question3B = NullableNumberField.fromOfflineCache(data.question3B);
    this.question4B = NullableNumberField.fromOfflineCache(data.question4B);
    this.question5B = NullableNumberField.fromOfflineCache(data.question5B);
    this.question6B = NullableNumberField.fromOfflineCache(data.question6B);
    this.question7B = NullableNumberField.fromOfflineCache(data.question7B);
    this.question8B = NullableNumberField.fromOfflineCache(data.question8B);
    this.pointsB = NullableNumberField.fromOfflineCache(data.pointsB);
    this.resultB = StringField.fromOfflineCache(data.resultB);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1A = NullableNumberField.toOfflineCache(this.question1A);
    firestoreData.question2A = NullableNumberField.toOfflineCache(this.question2A);
    firestoreData.question3A = NullableNumberField.toOfflineCache(this.question3A);
    firestoreData.pointsA = NullableNumberField.toOfflineCache(this.pointsA);
    firestoreData.resultA = StringField.toOfflineCache(this.resultA);
    firestoreData.additionalQuestions = BooleanField.toOfflineCache(this.additionalQuestions);
    firestoreData.question1B = NullableNumberField.toOfflineCache(this.question1B);
    firestoreData.question2B = NullableNumberField.toOfflineCache(this.question2B);
    firestoreData.question3B = NullableNumberField.toOfflineCache(this.question3B);
    firestoreData.question4B = NullableNumberField.toOfflineCache(this.question4B);
    firestoreData.question5B = NullableNumberField.toOfflineCache(this.question5B);
    firestoreData.question6B = NullableNumberField.toOfflineCache(this.question6B);
    firestoreData.question7B = NullableNumberField.toOfflineCache(this.question7B);
    firestoreData.question8B = NullableNumberField.toOfflineCache(this.question8B);
    firestoreData.pointsB = NullableNumberField.toOfflineCache(this.pointsB);
    firestoreData.resultB = StringField.toOfflineCache(this.resultB);

    return firestoreData;
  }
}
