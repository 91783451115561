import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getExpiringCompanyDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const companies: Company[] = await companyModel.getCompaniesWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("expiringDocs", DocTypeSource.Company);

    for (const company of companies) {
      if (company.trackExpirations === false) continue;

      const companyWidget: Widget | undefined = widgets.find((widget) => widget.source === DocTypeSource.Company && widget.sourceId === company.id);
      if (companyWidget === undefined) continue;
      if (companyWidget.values.count === 0) continue;
      if (companyWidget.values.expirations == undefined) continue;

      const expirations: Record<string, Timestamp> = companyWidget.values.expirations as Record<string, Timestamp>;
      for (const docTypeId of Object.keys(expirations)) {
        docExpirations.push(new DocExpiration(company.id, company.name ?? "-", expirations[docTypeId].toDate(), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyDocModel.getExpiringCompanyDocs", {}, error);
    return [];
  }
};
