export const fr = {
  auth: {
    askNewPassword: "Demander un nouveau mot de passe",
    authenticationSubtitle: "Connectez-vous avec votre compte",
    authenticationTitle: "Authentification",
    checkInbox: "Vérifiez votre boîte aux lettres",
    choosePassword: "Choisir un nouveau mot de passe",
    email: "E-mail",
    emailBlank: "Entrez votre adresse e-mail",
    emailError: "L'adresse e-mail n'est pas valide",
    forgotError: "Le mot de passe n'a pas pu être récupéré",
    forgotPasswordTitle: "Récupérer le mot de passe",
    forgetpasswordstart: "Mot de passe oublié?",
    forgotPasswordMiddle: "Exiger",
    forgetPasswordEnd: "récupération maintenant!",
    inputEmail: "Entrez votre adresse e-mail",
    login: "Connexion",
    loginFailed: "Échec de l'authentification",
    password: "Mot de passe",
    passwordBlank: "Entrez le nouveau mot de passe",
    passwordConfirm: "Confirmer le nouveau mot de passe",
    passwordConfirmBlank: "Veuillez entrer une nouvelle confirmation de mot de passe",
    passwordConfirmError: "Les deux mots de passe ne correspondent pas",
    passwordError: "Veuillez entrer un mot de passe valide",
    passwordErrorChars: "Le mot de passe doit comporter au moins 8 caractères",
    passwordErrorLowercase: "Veuillez saisir au moins un caractère minuscule",
    passwordErrorNumber: "Veuillez saisir au moins un chiffre",
    passwordErrorUppercase: "Veuillez saisir au moins un caractère majuscule",
    passwordRequirementsTitle: "Exigences",
    passwordRequirementsChars: "Minimum 8 caractères",
    passwordRequirementsLowercase: "Au moins un caractère minuscule",
    passwordRequirementsNumber: "Au moins un chiffre numérique",
    passwordRequirementsUppercase: "Au moins un caractère majuscule",
    passwordRememberedStart: "Mémoriser le mot de passe? Revenir à",
    passwordRememberedEnd: "authentification",
    recoveryPasswordTitle: "Récupérer le mot de passe",
    resetPassword: "Réinitialiser le mot de passe",
    resetPasswordError: "Impossible de réinitialiser le mot de passe",
    resetPasswordSuccessStart: "Le nouveau mot de passe a été défini. Aller à",
    resetPasswordSuccessEnd: "authentification",
  },
};
