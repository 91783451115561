export const fr = {
  profile: {
    changeEmail: "Modifier l'e-mail",
    changePassword: "Modifier le mot de passe",
    changePasswordButton: "Modifier le mot de passe",
    email: "E-mail",
    oldPassword: "Ancien mot de passe",
    oldPasswordBlank: "Veuillez saisir votre ancien mot de passe",
    newEmail: "Nouvelle adresse e-mail",
    newEmailError: "Veuillez saisir une adresse e-mail valide",
    newPassword: "Nouveau mot de passe",
    newPasswordBlank: "Veuillez entrer votre nouveau mot de passe",
  },
};
