import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

import { StringField } from "@/core/fields";

export class FamilyHistoryItem {
  public id: string = DataHelpers.uniqueId();
  public kinship: string | undefined = undefined;
  public diseases: string | undefined = undefined;
  public death: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): FamilyHistoryItem {
    this.id = DataHelpers.uniqueId();

    this.kinship = StringField.fromFirestore(data.kinship);
    this.diseases = StringField.fromFirestore(data.diseases);
    this.death = StringField.fromFirestore(data.death);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.kinship = StringField.toFirestore(this.kinship);
    firestoreData.diseases = StringField.toFirestore(this.diseases);
    firestoreData.death = StringField.toFirestore(this.death);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): FamilyHistoryItem {
    this.id = DataHelpers.uniqueId();

    this.kinship = StringField.fromOfflineCache(data.kinship);
    this.diseases = StringField.fromOfflineCache(data.diseases);
    this.death = StringField.fromOfflineCache(data.death);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.kinship = StringField.toOfflineCache(this.kinship);
    firestoreData.diseases = StringField.toOfflineCache(this.diseases);
    firestoreData.death = StringField.toOfflineCache(this.death);

    return firestoreData;
  }
}
