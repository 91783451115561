import { DocumentReference } from "firebase/firestore";

import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { HintContext } from "./HintContext";

import { EnumField, NumberWithDefaultField, StringField } from "@/core/fields";

export class Hint extends FirestoreOfflineDocument {
  public context: HintContext = HintContext.EndingMeasures;
  public text: string | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Hint {
    super.fromFirestore(data, id, firestoreRef);

    this.context = EnumField.fromFirestore<HintContext>(data.context, Object.values(HintContext), HintContext.EndingMeasures);
    this.text = StringField.fromFirestore(data.text);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.context = EnumField.toFirestore<HintContext>(this.context, HintContext.EndingMeasures);
    firestoreData.text = StringField.toFirestore(this.text);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): Hint {
    super.fromOfflineCache(data);

    this.context = EnumField.fromOfflineCache<HintContext>(data.context, Object.values(HintContext), HintContext.EndingMeasures);
    this.text = StringField.fromOfflineCache(data.text);
    this.order = NumberWithDefaultField.fromOfflineCache(data.order, 999);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.context = EnumField.toOfflineCache<HintContext>(this.context, HintContext.EndingMeasures);
    firestoreData.text = StringField.toOfflineCache(this.text);
    firestoreData.order = NumberWithDefaultField.toOfflineCache(this.order, 999);

    return firestoreData;
  }
}
