export const it = {
  doctorAgenda: {
    doctorAgenda: "Agenda medico",
  },
  navigation: {
    doctorAgenda: "Agenda",
  },
  toast: {
    error: {
      doctorAgendaEventsLoad: "Errore nel caricamento degli eventi",
    },
    success: {},
    warning: {},
  },
};
