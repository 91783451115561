import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "profile-menu fade-in-up" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "pi pi-circle-on"
}
const _hoisted_4 = {
  key: 1,
  class: "pi pi-circle-off"
}
const _hoisted_5 = {
  key: 2,
  class: "text-bold"
}
const _hoisted_6 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themes, (theme) => {
      return (_openBlock(), _createElementBlock("li", {
        key: theme.name
      }, [
        _createElementVNode("a", {
          onClick: ($event: any) => (_ctx.selectTheme(theme))
        }, [
          (_ctx.selectedTheme.name == theme.name)
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : (_openBlock(), _createElementBlock("i", _hoisted_4)),
          (_ctx.selectedTheme.name == theme.name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t("themes." + theme.name)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t("themes." + theme.name)), 1))
        ], 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}