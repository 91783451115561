import { CompanyDuty } from "@/features/modules/companyDuty/objects/CompanyDuty";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { EmployeeDuty } from "../objects/EmployeeDuty";
import { employeeDutyOfflineModel } from "./EmployeeDutyOfflineModel";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import { createEmployeeDuty, getEmployeeDutiesByCompany, isPeriodAvailableByCompany, setPersonalDuty } from "./methods";

export class EmployeeDutyModel extends FirestoreModel<EmployeeDuty> {
  public constructor() {
    super(() => new EmployeeDuty(), "employeeDuties", LockPolicy.DiscardUnsyncedChanges, "employeeDuty");
    this.parentCollectionName = "employees";
    this.offlineModuleModel = employeeDutyOfflineModel;
  }

  public async getDocuments(sortCriterias: SortCriteria[], parentId?: string): Promise<EmployeeDuty[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (user.role?.name === "Medico") {
      if (parentId == undefined) throw new Error("parentId is undefined");
      const doctor: Doctor | undefined = await doctorModel.getDoctorFromUser(user.id);
      const employee: Employee = await employeeModel.getDocument(parentId);
      const employeeCompanies: string[] = Object.keys(employee.companies);
      if (doctor === undefined || doctor.authorizedCompaniesIds.filter((id) => employeeCompanies.includes(id)).length === 0)
        throw new Error(`User doesn't have rights to read employee #${parentId}`);
    }

    return super.getDocuments(sortCriterias, parentId);
  }

  public async getDocument(employeeDutyId: string, parentId?: string): Promise<EmployeeDuty> {
    return super.getDocument(employeeDutyId, parentId);
  }

  public async createDocument(employeeDuty: EmployeeDuty, parentId?: string): Promise<string> {
    return createEmployeeDuty(employeeDuty, parentId);
  }

  public async updateDocument(employeeDuty: EmployeeDuty, parentId?: string): Promise<void> {
    return super.updateDocument(employeeDuty, parentId);
  }

  public async deleteDocument(employeeDuty: EmployeeDuty, parentId?: string): Promise<boolean> {
    return super.deleteDocument(employeeDuty, parentId);
  }

  public getEmployeeDutiesByCompany(companyId: string): Promise<EmployeeDuty[]> {
    return getEmployeeDutiesByCompany(companyId);
  }

  public async isPeriodAvailableByCompany(
    employeeId: string,
    from: Date | undefined,
    to: Date | undefined,
    employeeDutyId?: string,
    companyId?: string
  ): Promise<boolean> {
    return isPeriodAvailableByCompany(employeeId, from, to, employeeDutyId, companyId);
  }

  public setPersonalDuty(employeeDuty: EmployeeDuty, companyDuties: CompanyDuty[]): void {
    return setPersonalDuty(employeeDuty, companyDuties);
  }
}

export const employeeDutyModel: EmployeeDutyModel = new EmployeeDutyModel();
