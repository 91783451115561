import { getDocs, Query, QuerySnapshot } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocType } from "@/features/modules/docType/objects/DocType";
import { docTypeModel } from "../DocTypeModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getVisibleDocTypesBySource = async (source: DocTypeSource): Promise<DocType[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (docTypeModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: DocType[] = (await offlineModel.getCacheByCollection(docTypeModel.offlineModuleModel)) as DocType[];
      return sortDocuments(documents.filter((docType) => docType.visibleFor.includes(source)));
    }

    let snapshotQuery: Query<DocType> = docTypeModel.getPathReference().withConverter(docTypeModel.firestoreConverter);

    snapshotQuery = docTypeModel.addReadConditionToQuery(snapshotQuery);

    const snapshot: QuerySnapshot<DocType> = await getDocs(snapshotQuery);

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()).filter((docType) => docType.visibleFor.includes(source)));
  } catch (error: unknown) {
    appFaultModel.catchAppError("DocTypeModel.getVisibleDocTypesBySource", { source }, error);
    return [];
  }
};

function sortDocuments(documents: DocType[]): DocType[] {
  const sorter: FirestoreSorter<DocType> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
