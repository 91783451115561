import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { CompanyPriceList } from "@/features/modules/companyPriceList/objects/CompanyPriceList";
import { companyPriceListModel } from "../CompanyPriceListModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { firmModel } from "@/features/modules/firm/models/FirmModel";

export const getCompanyPriceListsByCompanyAndFirm = async (companyId: string): Promise<CompanyPriceList[]> => {
  try {
    const firmId: string = firmModel.getSelectedFirmId();

    const snapshotQuery: Query<CompanyPriceList> = query(
      companyPriceListModel.getPathReference(companyId).withConverter(companyPriceListModel.firestoreConverter),
      where("firm.id", "==", firmId)
    );

    const snapshot: QuerySnapshot<CompanyPriceList> = await getDocs(companyPriceListModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("CompanyPriceListModel.getCompanyPriceListsByCompanyAndFirm", { companyId }, error);
    return [];
  }
};

function sortDocuments(documents: CompanyPriceList[]): CompanyPriceList[] {
  const sorter: FirestoreSorter<CompanyPriceList> = new FirestoreSorter(documents);
  sorter.addSortCriteria("fromSort", "asc", "string");
  return sorter.sort();
}
