import { DataHelpers } from "@/core/modules/helpers/DataHelpers";

export class ContractExpiration {
  public id: string = DataHelpers.uniqueId();
  public companyId: string;
  public companyName: string;
  public expiration: Date | undefined;

  public constructor(companyId: string, companyName: string, expiration: Date | undefined) {
    this.companyId = companyId;
    this.companyName = companyName;
    this.expiration = expiration;
  }
}
