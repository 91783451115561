export const en = {
  guide: {
    browse: "Browse available guides",
    description: "Description",
    descriptionError: "Enter a description",
    guide: "Guide",
    guides: "Guides",
    isEnabled: "Enabled",
    isEnabledError: "Select a value",
    noGuides: "No guides available",
    title: "Title",
    titleError: "Enter a title",
  },
  navigation: {
    guide: "Guides",
  },
  toast: {
    error: {
      guideFileError: "Error while reading the file",
      guideFileUndefined: "File not found",
    },
    success: {},
    warning: {},
  },
};
