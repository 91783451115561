export const fr = {
  notification: {
    alert: "Vous avez {count} nouvelle notification | Vous avez {count} nouvelles notifications",
    none: "Aucune notification à lire",
    notifications: "Notifications",
    test: {
      groupName: "Groupe de test",
      message: "Notification de test",
    },
  },
  buttons: {
    notificationGoToUrl: "Aller à la page",
  },
};
