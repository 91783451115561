import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { User } from "../objects/User";
import { userModel } from "../models/UserModel";

export const userSearch: SearchModule = new SearchModule(
  "user.user",
  (firestoreDocument: FirestoreDocument) => Promise.resolve((firestoreDocument as User).fullName ?? "-"),
  (firestoreDocument: FirestoreDocument) => `/users/${firestoreDocument.id}/edit`,
  "id-card",
  userModel,
  [new SortCriteria("fullName", "asc", "string")]
);
