import { CompanyDuty } from "./CompanyDuty";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";

import { StringField } from "@/core/fields";

export class LinkedCompanyDuty extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromCompanyDuty(companyDuty: CompanyDuty): LinkedCompanyDuty {
    const linkedCompanyDuty: LinkedCompanyDuty = new LinkedCompanyDuty();
    linkedCompanyDuty.id = companyDuty.id;
    linkedCompanyDuty.name = companyDuty.name;

    return linkedCompanyDuty;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedCompanyDuty {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedCompanyDuty {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);

    return firestoreData;
  }
}
