import { getDocs, Query, query, QuerySnapshot, Timestamp, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { ExternalTest } from "@/features/modules/externalTest/objects/ExternalTest";
import { externalTestModel } from "../ExternalTestModel";
import { firmModel } from "@/features/modules/firm/models/FirmModel";

export const getExternalTestsWithFilters = async (
  startDate: Date,
  endDate: Date,
  companyId: string,
  supplierId: string,
  employeeId: string,
  state: string,
  invoiced: string
): Promise<ExternalTest[]> => {
  try {
    if (startDate == undefined) throw new Error("startDate is undefined");
    if (endDate == undefined) throw new Error("endDate is undefined");

    const firmId: string = firmModel.getSelectedFirmId();

    let snapshotQuery: Query<ExternalTest> = query(
      externalTestModel.getPathReference().withConverter(externalTestModel.firestoreConverter),
      where("date", ">=", Timestamp.fromDate(startDate)),
      where("date", "<=", Timestamp.fromDate(endDate)),
      where("firm.id", "==", firmId)
    );

    if (companyId !== "all") snapshotQuery = query(snapshotQuery, where("company.id", "==", companyId));
    if (supplierId !== "all") snapshotQuery = query(snapshotQuery, where("supplier.id", "==", supplierId));
    if (employeeId !== "all") snapshotQuery = query(snapshotQuery, where("employee.id", "==", employeeId));
    if (state !== "all") snapshotQuery = query(snapshotQuery, where("state", "==", state));
    if (invoiced !== "all" && invoiced === "no") {
      snapshotQuery = query(snapshotQuery, where("invoice", "==", null));
    }

    const snapshot: QuerySnapshot<ExternalTest> = await getDocs(externalTestModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    if (invoiced !== "all" && invoiced === "yes") {
      return snapshot.docs.map((doc) => doc.data()).filter((externalTest) => externalTest.invoice !== undefined);
    }

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExternalTestModel.getExternalTestsWithFilters", { startDate, endDate, companyId, supplierId, employeeId }, error);
    return [];
  }
};
