export const en = {
  profile: {
    changeEmail: "Change E-mail",
    changePassword: "Change Password",
    changePasswordButton: "Change Password",
    email: "E-mail",
    oldPassword: "Old Password",
    oldPasswordBlank: "Insert the old password",
    newEmail: "New E-mail Address",
    newEmailError: "Insert a valid e-mail address",
    newPassword: "New Password",
    newPasswordBlank: "Insert the new password",
  },
};
