import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "../EmployeeModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const getEmployeesByBranch = async (branchId: string): Promise<Employee[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (employeeModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: Employee[] = (await offlineModel.getCacheByCollection(employeeModel.offlineModuleModel)) as Employee[];
      return sortDocuments(documents.filter((employee) => branchId in employee.branches));
    }

    const snapshotQuery: Query<Employee> = query(
      employeeModel.getPathReference().withConverter(employeeModel.firestoreConverter),
      where(`branches.${branchId}.id`, "==", branchId)
    );

    const snapshot: QuerySnapshot<Employee> = await getDocs(employeeModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeModel.getEmployeesByBranch", { branchId }, error);
    return [];
  }
};

function sortDocuments(documents: Employee[]): Employee[] {
  const sorter: FirestoreSorter<Employee> = new FirestoreSorter(documents);
  sorter.addSortCriteria("fullName", "asc", "string");
  return sorter.sort();
}
