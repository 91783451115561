import { RouteRecordRaw } from "vue-router";

import { EmptyState } from "@/core/modules/store/interfaces/EmptyState";
import { LocaleHelpers } from "@/core/modules/locale/helpers/LocaleHelpers";
import { LocaleModule } from "@/core/modules/locale/objects/LocaleModule";
import { NotificationHelpers } from "@/core/modules/notification/helpers/NotificationHelpers";
import { NotificationModule } from "@/core/modules/notification/objects/NotificationModule";
import { OfflineHelpers } from "@/core/modules/offline/helpers/OfflineHelpers";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { RouterHelpers } from "@/core/modules/router/helpers/RouterHelpers";
import { SearchHelpers } from "@/core/modules/search/helpers/SearchHelpers";
import { SearchModule } from "@/core/modules/search/objects/SearchModule";
import { StatHelpers } from "@/core/modules/stat/helpers/StatHelpers";
import { StatModule } from "@/core/modules/stat/objects/StatModule";
import { StoreHelpers } from "@/core/modules/store/helpers/StoreHelpers";
import { StoreModule } from "@/core/modules/store/objects/StoreModule";
import { UtilityHelpers } from "@/core/modules/utility/helpers/UtilityHelpers";
import { UtilityModule } from "@/core/modules/utility/objects/UtilityModule";

import { coreLocales } from "@/core/boot/coreLocales";
import { coreNotificationModules } from "@/core/boot/coreNotificationModules";
import { coreOfflineModules } from "@/core/boot/coreOfflineModules";
import { coreRoutes } from "@/core/boot/coreRoutes";
import { coreSearchModules } from "@/core/boot/coreSearchModules";
import { coreStatModules } from "@/core/boot/coreStatModules";
import { coreStoreModules } from "@/core/boot/coreStoreModules";
import { coreUtilityModules } from "@/core/boot/coreUtilityModules";
import { featuresLocales } from "@/features/boot/featuresLocales";
import { featuresNotificationModules } from "@/features/boot/featuresNotificationModules";
import { featuresOfflineModules } from "@/features/boot/featuresOfflineModules";
import { featuresRoutes } from "@/features/boot/featuresRoutes";
import { featuresSearchModules } from "@/features/boot/featuresSearchModules";
import { featuresStatModules } from "@/features/boot/featuresStatModules";
import { featuresStoreModules } from "@/features/boot/featuresStoreModules";
import { featuresUtilityModules } from "@/features/boot/featuresUtilityModules";

export class Boot {
  private locales: LocaleModule[] = [];
  private notificationModules: NotificationModule[] = [];
  private offlineModules: OfflineModuleModel<FirestoreOfflineDocument>[] = [];
  private routes: RouteRecordRaw[] = [];
  private searchModules: SearchModule[] = [];
  private statModules: StatModule[] = [];
  private storeModules: StoreModule<EmptyState>[] = [];
  private utilityModules: UtilityModule[] = [];

  public constructor() {
    this.locales = [...coreLocales, ...featuresLocales];
    this.notificationModules = [...coreNotificationModules, ...featuresNotificationModules];
    this.offlineModules = [...coreOfflineModules, ...featuresOfflineModules];
    this.routes = [...coreRoutes, ...featuresRoutes];
    this.searchModules = [...coreSearchModules, ...featuresSearchModules];
    this.statModules = [...coreStatModules, ...featuresStatModules];
    this.storeModules = [...coreStoreModules, ...featuresStoreModules];
    this.utilityModules = [...coreUtilityModules, ...featuresUtilityModules];
  }

  public start(): void {
    this.registerRoutes();
    this.registerStoreModules();
    this.registerLocales();
    this.registerNotificationModules();
    this.registerSearchModules();
    this.registerStatModules();
    this.registerUtilityModules();
    this.registerOfflineModules();
  }

  private registerLocales(): void {
    for (const locale of this.locales) {
      LocaleHelpers.registerModule(locale);
    }
  }

  private registerNotificationModules(): void {
    for (const notificationModule of this.notificationModules) {
      NotificationHelpers.registerModule(notificationModule);
    }
  }

  private registerOfflineModules(): void {
    for (const offlineModule of this.offlineModules) {
      OfflineHelpers.registerModule(offlineModule);
    }
  }

  private registerRoutes(): void {
    for (const route of this.routes) {
      RouterHelpers.registerRoute(route);
    }
  }

  private registerSearchModules(): void {
    for (const searchModule of this.searchModules) {
      SearchHelpers.registerModule(searchModule);
    }
  }

  private registerStatModules(): void {
    for (const statModule of this.statModules) {
      StatHelpers.registerModule(statModule);
    }
  }

  private registerStoreModules(): void {
    for (const storeModule of this.storeModules) {
      StoreHelpers.registerModule(storeModule);
    }
  }

  private registerUtilityModules(): void {
    for (const utilityModule of this.utilityModules) {
      UtilityHelpers.registerModule(utilityModule);
    }
  }
}
