import { RouteRecordRaw } from "vue-router";

export const doctorRoutes: RouteRecordRaw[] = [
  {
    path: "/doctors",
    name: "DoctorList",
    component: () => import("../views/DoctorList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doctor",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/doctors/:id/edit",
    name: "DoctorEdit",
    component: () => import("../views/DoctorEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doctor",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/doctors/:id/:section?",
    name: "DoctorView",
    component: () => import("../views/DoctorView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "doctor",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/doctors/:id/draft-examinations",
    name: "DraftExaminationsByDoctorList",
    component: () => import("@/features/modules/examination/widgets/ExaminationListWidget.vue"),
    props: { type: "draft", who: "doctor" },
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/doctors/:id/last-examinations",
    name: "LastExaminationsByDoctorList",
    component: () => import("@/features/modules/examination/widgets/ExaminationListWidget.vue"),
    props: { type: "last", who: "doctor" },
    meta: {
      requiresAuth: true,
      requiredModule: "examination",
      requiredRight: "viewUI",
    },
  },
];
