import { RouteRecordRaw } from "vue-router";

import { appFaultRoutes } from "@/core/modules/appFault/router/routes";
import { authRoutes } from "@/core/modules/auth/router/routes";
import { backupRoutes } from "@/core/modules/backup/router/routes";
import { changelogRoutes } from "@/core/modules/changelog/router/routes";
import { cloudFaultRoutes } from "@/core/modules/cloudFault/router/routes";
import { feedbackRoutes } from "@/core/modules/feedback/router/routes";
import { guideRoutes } from "@/core/modules/guide/router/routes";
import { notificationRoutes } from "@/core/modules/notification/router/routes";
import { offlineRoutes } from "@/core/modules/offline/router/routes";
import { profileRoutes } from "@/core/modules/profile/router/routes";
import { roleRoutes } from "@/core/modules/role/router/routes";
import { searchRoutes } from "@/core/modules/search/router/routes";
import { statRoutes } from "@/core/modules/stat/router/routes";
import { userRoutes } from "@/core/modules/user/router/routes";
import { utilityRoutes } from "@/core/modules/utility/router/routes";
import { whoRoutes } from "@/core/modules/who/router/routes";

export const coreRoutes: RouteRecordRaw[] = [
  ...appFaultRoutes,
  ...authRoutes,
  ...backupRoutes,
  ...changelogRoutes,
  ...cloudFaultRoutes,
  ...feedbackRoutes,
  ...guideRoutes,
  ...notificationRoutes,
  ...offlineRoutes,
  ...profileRoutes,
  ...roleRoutes,
  ...searchRoutes,
  ...statRoutes,
  ...userRoutes,
  ...utilityRoutes,
  ...whoRoutes,
];
