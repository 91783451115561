export const de = {
  changelog: {
    addItem: "Element hinzufügen",
    changelog: "Versionshinweise",
    date: "Datum",
    dateError: "Bitte geben Sie ein gültiges Datum ein",
    empty: "Keine Versionshinweise zum Anzeigen",
    item: "Artikel",
    itemAdded: "Hinzugefügt",
    itemChanged: "Geändert",
    itemFixed: "Richtig",
    itemRemoved: "Entfernt",
    newItem: "Neuer Artikel",
    public: "Öffentlich",
    release: "Freigeben",
    releasedOn: "Freigegeben",
    tagGeneral: "Allgemein",
    version: "Version",
    versionError: "Geben Sie die Version ein",
  },
  navigation: {
    changelog: "Versionshinweise",
  },
};
