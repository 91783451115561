import { Ref } from "vue";

import { Examination } from "@/features/modules/examination/objects/Examination";
import { JudgmentState } from "@/features/modules/examination/objects/JudgmentState";
import { NotExpressedType } from "@/features/modules/examination/objects/NotExpressedType";

export function judgmentChanged(examinationRef: Ref<Examination>, lastJudgment: JudgmentState, fromLastJudgment = false): JudgmentState {
  if (examinationRef.value.ending.judgment === JudgmentState.NotExpressed || examinationRef.value.ending.judgment === JudgmentState.NotEvaluated) {
    examinationRef.value.ending.judgmentNotes = undefined;
  }
  if (examinationRef.value.ending.judgment !== JudgmentState.NotExpressed) {
    examinationRef.value.ending.notExpressedType = NotExpressedType.None;
  }
  if (
    examinationRef.value.ending.judgment !== JudgmentState.FitWithPrescriptions &&
    examinationRef.value.ending.judgment !== JudgmentState.FitWithPrescriptionsAndLimitations &&
    examinationRef.value.ending.judgment !== JudgmentState.FitWithLenses
  ) {
    examinationRef.value.ending.prescriptions = {};
    examinationRef.value.ending.prescriptionsNotes = undefined;
  }

  if (
    examinationRef.value.ending.judgment !== JudgmentState.FitWithLimitations &&
    examinationRef.value.ending.judgment !== JudgmentState.FitWithPrescriptionsAndLimitations &&
    examinationRef.value.ending.judgment !== JudgmentState.FitWithLenses
  ) {
    examinationRef.value.ending.limitations = {};
  }

  if (examinationRef.value.ending.judgment === undefined || examinationRef.value.ending.judgment === JudgmentState.NotEvaluated) {
    examinationRef.value.ending.judgmentDate = undefined;
    lastJudgment = examinationRef.value.ending.judgment;
    return lastJudgment;
  }

  examinationRef.value.ending.judgmentDate = new Date();
  if (lastJudgment === JudgmentState.NotEvaluated || fromLastJudgment === true) {
    /* don't chage examination date anymore
    examinationRef.value.date = new Date();
    examinationRef.value.date.setHours(0, 0, 0, 0);
    examinationRef.value.time?.setDate(examinationRef.value.date.getDate());
    examinationRef.value.time?.setMonth(examinationRef.value.date.getMonth());
    examinationRef.value.time?.setFullYear(examinationRef.value.date.getFullYear());
    */
    examinationRef.value.ending.companyDate = new Date();
    examinationRef.value.ending.companyDate.setHours(0, 0, 0, 0);
    examinationRef.value.ending.employeeDate = new Date();
    examinationRef.value.ending.employeeDate.setHours(0, 0, 0, 0);
  }

  lastJudgment = examinationRef.value.ending.judgment;

  return lastJudgment;
}
