import { Company } from "./Company";
import { CompanyType } from "./CompanyType";
import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { LinkedCompany } from "./LinkedCompany";
import { LinkedEmployeeDuty } from "@/features/modules/employeeDuty/objects/LinkedEmployeeDuty";
import { LinkedFirestoreOfflineDocument } from "@/core/modules/firestore/objects/LinkedFirestoreOfflineDocument";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";

import { ArrayByKeyField, BooleanField, DateField, EnumField, StringField } from "@/core/fields";

export class LinkedCompanyWithStatus extends LinkedFirestoreOfflineDocument {
  public name: string | undefined = undefined;
  public type: CompanyType = CompanyType.Private;
  public activeDuties: Record<string, LinkedEmployeeDuty> = {};
  public isActive = true;
  public hasDraftExamination = false;
  public isArchived: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromCompany(company: Company): LinkedCompanyWithStatus {
    const linkedCompanyWithStatus: LinkedCompanyWithStatus = new LinkedCompanyWithStatus();
    linkedCompanyWithStatus.id = company.id;
    linkedCompanyWithStatus.name = company.name;
    linkedCompanyWithStatus.type = company.type;

    return linkedCompanyWithStatus;
  }

  public static createFromLinkedCompany(linkedCompany: LinkedCompany): LinkedCompanyWithStatus {
    const linkedCompanyWithStatus: LinkedCompanyWithStatus = new LinkedCompanyWithStatus();
    linkedCompanyWithStatus.id = linkedCompany.id;
    linkedCompanyWithStatus.name = linkedCompany.name;
    linkedCompanyWithStatus.type = linkedCompany.type;

    return linkedCompanyWithStatus;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedCompanyWithStatus {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.type = EnumField.fromFirestore<CompanyType>(data.type, Object.values(CompanyType), CompanyType.Private);
    this.activeDuties = ArrayByKeyField.fromFirestore<LinkedEmployeeDuty>(data.activeDuties, (value) => new LinkedEmployeeDuty(value));
    this.isActive = BooleanField.fromFirestore(data.isActive);
    this.hasDraftExamination = BooleanField.fromFirestore(data.hasDraftExamination);
    this.isArchived = DateField.fromFirestore(data.isArchived);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.type = EnumField.toFirestore<CompanyType>(this.type, CompanyType.Private);
    firestoreData.activeDuties = ArrayByKeyField.toFirestore<LinkedEmployeeDuty>(this.activeDuties, (value) => value.toFirestore());
    firestoreData.isActive = BooleanField.toFirestore(this.isActive);
    firestoreData.hasDraftExamination = BooleanField.toFirestore(this.hasDraftExamination);
    firestoreData.isArchived = DateField.toFirestore(this.isArchived);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): LinkedCompanyWithStatus {
    super.fromOfflineCache(data);

    this.name = StringField.fromOfflineCache(data.name);
    this.type = EnumField.fromOfflineCache<CompanyType>(data.type, Object.values(CompanyType), CompanyType.Private);
    this.activeDuties = ArrayByKeyField.fromOfflineCache<LinkedEmployeeDuty>(data.activeDuties, (value) => new LinkedEmployeeDuty(value));
    this.isActive = BooleanField.fromOfflineCache(data.isActive);
    this.hasDraftExamination = BooleanField.fromOfflineCache(data.hasDraftExamination);
    this.isArchived = DateField.fromOfflineCache(data.isArchived);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.name = StringField.toOfflineCache(this.name);
    firestoreData.type = EnumField.toOfflineCache<CompanyType>(this.type, CompanyType.Private);
    firestoreData.activeDuties = ArrayByKeyField.toOfflineCache<LinkedEmployeeDuty>(this.activeDuties, (value) => value.toOfflineCache());
    firestoreData.isActive = BooleanField.toOfflineCache(this.isActive);
    firestoreData.hasDraftExamination = BooleanField.toOfflineCache(this.hasDraftExamination);
    firestoreData.isArchived = DateField.toOfflineCache(this.isArchived);

    return firestoreData;
  }

  public getActiveDuties(): LinkedEmployeeDuty[] {
    return DataHelpers.objectToSortedArray<LinkedEmployeeDuty>(this.activeDuties, new SortCriteria("name", "asc", "string"));
  }

  public setActiveDuties(linkedEmployeeDuties: LinkedEmployeeDuty[]): void {
    this.activeDuties = DataHelpers.sortedArrayToObject<LinkedEmployeeDuty>(linkedEmployeeDuties);
  }

  public addActiveDuty(linkedEmployeeDuty: LinkedEmployeeDuty): void {
    this.activeDuties[linkedEmployeeDuty.id] = linkedEmployeeDuty;
  }

  public removeActiveDuty(linkedEmployeeDuty: LinkedEmployeeDuty): void {
    delete this.activeDuties[linkedEmployeeDuty.id];
  }
}
