import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { GasSurveyForm } from "../objects/GasSurveyForm";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";

export class GasSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private gasSurveyForm: GasSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    gasSurveyForm: GasSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.gasSurveyForm = gasSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      gasQuestionLabel: { color: this.textColor, fontSize: 10, lineHeight: 1.2 },
      gasQuestionValue: { color: this.textColor, bold: true, fontSize: 10 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("CERTIFICATO MEDICO PER L'IDONEITÀ FISICA ALL'IMPIEGO DI GAS TOSSICI", isContinuos);

    await this.writeEmployeeData();

    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question1"), this.gasSurveyForm.question1);
    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question2"), this.gasSurveyForm.question2);
    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question3"), this.gasSurveyForm.question3);
    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question4"), this.gasSurveyForm.question4);
    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question5"), this.gasSurveyForm.question5);

    this.writeSubtitle(this.t("surveyForm.gasSurveyForm.title1"));

    this.writeLeftRightQuestion(this.t("surveyForm.gasSurveyForm.question6"), this.gasSurveyForm.question6l, this.gasSurveyForm.question6r);
    this.writeLeftRightQuestion(this.t("surveyForm.gasSurveyForm.question7"), this.gasSurveyForm.question7l, this.gasSurveyForm.question7r);
    this.writeLeftRightQuestion(this.t("surveyForm.gasSurveyForm.question8"), this.gasSurveyForm.question8l, this.gasSurveyForm.question8r);

    this.writeSubtitle("");

    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question9"), this.gasSurveyForm.question9);
    this.writeBooleanQuestion(this.t("surveyForm.gasSurveyForm.question10"), this.gasSurveyForm.question10);

    this.writeSubtitle("");

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeBooleanQuestion(question: string, answer: boolean): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(150),
          text: question,
          style: "gasQuestionLabel",
        },
        {
          margin: [DataHelpers.mmToPoints(5), DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: answer === true ? this.t("gen.yes").toLocaleUpperCase() : this.t("gen.no").toLocaleUpperCase(),
          style: "gasQuestionValue",
        },
      ],
    });
  }

  private writeLeftRightQuestion(question: string, answerLeft: string | undefined, answerRight: string | undefined): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(90),
          text: question,
          style: "gasQuestionLabel",
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.t("surveyForm.gasSurveyForm.left"),
          style: "gasQuestionLabel",
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: answerLeft ?? "-",
          style: "gasQuestionValue",
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: this.t("surveyForm.gasSurveyForm.right"),
          style: "gasQuestionLabel",
        },
        {
          margin: [0, DataHelpers.mmToPoints(2), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: answerRight ?? "-",
          style: "gasQuestionValue",
        },
      ],
    });
  }
}
