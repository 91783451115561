export const it = {
  companyDuty: {
    companyDuties: "Mansioni",
    companyDutiesOf: "Mansioni di {parent}",
    companyDuty: "Mansione",
    companyDutyOf: "Mansione di {parent}",
    companyDutyPaperTitle: "Dettaglio mansione",
    doctorSignature: "Il medico competente",
    filters: {
      archived: "Mansioni archiviate",
      archivedExcluded: "Archiviate escluse",
      archivedIncluded: "Archiviate incluse",
    },
    frequency: "Periodicità",
    frequencyError: "Selezionare una periodicità",
    healthProtocolPaperTitle: "Protocollo di sorveglianza sanitaria",
    importDuty: "Importa mansione",
    name: "Nome",
    nameError: "Inserire un nome valido",
    notes: "Note",
    risks: "Rischi",
    state: "Stato",
    stateError: "Seleziona lo stato",
    states: {
      active: "Attiva",
      archived: "Archiviata",
      archivedOn: "Archiviata il { date }",
    },
    surveys: "Questionari",
    testTypes: "Accertamenti",
  },
  buttons: {
    archiveCompanyDuty: "Metti in archivio",
    restoreCompanyDuty: "Togli dall'archivio",
    printHealthProtocol: "Stampa protocollo sanitario",
    saveAndCascadeUpdateCompanyDuty: "Salva e aggiorna lavoratori",
  },
  toast: {
    error: {
      importDutyNotFound: "Mansione non trovata",
    },
    success: {},
    warning: {},
  },
};
