export const it = {
  priceList: {
    addPayment: "Aggiungi pagamento",
    addPriceItem: "Aggiungi prezzo",
    amount: "Quantità",
    amountError: "Inserire una quantità valida",
    clonedPriceListName: "Copia di {name}",
    days: "giorni",
    daysToExpiration: "Scadenza",
    daysToExpirationError: "Inserire un valore di scadenza valido",
    discountValue: "Sconto percentuale",
    discountValueError: "Inserire un valore di sconto valido",
    editItem: "Modifica elemento",
    examination: "Visita medica",
    isVatApplied: "IVA applicata",
    isVatAppliedError: "Selezionare un valore",
    itemName: "Descrizione",
    itemNameError: "Inserire una descrizione valida",
    items: "Elementi",
    itemsError: "Selezionare almeno un elemento",
    missingElements: "Elementi mancanti",
    mode: "Modalità",
    modeError: "Selezionare una modalità",
    modes: {
      all: "Tutti",
      any: "Almeno uno",
      group: "Gruppo",
    },
    name: "Nome",
    nameError: "Inserire un nome valido",
    payments: "Pagamenti",
    percentSumError: "La somma delle percentuali di pagamento deve essere pari a 100%",
    priceList: "Listino prezzi",
    priceLists: "Listini prezzi",
    price: "Prezzo",
    priceError: "Inserire un prezzo valido",
    prices: "Prezzi",
    type: "Categoria",
    typeError: "Selezionare una categoria",
    types: {
      examType: "Visite mediche",
      serviceType: "Prestazioni",
      survey: "Questionari",
      testType: "Accertamenti",
    },
    vat: "IVA",
    yearlyPaymentType: "Tipo annualità",
    yearlyPaymentTypeError: "Selezionare un valore valido",
    yearlyPaymentTypes: {
      none: "Nessuna",
      lumpSum: "Forfettaria",
      byFirstExamination: "Alla prima visita",
    },
    yearlyPaymentValue: "Valore annualità",
    yearlyPaymentValueError: "Inserire un valore valido",
  },
  buttons: {
    clonePriceList: "Duplica listino prezzi",
  },
  navigation: {
    priceList: "Listini prezzi",
  },
};
