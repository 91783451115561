import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";
import { storageModel } from "../StorageModel";

export const getBase64 = async (path: string, name: string): Promise<string> => {
  try {
    const url: string = await storageModel.getDownloadLink(path, name);
    const blob = await StorageHelpers.fetchAsBlob(url);
    const doubleBase64EncodedFile: string = (await StorageHelpers.blobToBase64(blob)) as string;
    return doubleBase64EncodedFile;
  } catch (error: unknown) {
    appFaultModel.catchAppError("StorageModel.getBase64", { path, name }, error);
    return "ERROR";
  }
};
