import { getDownloadURL, ref } from "firebase/storage";

import { firebase } from "@/core/modules/firebase/objects/Firebase";

export const fileExists = async (path: string, name: string): Promise<boolean> => {
  try {
    await getDownloadURL(ref(firebase.storage, `${path}/${name}`));
    return true;
  } catch (error: unknown) {
    return false;
  }
};
