import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Branch } from "../objects/Branch";
import { branchModel } from "./BranchModel";
import { Company } from "@/features/modules/company/objects/Company";
import { companyModel } from "@/features/modules/company/models/CompanyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";

export class BranchOfflineModel extends OfflineModuleModel<Branch> {
  public constructor() {
    super(() => new Branch(), "branches", "branch.branches", "branch");
  }

  public async downloadCache(): Promise<Branch[]> {
    try {
      if (companyModel.offlineModuleModel === undefined) return [];
      const companies: Company[] = (await offlineModel.getCacheByCollection(companyModel.offlineModuleModel)) as Company[];

      let branches: Branch[] = [];
      for (const company of companies) {
        branches = [...branches, ...(await branchModel.getBranchesByCompany(company.id))];
      }

      return branches;
    } catch (error: unknown) {
      appFaultModel.catchAppError("BranchOfflineModel.downloadCache", {}, error);
      return [];
    }
  }
}

export const branchOfflineModel: BranchOfflineModel = new BranchOfflineModel();
