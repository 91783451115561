import { RouteRecordRaw } from "vue-router";

export const optionRoutes: RouteRecordRaw[] = [
  {
    path: "/options",
    name: "OptionEdit",
    component: () => import("../views/OptionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "option",
      requiredRight: "viewUI",
    },
  },
];
