import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { Theme } from "@/core/modules/theme/objects/Theme";
import { themeModel } from "../ThemeModel";
import { User } from "@/core/modules/user/objects/User";

export const getSelectedTheme = (): Theme => {
  const user: User = store.getters[storeTypes.getters.getUser];

  const defaultTheme: Theme = themeModel.getThemes()[0];

  if (user === undefined) return defaultTheme;

  const selectedTheme: Theme | undefined = themeModel.getThemes().find((theme: Theme) => theme.name === user.detail.theme);

  if (selectedTheme === undefined) return defaultTheme;

  return selectedTheme;
};
