import { DocumentReference, doc } from "firebase/firestore";

import { firebase } from "@/core/modules/firebase/objects/Firebase";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../FirestoreModel";

export const getDocumentReference = <T extends FirestoreDocument>(
  model: FirestoreModel<T>,
  documentId: string,
  parentId?: string
): DocumentReference => {
  let collectionPath: string = model.collectionName;
  if (model.parentCollectionName !== undefined && parentId !== undefined) {
    collectionPath = `${model.parentCollectionName}/${parentId}/${model.collectionName}`;
  }
  return doc(firebase.firestore, `${collectionPath}/${documentId}`);
};
