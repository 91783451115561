import { compareAsc, format, subDays } from "date-fns";
import { Timestamp } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { EmployeeDuty } from "@/features/modules/employeeDuty/objects/EmployeeDuty";
import { employeeDutyModel } from "../EmployeeDutyModel";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const createEmployeeDuty = async (employeeDuty: EmployeeDuty, parentId?: string): Promise<string> => {
  try {
    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (parentId === undefined) throw new Error("employeeIdUndefined");
    if (employeeDuty.company === undefined) throw new Error("companyUndefined");

    const user: User = store.getters[storeTypes.getters.getUser];

    // check if user can create the document
    if (user.canCreate(employeeDutyModel.roleModule) === false)
      throw new Error(`Unable to create document in collection ${employeeDutyModel.collectionName}`);

    employeeDuty.id = employeeDutyModel.getDocumentReference().id;

    employeeDuty.setSearchKeys();
    employeeDuty.setTimestampFields("create");
    batch.set(employeeDutyModel.getDocumentReference(employeeDuty.id, parentId), employeeDuty.toFirestore());

    await closePreviousDuty(employeeDuty, parentId);

    await batch.commit();

    return employeeDuty.id;
  } catch (error: unknown) {
    appFaultModel.catchAppError("EmployeeDutyModel.createEmployeeDuty", { employeeDuty, parentId }, error);
    return "ERROR";
  }
};

async function closePreviousDuty(newEmployeeDuty: EmployeeDuty, parentId: string): Promise<void> {
  if (newEmployeeDuty.company === undefined) return;
  if (newEmployeeDuty.from === undefined) return;

  const employeeDutiesByCompany: EmployeeDuty[] = await employeeDutyModel.getDocuments([], parentId);

  const previousEmployeeDuties: EmployeeDuty[] = employeeDutiesByCompany.filter((employeeDuty: EmployeeDuty) => {
    return (
      employeeDuty.company !== undefined &&
      employeeDuty.company.id === newEmployeeDuty.company?.id &&
      employeeDuty.toSort === "9999-99-99" &&
      newEmployeeDuty.from !== undefined &&
      employeeDuty.from !== undefined &&
      compareAsc(newEmployeeDuty.from, employeeDuty.from) > 0
    );
  });
  if (previousEmployeeDuties.length !== 1) return;

  const previousEmployeeDuty: EmployeeDuty | undefined = employeeDutiesByCompany.find((employeeDuty: EmployeeDuty) => {
    return (
      employeeDuty.company !== undefined &&
      employeeDuty.company.id === newEmployeeDuty.company?.id &&
      employeeDuty.toSort === "9999-99-99" &&
      newEmployeeDuty.from !== undefined &&
      employeeDuty.from !== undefined &&
      compareAsc(newEmployeeDuty.from, employeeDuty.from) > 0
    );
  });
  if (previousEmployeeDuty === undefined) return;

  const previousDutyTo: Date = subDays(new Date(newEmployeeDuty.from.getTime()), 1);
  previousDutyTo.setHours(0, 0, 0, 0);

  batch.update(employeeDutyModel.getDocumentReference(previousEmployeeDuty.id, parentId), {
    to: Timestamp.fromDate(previousDutyTo),
    toSort: format(previousDutyTo, "yyyy-MM-dd"),
  });
}
