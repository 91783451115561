export const de = {
  notification: {
    alert: "Sie haben {count} ungelesene Benachrichtigung | Sie haben {count} ungelesene Benachrichtigungen",
    none: "Keine Benachrichtigungen zum Lesen",
    notifications: "Benachrichtigungen",
    test: {
      groupName: "Testgruppe",
      message: "Testbenachrichtigung",
    },
  },
  buttons: {
    notificationGoToUrl: "Gehe zur Seite",
  },
};
