export class StringField {
  public static fromFirestore(value: unknown): string | undefined {
    if (value === null || value === undefined) return undefined;
    if (String(value).trim().length === 0) return undefined;

    return String(value).trim();
  }

  public static toFirestore(value: string | undefined): string | null {
    if (value === null || value === undefined) return null;
    if (value.trim().length === 0) return null;

    return value.trim();
  }

  public static fromOfflineCache(value: unknown): string | undefined {
    return StringField.fromFirestore(value);
  }

  public static toOfflineCache(value: string | undefined): string | null {
    return StringField.toFirestore(value);
  }
}
