import { AddressField } from "@/core/fields";

export class FormatHelpers {
  /**
   * capitalize the first letter of a string
   * @param string the string to capitalize
   * @returns the capitalized string
   */
  public static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * format an address inline
   * @param address the address
   * @returns the formatted address
   */
  public static formatAddressInline(address: AddressField, withCountry = true): string {
    if (address === undefined) return "-";

    let formatted = "-";
    if (address.street !== undefined) formatted = `${address.street} -`;
    if (address.zipCode !== undefined) formatted += ` ${address.zipCode}`;
    if (address.town !== undefined) formatted += ` ${address.town}`;
    if (address.province !== undefined) formatted += ` (${address.province})`;
    if (address.country !== undefined && withCountry === true) formatted += ` ${address.country}`;
    return formatted.trim();
  }

  /**
   * format an address multiline
   * @param address the address
   * @returns the formatted address
   */
  public static formatAddressMultiline(address: AddressField, withCountry = true): string {
    if (address === undefined) return "-";

    let formatted = "-";
    if (address.street !== undefined) formatted = `${address.street}\n`;
    if (address.zipCode !== undefined) formatted += `${address.zipCode}`;
    if (address.town !== undefined) formatted += ` ${address.town}`;
    if (address.province !== undefined) formatted += ` (${address.province})`;
    if (address.country !== undefined && withCountry === true) formatted += `\n${address.country}`;
    return formatted.trim();
  }

  /**
   * format a file size
   * @param bytes the file size in bytes
   * @param si the si flag
   * @param dp the number of decimal places
   * @returns the formatted file size
   */
  public static formatFileSize(bytes: number, si = true, dp = 2): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) return bytes + " B";
    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + " " + units[u];
  }

  /**
   * transform new lines to br
   * @param text the text to transform
   * @returns the transformed text
   */
  public static nl2br(text: string) {
    if (text == undefined) return "";
    const breakTag = "<br />";
    return (text + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
  }

  /**
   * format a number with given decimal places
   * @param number the number to format
   * @param decimalPlaces the number of decimal places
   * @param decimalSeparator the decimal separator character
   * @returns the formatted number
   */
  public static formatNumber(number: number, decimalPlaces: number, decimalSeparator = ","): string {
    if (number === undefined) return "-";

    const numberString = number.toFixed(decimalPlaces);
    if (decimalSeparator === ".") return numberString;

    return numberString.replace(".", decimalSeparator);
  }
}
