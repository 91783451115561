export const it = {
  employer: {
    addEmployer: "Aggiungi datore di lavoro",
    branch: "Sede",
    branchError: "Selezionare una sede valida",
    company: "Azienda",
    companyError: "Selezionare un'azienda valida",
    companyAndBranch: "Azienda e sede",
    companyAndBranchError: "Selezionare un'azienda e una sede valide",
    contractType: "Tipo di contratto",
    contractTypeError: "Selezionare un tipo di contratto",
    contractTypes: {
      fixedTerm: "Tempo determinato",
      permanent: "Tempo indeterminato",
    },
    employer: "Datore di lavoro",
    employerOf: "Datore di lavoro di {parent}",
    employers: "Datori di lavoro",
    employersOf: "Datori di lavoro di {parent}",
    from: "Inizio",
    fromError: "Selezionare una data di inizio valida",
    healthFolderDeliverDate: "Data consegna fascicolo sanitario",
    healthFolderDeliverDateLabel: "Consegna F.S.",
    noEmployers: "Deve essere inserito e compilato almeno un datore di lavoro",
    registrationNumber: "Matricola",
    to: "Fine",
    toError: "Selezionare una data di fine valida",
  },
};
