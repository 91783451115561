import { NavigationFailure } from "vue-router";

import { router } from "@/core/modules/router/module";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";

export class Navigation {
  public static async navigateTo(route: string): Promise<void | NavigationFailure | undefined> {
    return await router.push(route);
  }

  public static async navigateUndercoverTo(route: string): Promise<void | NavigationFailure | undefined> {
    return await router.replace(route);
  }

  public static async navigateWithConfirm(): Promise<void | NavigationFailure | undefined> {
    const route = store.state.route;

    store.commit(storeTypes.mutations.closeEditingDialog);
    if (route == undefined) return;

    store.commit(storeTypes.mutations.editingStop);
    const result = await router.push(route);
    return result;
  }

  public static navigateBack(): void {
    router.back();
  }
}
