import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { DocumentPaper } from "@/features/modules/paper/objects/DocumentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { LinkedSurvey } from "@/features/modules/survey/objects/LinkedSurvey";
import { UpperSurveyForm } from "../objects/UpperSurveyForm";

export class UpperSurveyFormPaper extends DocumentPaper {
  private survey: LinkedSurvey;
  private upperSurveyForm: UpperSurveyForm;

  constructor(
    examination: Examination,
    survey: LinkedSurvey,
    upperSurveyForm: UpperSurveyForm,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(examination, d, n, t);
    this.survey = survey;
    this.upperSurveyForm = upperSurveyForm;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      ...super.setStyles(),
      upperAnswerTable: { fontSize: 8 },
      upperAnswerValue: { alignment: "center", bold: true },
      upperNote: { color: this.textColor, fontSize: 10, alignment: "justify", margin: [0, DataHelpers.mmToPoints(3), 0, DataHelpers.mmToPoints(2)] },
      upperRow: { color: this.textColor, fontSize: 10 },
      upperRowQuestion: { bold: true, color: this.textColor, fontSize: 10 },
      upperQuestion: { color: this.textColor, fontSize: 8 },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  public async write(isContinuos = false): Promise<Record<string, unknown>[]> {
    await this.setStructure("QUESTIONARIO ANAMNESTICO DELLE PATOLOGIE DEGLI ARTI SUPERIORI", isContinuos);

    await this.writeEmployeeData();

    this.writeSubtitle(this.t("surveyForm.upperSurveyForm.title1"));
    this.writeThreeQuestions(
      this.t("surveyForm.upperSurveyForm.question1"),
      this.upperSurveyForm.question1,
      this.t("surveyForm.upperSurveyForm.question2"),
      this.upperSurveyForm.question2,
      this.t("surveyForm.upperSurveyForm.question3"),
      this.upperSurveyForm.question3 ? "Sì" : "No"
    );
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question4"), this.upperSurveyForm.question4.join(", ") ?? "-");
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question5"), this.upperSurveyForm.question5);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question6"), this.upperSurveyForm.question6);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question7"), this.upperSurveyForm.question7);

    this.writeSubtitle(this.t("surveyForm.upperSurveyForm.title2"));
    this.writeThreeQuestions(
      this.t("surveyForm.upperSurveyForm.question8"),
      this.upperSurveyForm.question8,
      this.t("surveyForm.upperSurveyForm.question9"),
      this.upperSurveyForm.question9,
      this.t("surveyForm.upperSurveyForm.question10"),
      this.upperSurveyForm.question10 ? "Sì" : "No"
    );
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question11"), this.upperSurveyForm.question11.join(", ") ?? "-");
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question12"), this.upperSurveyForm.question12);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question13"), this.upperSurveyForm.question13);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question14"), this.upperSurveyForm.question14);

    this.writeSubtitle(this.t("surveyForm.upperSurveyForm.title3"));
    this.writeThreeQuestions(
      this.t("surveyForm.upperSurveyForm.question15"),
      this.upperSurveyForm.question15,
      this.t("surveyForm.upperSurveyForm.question16"),
      this.upperSurveyForm.question16,
      this.t("surveyForm.upperSurveyForm.question17"),
      this.upperSurveyForm.question17 ? "Sì" : "No"
    );
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question18"), this.upperSurveyForm.question18.join(", ") ?? "-");
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question19"), this.upperSurveyForm.question19);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question20"), this.upperSurveyForm.question20);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question21"), this.upperSurveyForm.question21);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question22"), this.upperSurveyForm.question22);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question23"), this.upperSurveyForm.question23);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question24"), this.upperSurveyForm.question24);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question25"), this.upperSurveyForm.question25);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question26"), this.upperSurveyForm.question26);

    this.writeSubtitle(this.t("surveyForm.upperSurveyForm.title4"));
    this.writeThreeQuestions(
      this.t("surveyForm.upperSurveyForm.question27"),
      this.upperSurveyForm.question27,
      this.t("surveyForm.upperSurveyForm.question28"),
      this.upperSurveyForm.question28,
      this.t("surveyForm.upperSurveyForm.question29"),
      this.upperSurveyForm.question29 ? "Sì" : "No"
    );
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question30"), this.upperSurveyForm.question30.join(", ") ?? "-");
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question31"), this.upperSurveyForm.question31);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question32"), this.upperSurveyForm.question32);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question33"), this.upperSurveyForm.question33);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question34"), this.upperSurveyForm.question34);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question35"), this.upperSurveyForm.question35);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question36"), this.upperSurveyForm.question36);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question37"), this.upperSurveyForm.question37);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question38"), this.upperSurveyForm.question38);

    this.writeSubtitle(this.t("surveyForm.upperSurveyForm.title5"));
    this.writeThreeQuestions(
      this.t("surveyForm.upperSurveyForm.question39"),
      this.upperSurveyForm.question39,
      this.t("surveyForm.upperSurveyForm.question40"),
      this.upperSurveyForm.question40,
      this.t("surveyForm.upperSurveyForm.question41"),
      this.upperSurveyForm.question41 ? "Sì" : "No"
    );
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question42"), this.upperSurveyForm.question42.join(", ") ?? "-");
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question43"), this.upperSurveyForm.question43);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question44"), this.upperSurveyForm.question44);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question45"), this.upperSurveyForm.question45);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question46"), this.upperSurveyForm.question46);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question47"), this.upperSurveyForm.question47);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question48"), this.upperSurveyForm.question48);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question49"), this.upperSurveyForm.question49);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question50"), this.upperSurveyForm.question50);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question51"), this.upperSurveyForm.question51);

    this.writeSubtitle(this.t("surveyForm.upperSurveyForm.title6"));
    if (this.upperSurveyForm.question53)
      this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question53"), this.upperSurveyForm.question53Details, 90);
    if (this.upperSurveyForm.question54)
      this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question54"), this.upperSurveyForm.question54Details, 90);
    if (this.upperSurveyForm.question55)
      this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question55"), this.upperSurveyForm.question55Details, 90);
    if (this.upperSurveyForm.question56)
      this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question56"), this.upperSurveyForm.question56Details, 90);

    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question52"), this.upperSurveyForm.question52, 90);
    this.writeOneQuestion(this.t("surveyForm.upperSurveyForm.question57"), this.upperSurveyForm.question57, 90);

    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: this.upperSurveyForm.question58 ?? "-",
      style: "upperNote",
    });

    await this.writeSignaturesWithDate(this.survey.date);

    return this.docDefinition.content as Record<string, unknown>[];
  }

  private writeThreeQuestions(
    textA: string,
    questionA: string | undefined,
    textB: string,
    questionB: string | undefined,
    textC: string,
    questionC: string | undefined
  ): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(40),
          text: textA,
          style: "upperRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: questionA ?? "-",
          style: "upperRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: "",
          style: "upperRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(40),
          text: textB,
          style: "upperRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: questionB ?? "-",
          style: "upperRow",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(40),
          text: textC,
          style: "upperRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(20),
          text: questionC ?? "-",
          style: "upperRow",
        },
      ],
    });
  }

  private writeOneQuestion(text: string, value: string | undefined, size = 60): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      columns: [
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: DataHelpers.mmToPoints(size),
          text: text,
          style: "upperRowQuestion",
        },
        {
          margin: [0, DataHelpers.mmToPoints(1), 0, DataHelpers.mmToPoints(2)],
          width: "*",
          text: value ?? "-",
          style: "upperRow",
        },
      ],
    });
  }
}
