import { RouteRecordRaw } from "vue-router";

export const docFolderRoutes: Array<RouteRecordRaw> = [
  {
    path: "/doc-folders/:id/edit",
    name: "DocFolderEdit",
    component: () => import("../views/DocFolderEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "docFolder",
      requiredRight: "viewUI",
    },
  },
];
