import { RouteRecordRaw } from "vue-router";

export const brokerRoutes: RouteRecordRaw[] = [
  {
    path: "/brokers",
    name: "BrokerList",
    component: () => import("../views/BrokerList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "broker",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/brokers/:id/edit",
    name: "BrokerEdit",
    component: () => import("../views/BrokerEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "broker",
      requiredRight: "viewUI",
    },
  },
  {
    path: "/brokers/:id/:section?",
    name: "BrokerView",
    component: () => import("../views/BrokerView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "broker",
      requiredRight: "viewUI",
    },
  },
];
