import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { FirestoreModel } from "../models/FirestoreModel";
import { FirestoreOfflineDocument } from "@/core/modules/firestore/objects/FirestoreOfflineDocument";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export class FirestoreHelpers {
  public static async getOfflineDocuments<T extends FirestoreOfflineDocument>(user: User, model: FirestoreModel<T>, parentId?: string): Promise<T[]> {
    try {
      if (model.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      let documents: T[] = (await offlineModel.getCacheByCollection(model.offlineModuleModel)) as T[];
      if (parentId !== undefined) documents = documents.filter((document) => document.parentId === parentId);

      return documents;
    } catch (error: unknown) {
      appFaultModel.catchAppError("FirestoreHelpers.getOfflineDocuments", { user, model, parentId }, error);
      return [];
    }
  }

  public static async getOnlineAllDocuments<T extends FirestoreDocument>(model: FirestoreModel<T>, parentId?: string): Promise<T[]> {
    try {
      const snapshotQuery: Query<T> = model.getPathReference(parentId).withConverter(model.firestoreConverter);

      const snapshot: QuerySnapshot<T> = await getDocs(snapshotQuery);

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      appFaultModel.catchAppError("FirestoreHelpers.getOnlineAllDocuments", { model, parentId }, error);
      return [];
    }
  }

  public static async getOnlineOwnDocuments<T extends FirestoreDocument>(model: FirestoreModel<T>, parentId?: string): Promise<T[]> {
    try {
      const user: User = store.getters[storeTypes.getters.getUser];

      const snapshotQuery: Query<T> = query(
        model.getPathReference(parentId).withConverter(model.firestoreConverter),
        where("createdBy", "==", user?.id)
      );

      const snapshot: QuerySnapshot<T> = await getDocs(snapshotQuery);

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      appFaultModel.catchAppError("FirestoreHelpers.getOnlineOwnDocuments", { model, parentId }, error);
      return [];
    }
  }

  public static async getOnlineCustomDocuments<T extends FirestoreDocument>(customQuery: Query<T>): Promise<T[]> {
    try {
      const snapshot: QuerySnapshot<T> = await getDocs(customQuery);

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      appFaultModel.catchAppError("FirestoreHelpers.getOnlineCustomDocuments", { customQuery }, error);
      return [];
    }
  }
}
