import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "../DoctorModel";
import { batch } from "@/core/modules/batch/objects/Batch";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export const updateDoctor = async (doctor: Doctor): Promise<void> => {
  try {
    const user: User = store.getters[storeTypes.getters.getUser];

    if (offlineModel.getOfflineState() === "offline") throw new Error("offlineModuleModelNotSaveable");

    if (doctorModel.lockPolicy === LockPolicy.DiscardUnsyncedChanges) {
      const oldDoctor: Doctor = await doctorModel.getDocument(doctor.id);
      if (doctor.hasChangedFrom(oldDoctor)) throw new Error("sync");
    }

    // check if user can update the document
    if (user.canUpdate(doctorModel.roleModule, doctor) === false)
      throw new Error(`Unable to update document #${doctor.id} in collection ${doctorModel.collectionName}`);

    doctor.setFullNames();
    doctor.setSearchKeys();
    doctor.setTimestampFields("update");
    batch.set(doctorModel.getDocumentReference(doctor.id), doctor.toFirestore());

    return batch.commit();
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorModel.updateDoctor", { doctor }, error);
  }
};
