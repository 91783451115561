import { decode } from "base64-arraybuffer";
import { PDFDocument } from "pdf-lib";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { AttachmentPaper } from "@/features/modules/examination/paper/AttachmentPaper";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { ExaminationHelpers } from "../ExaminationHelpers";
import { storageModel } from "@/core/modules/storage/models/StorageModel";

export async function printMergedAttachments(
  examination: Examination,
  d: (date: Date, format: string) => string,
  n: (value: number, format: string) => string,
  t: (entry: string, params?: Record<string, unknown>) => string
): Promise<void> {
  try {
    const attachmentPaper: AttachmentPaper = new AttachmentPaper(examination, d, n, t);
    const attachmentPaperBase64: string = await attachmentPaper.outputBase64();

    const attachmentsBase64: string[] = [];
    for (const examinationTest of examination.getAllTests()) {
      if (examinationTest.file === undefined) continue;

      const attachmentBase64: string = await storageModel.getBase64(
        ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
        examinationTest.file?.uniqueName as string
      );
      attachmentsBase64.push(attachmentBase64);
    }

    for (const attachment of examination.attachments) {
      if (attachment.file === undefined) continue;

      const attachmentBase64: string = await storageModel.getBase64(
        ExaminationHelpers.getFolderPath(examination.firm?.id, examination.company?.id, examination.employee?.id, examination.id),
        attachment.file?.uniqueName as string
      );
      attachmentsBase64.push(attachmentBase64);
    }

    const mergedPdf = await PDFDocument.create();

    const attachmentPaperPdf = await PDFDocument.load(decode(attachmentPaperBase64));
    const attachmentPaperPages = await mergedPdf.copyPages(attachmentPaperPdf, attachmentPaperPdf.getPageIndices());
    for (const attachmentPaperPage of attachmentPaperPages) {
      mergedPdf.addPage(attachmentPaperPage);
    }

    for (const attachment of attachmentsBase64) {
      const attachmentPdf = await PDFDocument.load(decode(attachment));
      const attachmentPages = await mergedPdf.copyPages(attachmentPdf, attachmentPdf.getPageIndices());
      for (const attachmentPage of attachmentPages) {
        mergedPdf.addPage(attachmentPage);
      }
    }

    mergedPdf.setTitle(`CS-${examination.codeDisplay.replaceAll("/", "-")}.pdf`);
    const mergedPdfFile = await mergedPdf.save();

    const mergedPdfBlob = new File([mergedPdfFile], `CS-${examination.codeDisplay.replaceAll("/", "-")}.pdf`, { type: "application/pdf" });
    const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);
    window.open(mergedPdfUrl);
  } catch (error: unknown) {
    appFaultModel.catchAppError("ExaminationHelpers.printMergedAttachments", { examination }, error);
  }
}
