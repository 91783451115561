import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { OfflineModuleModel } from "@/core/modules/offline/objects/OfflineModuleModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "../objects/User";
import { userModel } from "./UserModel";

export class UserOfflineModel extends OfflineModuleModel<User> {
  public constructor() {
    super(() => new User(), "users", "user.users", undefined);
  }

  public async downloadCache(): Promise<User[]> {
    try {
      const user: User = store.getters[storeTypes.getters.getUser];

      return [await userModel.getDocument(user.id)];
    } catch (error: unknown) {
      if (error instanceof AppError) {
        error.addToStack("UserOfflineModel.downloadCache", {}, error);
        console.log(error);
        await appFaultModel.createAppFaultFromAppError(error);
      }
      return [];
    }
  }
}

export const userOfflineModel: UserOfflineModel = new UserOfflineModel();
