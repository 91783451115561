import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { websocketClient } from "@/features/modules/assist/objects/WebsocketClient";

export const executeDrugTest = (examination: Examination): void => {
  try {
    websocketClient.sendRequest("executeDrugTest", { examinationId: examination.id });
  } catch (error: unknown) {
    appFaultModel.catchAppError("AssistModel.executeDrugTest", { examination }, error);
  }
};
