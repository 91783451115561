export const fr = {
  cloudFault: {
    cloudFault: "Événement cloud",
    cloudFaults: "Événements cloud",
    createdAt: "Date et heure",
    details: "Détails",
    function: "Fonction",
    noDetails: "Aucun détail présent",
    notifications: {
      cloudFaultCreated: "Événement cloud créé",
      groupName: "Événements cloud",
    },
  },
  buttons: {
    deleteAll: "Supprimer tout",
    showDetails: "Afficher les détails",
  },
  navigation: {
    cloudFault: "Événements cloud",
  },
};
