export const it = {
  option: {
    invoice: "Fatturazione",
    option: "Impostazioni",
    stampThreshold: "Soglia imposta bollo",
    stampThresholdError: "Inserire un valore valido",
    stampValue: "Valore imposta bollo",
    stampValueError: "Inserire un valore valido",
    vatValue: "Valore IVA",
    vatValueError: "Inserire un valore valido",
    withHoldingTaxValue: "Ritenuta d'acconto",
    withHoldingTaxValueError: "Inserire un valore valido",
  },
  navigation: {
    option: "Impostazioni",
  },
};
