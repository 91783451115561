import { EmployeeExamination } from "@/features/modules/employee/objects/EmployeeExamination";
import { Examination } from "../objects/Examination";
import { examinationOfflineModel } from "./ExaminationOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";

import {
  changeDuty,
  createExaminationForEmployee,
  getDraftExaminationsByCompanyAndFirm,
  getDraftExaminationsByDoctorAndFirm,
  getDraftExaminationsByEmployeeAndCompanyAndFirm,
  getDraftExaminationsByFirm,
  getExaminationsByCompanyAndFirm,
  getExaminationsByEmployee,
  getExaminationsByEmployeeAndCompanyAndFirm,
  getExaminationsByEmployeeAndFirm,
  getExaminationsByIds,
  getExaminationsByYear,
  getExaminationsForDashboard,
  getExaminationsForLegalRecord,
  getExaminationsToInvoiceByPeriodAndFirm,
  getExaminationsToInvoiceByCompanyAndPeriodAndFirm,
  getExaminationsWithFilters,
  getLastCompletedExaminationsByCompanyAndFirm,
  getLastCompletedExaminationsByDoctorAndFirm,
  getLastCompletedExaminationByEmployeeAndCompanyAndFirm,
  getNextExaminationCodeByDateAndFirm,
  getPendingExaminationsByFirm,
  isChangeDutyPossible,
} from "./methods";

export class ExaminationModel extends FirestoreModel<Examination> {
  public constructor() {
    super(() => new Examination(), "examinations", LockPolicy.DiscardUnsyncedChanges, "examination");
    this.beforeDeleteFunction = "featuresExaminationBeforeDelete";
    this.offlineModuleModel = examinationOfflineModel;
  }

  public async getDocument(examinationId: string): Promise<Examination> {
    return super.getDocument(examinationId);
  }

  public async createDocument(examination: Examination): Promise<string> {
    await examination.setCodes();

    return super.createDocument(examination);
  }

  public async updateDocument(examination: Examination): Promise<void> {
    return super.updateDocument(examination);
  }

  public async deleteDocument(examination: Examination): Promise<boolean> {
    return super.deleteDocument(examination);
  }

  public async changeDuty(examination: Examination, newDutyName: string, selectedCompanyDutiesIds: string[]): Promise<void> {
    return changeDuty(examination, newDutyName, selectedCompanyDutiesIds);
  }

  public async createExaminationForEmployee(employeeExamination: EmployeeExamination): Promise<string> {
    return createExaminationForEmployee(employeeExamination);
  }

  public async getDraftExaminationsByCompanyAndFirm(companyId: string): Promise<Examination[]> {
    return getDraftExaminationsByCompanyAndFirm(companyId);
  }

  public async getDraftExaminationsByDoctorAndFirm(doctorId?: string): Promise<Examination[]> {
    return getDraftExaminationsByDoctorAndFirm(doctorId);
  }

  public async getDraftExaminationsByEmployeeAndCompanyAndFirm(employeeId: string, companyId: string): Promise<Examination[]> {
    return getDraftExaminationsByEmployeeAndCompanyAndFirm(employeeId, companyId);
  }

  public async getDraftExaminationsByFirm(): Promise<Examination[]> {
    return getDraftExaminationsByFirm();
  }

  public async getExaminationsByCompanyAndFirm(companyId: string, firmId?: string): Promise<Examination[]> {
    return getExaminationsByCompanyAndFirm(companyId, firmId);
  }

  public async getExaminationsByEmployee(employeeId: string): Promise<Examination[]> {
    return getExaminationsByEmployee(employeeId);
  }

  public async getExaminationsByEmployeeAndCompanyAndFirm(employeeId: string, companyId: string): Promise<Examination[]> {
    return getExaminationsByEmployeeAndCompanyAndFirm(employeeId, companyId);
  }

  public async getExaminationsByEmployeeAndFirm(employeeId: string): Promise<Examination[]> {
    return getExaminationsByEmployeeAndFirm(employeeId);
  }

  public async getExaminationsByIds(ids: string[]): Promise<Examination[]> {
    return getExaminationsByIds(ids);
  }

  public async getExaminationsByYear(year: number): Promise<Examination[]> {
    return getExaminationsByYear(year);
  }

  public async getExaminationsForDashboard(doctorId?: string): Promise<Examination[]> {
    return getExaminationsForDashboard(doctorId);
  }

  public async getExaminationsForLegalRecord(companyId: string, branchId: string, doctorId: string, year: number): Promise<Examination[]> {
    return getExaminationsForLegalRecord(companyId, branchId, doctorId, year);
  }

  public async getExaminationsToInvoiceByCompanyAndPeriodAndFirm(companyId: string, startDate: Date, endDate: Date): Promise<Examination[]> {
    return getExaminationsToInvoiceByCompanyAndPeriodAndFirm(companyId, startDate, endDate);
  }

  public async getExaminationsToInvoiceByPeriodAndFirm(startDate: Date, endDate: Date): Promise<Examination[]> {
    return getExaminationsToInvoiceByPeriodAndFirm(startDate, endDate);
  }

  public async getExaminationsWithFilters(
    startDate: Date,
    endDate: Date,
    companyId: string,
    employeeId: string,
    doctorId: string,
    state: string
  ): Promise<Examination[]> {
    return getExaminationsWithFilters(startDate, endDate, companyId, employeeId, doctorId, state);
  }

  public async getLastCompletedExaminationsByCompanyAndFirm(companyId: string): Promise<Examination[]> {
    return getLastCompletedExaminationsByCompanyAndFirm(companyId);
  }

  public async getLastCompletedExaminationsByDoctorAndFirm(doctorId?: string): Promise<Examination[]> {
    return getLastCompletedExaminationsByDoctorAndFirm(doctorId);
  }

  public async getLastCompletedExaminationByEmployeeAndCompanyAndFirm(employeeId: string, companyId: string): Promise<Examination | undefined> {
    return getLastCompletedExaminationByEmployeeAndCompanyAndFirm(employeeId, companyId);
  }

  public async getNextExaminationCodeByDateAndFirm(date: Date): Promise<number> {
    return getNextExaminationCodeByDateAndFirm(date);
  }

  public async getPendingExaminationsByFirm(): Promise<Examination[]> {
    return getPendingExaminationsByFirm();
  }

  public async isChangeDutyPossible(examination: Examination): Promise<boolean> {
    return isChangeDutyPossible(examination);
  }
}

export const examinationModel: ExaminationModel = new ExaminationModel();
