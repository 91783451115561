import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { DocExpiration } from "@/features/modules/doc/objects/DocExpiration";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { DocTypeSource } from "@/features/modules/docType/objects/DocTypeSource";
import { Widget } from "@/core/modules/widget/objects/Widget";
import { widgetModel } from "@/core/modules/widget/models/WidgetModel";

export const getMissingDoctorDocs = async (): Promise<DocExpiration[]> => {
  try {
    const docExpirations: DocExpiration[] = [];

    const doctors: Doctor[] = await doctorModel.getDoctorsWithTrackExpirations();
    const widgets: Widget[] = await widgetModel.getWidgetsByTypeAndSource("missingDocs", DocTypeSource.Doctor);

    for (const doctor of doctors) {
      if (doctor.trackExpirations === false) continue;

      const doctorWidget: Widget | undefined = widgets.find((widget) => widget.source === DocTypeSource.Doctor && widget.sourceId === doctor.id);
      if (doctorWidget === undefined) continue;
      if (doctorWidget.values.count === 0) continue;
      if (doctorWidget.values.missings == undefined) continue;

      const missings: string[] = doctorWidget.values.missings as string[];
      for (const docTypeId of missings) {
        docExpirations.push(new DocExpiration(doctor.id, doctor.fullNameWithTitle ?? "-", new Date("2000-01-01"), docTypeId));
      }
    }

    return docExpirations;
  } catch (error: unknown) {
    appFaultModel.catchAppError("DoctorDocModel.getMissingDoctorDocs", {}, error);
    return [];
  }
};
