
import { defineComponent } from "vue";

import AssistSelector from "@/features/modules/assist/components/AssistSelector.vue";

export default defineComponent({
  name: "TopBarRightHolder",
  components: {
    AssistSelector,
  },
});
