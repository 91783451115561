import { SurveyForm } from "./SurveyForm";

import { NullableNumberField, StringField } from "@/core/fields";

export class AuditSurveyForm extends SurveyForm {
  public question1: number | undefined = 0;
  public question2: number | undefined = 0;
  public question3: number | undefined = 0;
  public question4: number | undefined = 0;
  public question5: number | undefined = 0;
  public question6: number | undefined = 0;
  public question7: number | undefined = 0;
  public question8: number | undefined = 0;
  public question9: number | undefined = 0;
  public question10: number | undefined = 0;
  public points: number | undefined = 0;
  public result: string | undefined = "Consumo di alcol nella norma";

  public constructor(firestoreData?: Record<string, unknown>) {
    super(firestoreData);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);

    this.isPrintable = true;
  }

  public fromFirestore(data: Record<string, unknown>): AuditSurveyForm {
    super.fromFirestore(data);

    this.question1 = NullableNumberField.fromFirestore(data.question1);
    this.question2 = NullableNumberField.fromFirestore(data.question2);
    this.question3 = NullableNumberField.fromFirestore(data.question3);
    this.question4 = NullableNumberField.fromFirestore(data.question4);
    this.question5 = NullableNumberField.fromFirestore(data.question5);
    this.question6 = NullableNumberField.fromFirestore(data.question6);
    this.question7 = NullableNumberField.fromFirestore(data.question7);
    this.question8 = NullableNumberField.fromFirestore(data.question8);
    this.question9 = NullableNumberField.fromFirestore(data.question9);
    this.question10 = NullableNumberField.fromFirestore(data.question10);
    this.points = NullableNumberField.fromFirestore(data.points);
    this.result = StringField.fromFirestore(data.result);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.question1 = NullableNumberField.toFirestore(this.question1);
    firestoreData.question2 = NullableNumberField.toFirestore(this.question2);
    firestoreData.question3 = NullableNumberField.toFirestore(this.question3);
    firestoreData.question4 = NullableNumberField.toFirestore(this.question4);
    firestoreData.question5 = NullableNumberField.toFirestore(this.question5);
    firestoreData.question6 = NullableNumberField.toFirestore(this.question6);
    firestoreData.question7 = NullableNumberField.toFirestore(this.question7);
    firestoreData.question8 = NullableNumberField.toFirestore(this.question8);
    firestoreData.question9 = NullableNumberField.toFirestore(this.question9);
    firestoreData.question10 = NullableNumberField.toFirestore(this.question10);
    firestoreData.points = NullableNumberField.toFirestore(this.points);
    firestoreData.result = StringField.toFirestore(this.result);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): AuditSurveyForm {
    super.fromOfflineCache(data);

    this.question1 = NullableNumberField.fromOfflineCache(data.question1);
    this.question2 = NullableNumberField.fromOfflineCache(data.question2);
    this.question3 = NullableNumberField.fromOfflineCache(data.question3);
    this.question4 = NullableNumberField.fromOfflineCache(data.question4);
    this.question5 = NullableNumberField.fromOfflineCache(data.question5);
    this.question6 = NullableNumberField.fromOfflineCache(data.question6);
    this.question7 = NullableNumberField.fromOfflineCache(data.question7);
    this.question8 = NullableNumberField.fromOfflineCache(data.question8);
    this.question9 = NullableNumberField.fromOfflineCache(data.question9);
    this.question10 = NullableNumberField.fromOfflineCache(data.question10);
    this.points = NullableNumberField.fromOfflineCache(data.points);
    this.result = StringField.fromOfflineCache(data.result);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toOfflineCache();

    firestoreData.question1 = NullableNumberField.toOfflineCache(this.question1);
    firestoreData.question2 = NullableNumberField.toOfflineCache(this.question2);
    firestoreData.question3 = NullableNumberField.toOfflineCache(this.question3);
    firestoreData.question4 = NullableNumberField.toOfflineCache(this.question4);
    firestoreData.question5 = NullableNumberField.toOfflineCache(this.question5);
    firestoreData.question6 = NullableNumberField.toOfflineCache(this.question6);
    firestoreData.question7 = NullableNumberField.toOfflineCache(this.question7);
    firestoreData.question8 = NullableNumberField.toOfflineCache(this.question8);
    firestoreData.question9 = NullableNumberField.toOfflineCache(this.question9);
    firestoreData.question10 = NullableNumberField.toOfflineCache(this.question10);
    firestoreData.points = NullableNumberField.toOfflineCache(this.points);
    firestoreData.result = StringField.toOfflineCache(this.result);

    return firestoreData;
  }
}
