import { DocumentReference } from "firebase/firestore";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";

import { NumberField } from "@/core/fields";

export class Option extends FirestoreDocument {
  public stampThreshold = 0;
  public stampValue = 0;
  public vatValue = 0;
  public withHoldingTaxValue = 0;

  public constructor(firestoreData?: Record<string, unknown>) {
    super("options");
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, "options");
  }

  public fromFirestore(data: Record<string, unknown>, id?: string, firestoreRef?: DocumentReference): Option {
    super.fromFirestore(data, id, firestoreRef);

    this.stampThreshold = NumberField.fromFirestore(data.stampThreshold);
    this.stampValue = NumberField.fromFirestore(data.stampValue);
    this.vatValue = NumberField.fromFirestore(data.vatValue);
    this.withHoldingTaxValue = NumberField.fromFirestore(data.withHoldingTaxValue);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.stampThreshold = NumberField.toFirestore(this.stampThreshold);
    firestoreData.stampValue = NumberField.toFirestore(this.stampValue);
    firestoreData.vatValue = NumberField.toFirestore(this.vatValue);
    firestoreData.withHoldingTaxValue = NumberField.toFirestore(this.withHoldingTaxValue);

    return firestoreData;
  }
}
