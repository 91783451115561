import { Query, query, where } from "firebase/firestore";

import { Doctor } from "../objects/Doctor";
import { doctorOfflineModel } from "./DoctorOfflineModel";
import { FirestoreModel } from "@/core/modules/firestore/models/FirestoreModel";
import { LockPolicy } from "@/core/modules/firestore/objects/LockPolicy";
import { SortCriteria } from "@/core/modules/firestore/objects/SortCriteria";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

import {
  createDoctor,
  deleteDoctor,
  getDoctorFromUser,
  getDoctorsByFirm,
  getDoctorsWithTrackExpirations,
  isEmailUnique,
  updateDoctor,
} from "./methods";

export class DoctorModel extends FirestoreModel<Doctor> {
  public constructor() {
    super(() => new Doctor(), "doctors", LockPolicy.DiscardUnsyncedChanges, "doctor");
    this.beforeDeleteFunction = "featuresDoctorBeforeDelete";
    this.offlineModuleModel = doctorOfflineModel;
  }

  public async getDocuments(): Promise<Doctor[]> {
    const user: User = store.getters[storeTypes.getters.getUser];

    let customQuery: Query<Doctor> | undefined;
    if (user.role?.name === "Medico") {
      customQuery = query(this.getPathReference().withConverter(this.firestoreConverter), where("userId", "==", user.id));
    }

    return super.getDocuments([new SortCriteria("fullName", "asc", "string")], undefined, customQuery);
  }

  public async getDocument(doctorId: string): Promise<Doctor> {
    return super.getDocument(doctorId);
  }

  public async createDocument(doctor: Doctor): Promise<string> {
    return createDoctor(doctor);
  }

  public async updateDocument(doctor: Doctor): Promise<void> {
    return updateDoctor(doctor);
  }

  public async deleteDocument(doctor: Doctor): Promise<boolean> {
    return deleteDoctor(doctor);
  }

  public async getDoctorFromUser(userId: string): Promise<Doctor | undefined> {
    return getDoctorFromUser(userId);
  }

  public async getDoctorsByFirm(): Promise<Doctor[]> {
    return getDoctorsByFirm();
  }

  public async getDoctorsWithTrackExpirations(): Promise<Doctor[]> {
    return getDoctorsWithTrackExpirations();
  }

  public async isEmailUnique(email: string, doctorId?: string): Promise<boolean> {
    return isEmailUnique(email, doctorId);
  }
}

export const doctorModel: DoctorModel = new DoctorModel();
