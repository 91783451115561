import { RouteRecordRaw } from "vue-router";

export const normRoutes: RouteRecordRaw[] = [
  {
    path: "/norms/:id/edit",
    name: "NormEdit",
    component: () => import("../views/NormEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "norm",
      requiredRight: "viewUI",
    },
  },
];
