import { GroupedStatModule } from "../objects/GroupedStatModule";
import { StatModule } from "../objects/StatModule";
import { statModules } from "../objects/statModules";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { User } from "@/core/modules/user/objects/User";

export class StatHelpers {
  public static getGroupedModules(modules: StatModule[]): GroupedStatModule[] {
    const groupedModules: GroupedStatModule[] = [];

    const user: User = store.getters[storeTypes.getters.getUser];

    let lastGroup: string | undefined = undefined;
    for (const module of modules) {
      if (module.requiredModule !== undefined && user.canViewUI(module.requiredModule) === false) continue;

      if (lastGroup === undefined || lastGroup != module.group) {
        const groupedModule: GroupedStatModule = {
          group: module.group,
          modules: [module],
        };
        groupedModules.push(groupedModule);
      } else {
        const groupedModule: GroupedStatModule | undefined = groupedModules.find((groupedModule) => groupedModule.group === lastGroup);
        if (groupedModule === undefined) continue;
        groupedModule.modules.push(module);
      }
      lastGroup = module.group;
    }

    return groupedModules;
  }

  public static registerModule(module: StatModule): void {
    if (statModules.includes(module) === false) {
      statModules.push(module);
    }
  }
}
