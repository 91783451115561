import { getDocs, Query, query, QuerySnapshot, where } from "firebase/firestore";

import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { FirestoreSorter } from "@/core/modules/firestore/objects/FirestoreSorter";
import { offlineModel } from "@/core/modules/offline/models/OfflineModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";
import { TestType } from "@/features/modules/testType/objects/TestType";
import { testTypeModel } from "../TestTypeModel";
import { User } from "@/core/modules/user/objects/User";

export const getTestTypesByTestTypeCategory = async (testTypeCategoryId: string): Promise<TestType[]> => {
  try {
    if (offlineModel.getOfflineState() === "offline") {
      const user: User = store.getters[storeTypes.getters.getUser];

      if (testTypeModel.offlineModuleModel === undefined) return [];
      if (user.canRead("offline") === false) throw new Error("user doesn't have offline rights");

      const documents: TestType[] = (await offlineModel.getCacheByCollection(testTypeModel.offlineModuleModel)) as TestType[];
      return sortDocuments(documents.filter((testType) => testType.category?.id === testTypeCategoryId));
    }

    const snapshotQuery: Query<TestType> = query(
      testTypeModel.getPathReference().withConverter(testTypeModel.firestoreConverter),
      where("category.id", "==", testTypeCategoryId)
    );

    const snapshot: QuerySnapshot<TestType> = await getDocs(testTypeModel.addReadConditionToQuery(snapshotQuery));

    if (snapshot == undefined || snapshot.empty) return [];

    return sortDocuments(snapshot.docs.map((doc) => doc.data()));
  } catch (error: unknown) {
    appFaultModel.catchAppError("TestTypeModel.getTestTypesByTestTypeCategory", { testTypeCategoryId }, error);
    return [];
  }
};

function sortDocuments(documents: TestType[]): TestType[] {
  const sorter: FirestoreSorter<TestType> = new FirestoreSorter(documents);
  sorter.addSortCriteria("name", "asc", "string");
  return sorter.sort();
}
