
import { defineComponent, onBeforeUnmount, onMounted, Ref, ref } from "vue";

import { AppError } from "@/core/modules/appFault/objects/AppError";
import { appFaultModel } from "@/core/modules/appFault/models/AppFaultModel";
import { eventBus } from "@/core/modules/eventBus/EventBus";
import { offlineModel } from "../models/OfflineModel";
import { useLocale } from "@/core/modules/locale/module";

export default defineComponent({
  name: "OfflineComponent",
  setup() {
    const { t } = useLocale();
    let eventBusIdState: string | undefined = undefined;
    let eventBusIdQueue: string | undefined = undefined;

    const networkState: Ref<"online" | "offline"> = ref(offlineModel.getOfflineState());
    const onlineState: Ref<boolean> = ref(navigator.onLine);
    const queueCount: Ref<number> = ref(0);

    const updateOnlineState = async (e: Event): Promise<void> => {
      try {
        const { type } = e;
        onlineState.value = type === "online";
        eventBus.emit("connectionToggleState", onlineState.value ? "online" : "offline");
      } catch (error: unknown) {
        if (error instanceof AppError) {
          error.addToStack("OfflineComponent.updateOnlineState", { e }, error);
          console.log(error);
          await appFaultModel.createAppFaultFromAppError(error);
        }
      }
    };

    const updateQueueCount = async (): Promise<void> => {
      try {
        queueCount.value = await offlineModel.getQueueCount();
      } catch (error: unknown) {
        if (error instanceof AppError) {
          error.addToStack("OfflineComponent.updateQueueCount", {}, error);
          console.log(error);
          await appFaultModel.createAppFaultFromAppError(error);
        }
      }
    };

    onMounted(() => {
      window.addEventListener("online", updateOnlineState);
      window.addEventListener("offline", updateOnlineState);

      eventBusIdState = eventBus.on("offlineToggleStateComponent", async () => {
        networkState.value = offlineModel.getOfflineState();
      });
      eventBusIdQueue = eventBus.on("offlineDocumentQueueChanged", async () => {
        updateQueueCount();
      });

      updateQueueCount();
    });

    onBeforeUnmount(() => {
      if (eventBusIdState !== undefined) eventBus.off(eventBusIdState);
      if (eventBusIdQueue !== undefined) eventBus.off(eventBusIdQueue);

      window.removeEventListener("online", updateOnlineState);
      window.removeEventListener("offline", updateOnlineState);
    });

    return {
      networkState,
      queueCount,
      t,
    };
  },
});
