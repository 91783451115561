import { DataHelpers } from "@/core/modules/helpers/DataHelpers";
import { Doctor } from "@/features/modules/doctor/objects/Doctor";
import { DoctorHelpers } from "@/features/modules/doctor/helpers/DoctorHelpers";
import { doctorModel } from "@/features/modules/doctor/models/DoctorModel";
import { Employee } from "@/features/modules/employee/objects/Employee";
import { employeeModel } from "@/features/modules/employee/models/EmployeeModel";
import { Examination } from "@/features/modules/examination/objects/Examination";
import { Paper } from "@/core/modules/paper/objects/Paper";
import { PaperHelpers } from "../helpers/PaperHelpers";
import { StorageHelpers } from "@/core/modules/storage/helpers/StorageHelpers";
import { storageModel } from "@/core/modules/storage/models/StorageModel";
import { store } from "@/core/modules/store/module";
import { storeTypes } from "@/core/modules/store/types";

export class DocumentPaper extends Paper {
  protected examination: Examination;

  constructor(
    examination: Examination,
    d: (date: Date, format: string) => string,
    n: (value: number, format: string) => string,
    t: (entry: string, params?: Record<string, unknown>) => string
  ) {
    super(d, n, t);
    this.examination = examination;
  }

  public setStyles(): Record<string, unknown> {
    this.docDefinition.styles = {
      documentTitle: { alignment: "center", bold: true, color: this.highlightColor, fontSize: 14, margin: [0, 0, 0, 0] },
      documentSubtitle: {
        alignment: "center",
        bold: true,
        color: this.highlightColor,
        fontSize: 12,
        margin: [0, DataHelpers.mmToPoints(3), 0, DataHelpers.mmToPoints(2)],
      },
      employeeDataField: { fontSize: 6, margin: [0, 0, 0, 2] },
      employeeDataTable: { alignment: "center" },
      employeeDataValue: { border: false, color: this.highlightColor, fontSize: 10 },
      footerText: { color: this.footerColor, fontSize: 8, italics: true },
      headerText: { color: this.textColor, fontSize: 10 },
      referenceText: { alignment: "right", color: this.highlightColor, fontSize: 8 },
      signaturesDateField: { border: false, color: this.highlightColor, fontSize: 10, bold: true, margin: [0, 0, 0, 20] },
      signaturesDateValue: { border: false, color: this.textColor, fontSize: 10 },
      signaturesField: { fontSize: 6, margin: [0, 25, 0, 2] },
      signaturesMiddle: { fontSize: 8, color: this.highlightColor, margin: [0, 0, 0, 2] },
      signaturesMiddleWithSpace: { fontSize: 8, color: this.highlightColor, margin: [0, 0, 0, 25] },
      signaturesTable: { alignment: "center" },
      signaturesValue: { border: false, color: this.highlightColor, fontSize: 10, bold: true, margin: [0, 0, 0, 2] },
    };

    return this.docDefinition.styles as Record<string, unknown>;
  }

  protected async setStructure(title: string, isContinuos = false): Promise<void> {
    this.setTitle(title, isContinuos);
    this.setAuthor(store.getters[storeTypes.getters.getUser]);
    this.setHighlightColor("#000000");

    const customHeader = await PaperHelpers.getCustomHeader(this.d, this.n, this.t, `RIF: ${this.examination.codeDisplay}`);
    this.setHeader(customHeader);

    const customFooter = PaperHelpers.getCustomFooter(
      this.d,
      this.n,
      this.t,
      "La copia elettronica è conforme all'originale depositato presso lo studio medico"
    );
    this.setFooter(customFooter);
  }

  protected writeSubtitle(text: string): void {
    (this.docDefinition.content as Record<string, unknown>[]).push({
      text: text,
      style: "documentSubtitle",
    });
  }

  protected async writeEmployeeData(): Promise<void> {
    const employee: Employee = await employeeModel.getDocument(this.examination.employee?.id as string);

    const employeeDataTableBody: unknown[] = [
      [
        { text: this.examination.company?.name?.toLocaleUpperCase() ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.examination.branch?.name?.toLocaleUpperCase() ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("examination.company"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("examination.branch"), style: "employeeDataField", border: [false, true, false, false] },
      ],
      [
        { text: employee.fullName?.toLocaleUpperCase() ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: employee.fiscalCode?.toLocaleUpperCase() ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("employee.fullName"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("employee.fiscalCode"), style: "employeeDataField", border: [false, true, false, false] },
      ],
      [
        {
          text: employee.birthDate !== undefined ? this.d(employee.birthDate, "shortDate") : "-",
          style: "employeeDataValue",
          border: [false, false, false, false],
        },
        { text: "", border: [false, false, false, false] },
        { text: employee.birthPlace?.toLocaleUpperCase() ?? "-", style: "employeeDataValue", border: [false, false, false, false] },
      ],
      [
        { text: this.t("employee.birthDate"), style: "employeeDataField", border: [false, true, false, false] },
        { text: "", border: [false, false, false, false] },
        { text: this.t("employee.birthPlace"), style: "employeeDataField", border: [false, true, false, false] },
      ],
    ];

    (this.docDefinition.content as Record<string, unknown>[]).push({
      margin: [0, DataHelpers.mmToPoints(5), 0, DataHelpers.mmToPoints(5)],
      style: "employeeDataTable",
      table: {
        body: employeeDataTableBody,
        headerRows: 0,
        widths: [DataHelpers.mmToPoints(82), "*", DataHelpers.mmToPoints(82)],
      },
      layout: {
        hLineWidth: () => 0.5,
        hLineColor: () => "black",
      },
    });
  }

  protected async writeSignatures(): Promise<void> {
    if (this.examination.doctor === undefined) return;
    const doctor: Doctor = await doctorModel.getDocument(this.examination.doctor.id);

    if (doctor.signatureImage === undefined || doctor.signatureImage.uniqueName === undefined) {
      const signaturesTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          {
            text: "Firma del lavoratore",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
          {
            text: this.examination.doctor?.fullNameWithTitle ?? "-",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
        ],
      ];

      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(5), 0, 0],
        style: "signaturesTable",
        table: {
          body: signaturesTableBody,
          headerRows: 0,
          widths: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(50), "*", DataHelpers.mmToPoints(50), DataHelpers.mmToPoints(15)],
        },
        layout: {
          hLineWidth: () => 0.5,
          hLineColor: () => "black",
        },
      });
    } else {
      const signatureImageUrl: string = await storageModel.getDownloadLink(DoctorHelpers.getFolderPath(doctor.id), doctor.signatureImage.uniqueName);
      const signatureImageData: string | undefined = await StorageHelpers.blobToBase64(await StorageHelpers.fetchAsBlob(signatureImageUrl));
      if (signatureImageData === undefined) throw new Error("Unable to read signature image");

      const signaturesTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          {
            text: "Firma del lavoratore",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
          {
            text: "Firma del medico",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          {
            fit: [DataHelpers.mmToPoints(40), DataHelpers.mmToPoints(40)],
            height: DataHelpers.mmToPoints(20),
            image: `data:image/png;base64,${signatureImageData}`,
            width: DataHelpers.mmToPoints(40),
            border: [false, false, false, false],
            style: "signaturesMiddle",
          },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
        ],
      ];

      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(5), 0, 0],
        style: "signaturesTable",
        table: {
          body: signaturesTableBody,
          headerRows: 0,
          widths: [DataHelpers.mmToPoints(15), DataHelpers.mmToPoints(50), "*", DataHelpers.mmToPoints(50), DataHelpers.mmToPoints(15)],
        },
        layout: {
          hLineWidth: () => 0.5,
          hLineColor: () => "black",
        },
      });
    }
  }

  protected async writeSignaturesWithDate(date: Date | undefined): Promise<void> {
    if (this.examination.doctor === undefined) return;
    const doctor: Doctor = await doctorModel.getDocument(this.examination.doctor.id);

    if (doctor.signatureImage === undefined || doctor.signatureImage.uniqueName === undefined) {
      const signaturesTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          {
            text: "Data",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
          {
            text: "Firma del lavoratore",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
          {
            text: this.examination.doctor?.fullNameWithTitle ?? "-",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: date !== undefined ? this.d(date, "shortDate") : "-", style: "signaturesDateValue", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddleWithSpace", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
        ],
      ];

      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(10), 0, 0],
        style: "signaturesTable",
        table: {
          body: signaturesTableBody,
          headerRows: 0,
          widths: [
            DataHelpers.mmToPoints(10),
            DataHelpers.mmToPoints(20),
            "*",
            DataHelpers.mmToPoints(40),
            "*",
            DataHelpers.mmToPoints(40),
            DataHelpers.mmToPoints(10),
          ],
        },
        layout: {
          hLineWidth: () => 0.5,
          hLineColor: () => "black",
        },
      });
    } else {
      const signatureImageUrl: string = await storageModel.getDownloadLink(DoctorHelpers.getFolderPath(doctor.id), doctor.signatureImage.uniqueName);
      const signatureImageData: string | undefined = await StorageHelpers.blobToBase64(await StorageHelpers.fetchAsBlob(signatureImageUrl));
      if (signatureImageData === undefined) throw new Error("Unable to read signature image");

      const signaturesTableBody: unknown[] = [
        [
          { text: "", border: [false, false, false, false] },
          {
            text: "Data",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
          {
            text: "Firma del lavoratore",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
          {
            text: "Firma del medico",
            style: "signaturesValue",
            border: [false, false, false, false],
          },
          { text: "", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          { text: date !== undefined ? this.d(date, "shortDate") : "-", style: "signaturesDateValue", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
          {
            fit: [DataHelpers.mmToPoints(40), DataHelpers.mmToPoints(40)],
            height: DataHelpers.mmToPoints(20),
            image: `data:image/png;base64,${signatureImageData}`,
            width: DataHelpers.mmToPoints(40),
            border: [false, false, false, false],
            style: "signaturesMiddle",
          },
          { text: "", style: "signaturesMiddle", border: [false, false, false, false] },
        ],
        [
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
          { text: "", style: "signaturesField", border: [false, true, false, false] },
          { text: "", style: "signaturesField", border: [false, false, false, false] },
        ],
      ];

      (this.docDefinition.content as Record<string, unknown>[]).push({
        margin: [0, DataHelpers.mmToPoints(10), 0, 0],
        style: "signaturesTable",
        table: {
          body: signaturesTableBody,
          headerRows: 0,
          widths: [
            DataHelpers.mmToPoints(10),
            DataHelpers.mmToPoints(20),
            "*",
            DataHelpers.mmToPoints(40),
            "*",
            DataHelpers.mmToPoints(40),
            DataHelpers.mmToPoints(10),
          ],
        },
        layout: {
          hLineWidth: () => 0.5,
          hLineColor: () => "black",
        },
      });
    }
  }
}
