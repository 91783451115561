import { StringField } from "@/core/fields";

export class RecentHistory {
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): RecentHistory {
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.notes = StringField.toFirestore(this.notes);

    return firestoreData;
  }

  public fromOfflineCache(data: Record<string, unknown>): RecentHistory {
    this.notes = StringField.fromOfflineCache(data.notes);

    return this;
  }

  public toOfflineCache(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.notes = StringField.toOfflineCache(this.notes);

    return firestoreData;
  }
}
